import { memo, useState } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useInternshipRegistrations } from 'Hooks';
import { containerVariants, names } from 'Constants';
import { getCurrentLocale } from 'Utils';
import { format } from 'date-fns';
import {
	InternshipRegistration,
	InternshipRegistrationApproveStatus,
	BadgeStatus,
	Colors
} from 'Types';
import {
	clx,
	formatLocaleDateTime,
	getFilterByName,
	getFilterByValue,
	requestStatus
} from 'Utils';
import {
	Badge,
	Button,
	DataTable,
	DataTableBody,
	DataTableHead,
	DataTableLoading,
	DataTableOperation,
	DataTableRow,
	DataTableRowCell,
	ListingPage,
	NoData,
	Pagination,
	ShimmerImage,
	SystemErrorAlert,
	MoreTerms
} from 'Elements';
import styles from 'partials/shared/page.module.scss';

export const ListInternshipParticipants = memo(() => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const {
		jobs: { internshipParticipants: internshipParticipantsPage }
	} = names;
	const defaultImage = '/assets/img/profiles/img-user-default.png';
	const [searchParams, setSearchParams] = useSearchParams();
	const params = Object.fromEntries([...searchParams]);
	const [isOpen, setIsOpen] = useState(false);
	const [dataSelected, setDataSelected] = useState<any>({});
	const pageParam = searchParams.get('page') || 1;
	const queryParam = searchParams.get('q') || '';
	const statusParam = searchParams.get('status') || '';

	const [state, setState] = useState({
		pageSize: 10,
		filterBy: statusParam,
		currentPage: pageParam,
		keyword: queryParam
	});

	const {
		data: internshipParticipants,
		isFetching,
		isError
	} = useInternshipRegistrations({
		status: getFilterByName(requestStatus, statusParam)?.value,
		pageSize: state.pageSize,
		pageNumber: state.currentPage,
		keyword: state.keyword
	});

	const getLocalFormat = () => {
		const currentLocale = getCurrentLocale().code;
		if (currentLocale === 'de') {
			return 'dd.MM.yyyy';
		}
		return 'yyyy-MM-dd';
	};

	const onChangePageSize = (pageSize: number) => {
		setSearchParams({ ...params, page: '1' });
		setState((prev) => ({
			...prev,
			pageSize,
			currentPage: 1
		}));
	};

	const handleSearch = (query: string) => {
		setSearchParams({
			...params,
			page: '1',
			q: query
		});
		setState((prev) => ({
			...prev,
			currentPage: 1,
			keyword: query
		}));
	};

	const handleFilterBy = (selected_filter: Partial<BadgeStatus>) => {
		setSearchParams({
			...params,
			page: '1',
			status: selected_filter.title?.toString() ?? ''
		});
		setState((prev) => ({
			...prev,
			filterBy: selected_filter.label?.toString() ?? '',
			currentPage: 1
		}));
	};

	const handlePageChange = (page: number) => {
		setSearchParams({ ...params, page: page.toString() });
		setState((prev) => ({
			...prev,
			currentPage: page
		}));
	};

	const getRequestStatus = (
		req_status: InternshipRegistrationApproveStatus
	) => {
		return (
			<Badge
				color={
					getFilterByValue(requestStatus, req_status)?.color ??
					Colors.primary
				}>
				{getFilterByValue(requestStatus, req_status)?.label
					? t(
							getFilterByValue(requestStatus, req_status)
								?.label as string
					  )
					: '...'}
			</Badge>
		);
	};

	const handleSelectRow = (row_id: string) => {
		void navigate(`${internshipParticipantsPage.path}/${row_id}`, {
			state: { prevPath: internshipParticipantsPage.path }
		});
	};

	const getApplicantName = (applicant: InternshipRegistration) => {
		const { firstName, lastName } = applicant;
		return `${firstName} ${lastName}`;
	};

	const cellClassName = (col: string) => {
		const cols: { [x: string]: string } = {
			participants: 'w-sm-15',
			internship: 'w-sm-25',
			sessions: 'w-sm-25',
			'registration-date': 'w-sm-15',
			status: 'w-sm-10',
			operation: 'w-sm-10'
		};
		return clx('w-100', cols[col.toLowerCase()]);
	};

	return (
		<>
			<ListingPage
				totalItems={internshipParticipants?.totalItems}
				selectedPageSize={state.pageSize}
				onPageSizeChange={onChangePageSize}
				filterOptions={requestStatus}
				selectedFilter={t(
					getFilterByName(requestStatus, statusParam)?.label ??
						'dropdown.all'
				)}
				onFilterChange={handleFilterBy}
				onSearchChange={handleSearch}>
				<DataTable>
					{internshipParticipants &&
						internshipParticipants?.totalItems > 0 && (
							<DataTableHead>
								<DataTableRowCell
									cellText="table.internship-participants.participants"
									cellClassName={cellClassName(
										'participants'
									)}
								/>
								<DataTableRowCell
									cellText="table.internship-participants.internship"
									cellClassName={cellClassName('internship')}
								/>
								<DataTableRowCell
									cellText="table.internship-participants.sessions"
									cellClassName={cellClassName('sessions')}
								/>
								<DataTableRowCell
									cellText="table.internship-participants.registration-date"
									cellClassName={cellClassName(
										'registration-date'
									)}
								/>
								<DataTableRowCell
									cellText="table.internship-participants.status"
									cellClassName={cellClassName('status')}
								/>
								<DataTableRowCell
									cellText="table.internship-participants.operation"
									cellClassName={cellClassName('operation')}
								/>
							</DataTableHead>
						)}
					<DataTableBody>
						{!isFetching && (
							<motion.div
								variants={containerVariants}
								initial="hidden"
								animate="show">
								{internshipParticipants?.items?.map(
									(request) => {
										return (
											<DataTableRow key={request.id}>
												<DataTableRowCell
													cellClassName={cellClassName(
														'participants'
													)}
													cellHead="table.participants">
													<div className="d-flex align-items-center gap-3">
														<div
															className={
																styles.avatarBox
															}>
															<ShimmerImage
																width={48}
																height={48}
																src={
																	request.imageThumbnail
																}
																alt={getApplicantName(
																	request.applicant
																)}
																defaultImage={
																	defaultImage
																}
																className={clx(
																	styles.avatar,
																	'object-cover'
																)}
															/>
														</div>
														<div className="d-flex flex-column">
															<h6 className="fs-sm fw-400 mb-0 lh-base">
																{getApplicantName(
																	request.applicant
																)}
															</h6>
															<span className="text-gray-3 fs-small">
																{
																	request
																		.applicant
																		.email
																}
															</span>
														</div>
													</div>
												</DataTableRowCell>
												<DataTableRowCell
													cellClassName={cellClassName(
														'internship'
													)}
													cellHead="table.internship">
													<h6 className="fs-sm fw-500 mb-0 lh-base">
														{request.title}
													</h6>
													{request.code && (
														<span className="text-gray-3 fw-300">
															{`${t(
																'forms.code'
															)}: ${
																request.code
															}`}
														</span>
													)}
												</DataTableRowCell>
												<DataTableRowCell
													cellClassName={cellClassName(
														'sessions'
													)}
													cellHead="table.internship">
													<div className="d-flex flex-wrap align-items-center gap-1">
														{request?.term?.sessions?.map(
															(item, index) => {
																const startDate: any =
																	new Date(
																		Date.parse(
																			item?.sessionStartDate
																		)
																	);
																const endDate: any =
																	new Date(
																		Date.parse(
																			item?.sessionEndDate
																		)
																	);
																if (index < 2) {
																	return (
																		<div
																			key={
																				request
																					?.term
																					.internshipTermId +
																				index
																			}
																			className="d-flex align-items-center px-1 text-gray-3 bg-gray-1 rounded-3 fw-400 fs-verySmall">
																			<div className="me-1">
																				{format(
																					new Date(
																						item?.sessionStartDate
																					),
																					`${getLocalFormat()}`
																				)}{' '}
																			</div>
																			<div className="d-flex align-items-center gap-1">
																				<div>
																					{startDate.toLocaleTimeString(
																						[],
																						{
																							hour: '2-digit',
																							minute: '2-digit',
																							hour12: false
																						}
																					)}
																				</div>
																				<div>
																					-
																				</div>
																				<div>
																					{endDate.toLocaleTimeString(
																						[],
																						{
																							hour: '2-digit',
																							minute: '2-digit',
																							hour12: false
																						}
																					)}
																				</div>
																			</div>
																		</div>
																	);
																}
															}
														)}
														<div
															className="text-yellow fw-400 fs-small cursor-pointer"
															onClick={() => {
																setIsOpen(
																	(
																		prevState
																	) =>
																		!prevState
																);
																setDataSelected(
																	request
																);
															}}>
															{' '}
															{t(
																'button.more-term'
															)}
														</div>
													</div>
												</DataTableRowCell>
												<DataTableRowCell
													cellClassName={cellClassName(
														'registration-date'
													)}
													cellHead="table.registrationDate">
													<time
														dateTime={
															request.requestDate
														}>
														{formatLocaleDateTime(
															request.requestDate
														)}
													</time>
												</DataTableRowCell>
												<DataTableRowCell
													cellClassName={cellClassName(
														'status'
													)}
													cellHead="table.status">
													{getRequestStatus(
														request.approveStatus
													)}
												</DataTableRowCell>
												<DataTableRowCell
													cellClassName={cellClassName(
														'operation'
													)}
													cellHead="table.operation">
													<DataTableOperation>
														<Button
															size="sm"
															color={
																Colors[
																	'white-primary'
																]
															}
															label={
																t(
																	'button.details'
																) as string
															}
															onClick={() =>
																handleSelectRow(
																	request.id
																)
															}
														/>
													</DataTableOperation>
												</DataTableRowCell>
											</DataTableRow>
										);
									}
								)}
							</motion.div>
						)}
						{isFetching && (
							<DataTableLoading
								widths={[15, 25, 25, 15, 10, 10]}
								count={state.pageSize}
							/>
						)}
					</DataTableBody>
					{!isFetching &&
						internshipParticipants?.totalItems === 0 && (
							<NoData message="title.no-internship-participant" />
						)}
					{isError && <SystemErrorAlert />}
				</DataTable>
				{!isFetching &&
					internshipParticipants &&
					internshipParticipants?.totalPages > 1 && (
						<Pagination
							className="mt-3"
							totalItemsCount={internshipParticipants?.totalItems}
							activePage={parseInt(
								state.currentPage.toString(),
								10
							)}
							itemsCountPerPage={state.pageSize}
							onChange={handlePageChange}
						/>
					)}
			</ListingPage>
			<MoreTerms
				isOpen={isOpen}
				dataSelected={dataSelected}
				toggleDrawer={() => setIsOpen((prevState) => !prevState)}
			/>
		</>
	);
});

ListInternshipParticipants.displayName = 'ListInternshipParticipants';
