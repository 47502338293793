import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import type { FieldErrors } from 'react-hook-form/dist/types';
import { names } from 'Constants';
import { ModalProps } from 'Types';
import { CheckRequirement, FontAwesome, ModalBox } from 'Elements';

interface Props extends ModalProps {
	requirement: FieldErrors;
}

export const ModalBoxCheckPlanPriceRequirement: FC<Props> = memo(
	({ isOpen, onClose, requirement }) => {
		const { t } = useTranslation();
		const navigate = useNavigate();
		const { planPrice } = names;

		const navigateTo = (url: string) => {
			onClose();
			void navigate(url);
		};

		return (
			<ModalBox
				isOpen={isOpen}
				onClose={onClose}
				title="title.plan-price.check-requirement">
				<p className="lh-md mb-0">
					{t('text.plan-price.check-requirement')}
				</p>
				<CheckRequirement
					title={t('forms.plan-price.link-to-agb')}
					status={
						requirement.termsAndConditionLink ? 'invalid' : 'valid'
					}
					description={
						<p className="mb-0">
							{t('validation.plan-price.agb-terms.description')}
							<span
								className="d-flex align-items-center gap-2 mt-1"
								role="button"
								onClick={() => navigateTo(planPrice.info.path)}>
								{t('label.review-and-fix')}
								<FontAwesome
									icon="arrow-right-long"
									size="1x"
								/>
							</span>
						</p>
					}
				/>
				<CheckRequirement
					title={t('forms.plan-price.plan-feature')}
					status={requirement.features ? 'invalid' : 'valid'}
					className="border-top border-gray-1"
					description={
						<p className="mb-0">
							{t('validation.plan-price.feature.description')}
							<span
								className="d-flex align-items-center gap-2 mt-1"
								role="button"
								onClick={() =>
									navigateTo(planPrice.feature.path)
								}>
								{t('label.review-and-fix')}
								<FontAwesome
									icon="arrow-right-long"
									size="1x"
								/>
							</span>
						</p>
					}
				/>
			</ModalBox>
		);
	}
);

ModalBoxCheckPlanPriceRequirement.displayName =
	'ModalBoxCheckPlanPriceRequirement';
