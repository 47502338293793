import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { clx } from 'Utils';
import styles from 'partials/shared/tab.module.scss';

interface Props {
	title: string;
	isActive: boolean;
	number?: string;
	onClick?: () => void;
}

export const ListTabItem: FC<Props> = memo(
	({ title, isActive, onClick, number }) => {
		const { t } = useTranslation();
		return (
			<span
				role="button"
				className={clx(styles.tab_list_item, isActive && styles.active)}
				onClick={onClick}>
				{number ? `${t(title)} (${number})` : t(title)}
			</span>
		);
	}
);

ListTabItem.displayName = 'ListTabItem';
