import { memo, useEffect, useMemo, useCallback } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import type { SubmitHandler } from 'react-hook-form/dist/types';
import { SelectOption, UserGroupForm } from 'Types';
import { useUpdateGroup, useRoles, usePrompt } from 'Hooks';
import {
	Card,
	LoadingContent,
	SelectController,
	SubmitButtonGroup
} from 'Elements';

export const UpdateUserGroup = memo(() => {
	const { t } = useTranslation();
	const { group_id } = useParams();
	const {
		updateGroup,
		group: fields,
		isFetching
	} = useUpdateGroup(group_id as string);
	const { data: rolesList, isLoading: isLoadingRoles } = useRoles({
		pageNumber: 1,
		pageSize: 500
	});
	const schema = yup.object().shape({
		name: yup.string().required(t('validation.group-name.required')),
		roles: yup
			.array()
			.min(1, t('validation.select-role.required'))
			.nullable()
			.of(
				yup.object({
					label: yup.string(),
					value: yup.string()
				})
			)
			.required(t('validation.select-role.required'))
	});

	const {
		reset,
		control,
		register,
		handleSubmit,
		formState: { errors, isDirty, isSubmitting }
	} = useForm<UserGroupForm>({
		resolver: yupResolver(schema)
	});

	usePrompt(t('forms.leave-screen-massage'), isDirty);

	const getRoleListOptions = useMemo(() => {
		const options: SelectOption[] = [];
		rolesList?.items?.forEach((role) => {
			options.push({ value: role.id, label: role.name });
		});
		return options;
	}, [rolesList?.items]);

	const getDefaultRoles = useCallback(
		(roles?: { id: string; name: string }[]) => {
			const options: SelectOption[] = [];
			if (roles) {
				roles.forEach((role: { id: string; name: string }) => {
					options.push({
						value: role.id,
						label: role.name
					});
				});
				return options;
			}
			return undefined;
		},
		[]
	);

	const onSubmit: SubmitHandler<UserGroupForm> = async (data) => {
		const api_data = {
			...data,
			roles: data.roles.map((role: SelectOption) => {
				return role.value as string;
			})
		};
		await updateGroup(api_data);
	};

	useEffect(() => {
		reset({
			...fields,
			roles: getDefaultRoles(fields?.roles)
		});
	}, [reset, fields, getDefaultRoles]);

	return (
		<Card>
			<form onSubmit={handleSubmit(onSubmit)}>
				<Row>
					<Col md={8} xxl={6}>
						<FormGroup>
							<Label htmlFor="name">
								{t('forms.name')}
								<small className="ms-1">
									({t('validation.required')})
								</small>
							</Label>
							<input
								{...register('name')}
								type="text"
								id="name"
								aria-invalid={!!errors.name}
								className="inputbox w-100"
							/>
							{errors.name && (
								<div className="invalid-feedback d-block">
									{errors.name.message}
								</div>
							)}
						</FormGroup>
						<FormGroup>
							<Label htmlFor="roles">
								{t('forms.roles')}
								<small className="ms-1">
									({t('validation.required')})
								</small>
							</Label>
							<SelectController
								isMulti
								name="roles"
								control={control}
								isDisabled={isLoadingRoles}
								options={getRoleListOptions}
								error={errors?.roles?.message?.toString()}
							/>
						</FormGroup>
						<FormGroup>
							<Label htmlFor="description">
								{t('forms.user.group.desc')}
							</Label>
							<textarea
								{...register('description')}
								id="description"
								className="inputbox w-100"
								cols={10}
								rows={5}
							/>
						</FormGroup>
						<SubmitButtonGroup
							isDisable={!isDirty}
							isSubmitting={isSubmitting}
						/>
					</Col>
				</Row>
			</form>
			{isFetching && <LoadingContent />}
		</Card>
	);
});

UpdateUserGroup.displayName = 'UpdateUserGroup';
