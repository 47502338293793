import { memo, FC } from 'react';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Tab } from 'Types';
import { Card, DecorationTab } from 'Elements';
import styles from 'partials/decoration/page-decoration-box.module.scss';

export const IntegrationDecorationBox: FC = memo(() => {
	const { pathname } = useLocation();
	const { t } = useTranslation();

	const defaultTab =
		pathname === `/marketing/integration` ||
		pathname === `/marketing/integration/partners`;

	const tabs: Tab[] = [
		{
			id: 0,
			title: 'tab.integration.partners',
			path: 'partners',
			isActive: defaultTab
		},
		{
			id: 1,
			title: 'tab.integration.become-partner',
			path: 'become-partner',
			isActive: pathname === `/marketing/integration/become-partner`
		},
		{
			id: 2,
			title: 'tab.integration.jobs',
			path: 'jobs',
			isActive: pathname === `/marketing/integration/jobs`
		},
		{
			id: 3,
			title: 'tab.integration.jobbiplace-partner',
			path: 'jobbiplace-partner',
			isActive: pathname === `/marketing/integration/jobbiplace-partner`
		}
	];

	return (
		<Card tab>
			<Row>
				<Col md={6} xl={7}>
					<h5 className="fs-6 fw-500 mb-3 lh-base">
						{t('title.integration.how-to-use')}
					</h5>
					<div className="text-gray-4">
						<p className="lh-md mb-1">
							{t('text.integration.how-to-use-subtext')}
						</p>
						<ul className="mb-2 text-list">
							<li className="lh-lg">
								{t('text.integration.how-to-use-step-1')}
							</li>
							<li className="lh-lg">
								{t('text.integration.how-to-use-step-2')}
							</li>
							<li className="lh-lg">
								{t('text.integration.how-to-use-step-3')}
							</li>
							<li className="lh-lg">
								{t('text.integration.how-to-use-step-4')}
							</li>
							<li className="lh-lg">
								{t('text.integration.how-to-use-step-5')}
							</li>
						</ul>
						<p className="lh-md">
							{t('text.integration.how-to-get-help')}
						</p>
					</div>
				</Col>
				<Col
					md={6}
					xl={5}
					className="my-3 my-md-0 align-self-xl-center">
					<img
						src="/assets/img/script.svg"
						className={styles.image}
					/>
				</Col>
			</Row>
			<DecorationTab tabs={tabs} />
		</Card>
	);
});

IntegrationDecorationBox.displayName = 'IntegrationDecorationBox';
