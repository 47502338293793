import { FC, memo } from 'react';
import { clx } from 'Utils';
import { AgentHeroImage, CompanyHeroImage } from 'Elements';
import styles from 'partials/homepage/section/profile-hero-image.module.scss';

interface Props {
	type: 'agent' | 'company';
}

export const ProfileHeroImage: FC<Props> = memo(({ type }) => {
	return (
		<div
			className={clx(
				styles.headerImage,
				'px-3 px-md-4 pt-3 pt-md-4 pb-0'
			)}>
			{type === 'agent' && <AgentHeroImage />}
			{type === 'company' && <CompanyHeroImage />}
		</div>
	);
});

ProfileHeroImage.displayName = 'ProfileHeroImage';
