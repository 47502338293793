import { memo, FC, useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAxios, useSubscription, useGetCompanyProfileQuery } from 'Hooks';
import {
	Card,
	LoadingSpinner,
	PaymentMethodCard,
	ShimmerImage,
	SubmitButton,
	ReactSwitch,
	FontAwesome,
	Button
} from 'Elements';
import {
	api,
	clx,
	currencyFormatter,
	formatLocaleDateTime,
	getDiscountFormat,
	paymentMethods,
	subtractDays
} from 'Utils';
import {
	PaymentMethodCard as IPaymentMethodCard,
	PaymentMethod,
	VoucherDiscountType,
	Colors
} from 'Types';
import styles from 'partials/payment/payment.module.scss';

interface Props {
	subscriptionId: string;
}

export const CheckoutPaymentSummary: FC<Props> = memo(({ subscriptionId }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { data: user } = useGetCompanyProfileQuery();
	const { sendRequest, error: errorVoucherCode } = useAxios();
	const {
		data: subscription,
		isLoading,
		refetch
	} = useSubscription(subscriptionId, {
		enabled: !!subscriptionId
	});
	const [active, setActive] = useState(false);
	const [voucherCodeLoading, setVoucherCodeLoading] = useState(false);
	const [voucherCode, setVoucherCode] = useState('');
	const [selectedMethod, setSelectedMethod] =
		useState<IPaymentMethodCard | null>(null);
	const { sendRequest: sendPaymentRequest, isLoading: isSubmittingPayment } =
		useAxios<string>();

	const taxPercent = subscription?.taxPercent ?? 0;
	const agent = subscription?.agent;
	const taxAmount = subscription?.taxAmount ?? 0;
	const discount = subscription?.discount;
	const discountAmount = subscription?.discountAmount ?? 0;
	const discountType = subscription?.applyVoucher?.discountType;
	const totalAmount = subscription?.totalAmount ?? 0;
	const defaultLogo = '/assets/img/agent-default.jpg';

	const handleSelectMethod = (method: IPaymentMethodCard) => {
		setSelectedMethod(method);
	};

	const handlePaymentResponse = (data?: string) => {
		if (data) {
			window.location.replace(data);
		}
	};

	const createPayment = async () => {
		await sendPaymentRequest(
			api.createPayment,
			{
				data: {
					token: 'token',
					companySubscriptionId: subscriptionId,
					paymentMethod: selectedMethod?.id
				}
			},
			handlePaymentResponse
		);
	};

	const createInvoice = async () => {
		await sendPaymentRequest(
			api.createPaymentByInvoice(subscriptionId),
			{
				method: 'PUT'
			},
			() => void navigate('/payment-invoice', { replace: true })
		);
	};

	const renewSubscription = async () => {
		const method = selectedMethod?.id;
		if (totalAmount !== 0) {
			if (method === PaymentMethod.online) {
				await createPayment();
			} else if (method === PaymentMethod.invoice) {
				await createInvoice();
			} else if (method === PaymentMethod.payPal) {
				await createPayment();
			} else if (method === PaymentMethod.sepa) {
				await createPayment();
			}
		} else {
			await createPayment();
		}
	};

	const handleClickVoucherCode = async (remove: boolean) => {
		const code = remove ? null : voucherCode;
		setVoucherCodeLoading(true);
		if (remove) {
			await sendRequest(
				api.updateVoucherCompany(subscription?.id || '', ''),
				{
					method: 'put'
				},
				() => {
					refetch();
				}
			);
			setVoucherCode('');
		} else {
			await sendRequest(
				api.validateVoucherCode,
				{
					method: 'post',
					data: {
						companyregionCode: user?.address?.postalCode?.substring(
							0,
							2
						),
						voucherCode: voucherCode,
						planCode: subscription?.plan?.code,
						agentId: {
							value: subscription?.agentId
						}
					}
				},
				async () => {
					await sendRequest(
						api.updateVoucherCompany(
							subscription?.id || '',
							voucherCode
						),
						{
							method: 'put'
						},
						() => {
							refetch();
						}
					);
				}
			);
		}
	};

	const handleCheckout = async () => {
		await renewSubscription();
	};

	useEffect(() => {
		setVoucherCode(subscription?.applyVoucher?.voucherCode || '');
		setActive(subscription?.applyVoucher?.voucherCode ? true : false);
		setVoucherCodeLoading(false);
	}, [subscription?.applyVoucher?.voucherCode]);

	useEffect(() => {
		if (errorVoucherCode) {
			setVoucherCodeLoading(false);
		}
	}, [errorVoucherCode]);

	return (
		<Card className="p-0 overflow-hidden">
			{isLoading && <LoadingSpinner className="mt-4" />}
			{!isLoading && (
				<Row className="g-0">
					<Col md={6}>
						<div
							className={clx(
								styles.box,
								styles.border_box,
								'h-100'
							)}>
							<div className="d-flex align-items-center gap-3 mb-4">
								<div className={styles.logoWrapper}>
									<ShimmerImage
										width={45}
										height={45}
										src={agent?.logo || defaultLogo}
										alt={agent?.name ?? ''}
										defaultImage={defaultLogo}
										className={styles.logo}
									/>
								</div>
								<div className="flex-grow-1 d-flex flex-column justify-content-center text-truncate">
									<h4 className="fs-base lh-base mb-0">
										{agent?.name ?? ''}
									</h4>
									{agent?.email && (
										<span className="text-gray-3 fs-small lh-md text-truncate">
											{agent.email}
										</span>
									)}
								</div>
							</div>
							<div className="fs-small mb-2 text-gray-3">
								{t('title.plan-info')}
							</div>
							<div className={styles.row}>
								<div className={styles.label}>
									{t('title.plan-name')}
								</div>
								<div className={styles.value}>
									{subscription?.title}
								</div>
							</div>
							<div className={styles.row}>
								<div className={styles.label}>
									{t('title.duration')}
								</div>
								<div className={styles.value}>
									{subscription?.plan?.duration}{' '}
									{t('label.days')}
								</div>
							</div>
							<div className={styles.row}>
								<div className={styles.label}>
									{t('title.plan-price.key')}
								</div>
								<div className={styles.value}>
									{currencyFormatter(
										subscription?.price ?? 0
									)}
								</div>
							</div>
							<div className={styles.row}>
								<div className={styles.label}>
									{t('title.expire-on')}
								</div>
								<div className={styles.value}>
									{subscription?.endDate
										? formatLocaleDateTime(
												subscription?.endDate,
												false
										  )
										: '---'}
								</div>
							</div>
							<div className="fs-small mt-4 text-gray-3">
								{t('title.invoice-info')}
							</div>
							<div className="mb-lg-3">
								<div className={styles.row}>
									<div className={styles.label}>
										{t('label.original-price')}
									</div>
									<div
										className={clx(
											styles.value,
											'd-flex flex-column text-end'
										)}>
										<span className="lh-base">
											{currencyFormatter(
												subscription?.price ?? 0
											)}
										</span>
										<span className="text-gray-3 fw-400 fs-small">
											{`${t('title.per')} ${
												subscription?.duration
											} ${t('title.days')}`}
										</span>
									</div>
								</div>
								<div className={styles.row}>
									<div className={styles.label}>
										{t('label.discount')}
										{discountType ===
											VoucherDiscountType.Percent && (
											<span className="text-gray-3 fs-small">
												(
												{getDiscountFormat(
													discount,
													discountType
												)}
												)
											</span>
										)}
									</div>
									<div className={styles.value}>
										{discountAmount && discountAmount > 0
											? `- ${currencyFormatter(
													discountAmount
											  )}`
											: currencyFormatter(0)}
									</div>
								</div>

								<div className={styles.row}>
									<div className={styles.label}>
										{t('label.subtotal')}
									</div>
									<div
										className={clx(
											styles.value,
											'd-flex flex-column text-end'
										)}>
										<span className="lh-base">
											{currencyFormatter(
												subscription?.subTotal ?? 0
											)}
										</span>
										<span className="text-gray-3 fw-400 fs-small">
											{`${t('title.per')} ${
												subscription?.duration
											} ${t('title.days')}`}
										</span>
									</div>
								</div>
								<div
									className={clx(
										styles.row,
										'border-0 border-bottom border-gray-2'
									)}>
									<div className="d-flex align-items-center">
										<div className={styles.label}>
											{t('label.tax')}
											<span className="text-gray-3 fs-small">
												({taxPercent}%)
											</span>
										</div>
									</div>
									<div className={styles.value}>
										{taxAmount > 0
											? `+ ${currencyFormatter(
													taxAmount
											  )}`
											: currencyFormatter(0)}
									</div>
								</div>
								<div className={styles.row}>
									<h5
										className={clx(
											styles.label,
											'h5 text-gray-5 mb-0'
										)}>
										{t('title.total')}:
									</h5>
									<div className={styles.value}>
										<div className="d-flex flex-column">
											<span className="fs-5 fw-700 text-gray-5 lh-base text-end">
												{currencyFormatter(totalAmount)}
											</span>
											{totalAmount !== 0 && (
												<span className="text-gray-3 lh-1 fw-400 fs-small text-end">
													{t('label.tax-included')}
												</span>
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</Col>
					<Col md={6}>
						<div className={clx(styles.box, 'h-100')}>
							<h6 className="fs-base mb-4 lh-base">
								{t('title.payment.choose-payment-below')}:
							</h6>
							<div className="d-flex gap-3 mb-4 flex-wrap">
								{paymentMethods.map((method) => (
									<PaymentMethodCard
										key={method.id}
										title={method.title}
										icon={method.icon}
										description={method.description}
										isSelected={
											totalAmount === 0
												? false
												: selectedMethod?.id ===
												  method.id
										}
										isDisabled={
											totalAmount === 0
												? true
												: isSubmittingPayment
												? isSubmittingPayment
												: false
										}
										onSelect={() =>
											handleSelectMethod(method)
										}
									/>
								))}
							</div>
							<div className="text-center">
								<p className="text-gray-3 fs-small lh-base mt-2">
									{t('text.payment.terms', {
										renewalDate: subscription?.endDate
											? formatLocaleDateTime(
													subtractDays(
														subscription.endDate,
														90
													),
													false
											  )
											: ''
									})}
								</p>
								<div className="mb-4 px-2">
									<div
										className={clx(
											styles.row,
											'border-0 gap-2'
										)}>
										<ReactSwitch
											id="test"
											size="sm"
											checked={active}
											onChange={() => {
												setActive(!active);
											}}
										/>
										<span className={styles.rowLabel}>
											{t('forms.add-voucher-code')}
										</span>
									</div>
									<div className="d-flex align-items-center mb-2">
										<FontAwesome
											icon="info-circle"
											size="1x"
											className="me-2"
										/>
										<span className="fs-small text-gray-3 ">
											{t(
												'title.by-activating-your-voucher-code-discount-will-be-applied-to-the-total-price'
											)}
										</span>
									</div>
									{active && (
										<div className="d-flex align-items-center gap-4">
											<input
												type="text"
												id="voucher-code"
												className="inputbox"
												value={voucherCode}
												disabled={
													subscription?.applyVoucher
														?.voucherCode
														? true
														: false
												}
												onChange={(e) => {
													setVoucherCode(
														e.target.value
													);
												}}
											/>
											<Button
												color={
													subscription?.applyVoucher
														?.voucherCode
														? Colors['white-red']
														: Colors[
																'white-primary'
														  ]
												}
												disabled={voucherCodeLoading}
												onClick={() => {
													handleClickVoucherCode(
														subscription
															?.applyVoucher
															?.voucherCode
															? true
															: false
													);
												}}>
												{subscription?.applyVoucher
													?.voucherCode
													? t('button.remove')
													: t(
															'button.submit-voucher-code'
													  )}
											</Button>
										</div>
									)}
								</div>
								<SubmitButton
									icon={false}
									label={
										totalAmount === 0
											? 'button.confirm'
											: selectedMethod?.title ??
											  'button.choose-method'
									}
									savingLabel="button.subscribing"
									className={styles.submit}
									isDisable={
										totalAmount === 0
											? false
											: !selectedMethod
									}
									isSubmitting={isSubmittingPayment}
									onClick={handleCheckout}
								/>
							</div>
						</div>
					</Col>
				</Row>
			)}
		</Card>
	);
});

CheckoutPaymentSummary.displayName = 'CheckoutPaymentSummary';
