import { FC, memo } from 'react';
import { clx } from 'Utils';
import styles from 'partials/shared/circle-check.module.scss';

interface Props {
	className?: string;
	size?: string;
}

export const CircleCheck: FC<Props> = memo(({ className, size }) => {
	return (
		<svg
			className={clx(styles.checkmark, className)}
			xmlns="http://www.w3.org/2000/svg"
			width={size}
			height={size}
			viewBox="0 0 64 64">
			<circle
				className={styles.circle}
				cx="32"
				cy="32"
				r="30"
				fill="none"
			/>
			<path
				className={styles.check}
				fill="none"
				d="M14.1 27.2l7.1 7.2 16.7-16.8"
			/>
		</svg>
	);
});

CircleCheck.displayName = 'CircleCheck';
