import { FC, memo } from 'react';
import { clx } from 'Utils';
import { Colors, SocialNetwork } from 'Types';
import { Button, FontAwesome } from 'Elements';
import styles from 'partials/homepage/social-item.module.scss';

interface Props {
	social: SocialNetwork;
	className?: string;
	color?: string;
	onRemove?: () => void;
}

type SocialItems =
	| 'twitter'
	| 'facebook'
	| 'linkedin'
	| 'instagram'
	| 'whatsapp'
	| 'youtube'
	| 'xing'
	| 'tiktok';

export const SocialItem: FC<Props> = memo(
	({ social, className, color, onRemove }) => {
		const getImageUrl = () => {
			const imageType: Record<SocialItems, any> = {
				twitter: 'twitter',
				facebook: 'facebook',
				linkedin: 'linkedin-in',
				instagram: 'instagram',
				whatsapp: 'whatsapp',
				youtube: 'youtube',
				xing: 'xing',
				tiktok: 'tiktok'
			};
			return imageType[social.type];
		};

		return (
			<div className={clx(styles.item, className)}>
				{onRemove && (
					<Button
						color={Colors.empty}
						className={styles.remove}
						onClick={onRemove}>
						<FontAwesome icon="trash-xmark" size="lg" />
					</Button>
				)}
				<FontAwesome
					set="fab"
					icon={getImageUrl()}
					size="lg"
					color={color}
				/>
			</div>
		);
	}
);

SocialItem.displayName = 'SocialItem';
