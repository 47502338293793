import { startTransition } from 'react';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import axios from 'Adapter';
import { useAxios } from 'Hooks';
import { queryKey } from 'Constants';
import { api, fetch, toastify } from 'Utils';
import {
	CompanyProfileFormData,
	CompanyProfileInitialData,
	CompanySetting,
	LinkedinConfig,
	SubscriptionAgent
} from 'Types';

const config = (options = {}) => {
	return {
		...options,
		staleTime: Infinity
	};
};

export const useCompanyProfile = <T = CompanyProfileInitialData>(
	options?: UseQueryOptions<T, any, CompanyProfileInitialData>
): UseQueryResult<CompanyProfileInitialData, unknown> => {
	return useQuery({
		queryKey: [queryKey.companyProfile],
		queryFn: () => fetch.companyProfile(),
		...config(options)
	});
};

export const useCompanyAgents = <T = SubscriptionAgent>(
	options?: UseQueryOptions<T, any, SubscriptionAgent>
): UseQueryResult<SubscriptionAgent, unknown> => {
	return useQuery({
		queryKey: [queryKey.companyAgents],
		queryFn: () => fetch.companyAgents(),
		...config(options)
	});
};

export const useUpdateCompany = (
	options?: UseQueryOptions<CompanyProfileInitialData>
) => {
	const query = useCompanyProfile(options);
	const company = query.data;
	const setting = company?.companySetting;
	const address = company?.address;
	const { sendRequest } = useAxios();

	const updateCompany: (
		fields: Partial<CompanyProfileFormData>,
		onFinal?: () => void,
		onCatch?: () => void
	) => Promise<void> = async (fields, onFinal, onCatch) => {
		const data = {
			...company,
			...fields
		};
		await axios
			.put(api.updateCompanyProfile, data)
			.then((res) => {
				if (res.status === 200) {
					query.refetch();
					startTransition(() => {
						toastify('toastify.company-updated', {
							type: 'success'
						});
					});
					onFinal?.();
				}
			})
			.catch(() => onCatch?.());
	};

	const updateCompanySetting: (
		field: Partial<CompanySetting>,
		onFinal?: () => void
	) => Promise<void> = async (field, onFinal) => {
		const data = {
			companySetting: {
				...setting,
				...field
			}
		};
		await sendRequest(
			api.updateCompanySetting,
			{ data, method: 'PATCH' },
			() => {
				query.refetch();
				startTransition(() => {
					toastify('toastify.company-updated', {
						type: 'success'
					});
				});
				onFinal?.();
			}
		);
	};

	const updateCompanyLinkedin: (
		field: LinkedinConfig,
		// returnUrl?: string,
		onFinal?: () => void
	) => Promise<void> = async (field, onFinal) => {
		await sendRequest(
			api.updateCompanyLinkedinConfig,
			{ data: field, method: 'PATCH' },
			() => {
				onFinal?.();
			}
		);
		// await axios
		// 	.patch(api.updateCompanyLinkedinConfig, field)
		// 	.then(async (res) => {
		// 		if (res.status === 200) {
		// 			await getLinkedinToken(() => {
		// 			});
		// 		}
		// 	});
	};

	return {
		...query,
		company,
		setting,
		address,
		updateCompany,
		updateCompanySetting,
		updateCompanyLinkedin
	};
};
