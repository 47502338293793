import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ColorValues, Colors, ModalProps } from 'Types';
import {
	Button,
	ModalBox,
	ModalBoxFooter,
	SubmitButton,
	FontAwesome
} from 'Elements';

interface Props extends ModalProps {
	title: string;
	onCancel: () => void;
	isCanceling: boolean;
}

export const ModalBoxCancelEvent: FC<Props> = memo(
	({ title, isOpen, isCanceling, onCancel, onClose }) => {
		const { t } = useTranslation();
		return (
			<ModalBox isOpen={isOpen} onClose={onClose}>
				<header className="d-flex flex-column align-items-center text-center">
					<FontAwesome
						icon="triangle-exclamation"
						size="8x"
						color={ColorValues.red}
						className="mb-3"
					/>
					<p>{t('text.are-you-sure-to-cancel')}</p>
					<h6 className="h6 lh-base">{title}</h6>
					<p className="text-gray-3 mt-2">
						{t('text.this-action-can-not-be-undone')}
					</p>
				</header>
				<ModalBoxFooter className="justify-content-between pt-3">
					<Button color={Colors['white-gray']} onClick={onClose}>
						{t('button.no-thanks')}
					</Button>
					<SubmitButton
						type="button"
						icon={false}
						color={Colors['white-red']}
						label="button.cancel-event"
						savingLabel="button.canceling"
						isSubmitting={isCanceling}
						onClick={onCancel}
					/>
				</ModalBoxFooter>
			</ModalBox>
		);
	}
);

ModalBoxCancelEvent.displayName = 'ModalBoxCancelEvent';
