import { configureStore } from '@reduxjs/toolkit';
import { UserApi } from 'Services';
import {
	SettingReducer,
	SidebarReducer,
	MarketingReducer,
	SubscriptionReducer
} from 'Slices';

const reducers = {
	setting: SettingReducer,
	sidebar: SidebarReducer,
	marketing: MarketingReducer,
	subscription: SubscriptionReducer
};

export const store = configureStore({
	reducer: {
		...reducers,
		[UserApi.reducerPath]: UserApi.reducer
	},
	middleware: (getDefaultMiddleware) => {
		return getDefaultMiddleware().concat(UserApi.middleware);
	}
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
