import { FC, memo } from 'react';
import { clx } from 'Utils';
import { IntegrationPartnerJob } from 'Types';
import styles from 'partials/marketing/integration-job.module.scss';

interface Props {
	job: IntegrationPartnerJob;
}

export const IntegrationJobCard: FC<Props> = memo(({ job }) => {
	const encodedURI = job.logo?.replace(/\s+/g, '%20');
	return (
		<article className={styles.card}>
			<header className={clx(styles.header, 'flex-md-row')}>
				<div className="d-flex align-items-center w-100 w-sm-auto">
					<div className={styles.imageBox}>
						<a href={job.jobUrl} rel="noreferrer" target="_blank">
							<img
								src={encodedURI}
								alt={job.name}
								className={styles.image}
							/>
						</a>
					</div>
					<div className={styles.mobile_company}>
						<h6
							className="mb-0 fs-sm text-truncate flex-grow-1"
							title={job.name}>
							{job.name}
						</h6>
					</div>
				</div>
				<div className={styles.content}>
					<h3 className={styles.title}>
						<a href={job.jobUrl} target="_blank" rel="noreferrer">
							{job.title}
						</a>
					</h3>
					<div className="d-flex align-items-center mt-auto pt-2">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="me-1 flex-shrink-0"
							width="18"
							height="18"
							viewBox="0 0 24 24"
							stroke="currentColor"
							strokeWidth="1"
							fill="none"
							strokeLinecap="round"
							strokeLinejoin="round">
							<path
								stroke="none"
								d="M0 0h24v24H0z"
								fill="none"></path>
							<circle cx="12" cy="11" r="3"></circle>
							<path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z"></path>
						</svg>
						<span className={styles.location}>
							{job.address.postalCode} {job.address.city}
						</span>
					</div>
				</div>
			</header>
			<div
				className={clx(
					styles.footer,
					'd-none d-md-flex align-items-center'
				)}>
				<h6
					className="mb-0 fw-400 text-truncate fs-sm flex-grow-1"
					title={job.name}>
					{job.name}
				</h6>
			</div>
		</article>
	);
});

IntegrationJobCard.displayName = 'IntegrationJobCard';
