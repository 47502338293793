import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalProps } from 'Types';
import { Button, ModalBox, ModalBoxFooter } from 'Elements';

interface Props extends ModalProps {
	downloadLink: string;
}

export const ModalBoxDownloadFile: FC<Props> = memo(
	({ onClose, isOpen, downloadLink }) => {
		const { t } = useTranslation();

		const openDownloadLink = () => {
			window.open(downloadLink, '_blank', 'noopener,noreferrer');
			onClose();
		};
		return (
			<ModalBox
				isOpen={isOpen}
				onClose={onClose}
				title="title.marketing.download-confirm">
				<div className="d-flex flex-column align-items-center gap-3 p-3">
					<p className="text-gray-3 text-center mb-0 lh-md">
						{t('text.marketing.download-link')}
					</p>
					<ModalBoxFooter className="justify-content-center">
						<Button
							label={
								t(
									'button.marketing.click-to-download'
								) as string
							}
							onClick={openDownloadLink}
						/>
					</ModalBoxFooter>
					{/* <a
						href={downloadLink}
						target="_blank"
						rel="noreferrer"
						className={clx('text-primary fw-500')}>
						Click to download
					</a> */}
				</div>
			</ModalBox>
		);
	}
);

ModalBoxDownloadFile.displayName = 'ModalBoxDownloadFile';
