import { memo, createElement, forwardRef } from 'react';
import { parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { UseControllerProps } from 'react-hook-form';
import { FieldValues } from 'Types';
import { dateFormat } from 'Utils';
import { CustomDateTimeInput, DatePickerController } from 'Elements';

type Props<T extends FieldValues> = {
	defaultSelected: string | Date;
	onDateChange?: (val: Date) => void;
	[x: string]: any;
} & UseControllerProps<T>;

export const EventDatePicker = <T extends FieldValues>({
	control,
	name,
	error,
	defaultSelected,
	onDateChange,
	...datePickerProps
}: Props<T>): JSX.Element => {
	const { t } = useTranslation();
	return (
		<DatePickerController
			name={name}
			control={control}
			error={error}
			showTimeSelect
			timeCaption={t('label.time.key')}
			timeFormat="HH:mm"
			minDate={new Date()}
			timeIntervals={15}
			placeholder={t('forms.select-date')}
			selected={parseISO(defaultSelected?.toString()) ?? ''}
			customInput={createElement(memo(forwardRef(CustomDateTimeInput)))}
			dateFormat={dateFormat()}
			onDateChange={onDateChange}
			{...datePickerProps}
		/>
	);
};

EventDatePicker.displayName = 'EventDatePicker';
