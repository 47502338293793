import { FC, memo } from 'react';
import { clx } from 'Utils';
import { IntegrationPartner } from 'Types';
import styles from 'partials/marketing/integration-partner.module.scss';

interface Props {
	partner: IntegrationPartner;
	showLogo: boolean;
	className?: string;
}

export const IntegrationPartnerCard: FC<Props> = memo(
	({ partner, className, showLogo }) => {
		const encodedURI = partner?.companySetting?.logo?.replace(
			/\s+/g,
			'%20'
		);
		return (
			<div className={clx(styles.card, className)}>
				<a
					rel="noreferrer"
					href={partner.webUrl || '#'}
					className={styles.card_logo_wrapper}
					target="_blank">
					<img
						src={encodedURI}
						alt={partner.companyName}
						className={styles.card_logo}
					/>
				</a>
				{!showLogo && (
					<div className="position-relative d-flex flex-column align-items-center text-center px-2 pb-4 gap-2 w-100 mt-auto">
						<h4
							className={clx(
								styles.title,
								'fs-6 fw-bold lh-base mb-0'
							)}>
							<a
								rel="noreferrer"
								target="_blank"
								href={partner.webUrl}
								className="d-block">
								{partner.companyName}
							</a>
						</h4>
						{partner.companySetting?.branch && (
							<span className="fw-light">
								{partner.companySetting.branch}
							</span>
						)}
					</div>
				)}
			</div>
		);
	}
);

IntegrationPartnerCard.displayName = 'IntegrationPartnerCard';
