import { useTranslation } from 'react-i18next';
import { SingleValue } from 'react-select';
import { Controller, UseControllerProps } from 'react-hook-form';
import { clx } from 'Utils';
import { ReactSelect } from 'Elements';
import { FieldValues, SelectOption } from 'Types';

type Props<T extends FieldValues> = {
	options: SelectOption[];
	className?: string;
	error?: string;
	placeholder?: string;
	onSelectChange?: (...val: SingleValue<SelectOption>[]) => void;
	[x: string]: any;
} & UseControllerProps<T>;

export const SelectController = <T extends FieldValues>({
	control,
	name,
	error,
	options,
	className,
	placeholder = 'placeholder.select',
	defaultValue,
	onSelectChange,
	...selectProps
}: Props<T>): JSX.Element => {
	const { t } = useTranslation();
	return (
		<div className={className}>
			<Controller
				name={name}
				control={control}
				render={({ field: { onChange, value = defaultValue } }) => (
					<ReactSelect
						{...selectProps}
						className={clx(!!error && 'react-select--has-error')}
						value={value}
						options={options}
						aria-invalid={!!error}
						placeholder={t(placeholder)}
						onChange={(val: SingleValue<SelectOption>) => {
							onChange(val);
							onSelectChange?.(val);
						}}
					/>
				)}
			/>
			{error && <div className="invalid-feedback d-block">{error}</div>}
		</div>
	);
};

SelectController.displayName = 'SelectController';
