import { memo, FC, PropsWithChildren, HTMLAttributes } from 'react';
import { clx } from 'Utils';
import { Colors } from 'Types';
import styles from 'partials/shared/badge.module.scss';

type Props = {
	color: Colors;
	className?: string;
} & PropsWithChildren &
	HTMLAttributes<HTMLSpanElement>;

export const Badge: FC<Props> = memo(
	({ className, color, children, ...props }) => {
		return (
			<span
				className={clx(styles.badge, styles[color], className)}
				{...props}>
				{children}
			</span>
		);
	}
);

Badge.displayName = 'Badge';
