import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { AgentPlan, AspectRatio, Media } from 'Types';
import {
	CoverController,
	FeatureVideo,
	FormInlineTip,
	RichTextController
} from 'Elements';

export const AgentPlanPriceInfo: FC = memo(() => {
	const { t } = useTranslation();
	const fields = useOutletContext<AgentPlan>();
	const {
		watch,
		control,
		register,
		setValue,
		formState: { errors }
	} = useFormContext<AgentPlan>();
	const watchVideo = watch('videoUrl') ?? fields?.videoUrl ?? '';
	const watchCoverPhoto = {
		url: watch('coverPhotoUrl') ?? fields?.coverPhotoUrl
	};

	const handleImageChange = (image_data: Partial<Media>) => {
		setValue('coverPhotoUrl', image_data.url ?? '', { shouldDirty: true });
	};

	const handleVideoChange = (value: string) => {
		setValue('videoUrl', value, { shouldDirty: true });
	};

	return (
		<Row>
			<Col lg={6}>
				<FormGroup>
					<Label htmlFor="termsAndConditionLink">
						{t('forms.plan-price.link-to-agb')}
						<small className="ms-1">
							({t('validation.required')})
						</small>
					</Label>
					<input
						{...register('termsAndConditionLink')}
						type="url"
						inputMode="url"
						id="termsAndConditionLink"
						className="inputbox w-100"
					/>
					{errors.termsAndConditionLink && (
						<div className="invalid-feedback d-block">
							{errors.termsAndConditionLink.message?.toString()}
						</div>
					)}
				</FormGroup>
				<FormGroup>
					<Label htmlFor="description">
						{t('forms.plan-price.desc')}
					</Label>
					<RichTextController
						control={control}
						name="description"
						defaultValue={fields?.description}
					/>
				</FormGroup>
				<FormGroup>
					<Label htmlFor="videoUrl">
						{t('forms.plan-price.video')}
					</Label>
					<FeatureVideo
						videoUrl={watchVideo}
						onRemove={() => handleVideoChange('')}
						onUpload={(url) => handleVideoChange(url)}
					/>
				</FormGroup>
				<FormGroup>
					<Label>{t('forms.plan-price.cover-photo')}</Label>
					<FormInlineTip tip="text.plan-price.cover-photo" className='mt-1' />
					<CoverController
						name="coverPhotoUrl"
						aspectRatio={AspectRatio['16/9']}
						image={watchCoverPhoto}
						onRemove={handleImageChange}
						onUpload={handleImageChange}
					/>
				</FormGroup>
			</Col>
		</Row>
	);
});

AgentPlanPriceInfo.displayName = 'AgentPlanPriceInfo';
