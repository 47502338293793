import { memo, FC, ReactNode, HTMLAttributes } from 'react';
import { clx } from 'Utils';
import { ResponsiveSize } from 'Types';

interface Props extends HTMLAttributes<HTMLOrSVGElement> {
	children: ReactNode;
	className?: string;
	tab?: boolean;
	size?: ResponsiveSize;
	background?:
		| 'white'
		| 'yellow'
		| 'green'
		| 'red'
		| 'gray-1'
		| 'gray-2'
		| 'gray-3'
		| 'gray-4'
		| 'gray-5'
		| 'primary'
		| 'green_o-2'
		| 'primary-light'
		| 'primary-dark'
		| 'secondary'
		| 'secondary-light'
		| 'secondary-dark'
		| 'tertiary'
		| 'tertiary_o-1'
		| 'tertiary-light'
		| 'tertiary-dark'
		| 'warning'
		| 'success'
		| 'error'
		| 'muted';
	tag?: keyof JSX.IntrinsicElements;
}

export const Card: FC<Props> = memo(
	({
		children,
		className,
		background,
		tab,
		size = 'md',
		tag: Element = 'div',
		...props
	}) => {
		return (
			<Element
				className={clx(
					'card-box',
					`card-box--${size}`,
					className,
					background && `bg-${background}`,
					tab && 'card-box--has-tab'
				)}
				{...props}>
				{children}
			</Element>
		);
	}
);

Card.displayName = 'Card';
