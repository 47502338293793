import { memo, FC, ChangeEvent, useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Label } from 'reactstrap';
import { clx, config } from 'Utils';
import {
	useAgentProfile,
	useCompanyProfile,
	useDispatch,
	useGetB2bUserProfileQuery,
	useSelector
} from 'Hooks';
import {
	AssetConfigInputType,
	AssetConfigType,
	HeadlineElement,
	ImageElement,
	Media,
	SelectOption,
	SizeElement,
	ColorElement
} from 'Types';
import {
	clearFieldValidationError,
	setInputAssetData,
	setBackgroundImage,
	setHeadline,
	setShowLogo,
	setLogo,
	setSize,
	setTextBgColor,
	setTextColor
} from 'Actions';
import {
	CoverController,
	RadioGroup,
	ReactSelect,
	ReactSwitch,
	ToolboxConfigGroup,
	ImageELementList,
	ColorElementList,
	HeadlineElementList,
	TextElement
} from 'Elements';
import styles from 'partials/marketing/toolbox-config.module.scss';
import { SingleValue } from 'react-select';

export const ToolboxConfigurator: FC = memo(() => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [state, setState] = useState({
		customTextColor: '#f2f2f4',
		customTextBgColor: '#403e50',
		showQrInput: false
	});
	const { assetConfig, selectedTemplate, errors, showLogo } = useSelector(
		(state) => state.marketing
	);
	const { data: user, isLoading } = useGetB2bUserProfileQuery();
	const userType = user?.userCategory?.toLowerCase();

	const { data: agentProfile } = useAgentProfile({
		enabled: !isLoading && userType === 'agent'
	});

	const { data: companyProfile } = useCompanyProfile({
		enabled: !isLoading && userType === 'company'
	});

	const profileId =
		userType === 'agent' ? agentProfile?.id : companyProfile?.id;

	const profileSlug =
		userType === 'agent'
			? agentProfile?.slugUrl ?? ''
			: companyProfile?.slugUrl ?? '';

	const required_validation = 'validation.required';
	const public_profile_url = `${config.publicUrl}/${userType}/${profileId}/${profileSlug}`;
	const public_job_section = `${config.publicUrl}/${userType}/${profileId}/${profileSlug}#${userType}-job`;

	const qrCodeOptions: SelectOption[] = [
		{ label: t('label.marketing.job-section'), value: public_job_section },
		{ label: t('label.marketing.public-page'), value: public_profile_url },
		{ label: t('label.marketing.custom-url'), value: 0 }
	];

	const handleSizeChange = (value: string | number) => {
		const selectedSize = selectedTemplate?.sizeElement.inputItems.find(
			(size) => size.value === value.toString()
		);
		if (selectedSize) dispatch(setSize(selectedSize));
		dispatch(clearFieldValidationError(AssetConfigType.sizeElement));
	};

	const handleTextColorChange = (value: ColorElement) => {
		dispatch(setTextColor(value));
		dispatch(clearFieldValidationError(AssetConfigType.textColorElement));
	};

	const handleTextBgColorChange = (value: ColorElement) => {
		dispatch(setTextBgColor(value));
		dispatch(clearFieldValidationError(AssetConfigType.textBgColorElement));
	};

	const handleCustomTextColorChange = (color: string) => {
		dispatch(setTextColor({ hex: color, name: color, isDefault: false }));
		dispatch(clearFieldValidationError(AssetConfigType.textColorElement));
	};

	const handleCustomTextBgColorChange = (color: string) => {
		dispatch(setTextBgColor({ hex: color, name: color, isDefault: false }));
		dispatch(clearFieldValidationError(AssetConfigType.textBgColorElement));
	};

	const handleNoTextColor = () => {
		dispatch(setTextColor({ hex: '', name: '', isDefault: false }));
		dispatch(clearFieldValidationError(AssetConfigType.textColorElement));
	};

	const handleNoTextBgColor = () => {
		dispatch(setTextBgColor({ hex: '', name: '', isDefault: false }));
		dispatch(clearFieldValidationError(AssetConfigType.textBgColorElement));
	};

	const handleHeadlineChange = useCallback(
		(item: HeadlineElement) => {
			dispatch(
				setHeadline({
					value: item.value,
					label: item.label,
					isDefault: item.isDefault
				})
			);
			dispatch(
				clearFieldValidationError(AssetConfigType.headlineElement)
			);
		},
		[dispatch]
	);

	const handleQrCodeChange = useCallback(
		(selectedItem: SingleValue<SelectOption>) => {
			// If select custom URL
			if (selectedItem?.value === 0) {
				setState((prev) => ({
					...prev,
					showQrInput: true
				}));
				dispatch(
					setInputAssetData({
						name: AssetConfigType.qrCodeElement,
						value: ''
					})
				);
			} else {
				setState((prev) => ({
					...prev,
					showQrInput: false
				}));
				dispatch(
					setInputAssetData({
						name: AssetConfigType.qrCodeElement,
						value: selectedItem?.value.toString() ?? ''
					})
				);
				dispatch(
					clearFieldValidationError(AssetConfigType.qrCodeElement)
				);
			}
		},
		[dispatch]
	);

	const handleSwitchLogoChange = (val: boolean) => {
		dispatch(setShowLogo(val));
	};

	const handleImageSelect = (image?: ImageElement) => {
		if (image) {
			dispatch(setBackgroundImage(image));
			dispatch(clearFieldValidationError(AssetConfigType.imageElement));
		} else {
			dispatch(
				setBackgroundImage({
					thumbnail: '',
					original: '',
					isDefault: false
				})
			);
		}
	};

	const handleInput = useCallback(
		(target: { name: string; value: string }) => {
			const { name, value } = target;
			dispatch(
				setInputAssetData({ name: name as AssetConfigInputType, value })
			);
			dispatch(clearFieldValidationError(AssetConfigType[name]));
		},
		[dispatch]
	);

	const handleUploadLogo = (image_data: Media) => {
		dispatch(setLogo(image_data.url));
	};

	useEffect(() => {
		setState((prev) => ({
			...prev,
			showQrInput: false
		}));
	}, [selectedTemplate]);

	return (
		<Col md={10}>
			{selectedTemplate?.sizeElement && (
				<ToolboxConfigGroup
					title={selectedTemplate?.sizeElement.title}
					isRequired={selectedTemplate?.sizeElement.isRequired}
					errors={errors.sizeElement}>
					<RadioGroup
						name="size"
						boxClassName={clx(styles.imageGrid, 'mt-3')}
						options={
							selectedTemplate?.sizeElement
								.inputItems as SizeElement[]
						}
						defaultSelected={assetConfig.sizeElement?.value ?? ''}
						onChange={handleSizeChange}
					/>
				</ToolboxConfigGroup>
			)}
			{selectedTemplate?.headlineElement && (
				<HeadlineElementList
					element={selectedTemplate?.headlineElement}
					errors={errors.headlineElement}
					defaultValue={assetConfig.headlineElement}
					onChange={handleHeadlineChange}
				/>
			)}
			{selectedTemplate?.textColorElement && (
				<ColorElementList
					name="textColor"
					sectionTitle={selectedTemplate.textColorElement.title}
					element={selectedTemplate?.textColorElement}
					defaultColor={assetConfig.textColorElement?.hex}
					errors={errors.textColorElement}
					onSelect={handleTextColorChange}
					onSelectCustom={handleCustomTextColorChange}
					onSelectNoColor={handleNoTextColor}
				/>
			)}
			{selectedTemplate?.textBgColorElement && (
				<ColorElementList
					name="textBgColor"
					sectionTitle={selectedTemplate.textBgColorElement.title}
					element={selectedTemplate?.textBgColorElement}
					defaultColor={assetConfig.textBgColorElement?.hex}
					errors={errors.textBgColorElement}
					onSelect={handleTextBgColorChange}
					onSelectCustom={handleCustomTextBgColorChange}
					onSelectNoColor={handleNoTextBgColor}
				/>
			)}
			{selectedTemplate?.imageElement && (
				<ImageELementList
					element={selectedTemplate?.imageElement}
					errors={errors.imageElement}
					isSelected={assetConfig.imageElement?.thumbnail === ''}
					onSelect={handleImageSelect}
				/>
			)}
			{selectedTemplate?.nameElement && (
				<TextElement
					name="nameElement"
					label={selectedTemplate.nameElement.title}
					element={selectedTemplate?.nameElement}
					defaultValue={assetConfig.nameElement ?? ''}
					errors={errors.nameElement}
					onChange={(e: ChangeEvent<HTMLInputElement>) =>
						handleInput(e.target)
					}
				/>
			)}
			{selectedTemplate?.emailElement && (
				<TextElement
					name="emailElement"
					label={selectedTemplate.emailElement.title}
					type="email"
					element={selectedTemplate?.emailElement}
					defaultValue={assetConfig.emailElement ?? ''}
					errors={errors.emailElement}
					onChange={(e: ChangeEvent<HTMLInputElement>) =>
						handleInput(e.target)
					}
				/>
			)}
			{selectedTemplate?.phoneElement && (
				<TextElement
					name="phoneElement"
					label={selectedTemplate.phoneElement.title}
					element={selectedTemplate?.phoneElement}
					defaultValue={assetConfig.phoneElement ?? ''}
					type="tel"
					inputMode="numeric"
					errors={errors.phoneElement}
					onChange={(e: ChangeEvent<HTMLInputElement>) =>
						handleInput(e.target)
					}
				/>
			)}
			{selectedTemplate?.qrCodeElement && (
				<FormGroup>
					<Label htmlFor="qrcode">
						{selectedTemplate.qrCodeElement.title}
						{selectedTemplate?.qrCodeElement.isRequired && (
							<small className="ms-1">
								({t(required_validation)})
							</small>
						)}
					</Label>
					<ReactSelect
						value={
							qrCodeOptions.find(
								(qrCode) =>
									qrCode.value === assetConfig.qrCodeElement
							) ?? null
						}
						options={qrCodeOptions}
						onChange={handleQrCodeChange}
					/>
					{state.showQrInput && (
						<input
							type="text"
							name="qrCodeElement"
							className="inputbox w-100 mt-3"
							onInput={(e: ChangeEvent<HTMLInputElement>) =>
								handleInput(e.target)
							}
						/>
					)}
					{errors.qrCodeElement && (
						<div className="invalid-feedback d-block">
							{errors.qrCodeElement}
						</div>
					)}
				</FormGroup>
			)}
			{selectedTemplate?.simpleLogoElement && (
				<FormGroup className="d-flex flex-column pt-3">
					<Label>
						{selectedTemplate.simpleLogoElement.title}
						{selectedTemplate?.simpleLogoElement.isRequired && (
							<small className="ms-1">
								({t(required_validation)})
							</small>
						)}
					</Label>
					{!selectedTemplate?.simpleLogoElement.isRequired && (
						<div className="d-flex align-items-center mb-3">
							<ReactSwitch
								id="showLogo"
								checked={showLogo}
								onChange={handleSwitchLogoChange}
							/>
							<Label
								htmlFor="showLogo"
								className="ps-2 mb-0 pb-0 pt-0 cursor-pointer">
								{t('label.marketing.show-logo')}
							</Label>
						</div>
					)}
					{showLogo && (
						<CoverController
							image={{
								url: assetConfig.simpleLogoElement ?? '',
								thumbnailUrl:
									assetConfig.simpleLogoElement ?? ''
							}}
							className="w-100 w-xl-70"
							uploadLabel="button.upload-new-logo"
							onUpload={handleUploadLogo}
						/>
					)}
					{errors.simpleLogoElement && (
						<div className="invalid-feedback d-block">
							{errors.simpleLogoElement}
						</div>
					)}
				</FormGroup>
			)}
		</Col>
	);
});

ToolboxConfigurator.displayName = 'ToolboxConfigurator';
