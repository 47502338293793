import { FC, memo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Container, Row } from 'reactstrap';
import { AgentSetting } from 'Types';
import { useTheme, useUpdateAgent } from 'Hooks';
import {
	ProfileStatisticCard,
	LoadingSpinner,
	SectionSwitcher
} from 'Elements';

export const AgentStatisticSection: FC = memo(() => {
	const { theme } = useTheme();
	const { control, reset } = useForm();
	const { setting, updateAgentSetting, isLoading } = useUpdateAgent();

	const handleUpdate = async (field: Partial<AgentSetting>) => {
		await updateAgentSetting(field);
	};

	useEffect(() => {
		reset(setting);
	}, [reset, setting]);

	return (
		<section className="position-relative mb-5 pb-5">
			{isLoading && (
				<div className="d-flex align-items-center justify-content-center my-4 py-4">
					<LoadingSpinner />
				</div>
			)}
			{!isLoading && setting && (
				<Container>
					<SectionSwitcher
						control={control}
						name="statisticsSectionStatus"
						label="forms.show-statistic-in-home"
						isActive={!setting?.statisticsSectionStatus}
						onSwitchChange={(val) =>
							handleUpdate({
								statisticsSectionStatus: val
							})
						}
					/>
					<Row className="pt-3 justify-content-center">
						<Col sm={4} className="mb-4 mb-md-0">
							<ProfileStatisticCard
								icon="calendar"
								fill={theme.agent.primaryColor}
								title="label.established-date"
								desc={setting?.establishedDate}
								onUpdate={(val) =>
									handleUpdate({ establishedDate: val })
								}
							/>
						</Col>
						<Col sm={4} className="mb-4 mb-md-0">
							<ProfileStatisticCard
								icon="users"
								fill={theme.agent.primaryColor}
								title="label.members"
								desc={setting?.memberNumber}
								onUpdate={(val) =>
									handleUpdate({ memberNumber: val })
								}
							/>
						</Col>
						<Col sm={4} className="mb-4 mb-md-0">
							<ProfileStatisticCard
								icon="building"
								fill={theme.agent.primaryColor}
								title="label.departments"
								desc={setting?.departments}
								onUpdate={(val) =>
									handleUpdate({ departments: val })
								}
							/>
						</Col>
					</Row>
				</Container>
			)}
			{!isLoading && !setting?.statisticsSectionStatus && (
				<div className="overlay overlay_white overlay-box" />
			)}
		</section>
	);
});

AgentStatisticSection.displayName = 'AgentStatisticSection';
