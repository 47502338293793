import { PayloadAction } from '@reduxjs/toolkit';
import {
	AssetConfigInputType,
	HeadlineElement,
	ImageElement,
	MarketingAsset,
	MarketingAssetConfig,
	MarketingState,
	SizeElement,
	ColorElement
} from 'Types';

const reducers = {
	setSelectedAsset(
		state: MarketingState,
		action: PayloadAction<MarketingAsset>
	) {
		const { payload } = action;
		state.selectedAsset = payload;
	},
	clearAssetConfig(state: MarketingState) {
		state.hasErrors = false;
		state.assetConfig = {
			sizeElement: null,
			textColorElement: null,
			textBgColorElement: null,
			imageElement: null,
			headlineElement: null,
			qrCodeElement: null,
			simpleLogoElement: null,
			nameElement: null,
			emailElement: null,
			phoneElement: null
		};
	},
	setSelectedTemplate(
		state: MarketingState,
		action: PayloadAction<MarketingAssetConfig | null>
	) {
		const { payload } = action;
		state.selectedTemplate = payload;
	},
	setInputAssetData: (
		state: MarketingState,
		action: PayloadAction<{
			name: AssetConfigInputType;
			value: string;
		}>
	) => {
		const {
			payload: { name, value }
		} = action;
		state.assetConfig[name] = value;
	},
	setSize(state: MarketingState, action: PayloadAction<SizeElement>) {
		const { payload } = action;
		state.assetConfig.sizeElement = {
			...payload,
			width: +payload.width,
			height: +payload.height
		};
	},
	setBackgroundImage(
		state: MarketingState,
		action: PayloadAction<ImageElement>
	) {
		const { payload } = action;
		state.assetConfig.imageElement = payload;
	},
	setTextColor(state: MarketingState, action: PayloadAction<ColorElement>) {
		const { payload } = action;
		state.assetConfig.textColorElement = payload;
	},
	setTextBgColor(state: MarketingState, action: PayloadAction<ColorElement>) {
		const { payload } = action;
		state.assetConfig.textBgColorElement = payload;
	},
	setHeadline(state: MarketingState, action: PayloadAction<HeadlineElement>) {
		const { payload } = action;
		state.assetConfig.headlineElement = payload;
	},
	setLogo(state: MarketingState, action: PayloadAction<string>) {
		const { payload } = action;
		state.assetConfig.simpleLogoElement = payload;
	},
	setOriginalLogo(state: MarketingState, action: PayloadAction<string>) {
		const { payload } = action;
		state.originalLogo = payload;
	},
	setShowLogo(state: MarketingState, action: PayloadAction<boolean>) {
		const { payload } = action;
		state.showLogo = payload;
	},
	setFieldValidationError: (
		state: MarketingState,
		action: PayloadAction<{ field: string; error: string }>
	) => {
		const { field, error } = action.payload;
		state.errors[field] = error;
		state.hasErrors = true;
	},
	clearFieldValidationError: (
		state: MarketingState,
		action: PayloadAction<string>
	) => {
		const { payload } = action;
		delete state.errors[payload];
	},
	clearFieldValidationErrors: (state: MarketingState) => {
		state.hasErrors = false;
		state.errors = {
			sizeElement: null,
			textColorElement: null,
			textBgColorElement: null,
			imageElement: null,
			headlineElement: null,
			qrCodeElement: null,
			simpleLogoElement: null,
			nameElement: null,
			emailElement: null,
			phoneElement: null
		};
	}
};

export default reducers;
