import { FC, memo, startTransition } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { clx } from 'Utils';
import { Colors } from 'Types';
import { Button, CircleCheck } from 'Elements';

interface Props {
	successTitle: string;
	addButtonTitle?: string;
	listTitle?: string;
	listUrl: string;
	className?: string;
	fetcher?: () => void;
	onClickAdd?: () => void;
}

export const FormSuccess: FC<Props> = memo(
	({
		successTitle,
		addButtonTitle,
		listTitle,
		listUrl,
		className,
		onClickAdd,
		fetcher
	}) => {
		const navigate = useNavigate();
		const { t } = useTranslation();
		const handleNavigate = () => {
			fetcher?.();
			startTransition(() => {
				navigate(listUrl);
			});
		};
		return (
			<div className={clx('text-center m-3 m-lg-5 py-md-5', className)}>
				<div className="d-flex align-items-center flex-column pb-4">
					<CircleCheck size="90" className="text-primary mb-3" />
					<h2 className="mb-2 pb-0 text-primary font-weight-semibold">
						<span>{t(successTitle)}</span>
					</h2>
				</div>
				<div className="d-flex align-items-center justify-content-center flex-wrap">
					{listTitle && (
						<Button
							color={Colors['white-secondary']}
							className="mx-1 mt-3"
							onClick={handleNavigate}>
							{t(listTitle)}
						</Button>
					)}
					{addButtonTitle && (
						<Button
							color={Colors.secondary}
							className="mx-1 mt-3"
							onClick={onClickAdd}>
							{t(addButtonTitle)}
						</Button>
					)}
				</div>
			</div>
		);
	}
);

FormSuccess.displayName = 'FormSuccess';
