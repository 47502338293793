import { useEffect } from 'react';

export const ScrollTop = () => {
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});
		// eslint-disable-next-line
	}, [window.location.href]);

	return null;
};
