import { memo, FC } from 'react';
import Switch, { SwitchProps } from 'rc-switch';
import { clx } from 'Utils';
import 'rc-switch/assets/index.css';

type Props = {
	size?: 'sm' | 'md';
} & SwitchProps;

export const ReactSwitch: FC<Props> = memo(
	({ size = 'sm', ...switchProps }) => {
		return (
			<Switch
				{...switchProps}
				className={clx(
					`custom-switch custom-switch-primary custom-switch-${size} flex-shrink-0`,
					switchProps.className
				)}
			/>
		);
	}
);

ReactSwitch.displayName = 'ReactSwitch';
