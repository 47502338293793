import { ReactNode } from 'react';
import { Controller, UseControllerProps } from 'react-hook-form';
import { clx } from 'Utils';
import { FontAwesome } from 'Elements';
import { FieldValues, XOR } from 'Types';

type SharedProps<T extends FieldValues> = {
	id: string;
	name: string;
	className?: string;
	onInputChange?: (status: boolean) => void;
	[x: string]: any;
} & UseControllerProps<T>;

interface PropsWithChildren {
	children: ReactNode;
}

interface PropsWithLabel {
	label: string;
}

export const CheckboxController = <T extends FieldValues>({
	control,
	name,
	id,
	label,
	children,
	isChecked,
	className,
	onInputChange,
	...inputProps
}: SharedProps<T> & XOR<PropsWithChildren, PropsWithLabel>): JSX.Element => {
	return (
		<div>
			<Controller
				control={control}
				name={name}
				render={({ field: { onChange } }) => (
					<div role="checkbox" tabIndex={0} aria-checked={isChecked}>
						<input
							type="checkbox"
							id={id}
							value={id}
							checked={isChecked}
							name={name}
							className="form-input-check"
							{...inputProps}
							onChange={(e) => {
								onChange(e.target.checked);
								onInputChange?.(e.target.checked);
							}}
						/>
						<span className="form-check-tick">
							<FontAwesome icon="check" size="xs" />
						</span>
						<label
							htmlFor={id}
							className={clx(
								className,
								'ps-2 cursor-pointer text-break'
							)}
							title={label ?? ''}>
							{children || label}
						</label>
					</div>
				)}
			/>
		</div>
	);
};
// );

CheckboxController.displayName = 'CheckboxController';
