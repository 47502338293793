import { memo, startTransition } from 'react';
import { api, toastify } from 'Utils';
import { Contact, SocialNetwork } from 'Types';
import { useAxios, useUpdateCompany } from 'Hooks';
import { ProfileContactSection } from 'Elements';

export const CompanyContactSection = memo(() => {
	const { sendRequest } = useAxios();
	const { isLoading, company, refetch } = useUpdateCompany();
	const contact = company?.contact;
	const address = company?.address;
	const social = company?.socialNetwork as SocialNetwork[];

	const submitRequest: (
		data: {
			contact: Partial<Contact>;
			socialNetwork?: Partial<SocialNetwork[]>;
		},
		onFinal?: () => void
	) => Promise<void> = async (data, onFinal) => {
		await sendRequest(
			api.updateCompanyContact,
			{ data, method: 'PATCH' },
			() => {
				void refetch();
				startTransition(() => {
					toastify('toastify.homepage-updated', {
						type: 'success'
					});
				});
				onFinal?.();
			}
		);
	};

	return (
		<ProfileContactSection
			type="company"
			contact={contact}
			address={address}
			social={social}
			isLoading={isLoading}
			onUpdate={submitRequest}
		/>
	);
});
CompanyContactSection.displayName = 'CompanyContactSection';
