import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { fetch } from 'Utils';
import { queryKey } from 'Constants';
import { DataList, DataQueryParams, JobType } from 'Types';

const config = (options = {}) => {
	return {
		...{ keepPreviousData: false },
		...options
	};
};

export const useJobTypes = <T = DataList<JobType>>(
	{ pageSize, pageNumber, keyword = '' }: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<JobType>>
): UseQueryResult<DataList<JobType>, unknown> => {
	return useQuery(
		{
			queryKey: [queryKey.jobTypes, { pageSize, pageNumber, keyword }],
			queryFn: () => fetch.jobTypeList(pageSize, pageNumber, keyword),
			...config(options)
		}
	);
};
