import { memo, useState } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useInvitations } from 'Hooks';
import { containerVariants, names } from 'Constants';
import { formatLocaleDateTime, getDiscountFormat } from 'Utils';
import {
	DataTable,
	DataTableBody,
	DataTableHead,
	DataTableLoading,
	DataTableOperation,
	DataTableRow,
	DataTableRowCell,
	EndOfList,
	ListingPage,
	NoData,
	Pagination,
	SystemErrorAlert
} from 'Elements';

export const ListInvitation = memo(() => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { invitations } = names;
	const [searchParams, setSearchParams] = useSearchParams();
	const params = Object.fromEntries([...searchParams]);
	const pageParam = searchParams.get('page') || 1;
	const queryParam = searchParams.get('q') || '';
	const [state, setState] = useState({
		pageSize: 10,
		currentPage: pageParam,
		keyword: queryParam
	});

	const { data, isFetching, isError } = useInvitations({
		pageSize: state.pageSize,
		pageNumber: state.currentPage,
		keyword: state.keyword
	});

	const onChangePageSize = (pageSize: number) => {
		setSearchParams({ ...params, page: '1' });
		setState((prev) => ({
			...prev,
			pageSize,
			currentPage: 1
		}));
	};

	const handleSearch = (query: string) => {
		setSearchParams({
			...params,
			page: '1',
			q: query
		});
		setState((prev) => ({
			...prev,
			currentPage: 1,
			keyword: query
		}));
	};

	const handlePageChange = (page: number) => {
		setSearchParams({ ...params, page: page.toString() });
		setState((prev) => ({
			...prev,
			currentPage: page
		}));
	};

	const handleSelectRow = (row_id: string) => {
		return navigate(`${invitations.path}/${row_id}`, {
			state: { prevPath: invitations.path }
		});
	};

	return (
		<ListingPage
			totalItems={data?.totalItems}
			pageSizes={[5, 10, 20]}
			selectedPageSize={state.pageSize}
			onPageSizeChange={onChangePageSize}
			onSearchChange={handleSearch}>
			<DataTable>
				{data && data?.totalItems > 0 && (
					<DataTableHead>
						<DataTableRowCell
							cellText="table.company"
							cellClassName="w-100 w-sm-25"
						/>
						<DataTableRowCell
							cellText="table.receiver"
							cellClassName="w-100 w-sm-15"
						/>
						<DataTableRowCell
							cellText="table.voucher"
							cellClassName="w-100 w-sm-15"
						/>
						<DataTableRowCell
							cellText="table.sent-date"
							cellClassName="w-100 w-sm-15"
						/>
						<DataTableRowCell
							cellText="table.signup-date"
							cellClassName="w-100 w-sm-15"
						/>
						<DataTableRowCell
							cellText="table.operation"
							cellClassName="w-100 w-sm-15"
						/>
					</DataTableHead>
				)}
				<DataTableBody>
					{!isFetching && (
						<motion.div
							variants={containerVariants}
							initial="hidden"
							animate="show">
							{data?.items?.map((invitation) => {
								return (
									<DataTableRow key={invitation.id}>
										<DataTableRowCell
											cellClassName="w-100 w-sm-25"
											cellHead="table.company">
											<div className="d-flex flex-column text-end text-sm-start">
												<h4
													className="fs-sm mb-1"
													title={
														invitation.companyName
													}>
													{invitation.companyName}
												</h4>
												<span className="text-gray-3 lh-base">
													{invitation.email}
												</span>
											</div>
										</DataTableRowCell>
										<DataTableRowCell
											cellClassName="w-100 w-sm-15"
											cellHead="table.receiver"
											cellText={
												invitation.receiverName || '---'
											}
										/>
										<DataTableRowCell
											cellClassName="w-100 w-sm-15"
											cellHead="table.voucher">
											{invitation?.voucherCode && (
												<div className="d-flex flex-column text-end text-sm-start">
													<h4
														className="fs-sm mb-1"
														title={
															invitation.voucherCode
														}>
														{invitation.voucherCode}
													</h4>
													<span className="text-gray-3 lh-base">
														{getDiscountFormat(
															invitation.voucherDiscount,
															invitation.voucherDiscountType
														)}
													</span>
												</div>
											)}
											{!invitation.voucherCode && '---'}
										</DataTableRowCell>
										<DataTableRowCell
											cellClassName="w-100 w-sm-15"
											cellHead="table.sent-date">
											<time
												title={formatLocaleDateTime(
													invitation.lastModifyDate
												)}
												dateTime={formatLocaleDateTime(
													invitation.lastModifyDate
												)}>
												{formatLocaleDateTime(
													invitation.lastModifyDate
												)}
											</time>
										</DataTableRowCell>
										<DataTableRowCell
											cellClassName="w-100 w-sm-15"
											cellHead="table.signup-date">
											{invitation.lastSignUpDate && (
												<time
													title={formatLocaleDateTime(
														invitation.lastSignUpDate
													)}
													dateTime={formatLocaleDateTime(
														invitation.lastSignUpDate
													)}>
													{formatLocaleDateTime(
														invitation.lastSignUpDate
													)}
												</time>
											)}
											{invitation.usageCount === 0 &&
												t('label.not-signup-yet')}
										</DataTableRowCell>
										<DataTableRowCell
											cellClassName="w-100 w-sm-15"
											cellHead="table.operation">
											<DataTableOperation
												onEditRow={
													invitation.usageCount === 0
														? () =>
																handleSelectRow(
																	invitation.id
																)
														: false
												}
												onViewRow={
													invitation.usageCount > 0
														? () =>
																handleSelectRow(
																	invitation.id
																)
														: false
												}
											/>
										</DataTableRowCell>
									</DataTableRow>
								);
							})}
						</motion.div>
					)}
					{isFetching && (
						<DataTableLoading
							widths={[25, 15, 15, 15, 15, 15]}
							count={state.pageSize}
						/>
					)}
				</DataTableBody>
				{!isFetching && data?.totalItems === 0 && (
					<NoData message="title.no-invitation-sent" />
				)}
			</DataTable>
			{!isFetching && <EndOfList data={data} />}
			{isError && <SystemErrorAlert />}
			{!isFetching && data && data?.totalPages > 1 && (
				<Pagination
					className="mt-3"
					totalItemsCount={data?.totalItems}
					activePage={parseInt(state.currentPage.toString(), 10)}
					itemsCountPerPage={state.pageSize}
					onChange={handlePageChange}
				/>
			)}
		</ListingPage>
	);
});

ListInvitation.displayName = 'ListInvitation';
