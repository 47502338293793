import { memo, FC, useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
	KeenSliderInstance,
	KeenSliderOptions,
	useKeenSlider
} from 'keen-slider/react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { clx, config } from 'Utils';
import { IntegrationPartners } from 'Types';
import { useAgentProfile, useAxios } from 'Hooks';
import {
	Card,
	CopyButton,
	LoadingSpinner,
	ReactSwitch,
	IntegrationPartnerCard
} from 'Elements';
import 'keen-slider/keen-slider.min.css';
import styles from 'partials/marketing/integration-partner.module.scss';
import integrationStyles from 'partials/marketing/integration.module.scss';

export const PartnerScript: FC = memo(() => {
	const { t } = useTranslation();
	const { sendRequest, isLoading: isSubmitting } =
		useAxios<IntegrationPartners>();
	const { data, isLoading } = useAgentProfile();
	const [state, setState] = useState<{
		show_title: boolean;
		show_desc: boolean;
		show_logo: boolean;
		show_intern: boolean;
		show_carousel: boolean;
		scriptData: IntegrationPartners | null;
	}>({
		show_title: true,
		show_desc: true,
		show_logo: false,
		show_intern: false,
		show_carousel: false,
		scriptData: null
	});
	const sliderTimeout = 4500;
	const sliderOptions: KeenSliderOptions = {
		loop: true,
		disabled: !state.show_carousel,
		breakpoints: {
			'(min-width: 500px)': {
				slides: {
					perView: 2,
					spacing: 20
				}
			},
			'(min-width: 768px)': {
				slides: {
					perView: 3,
					spacing: 20
				}
			},
			'(min-width: 1200px)': {
				slides: {
					perView: 4,
					spacing: 20
				}
			}
		}
	};
	const sliderPlugins = [
		(slider: KeenSliderInstance) => {
			let timeout: ReturnType<typeof setTimeout>;
			let mouseOver = false;
			function clearNextTimeout() {
				clearTimeout(timeout);
			}
			function nextTimeout() {
				clearTimeout(timeout);
				if (mouseOver) return;
				timeout = setTimeout(() => {
					slider.next();
				}, sliderTimeout);
			}
			slider.on('created', () => {
				slider.container.addEventListener('mouseover', () => {
					mouseOver = true;
					clearNextTimeout();
				});
				slider.container.addEventListener('mouseout', () => {
					mouseOver = false;
					nextTimeout();
				});
				nextTimeout();
			});
			slider.on('dragStarted', clearNextTimeout);
			slider.on('animationEnded', nextTimeout);
			slider.on('updated', nextTimeout);
		}
	];
	const [sliderRef] = useKeenSlider<HTMLDivElement>(
		sliderOptions,
		sliderPlugins
	);
	const scriptUrl = config.widgetScriptUrl;
	const agentId = data?.id;

	const htmlContent = `<script src="${scriptUrl}/partner/partner-widget.min.js" id="partner-widget" data-agent-id="${agentId}" data-widget-url="${config.widgetApiUrl}" data-script-url="${scriptUrl}" data-show-title="${state.show_title}" data-show-description="${state.show_desc}" data-show-logo="${state.show_logo}" data-show-carousel="${state.show_carousel}" data-show-intern="${state.show_intern}"></script>`;

	const updateState = (stateName: keyof typeof state, value: unknown) => {
		setState((prev) => ({
			...prev,
			[stateName]: value
		}));
	};

	const getPreview = useCallback(async () => {
		const api_url = `${config.widgetApiUrl}/widget/agent/partners/${agentId}?onlyAgentInternalPartners=${state.show_intern}`;
		await sendRequest(api_url, { method: 'GET' }, (data) => {
			setState((prev) => ({
				...prev,
				scriptData: data
			}));
		});
	}, [agentId, sendRequest, state.show_intern]);

	useEffect(() => {
		if (agentId) {
			getPreview();
		}
	}, [getPreview, agentId]);

	return (
		<div className="mb-4">
			<Card>
				<Row>
					<Col md={4} className="mb-3 mb-md-0">
						<h6 className="mb-3">{t('title.setting')}</h6>
						<FormGroup className="d-flex mb-3">
							<ReactSwitch
								id="showTitle"
								checked={state.show_title}
								onChange={(value) =>
									updateState('show_title', value)
								}
							/>
							<Label
								htmlFor="showTitle"
								className="ps-2 mb-0 py-0 cursor-pointer">
								{t('label.integration.show-title')}
							</Label>
						</FormGroup>
						<FormGroup className="d-flex mb-3">
							<ReactSwitch
								id="showDesc"
								checked={state.show_desc}
								onChange={(value) =>
									updateState('show_desc', value)
								}
							/>
							<Label
								htmlFor="showDesc"
								className="ps-2 mb-0 py-0 cursor-pointer">
								{t('label.integration.show-desc')}
							</Label>
						</FormGroup>
						<FormGroup className="d-flex mb-3">
							<ReactSwitch
								id="showCarousel"
								checked={state.show_carousel}
								onChange={(value) =>
									updateState('show_carousel', value)
								}
							/>
							<Label
								htmlFor="showCarousel"
								className="ps-2 mb-0 py-0 cursor-pointer">
								{t('label.integration.show-carousel')}
							</Label>
						</FormGroup>
						<FormGroup className="d-flex mb-3">
							<ReactSwitch
								id="showIntern"
								checked={state.show_intern}
								onChange={(value) =>
									updateState('show_intern', value)
								}
							/>
							<Label
								htmlFor="showIntern"
								className="ps-2 mb-0 py-0 cursor-pointer">
								{t('label.integration.show-intern')}
							</Label>
						</FormGroup>
						<FormGroup className="d-flex mb-3">
							<ReactSwitch
								id="showLogo"
								checked={state.show_logo}
								onChange={(value) =>
									updateState('show_logo', value)
								}
							/>
							<Label
								htmlFor="showLogo"
								className="ps-2 mb-0 py-0 cursor-pointer">
								{t('label.integration.show-logo')}
							</Label>
						</FormGroup>
					</Col>
					<Col md={8}>
						<div className="d-flex flex-column">
							<h6 className="mb-3">
								{t('title.integration.your-script')}
							</h6>
							<pre className={integrationStyles.pre}>
								{isLoading ? <LoadingSpinner /> : htmlContent}
							</pre>
							<div className="d-flex align-items-center justify-content-end">
								{!isLoading && (
									<CopyButton textToCopy={htmlContent} />
								)}
							</div>
						</div>
					</Col>
				</Row>
			</Card>
			<div>
				<h6 className="mb-3 fs-6 text-gray-3">{t('title.preview')}</h6>
				{isSubmitting && (
					<div className={clx(styles.loading, 'm-3')}>
						<LoadingSpinner />
					</div>
				)}
				{!isSubmitting && state.scriptData && (
					<div className={styles.container}>
						{(state.show_desc || state.show_title) && (
							<header className={styles.headline}>
								{state.show_title && (
									<h3
										className={styles.section_title}
										style={{
											color: state.scriptData.agentColor
										}}>
										{state.scriptData.headline}
									</h3>
								)}
								{state.show_desc && (
									<div
										dangerouslySetInnerHTML={{
											__html: state.scriptData.description
										}}
										className={styles.section_text}
									/>
								)}
							</header>
						)}
						{!state.show_carousel && (
							<div className={styles.list}>
								{state.scriptData.partners?.map((partner) => {
									return (
										<IntegrationPartnerCard
											key={partner.id}
											className={styles.card_grid}
											partner={partner}
											showLogo={state.show_logo}
										/>
									);
								})}
							</div>
						)}
						{state.show_carousel && (
							<div ref={sliderRef} className="keen-slider">
								{state.scriptData.partners?.map((partner) => {
									return (
										<IntegrationPartnerCard
											key={partner.id}
											partner={partner}
											className="keen-slider__slide"
											showLogo={state.show_logo}
										/>
									);
								})}
							</div>
						)}
					</div>
				)}
			</div>
		</div>
	);
});

PartnerScript.displayName = 'PartnerScript';
