import { useCallback, useState } from 'react';
import { AxiosError, AxiosRequestConfig } from 'axios';
import Adapter from 'Adapter';
import { Error } from 'Types';

type UseAxiosReturnType<T> = {
	isLoading: boolean;
	error: AxiosError<Error> | null;
	sendRequest: (
		url: string,
		config?: AxiosRequestConfig,
		applyData?: (data: T) => void
	) => Promise<void>;
};

export const useAxios = <T>(): UseAxiosReturnType<T> => {
	const [isLoading, setLoading] = useState(false);
	const [error, setError] = useState<AxiosError<Error> | null>(null);

	const sendRequest = useCallback(
		async (
			url: string,
			config?: AxiosRequestConfig,
			applyData?: (data: T) => void
		) => {
			setLoading(true);
			try {
				const response = await Adapter(url, {
					method: config?.method ?? 'POST',
					data: config?.data
				});
				if (response) {
					applyData?.(response.data);
				}
			} catch (error) {
				setError(error as AxiosError<Error>);
				// if (axios.isAxiosError(error)) {
				// 	setError(new Error(error.message));
				// } else {
				// }
			} finally {
				setLoading(false);
			}
		},
		[]
	);

	return { isLoading, error, sendRequest } as const;
};
