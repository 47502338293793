import { memo } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import { clx } from 'Utils';
import { AssetConfigInputType, Colors } from 'Types';
import {
	setInputAssetData,
	setLogo,
	setOriginalLogo,
	toggleSidebar
} from 'Actions';
import {
	Button,
	IconLogo,
	LocaleDropDown,
	UserDropDown,
	FontAwesome
} from 'Elements';
import {
	useDispatch,
	useResponsive,
	useSelector,
	useAgentProfile,
	useCompanyProfile,
	useGetB2bUserProfileQuery
} from 'Hooks';
import classes from 'partials/shared/header.module.scss';

export const Header = memo(() => {
	const dispatch = useDispatch();
	const sidebar = useSelector((state) => state.sidebar);
	const { isDesktopAndBelow } = useResponsive();
	const { data: user, isLoading } = useGetB2bUserProfileQuery();
	const userType = user?.userCategory?.toLowerCase();
	const { data: companyProfile } = useCompanyProfile({
		enabled: !isLoading && userType === 'company',
		onSuccess(data) {
			const simpleLogoElement = data.companySetting?.logo ?? '';
			const basic_Profile_data = {
				nameElement: data?.companyName ?? '',
				emailElement: data?.companyEmail ?? '',
				phoneElement: data.contact?.tel ?? ''
			};
			for (const key in basic_Profile_data) {
				dispatch(
					setInputAssetData({
						name: key as AssetConfigInputType,
						value: basic_Profile_data[key]
					})
				);
			}
			dispatch(setLogo(simpleLogoElement));
			dispatch(setOriginalLogo(simpleLogoElement));
		}
	});
	const { data: agentProfile } = useAgentProfile({
		enabled: !isLoading && userType === 'agent',
		onSuccess(data) {
			const simpleLogoElement = data.agentSetting?.logo ?? '';
			const basic_Profile_data = {
				nameElement: data?.agentName ?? '',
				emailElement: data?.agentEmail ?? '',
				phoneElement: data.contact?.tel ?? ''
			};
			for (const key in basic_Profile_data) {
				dispatch(
					setInputAssetData({
						name: key as AssetConfigInputType,
						value: basic_Profile_data[key]
					})
				);
			}
			dispatch(setLogo(simpleLogoElement));
			dispatch(setOriginalLogo(simpleLogoElement));
		}
	});
	const defaultImage = '/assets/img/company-default.jpg';

	const profileLogoUrl =
		userType === 'company'
			? companyProfile?.companySetting?.logo
			: agentProfile?.agentSetting?.logo;

	const profileLogoAlt =
		userType === 'company'
			? companyProfile?.companyName
			: agentProfile?.agentName;

	const onToggleSidebar = () => {
		dispatch(toggleSidebar(!sidebar.isOpen));
	};

	return (
		<header className={classes.header}>
			<Link className={clx(classes.logo, 'd-none d-lg-flex')} to="/">
				<IconLogo fill="#fff" className={classes.logo_image} />
			</Link>
			<div className={classes.navbar}>
				<Container fluid className="d-flex align-items-center">
					{isDesktopAndBelow && (
						<Button
							color={Colors.empty}
							className={clx(classes.trigger, 'p-0')}
							onClick={onToggleSidebar}>
							<FontAwesome
								icon={!sidebar.isOpen ? 'bars' : 'arrow-left'}
								size="lg"
							/>
						</Button>
					)}
					<Link to="/" className={classes.logo_profile}>
						<img
							src={profileLogoUrl || defaultImage}
							alt={profileLogoAlt}
							title={profileLogoAlt}
							className={classes.logo_profile_image}
						/>
					</Link>
					<div className="d-flex align-items-center gap-4 ms-auto">
						<LocaleDropDown />
						<UserDropDown />
					</div>
				</Container>
			</div>
		</header>
	);
});

Header.displayName = 'Header';
