import { FC, memo, SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
	fill?: string;
	className?: string;
}

export const ShapeDividerBottom: FC<Props> = memo(
	({ fill = '#fff', className, ...props }): JSX.Element => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 1921 129.181"
				className={className}
				{...props}>
				<path
					d="M-2161.6,4741.6h-1920l0-128.045,407.154,98.894a327.348,327.348,0,0,0,102.071,8.308l1410.775-107.2Z"
					transform="translate(4082.096 -4612.919)"
					fill={fill}
					strokeMiterlimit="10"
					strokeWidth="1"
				/>
			</svg>
		);
	}
);

ShapeDividerBottom.displayName = 'ShapeDividerBottom';
