import { memo, FC, startTransition } from 'react';
import axios from 'Adapter';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useInternshipRegistration } from 'Hooks';
import { toastify, api } from 'Utils';
import { Colors, ModalProps } from 'Types';
import { Button, ModalBox, ModalBoxFooter, SubmitButton } from 'Elements';

interface Props extends ModalProps {
	title: string;
	type: 'approve' | 'reject';
}

interface RequestForm {
	description: string;
	status: number;
}

export const ModalBoxInternshipParticipantsRequestDescription: FC<Props> = memo(
	({ isOpen, title, type, onClose }) => {
		const { t } = useTranslation();
		const { internshipParticipant_id } = useParams();
		const { refetch } = useInternshipRegistration(internshipParticipant_id as string);

		const {
			register,
			handleSubmit,
			formState: { isSubmitting }
		} = useForm<RequestForm>();

		const submitRequest: (data: RequestForm) => Promise<void> = async (
			data
		) => {
			await axios
				.patch(api.updateInternshipRegistration(internshipParticipant_id as string), data)
				.then((res) => {
					if (res.status === 200) {
						refetch();
						startTransition(() => {
							onClose();
							data?.status.toString() === '1'
								? toastify('toastify.applicant-approved', {
										type: 'success'
								  })
								: toastify('toastify.applicant-rejected', {
										type: 'success'
								  });
						});
					}
				});
		};

		const submitHandler: SubmitHandler<RequestForm> = async (data) => {
			const api_data: RequestForm = {
				...data,
				status: type === 'approve' ? 1 : 2
			};
			await submitRequest(api_data);
		};

		return (
			<ModalBox isOpen={isOpen} title={title} onClose={onClose}>
				<form onSubmit={handleSubmit(submitHandler)}>
					<textarea
						{...register('description')}
						id="description"
						placeholder={
							type === 'approve'
								? `${t('forms.any-desc-for-approve')} (${t(
										'placeholder.optional'
								  )})`
								: `${t('forms.any-desc-for-reject')} (${t(
										'placeholder.optional'
								  )})`
						}
						className="inputbox w-100"
						cols={10}
						rows={5}
					/>
					<ModalBoxFooter className="justify-content-between mt-3">
						<Button
							type="button"
							color={Colors['white-gray']}
							onClick={onClose}>
							{t('button.cancel')}
						</Button>
						<SubmitButton
							icon={type === 'approve' ? 'check' : 'xmark'}
							color={
								type === 'approve' ? 'white-green' : 'white-red'
							}
							isSubmitting={isSubmitting}
							label={
								type === 'approve'
									? 'button.approve'
									: 'button.reject'
							}
							savingLabel={
								type === 'approve'
									? 'button.approving'
									: 'button.rejecting'
							}
						/>
					</ModalBoxFooter>
				</form>
			</ModalBox>
		);
	}
);

ModalBoxInternshipParticipantsRequestDescription.displayName = 'ModalBoxInternshipParticipantsRequestDescription';
