import { memo, FC, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Container, Row } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { clx } from 'Utils';
import { names } from 'Constants';
import { AgentSetting, Colors } from 'Types';
import { useProfileJobs, useUpdateAgent } from 'Hooks';
import {
	Button,
	Editor,
	FontAwesome,
	LoadingSpinner,
	ProfileAddNewCard,
	ProfileJobCard,
	SectionSwitcher
} from 'Elements';
import styles from 'partials/homepage/section/profile-job-section.module.scss';

export const AgentJobSection: FC = memo(() => {
	const perRow = 3;
	const { t } = useTranslation();
	const { control, reset } = useForm();
	const [next, setNext] = useState(perRow);
	const navigate = useNavigate();
	const {
		jobs: { add }
	} = names;
	const addJobUrl = add?.path as string;

	const {
		setting,
		updateAgentSetting,
		isLoading: isLoadingSetting
	} = useUpdateAgent();

	const { data: jobs, isLoading } = useProfileJobs({
		pageNumber: 1,
		pageSize: 200,
		keyword: ''
	});

	const handleMore = () => {
		setNext(next + perRow);
	};

	const handleNavigate = () => {
		navigate(addJobUrl, { state: { prevPath: '/homepage' } });
	};

	const handleUpdate = async (field: Partial<AgentSetting>) => {
		await updateAgentSetting(field);
	};

	useEffect(() => {
		reset(setting);
	}, [reset, setting]);

	return (
		<section className={styles.wrapper}>
			<div className={styles.background}>
				{/* <img
					src="/assets/img/job/agent-jobs.webp"
					alt=""
					className="w-100"
				/> */}
			</div>
			{isLoading && (
				<div className="d-flex align-items-center justify-content-center">
					<LoadingSpinner />
				</div>
			)}
			{!isLoading && !isLoadingSetting && setting && jobs && (
				<Container className="position-relative">
					<SectionSwitcher
						control={control}
						isActive={!setting?.jobListStatus}
						name="jobListStatus"
						wrapperClassName={clx(styles.switch, 'mt-3')}
						label="forms.show-agent-jobs-in-home"
						onSwitchChange={(val) =>
							handleUpdate({ jobListStatus: val })
						}
					/>
					<h3 className="h3 text-gray-5 mb-5 pb-4 lh-base d-inline-flex">
						<Editor
							type="text"
							value={
								setting?.jobListTitle ||
								`[${t('placeholder.job-list-headline')}]`
							}
							onSave={(val) =>
								handleUpdate({ jobListTitle: val })
							}
						/>
					</h3>
					{jobs?.items?.length > 0 && (
						<Row className="justify-content-center">
							{jobs.items?.slice(0, next)?.map((job) => {
								return (
									<Col
										md={6}
										lg={4}
										key={job.id}
										className="mb-4">
										<ProfileJobCard
											type="agent"
											job={job}
											profileImage={setting.headerImage}
										/>
									</Col>
								);
							})}
						</Row>
					)}
					<div className="d-flex align-items-center justify-content-center mt-3 gap-3">
						{jobs.items?.length > next && (
							<Button
								size="lg"
								color={Colors['border-gray-4']}
								label={t('button.more-jobs') as string}
								className="rounded-pill"
								onClick={handleMore}
							/>
						)}
						{jobs.totalItems > 0 && (
							<Button
								size="lg"
								className="gap-3 rounded-pill"
								onClick={handleNavigate}>
								<FontAwesome
									icon="plus"
									size="xl"
									swapOpacity
								/>
								{t('title.add-new-job')}
							</Button>
						)}
					</div>
					{jobs && jobs?.items?.length === 0 && (
						<ProfileAddNewCard
							navigationUrl={addJobUrl}
							title="title.add-new-job"
						/>
					)}
				</Container>
			)}
			{!setting?.jobListStatus && (
				<div className="overlay overlay_white overlay-box" />
			)}
		</section>
	);
});

AgentJobSection.displayName = 'AgentJobSection';
