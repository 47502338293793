import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { fetch } from 'Utils';
import { queryKey } from 'Constants';
import { DataQueryParams, MarketingAsset, MarketingAssetConfig } from 'Types';

const config = (options = {}) => {
	return {
		...options,
		keepPreviousData: false
	};
};

export const useMarketingAssets = <T = MarketingAsset[]>(
	useFor: string,
	options?: UseQueryOptions<T, any, MarketingAsset[]>
): UseQueryResult<MarketingAsset[], unknown> => {
	return useQuery(
		{
			queryKey: [queryKey.marketingAssets, { useFor }],
			queryFn: () => fetch.marketingAssets(useFor),
			...config(options)
		}
	);
};

export const useMarketingTemplate = <T = MarketingAssetConfig[]>(
	{ toolType, useFor }: DataQueryParams,
	options?: UseQueryOptions<T, any, MarketingAssetConfig[]>
): UseQueryResult<MarketingAssetConfig[], unknown> => {
	return useQuery(
		{
			queryKey: [queryKey.marketingTemplates, { toolType, useFor }],
			queryFn: () => fetch.marketingTemplates(toolType, useFor),
			...config(options)
		}
	);
};

export const useMarketingAssetConfig = <T = MarketingAssetConfig>(
	assetId: string,
	options?: UseQueryOptions<T, any, MarketingAssetConfig>
): UseQueryResult<MarketingAssetConfig, unknown> => {
	return useQuery(
		{
			queryKey: [queryKey.marketingAssetConfig, { assetId }],
			queryFn: () => fetch.marketingAssetConfig(assetId),
			...config(options)
		}
	);
};
