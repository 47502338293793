import { FC, memo } from 'react';
import { ColorValues } from 'Types';

interface Props {
	fill?: string;
	className?: string;
	size?: number;
}

export const IconCoaster: FC<Props> = memo(
	({
		fill = ColorValues.primary,
		className,
		size = 100,
		...props
	}): JSX.Element => {
		return (
			<svg
				width={size}
				height={size}
				viewBox="0 0 100 100"
				xmlns="http://www.w3.org/2000/svg"
				className={className}
				{...props}>
				<g transform="translate(16 10)">
					<rect
						width="100"
						height="100"
						transform="translate(-16 -10)"
						fill="#fff"
						opacity="0"
					/>
					<path
						d="M119.844,129.32,145.4,115.214a15.347,15.347,0,0,1,14.5.515l22.456,14.827c3.894,2.571,3.7,6.48-.445,8.765l-25.555,14.106a15.347,15.347,0,0,1-14.5-.515L119.4,138.085c-3.894-2.571-3.695-6.48.445-8.766Z"
						transform="translate(-116.605 -72.803)"
						fill={ColorValues['primary-light']}
					/>
					<path
						d="M120.035,127.609,145.591,113.5a15.347,15.347,0,0,1,14.5.515l22.456,14.827c3.894,2.571,3.695,6.48-.445,8.766l-24.428,13.484-1.127.622a15.347,15.347,0,0,1-14.5-.515L119.59,136.374c-3.894-2.571-3.695-6.48.445-8.765Z"
						transform="translate(-116.369 -77.908)"
						fill={fill}
					/>
					<line
						y1="11"
						x2="21"
						transform="translate(33.048 55.912)"
						fill="none"
						stroke={ColorValues['tertiary-light']}
						strokeLinecap="round"
						strokeWidth="2"
					/>
					<line
						y1="11"
						x2="21"
						transform="translate(27.048 51.912)"
						fill="none"
						stroke={ColorValues['tertiary-light']}
						strokeLinecap="round"
						strokeWidth="2"
					/>
					<path
						d="M120.366,100.256s1.183,23.92,2.955,31.407S131,145.177,131,145.177c0,2.489,7.019,4.507,15.679,4.507s15.679-2.018,15.679-4.507c0,0,5.9-6.027,7.676-13.514s2.955-31.407,2.955-31.407Z"
						transform="translate(-111.975 -91.273)"
						fill={ColorValues['primary-light']}
					/>
					<g
						transform="translate(8.392 1)"
						fill={fill}
						strokeLinecap="round">
						<path
							d="M 26.30928802490234 14.96576118469238 C 19.37749862670898 14.96576118469238 12.87377834320068 14.15069103240967 7.99616813659668 12.67071151733398 C 5.717497825622559 11.97930145263672 3.86896800994873 11.15179061889648 2.650408029556274 10.27763080596924 C 1.89749801158905 9.737510681152344 0.9999980330467224 8.900951385498047 0.9999980330467224 7.98288106918335 C 0.9999980330467224 7.064811229705811 1.89749801158905 6.228250980377197 2.650408029556274 5.688130855560303 C 3.86896800994873 4.813971042633057 5.717497825622559 3.986461162567139 7.99616813659668 3.295051097869873 C 12.87377834320068 1.815071105957031 19.37749862670898 1.000001072883606 26.30928802490234 1.000001072883606 C 33.2410774230957 1.000001072883606 39.74479675292969 1.815071105957031 44.62240982055664 3.295051097869873 C 46.90107727050781 3.986461162567139 48.74960708618164 4.813971042633057 49.96816635131836 5.688130855560303 C 50.72107696533203 6.228250980377197 51.61857986450195 7.064811229705811 51.61857986450195 7.98288106918335 C 51.61857986450195 8.900951385498047 50.72107696533203 9.737510681152344 49.96816635131836 10.27763080596924 C 48.74960708618164 11.15179061889648 46.90107727050781 11.97930145263672 44.62240982055664 12.67071151733398 C 39.74479675292969 14.15069103240967 33.2410774230957 14.96576118469238 26.30928802490234 14.96576118469238 Z"
							stroke="none"
						/>
						<path
							d="M 26.30928802490234 2.000000953674316 C 19.47317886352539 2.000000953674316 13.07256698608398 2.799771308898926 8.286518096923828 4.251971244812012 C 3.129379272460938 5.816771507263184 1.999996185302734 7.475710868835449 1.999996185302734 7.98288106918335 C 1.999996185302734 8.49005126953125 3.129379272460938 10.14899063110352 8.286518096923828 11.71379089355469 C 13.07256698608398 13.16599082946777 19.47317886352539 13.96576118469238 26.30928802490234 13.96576118469238 C 33.1453971862793 13.96576118469238 39.5460090637207 13.16599082946777 44.33205795288086 11.71379089355469 C 49.48919677734375 10.14899063110352 50.61857986450195 8.49005126953125 50.61857986450195 7.98288106918335 C 50.61857986450195 7.475710868835449 49.48919677734375 5.816771507263184 44.33205795288086 4.251971244812012 C 39.5460090637207 2.799771308898926 33.1453971862793 2.000000953674316 26.30928802490234 2.000000953674316 M 26.30928802490234 9.5367431640625e-07 C 40.83950805664062 9.5367431640625e-07 52.61857986450195 3.574061393737793 52.61857986450195 7.98288106918335 C 52.61857986450195 12.39170074462891 40.83950805664062 15.96576118469238 26.30928802490234 15.96576118469238 C 11.77906799316406 15.96576118469238 -3.814697265625e-06 12.39170074462891 -3.814697265625e-06 7.98288106918335 C -3.814697265625e-06 3.574061393737793 11.77906799316406 9.5367431640625e-07 26.30928802490234 9.5367431640625e-07 Z"
							stroke="none"
							fill={fill}
						/>
					</g>
					<path
						d="M138.593,106.855c4.3-3.646,9.768-2.109,12.856,3.617s2.872,13.918-.509,19.273-8.911,6.261-13.008,2.132m.1-4.237c2.636,2.75,6.977,2.309,9.152-1.257a13.974,13.974,0,0,0,.328-12.838c-1.987-3.814-6.287-5-9.055-2.572"
						transform="translate(-86.359 -87.576)"
						fill={fill}
					/>
				</g>
			</svg>
		);
	}
);

IconCoaster.displayName = 'IconCoaster';
