import { memo } from 'react';
import { Col, Row } from 'reactstrap';
import { useSearchParams } from 'react-router-dom';
import { CheckoutPaymentSummary, CheckoutSubscriptionSummary } from 'Elements';
import styles from 'partials/payment/payment.module.scss';

export const CheckoutSubscription = memo(() => {
	const [searchParams] = useSearchParams();
	const subscriptionId = searchParams.get('subscriptionId') as string;

	return (
		<section className={styles.wrapper}>
			<Row className="d-flex justify-content-center">
				<Col xxl={9}>
					{!subscriptionId && <CheckoutSubscriptionSummary />}
					{!!subscriptionId && (
						<CheckoutPaymentSummary
							subscriptionId={subscriptionId}
						/>
					)}
				</Col>
			</Row>
		</section>
	);
});

CheckoutSubscription.displayName = 'CheckoutSubscription';
