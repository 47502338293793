import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { clx } from 'Utils';
import { ToolboxConfigGroup, ToolboxImageCard, FontAwesome } from 'Elements';
import { AssetConfigElement, AssetConfigType, ImageElement } from 'Types';
import styles from 'partials/marketing/toolbox-config.module.scss';

interface Props {
	element: AssetConfigElement<ImageElement>;
	errors?: AssetConfigType;
	isSelected?: boolean;
	onSelect: (image?: ImageElement) => void;
}

export const ImageELementList: FC<Props> = memo(
	({ element, errors, isSelected, onSelect }) => {
		const { t } = useTranslation();

		return (
			<ToolboxConfigGroup
				title={element.title}
				isRequired={element.isRequired}
				errors={errors}>
				<div className={styles.imageGrid}>
					{element.inputItems.map((image) => (
						<ToolboxImageCard
							card={image}
							key={image.thumbnail}
							onClick={onSelect}
						/>
					))}
					{!element.isRequired && (
						<div
							className={clx(
								styles.imageWrapper,
								isSelected && styles.selectedImage,
								'bg-gray-1 shadow-none d-flex flex-column justify-content-center align-items-center text-center gap-2'
							)}
							onClick={() => onSelect()}>
							<FontAwesome icon="image-slash" size="xl" />
							{t('title.marketing.no-image')}
						</div>
					)}
				</div>
			</ToolboxConfigGroup>
		);
	}
);

ImageELementList.displayName = 'ImageELementList';
