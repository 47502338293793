import { memo, forwardRef, useRef, useEffect } from 'react';
import { clx } from 'Utils';
import { SubmitButton } from 'Elements';
import styles from 'partials/homepage/color-picker.module.scss';

interface Props {
	color: string;
	isSubmitting: boolean;
	onChange: (color: string) => void;
	onSubmit: (color: string) => void;
	onClose: () => void;
}

export const ColorPickerPopover = memo(
	forwardRef<HTMLDivElement, Props>(
		({ color, isSubmitting, onChange, onSubmit, onClose }, ref) => {
			const colorLabel = useRef<HTMLLabelElement>(null);

			useEffect(() => {
				colorLabel?.current?.click();
			}, []);

			useEffect(() => {
				// window.addEventListener('blur', onClose);
				window.addEventListener('scroll', onClose);
				return () => {
					// window.removeEventListener('blur', onClose);
					window.removeEventListener('scroll', onClose);
				};
			}, [onClose]);

			return (
				<div className={styles.popover} ref={ref}>
					<label
						ref={colorLabel}
						htmlFor="profile_color"
						className="w-100">
						<input
							id="profile_color"
							name="color"
							type="color"
							className="visually-hidden"
							value={color}
							onChange={(event) => onChange(event.target.value)}
						/>
						<SubmitButton
							icon={false}
							className={clx(styles.submit, 'w-100')}
							label="button.set-color"
							savingLabel="button.submitting"
							isSubmitting={isSubmitting}
							onClick={() => onSubmit(color)}
						/>
					</label>
				</div>
			);
		}
	)
);

ColorPickerPopover.displayName = 'ColorPickerPopover';
