import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { clx } from 'Utils';
import { FontAwesome } from 'Elements';

interface Props {
	tip: string;
	className?: string;
	icon?: any;
	textColorClass?: string;
}

export const FormInlineTip: FC<Props> = memo(
	({
		icon = 'circle-info',
		tip,
		textColorClass = 'text-gray-3',
		className
	}) => {
		const { t } = useTranslation();

		return (
			<div
				className={clx(
					'd-flex fs-small lh-md gap-2 mb-3',
					textColorClass,
					className
				)}>
				<FontAwesome
					icon={icon}
					size="lg"
					className="mt-1"
					fillOpacity={0.7}
				/>
				<div
					dangerouslySetInnerHTML={{
						__html: t(tip)
					}}
				/>
			</div>
		);
	}
);

FormInlineTip.displayName = 'FormInlineTip';
