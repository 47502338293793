import { Locale } from 'Types';

export const defaultLocale: Locale = {
	name: 'Deutsch',
	code: 'de',
	lang: 'de-DE',
	icon: '/assets/img/flags/DE.png',
	direction: 'ltr',
	currency: 'EUR'
};

export const locales: Locale[] = [
	defaultLocale,
	{
		name: 'English',
		code: 'en',
		lang: 'en-US',
		icon: '/assets/img/flags/EN.png',
		direction: 'ltr',
		currency: 'USD'
	}
];
