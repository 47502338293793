import { FC, memo } from 'react';
import { clx } from 'Utils';
import { Box } from 'Styles';
import styles from 'partials/homepage/card/titlebox.module.scss';

interface Props {
	title: string;
	bgColor: string;
	subtitle?: string;
	className?: string;
}

export const TitleBox: FC<Props> = memo(
	({ title, subtitle, bgColor, className }) => {
		return (
			<div className={clx(styles.box, className)}>
				<Box className={styles.content} $bgColor={bgColor}>
					{title && (
						<h4 className={styles.title} title={title}>
							{title}
						</h4>
					)}
					{subtitle && (
						<span className={clx(styles.subtitle, 'text-break')}>
							{subtitle}
						</span>
					)}
				</Box>
			</div>
		);
	}
);

TitleBox.displayName = 'TitleBox';
