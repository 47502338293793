import { FC, memo, useState } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { isAfter, isSameDay, parseISO } from 'date-fns';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { addDays } from 'Utils';
import { usePrompt } from 'Hooks';
import { JobFormGeneral } from 'Types';
import {
	DatePickerController,
	FormInlineTip,
	StepNavigation,
	SwitchController
} from 'Elements';
import styles from 'partials/step/step-container.module.scss';

interface Props {
	fields: JobFormGeneral;
	onNext: (data: JobFormGeneral) => void;
}

export const CreateJobGeneral: FC<Props> = memo(({ fields, onNext }) => {
	const { t } = useTranslation();
	const [hasStartDate, setStartDate] = useState(fields.hasStartDate);
	const [hasExpireDate, setExpireDate] = useState(fields.hasExpireDate);
	const descriptionLimit = 450;

	const schema = yup.object().shape({
		title: yup.string().required(t('validation.job-title.required')),
		startDate: yup.string().when(['hasStartDate'], {
			is: true,
			then: yup
				.string()
				.nullable()
				.required(t('validation.start-date.required'))
		}),
		expireDate: yup.string().when(['hasExpireDate'], {
			is: true,
			then: yup
				.string()
				.nullable()
				.required(t('validation.expire-date.required'))
		})
	});

	const {
		watch,
		control,
		setValue,
		getValues,
		register,
		handleSubmit,
		formState: { errors, isDirty }
	} = useForm<JobFormGeneral>({
		resolver: yupResolver(schema),
		defaultValues: fields
	});

	const onSwitchStartDate = (val: boolean) => {
		setStartDate(val);
		if (!val) {
			setValue('startDate', '');
		}
	};

	const onSwitchExpireDate = (val: boolean) => {
		setExpireDate(val);
		if (!val) {
			setValue('expireDate', '');
		}
	};

	const onStartDateChange = () => {
		const startDate = new Date(getValues('startDate') as string);
		const expireDate = new Date(getValues('expireDate') as string);
		if (
			(expireDate && isAfter(startDate, expireDate)) ||
			isSameDay(startDate, expireDate)
		) {
			setValue('expireDate', '');
		}
	};

	usePrompt(t('forms.leave-screen-massage'), isDirty);

	const onSubmit: SubmitHandler<JobFormGeneral> = (data) => {
		onNext?.(data);
	};

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className={styles.form}
			noValidate>
			<Row>
				<Col xxl={8}>
					<FormGroup>
						<Label htmlFor="title">
							{t('forms.job-title')}
							<small className="ms-1">
								({t('validation.required')})
							</small>
						</Label>
						<input
							{...register('title')}
							type="text"
							id="title"
							aria-invalid={!!errors.title}
							className="inputbox w-100"
						/>
						{errors.title && (
							<div className="invalid-feedback d-block">
								{errors.title.message}
							</div>
						)}
					</FormGroup>
					<FormGroup>
						<Label htmlFor="code">
							{t('forms.reference-number')}
						</Label>
						<input
							{...register('code')}
							type="text"
							id="code"
							aria-invalid={!!errors.code}
							className="inputbox w-100"
						/>
					</FormGroup>
					<FormGroup className="position-relative">
						<Label htmlFor="shortDescription">
							{t('forms.job.short-desc')}
						</Label>
						<textarea
							{...register('shortDescription', {
								maxLength: descriptionLimit
							})}
							maxLength={descriptionLimit}
							id="shortDescription"
							className="inputbox w-100"
							cols={10}
							rows={5}
						/>
						<div className="position-absolute end-0">
							<small className="ms-auto mt-1">
								{watch('shortDescription')?.length || 0}/
								{descriptionLimit}
							</small>
						</div>
					</FormGroup>
					<FormGroup>
						<SwitchController
							name="hasStartDate"
							control={control}
							label="forms.has-start-date"
							boxClassName="py-2"
							onSwitchChange={onSwitchStartDate}
						/>
						<FormInlineTip tip="forms.auto-publish-desc" />
					</FormGroup>
					{hasStartDate && (
						<FormGroup>
							<Label htmlFor="startDate">
								{t('forms.start-date')}
								<small className="ms-1">
									({t('validation.required')})
								</small>
							</Label>
							<DatePickerController
								name="startDate"
								minDate={addDays(new Date(), 1)}
								control={control}
								error={errors.startDate}
								placeholder={t('forms.select-date')}
								selected={parseISO(fields?.startDate ?? '')}
								onDateChange={onStartDateChange}
							/>
						</FormGroup>
					)}
					<FormGroup>
						<SwitchController
							name="hasExpireDate"
							control={control}
							label="forms.has-expire-date"
							boxClassName="py-2"
							onSwitchChange={onSwitchExpireDate}
						/>
						<FormInlineTip tip="forms.expire-date-desc" />
					</FormGroup>
					{hasExpireDate && (
						<FormGroup>
							<Label htmlFor="expireDate">
								{t('forms.expire-date')}
								<small className="ms-1">
									({t('validation.required')})
								</small>
							</Label>
							<DatePickerController
								name="expireDate"
								minDate={
									watch('startDate')
										? addDays(
												watch('startDate') as string,
												1
										  )
										: addDays(new Date(), 1)
								}
								control={control}
								error={errors.expireDate}
								placeholder={t('forms.select-date')}
								selected={parseISO(fields?.expireDate ?? '')}
							/>
						</FormGroup>
					)}
					<StepNavigation />
				</Col>
			</Row>
		</form>
	);
});
CreateJobGeneral.displayName = 'CreateJobGeneral';
