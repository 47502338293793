import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { fetch } from 'Utils';
import { queryKey } from 'Constants';
import { InternshipRegistration, DataList, DataQueryParams } from 'Types';

const config = (options = {}) => {
	return {
		...{ keepPreviousData: false },
		...options
	};
};

export const useInternshipRegistration = <T = InternshipRegistration>(
	internshipParticipants_id: string,
	options?: UseQueryOptions<T, any, InternshipRegistration>
): UseQueryResult<InternshipRegistration, unknown> => {
	return useQuery(
		{
			queryKey: [queryKey.internshipParticipants, { internshipParticipants_id }],
			queryFn: () => fetch.internshipRegistrationDetails(internshipParticipants_id),
			...config(options)
		}
	);
};

export const useInternshipRegistrations = <T = DataList<InternshipRegistration>>(
	{ status = '', pageSize, pageNumber, keyword = '' }: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<InternshipRegistration>>
): UseQueryResult<DataList<InternshipRegistration>, unknown> => {
	return useQuery(
		{
			queryKey: [queryKey.internshipParticipants, { status, pageSize, pageNumber, keyword }],
			queryFn: () => fetch.internshipRegistrationList(status, pageSize, pageNumber, keyword),
			...config(options)
		}
	);
};
