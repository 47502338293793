import { FC, memo, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { clx } from 'Utils';
import { XOR } from 'Types';
import styles from 'partials/data-table/data-table.module.scss';

type SharedProps = {
	cellClassName?: string;
	boxClassName?: string;
	cellHead?: string;
};

type Props = XOR<{ children: ReactNode }, { cellText: string }> & SharedProps;

export const DataTableRowCell: FC<Props> = memo(
	({ children, cellText, cellHead, cellClassName, boxClassName }) => {
		const { t } = useTranslation();
		const cellClass = clx(cellClassName, styles.cell, 'py-1 py-md-0 px-2');
		const boxClass = clx(
			'text-break ms-auto ms-sm-0 lh-base',
			boxClassName
		);

		return (
			<div className={cellClass} data-th={t(cellHead as string)}>
				<div className={boxClass} title={t(cellText as string)}>
					{t(cellText as string) || children || null}
				</div>
			</div>
		);
	}
);

DataTableRowCell.displayName = 'DataTableRowCell';
