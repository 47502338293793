import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown
} from 'reactstrap';
import { clx } from 'Utils';
import { SelectOption } from 'Types';
import styles from 'partials/shared/dropdown/duration-dropdown.module.scss';

interface Props {
	durations: SelectOption[];
	selectedDuration: SelectOption;
	className?: string;
	onChange?: (duration: SelectOption) => void;
}

export const DurationDropDown: FC<Props> = memo(
	({ durations, selectedDuration, onChange, className }) => {
		const { t } = useTranslation();
		return (
			<UncontrolledDropdown
				className={clx(
					className,
					'd-inline-flex align-items-center position-relative ms-2'
				)}>
				<DropdownToggle
					caret
					color="empty"
					size="xs"
					className="dropdown-toggle">
					<span className="fs-small">
						{t('label.time.duration')}:
					</span>
					<span className="ms-2 fw-500">
						{selectedDuration.label}
					</span>
				</DropdownToggle>
				<DropdownMenu end className={styles.menu}>
					{durations.map((duration, index) => {
						return (
							<DropdownItem
								key={index}
								className={styles.item}
								onClick={() => onChange?.(duration)}>
								{duration.label}
							</DropdownItem>
						);
					})}
				</DropdownMenu>
			</UncontrolledDropdown>
		);
	}
);

DurationDropDown.displayName = 'DurationDropDown';
