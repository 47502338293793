import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Colors } from 'Types';
import { Button, Card } from 'Elements';

interface Props {
	onRegister: () => void;
}

export const EventParticipationBox: FC<Props> = memo(({ onRegister }) => {
	const { t } = useTranslation();

	return (
		<Card>
			<Button
				color={Colors.tertiary}
				label={t('button.event.register-as-event-partners') as string}
				onClick={onRegister}
			/>
		</Card>
	);
});

EventParticipationBox.displayName = 'EventParticipationBox';
