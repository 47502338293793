import { memo, FC, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Colors } from 'Types';
import { config } from 'Utils';
import { useAgentProfile } from 'Hooks';
import {
	Button,
	Card,
	CopyButton,
	FontAwesome,
	LoadingSpinner
} from 'Elements';
import styles from 'partials/marketing/integration.module.scss';

export const BecomePartnerScript: FC = memo(() => {
	const { t } = useTranslation();
	const { data, isLoading } = useAgentProfile();
	const scriptUrl = config.widgetScriptUrl;
	const [scriptLoaded, setScriptLoaded] = useState(false);
	const agentId = data?.id;

	const htmlContent = `<script src="${scriptUrl}/become-partner/become-partner-widget.min.js" id="become-partner-widget" data-agent-id="${agentId}" data-widget-url="${config.widgetApiUrl}" data-script-url="${scriptUrl}"></script>`;

	const scriptRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (!scriptLoaded || !agentId) {
			return;
		}
		if (agentId && scriptLoaded) {
			const scriptTag = document.createElement('script');
			scriptTag.src = `${scriptUrl}/become-partner/become-partner-widget.min.js`;
			scriptTag.async = true;
			scriptTag.id = 'become-partner-widget';
			scriptTag.setAttribute('data-agent-id', agentId);
			scriptTag.setAttribute('data-script-url', scriptUrl as string);
			scriptTag.setAttribute(
				'data-widget-url',
				config.widgetApiUrl as string
			);

			// Append the script to the ref element
			scriptRef.current?.appendChild(scriptTag);

			// Update the scriptLoaded state
			setScriptLoaded(true);

			// Cleanup function
			return () => {
				// Check if the script is loaded and the ref element exists
				if (scriptLoaded && scriptTag && scriptRef.current) {
					// Remove the script element from the DOM
					scriptRef.current.removeChild(scriptTag);
				}
			};
		}
	}, [scriptLoaded, agentId, scriptUrl]);

	const onSetPreview = () => {
		if (!scriptLoaded) {
			setScriptLoaded(true);
		}
	};

	return (
		<div className="mb-4">
			<Card>
				<div className="d-flex flex-column">
					<h6 className="mb-3">
						{t('title.integration.your-script')}
					</h6>
					<pre className={styles.pre}>
						{isLoading ? <LoadingSpinner /> : htmlContent}
					</pre>
					<div className="d-flex align-items-center justify-content-end gap-2">
						{!isLoading && (
							<Button
								color={Colors['white-primary']}
								onClick={onSetPreview}>
								<FontAwesome
									icon="eye"
									size="lg"
									className="me-2"
								/>
								{t('button.preview')}
							</Button>
						)}
						{!isLoading && <CopyButton textToCopy={htmlContent} />}
					</div>
				</div>
			</Card>
			<div ref={scriptRef}></div>
		</div>
	);
});

BecomePartnerScript.displayName = 'BecomePartnerScript';
