import { createSlice } from '@reduxjs/toolkit';
import reducers from '../setting/SettingReducer';
import { SettingState } from 'Types';
import { getCurrentLocale } from 'src/helpers/Utils';

const initialState: SettingState = {
	locale: getCurrentLocale()
};

export const SettingSlice = createSlice({
	name: 'setting',
	initialState,
	reducers
});

export const { changeLocale } = SettingSlice.actions;

export default SettingSlice.reducer;
