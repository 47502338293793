import { FC, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColorValues, Colors, ModalProps } from 'Types';
import {
	Button,
	FontAwesome,
	FormInlineCheckbox,
	ModalBox,
	ModalBoxFooter,
	SubmitButton
} from 'Elements';

interface Props extends ModalProps {
	isSubmitting: boolean;
	onConfirm: () => Promise<void>;
}

export const ModalBoxConfirmPlanPrice: FC<Props> = memo(
	({ isOpen, isSubmitting, onClose, onConfirm }) => {
		const { t } = useTranslation();
		const [disableDelete, setDisableDelete] = useState(true);

		const onCheckTerms = (isChecked: boolean) => {
			setDisableDelete(!isChecked);
		};

		return (
			<ModalBox
				isOpen={isOpen}
				onClose={onClose}
				title="title.plan-price.confirm-agent-plan-price">
				<header className="d-flex flex-column align-items-center text-center px-3">
					<FontAwesome
						icon="triangle-exclamation"
						size="8x"
						color={ColorValues.yellow}
						className="mb-3"
					/>
					<h6 className="h6 lh-base">
						{t('title.plan-price.are-you-sure-to-update')}
					</h6>
					<p className="lh-md text-gray-3">
						{t('text.plan-price.confirm-set-plan-price')}
					</p>
					<FormInlineCheckbox
						id="submitPlanPrice"
						className="d-flex text-center mb-3"
						label={t('label.i-read-warning') as string}
						onChange={onCheckTerms}
					/>
				</header>
				<ModalBoxFooter className="justify-content-between mt-3">
					<Button
						type="button"
						color={Colors['white-gray']}
						label={t('button.no-thanks') as string}
						onClick={onClose}
					/>
					<SubmitButton
						type="button"
						icon={false}
						isDisable={disableDelete}
						isSubmitting={isSubmitting}
						label="button.yes-i-am-sure"
						savingLabel=""
						onClick={onConfirm}
					/>
				</ModalBoxFooter>
			</ModalBox>
		);
	}
);

ModalBoxConfirmPlanPrice.displayName = 'ModalBoxConfirmPlanPrice';
