import { memo, FC, useState } from 'react';
import axios from 'Adapter';
import { Buffer } from 'buffer';
import { useTranslation } from 'react-i18next';
import { dynamicFileDownload, slugify } from 'Utils';
import { Colors, ModalProps } from 'Types';
import { LoadingMask, ModalBox, ModalBoxFooter, SubmitButton } from 'Elements';
import styles from 'partials/modal/qr-code.module.scss';

interface Props extends ModalProps {
	downloadQrcodeApi: string;
	isLoading: boolean;
	data?: string;
	namespace: string;
}

export const ModalBoxQrCode: FC<Props> = memo(
	({
		isOpen,
		onClose,
		title,
		downloadQrcodeApi,
		isLoading,
		data,
		namespace
	}) => {
		const { t } = useTranslation();
		const [loadingQrImage, setLoadingQrImage] = useState(false);

		const downloadBase64File = (
			contentType: string,
			base64Data: string,
			fileName?: string
		) => {
			const linkSource = `data:${contentType};base64,${base64Data}`;
			// const downloadLink = document.createElement('a');
			// downloadLink.href = linkSource;
			// downloadLink.download = fileName || '';
			// downloadLink.click();
			// downloadLink?.parentNode?.removeChild(downloadLink);
			dynamicFileDownload(linkSource, fileName || '');
		};

		const getPic = async () => {
			setLoadingQrImage(true);
			await axios
				.get(downloadQrcodeApi, {
					responseType: 'arraybuffer'
				})
				.then((res) => {
					const raw = Buffer.from(res.data).toString('base64');
					downloadBase64File(
						'image/png',
						raw,
						'QR-Code-' + slugify(title as string)
					);
				})
				.finally(() => {
					setLoadingQrImage(false);
				});
		};

		return (
			<ModalBox
				isOpen={isOpen}
				onClose={onClose}
				title={t(`title.${namespace}-qrcode`)}>
				{!isLoading && data && (
					<div
						className={styles.image}
						dangerouslySetInnerHTML={{ __html: data }}
					/>
				)}
				{isLoading && <LoadingMask className={styles.image} />}
				<p className="text-gray-4 text-center mt-2">
					{t(`text.scan-${namespace}-qrcode`)}
				</p>
				<ModalBoxFooter className="justify-content-center my-3">
					<SubmitButton
						type="button"
						icon="download"
						isSubmitting={loadingQrImage}
						savingLabel={t('button.preparing-qrcode')}
						color={Colors['white-primary']}
						label={t('button.download-qrcode')}
						onClick={() => getPic()}
					/>
				</ModalBoxFooter>
			</ModalBox>
		);
	}
);

ModalBoxQrCode.displayName = 'ModalBoxQrCode';
