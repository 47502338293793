import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { clx } from 'Utils';
import { FontAwesome } from 'Elements';

type Props = {
	icon: any;
	title: string;
	desc: string;
	className?: string;
	fill?: string;
};

export const JobStatisticCard: FC<Props> = memo(
	({ icon, title, desc, fill, className }) => {
		const { t } = useTranslation();

		return (
			<div
				className={clx(
					className,
					'd-flex flex-row flex-lg-column gap-3 gap-lg-2 align-items-center'
				)}>
				<FontAwesome icon={icon} size="2x" fixedWidth color={fill} />
				<div className="d-flex flex-column align-items-start align-items-lg-center flex-grow-1">
					<h6 className="mb-0 lh-base fs-sm fw-500 text-center text-gray">
						{t(title)}
					</h6>
					<span className="fw-300 text-start text-md-center">
						{desc || '----'}
					</span>
				</div>
			</div>
		);
	}
);

JobStatisticCard.displayName = 'JobStatisticCard';
