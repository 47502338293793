import { memo, FC, startTransition, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalProps } from 'Types';
import { api, toastify } from 'Utils';
import {
    ModalBox,
    FileUploaderBox,
    FormInlineCheckbox,
} from 'Elements';

interface Props extends ModalProps {
    contactGroupId: string;
    onSubmitModal: () => void;
}

export const ModalUploaderFileContactPeople: FC<Props> = memo(
    ({ contactGroupId, isOpen, onSubmitModal, onClose }) => {
        const { t } = useTranslation();
        const [deleteAllExistingItems, setDeleteAllExistingItems] = useState(false);

        const handleUploadCV = (data: any) => {
            if (data?.status === 200) {
                startTransition(() => {
                    toastify('toastify.contact-people.import', {
                        type: 'success'
                    });
                });
                onClose();
                onSubmitModal();
            }
        };

        const onCheck = (isChecked: boolean) => {
            setDeleteAllExistingItems(isChecked);
        };

        return (
            <ModalBox isOpen={isOpen} onClose={onClose} title={t('title.import-contacts')}>
                <FileUploaderBox
                    onChange={handleUploadCV}
                    nameKey="csvFile"
                    url={api.importContactPeople(contactGroupId as string, deleteAllExistingItems as boolean)}
                />
                <FormInlineCheckbox
                    id="delete"
                    className="d-flex mb-3"
                    label={t('label.delete-all-existing-item') as string}
                    onChange={onCheck}
                />
            </ModalBox>
        );
    }
);

ModalUploaderFileContactPeople.displayName = 'ModalUploaderFileContactPeople';
