import { FC, ReactNode, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { clx } from 'Utils';
import { ColorValues } from 'Types';
import { FontAwesome } from 'Elements';

interface Props {
	status: 'valid' | 'invalid';
	title: string;
	description?: ReactNode;
	className?: string;
}

export const CheckRequirement: FC<Props> = memo(
	({ status, title, description, className }) => {
		const { t } = useTranslation();
		return (
			<div className={clx('d-flex gap-2 py-3', className)}>
				<FontAwesome
					size="lg"
					fixedWidth
					className="mt-1"
					icon={status === 'valid' ? 'circle-check' : 'circle-xmark'}
					color={
						status === 'valid' ? ColorValues.green : ColorValues.red
					}
				/>
				<div className="flex-grow-1 d-flex flex-column gap-1">
					<div className="d-flex align-items-center">
						<h4 className="mb-0 lh-sm fs-base fw-400 d-inline-flex">
							{title}
						</h4>
						{status === 'invalid' && (
							<small className="ms-1 text-red">
								({t('validation.required')})
							</small>
						)}
						{status === 'valid' && (
							<small className="ms-1 text-green">
								({t('label.filled')})
							</small>
						)}
					</div>
					{description && status === 'invalid' && (
						<div className="text-gray-3 lh-base fs-small">
							{description}
						</div>
					)}
				</div>
			</div>
		);
	}
);

CheckRequirement.displayName = 'CheckRequirement';
