import { memo, FC } from 'react';
import { Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Card, FontAwesome } from 'Elements';
import { InternshipRegistrationFile, ColorValues } from 'Types';
import styles from 'partials/card/applicant-file.module.scss';

interface Props {
	card: InternshipRegistrationFile;
}

export const InternshipRegistrationFileCard: FC<Props> = memo(({ card }) => {
	const { t } = useTranslation();
	const extensions = {
		txt: 'file-lines',
		pdf: 'file-pdf',
		doc: 'file-word',
		docx: 'file-word',
		jpg: 'file-image',
		png: 'file-image',
		jpeg: 'file-image',
		zip: 'file-zipper'
	};
	const getFileExtensionIcon = () => {
		const re = /(?:\.([^.]+))?$/;
		const ext = re.exec(card.title)?.[1];
		return ext && extensions[ext.toLowerCase()]
			? extensions[ext.toLowerCase()]
			: extensions.txt;
	};

	return (
		<Col xs={6} sm={12} xl={6} xxl={4} className="mb-4">
			<Card className={styles.card}>
				<FontAwesome
					icon={getFileExtensionIcon()}
					size="4x"
					color={ColorValues['gray-3']}
					className="mb-3"
				/>
				<span
					className="text-break text-gray-3 lh-base text-center d-flex"
					title={card.title}>
					{card.title}
				</span>
				<a
					download={card.title}
					href={card.url}
					target="_blank"
					rel="noreferrer noopener"
					className={styles.download}>
					<FontAwesome
						icon="download"
						size="2x"
						color={ColorValues['gray-1']}
					/>
					<span className="mt-2 text-white fw-500">
						{t('forms.download')}
					</span>
				</a>
			</Card>
		</Col>
	);
});

InternshipRegistrationFileCard.displayName = 'InternshipRegistrationFileCard';
