import { startTransition } from 'react';
import axios from 'Adapter';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { api, fetch, toastify } from 'Utils';
import { queryKey } from 'Constants';
import { DataList, DataQueryParams, UserGroup, UserGroupFormData } from 'Types';

const config = (options = {}) => {
	return {
		...{ keepPreviousData: false },
		...options
	};
};

export const useGroups = <T = DataList<UserGroup>>(
	{ pageSize = 10, pageNumber = 1, keyword = '' }: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<UserGroup>>
): UseQueryResult<DataList<UserGroup>, unknown> => {
	return useQuery(
		{
			queryKey: [queryKey.groups, { pageSize, pageNumber, keyword }],
			queryFn: () => fetch.userGroupList(pageSize, pageNumber, keyword),
			...config(options)
		}
	);
};

export const useGroup = <T = UserGroup>(
	groupId: string,
	options?: UseQueryOptions<T, any, UserGroup>
): UseQueryResult<UserGroup, unknown> => {
	return useQuery(
		{
			queryKey: [queryKey.group, { id: groupId }],
			queryFn: () => fetch.userGroupDetails(groupId),
			...config(options)
		}
	);
};

export const useCreateGroup = () => {
	const submitRequest: (
		fields: UserGroupFormData,
		onFinal?: () => void
	) => Promise<void> = async (fields, onFinal) => {
		await axios.post(api.createUserGroup, fields).then((res) => {
			if (res.status === 200) {
				startTransition(() => {
					onFinal?.();
				});
			}
		});
	};

	return {
		submitRequest
	};
};

export const useUpdateGroup = (groupId: string) => {
	const query = useGroup(groupId);
	const group = query.data;

	const updateGroup: (
		fields: UserGroupFormData,
		onFinal?: () => void
	) => Promise<void> = async (fields, onFinal) => {
		await axios.put(api.updateUserGroup(groupId), fields).then((res) => {
			if (res.status === 200) {
				query.refetch();
				startTransition(() => {
					toastify('toastify.user-group-updated', {
						type: 'success'
					});
				});
				onFinal?.();
			}
		});
	};

	return { ...query, group, updateGroup };
};
