import { FC, memo, ReactNode } from 'react';
import { clx } from 'Utils';
import styles from 'partials/data-table/data-table.module.scss';

interface Props {
	children: ReactNode;
	className?: string;
}

export const DataTable: FC<Props> = memo(({ children, className }) => {
	const tableClass = clx(
		'd-flex justify-content-center flex-column',
		styles.table,
		className
	);
	return <div className={tableClass}>{children}</div>;
});

DataTable.displayName = 'DataTable';
