import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown
} from 'reactstrap';
import { clx } from 'Utils';
import { SelectOption } from 'Types';
import styles from 'partials/shared/heading/page-heading-filter.module.scss';

interface Shared {
	className?: string;
}

type Props = Shared &
	(
		| {
				filters?: SelectOption[];
				selectedFilter?: string;
				onChange?: (filter: SelectOption) => void;
		  }
		| {
				filters: SelectOption[];
				selectedFilter: string;
				onChange: (filter: SelectOption) => void;
		  }
	);

export const PageHeadingFilter: FC<Props> = memo(
	({ filters, selectedFilter, className, onChange }) => {
		const { t } = useTranslation();
		return (
			<UncontrolledDropdown
				className={clx(
					styles.box,
					className,
					'd-inline-flex align-items-center ms-2'
				)}>
				<DropdownToggle
					caret
					color="empty"
					size="xs"
					className="dropdown-toggle">
					{t('dropdown.filter-by')}:
					<span className="ms-2 font-weight-semibold text-gray-4">
						{selectedFilter}
					</span>
				</DropdownToggle>
				<DropdownMenu end>
					{filters &&
						filters?.map((filter) => {
							return (
								<DropdownItem
									key={filter.value}
									onClick={() => onChange?.(filter)}>
									{t(filter.label)}
								</DropdownItem>
							);
						})}
				</DropdownMenu>
			</UncontrolledDropdown>
		);
	}
);

PageHeadingFilter.displayName = 'PageHeadingFilter';
