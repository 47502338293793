import { memo, FC } from 'react';
import { Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { ApplicantFile } from 'Types';
import { ApplicantFileCard, Card, LoadingContent, NoData } from 'Elements';

interface Props {
	files: ApplicantFile[];
	isLoading: boolean;
}

export const RequestFiles: FC<Props> = memo(({ isLoading, files }) => {
	const { t } = useTranslation();
	return (
		<Card className="h-100">
			<h6 className="text-gray-3 mb-3 fs-6 fw-400">{t('title.files')}</h6>
			<Row>
				{files &&
					files?.map((file) => {
						return <ApplicantFileCard key={file.url} card={file} />;
					})}
				{files?.length === 0 && (
					<NoData iconClassName="px-4" message="title.no-file" />
				)}
			</Row>
			{isLoading && <LoadingContent />}
		</Card>
	);
});

RequestFiles.displayName = 'RequestFiles';
