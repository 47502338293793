import { FC, ReactNode, memo } from 'react';
import { DataTableAction } from 'Elements';
import { DataTableActionTypes } from 'Types';

interface Props {
	children?: ReactNode;
	onEditRow?: (() => void) | false;
	onDeleteRow?: (() => void) | false;
	onViewRow?: (() => void) | false;
}

export const DataTableOperation: FC<Props> = memo(
	({ onEditRow, onDeleteRow, onViewRow, children }) => {
		return (
			<div className="d-flex align-items-center flex-wrap gap-2">
				{onViewRow && (
					<DataTableAction
						type={DataTableActionTypes.view}
						onClick={onViewRow}
					/>
				)}
				{onEditRow && (
					<DataTableAction
						type={DataTableActionTypes.edit}
						onClick={onEditRow}
					/>
				)}
				{onDeleteRow && (
					<DataTableAction
						type={DataTableActionTypes.delete}
						onClick={onDeleteRow}
					/>
				)}
				{children}
			</div>
		);
	}
);

DataTableOperation.displayName = 'DataTableOperation';
