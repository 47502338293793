import { FC, memo } from 'react';
import { ColorValues } from 'Types';

interface Props {
	fill?: string;
	className?: string;
	size?: number;
}

export const IconSticker: FC<Props> = memo(
	({
		fill = ColorValues.primary,
		className,
		size = 100,
		...props
	}): JSX.Element => {
		return (
			<svg
				width={size}
				height={size}
				viewBox="0 0 100 100"
				xmlns="http://www.w3.org/2000/svg"
				className={className}
				{...props}>
				<path
					d="M70.667,37.333A33.333,33.333,0,1,1,37.333,4"
					transform="translate(87.333 12.667) rotate(90)"
					fill="#fff"
				/>
				<path
					d="M70.667,37.333A33.333,33.333,0,1,1,37.333,4"
					transform="translate(12.667 12.667)"
					fill={fill}
				/>
				<path d="M0,0H100V100H0Z" fill="none" />
				<path
					d="M46.989,37.333l-8.333,2.083A25,25,0,0,1,11.572,12.333L13.656,4,46.989,37.333"
					transform="translate(36.344 12.667)"
					fill={ColorValues['primary-light']}
				/>
				<g transform="translate(14.711 33.288)">
					<path
						d="M113.832,169.459h35.142"
						transform="translate(-98.292 -143.424)"
						fill={ColorValues['tertiary-light']}
						stroke={ColorValues.tertiary}
						strokeLinecap="round"
						strokeWidth="2"
					/>
					<path
						d="M113.832,166.181h10.992"
						transform="translate(-98.292 -147.291)"
						fill={ColorValues['tertiary-light']}
						stroke={ColorValues.tertiary}
						strokeLinecap="round"
						strokeWidth="2"
					/>
				</g>
			</svg>
		);
	}
);

IconSticker.displayName = 'IconSticker';
