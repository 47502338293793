import { FC, memo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { EventDetails } from 'Types';

interface Props {
	event: EventDetails;
}

const Text = styled.div`
	img {
		border-radius: 15px;
	}
`;

export const EventDetailsSection: FC<Props> = memo(({ event }) => {
	const { t } = useTranslation();
	return (
		<div className="order-1 order-md-0">
			<div className="mb-lg-3">
				<h4 className="h6 lh-base">{t('title.details')}</h4>
				<Text
					className="lh-md"
					dangerouslySetInnerHTML={{
						__html: event?.description || '---'
					}}
				/>
			</div>
		</div>
	);
});
EventDetailsSection.displayName = 'EventDetailsSection';
