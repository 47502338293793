import { FC, memo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Row } from 'reactstrap';
import { clx } from 'Utils';
import { AgentSetting } from 'Types';
import { useUpdateAgent } from 'Hooks';
import {
	LoadingSpinner,
	Card,
	LoadingMask,
	AgentJobCardLoading,
	SectionSwitcher,
	Editor
} from 'Elements';
import { AgentProfileFormData } from 'Types';
import styles from 'partials/homepage/section/profile-job-section.module.scss';

interface Props {
	agent?: AgentProfileFormData;
}

export const AgentPartnerJobSection: FC<Props> = memo(({ agent }) => {
	const { t } = useTranslation();
	const { control, reset } = useForm();
	const { setting, isLoading, updateAgentSetting } = useUpdateAgent();
	const [
		hasCheckOnlyMyCommunityPartnerJobs,
		setHasCheckOnlyMyCommunityPartnerJobs
	] = useState(false);
	const [hasCheckAllJobsInRegion, setHasCheckAllJobsInRegion] =
		useState(true);
	const [hasCheckBusinessPartners, setHasCheckBusinessPartners] =
		useState(true);

	const handleUpdate = async (field: Partial<AgentSetting>) => {
		await updateAgentSetting(field);
	};

	useEffect(() => {
		reset({
			...setting
		});
		setHasCheckAllJobsInRegion(
			setting?.jobHeadlineStatus && setting?.jobFilterSetting === 3 || setting?.jobFilterSetting === null
				? false
				: true
		);
		setHasCheckOnlyMyCommunityPartnerJobs(
			setting?.jobHeadlineStatus && setting?.jobFilterSetting === 2
				? false
				: true
		);
		setHasCheckBusinessPartners(
			setting?.jobHeadlineStatus && setting?.jobFilterSetting === 1
				? false
				: true
		);
	}, [reset, setting]);

	return (
		<>
			{isLoading && (
				<div className="d-flex align-items-center justify-content-center">
					<LoadingSpinner />
				</div>
			)}
			{!isLoading && setting && (
				<div className="mt-4 pt-3 pb-4 position-relative">
					{agent?.communityPartnerClass === 3 ? (
						<>
							<SectionSwitcher
								control={control}
								isActive={!setting?.jobHeadlineStatus}
								name="jobHeadlineStatus"
								label="forms.show-jobs-in-home"
								wrapperClassName={styles.switch}
								onSwitchChange={(val) =>
									handleUpdate({
										jobHeadlineStatus: val,
										jobFilterSetting:
											setting?.jobFilterSetting
												? setting?.jobFilterSetting
												: 3
									})
								}
							/>
							<FormGroup
								check
								className="d-flex align-items-center gap-2 mb-0">
								<input
									type="radio"
									checked={!hasCheckAllJobsInRegion}
									onClick={() => {
										handleUpdate({
											jobHeadlineStatus: true,
											jobFilterSetting:
												hasCheckAllJobsInRegion
													? 3
													: undefined
										});
										setHasCheckAllJobsInRegion(
											!hasCheckAllJobsInRegion
										);
										setHasCheckBusinessPartners(true);
										setHasCheckOnlyMyCommunityPartnerJobs(
											true
										);
									}}
									id="showAllJobsRegion"
									value="false"
									className="form-radio-input"
								/>
								<label
									htmlFor="showAllJobsRegion"
									className="form-radio-label">
									{t('forms.show-all-jobs-in-the-region')}
								</label>
							</FormGroup>
							<p className="fs-small fw-400 text-gray-3 mb-4 mt-0">
								{t(
									'forms.show-all-jobs-in-the-region-description'
								)}
							</p>
							<FormGroup
								check
								className="d-flex align-items-center gap-2 mb-0">
								<input
									type="radio"
									checked={!hasCheckBusinessPartners}
									onClick={() => {
										handleUpdate({
											jobHeadlineStatus: true,
											jobFilterSetting:
												hasCheckBusinessPartners
													? 1
													: undefined
										});
										setHasCheckBusinessPartners(
											!hasCheckBusinessPartners
										);
										setHasCheckOnlyMyCommunityPartnerJobs(
											true
										);
										setHasCheckAllJobsInRegion(true);
									}}
									id="businessPartners"
									value="false"
									className="form-radio-input"
								/>
								<label
									htmlFor="businessPartners"
									className="form-radio-label">
									{t(
										'forms.show-only-jobs-from-my-direct-business-partners'
									)}
								</label>
							</FormGroup>
							<p className="fs-small fw-400 text-gray-3 mb-4 mt-0">
								{t(
									'forms.show-only-jobs-from-my-direct-business-partners-description'
								)}
							</p>
							<FormGroup
								check
								className="d-flex align-items-center gap-2">
								<input
									type="radio"
									checked={
										!hasCheckOnlyMyCommunityPartnerJobs
									}
									onClick={() => {
										handleUpdate({
											jobHeadlineStatus: true,
											jobFilterSetting:
												hasCheckOnlyMyCommunityPartnerJobs
													? 2
													: undefined
										});
										setHasCheckOnlyMyCommunityPartnerJobs(
											!hasCheckOnlyMyCommunityPartnerJobs
										);
										setHasCheckBusinessPartners(true);
										setHasCheckAllJobsInRegion(true);
									}}
									id="communityPartners"
									value="false"
									className="form-radio-input"
								/>
								<label
									htmlFor="communityPartners"
									className="form-radio-label">
									{t(
										'forms.show-all-jobs-in-the-regions-but-no-jobs-from-other-community-partners'
									)}
								</label>
							</FormGroup>
						</>
					) : (
						<>
							<SectionSwitcher
								control={control}
								isActive={!setting?.jobHeadlineStatus}
								name="jobHeadlineStatus"
								label="forms.show-jobs-in-home"
								wrapperClassName={styles.switch}
								onSwitchChange={(val) =>
									handleUpdate({
										jobHeadlineStatus: val,
										jobFilterSetting:
											setting?.jobFilterSetting
												? setting?.jobFilterSetting
												: 3
									})
								}
							/>
							<FormGroup
								check
								className="d-flex align-items-center gap-2 mb-0">
								<input
									type="radio"
									checked={!hasCheckAllJobsInRegion}
									onClick={() => {
										handleUpdate({
											jobHeadlineStatus: true,
											jobFilterSetting:
												hasCheckAllJobsInRegion
													? 3
													: undefined
										});
										setHasCheckAllJobsInRegion(
											!hasCheckAllJobsInRegion
										);
										setHasCheckBusinessPartners(true);
										setHasCheckOnlyMyCommunityPartnerJobs(
											true
										);
									}}
									id="showAllJobsRegion"
									value="false"
									className="form-radio-input"
								/>
								<label
									htmlFor="showAllJobsRegion"
									className="form-radio-label">
									{t('forms.show-all-jobs-in-the-region')}
								</label>
							</FormGroup>
							<p className="fs-small fw-400 text-gray-3 mb-4 mt-0">
								{t(
									'forms.show-all-jobs-in-the-region-description'
								)}
							</p>
							<FormGroup
								check
								className="d-flex align-items-center gap-2">
								<input
									type="radio"
									checked={
										!hasCheckOnlyMyCommunityPartnerJobs
									}
									onClick={() => {
										handleUpdate({
											jobHeadlineStatus: true,
											jobFilterSetting:
												hasCheckOnlyMyCommunityPartnerJobs
													? 2
													: undefined
										});
										setHasCheckOnlyMyCommunityPartnerJobs(
											!hasCheckOnlyMyCommunityPartnerJobs
										);
										setHasCheckBusinessPartners(true);
										setHasCheckAllJobsInRegion(true);
									}}
									id="communityPartners"
									value="false"
									className="form-radio-input"
								/>
								<label
									htmlFor="communityPartners"
									className="form-radio-label">
									{t(
										'forms.show-all-jobs-in-the-regions-but-no-jobs-from-other-community-partners'
									)}
								</label>
							</FormGroup>
						</>
					)}
					<h3 className="h3 text-gray-5 mb-5 pb-5 lh-base d-inline-flex mt-3">
						<Editor
							type="text"
							value={
								setting?.jobHeadline ||
								`[${t('placeholder.job-list-headline')}]`
							}
							onSave={(val) => handleUpdate({ jobHeadline: val })}
						/>
					</h3>
					<Row>
						<Col xl={3} className="d-none d-xl-block">
							<Card
								background="gray-1"
								className={clx(styles.card, 'shadow-none')}>
								<div className={styles.sidebar_header}>
									<LoadingMask
										className={clx(
											styles.loading,
											styles.loadingLg
										)}
									/>
								</div>
								<div className={styles.sidebar_body}>
									<div className={styles.sidebar_section}>
										<LoadingMask
											className={clx(
												styles.loading,
												styles.loadingSm,
												'mb-3'
											)}
										/>
										<LoadingMask
											className={clx(
												styles.loading,
												styles.loadingMd,
												'mb-3'
											)}
										/>
										<LoadingMask
											className={clx(
												styles.loading,
												styles.loadingLg
											)}
										/>
									</div>
									<div className={styles.sidebar_section}>
										<LoadingMask
											className={clx(
												styles.loading,
												styles.loadingSm,
												'mb-3'
											)}
										/>
										<LoadingMask
											className={clx(
												styles.loading,
												styles.loadingMd,
												'mb-3'
											)}
										/>
										<LoadingMask
											className={clx(
												styles.loading,
												styles.loadingLg
											)}
										/>
									</div>
									<div className={styles.sidebar_section}>
										<LoadingMask
											className={clx(
												styles.loading,
												styles.loadingSm,
												'mb-3'
											)}
										/>
										<LoadingMask
											className={clx(
												styles.loading,
												styles.loadingMd,
												'mb-3'
											)}
										/>
										<LoadingMask
											className={clx(
												styles.loading,
												styles.loadingLg,
												'mb-3'
											)}
										/>
									</div>
								</div>
							</Card>
						</Col>
						<Col xl={9}>
							<AgentJobCardLoading count={3} />
						</Col>
					</Row>
				</div>
			)}
		</>
	);
});

AgentPartnerJobSection.displayName = 'AgentPartnerJobSection';
