import { memo, FC, useState } from 'react';
import axios from 'Adapter';
import { useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { api, toastify } from 'Utils';
import { containerVariants } from 'Constants';
import { useContactGroups, useEventAllInvitations } from 'Hooks';
import { motion } from 'framer-motion';
import {
	ModalBox,
	PageHeadingSearch,
	SubmitButton,
	NoData,
	Button,
	Scrollbar,
	InviteContactGroupCard,
	InviteContactGroupCardLoading,
	ModalBoxFooter,
	Pagination,
	FormInlineCheckbox,
	SystemErrorAlert
} from 'Elements';
import { Colors, ModalProps } from 'Types';
import styles from 'partials/page/event.module.scss';

interface Props extends ModalProps {
	isPartner?: boolean;
}

export const ModalBoxInviteGuestsContactGroups: FC<Props> = memo(
	({ isOpen, onClose, isPartner = true }) => {
		const { t } = useTranslation();
		const { event_id } = useParams();
		const [searchParams, setSearchParams] = useSearchParams();
		const [isSubmit, setIsSubmit] = useState(false);
		const [usePartners, setUsePartners] = useState(false);
		const [isDisable, setIsDisable] = useState(true);
		const params = Object.fromEntries([...searchParams]);
		const pageParam = searchParams.get('page') || 1;
		const queryParam = searchParams.get('q') || '';
		const statusParam = searchParams.get('isActive') || '';

		const [state, setState] = useState<{
			isActive: '';
			pageSize: number;
			currentPage: string | number;
			keyword: string;
			filterBy: string;
			isRemoving: boolean;
			selectedContactGroups: string[];
		}>({
			isActive: '',
			pageSize: 5,
			currentPage: pageParam,
			keyword: queryParam,
			filterBy: statusParam,
			isRemoving: false,
			selectedContactGroups: []
		});

		const { data, isFetching, isError } = useContactGroups({
			isActive: true,
			pageSize: state.pageSize,
			pageNumber: state.currentPage,
			keyword: state.keyword
		});

		const { refetch } = useEventAllInvitations({
			eventId: event_id as string,
			isPartner
		});

		const handlePageChange = (page: number) => {
			setSearchParams({ ...params, page: page.toString() });
			setState((prev) => ({
				...prev,
				currentPage: page
			}));
		};

		const inviteContactGroups = async () => {
			setIsSubmit(true);
			const config = {
				contactGroupIds: state?.selectedContactGroups,
				isPartner: isPartner,
				usePartners: usePartners
			};
			await axios
				.post(
					api.inviteGuestByContactGroups(event_id as string),
					config
				)
				.then((res) => {
					if (res.status === 200) {
						onClose?.();
						refetch();
						toastify('toastify.invitation-sent', {
							type: 'success'
						});
					}
				});
		};

		const handleSearch = (query: string) => {
			setState((prev) => ({
				...prev,
				keyword: query
			}));
		};

		const handleCheck = (id: string, checked: boolean) => {
			let selected: string[] = state.selectedContactGroups;
			if (!checked) {
				selected?.push(id);
			} else {
				selected = [];
				state.selectedContactGroups?.map((item) => {
					if (item !== id) {
						selected?.push(item);
					}
				});
			}
			if (selected.length === 0 && !usePartners) {
				setIsDisable(true);
			} else {
				setIsDisable(false);
			}
			setState((prev) => ({
				...prev,
				selectedContactGroups: selected
			}));
		};


		const onCheckAllMyPartners = (isChecked: boolean) => {
			const selected: string[] = state.selectedContactGroups;
			if (isChecked) {
				setUsePartners(true)
			} else {
				setUsePartners(false)
			}
			if (selected.length === 0 && !isChecked) {
				setIsDisable(true);
			} else {
				setIsDisable(false);
			}
		};

		return (
			<ModalBox
				isOpen={isOpen}
				onClose={onClose}
				title={t('title.invite-contact-groups')}>
				<div className="d-flex mb-3 align-items-center gap-3 flex-wrap">
					<PageHeadingSearch onSubmit={handleSearch} />
					{data?.items?.length && (
						<div className="ms-auto d-flex align-items-center gap-1 text-gray-4 fw-300">
							<span className="fw-500 text-gray-5">
								{data?.totalItems}
							</span>
							{t('pages.contact-groups-list')}
						</div>
					)}
				</div>
				<FormInlineCheckbox
					id="delete"
					isChecked={usePartners}
					className="d-flex py-2 border-bottom"
					label={t('label.all-my-partners') as string}
					onChange={onCheckAllMyPartners}
				/>
				{!isFetching && data && data?.items?.length > 0 && (
					<Scrollbar className={styles.scrollbar}>
						<div>
							{data?.items?.map((group) => {
								return (
									<InviteContactGroupCard
										key={group.id}
										card={group}
										isChecked={
											state?.selectedContactGroups?.find(
												(element) =>
													element === group?.id
											)
												? true
												: false
										}
										onClick={(id, checked) => {
											handleCheck(id, checked);
										}}
									/>
								);
							})}
						</div>
					</Scrollbar>
				)}
				{isError && <SystemErrorAlert />}
				{isFetching && <InviteContactGroupCardLoading />}
				{!isFetching && data?.items?.length === 0 && (
					<NoData message="title.no-contact-group" />
				)}
				{!isFetching && data && data?.totalPages > 1 && (
					<Pagination
						className="mt-3"
						totalItemsCount={data.totalItems}
						activePage={parseInt(state.currentPage.toString(), 10)}
						itemsCountPerPage={state.pageSize}
						onChange={handlePageChange}
					/>
				)}
				<ModalBoxFooter className="justify-content-end pt-3 gap-3">
					<Button
						color={Colors['white-red']}
						label={t('button.cancel') as string}
						onClick={onClose}
					/>
					<SubmitButton
						icon="paper-plane"
						isDisable={isDisable}
						isSubmitting={isSubmit}
						label={t('button.send-invitation') as string}
						onClick={inviteContactGroups}
					/>
				</ModalBoxFooter>
			</ModalBox>
		);
	}
);

ModalBoxInviteGuestsContactGroups.displayName =
	'ModalBoxInviteGuestsContactGroups';
