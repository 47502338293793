import { Country, SelectableCountry, SelectableState } from 'Types';

export const countries: Array<Country> = [
	{
		id: 'DE',
		name: 'Deutschland',
		states: [
			{
				id: 'Bayern',
				name: 'Bayern',
				country: 'DE'
			},
			{
				id: 'Baden-Württemberg',
				name: 'Baden-Württemberg',
				country: 'DE'
			},
			{
				id: 'Hessen',
				name: 'Hessen',
				country: 'DE'
			},
			{
				id: 'Berlin',
				name: 'Berlin',
				country: 'DE'
			},
			{
				id: 'Brandenburg',
				name: 'Brandenburg',
				country: 'DE'
			},
			{
				id: 'Bremen',
				name: 'Bremen',
				country: 'DE'
			},
			{
				id: 'Hamburg',
				name: 'Hamburg',
				country: 'DE'
			},
			{
				id: 'Mecklenburg-Vorpommern',
				name: 'Mecklenburg-Vorpommern',
				country: 'DE'
			},
			{
				id: 'Niedersachsen',
				name: 'Niedersachsen',
				country: 'DE'
			},
			{
				id: 'Nordrhein-Westfalen',
				name: 'Nordrhein-Westfalen',
				country: 'DE'
			},
			{
				id: 'Rheinland-Pfalz',
				name: 'Rheinland-Pfalz',
				country: 'DE'
			},
			{
				id: 'Saarland',
				name: 'Saarland',
				country: 'DE'
			},
			{
				id: 'Sachsen',
				name: 'Sachsen',
				country: 'DE'
			},
			{
				id: 'Sachsen-Anhalt',
				name: 'Sachsen-Anhalt',
				country: 'DE'
			},
			{
				id: 'Schleswig-Holstein',
				name: 'Schleswig-Holstein',
				country: 'DE'
			},
			{
				id: 'Thüringen',
				name: 'Thüringen',
				country: 'DE'
			}
		]
	},
	{
		id: 'AT',
		name: 'Österreich',
		states: [
			{
				id: 'Burgenland',
				name: 'Burgenland',
				country: 'AT'
			},
			{
				id: 'Kärnten',
				name: 'Kärnten',
				country: 'AT'
			},
			{
				id: 'Niederösterreich',
				name: 'Niederösterreich',
				country: 'AT'
			},
			{
				id: 'Oberösterreich',
				name: 'Oberösterreich',
				country: 'AT'
			},
			{
				id: 'Salzburg',
				name: 'Salzburg',
				country: 'AT'
			},
			{
				id: 'Steiermark',
				name: 'Steiermark',
				country: 'AT'
			},
			{
				id: 'Tirol',
				name: 'Tirol',
				country: 'AT'
			},
			{
				id: 'Vorarlberg',
				name: 'Vorarlberg',
				country: 'AT'
			}
		]
	}
];

export const getCountries = () => {
	const country_arr = new Array<SelectableCountry>();
	countries.forEach((country: Country) => {
		country_arr.push({
			value: country.id,
			label: country.name,
			states: country.states.map((state) => {
				return {
					value: state.id,
					label: state.name
				};
			})
		});
	});

	return country_arr;
};

export const getStates = () => {
	const states = new Array<SelectableState>();
	countries.forEach((country) => {
		country.states.forEach((state) => {
			states.push({
				value: state.id,
				label: state.name,
				country: state.country
			});
		});
	});
	return states;
};

export const getStatesByCountry = (selected: SelectableCountry) => {
	return getStates().filter(
		(state) =>
			state?.country?.toUpperCase() === selected?.value?.toString()?.toUpperCase()
	);
};

export const getStatesByCountryId = (selected: string) => {
	return getStates().filter(
		(state) => state?.country?.toUpperCase() === selected?.toUpperCase()
	);
};

export const getCountryNameById = (id: string) => {
	return getCountries().find((country) => country.value === id)?.label || '';
};

export const getStateNameById = (id: string) => {
	return getStates().find((state) => state.value === id)?.label || '';
};
