import reducers from './MarketingReducer';
import { createSlice } from '@reduxjs/toolkit';
import { MarketingState } from 'Types';

export const initialState: MarketingState = {
	selectedAsset: null,
	selectedTemplate: null,
	showLogo: true,
	hasErrors: false,
	originalLogo: null,
	errors: {
		sizeElement: null,
		textColorElement: null,
		textBgColorElement: null,
		imageElement: null,
		headlineElement: null,
		qrCodeElement: null,
		simpleLogoElement: null,
		nameElement: null,
		emailElement: null,
		phoneElement: null
	},
	assetConfig: {
		sizeElement: null,
		textColorElement: null,
		textBgColorElement: null,
		imageElement: null,
		headlineElement: null,
		qrCodeElement: null,
		simpleLogoElement: null,
		nameElement: null,
		emailElement: null,
		phoneElement: null
	}
};

const MarketingSlice = createSlice({
	name: 'marketing',
	initialState,
	reducers
});

export const {
	setSelectedAsset,
	setSelectedTemplate,
	setInputAssetData,
	setBackgroundImage,
	setSize,
	setTextColor,
	setTextBgColor,
	setHeadline,
	setShowLogo,
	setLogo,
	setOriginalLogo,
	setFieldValidationError,
	clearFieldValidationError,
	clearFieldValidationErrors,
	clearAssetConfig
} = MarketingSlice.actions;

export default MarketingSlice.reducer;
