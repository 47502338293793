import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { clx } from 'Utils';
import { AssetExportType } from 'Types';
import { Card, SubmitButton } from 'Elements';

interface Props {
	className?: string;
	exportType: AssetExportType[];
	exporting: {
		pdf: boolean;
		jpg: boolean;
	};
	onExport?: (type: AssetExportType) => void;
}
export const ToolboxExportSection: FC<Props> = memo(
	({ className, onExport, exportType, exporting }) => {
		const { t } = useTranslation();

		return (
			<Card
				className={clx(
					className,
					'd-flex align-items-xxl-center flex-column flex-sm-row flex-md-column flex-xxl-row gap-3'
				)}>
				<p className="text-gray-3 lh-base mb-0">
					{t('text.marketing.click-to-generate')}
				</p>
				<div className="flex-grow-1 flex-shrink-0 d-flex align-items-center justify-content-xxl-end flex-xxl-wrap gap-3">
					{exportType.includes(AssetExportType.pdf) && (
						<SubmitButton
							type="button"
							icon="download"
							isSubmitting={exporting.pdf}
							label="button.marketing.generate-pdf"
							savingLabel="button.marketing.generating"
							onClick={() => onExport?.(AssetExportType.pdf)}
						/>
					)}
					{exportType.includes(AssetExportType.jpg) && (
						<SubmitButton
							type="button"
							icon="download"
							isSubmitting={exporting.jpg}
							label="button.marketing.generate-jpg"
							savingLabel="button.marketing.generating"
							onClick={() => onExport?.(AssetExportType.jpg)}
						/>
					)}
				</div>
			</Card>
		);
	}
);

ToolboxExportSection.displayName = 'ToolboxExportSection';
