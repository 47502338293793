import { memo, FC, useState } from 'react';
import axios from 'Adapter';
import { useOutletContext, useParams } from 'react-router-dom';
import { api } from 'Utils';
import { useParticipant } from 'Hooks';
import { AgentEvent, EventParticipant, EventStatus } from 'Types';
import {
	EventParticipantCard,
	EventInvitedCardLoading,
	NoData,
	PageHeadingSearch,
	Pagination,
	Scrollbar
} from 'Elements';
import styles from 'partials/page/event.module.scss';

export const EventPartnerParticipantList: FC = memo(() => {
	const { event_id } = useParams();
	const fields = useOutletContext<AgentEvent>();
	const isEventFinishOrCancel =
		fields?.isFinished || fields?.eventStatus === EventStatus.Canceled;

	const [state, setState] = useState({
		pageSize: 10,
		currentPage: 1,
		keyword: ''
	});

	const {
		data: list,
		isFetching,
		refetch
	} = useParticipant({
		eventId: event_id,
		isPartner: true,
		status: '',
		pageSize: state.pageSize,
		pageNumber: state.currentPage,
		keyword: state.keyword
	});

	const handleSearch = (query: string) => {
		setState((prev) => ({
			...prev,
			currentPage: 1,
			keyword: query
		}));
	};

	const handlePageChange = (page: number) => {
		setState((prev) => ({
			...prev,
			currentPage: page
		}));
	};

	const handleCancelInvite = (participant: EventParticipant) => {
		axios
			.post(api.cancelInvitation(event_id as string, participant.id))
			.then((res) => {
				if (res.status === 200) {
					refetch();
				}
			});
	};

	return (
		<div className="pt-2">
			<PageHeadingSearch onSubmit={handleSearch} className="mb-3" />
			{!isFetching && list && (
				<Scrollbar className={styles.scrollbar}>
					<div>
						{list.items.map((participant) => {
							return (
								<EventParticipantCard
									key={participant.id}
									card={participant}
									cancelable={
										!isEventFinishOrCancel &&
										participant.status !== 1
									}
									onCancel={() =>
										handleCancelInvite(participant)
									}
								/>
							);
						})}
					</div>
				</Scrollbar>
			)}
			{isFetching && <EventInvitedCardLoading />}
			{!isFetching && list?.items?.length === 0 && (
				<NoData message="title.no-participant" />
			)}
			{!isFetching && list && list?.totalPages > 1 && (
				<Pagination
					className="mt-3"
					totalItemsCount={list.totalItems}
					activePage={parseInt(state.currentPage.toString(), 10)}
					itemsCountPerPage={state.pageSize}
					onChange={handlePageChange}
				/>
			)}
		</div>
	);
});

EventPartnerParticipantList.displayName = 'EventPartnerParticipantList';
