import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { clx, formatTimeToNow } from 'Utils';
import {
	Button,
	Card,
	LoadingContent,
	ShimmerImage,
	FontAwesome
} from 'Elements';
import {
	Colors,
	CompanyParticipatedEvent,
	EventParticipationStatus,
	RegistrationStatus
} from 'Types';
import styles from 'partials/page/my-event.module.scss';

interface Props {
	className?: string;
	event: CompanyParticipatedEvent;
	isSubmitting: boolean;
	onAccept: () => void;
	onReject: () => void;
}

export const CompanyParticipatedEventStatusBox: FC<Props> = memo(
	({ className, event, isSubmitting, onReject, onAccept }) => {
		const { t } = useTranslation();
		const defaultLogo = '/assets/img/agent-default.jpg';
		const eventInfo = event.eventInfo;
		const status = event.status;
		const partnerRegistrationStatus = event.partnerRegistrationStatus;

		return (
			<Card
				className={clx(
					className,
					styles.card,
					status === EventParticipationStatus.Active &&
						styles.approved,
					(status === EventParticipationStatus.Canceled ||
						status === EventParticipationStatus.CanceledByAgent) &&
						styles.rejected,
					event.isFinished && styles.finished
				)}>
				<h6 className="fs-sm text-gray-5 fw-400">
					{t('label.hosted-by')}:
				</h6>
				<div className="d-flex align-items-center mt-2">
					<div className={styles.logoBox}>
						<ShimmerImage
							src={eventInfo.agentLogo || defaultLogo}
							width={35}
							height={35}
							defaultImage={defaultLogo}
							alt={eventInfo.agentName}
							className={styles.logo}
						/>
					</div>
					<span className="fs-small">{eventInfo.agentName}</span>
				</div>
				{partnerRegistrationStatus !== RegistrationStatus.Finished && (
					<div className="mt-3">
						{status !== EventParticipationStatus.Active &&
							partnerRegistrationStatus !==
								RegistrationStatus.Open && (
								<time className={styles.timeRow}>
									<span className="d-flex align-items-center">
										<FontAwesome
											icon="timer"
											size="sm"
											className="me-2 flex-shrink-0"
										/>
										{t('label.event.register-start-in')}:
									</span>
									<span className="fw-300">
										{formatTimeToNow(
											eventInfo.partnerRegistrationStartDate ||
												eventInfo.startDate
										)}
									</span>
								</time>
							)}
						{status !== EventParticipationStatus.Active && (
							<time className={styles.timeRow}>
								<span className="d-flex align-items-center">
									<FontAwesome
										icon="timer"
										size="sm"
										className="me-2 flex-shrink-0"
									/>
									{t('label.event.register-end-in')}:
								</span>
								<span className="fw-300">
									{formatTimeToNow(
										eventInfo.partnerRegistrationEndDate ||
											eventInfo.endDate
									)}
								</span>
							</time>
						)}
					</div>
				)}
				{(event.isFinished || event.isCanceled) && (
					<p className="mt-3 fw-500">
						{event.isFinished && t('text.event.finished')}
						{event.isCanceled && t('text.event.canceled')}
					</p>
				)}
				{!event.isCanceled &&
					!event.isFinished &&
					partnerRegistrationStatus === RegistrationStatus.Open && (
						<div className="d-flex align-items-center gap-3 flex-wrap mt-auto pt-3">
							{status === EventParticipationStatus.Active && (
								<>
									<div className="flex-grow-1 d-flex align-items-center">
										{t('label.event.number-of-seat')}:
										<strong className="fw-500 ms-2">
											{event.numberOfSeats}
										</strong>
									</div>
									<Button
										color={Colors['white-red']}
										label={
											t('button.event.cancel') as string
										}
										onClick={onReject}
									/>
								</>
							)}
							{status === EventParticipationStatus.Canceled && (
								<Button
									label={
										t('button.event.re-accept') as string
									}
									className="flex-xl-fill"
									onClick={onAccept}
								/>
							)}
						</div>
					)}
				{isSubmitting && <LoadingContent />}
			</Card>
		);
	}
);

CompanyParticipatedEventStatusBox.displayName =
	'CompanyParticipatedEventStatusBox';
