import { useRef, useState, useEffect } from 'react';
import axios from 'Adapter';
import { useTranslation } from 'react-i18next';
import { Controller, UseControllerProps } from 'react-hook-form';
import { api, clx } from 'Utils';
import { useModal } from 'Hooks';
import {
	AspectRatio,
	Colors,
	Event,
	FieldValues,
	Media,
	ModalProps
} from 'Types';
import {
	Button,
	LoadingSpinner,
	ModalBox,
	ModalBoxCropImage,
	ModalBoxFooter,
	FontAwesome
} from 'Elements';
import styles from 'partials/modal/modalbox-change-image.module.scss';

type Props<T extends FieldValues> = {
	title: string;
	image: string;
	aspectRatio?: AspectRatio;
	defaultImage?: string;
	onRemove?: () => void;
	onImageChange?: (image_data: Media) => void;
} & UseControllerProps<T> &
	ModalProps;

export const ModalBoxChangeImage = <T extends FieldValues>({
	control,
	name,
	isOpen,
	onClose,
	title,
	image,
	aspectRatio,
	defaultImage = '/assets/img/default-image.jpg',
	onImageChange,
	onRemove
}: Props<T>): JSX.Element => {
	const { t } = useTranslation();
	const [openModalChange, toggleModalChange] = useModal();
	const imageFileInput = useRef<HTMLInputElement>(null);
	const [state, setState] = useState({
		imageLoading: false,
		blobImage: '',
		currentImage: image,
		fileName: ''
	});

	const handleRemove = () => {
		setState((prev) => ({
			...prev,
			currentImage: ''
		}));
		onRemove?.();
	};

	const onUploadImage = () => {
		imageFileInput?.current?.click();
	};

	const uploadImageRequest: (
		formData: FormData,
		onFinal: () => void
	) => Promise<Media> = async (formData, onFinal) => {
		return await axios
			.post(api.uploadMedia, formData)
			.then((res) => {
				if (res.status === 200) {
					return res.data;
				}
			})
			.finally(() => onFinal?.());
	};

	const handleCropImage = async (image: FormData) => {
		if (image) {
			setState((prev) => ({
				...prev,
				imageLoading: true
			}));
			const res = await uploadImageRequest(image, () =>
				setState((prev) => ({
					...prev,
					imageLoading: false
				}))
			);
			if (res) {
				onImageChange?.(res);
				onClose();
			}
		}
	};

	const uploadImage = async (e: Event<HTMLInputElement>) => {
		// Reference to the DOM input element
		const { files } = e.target;

		// Ensure that you have a file before attempting to read it
		if (files && files[0]) {
			const blob = URL.createObjectURL(files[0]);
			const reader = new FileReader();

			reader.onload = () => {
				// Read image as base64 and set it as src:
				setState((prev) => ({
					...prev,
					blobImage: blob,
					fileName: files?.[0]?.name ?? ''
				}));
				toggleModalChange();
			};
			// Start the reader job - read file as a data url (base64 format) and get the real file type
			reader.readAsArrayBuffer(files[0]);
			// reader.readAsDataURL(files?.[0]);
		}
		// Clear the event target value to give the possibility to upload the same image:
		e.target.value = '';
	};

	useEffect(() => {
		setState((prev) => ({
			...prev,
			currentImage: image
		}));
	}, [image]);

	useEffect(() => {
		// Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
		return () => {
			if (state.blobImage) {
				URL.revokeObjectURL(state.blobImage);
			}
		};
	}, [state.blobImage]);

	return (
		<ModalBox isOpen={isOpen} onClose={onClose} title={title} size="lg">
			{control && (
				<Controller
					control={control}
					name={name}
					render={({ field: { onChange } }) => (
						<input
							ref={imageFileInput}
							type="file"
							accept="image/png, image/jpg, image/jpeg"
							className="d-none"
							onChange={(val) => {
								onChange(val);
								uploadImage(val);
							}}
						/>
					)}
				/>
			)}
			{!control && (
				<input
					ref={imageFileInput}
					type="file"
					accept="image/png, image/jpg, image/jpeg"
					className="d-none"
					onChange={(event) => uploadImage(event)}
				/>
			)}
			<div
				className={clx(
					styles.box,
					state.imageLoading && styles.boxLoading
				)}>
				<img
					src={state.currentImage || defaultImage}
					className={clx(
						styles.image,
						aspectRatio === '16/9' && styles.imageRatio
					)}
					alt=""
				/>
				<div className="text-center mt-3 text-gray-3 fs-sm">
					{t('forms.image-accept-type')}
				</div>
				{state.imageLoading && (
					<div className={styles.loading}>
						<LoadingSpinner size="2x" color="#fff" />
					</div>
				)}
			</div>
			<ModalBoxFooter>
				{onRemove && state.currentImage && (
					<Button
						color={Colors['white-red']}
						disabled={state.imageLoading}
						onClick={handleRemove}>
						<FontAwesome
							icon="trash-xmark"
							size="lg"
							className="me-2"
						/>
						{t('forms.remove')}
					</Button>
				)}
				<div className="ms-auto flex-shrink-0">
					<Button
						className="ms-2"
						disabled={state.imageLoading}
						onClick={onUploadImage}>
						<FontAwesome
							icon="arrow-up-from-bracket"
							size="lg"
							className="me-2"
						/>
						{t('button.upload-new-photo')}
					</Button>
				</div>
			</ModalBoxFooter>
			<ModalBoxCropImage
				isOpen={openModalChange}
				image={state.blobImage}
				aspectRatio={aspectRatio}
				onClose={toggleModalChange}
				onCrop={handleCropImage}
			/>
		</ModalBox>
	);
};
// );

ModalBoxChangeImage.displayName = 'ModalBoxChangeImage';
