export const config = {
	tenant: process.env.REACT_APP_TENANT,
	b2cTenant: process.env.REACT_APP_B2C_TENANT,
	clientId: process.env.REACT_APP_CLIENT_ID,
	b2cSigningPolicy: process.env.REACT_APP_B2C_SIGNING_POLICY,
	redirectUrl: process.env.REACT_APP_REDIRECT_URL,
	scope: process.env.REACT_APP_SCOPE,
	baseUrl: process.env.REACT_APP_BASE_URL,
	publicUrl: process.env.REACT_APP_PUBLIC_URL,
	subscriptionKey: process.env.REACT_APP_SUBSCRIPTION_KEY,
	enableEvent: process.env.REACT_APP_ENABLE_EVENT,
	widgetApiUrl: process.env.REACT_APP_WIDGET_API_URL,
	widgetScriptUrl: process.env.REACT_APP_WIDGET_SCRIPT_URL
};
