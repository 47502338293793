import { memo, FC, useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { clx, config } from 'Utils';
import { IntegrationPartnerJobs } from 'Types';
import { useAgentProfile, useAxios } from 'Hooks';
import {
	Button,
	Card,
	CopyButton,
	IntegrationJobCard,
	LoadingSpinner,
	ReactSwitch,
	Pagination
} from 'Elements';
import styles from 'partials/marketing/integration.module.scss';
import integrationStyles from 'partials/marketing/integration-job.module.scss';

export const JobsScript: FC = memo(() => {
	const { t } = useTranslation();
	const { sendRequest, isLoading: isSubmitting } = useAxios();
	const { data, isLoading } = useAgentProfile();
	const perRow = 10;
	const [next, setNext] = useState(perRow);
	const [state, setState] = useState<{
		show_BPJob: boolean;
		show_title: boolean;
		scriptData: IntegrationPartnerJobs | null;
		pageSize: number;
		currentPage: number;
	}>({
		show_BPJob: false,
		show_title: true,
		scriptData: null,
		pageSize: 10,
		currentPage: 1
	});
	const scriptUrl = config.widgetScriptUrl;
	const agentId = data?.id;

	const htmlContent = `<script src="${scriptUrl}/jobs/jobs-widget.min.js" id="jobs-widget" data-agent-id="${agentId}" data-widget-url="${config.widgetApiUrl}" data-script-url="${scriptUrl}" data-show-title="${state.show_title}" data-show-bp-job="${state.show_BPJob}"></script>`;

	const updateState = useCallback(
		(stateName: keyof typeof state, value: unknown) => {
			setState((prev) => ({
				...prev,
				[stateName]: value
			}));
		},
		[]
	);

	const getPreview = useCallback(async () => {
		const api_url = `${
			config.widgetApiUrl
		}/widget/agent/partnerjobs/${agentId}?onlyPartnerJobs=${
			state.show_BPJob
		}&pageSize=200&pageNumber=${1}`;
		await sendRequest(api_url, { method: 'GET' }, (data) => {
			updateState('scriptData', data);
		});
	}, [agentId, sendRequest, updateState, state.show_BPJob]);

	useEffect(() => {
		if (agentId) {
			getPreview();
		}
	}, [getPreview, agentId]);

	const handleMore = () => {
		setNext(next + perRow);
	};

	return (
		<div className="mb-4">
			<Card>
				<Row>
					<Col md={4} className="mb-3 mb-md-0">
						<h6 className="mb-3">{t('title.setting')}</h6>
						<FormGroup className="d-flex mb-3">
							<ReactSwitch
								id="showTitle"
								checked={state.show_title}
								onChange={(value) =>
									updateState('show_title', value)
								}
							/>
							<Label
								htmlFor="showTitle"
								className="ps-2 mb-0 pb-0 pt-0 cursor-pointer">
								{t('label.integration.show-title')}
							</Label>
						</FormGroup>
						{data?.commissionGroup?.isPremium && (
							<FormGroup className="d-flex mb-3">
								<ReactSwitch
									id="showBPJob"
									checked={state.show_BPJob}
									onChange={(value) =>
										updateState('show_BPJob', value)
									}
								/>
								<Label
									htmlFor="showBPJob"
									className="ps-2 mb-0 pb-0 pt-0 cursor-pointer">
									{t('label.integration.show-bp-jobs')}
								</Label>
							</FormGroup>
						)}
					</Col>
					<Col md={8}>
						<div className="d-flex flex-column">
							<h6 className="mb-3">
								{t('title.integration.your-script')}
							</h6>
							<pre className={styles.pre}>
								{isLoading ? <LoadingSpinner /> : htmlContent}
							</pre>
							<div className="d-flex align-items-center justify-content-end gap-2">
								{!isLoading && (
									<CopyButton textToCopy={htmlContent} />
								)}
							</div>
						</div>
					</Col>
				</Row>
			</Card>
			<div>
				<h6 className="mb-3 fs-6 text-gray-3">{t('title.preview')}</h6>
				{isSubmitting && (
					<div className={clx(styles.loading, 'm-3')}>
						<LoadingSpinner />
					</div>
				)}
				{state.scriptData && state.scriptData?.jobs.length > 0 && (
					<div className={integrationStyles.container}>
						{state.show_title && (
							<header className={integrationStyles.headline}>
								{state.show_title && (
									<h3
										className={
											integrationStyles.section_title
										}>
										{state.scriptData.headline}
									</h3>
								)}
							</header>
						)}
						<div className={integrationStyles.list}>
							{state.scriptData.jobs
								?.slice(0, next)
								.map((job) => {
									return (
										<IntegrationJobCard
											key={job.jobId}
											job={job}
										/>
									);
								})}
						</div>
						<div className="mt-4 d-flex align-items-center justify-content-center">
							{state.scriptData &&
								state.scriptData.jobs?.length > next && (
									<Button
										label={t('button.more-jobs') as string}
										onClick={handleMore}></Button>
								)}
						</div>
					</div>
				)}
			</div>
		</div>
	);
});

JobsScript.displayName = 'JobsScript';
