import { memo, useEffect, useState } from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import { Title } from 'Styles';
import { CompanySetting } from 'Types';
import { useTheme, useUpdateCompany } from 'Hooks';
import { Editor, FontAwesome, LoadingSpinner, SectionSwitcher } from 'Elements';

const Box = styled.div`
	background-color: ${({ theme }) =>
		theme && rgba(theme.company.primaryColor, 0.05)};
`;

export const CompanyJobHeadlineSection = memo(() => {
	const { t } = useTranslation();
	const { theme } = useTheme();
	const { control, reset } = useForm();
	const { setting, updateCompanySetting, isLoading } = useUpdateCompany();
	const [state, setState] = useState({
		jobCommonHeadline: '',
		jobText: ''
	});

	const handleUpdate: (
		field: Partial<CompanySetting>
	) => Promise<void> = async (field) => {
		setState((prev) => ({
			...prev,
			...field
		}));
		await updateCompanySetting(field);
	};

	useEffect(() => {
		reset(setting);
		setState({
			jobCommonHeadline:
				setting?.jobCommonHeadline ||
				`[${t('placeholder.job-common-headline')}]`,
			jobText: setting?.jobText || `[${t('placeholder.job-common-desc')}]`
		});
	}, [reset, setting, t]);

	return (
		<section className="position-relative mb-5 pb-5">
			<Container>
				{isLoading && (
					<div className="d-flex align-items-center justify-content-center my-5 py-5">
						<LoadingSpinner />
					</div>
				)}
				{!isLoading && setting && (
					<Row className="justify-content-center">
						<Col lg={10} xl={8} className="position-relative">
							<SectionSwitcher
								control={control}
								name="jobCommonHeadlineStatus"
								label="forms.show-job-common-in-home"
								isActive={!setting?.jobCommonHeadlineStatus}
								onSwitchChange={(val) =>
									handleUpdate({
										jobCommonHeadlineStatus: val
									})
								}
							/>
							<Box className="p-4 position-relative">
								<div className="d-flex align-items-center gap-2">
									<FontAwesome
										icon="grid-4"
										size="3x"
										color={theme.company.primaryColor}
									/>
									<Title
										textColor="text-gray-5"
										className="h3 mb-0 flex-grow-1">
										<Editor
											type="text"
											value={state.jobCommonHeadline}
											onSave={(val) =>
												handleUpdate({
													jobCommonHeadline: val
												})
											}
										/>
									</Title>
								</div>
								<div className="mt-2">
									<Editor
										type="textarea"
										className="text-gray-3 fs-base lh-base"
										value={state.jobText}
										onSave={(val) =>
											handleUpdate({
												jobText: val
											})
										}
									/>
								</div>
								{!setting?.jobCommonHeadlineStatus && (
									<div className="overlay overlay_white overlay-box" />
								)}
							</Box>
						</Col>
					</Row>
				)}
			</Container>
		</section>
	);
});

CompanyJobHeadlineSection.displayName = 'CompanyJobHeadlineSection';
