import { Address, Sector, SelectableAddress, Status } from 'Types';

export enum InternshipRegistrationApproveStatus {
	Pending = 0,
	Approve = 1,
	Reject = 2,
	CanceledByApplicant = 3
}

export interface InternshipRegistration {
	id: string;
	dateOfBirth: string;
	gender: number;
	tel: string;
	description: null;
	image: string;
	imageThumbnail: string;
	firstName: string;
	lastName: string;
	email: string;
	address: Address;
	applicantFiles: InternshipRegistrationFile[];
	terms?:any
	term?:any
}

export interface InternshipRegistration {
	id: string;
	requestDate: string;
	approveStatus: InternshipRegistrationApproveStatus;
	requestStatus: Status;
	jobId: string;
	code: string;
	shortDescription: string;
	applicantComment: string;
	title: string;
	slugUrl: string;
	applicant: InternshipRegistration;
	sectorId: string;
	sector: Sector;
}

export interface InternshipRegistrationFile {
	id: string;
	url: string;
	title: string;
	size: string;
}

export interface InternshipRegistrationState {
	firstName: string;
	lastName: string;
	email: string;
	postalCode: string;
	userRegisterId: string;
	address: SelectableAddress;
	selectedSectors: string[];
	selectedJobTypes: string[];
	selectedActivities: string[];
}
