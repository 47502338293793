import { memo, FC } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Colors, Internship } from 'Types';
import { api, clx, config, formatLocaleDateTime } from 'Utils';
import { useDelayUnmount, useInternshipQrCode, useModal } from 'Hooks';
import {
	Button,
	FontAwesome,
	ModalBoxQrCode,
	ModalBoxUnPublishInternship
} from 'Elements';
import styles from 'partials/job/job.module.scss';

interface Props {
	className?: string;
	internship: Internship;
}

export const InternshipPublishBar: FC<Props> = memo(({ internship, className }) => {
	const { t } = useTranslation();
	const { internship_id } = useParams() as { internship_id: string };
	const [openQrModal, toggleQrModal] = useModal();
	const { data, isLoading } = useInternshipQrCode(internship_id);
	const [openUnPublishModal, toggleUnPublishModal] = useModal();
	const shouldRenderQrhModal = useDelayUnmount(openQrModal, 350);
	const shouldRenderUnPublishModal = useDelayUnmount(openUnPublishModal, 350);
	const previewUrl = `${config.publicUrl}/job/${internship?.id}/${internship.publishedInfo.slugUrl}`;

	const redirectToPreview = () => {
		window.open(previewUrl, '_blank', 'noopener,noreferrer');
	};

	return (
		<div
			className={clx(
				styles.publishbar,
				className,
				'd-flex align-items-md-center flex-column flex-md-row'
			)}>
			<div className="d-flex align-items-md-center flex-column flex-md-row flex-wrap flex-grow-1 mb-3 mb-md-0 pe-3 gap-3">
				<div className="d-flex align-items-center me-2">
					<FontAwesome
						icon="code-branch"
						size="lg"
						className="me-2"
					/>
					<span className="text-gray-3 fw-400">
						{t('card.published-version')}:
					</span>
					<span className="ms-1 text-gray-5">
						{internship.publishedVersion ?? '...'}
					</span>
				</div>
				<div className="d-flex align-items-center">
					<FontAwesome
						icon="calendar-days"
						size="lg"
						className="me-2"
					/>
					<span className="text-gray-3">
						{t('card.published-date')}:
					</span>
					<span className="ms-1 text-gray-5">
						{internship?.publishedAt
							? formatLocaleDateTime(internship?.publishedAt)
							: '...'}
					</span>
				</div>
			</div>
			<div className="ms-md-auto d-flex align-items-center">
				<div className="d-flex me-auto me-md-0">
					<Button
						color={Colors['white-primary']}
						title={t('title.download-qrcode')}
						className={styles.action}
						onClick={toggleQrModal}>
						<FontAwesome icon="qrcode" size="xl" />
					</Button>
					{internship && internship?.publishedInfo.downloadUrl && (
						<a
							href={internship?.publishedInfo.downloadUrl}
							rel="noreferrer"
							target="_blank"
							title={t('title.download-pdf')}
							download={`jobbiplace-internship_${internship?.title}.pdf`}
							className={clx(styles.action, styles.downloadPdf)}>
							<FontAwesome icon="download" size="xl" />
						</a>
					)}
					<Button
						color={Colors['white-primary']}
						title={t('button.internship.view-internship-page')}
						className={styles.action}
						onClick={redirectToPreview}>
						<FontAwesome icon="eye" size="xl" />
					</Button>
				</div>
				<Button
					color={Colors['white-red']}
					onClick={toggleUnPublishModal}>
					<FontAwesome
						icon="triangle-exclamation"
						size="lg"
						className="me-2"
					/>
					{t('button.unPublish')}
				</Button>
			</div>
			{shouldRenderUnPublishModal && (
				<ModalBoxUnPublishInternship
					isOpen={openUnPublishModal}
					onClose={toggleUnPublishModal}
				/>
			)}
			{shouldRenderQrhModal && (
				<ModalBoxQrCode
					isOpen={openQrModal}
					onClose={toggleQrModal}
					title={internship.title}
					data={data}
					isLoading={isLoading}
					downloadQrcodeApi={api.downloadInternshipQrCode(internship_id)}
					namespace="internship"
				/>
			)}
		</div>
	);
});

InternshipPublishBar.displayName = 'InternshipPublishBar';
