import { FC, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { clx } from 'Utils';
import { names } from 'Constants';
import { EditButton, FontAwesome } from 'Elements';

interface Props {
	className?: string;
	color: string;
	feature: {
		title: string;
		description: string;
		icon: any;
		sortOrder: number;
	};
}

export const AgentFeatureCard: FC<Props> = memo(
	({ feature, className, color }) => {
		const navigate = useNavigate();
		const { planPrice } = names;

		const handleNavigate = () => {
			navigate(planPrice.feature.path, {
				state: {
					prevPath: '/home'
				}
			});
		};

		return (
			<div
				className={clx(
					'bg-gray-6 cursor-pointer d-flex gap-3 p-4 round-10 h-100 position-relative',
					className
				)}
				onClick={handleNavigate}>
				<FontAwesome
					icon={feature.icon}
					size="2x"
					fixedWidth
					className="mt-1"
					color={color}
				/>
				<div className="d-flex flex-column gap-1 flex-grow-1 text-gray-1">
					<h5 className="lh-base fs-base mb-0">{feature.title}</h5>
					{feature?.description && (
						<p className="fw-300 lh-sm pre-line mb-0">
							{feature.description}
						</p>
					)}
				</div>
				<div className="edit-overlay">
					<EditButton className="edit-overlay-icon" />
				</div>
			</div>
		);
	}
);

AgentFeatureCard.displayName = 'AgentFeatureCard';
