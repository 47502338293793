import {
	AgentPartnerApprovalStatus,
	AgentSetting,
	CustomerDto,
	PaymentMethod,
	Status,
	Voucher,
	VoucherDiscountType
} from 'Types';

export const enum SubscriptionStatus {
	pending = 0,
	active = 1,
	canceled = 2,
	expired = 3,
	inactive = 4,
	pendingPayment = 5
}

export interface SubscriptionPlan {
	id: string;
	code: string;
	title: string;
	shortDescription: string;
	description: string;
	duration: number;
	remainDuration: number;
	price: number;
	originalPrice: number;
	total: number;
	taxAmount: number;
	isActive: boolean;
	agentPercentPay: number;
	taxPercent: number;
	subscriptionType: number;
}

export interface SubscriptionSate {
	selectedAgent: SubscriptionAgentSetting | null;
	selectedPlan: SubscriptionPlan | null;
	selectedVoucher: Voucher | null;
}

export interface SubscriptionForm {
	step1: SubscriptionFormAgent;
	step2: SubscriptionPlan | null;
}

export interface SubscriptionFormAgent {
	agent: SubscriptionAgentSetting | null;
}

export interface SubscriptionAgent {
	id: string;
	agentMainList: SubscriptionAgentSetting[];
	agentAlternativeList: SubscriptionAgentSetting[];
	agentId: string;
	regionId: string;
}

export interface SubscriptionAgentSetting {
	agentId: string;
	name: string;
	slugUrl: string;
	agentSetting: AgentSetting;
	companyApprovalStatus?:boolean
}

export interface Subscription {
	id: string;
	agentApprovedStatus: AgentPartnerApprovalStatus;
	agentApprovalStatus: {
		agentApprovedStatus: string;
		updatedAt: string;
		description: string;
		createdBy: string;
		createdByIdentify: string;
	};
	companyId: string;
	agentId: string;
	purchaseDate: string;
	renewalDate: string;
	totalAmount: number;
	endDate: string;
	company: CustomerDto;
	code: string;
	title: string;
	remainDuration: number;
	remainDays: string;
	autoRenewal: boolean;
	agent: CustomerDto;
	status: Status;
	payment: SubscriptionPayment;
	paymentIds: { paymentId: string }[];
	duration: number;
	shortDescription: string;
	description: string;
	paymentStatus: string;
	transaction: SubscriptionTransaction;
	plan: SubscriptionPlan;
	price: number;
	paymentMethod: PaymentMethod;
	subscriptionType: string;
	parentId: string;
	payUrl: string;
	priceForXDays: number;
	isSubCompanySubscription: boolean;
	subCompanySubscription: Subscription[];
	subTotal: number;
	discountAmount: number;
	taxAmount: number;
	taxPercent: number;
	discount: number;
	priceAfterDiscount: number;
	discountType: VoucherDiscountType;
	applyVoucher: Voucher;

}
export interface SubscriptionDetails
	extends Omit<Subscription, 'subCompanySubscription'> {
	subCompanySubscription: Subscription;
}

export interface SubscriptionPayment {
	id: string;
	status: string;
	paymentCode: string;
}

export interface SubscriptionTransaction {
	id: string;
	amount: number;
	taxAmount: number;
	totalAmount: number;
	taxPercent: number;
	priceAfterDiscount: number;
	applyVoucher: Voucher;
	discountAmount: number;
	subTotal: number;
	discount: number;
	discountType: VoucherDiscountType;
}
