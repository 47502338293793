import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Colors } from 'Types';
import { Button, SubmitButton, FontAwesome } from 'Elements';

interface Shared {
	showNext?: boolean;
	onPrev?: () => void;
}

type TypeCheck =
	| {
			type: 'submit';
			isSubmitting: boolean;
			[x: string]: any;
	  }
	| { type?: 'button'; isSubmitting?: boolean };

type Props = Shared & TypeCheck;

export const StepNavigation: FC<Props> = memo(
	({
		type = 'button',
		onPrev,
		showNext = true,
		isSubmitting,
		...submitProps
	}) => {
		const { t } = useTranslation();
		return (
			<div className="d-flex align-items-center justify-content-end pt-4 gap-3">
				{onPrev && (
					<Button
						color={Colors['white-primary']}
						className="gap-2 me-auto"
						onClick={onPrev}>
						<FontAwesome
							icon="chevron-left"
							size="1x"
							swapOpacity
						/>
						{t('wizard.prev')}{' '}
					</Button>
				)}
				{type === 'button' && showNext && (
					<Button type="submit" className="gap-2">
						{t('wizard.next')}{' '}
						<FontAwesome
							icon="chevron-right"
							size="1x"
							swapOpacity
						/>
					</Button>
				)}
				{type === 'submit' && (
					<SubmitButton
						{...submitProps}
						isSubmitting={isSubmitting as boolean}
					/>
				)}
			</div>
		);
	}
);

StepNavigation.displayName = 'StepNavigation';
