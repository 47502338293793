import { createContext, FC, memo, ReactNode, useEffect, useState } from 'react';
import { useScroll } from 'Hooks';

interface ScrollState {
	colorType?: 'colorize' | 'white';
}

interface ScrollContext {
	state: ScrollState;
	setState: (state: ScrollState) => void;
}

const initialState: ScrollContext = {
	state: { colorType: 'white' },
	setState: (state: ScrollState) => {}
};

export const ScrollContext = createContext(initialState);

export const ScrollProvider: FC<{ children: ReactNode }> = memo(
	({ children }) => {
		const [state, setState] = useState(initialState.state);
		const { isActiveScroll } = useScroll();

		useEffect(() => {
			setState((prev) => ({
				...prev,
				colorType: isActiveScroll ? 'colorize' : 'white'
			}));
		}, [isActiveScroll]);

		return (
			<ScrollContext.Provider value={{ state, setState }}>
				{children}
			</ScrollContext.Provider>
		);
	}
);
ScrollProvider.displayName = 'ScrollProvider';
