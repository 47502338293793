import { UnprivilegedEditor } from 'react-quill';
import { locales, defaultLocale } from 'Constants';
import { BadgeStatus, Locale, SelectOption, Translate, XOR } from 'Types';

export const body = document.body as HTMLElement;

/**
 * Returns the HTTPS version of any URL.
 * If the URL already starts with "https://", it is returned as is.
 * Otherwise, "https://" is prepended to the URL.
 * @param url The URL to convert to HTTPS.
 * @returns The HTTPS version of the URL.
 */
export const withHttps = (url: string) => {
	return url.replace(/^(?:(.*:)?\/\/)?(.*)/i, (match, schema, nonSchemaUrl) =>
		schema ? match : `https://${nonSchemaUrl}`
	);
};

/**
 * Retrieves the current locale from local storage.
 * If the locale does not exist in the list of supported locales,
 * the default locale is returned.
 * @returns The current locale.
 */
export const getCurrentLocale: () => Locale = () => {
	let language = defaultLocale;
	const currentLocale = JSON.parse(
		localStorage.getItem('currentLocale') ?? '{}'
	);
	const localeExists = locales.some(
		(locale) => locale.code === currentLocale.code
	);

	if (localeExists) {
		language = currentLocale;
	}

	return language;
};

/**
 * Sets the current locale in local storage.
 *
 * @param {Locale} locale - The locale to be set.
 * @returns {void} No return value.
 */
export const setCurrentLocale = (locale: Locale): void => {
	try {
		localStorage.setItem('currentLocale', JSON.stringify(locale));
	} catch (error) {
		console.log(
			'>>>>: src/helpers/Utils.js : setCurrentLocale -> error',
			error
		);
	}
};

/**
 * Clears the specified storage.
 * @param storage - The storage type to clear ('local' or 'session').
 * @returns void
 */
export const clearStorage = (storage: 'local' | 'session' = 'local'): void => {
	if (storage === 'session') {
		sessionStorage.clear();
	}
	if (storage === 'local') {
		localStorage.clear();
	}
};

/**
 * Truncates a string to a specified number of words.
 * If the string has more words than the specified number, it adds a tail at the end.
 * @param str - The string to truncate.
 * @param no_words - The number of words to keep in the truncated string.
 * @param tail - The tail to add at the end of the truncated string if needed.
 * @returns The truncated string.
 */
export const truncateToWord = (str: string, no_words: number, tail = '...') => {
	const words = str.split(' ', no_words + 1);
	const truncated = words.slice(0, no_words).join(' ');
	return truncated + (words.length > no_words ? tail : '');
};

/**
 * Scrolls the page to the top.
 */
export const scrollTop = () => {
	const scrollToTop = () => {
		const currentScrollPosition =
			document.documentElement.scrollTop || document.body.scrollTop;
		if (currentScrollPosition > 0) {
			window.requestAnimationFrame(scrollToTop);
			window.scrollTo(
				0,
				currentScrollPosition - currentScrollPosition / 8
			);
		}
	};
	scrollToTop();
};

/**
 * Returns the scrollbar position of the specified element or the window.
 * @param el - The element to get the scrollbar position from. Defaults to the window.
 * @returns An object with the x and y positions of the scrollbar.
 */
export const getScrollPosition = (el: any = window) => {
	const { pageXOffset, pageYOffset, scrollLeft, scrollTop } = el;
	return {
		x: pageXOffset !== undefined ? pageXOffset : scrollLeft,
		y: pageYOffset !== undefined ? pageYOffset : scrollTop
	};
};

/**
 * Scrolls to the element with the specified ID.
 * @param elementId The ID of the element to scroll to.
 * @param scrollOptions The scroll options.
 */
interface ScrollOptions {
	behavior?: 'auto' | 'smooth';
	block?: 'start' | 'center' | 'end' | 'nearest';
	inline?: 'start' | 'center' | 'end' | 'nearest';
	offset?: number; // Add an offset property
}

const defaultOptions: ScrollOptions = {
	behavior: 'smooth'
};
export const scrollToElement = (
	elementId: string,
	scrollOptions: ScrollOptions = defaultOptions
) => {
	const element = document.getElementById(elementId);

	if (!element) {
		return;
	}

	const offset = scrollOptions.offset || 0;
	const scrollPosition =
		element.getBoundingClientRect().top + window.scrollY + offset;

	window.scrollTo({
		top: scrollPosition,
		behavior: scrollOptions.behavior || 'auto'
	});
};

/**
 * Returns an immutable object by updating the properties of an existing object.
 * @param oldObj - The original object.
 * @param updatedVal - The properties to update in the original object.
 * @returns A new object with the updated properties.
 */
export const updateObj = <T extends Record<string, any>>(
	oldObj: T,
	updatedVal: Partial<T>
): T => ({
	...oldObj,
	...updatedVal
});

/**
 * Concatenates truthy classes into a space-separated string.
 *
 * @param classes - The classes to concatenate.
 * @returns The concatenated classes.
 */
export const clx = (...classes: (string | boolean | undefined)[]): string => {
	return classes.filter((c) => c).join(' ');
};

/**
 * Calculates the brightness level from a color value.
 * The brightness level is determined using the formula: Y = 0.299R + 0.587G + 0.114B.
 * If the color is pure white (#fff), the maximum brightness level (255) is returned.
 * @param color The color value in hexadecimal format (e.g., "#ff0000" for red).
 * @returns The brightness level of the color.
 */
export const calculateBrightness = (color: string) => {
	// Parse the color value to RGB
	const rgb = parseInt(color.substring(1), 16);
	const r = (rgb >> 16) & 0xff;
	const g = (rgb >> 8) & 0xff;
	const b = (rgb >> 0) & 0xff;

	// Check if the color is pure white (#fff)
	if (r === 255 && g === 255 && b === 255) {
		return 255; // Return maximum brightness (255) for pure white
	}

	// Calculate the brightness level using the formula: Y = 0.299R + 0.587G + 0.114B
	return (r * 299 + g * 587 + b * 114) / 1000;
};

/**
 * Converts a hexadecimal or RGB color to an RGBA color with an optional opacity.
 * @param color - The color to convert.
 * @param opacity - The opacity of the RGBA color. Defaults to 1.
 * @returns The converted RGBA color.
 */
export const hexToRgb = (color: string, opacity = 1): string => {
	const normalizedOpacity = Math.max(0, Math.min(1, opacity));

	if (color.startsWith('rgb')) {
		// Handle RGB color format
		const rgbValues = color
			.substring(color.indexOf('(') + 1, color.indexOf(')'))
			.split(',')
			.map((value) => parseInt(value.trim()));

		// Validate RGB values
		if (rgbValues.length === 3) {
			const [r, g, b] = rgbValues;
			return `rgba(${r},${g},${b},${normalizedOpacity})`;
		}
	} else if (color.startsWith('#')) {
		// Handle hexadecimal color format
		const sanitizedHex = color.replace('#', '');

		if (sanitizedHex.length === 3) {
			const expandedHex = sanitizedHex
				.split('')
				.map((char) => char + char)
				.join('');
			const bigint = parseInt(expandedHex, 16);
			const r = (bigint >> 16) & 255;
			const g = (bigint >> 8) & 255;
			const b = bigint & 255;

			return `rgba(${r},${g},${b},${normalizedOpacity})`;
		} else if (sanitizedHex.length === 6) {
			const bigint = parseInt(sanitizedHex, 16);
			const r = (bigint >> 16) & 255;
			const g = (bigint >> 8) & 255;
			const b = bigint & 255;

			return `rgba(${r},${g},${b},${normalizedOpacity})`;
		}
	}

	// Return the input color as is if it doesn't match any expected format
	return color;
};

/**
 * Formats a currency value into a pretty format.
 * @param value - The currency value to format.
 * @param options - Optional formatting options.
 * @returns The formatted currency value.
 */
export const currencyFormatter = (
	value: number,
	options: Intl.NumberFormatOptions = {}
): string => {
	const defaultOptions: Intl.NumberFormatOptions = {
		...options,
		minimumFractionDigits: 2,
		maximumFractionDigits: 2
	};

	const locale = getCurrentLocale()?.lang ?? '';
	const formatter = new Intl.NumberFormat(locale, {
		style: 'currency',
		currency: 'EUR',
		...defaultOptions
	});
	return formatter.format(value);
};

/**
 * Format local address with the given parameters.
 *
 * @param street - The street name.
 * @param houseNo - The house number.
 * @param postalCode - The postal code.
 * @param city - The city name.
 * @param state - The state name.
 * @param country - (Optional) The country name.
 *
 * @returns The formatted local address.
 */
export const formatLocalAddress = (
	street: string,
	houseNo: string,
	postalCode: string,
	city: string,
	state: string,
	country?: string
): string => {
	const addressParts = [
		`${street} ${houseNo}`,
		`${postalCode} ${city}`,
		state
	];
	if (country) {
		addressParts.push(country);
	}
	return addressParts.join(', ');
};

/**
 * Generate a unique id.
 * @returns {string} The generated unique id.
 */
export const uid = () => {
	// Way 1: return Date.now().toString(36) + Math.random().toString(36).substr(5);
	return crypto.randomUUID();
};

/**
 * Converts a string into a slug.
 * @param str - The string to be slugified.
 * @returns The slugified string.
 */
export const slugify = (str: string) => {
	return str
		.trim()
		.toLowerCase()
		.replace(/\s+/g, '-')
		.replace(/[^\w-]+/g, '')
		.replace(/-+$/, '');
};

/**
 * Unslug string
 *
 * @param slugs - The slug string to unslug
 * @returns The unslugged string
 */
export const unslug = (slugs: string) => {
	return slugs
		.replace(/_/g, '-')
		.replace(/--/g, '-')
		.split('-')
		.map((slug) => slug.charAt(0).toUpperCase() + slug.slice(1))
		.join(' ');
};

/**
 * Download a file from a given URL.
 *
 * @param fileUrl The URL of the file to download.
 * @param fileName The name to save the downloaded file as.
 */
export const dynamicFileDownload = async (
	fileUrl: string,
	fileName: string
) => {
	try {
		const response = await fetch(fileUrl);
		const blob = await response.blob();
		const url = window.URL.createObjectURL(blob);
		const downloadLink = document.createElement('a');
		downloadLink.href = url;
		downloadLink.setAttribute('download', fileName);
		downloadLink.setAttribute('target', 'blank');
		downloadLink.setAttribute('rel', 'noopener noreferrer');
		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);
		window.URL.revokeObjectURL(url);
	} catch (error) {
		console.error('Failed to download file:', error);
	}
};

/**
 * Retrieve the key in an enum object that corresponds to a given value.
 *
 * @param enumObj The enum object to search.
 * @param value The value to search for.
 * @returns The key corresponding to the value, or undefined if the value is not found.
 */
export const getKeyFromValue = <T extends Record<string, string | number>>(
	enumObj: T,
	value: number
): keyof T | undefined => {
	return Object.keys(enumObj).find((key) => enumObj[key] === value);
};

/**
 * Find duplicate items in an array based on a key function.
 *
 * @template T The type of the items in the array.
 * @param {T[]} array The array to search for duplicates.
 * @param {(item: T) => string | number} keyFn The function that extracts the key from each item.
 * @returns {Record<string | number, number>} An object that maps the duplicate keys to their counts.
 */
export const findDuplicates = <T>(
	array: T[],
	keyFn: (item: T) => string | number
): Record<string | number, number> => {
	const duplicates: Record<string | number, number> = {};

	array.forEach((item) => {
		const key = keyFn(item);
		duplicates[key] = (duplicates[key] || 0) + 1;
	});

	Object.keys(duplicates).forEach((key) => {
		if (duplicates[key] === 1) {
			delete duplicates[key];
		}
	});

	return duplicates;
};

/**
 * Build a local URL by joining the provided URL segments.
 * Removes extra slashes from the beginning of segments.
 * Handles escaped characters and ensures proper URL formatting.
 * @param params URL segments to be joined.
 * @returns The built local URL.
 */
export const buildUrl = (...params: (string | number)[]): string => {
	return params.map((param) => param.toString().replace(/^\//, '')).join('/');
};

/**
 * Constructs a URL with query parameters.
 *
 * @param base - The base URL.
 * @param params - An object containing the query parameters.
 * @returns The constructed URL.
 */
export const buildUrlWithQueryParams = (
	base: string,
	params: { [key: string]: string }
): string => {
	// Convert the query parameters object to a URL encoded string
	const query = new URLSearchParams(params).toString();

	// Concatenate the base URL and the query parameters string
	return `${base}?${query}`;
};

/**
 * Converts an array of objects with `id` and `title` properties to an array of `SelectOption` objects.
 * @param array - The array of objects to convert.
 * @returns An array of `SelectOption` objects with `value` and `label` properties.
 */
export const getSelectOption = <T extends { id: string; title: string }>(
	array: T[]
): SelectOption[] => {
	return array.map((item) => ({
		value: item.id,
		label: item.title
	}));
};

/**
 * Returns the default select option from an array based on a condition.
 * @param array - The array of options to search in.
 * @param condition - The condition function to determine the selected option.
 * @returns The selected option as a SelectOption object, or undefined if no option matches the condition.
 */
type Props = XOR<{ title: string }, { name: string }> & { id: string };
export const defaultSelectOption = <T extends Props>(
	array: T[],
	condition: (item: T) => boolean
): SelectOption | undefined => {
	for (const item of array) {
		if (condition(item)) {
			return {
				value: item.id,
				label: item.title ?? item.name
			};
		}
	}
	return undefined;
};

/**
 * Retrieves a filter from an array by its name.
 * @param filters - The array of filters to search in.
 * @param filterName - The name of the filter to retrieve.
 * @returns The filter object if found, or undefined if not found.
 */
export const getFilterByName = (
	filters: BadgeStatus[],
	filterName: string
): BadgeStatus | undefined => {
	return filters.find((filter) => filter.title === filterName);
};

/**
 * Retrieves a filter from an array by its name.
 * @param filters - The array of filters to search in.
 * @param filterValue - The value of the filter to retrieve.
 * @returns The filter object if found, or undefined if not found.
 */
export const getFilterByValue = (
	filters: BadgeStatus[],
	filterValue: string | number
): BadgeStatus | undefined => {
	for (const filter of filters) {
		if (filter.value?.toString() === filterValue?.toString()) {
			return filter;
		}
	}
	return undefined;
};

/**
 * Checks if the Quill editor is empty.
 * @param quill - The Quill editor instance.
 * @returns True if the editor is empty, false otherwise.
 */
export const isQuillEmpty = (quill: UnprivilegedEditor) => {
	const opsLength = quill.getContents()?.ops?.length ?? 0;
	const isTextEmpty = quill.getText().trim().length === 0;

	return opsLength !== 1 ? false : isTextEmpty;
};

/**
 * Returns the translated name based on the given translates array.
 * If a translation for 'de' (German) is available, it returns that.
 * Otherwise, it returns the name from the first translation in the array.
 * If the translates array is empty, it returns an empty string.
 * @param translates The array of Translate objects
 * @returns The translated name or an empty string
 */
export const getTranslate = (translates: Translate[] = []): string => {
	const deTranslate = translates.find(
		(item) => item?.language.toLowerCase() === 'de'
	);
	return deTranslate ? deTranslate.name : translates[0]?.name || '';
};

/**
 * Recursively checks if an object is empty.
 * @param obj - The object to check for emptiness.
 * @returns `true` if the object and all its nested objects have no properties, `false` otherwise.
 */
export const isObjectEmpty = (obj: Record<string, any>): boolean => {
	return Object.keys(obj).every((key) => {
		return typeof obj[key] === 'object' && isObjectEmpty(obj[key]);
	});
};
