import { memo, FC } from 'react';

interface Props {
	fill?: string;
	className?: string;
}

export const IconGrid: FC<Props> = memo(
	({ fill = 'currentcolor', className, ...props }): JSX.Element => {
		return (
			<svg
				width="705"
				height="21"
				fill="none"
				className={className}
				xmlns="http://www.w3.org/2000/svg"
				{...props}>
				<rect x="0.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="12.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="24.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="36.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="0.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="12.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="24.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="36.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="48.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="60.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="72.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="84.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="48.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="60.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="72.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="84.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="96.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="108.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="120.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="132.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="96.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="108.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="120.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="132.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="144.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="156.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="168.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="180.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="144.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="156.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="168.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="180.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="192.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="204.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="216.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="228.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="192.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="204.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="216.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="228.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="240.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="252.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="264.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="276.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="240.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="252.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="264.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="276.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="288.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="300.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="312.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="324.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="288.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="300.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="312.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="324.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="336.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="348.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="360.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="372.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="336.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="348.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="360.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="372.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="384.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="396.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="408.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="420.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="384.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="396.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="408.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="420.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="432.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="444.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="456.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="468.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="432.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="444.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="456.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="468.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="480.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="492.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="504.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="516.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="480.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="492.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="504.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="516.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="528.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="540.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="552.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="564.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="528.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="540.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="552.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="564.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="576.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="588.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="600.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="612.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="576.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="588.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="600.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="612.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="624.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="624.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="636.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="636.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="648.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="648.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="660.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="660.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="672.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="672.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="684.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="684.5" y="12.1016" width="8" height="8" fill={fill} />
				<rect x="696.5" y="0.101562" width="8" height="8" fill={fill} />
				<rect x="696.5" y="12.1016" width="8" height="8" fill={fill} />
			</svg>
		);
	}
);

IconGrid.displayName = 'IconGrid';
