import { memo, FC } from 'react';
import { Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { names } from 'Constants';
import { Colors, User } from 'Types';
import { getUserTypeById } from 'Utils';
import { Badge, Button, Card, ShimmerImage, FontAwesome } from 'Elements';
import styles from 'partials/card/user-profile-card.module.scss';

interface Props {
	user: User;
}

export const UserCard: FC<Props> = memo(({ user }) => {
	const { users } = names;
	const navigate = useNavigate();
	const { t } = useTranslation();
	const navigateUrl = `/users/${user.userId}`;
	const userName = `${user.firstName} ${user.lastName}`;
	const defaultImage = '/assets/img/profiles/img-user-default.png';

	const handleNavigate = () => {
		void navigate(navigateUrl, { state: { prevPath: `${users.path}` } });
	};

	return (
		<Col sm={6} xl={4} xxl={3} className="mb-4">
			<Card className={styles.card}>
				<Badge color={Colors.primary} className={styles.badge}>
					{user.userType === 4 &&
						t(getUserTypeById(user.userType).title)}
				</Badge>
				<div className={styles.avatar}>
					<Link
						state={{ prevPath: `${users.path}` }}
						to={navigateUrl}
						className={styles.shimmerBox}>
						<ShimmerImage
							src={user.imageThumbnailUrl}
							width={110}
							height={110}
							defaultImage={defaultImage}
							alt={userName}
							className={styles.shimmer}
						/>
					</Link>
				</div>
				<div className={styles.body}>
					<h4 className="fs-6 fw-400 mb-2 text-truncate">
						<Link
							state={{ prevPath: `${users.path}` }}
							to={navigateUrl}>
							{userName}
						</Link>
					</h4>
					<span className="text-gray-3 pt-1 fw-300">
						{user.emailAddress}
					</span>
					<p className="text-gray-3 text-center d-flex align-items-center justify-content-center mt-3 mb-0">
						{t('card.group')}:{' '}
						<span className="ms-2 text-primary">
							{user.userType === 4
								? t('forms.owner')
								: user.userGroupName}
						</span>
					</p>
					<div className="d-flex align-items-center justify-content-center mt-auto pt-4">
						<Button
							size="sm"
							color={Colors['white-primary']}
							onClick={handleNavigate}>
							<FontAwesome
								size="sm"
								icon="pen"
								className="me-2"
							/>
							{t('button.edit-details')}
						</Button>
					</div>
				</div>
			</Card>
		</Col>
	);
});

UserCard.displayName = 'UserCard';
