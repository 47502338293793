import { memo, Suspense, useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import { Outlet, useParams, useNavigate } from 'react-router-dom';
import type { SubmitHandler } from 'react-hook-form/dist/types';
import { names } from 'Constants';
import { clx, getUserTypeById } from 'Utils';
import { getCountries, getStates } from 'Data';
import { B2BUser, Colors, SelectOption, UserInitialData } from 'Types';
import {
	useDelayUnmount,
	useGetB2bUserProfileQuery,
	useGroups,
	useModal,
	useResponsive,
	useUpdateUser
} from 'Hooks';
import {
	Button,
	Card,
	LoadingContent,
	ModalBoxDelete,
	SubmitButton,
	UserDecoration,
	FontAwesome
} from 'Elements';
import styles from 'partials/page/form.module.scss';

export const UpdateUser = memo(() => {
	const { users } = names;
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { user_id } = useParams();
	const { isDesktopAndBelow } = useResponsive();
	const [openModal, toggleModal] = useModal();
	const [isRemoving, setIsRemoving] = useState(false);
	const shouldRenderModal = useDelayUnmount(openModal, 350);

	const {
		data: user,
		isFetching,
		updateUser,
		removeUser
	} = useUpdateUser(user_id as string);
	const name = `${user?.firstName} ${user?.lastName}`;

	const { data: currentUser } = useGetB2bUserProfileQuery();

	const isUser =
		getUserTypeById(user?.userType as number)?.type === 'b2b-user';

	const { data: groupList } = useGroups(
		{
			pageNumber: 1,
			pageSize: 500
		},
		{
			enabled: isUser
		}
	);

	const schema = yup.object().shape(
		{
			firstName: yup
				.string()
				.required(t('validation.firstName.required')),
			lastName: yup.string().required(t('validation.lastName.required')),
			dateOfBirth: yup
				.date()
				.nullable()
				.default(null)
				.notRequired()
				.transform((curr, orig) => (orig === '' ? null : curr))
				.when('dateOfBirth', {
					is: (value: string) => value?.length,
					then: yup.date().typeError(t('validation.date.type'))
				}),
			postalCode: yup
				.string()
				.notRequired()
				.when('postalCode', {
					is: (value: string) => value?.length,
					then: yup
						.string()
						.notRequired()
						.min(5, t('validation.postal-code.length'))
						.max(5, t('validation.postal-code.length'))
						.matches(/^\d+$/, t('validation.only-digit'))
				}),
			...(isUser && {
				userGroupId: yup
					.object({
						label: yup.string(),
						value: yup.string()
					})
					.nullable()
					.required(t('validation.group-name.required'))
			})
		},
		[
			['dateOfBirth', 'dateOfBirth'],
			['postalCode', 'postalCode']
		]
	);

	const formMethods = useForm<UserInitialData>({
		resolver: yupResolver(schema),
		shouldUnregister: false,
		mode: 'onChange'
	});

	const {
		reset,
		handleSubmit,
		formState: { isDirty, isSubmitting }
	} = formMethods;

	const defaultUserGroup = useCallback(
		(userGroupId?: string) => {
			let groupOption: SelectOption;
			if (groupList && userGroupId) {
				const foundedGroup = groupList?.items?.find(
					(group) => group?.id === userGroupId
				);
				if (foundedGroup) {
					groupOption = {
						label: foundedGroup?.name,
						value: foundedGroup?.id
					} as SelectOption;
					return groupOption;
				}
			}
			return null;
		},
		[groupList]
	);

	const defaultCountry = (selected_country?: string) => {
		if (selected_country) {
			return getCountries().find(
				(country) => country.value === selected_country
			);
		}
		return null;
	};

	const defaultState = (selected?: B2BUser) => {
		if (selected) {
			return getStates().find((state) => {
				return (
					state.value === selected?.state &&
					state.country === selected?.country
				);
			});
		}
		return null;
	};

	const handleRemoveUser = async () => {
		try {
			setIsRemoving(true);
			await removeUser(() => {
				setIsRemoving(false);
				navigate(`${users.path}`);
			});
		} finally {
			setIsRemoving(false);
		}
	};

	const submitHandler: SubmitHandler<UserInitialData> = async (data) => {
		const api_data = {
			...data,
			userGroupId: (data?.userGroupId?.value as string) ?? '',
			country: (data?.country?.value as string) ?? '',
			state: (data?.state?.value as string) ?? ''
		};
		await updateUser(api_data);
	};

	useEffect(() => {
		reset({
			...user,
			userGroupId: defaultUserGroup(user?.userGroupId),
			country: defaultCountry(user?.country),
			state: defaultState(user)
		});
	}, [reset, user, defaultUserGroup]);

	return (
		<FormProvider {...formMethods}>
			<form
				onSubmit={handleSubmit(submitHandler)}
				className={clx(isDesktopAndBelow && styles.form)}>
				<UserDecoration />
				<Suspense fallback={<LoadingContent />}>
					<Card className={clx(isDesktopAndBelow && 'mb-4')}>
						<Row>
							<Col md={8} xxl={6}>
								<Outlet context={user} />
								{isFetching && <LoadingContent />}
							</Col>
						</Row>
					</Card>
					<Card
						className={clx(
							isDesktopAndBelow && styles.sticky_action
						)}>
						<div className="d-flex align-items-center justify-content-end gap-2">
							{currentUser &&
								user &&
								currentUser?.userId !== user?.userId && (
									<Button
										color={Colors['white-red']}
										className="me-auto"
										onClick={toggleModal}>
										<FontAwesome
											icon='trash-xmark'
											size="lg"
											className="me-2"
										/>
										{t('button.remove')}
									</Button>
								)}
							<SubmitButton
								isDisable={!isDirty}
								isSubmitting={isSubmitting}
							/>
						</div>
					</Card>
				</Suspense>
			</form>
			{shouldRenderModal && (
				<ModalBoxDelete
					isOpen={openModal}
					onClose={toggleModal}
					onRemove={handleRemoveUser}
					isRemoving={isRemoving}
					title={name ?? '---'}
				/>
			)}
		</FormProvider>
	);
});

UpdateUser.displayName = 'UpdateUser';
