import { memo, FC } from 'react';
import { clx } from 'Utils';
import { useSelector } from 'Hooks';
import { MarketingAssetConfig } from 'Types';
import styles from 'partials/card/template-card.module.scss';

interface Props {
	card: MarketingAssetConfig;
	index: string;
	onSelect?: (card: MarketingAssetConfig) => void;
}

export const ToolboxTemplateCard: FC<Props> = memo(
	({ card, index, onSelect }) => {
		const { selectedTemplate } = useSelector((state) => state.marketing);
		const isActive = selectedTemplate?.id === index;

		const onSelectToolbox = () => {
			onSelect?.(card);
		};

		return (
			<div className={styles.card} onClick={onSelectToolbox}>
				<div className={clx(styles.content, isActive && styles.active)}>
					<img
						src={card.imageUrl}
						alt={card.title}
						className={styles.image}
					/>
				</div>
				<h6
					className={clx(
						styles.title,
						'mb-0 lh-base px-1',
						isActive && 'text-primary'
					)}>
					{card.title}
				</h6>
			</div>
		);
	}
);

ToolboxTemplateCard.displayName = 'ToolboxTemplateCard';
