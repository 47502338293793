import { memo, FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ColorValues, Colors, ModalProps } from 'Types';
import { useUpdateAgent, useUpdateCompany } from 'Hooks';
import {
	Button,
	ModalBox,
	ModalBoxFooter,
	SubmitButton,
	FontAwesome
} from 'Elements';

interface Props extends ModalProps {
	type: 'publish' | 'unPublish';
	userType: 'agent' | 'company';
	isPublished: boolean;
}
export const ModalBoxPublish: FC<Props> = memo(
	({ isOpen, type, isPublished, userType, onClose }) => {
		const { t } = useTranslation();
		const { updateAgent } = useUpdateAgent({
			enabled: userType === 'agent'
		});
		const { updateCompany } = useUpdateCompany({
			enabled: userType === 'company'
		});

		const {
			handleSubmit,
			formState: { isSubmitting }
		} = useForm();

		const onPublish = async () => {
			const api_data = {
				isActive: !isPublished
			};
			userType === 'company'
				? await updateCompany(api_data, onClose, onClose)
				: await updateAgent(api_data, onClose, onClose);
		};

		return (
			<ModalBox isOpen={isOpen} onClose={onClose}>
				<form onSubmit={handleSubmit(onPublish)}>
					<header className="d-flex flex-column align-items-center text-center">
						<FontAwesome
							icon={
								type === 'publish'
									? 'building-circle-check'
									: 'triangle-exclamation'
							}
							size="8x"
							color={
								type === 'publish'
									? ColorValues.green
									: ColorValues.red
							}
							className="mb-4"
						/>
						<h4 className="h6 lh-base">
							{t(
								type === 'publish'
									? `text.are-you-sure-to-publish-your-${userType}`
									: `text.are-you-sure-to-unPublish-your-${userType}`
							)}
						</h4>
						<p className="text-gray-3 lh-md">
							{t(
								type === 'publish'
									? `text.${userType}-publish-sub-text`
									: `text.${userType}-unPublish-sub-text`
							)}
						</p>
					</header>
					<ModalBoxFooter className="justify-content-between pt-3">
						<Button color={Colors['white-gray']} onClick={onClose}>
							{t('button.cancel')}
						</Button>
						<SubmitButton
							icon={type === 'publish' && 'upload'}
							color={
								type === 'publish'
									? 'white-primary'
									: 'white-red'
							}
							isSubmitting={isSubmitting}
							label={
								type === 'publish'
									? 'button.publish'
									: 'button.unPublish'
							}
							savingLabel={
								type === 'publish'
									? 'button.publishing'
									: 'button.unPublishing'
							}
						/>
					</ModalBoxFooter>
				</form>
			</ModalBox>
		);
	}
);

ModalBoxPublish.displayName = 'ModalBoxPublish';
