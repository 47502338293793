import { memo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useGroups } from 'Hooks';
import { UserGroup } from 'Types';
import { motion } from 'framer-motion';
import { containerVariants, names } from 'Constants';
import {
	DataTable,
	DataTableBody,
	DataTableHead,
	DataTableLoading,
	DataTableOperation,
	DataTableRow,
	DataTableRowCell,
	EndOfList,
	ListingPage,
	NoData,
	Pagination,
	SystemErrorAlert
} from 'Elements';

export const ListUserGroup = memo(() => {
	const navigate = useNavigate();
	const { groups } = names;
	const [searchParams, setSearchParams] = useSearchParams();
	const params = Object.fromEntries([...searchParams]);
	const pageParam = searchParams.get('page') || 1;
	const queryParam = searchParams.get('q') || '';

	const [state, setState] = useState<{
		pageSize: number;
		currentPage: string | number;
		keyword: string;
	}>({
		pageSize: 10,
		currentPage: pageParam,
		keyword: queryParam
	});

	const { data, isError, isFetching } = useGroups({
		pageSize: state.pageSize,
		pageNumber: state.currentPage,
		keyword: state.keyword
	});

	const onChangePageSize = (pageSize: number) => {
		setSearchParams({ ...params, page: '1' });
		setState((prev) => ({
			...prev,
			pageSize,
			currentPage: 1
		}));
	};

	const handleSearch = (query: string) => {
		setSearchParams({
			...params,
			page: '1',
			q: query
		});
		setState((prev) => ({
			...prev,
			currentPage: 1,
			keyword: query
		}));
	};

	const handlePageChange = (page: number) => {
		setSearchParams({ ...params, page: page.toString() });
		setState((prev) => ({
			...prev,
			currentPage: page
		}));
	};

	const getGroupRoles = (group: UserGroup) => {
		return group?.roles.map((role) => role.name).join(', ');
	};

	const handleSelectRow = (row_id: string) => {
		void navigate(`${groups.path}/${row_id}`, {
			state: { prevPath: `${groups.path}` }
		});
	};

	return (
		<ListingPage
			totalItems={data?.totalItems}
			pageSizes={[5, 10, 20]}
			selectedPageSize={state.pageSize}
			onPageSizeChange={onChangePageSize}
			onSearchChange={handleSearch}>
			<DataTable>
				{data && data?.totalItems > 0 && (
					<DataTableHead>
						<DataTableRowCell
							cellText={'table.name'}
							cellClassName="w-100 w-sm-20"
						/>
						<DataTableRowCell
							cellText={'table.desc'}
							cellClassName="w-100 w-sm-30"
						/>
						<DataTableRowCell
							cellText={'table.roles'}
							cellClassName="w-100 w-sm-30"
						/>
						<DataTableRowCell
							cellText={'table.operation'}
							cellClassName="w-100 w-sm-20"
						/>
					</DataTableHead>
				)}
				<DataTableBody>
					{!isFetching &&
						<motion.div
							variants={containerVariants}
							initial="hidden"
							animate="show">
							{data?.items?.map((group) => {
								return (
									<DataTableRow key={group.id}>
										<DataTableRowCell
											cellClassName="w-100 w-sm-20 fw-500"
											cellHead="table.name">
											<span
												className="cursor-pointer"
												onClick={() =>
													handleSelectRow(group.id)
												}>
												{group.name}
											</span>
										</DataTableRowCell>
										<DataTableRowCell
											cellClassName="w-100 w-sm-30"
											cellHead="table.desc"
											cellText={
												group.description || '--------'
											}
										/>
										<DataTableRowCell
											cellClassName="w-100 w-sm-30"
											cellHead="table.roles">
											{getGroupRoles(group)}
										</DataTableRowCell>
										<DataTableRowCell
											cellClassName="w-100 w-sm-20"
											cellHead="table.operation">
											<DataTableOperation
												onEditRow={() =>
													handleSelectRow(group.id)
												}
											/>
										</DataTableRowCell>
									</DataTableRow>
								);
							})}
						</motion.div>}
					{isFetching && (
						<DataTableLoading
							widths={[20, 30, 30, 20]}
							count={state.pageSize}
						/>
					)}
				</DataTableBody>
				{isError && <SystemErrorAlert />}
				{!isFetching && data?.totalItems === 0 && (
					<NoData message="title.no-group" />
				)}
			</DataTable>
			{!isFetching && <EndOfList data={data} />}
			{!isFetching && data && data?.totalPages > 1 && (
				<Pagination
					className="mt-3"
					totalItemsCount={data?.totalItems}
					activePage={parseInt(state.currentPage.toString(), 10)}
					itemsCountPerPage={state.pageSize}
					onChange={handlePageChange}
				/>
			)}
		</ListingPage>
	);
});

ListUserGroup.displayName = 'ListUserGroup';
