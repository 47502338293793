import React, { FC, memo } from 'react';
import { ColorValues } from 'Types';

interface Props {
	fill?: string;
	className?: string;
	size?: number;
}

export const IconDate: FC<Props> = memo(
	({
		fill = ColorValues.primary,
		className,
		size,
		...props
	}): JSX.Element => {
		return (
			<svg
				width={size}
				height={size}
				viewBox="0 0 24 24"
				xmlns="http://www.w3.org/2000/svg"
				className={className}
				{...props}>
				<g>
					<path
						d="M20.076 10.038A10.038 10.038 0 1 1 10.038 0a10.038 10.038 0 0 1 10.038 10.038z"
						transform="translate(1.962 1.962)"
						fill={fill}
						fillOpacity={0.25}
					/>
					<path
						d="M10.153 3.531A3.57 3.57 0 0 0 7.9 1.511V.736a.736.736 0 1 0-1.471 0v.579H3.767V.736a.736.736 0 0 0-1.467 0v.775a3.57 3.57 0 0 0-2.26 2.02.5.5 0 0 0 .451.7h9.192a.506.506 0 0 0 .47-.7z"
						transform="translate(6.899 5.957)"
						fill={fill}
					/>
					<path
						d="M10.3 0a.486.486 0 0 1 .49.49v2.482A3.758 3.758 0 0 1 6.867 6.9H3.924A3.758 3.758 0 0 1 0 2.972V.49A.486.486 0 0 1 .49 0z"
						transform="translate(6.605 11.156)"
						fill={fill}
					/>
				</g>
			</svg>
		);
	}
);

IconDate.displayName = 'IconDate';
