import { memo, FC } from 'react';
import { Tab } from 'Types';
import { TabNavigation } from 'Elements';
import styles from 'partials/decoration/page-decoration-box.module.scss';

interface Props {
	tabs: Tab[];
}

export const DecorationTab: FC<Props> = memo(({ tabs }) => {
	return (
		<div className={styles.tab}>
			{tabs?.map((tab) => {
				return (
					<TabNavigation
						key={tab.id}
						to={tab.path}
						title={tab.title}
						isActive={tab.isActive}
					/>
				);
			})}
		</div>
	);
});

DecorationTab.displayName = 'DecorationTab';
