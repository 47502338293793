import { memo, FC, useState, useEffect } from 'react';
import * as yup from 'yup';
import { isAfter, isSameDay } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { usePrompt } from 'Hooks';
import { formatTimeToCET, getCurrentLocale } from 'Utils';
import { EventFormRegistrationType, RadioOption } from 'Types';
import {
	EventDatePicker,
	FormInlineTip,
	RadioController,
	StepNavigation,
	SwitchController,
	FontAwesome
} from 'Elements';
import styles from 'partials/step/step-container.module.scss';

interface Props {
	fields: EventFormRegistrationType;
	endDate: string | Date;
	onPrev: () => void;
	onNext: (data: any) => void;
}

export const CreateEventRegistration: FC<Props> = memo(
	({ fields, endDate, onPrev, onNext }) => {
		const { t } = useTranslation();
		const currentLocaleLang = getCurrentLocale().lang;
		const [startDateCET, setStartCET] = useState({
			visitor: '',
			partner: ''
		});
		const [endDateCET, setEndCET] = useState({
			visitor: '',
			partner: ''
		});
		const required_message = 'validation.required';
		const validation_type_message = 'validation.number.type';

		const schema = yup.object().shape(
			{
				participationType: yup
					.number()
					.nullable()
					.required(t('validation.participation-type.required')),

				partnerLimit: yup
					.number()
					.typeError(t(validation_type_message))
					.when(['hasPartnerLimit', 'participationType'], {
						is: (
							hasPartnerLimit: number,
							participationType: number
						) => participationType !== 0 && !!hasPartnerLimit,
						then: yup
							.number()
							.required(t('validation.company-limit.required'))
							.integer(t(validation_type_message))
							.min(1, t('validation.company-limit.min'))
							.typeError(t(validation_type_message))
					}),
				participantLimit: yup
					.number()
					.typeError(t(validation_type_message))
					.when(['hasParticipantLimit', 'participationType'], {
						is: (
							hasParticipantLimit: number,
							participationType: number
						) => participationType !== 0 && !!hasParticipantLimit,
						then: yup
							.number()
							.required(t('validation.visitor-limit.required'))
							.integer(t(validation_type_message))
							.min(1, t('validation.visitor-limit.min'))
							.typeError(t(validation_type_message))
					}),
				seatPerPartnerLimit: yup
					.number()
					.typeError(t(validation_type_message))
					.when(['hasPartnerLimit', 'participationType'], {
						is: (
							hasPartnerLimit: number,
							participationType: number
						) => participationType !== 0 && !!hasPartnerLimit,
						then: yup
							.number()
							.integer(t(validation_type_message))
							.required(t('validation.number-of-seat.required'))
							.min(1, t('validation.number-of-seat.min'))
							.typeError(t(validation_type_message))
					})
			},
			[
				['seatPerPartnerLimit', 'hasPartnerLimit'],
				['seatPerPartnerLimit', 'participationType'],
				['partnerLimit', 'participationType'],
				['partnerLimit', 'hasPartnerLimit'],
				['participantLimit', 'participationType'],
				['participantLimit', 'hasParticipantLimit']
			]
		);

		const {
			watch,
			control,
			register,
			setValue,
			getValues,
			clearErrors,
			handleSubmit,
			formState: { errors, isDirty }
		} = useForm<EventFormRegistrationType>({
			resolver: yupResolver(schema),
			defaultValues: fields
		});

		const updateDateState = (
			fieldName: 'visitor' | 'partner',
			val: string,
			dateState: 'start' | 'end' = 'start'
		) => {
			if (dateState === 'start') {
				setStartCET((prev) => ({
					...prev,
					[fieldName]: val
				}));
			} else {
				setEndCET((prev) => ({
					...prev,
					[fieldName]: val
				}));
			}
		};

		const getParticipationType: () => RadioOption[] = () => {
			return [
				{
					label: t('forms.event.participation-public'),
					value: 0
				},
				{
					label: t('forms.event.participation-with-registration'),
					value: 1
				},
				{
					label: t('forms.event.participation-vip'),
					value: 2
				}
			];
		};

		const getLimitType: () => RadioOption[] = () => {
			return [
				{
					label: t('forms.event.limited'),
					value: 1
				},
				{
					label: t('forms.event.unlimited'),
					value: 0
				}
			];
		};

		const onParticipationChange = () => {
			setValue('hasParticipantLimit', 0);
			setValue('participantLimit', '-1');
			setValue('registrationStartDate', '');
			setValue('registrationEndDate', '');
			updateDateState('visitor', '');
			updateDateState('visitor', '', 'end');
			clearErrors();
		};

		const onEventPartnerChange = () => {
			setValue('hasPartnerLimit', 0);
			setValue('partnerNeedInvitation', false);
			setValue('partnerLimit', '-1');
			setValue('seatPerPartnerLimit', '-1');
			setValue('partnerRegistrationStartDate', '');
			setValue('partnerRegistrationEndDate', '');
			setValue('showPartnersDetail', true);
			updateDateState('partner', '');
			updateDateState('partner', '', 'end');
			clearErrors();
		};

		const handleLimitChange = (
			value: string | number,
			type: 'partner' | 'visitor' = 'partner'
		) => {
			if (type === 'partner') {
				if (value) {
					setValue('partnerLimit', '1');
					setValue('seatPerPartnerLimit', '1');
				} else {
					setValue('partnerLimit', '-1');
					setValue('seatPerPartnerLimit', '-1');
				}
			} else if (type === 'visitor') {
				if (value) {
					setValue('participantLimit', '1');
				} else {
					setValue('participantLimit', '-1');
				}
			}
		};

		const onStartDateChange = (
			val: Date,
			fieldName: 'partner' | 'visitor',
			endDateFieldName:
				| 'registrationEndDate'
				| 'partnerRegistrationEndDate'
		) => {
			if (val) {
				const startDate = val;
				const endDate = new Date(getValues(endDateFieldName));
				updateDateState(fieldName, formatTimeToCET(startDate));
				if (
					(endDate && isAfter(startDate, endDate)) ||
					isSameDay(startDate, endDate)
				) {
					setValue(endDateFieldName, '');
				}
			} else {
				updateDateState(fieldName, '');
			}
		};

		const onEndDateChange = (
			val: Date,
			fieldName: 'visitor' | 'partner'
		) => {
			updateDateState(fieldName, val ? formatTimeToCET(val) : '', 'end');
		};

		const filterPassedTime = (time: Date) => {
			const currentDate = new Date();
			const selectedDate = new Date(time);

			return currentDate.getTime() < selectedDate.getTime();
		};

		usePrompt(t('forms.leave-screen-massage'), isDirty);

		const onSubmit: SubmitHandler<EventFormRegistrationType> = (data) => {
			onNext?.(data);
		};

		useEffect(() => {
			updateDateState(
				'visitor',
				watch('registrationStartDate') || fields?.registrationStartDate
					? formatTimeToCET(
							watch('registrationStartDate') ||
								fields?.registrationStartDate
					  )
					: ''
			);
			updateDateState(
				'partner',
				watch('partnerRegistrationStartDate') ||
					fields?.partnerRegistrationStartDate
					? formatTimeToCET(
							watch('partnerRegistrationStartDate') ||
								fields?.partnerRegistrationStartDate
					  )
					: ''
			);
			updateDateState(
				'visitor',
				watch('registrationEndDate') || fields?.registrationEndDate
					? formatTimeToCET(
							watch('registrationEndDate') ||
								fields?.registrationEndDate
					  )
					: '',
				'end'
			);
			updateDateState(
				'partner',
				watch('partnerRegistrationEndDate') ||
					fields?.partnerRegistrationEndDate
					? formatTimeToCET(
							watch('partnerRegistrationEndDate') ||
								fields?.registrationEndDate
					  )
					: '',
				'end'
			);
		}, [
			fields?.registrationStartDate,
			fields?.registrationEndDate,
			fields?.partnerRegistrationStartDate,
			fields?.partnerRegistrationEndDate,
			watch,
			currentLocaleLang
		]);

		return (
			<form
				onSubmit={handleSubmit(onSubmit)}
				className={styles.form}
				noValidate>
				<Row>
					<Col xxl={8}>
						<section className="mb-3 pb-2">
							<h4 className="fs-sm d-flex align-items-center text-primary mb-3">
								<FontAwesome
									icon='users'
									size="lg"
									className="me-2"
									fixedWidth
								/>
								{t('title.event.visitors')}
							</h4>
							<div className="ps-sm-4 ps-lg-0 ps-xl-4 ms-sm-1 ms-lg-0 ms-xl-1">
								<FormGroup>
									<RadioController
										control={control}
										name="participationType"
										option={getParticipationType()}
										defaultSelected={0}
										radioClassName="d-flex flex-column gap-3 pb-1"
										error={errors.participationType}
										onRadioChange={onParticipationChange}
									/>
								</FormGroup>
								{watch('participationType') !== 0 && (
									<>
										<FormGroup>
											<Label htmlFor="participantLimit">
												{t('forms.event.total-visitor')}
												<small className="ms-1">
													({t(required_message)})
												</small>
											</Label>
											<RadioController
												control={control}
												name="hasParticipantLimit"
												option={getLimitType()}
												radioClassName="d-flex align-items-center pt-1 gap-3"
												onRadioChange={(value) =>
													handleLimitChange(
														value,
														'visitor'
													)
												}
											/>
										</FormGroup>
										{!!watch('hasParticipantLimit') && (
											<FormGroup row>
												<Col sm={6}>
													<input
														{...register(
															'participantLimit'
														)}
														type="text"
														id="participantLimit"
														placeholder={t(
															'placeholder.number-of-visitors'
														)}
														className="inputbox w-100"
													/>
													{errors.participantLimit && (
														<div className="invalid-feedback d-block">
															{
																errors
																	.participantLimit
																	.message
															}
														</div>
													)}
												</Col>
											</FormGroup>
										)}
										<FormGroup row>
											<Col
												sm={6}
												lg={12}
												xl={6}
												className="mb-3 mb-sm-0 mb-lg-3 mb-xl-0">
												<Label htmlFor="startDate">
													{t(
														'forms.event.start-registration'
													)}
												</Label>
												<EventDatePicker
													control={control}
													name="registrationStartDate"
													maxDate={new Date(endDate)}
													defaultSelected={
														fields?.registrationStartDate
													}
													isClearable={watch(
														'registrationStartDate'
													)}
													filterTime={
														filterPassedTime
													}
													onDateChange={(val: Date) =>
														onStartDateChange(
															val,
															'visitor',
															'registrationEndDate'
														)
													}
												/>
												{startDateCET.visitor && (
													<div className="text-gray-3 fs-small mt-2">
														{startDateCET.visitor}
													</div>
												)}
											</Col>
											<Col sm={6} lg={12} xl={6}>
												<Label htmlFor="registrationEndDate">
													{t(
														'forms.event.end-registration'
													)}
												</Label>
												<EventDatePicker
													control={control}
													name="registrationEndDate"
													minDate={
														watch(
															'registrationStartDate'
														) || new Date()
													}
													maxDate={new Date(endDate)}
													defaultSelected={
														fields?.registrationEndDate
													}
													isClearable={watch(
														'registrationEndDate'
													)}
													filterTime={
														filterPassedTime
													}
													onDateChange={(val: Date) =>
														onEndDateChange(
															val,
															'visitor'
														)
													}
												/>
												{endDateCET.visitor && (
													<div className="text-gray-3 fs-small mt-2">
														{endDateCET.visitor}
													</div>
												)}
											</Col>
										</FormGroup>
										<FormInlineTip tip="forms.event.date-info" />
									</>
								)}
							</div>
						</section>
						<section className="mb-3 pb-2">
							<h4 className="fs-sm d-flex align-items-center text-primary mb-3">
								<FontAwesome
									icon='buildings'
									size="lg"
									className="me-2"
									fixedWidth
								/>
								{t('title.event.event-partners')}
							</h4>
							<div className="ps-sm-4 ps-lg-0 ps-xl-4 ms-sm-1 ms-lg-0 ms-xl-1">
								<FormGroup>
									<SwitchController
										control={control}
										name="hasPartner"
										boxClassName="pt-2"
										label="forms.event.enable-event-partner-registration"
										onSwitchChange={onEventPartnerChange}
									/>
								</FormGroup>
								{watch('hasPartner') && (
									<>
										<FormGroup>
											<SwitchController
												control={control}
												name="partnerNeedInvitation"
												boxClassName="py-2 mb-2"
												label="forms.event.only-with-invitation"
											/>
											<FormInlineTip tip="forms.event.enable-event-partner-registration-desc" />
										</FormGroup>
										<FormGroup>
											<Label htmlFor="partnerLimit">
												{t(
													'forms.event.total-event-partners'
												)}
												<small className="ms-1">
													({t(required_message)})
												</small>
											</Label>
											<RadioController
												control={control}
												name="hasPartnerLimit"
												option={getLimitType()}
												radioClassName="d-flex align-items-center pt-1 gap-3"
												onRadioChange={(value) =>
													handleLimitChange(
														value,
														'partner'
													)
												}
											/>
										</FormGroup>
										{!!watch('hasPartnerLimit') && (
											<>
												<FormGroup row>
													<Col sm={6}>
														<input
															{...register(
																'partnerLimit'
															)}
															type="text"
															id="partnerLimit"
															placeholder={t(
																'placeholder.number-of-event-partners'
															)}
															className="inputbox w-100"
														/>
														{errors.partnerLimit && (
															<div className="invalid-feedback d-block">
																{
																	errors
																		.partnerLimit
																		.message
																}
															</div>
														)}
													</Col>
												</FormGroup>
												<FormGroup row>
													<Col xs={12}>
														<Label htmlFor="seatPerPartnerLimit">
															{t(
																'forms.event.event-partners-max-seat'
															)}
															<small className="ms-1">
																(
																{t(
																	'validation.required'
																)}
																)
															</small>
														</Label>
													</Col>
													<Col sm={6}>
														<input
															{...register(
																'seatPerPartnerLimit'
															)}
															type="text"
															id="seatPerPartnerLimit"
															className="inputbox w-100"
														/>
														{errors.seatPerPartnerLimit && (
															<div className="invalid-feedback d-block">
																{
																	errors
																		.seatPerPartnerLimit
																		.message
																}
															</div>
														)}
													</Col>
												</FormGroup>
											</>
										)}
										<FormGroup row>
											<Col
												sm={6}
												lg={12}
												xl={6}
												className="mb-3 mb-sm-0 mb-lg-3 mb-xl-0">
												<Label htmlFor="startDate">
													{t(
														'forms.event.start-registration'
													)}
												</Label>
												<EventDatePicker
													control={control}
													name="partnerRegistrationStartDate"
													maxDate={new Date(endDate)}
													defaultSelected={
														fields?.partnerRegistrationStartDate
													}
													isClearable={watch(
														'partnerRegistrationStartDate'
													)}
													filterTime={
														filterPassedTime
													}
													onDateChange={(val: Date) =>
														onStartDateChange(
															val,
															'partner',
															'partnerRegistrationEndDate'
														)
													}
												/>
												{startDateCET.partner && (
													<div className="text-gray-3 fs-small mt-2">
														{startDateCET.partner}
													</div>
												)}
											</Col>
											<Col sm={6} lg={12} xl={6}>
												<Label htmlFor="registrationEndDate">
													{t(
														'forms.event.end-registration'
													)}
												</Label>
												<EventDatePicker
													control={control}
													name="partnerRegistrationEndDate"
													minDate={
														watch(
															'partnerRegistrationStartDate'
														) || new Date()
													}
													maxDate={new Date(endDate)}
													defaultSelected={
														fields?.partnerRegistrationEndDate
													}
													isClearable={watch(
														'partnerRegistrationEndDate'
													)}
													filterTime={
														filterPassedTime
													}
													onDateChange={(val: Date) =>
														onEndDateChange(
															val,
															'partner'
														)
													}
												/>
												{endDateCET.partner && (
													<div className="text-gray-3 fs-small mt-2">
														{endDateCET.partner}
													</div>
												)}
											</Col>
										</FormGroup>
										<FormInlineTip tip="forms.event.date-info" />
										<FormGroup>
											<SwitchController
												control={control}
												name="showPartnersDetail"
												label="forms.event.show-participant-details"
												boxClassName="pt-2"
											/>
										</FormGroup>
									</>
								)}
							</div>
						</section>
						<StepNavigation onPrev={onPrev} />
					</Col>
				</Row>
			</form>
		);
	}
);

CreateEventRegistration.displayName = 'CreateEventRegistration';
