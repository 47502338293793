import { memo, FC } from 'react';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { AgentPlan } from 'Types';
import { currencyFormatter } from 'Utils';
import { AgentAboCalculator, CurrencyController } from 'Elements';

export const AgentPlanPriceCalculation: FC = memo(() => {
	const { t } = useTranslation();
	const { control, watch } = useFormContext<AgentPlan>();

	const basePlanPrice = 1000;
	const watchPrice = +watch('price') || 0;
	const additionalAgentPrice = currencyFormatter(basePlanPrice + watchPrice);

	return (
		<Row className="g-0">
			<Col
				md={6}
				lg={12}
				xl={6}
				className="border-sm-right border-md-right-none border-lg-right border-gray-1 pe-md-4 pe-lg-0 pe-xl-4 pb-4 pb-md-0 pb-lg-4 pb-xl-0">
				<div className="d-flex align-items-center lh-base">
					{t('label.plan-price.base-plan-price')}
					<span className="fs-base fw-500 ms-auto">
						{currencyFormatter(basePlanPrice)}
					</span>
				</div>
				<div className="d-flex align-items-center lh-base pb-3 my-3 border-bottom">
					{t('label.plan-price.your-plan-price')}
					<CurrencyController
						control={control}
						name="price"
						className="ms-auto"
						inputClassName="text-end"
						suffix=" €"
					/>
				</div>
				<div className="d-flex align-items-center lh-base">
					<span className="fw-500 fs-base">
						{t('label.plan-price.total-plan-price')}
					</span>
					<div className="d-flex flex-column ms-auto gap-1">
						<span className="fs-6 fw-700 text-gray-5 lh-base text-end">
							{additionalAgentPrice}
						</span>
						<span className="text-gray-3 lh-1 fw-400 fs-small text-end">
							{t('label.excluded-tax')}
						</span>
					</div>
				</div>
			</Col>
			<Col md={6} lg={12} xl={6}>
				<AgentAboCalculator
					additionalPlanPrice={watchPrice}
					className="ps-md-4 ps-lg-0 ps-xl-4 pt-4 pt-md-0 pt-lg-4 pt-xl-0"
				/>
			</Col>
		</Row>
	);
});

AgentPlanPriceCalculation.displayName = 'AgentPlanPriceCalculation';
