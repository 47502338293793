import { memo, useEffect } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { Container } from 'reactstrap';
import { clx } from 'Utils';
import { AgentSetting, ColorValues } from 'Types';
import { useAgentProfilePlan, useUpdateAgent } from 'Hooks';
import {
	LoadingSpinner,
	SectionSwitcher,
	AgentCompanyOverviewPremium,
	AgentCompanyOverviewStandard
} from 'Elements';
import styles from 'partials/homepage/section/agent-company-overview-section.module.scss';

const Background = styled.div<{ $isPremium: boolean; $image?: string }>`
	${({ $isPremium, $image }) =>
		$isPremium &&
		`
		position: relative;
		${
			$image &&
			`
			background-image: url(${$image});
			background-size: cover;
			background-repeat: no-repeat;
		`
		};
		&:before {
			content: '';
			position: absolute;
			right: 0;
			left: 0;
			top: 0;
			bottom: 0;
			background-color: ${ColorValues['gray-6']};
			opacity: ${$image ? 0.85 : 1};
		}`}
	${({ $isPremium }) =>
		!$isPremium && `background-color: ${ColorValues['gray-3']};`}
`;

export const AgentCompanyOverviewSection = memo(() => {
	const { control, reset } = useForm();
	const {
		setting,
		isLoading,
		updateAgentSetting,
		agent: agentProfile
	} = useUpdateAgent();
	const { data: agentPlanInfo } = useAgentProfilePlan();
	const isPremium = agentProfile?.commissionGroup?.isPremium;

	const handleUpdate = async (field: Partial<AgentSetting>) => {
		await updateAgentSetting(field);
	};

	useEffect(() => {
		reset(setting);
	}, [reset, setting]);

	return (
		<section className="position-relative">
			{isLoading && (
				<div className="d-flex align-items-center justify-content-center">
					<LoadingSpinner />
				</div>
			)}
			{!isLoading && (
				<>
					<Container>
						<SectionSwitcher
							isActive={!setting?.companyOverviewSectionStatus}
							control={control}
							name="companyOverviewSectionStatus"
							label="forms.show-company-overview-in-home"
							onSwitchChange={(val) =>
								handleUpdate({
									companyOverviewSectionStatus: val
								})
							}
						/>
					</Container>
					<Background
						$isPremium={!!isPremium}
						$image={agentPlanInfo?.coverPhotoUrl}>
						<Container
							fluid={!isPremium ? 'lg' : false}
							className={clx(
								styles.container,
								!isPremium && 'g-0'
							)}>
							{isPremium ? (
								<AgentCompanyOverviewPremium />
							) : (
								<AgentCompanyOverviewStandard />
							)}
						</Container>
					</Background>
				</>
			)}
			{!setting?.companyOverviewSectionStatus && (
				<div className="overlay overlay_white overlay-box" />
			)}
		</section>
	);
});

AgentCompanyOverviewSection.displayName = 'AgentCompanyOverviewSection';
