import { memo, FC } from 'react';
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown
} from 'reactstrap';
import { clx } from 'Utils';
import styles from 'partials/shared/heading/page-heading-size.module.scss';

type Props =
	| {
			pageSizes?: number[];
			selectedPageSize?: number;
			onChange?: (pageSize: number) => void;
	  }
	| {
			pageSizes: number[];
			selectedPageSize: number;
			onChange: (pageSize: number) => void;
	  };

export const PageHeadingPageSize: FC<Props> = memo(
	({ pageSizes = [1, 5, 10, 20], selectedPageSize, onChange }) => {
		return (
			<UncontrolledDropdown
				className={clx(
					styles.box,
					'd-inline-flex align-items-center position-relative ms-2'
				)}>
				<DropdownToggle tag="div" caret className="dropdown-toggle">
					{selectedPageSize}
				</DropdownToggle>
				<DropdownMenu end className={styles.menu}>
					{pageSizes.map((size, index) => {
						return (
							<DropdownItem
								key={index}
								className={styles.item}
								onClick={() => onChange?.(size)}>
								{size}
							</DropdownItem>
						);
					})}
				</DropdownMenu>
			</UncontrolledDropdown>
		);
	}
);

PageHeadingPageSize.displayName = 'PageHeadingPageSize';
