import { FC, memo } from 'react';
import { FormGroup } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { clx } from 'Utils';
import { Colors, XOR } from 'Types';
import { Button, SubmitButton, FontAwesome } from 'Elements';

interface SharedProps {
	isSubmitting: boolean;
	isDisable?: boolean;
	className?: string;
	label?: string;
	color?: Colors;
	icon?: any | false;
	savingLabel?: string;
}

type RemoveProps = XOR<
	{
		onRemove: () => void;
		removeTitle: string;
		isRemoving: boolean;
	},
	{
		onRemove?: () => void;
		removeTitle?: string;
		isRemoving?: boolean;
	}
>;

type Props = SharedProps & RemoveProps;

export const SubmitButtonGroup: FC<Props> = memo(
	({
		isDisable,
		isSubmitting,
		className,
		label,
		icon,
		color,
		savingLabel,
		removeTitle = 'button.remove',
		onRemove
	}) => {
		const { t } = useTranslation();
		return (
			<FormGroup
				className={clx(
					'd-flex align-items-center flex-wrap gap-2 pt-3',
					className
				)}>
				{onRemove && (
					<Button
						type="button"
						color={Colors['white-red']}
						className="me-auto"
						onClick={onRemove}>
						<FontAwesome
							icon="trash-xmark"
							size="lg"
							className="me-2"
						/>
						{t(removeTitle)}
					</Button>
				)}
				<SubmitButton
					type="submit"
					icon={icon}
					color={color}
					isDisable={isDisable}
					isSubmitting={isSubmitting}
					label={label}
					savingLabel={savingLabel}
					className="ms-auto"
				/>
			</FormGroup>
		);
	}
);

SubmitButtonGroup.displayName = 'SubmitButtonGroup';
