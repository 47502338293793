import { Colors, SocialNetwork, AgentCommissionGroup } from 'Types';

export interface SelectOption {
	label: string;
	value: string | number;
}

export interface SelectOptionIcon {
	value: any;
	label: string;
	icon: any;
}

export interface RadioOption {
	label: string;
	value: string | number;
	name?: string;
	disabled?: boolean;
}

export interface FieldValues {
	[x: string]: any;
}

export interface DataList<T> {
	items: T[];
	pageNumber: number;
	pageSize: number;
	totalItems: number;
	totalPages: number;
}

export interface Error {
	type: null;
	title: string;
	status: number;
	detail: string;
	instance: null;
	extensions: Extensions;
}

export interface Extensions {
	traceId: string;
}

export interface Media {
	url: string;
	type: string;
	thumbnailUrl: string;
	metaData: MetaData;
}

export enum AspectRatio {
	'16/9' = '16/9',
	'3/4' = '3/4',
	'1/1' = '1/1',
	'0/0' = '0/0'
}

export interface MetaData {
	StoredHeight: string;
	StoredWidth: string;
	ActualHeight: string;
	ActualWidth: string;
	HorizontalResolution: string;
	VerticalResolution: string;
	StoredSize: string;
	ActualSize: string;
	OriginalName: string;
}

export interface Contact {
	email: string;
	fullName: string;
	firstName: string;
	lastName: string;
	position: string;
	tel: string;
	contactDescription: string;
	contactImage: string;
	contactThumbnailImage: string;
}

export interface ContactForm {
	contact: Contact;
	socialNetwork: SocialNetwork[];
}

export interface Status {
	approveStatus: number;
	updatedAt: string;
	createdBy: string;
}

export interface BadgeStatus {
	value: string | number;
	title?: string;
	label: string;
	color: Colors;
	type?: string[];
}

export interface Translate {
	language: string;
	name: string;
	description: string;
}

export type ResponsiveSize = 'sm' | 'md' | 'lg' | 'xl';

export interface ModalProps {
	size?: ResponsiveSize;
	isOpen: boolean;
	onClose: () => void;
	title?: string;
}

export enum DataTableActionTypes {
	'edit' = 'edit',
	'delete' = 'delete',
	'view' = 'view'
}

export interface Tab {
	id: number;
	title: string;
	path: string;
	isActive: boolean;
	isDisabled?: boolean;
	number?: string;
}

export interface CustomerDto {
	name: string;
	email: string;
	logo: string;
	commissionGroup?: AgentCommissionGroup;
	agentPlanInfo?: {
		additionalCharge: number;
		description: string;
		updatedAt: string;
		updatedBy: string;
		features: {
			title: string;
			description: string;
			icon: any;
			sortOrder: number;
		}[];
	};
}

export interface IStep {
	id: string;
	title: string;
}

export interface JWTToken {
	exp: number;
	nbf: number;
	ver: string;
	iss: string;
	sub: string;
	aud: string;
	nonce: string;
	iat: number;
	auth_time: number;
	oid: string;
	name: string;
	given_name: string;
	family_name: string;
	extension_userid: string;
	extension_usertype: number;
	extension_tenant: string;
	tfp: string;
}

export interface Event<T = EventTarget> {
	target: T;
}

type Without<T, U> = { [P in Exclude<keyof T, keyof U>]?: never };

export type XOR<T, U> = T | U extends object
	? (Without<T, U> & U) | (Without<U, T> & T)
	: T | U;
