

export enum PaymentMethod {
	invoice = 0,
	online = 1,
	pending = 2,
	payPal = 3,
	sepa = 5
}
export interface Payment {
	details: PaymentDetails;
	resource: string;
	id: string;
	mode: string;
	createdAt: string;
	status: string;
	isCancelable: boolean;
	authorizedAt: string;
	paidAt: string;
	canceledAt: string;
	expiresAt: string;
	expiredAt: string;
	failedAt: string;
	amount: PaymentAmount;
	amountRefunded: PaymentAmount;
	amountRemaining: PaymentAmount;
	amountCaptured: string;
	amountChargedBack: string;
	description: string;
	redirectUrl: string;
	webhookUrl: string;
	method: string;
	metadata: string;
	locale: string;
	countryCode: string;
	profileId: string;
	settlementAmount: PaymentAmount;
	settlementId: string;
	customerId: string;
	sequenceType: string;
	mandateId: string;
	subscriptionId: string;
	orderId: string;
	applicationFee: string;
	_links: PaymentLinks;
}

export interface PaymentDetails {
	cardHolder: string;
	cardNumber: string;
	cardFingerprint: string;
	cardAudience: string;
	cardLabel: string;
	cardCountryCode: string;
	cardSecurity: string;
	feeRegion: string;
	failureReason: string;
	failureMessage: string;
	wallet: string;
}

export interface PaymentLinks {
	self: PaymentLinksState;
	checkout: PaymentLinksState;
	refunds: PaymentLinksState;
	chargebacks: PaymentLinksState;
	settlement: PaymentLinksState;
	documentation: PaymentLinksState;
	mandate: PaymentLinksState;
	subscription: PaymentLinksState;
	customer: PaymentLinksState;
	changePaymentState: PaymentLinksState;
}

export interface PaymentLinksState {
	href: string;
	type: string;
}

export interface PaymentAmount {
	currency: string;
	value: string;
}
export interface PaymentMethodCard {
	id: number;
	title: string;
	icon: any;
	description: string;
}
