import {
	memo,
	startTransition,
	Suspense,
	useCallback,
	useEffect,
	useState
} from 'react';
import * as yup from 'yup';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import type { FieldValues, SubmitHandler } from 'react-hook-form/dist/types';
import { clx, toastify } from 'Utils';
import { names } from 'Constants';
import { useDelayUnmount, useModal, useResponsive, useUpdateJob } from 'Hooks';
import {
	LoadingContent,
	JobDecorationBox,
	Card,
	SubmitButton,
	Button,
	ModalBoxDelete,
	SaveAndPublishJob,
	FontAwesome
} from 'Elements';
import {
	Activity,
	Colors,
	JobFormData,
	JobFormInitialData,
	JobType,
	Sector,
	SelectOption,
	Translate
} from 'Types';
import styles from 'partials/page/form.module.scss';

export const UpdateJob = memo(() => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { jobs } = names;
	const { job_id } = useParams();
	const { isDesktopAndBelow } = useResponsive();
	const [openModal, toggleModal] = useModal();
	const [isRemoving, setIsRemoving] = useState(false);
	const shouldRenderModal = useDelayUnmount(openModal, 350);
	const descriptionLimit = 450;

	const {
		data: fields,
		isFetching,
		refetch,
		updateJob,
		removeJob
	} = useUpdateJob(job_id as string);
	const name = fields?.title;

	const schema = yup.object().shape({
		title: yup.string().required(t('validation.job-title.required')),
		startDate: yup.string().when(['hasStartDate'], {
			is: true,
			then: yup
				.string()
				.nullable()
				.required(t('validation.start-date.required'))
		}),
		expireDate: yup.string().when(['hasExpireDate'], {
			is: true,
			then: yup
				.string()
				.nullable()
				.required(t('validation.expire-date.required'))
		}),
		sectorId: yup
			.object()
			.shape({
				label: yup.string(),
				value: yup.string()
			})
			.nullable()
			.required(t('validation.select-sector.required')),
		jobTypeId: yup
			.object()
			.shape({
				label: yup.string(),
				value: yup.string()
			})
			.nullable()
			.required(t('validation.select-job-type.required')),
		activityList: yup
			.array()
			.min(1, t('validation.select-activity.required'))
			.of(
				yup.object().shape({
					label: yup.string(),
					value: yup.string()
				})
			)
			.nullable()
			.required(t('validation.select-activity.required'))
	});

	const formMethods = useForm<JobFormInitialData>({
		resolver: yupResolver(schema),
		shouldUnregister: false,
		mode: 'onChange'
	});

	const {
		reset,
		trigger,
		handleSubmit,
		formState: { isDirty, isSubmitting }
	} = formMethods;

	const defaultCodeName: (
		section: FieldValues,
		section_name: string
	) => string = (section, section_name) => {
		const foundedName = section?.[`${section_name}Translates`]?.find(
			(translate: Translate) =>
				translate.name !== '' && translate.language === 'de'
		)?.name;
		return foundedName
			? `${foundedName} (${section?.code})`
			: section?.code;
	};

	const optionsListObject = useCallback(
		(key_obj: Sector | JobType | Activity, key_name: string) => {
			return {
				value: key_obj?.['id'],
				label: defaultCodeName(key_obj, key_name)
			} as SelectOption;
		},
		[]
	);

	const getJobFormData = (data: JobFormInitialData) => {
		return {
			...data,
			expireDate: data?.expireDate
				? format(new Date(data?.expireDate), 'yyyy-MM-dd')
				: '',
			startDate: data?.startDate
				? format(new Date(data?.startDate), 'yyyy-MM-dd')
				: '',
			sectorId: data.sectorId?.value,
			jobTypeId: data.jobTypeId?.value,
			activityList: data.activityList?.map((activity: SelectOption) => {
				return { activityId: activity.value };
			})
		} as JobFormData;
	};

	const getDefaultSector = useCallback(
		(sectors?: Sector) => {
			if (sectors) {
				return optionsListObject(sectors, 'sector');
			}
			return null;
		},
		[optionsListObject]
	);

	const getDefaultJobType = useCallback(
		(jobTypes?: JobType) => {
			if (jobTypes) {
				return optionsListObject(jobTypes, 'jobType');
			}
			return null;
		},
		[optionsListObject]
	);

	const getDefaultActivity = useCallback(
		(activities?: Activity[]) => {
			const options: SelectOption[] = [];
			if (activities?.length) {
				activities.forEach((activity: Activity) => {
					options.push(optionsListObject(activity, 'activity'));
				});
			}
			return options;
		},
		[optionsListObject]
	);

	const submitForm: (
		data: JobFormInitialData,
		onFinal?: () => void
	) => Promise<void> = async (data, onFinal) => {
		await updateJob(job_id as string, getJobFormData(data), onFinal);
	};

	const handleRemoveJob = async () => {
		try {
			setIsRemoving(true);
			await removeJob(job_id as string, () => {
				startTransition(() => {
					setIsRemoving(false);
					navigate(`${jobs.path}`);
				});
			});
		} finally {
			setIsRemoving(false);
		}
	};

	const submitHandler: SubmitHandler<JobFormInitialData> = async (data) => {
		const isValidForm = await trigger();
		if (isValidForm) {
			await submitForm(data);
		} else {
			toastify(t('toastify.check-required-fields'));
		}
	};

	useEffect(() => {
		reset({
			...fields,
			contactPerson: {
				...fields?.contactPerson,
				image: fields?.contactPerson?.image ?? '',
				fullName: fields?.contactPerson?.fullName ?? '',
				emailAddress: fields?.contactPerson?.emailAddress ?? '',
				phoneNumber: fields?.contactPerson?.phoneNumber ?? '',
				visibleOnDetail: fields?.contactPerson?.visibleOnDetail ?? false
			},
			hasStartDate: !!fields?.startDate,
			hasExpireDate: !!fields?.expireDate,
			startDate: fields?.startDate ?? '',
			expireDate: fields?.expireDate ?? '',
			shortDescription: fields?.shortDescription.substring(
				0,
				descriptionLimit
			),
			benefits: fields?.benefits || '',
			requirements: fields?.requirements || '',
			responsibilities: fields?.responsibilities || '',
			description: fields?.description || '',
			sectorId: getDefaultSector(fields?.sectors),
			jobTypeId: getDefaultJobType(fields?.jobTypes),
			activityList: getDefaultActivity(fields?.activityLists)
		});
	}, [
		reset,
		fields,
		getDefaultSector,
		getDefaultJobType,
		getDefaultActivity
	]);

	useEffect(()=>{
		refetch();
	},[])

	return (
		<FormProvider {...formMethods}>
			<form
				onSubmit={handleSubmit(submitHandler)}
				className={clx(
					isDesktopAndBelow && styles.form,
					'position-relative'
				)}>
				<JobDecorationBox />
				<Suspense fallback={<LoadingContent />}>
					<Card className={clx(isDesktopAndBelow && 'mb-4')}>
						<Outlet context={fields} />
						{isFetching && <LoadingContent />}
					</Card>
				</Suspense>
				<Card
					className={clx(isDesktopAndBelow && styles.sticky_action)}>
					<div className="d-flex align-items-center gap-3 flex-wrap">
						<div className="d-flex align-items-center me-auto gap-2">
							{fields?.publishedVersion === null && (
								<Button
									size="sm"
									color={Colors['white-red']}
									className="gap-2"
									onClick={toggleModal}>
									<FontAwesome icon="trash-xmark" size="lg" />
									{t('button.remove')}
								</Button>
							)}
							<Button
								as="a"
								to="preview"
								size="sm"
								color={Colors['white-secondary']}
								className="gap-2">
								<FontAwesome icon="eye" size="lg" />
								{t('button.job.preview-current-version')}
							</Button>
						</div>
						<div className="d-flex align-items-center gap-2">
							<SubmitButton
								color={Colors['white-primary']}
								isDisable={!isDirty}
								isSubmitting={isSubmitting}
							/>
							<SaveAndPublishJob formData={getJobFormData} />
						</div>
					</div>
				</Card>
			</form>
			{shouldRenderModal && (
				<ModalBoxDelete
					title={name ?? '---'}
					isOpen={openModal}
					isRemoving={isRemoving}
					onClose={toggleModal}
					onRemove={handleRemoveJob}
				/>
			)}
		</FormProvider>
	);
});

UpdateJob.displayName = 'UpdateJob';
