import { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Container, Row } from 'reactstrap';
import { useInfiniteQuery } from 'react-query';
import axios from 'Adapter';
import { api } from 'Utils';
import { queryKey } from 'Constants';
import { useTheme, useUpdateAgent } from 'Hooks';
import { AgentPartner, AgentSetting, Colors, DataList } from 'Types';
import {
	AgentPartnerCard,
	LoadingSpinner,
	NoData,
	SectionHeadline,
	SectionSwitcher,
	SubmitButton
} from 'Elements';

export const AgentPartnerSection = memo(() => {
	const perRow = 8;
	const { theme } = useTheme();
	const { t } = useTranslation();
	const { control, reset } = useForm();
	const {
		setting,
		isLoading: isLoadingSetting,
		updateAgentSetting
	} = useUpdateAgent();

	const {
		data: partners,
		isFetching,
		isLoading,
		fetchNextPage,
		hasNextPage
	} = useInfiniteQuery<any, unknown, DataList<AgentPartner>>(
		queryKey.agentPartners,
		async ({ pageParam }) => {
			const res = await axios.get(
				`${api.getAgentCompanies}?pageSize=${perRow}&pageNumber=${
					pageParam || 1
				}&keyword&agentApprovalStatus`
			);
			return res.data ?? [];
		},
		{
			getNextPageParam: (lastPage, pages) => {
				if (lastPage.pageNumber < lastPage.totalPages) {
					return pages.length + 1;
				}
			}
		}
	);

	const handleUpdate = async (field: Partial<AgentSetting>) => {
		await updateAgentSetting(field);
	};

	useEffect(() => {
		reset(setting);
	}, [reset, setting]);

	return (
		<section className="position-relative mb-5 pb-5">
			{isLoading && (
				<div className="d-flex align-items-center justify-content-center">
					<LoadingSpinner />
				</div>
			)}
			{!isLoading && !isLoadingSetting && (
				<Container>
					<SectionSwitcher
						control={control}
						name="partnerHeadlineStatus"
						label="forms.show-partners-in-home"
						isActive={!setting?.partnerHeadlineStatus}
						onSwitchChange={(val) =>
							handleUpdate({ partnerHeadlineStatus: val })
						}
					/>
					<SectionHeadline
						titleColor="text-gray-5"
						title={
							setting?.partnerHeadline ||
							`[${t('placeholder.partner-title')}]`
						}
						description={
							setting?.partnerDescription ||
							`[${t('placeholder.partner-description')}]`
						}
						onUpdateTitle={(val) =>
							handleUpdate({ partnerHeadline: val })
						}
						onUpdateDescription={(val) =>
							handleUpdate({ partnerDescription: val })
						}
					/>
					{partners && partners?.pages[0]?.totalItems > 0 && (
						<Row className="justify-content-center">
							{partners?.pages.map((page) =>
								page.items?.map((partner) => (
									<AgentPartnerCard
										key={partner.id}
										partner={partner}
									/>
								))
							)}
						</Row>
					)}
					{hasNextPage && (
						<div className="text-center mt-4">
							<SubmitButton
								icon={false}
								type="button"
								savingLabel=""
								isSubmitting={isFetching}
								color={Colors['border-gray']}
								className="rounded-pill px-4"
								label={t('button.show-more') as string}
								onClick={() => fetchNextPage()}
							/>
						</div>
					)}
					{partners && partners?.pages[0]?.totalItems === 0 && (
						<NoData message={t('title.no-partner-yet')} />
					)}
				</Container>
			)}
			{!setting?.partnerHeadlineStatus && (
				<div className="overlay overlay_white overlay-box" />
			)}
		</section>
	);
});
AgentPartnerSection.displayName = 'AgentPartnerSection';
