import { Address } from 'Types';

export enum EventType {
	Online = 0,
	InPerson = 1,
	Hybrid = 2
}

export enum EventStatus {
	Draft = 0,
	Published = 1,
	Canceled = 2
}

export enum EventInvitationStatus {
	NotSend = 0,
	Pending = 1,
	Accepted = 2,
	Rejected = 3
}

export enum EventParticipationStatus {
	Active = 0,
	Canceled = 1,
	CanceledByAgent = 2,
	CheckIn = 3
}

export enum RegistrationStatus {
	NotStarted = 1,
	Open = 2,
	Finished = 3
}

export enum ParticipationType {
	Public = 0,
	WithRegistration = 1,
	VIP = 2
}

export interface AgentEvent {
	id: string;
	agentId: string;
	title: string;
	slugTitle: string;
	shortDescription: string;
	description: string;
	startDate: string | Date;
	endDate: string | Date;
	registrationStartDate: string | Date;
	registrationEndDate: string | Date;
	partnerRegistrationStartDate: string | Date;
	partnerRegistrationEndDate: string | Date;
	hasParticipantLimit: number;
	participantLimit: string;
	hasPartner: boolean;
	showPartnersDetail: boolean;
	partnerNeedInvitation: boolean;
	hasPartnerLimit: number;
	partnerLimit: string;
	seatPerPartnerLimit: string;
	featuredPhoto?: string;
	featuredVideo?: string;
	eventType: EventType;
	eventStatus: number;
	participationType: number;
	createdAt: string;
	modifiedAt: string;
	publishedAt: string;
	version: number;
	publishedVersion: number;
	address: Address;
	meetingLink: string;
	cancelReason: string;
	isPublished: boolean;
	isFinished: boolean;
	isStarted: boolean;
	isInternal: boolean;
	hasUnpublishedChanges: boolean;
	registrationStatus: RegistrationStatus;
	partnerRegistrationStatus: RegistrationStatus;
	partnerCount: number;
	visitorCount: number;
	partnerSeatCount: number;
	visitorSeatCount: number;
}

export interface EventFormGeneral {
	title: string;
	shortDescription: string;
	description: string;
	startDate: string | Date;
	endDate: string | Date;
	featuredPhoto: string;
	address: Address | null;
	eventType: EventType; // Online = 0; InPerson = 1; Hybrid = 2
	meetingLink: string;
	isInternal: boolean;
}

export interface EventFormRegistrationType {
	participationType: ParticipationType; // Public = 0; WithRegistration = 1; VIP = 2
	hasParticipantLimit: number;
	participantLimit: string;
	registrationStartDate: string | Date;
	registrationEndDate: string | Date;
	hasPartner: boolean;
	partnerNeedInvitation: boolean;
	hasPartnerLimit: number;
	partnerLimit: string;
	seatPerPartnerLimit: string;
	showPartnersDetail: boolean;
	partnerRegistrationStartDate: string | Date;
	partnerRegistrationEndDate: string | Date;
}

export interface EventForm {
	step1: EventFormGeneral;
	step2: EventFormRegistrationType;
}

export type EventFormData = EventFormGeneral & EventFormRegistrationType;

export interface CompaniesToInvite {
	id: string;
	logo: string;
	name: string;
	postalCode: string;
	city: string;
	distance: number;
}

export interface EventParticipant {
	id: string;
	eventId: string;
	agentId: string;
	eventInvitationId: string;
	companyId: string;
	fullName: string;
	companyName: string;
	companyLogo: string;
	phoneNumber: string;
	email: string;
	numberOfSeats: number;
	showDetails: boolean;
	isPartner: boolean;
	status: EventParticipationStatus; // Active = 0; Canceled = 1
	createdAt: string;
	modifiedAt: string;
	isExtern: boolean;
}

export interface EventInvitation {
	id: string;
	eventId: string;
	inviteDate: string;
	status: EventInvitationStatus; // All = null; NotSend = 0; Pending = 1; Accepted = 2; Rejected = 3
	responseDate: string;
	companyId: string;
	fullName: string;
	logo: string;
	email: string;
	isPartner: boolean;
	isExtern: boolean;
}

export interface EventInvitationForm {
	fullName: string;
	email: string;
}

export interface CompanyEventInvitation {
	id: string;
	companyId: string;
	eventInvitationId: string;
	eventId: string;
	agentId: string;
	inviteDate: string;
	status: number;
	responseDate: string;
	eventInfo: EventInfo;
	participationInfo: ParticipationInfo;
	isFinished: boolean;
	isCanceled: boolean;
	registrationStatus: RegistrationStatus; // NotStarted = 1; Open = 2; Finished = 3
	partnerRegistrationStatus: RegistrationStatus; // NotStarted = 1; Open = 2; Finished = 3
}

export interface CompanyParticipatedEvent {
	id: string;
	eventId: string;
	agentId: string;
	eventInvitationId: string;
	companyId: string;
	fullName: string;
	companyName: string;
	companyLogo: string;
	phoneNumber: string;
	email: string;
	numberOfSeats: number;
	showDetails: boolean;
	isPartner: boolean;
	status: EventParticipationStatus;
	createdAt: string;
	modifiedAt: string;
	isExtern: boolean;
	eventInfo: EventInfo;
	isFinished: boolean;
	isCanceled: boolean;
	registrationStatus: RegistrationStatus;
	partnerRegistrationStatus: RegistrationStatus;
}

export interface EventInfo {
	agentId: string;
	agentName: string;
	agentEmail: string;
	agentLogo: string;
	title: string;
	startDate: string;
	endDate: string;
	registrationStartDate: string;
	registrationEndDate: string;
	partnerRegistrationStartDate: string;
	partnerRegistrationEndDate: string;
	participantLimit: string;
	hasPartner: boolean;
	showPartnersDetail: boolean;
	partnerNeedInvitation: boolean;
	partnerLimit: string;
	seatPerPartnerLimit: string;
	featuredPhoto: string;
	featuredVideo: string;
	eventType: EventType;
	participationType: number;
	address: Address;
	meetingLink: string;
	isPublished: boolean;
	publishedVersion: number;
	slugTitle: string;
	cancelReason: string;
	shortDescription: string;
}

export interface ParticipationInfo {
	eventParticipantId: string;
	fullName: string;
	companyName: string;
	phoneNumber: string;
	email: string;
	showDetails: boolean;
	numberOfSeats: number;
}

export interface EventDetails {
	id: string;
	agentId: string;
	agentAddress: Address;
	agentName: string;
	agentLogo: string;
	agentContact: {
		email: string;
		firstName: string;
		lastName: string;
		fullName: string;
		position: string;
		tel: string;
		contactDescription: string;
		contactImage: string;
		contactThumbnailImage: string;
	};
	title: string;
	slugTitle: string;
	shortDescription: string;
	description: string;
	startDate: string;
	endDate: string;
	registrationStartDate: string;
	registrationEndDate: string;
	partnerRegistrationStartDate: string;
	partnerRegistrationEndDate: string;
	participantLimit: number;
	hasPartner: boolean;
	showPartnersDetail: boolean;
	partnerNeedInvitation: boolean;
	partnerLimit: number;
	seatPerPartnerLimit: number;
	featuredPhoto: string;
	featuredVideo: string;
	eventType: EventType;
	eventStatus: number;
	participationType: number;
	createdAt: string;
	version: number;
	meetingLink: string;
	cancelReason: string;
	companyInvitation: {
		id: string;
		inviteDate: string;
		responseDate: string;
		status: number;
	};
	participationInfo: {
		id: string;
		fullName: string;
		companyName: string;
		companyLogo: string;
		phoneNumber: string;
		email: string;
		numberOfSeats: number;
		showDetails: boolean;
		status: EventParticipationStatus;
		createdAt: string;
		modifiedAt: string;
	};
	address: Address;
	isFinished: boolean;
	isStarted: boolean;
	registrationStatus: RegistrationStatus;
	partnerRegistrationStatus: RegistrationStatus;
}

export interface EventRegisterForm {
	companyName: string;
	email: string;
	phoneNumber: string;
	numberOfSeats: number;
	showDetails: boolean;
	invitationId: string;
}

export interface EventLimit {
	visitorCanRegister: boolean;
	partnerCanRegister: boolean;
	alreadyParticipated: boolean;
	seatPerPartnerLimit: number;
}
