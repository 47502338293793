import { memo } from 'react';
import { Col, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useInternshipRegistration } from 'Hooks';
import { InternshipRegistrationFile } from 'Types';
import { getCurrentLocale } from 'Utils';
import { format } from 'date-fns';
import {
	InternshipParticipantDecoration,
	InternshipParticipantFiles,
	InternshipParticipantInformation,
	Card
} from 'Elements';

export const ViewInternshipParticipant = memo(() => {
	const { t } = useTranslation();
	const { internshipParticipant_id } = useParams();

	const { data: request, isLoading } = useInternshipRegistration(
		internshipParticipant_id as string
	);

	const getLocalFormat = () => {
		const currentLocale = getCurrentLocale().code;
		if (currentLocale === 'de') {
			return 'dd.MM.yyyy';
		}
		return 'yyyy-MM-dd';
	};

	return (
		<Row>
			<Col xs={12} className="mb-4">
				<InternshipParticipantDecoration
					request={request}
					isLoading={isLoading}
				/>
			</Col>
			<Col sm={6} className="mb-4 mb-sm-0">
				<InternshipParticipantInformation
					request={request}
					isLoading={isLoading}
				/>
			</Col>
			<Col sm={6}>
				<InternshipParticipantFiles
					files={
						request?.applicant
							?.applicantFiles as InternshipRegistrationFile[]
					}
					isLoading={isLoading}
				/>
			</Col>
			<Col sm={12} className="mt-4">
				<Card className="h-100">
					<h6 className="text-gray-3 mb-3 fs-6 fw-400">
						{t('title.sessions')}
					</h6>
					<Row>
						<div className="d-flex align-items-center">
							<p className="ms-2">
								{request?.title}
							</p>
						</div>
						<div className="d-flex flex-wrap align-items-center gap-2">
							{request?.term?.sessions.map((item: any, index) => {
								const startDate: any = new Date(
									Date.parse(item?.sessionStartDate)
								);
								const endDate: any = new Date(
									Date.parse(item?.sessionEndDate)
								);
								return (
									<div
										key={index}
										className="d-flex align-items-center text-light-gray bg-gray-1 px-2 py-1 rounded-3">
										<div className="me-1">
											{format(
												new Date(
													item?.sessionStartDate
												),
												`${getLocalFormat()}`
											)}{' '}
										</div>
										<div className="d-flex align-items-center gap-1">
											<div>
												{startDate.toLocaleTimeString(
													[],
													{
														hour: '2-digit',
														minute: '2-digit',
														hour12: false
													}
												)}
											</div>
											<div>-</div>
											<div>
												{endDate.toLocaleTimeString(
													[],
													{
														hour: '2-digit',
														minute: '2-digit',
														hour12: false
													}
												)}
											</div>
										</div>
									</div>
								);
							})}
						</div>
					</Row>
				</Card>
			</Col>
		</Row>
	);
});

ViewInternshipParticipant.displayName = 'ViewInternshipParticipant';
