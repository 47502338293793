import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { fetch } from 'Utils';
import { queryKey } from 'Constants';
import { DataList, DataQueryParams, MyVoucher } from 'Types';

const config = (options = {}) => {
	return {
		...{ keepPreviousData: true },
		...options
	};
};

export const useVouchers = <T = DataList<MyVoucher>>(
	{ pageSize = 12, pageNumber = 1, keyword = '' }: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<MyVoucher>>
): UseQueryResult<DataList<MyVoucher>, unknown> => {
	return useQuery(
		{
			queryKey: [queryKey.vouchers, { pageSize, pageNumber, keyword }],
			queryFn: () => fetch.agentVouchers(pageSize, pageNumber, keyword),
			...config(options)
		}
	);
};

export const useVoucher = <T = MyVoucher>(
	voucherId: string,
	options?: UseQueryOptions<T, any, MyVoucher>
): UseQueryResult<MyVoucher, unknown> => {
	return useQuery(
		{
			queryKey: [queryKey.voucher, { voucherId }],
			queryFn: () => fetch.agentVoucherDetails(voucherId),
			...config(options)
		}
	);
};

export const useAvailableVouchers = <T = DataList<MyVoucher>>(
	options?: UseQueryOptions<T, any, DataList<MyVoucher>>
): UseQueryResult<DataList<MyVoucher>, unknown> => {
	return useQuery(
		{
			queryKey: [queryKey.availableVouchers],
			queryFn: () => fetch.availableVouchers(),
			...config(options)
		}
	);
};
