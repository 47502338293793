import { FC, memo } from 'react';
import styled from 'styled-components';
import { darken, rgba } from 'polished';
import { Col, Row } from 'reactstrap';
import { SwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { names } from 'Constants';
import { Colors, AgentSetting, ColorValues } from 'Types';
import { useAgentProfilePlan, useUpdateAgent } from 'Hooks';
import {
	Button,
	Carousel,
	SectionHeadline,
	VideoThumb,
	ShimmerImage,
	EditButton,
	ButtonProps,
	AgentFeatureCard,
	ProfileAddNewCard
} from 'Elements';
import styles from 'partials/homepage/section/agent-company-overview-section.module.scss';

const PartnerButton = styled((props: ButtonProps) => (
	<Button color={Colors.empty} {...props} />
))`
	${({ theme }) =>
		theme &&
		`
			background-color: #fff !important;
			color: #000 !important;
			&:hover {
				color: #000;
				background-color: ${darken(0.07, "#fff")} !important;
			}
		`}
`;

const StyledBox = styled.div<{ $bgColor: string }>`
	${({ $bgColor }) =>
		$bgColor &&
		`
		background-color: ${rgba($bgColor, 0.1)};
		border-bottom: 8px solid ${$bgColor};
	`}
`;

export const AgentCompanyOverviewPremium: FC = memo(() => {
	const { t } = useTranslation();
	const { setting, updateAgentSetting } = useUpdateAgent();
	const { data: agentPlanInfo } = useAgentProfilePlan();
	const navigate = useNavigate();
	const { planPrice } = names;
	const contactPerson = agentPlanInfo?.contactPerson;

	const breakpoints = {
		0: {
			slidesPerView: 1
		},
		768: {
			slidesPerView: 2
		},
		992: {
			slidesPerView: 3
		}
	};

	const handleUpdate = async (field: Partial<AgentSetting>) => {
		await updateAgentSetting(field);
	};

	const navigateTo = (url: string) => {
		navigate(url, {
			state: {
				prevPath: 'homepage'
			}
		});
	};

	return (
		<div className="py-5 pt-md-6 position-relative">
			<SectionHeadline
				title={
					setting?.companyHeadline ||
					`[${t('placeholder.company-name')}]`
				}
				description={
					setting?.companyOverviewDescription ||
					`[${t('placeholder.company-overview-description')}]`
				}
				className="mx-auto text-center"
				titleColor={ColorValues['gray-1']}
				textColor={ColorValues['gray-1']}
				onUpdateTitle={(val) => handleUpdate({ companyHeadline: val })}
				onUpdateDescription={(val) =>
					handleUpdate({ companyOverviewDescription: val })
				}
			/>
			{setting && !contactPerson && (
				<div className="d-flex align-items-center justify-content-center mt-4">
					<PartnerButton
						className="rounded-pill px-md-4 fs-base"
						label={t('button.become-partner') as string}
					/>
				</div>
			)}
			{setting && !!contactPerson && (
				<Row
					className="px-lg-6 py-4 g-0 position-relative cursor-pointer"
					onClick={() => navigateTo(planPrice.contactPerson.path)}>
					<div className="edit-overlay z-index-1">
						<EditButton className="edit-overlay-icon" />
					</div>
					<Col md={4}>
						<StyledBox
							$bgColor={setting.headerBgColor}
							className={styles.contactImageWrapper}>
							<ShimmerImage
								src={contactPerson?.avatarUrl}
								defaultImage="/assets/img/profiles/img-user-default.png"
								alt={contactPerson?.fullName}
								width={288}
								height={288}
								className={styles.contactImage}
							/>
						</StyledBox>
						<h6 className="h3 text-gray-1 text-center lh-sm">
							{agentPlanInfo?.contactPerson?.fullName}
						</h6>
					</Col>
					<Col md={8} className="align-self-center">
						<div className="d-flex flex-column align-items-center align-items-md-start gap-4">
							{agentPlanInfo?.contactPerson?.description && (
								<p className="text text-gray-1 text-center text-md-start mb-0">
									{agentPlanInfo?.contactPerson.description}
								</p>
							)}
							<div className="d-flex align-items-center">
								<PartnerButton
									className="rounded-pill px-md-4 fs-base"
									label={t('button.become-partner') as string}
								/>
							</div>
						</div>
					</Col>
				</Row>
			)}
			{setting &&
				agentPlanInfo?.features &&
				agentPlanInfo?.features?.length > 0 && (
					<Carousel
						centeredSlidesBounds
						navigationGroup
						darkBackground
						spaceBetween={16}
						className="my-5"
						breakpoints={breakpoints}>
						<SwiperSlide className="h-auto">
							<ProfileAddNewCard
								size="sm"
								title="title.plan-price.add-new-feature"
								navigationUrl={planPrice.feature.path}
							/>
						</SwiperSlide>
						{agentPlanInfo.features
							?.reverse()
							?.map((feature, index) => {
								return (
									<SwiperSlide key={index} className="h-auto">
										<AgentFeatureCard
											feature={feature}
											color={setting.headerBgColor}
										/>
									</SwiperSlide>
								);
							})}
					</Carousel>
				)}
			{setting &&
				agentPlanInfo?.features &&
				agentPlanInfo?.features?.length === 0 && (
					<ProfileAddNewCard
						size="sm"
						title="title.plan-price.add-new-feature"
						navigationUrl={planPrice.feature.path}
					/>
				)}
			{agentPlanInfo?.videoUrl && (
				<Row className="justify-content-center py-4 g-0">
					<Col
						md={8}
						lg={6}
						className="position-relative cursor-pointer"
						onClick={() => navigateTo(planPrice.info.path)}>
						<VideoThumb
							imageUrl={agentPlanInfo?.coverPhotoUrl}
							videoUrl={agentPlanInfo.videoUrl}
						/>
						<div className="edit-overlay z-index-1">
							<EditButton className="edit-overlay-icon" />
						</div>
					</Col>
				</Row>
			)}
		</div>
	);
});
AgentCompanyOverviewPremium.displayName = 'AgentCompanyOverviewPremium';
