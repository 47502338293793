import { memo, FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { clx } from 'Utils';
import { Colors } from 'Types';
import { useDelayUnmount, useEvent, useModal } from 'Hooks';
import { Button, Card, ModalBoxCancelEvent } from 'Elements';
import styles from 'partials/page/form.module.scss';

export const CancelEvent: FC = memo(() => {
	const { t } = useTranslation();
	const { event_id } = useParams();
	const [canceling, setCanceling] = useState(false);
	const { data: event, cancelEvent } = useEvent(event_id as string);
	const [openModal, toggleModal] = useModal();
	const shouldRenderModal = useDelayUnmount(openModal, 350);

	const onCancel = async () => {
		try {
			setCanceling(true);
			await cancelEvent(() => toggleModal());
		} finally {
			setCanceling(false);
		}
	};

	return (
		<Card
			className={clx(
				styles.danger,
				'd-flex align-items-center flex-wrap gap-3 mb-3'
			)}>
			<Button
				color={Colors.red}
				label={t('button.cancel-event') as string}
				onClick={toggleModal}
			/>
			<p className="mb-0">{t('text.event.cancel')}</p>
			{shouldRenderModal && (
				<ModalBoxCancelEvent
					title={event?.title ?? ''}
					isOpen={openModal}
					isCanceling={canceling}
					onClose={toggleModal}
					onCancel={onCancel}
				/>
			)}
		</Card>
	);
});

CancelEvent.displayName = 'CancelEvent';
