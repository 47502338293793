import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Error } from 'Types';
import {
	config,
	ErrorNotification,
	getAuthHeader,
	logoutRedirect
} from 'Utils';

export const cancelTokenSource = axios.CancelToken.source();

const axios_config: AxiosRequestConfig = {
	baseURL: config.baseUrl,
	cancelToken: cancelTokenSource.token
};

const instance = axios.create(axios_config);

instance.interceptors.request.use(
	async (req_config: AxiosRequestConfig | any) => {
		const authHeader = await getAuthHeader();
		if (authHeader) {
			req_config.headers = authHeader;
		} else {
			await logoutRedirect();
		}
		return req_config;
	},
	(error: AxiosError<Error>) => {
		ErrorNotification(error);
		return Promise.reject(error);
	}
);

instance.interceptors.response.use(
	(res: AxiosResponse) => {
		return res;
	},
	async (error: AxiosError<Error>) => {
		const originalConfig = error?.config as any;
		const status = error?.response?.status;
		if (status === 401) {
			try {
				const authHeader = await getAuthHeader();
				if (authHeader && originalConfig) {
					originalConfig.headers = authHeader;
				} else {
					await logoutRedirect();
				}
				return originalConfig;
			} catch (_error) {
				ErrorNotification(error);
				return Promise.reject(_error);
			}
		}
		if (status !== 500 || process.env.NODE_ENV === 'development') {
			ErrorNotification(error);
		}
		return Promise.reject(error);
	}
);

export default instance;
