import { memo, FC, SVGProps, useMemo, useState } from 'react';
import { SingleValue } from 'react-select';
import { useTranslation } from 'react-i18next';
import {
	AreaChart,
	Area,
	XAxis,
	YAxis,
	Tooltip,
	ResponsiveContainer,
	TooltipProps
} from 'recharts';
import {
	ValueType,
	NameType
} from 'recharts/types/component/DefaultTooltipContent';
import { AreaDot } from 'recharts/types/cartesian/Area';
import { useJobStatistics } from 'Hooks';
import { formatLocaleDateTime, intlDateFormat } from 'Utils';
import {
	Card,
	DashboardJobTotal,
	DurationDropDown,
	LoadingSpinner
} from 'Elements';
import {
	ColorValues,
	JobStatistic,
	JobStatisticReportRange,
	SelectOption
} from 'Types';

interface AggregatedData extends JobStatistic {
	axisLabel: string;
	publishedSum?: number;
	draftSum?: number;
	unpublishedSum?: number;
	expiredSum?: number;
}

export const DashboardJobStatistic: FC = memo(() => {
	const { t } = useTranslation();
	const [selectionType, setSelectionType] = useState<SelectOption>({
		value: JobStatisticReportRange.Monthly,
		label: t('label.time.1-month')
	});

	const { data, isLoading } = useJobStatistics(
		selectionType.value as JobStatisticReportRange
	);

	const selectOption: SelectOption[] = [
		{
			value: JobStatisticReportRange.Monthly,
			label: t('label.time.1-month')
		},
		{
			value: JobStatisticReportRange.SixMonth,
			label: t('label.time.6-month')
		},
		{
			value: JobStatisticReportRange.Yearly,
			label: t('label.time.1-year')
		}
	];
	const axisLineStyle: SVGProps<SVGLineElement> = {
		stroke: ColorValues['gray-2'],
		strokeWidth: 1
	};

	const tickStyle: SVGProps<SVGTextElement> = {
		stroke: ColorValues['gray-2'],
		strokeWidth: 1
	};

	const dotStyle = (color: ColorValues): AreaDot => {
		return {
			stroke: color,
			strokeWidth: 1,
			fill: '#fff'
		};
	};

	const activeDotStyle = (color: ColorValues): AreaDot => {
		return {
			stroke: color,
			fill: color
		};
	};

	const sortedData = useMemo(() => {
		return [...(data ?? [])].sort(
			(a, b) =>
				new Date(a.createdAt).getTime() -
				new Date(b.createdAt).getTime()
		);
	}, [data]);

	const aggregateMonthlyData: AggregatedData[] = useMemo(() => {
		return sortedData.map((item) => {
			return {
				...item,
				axisLabel: intlDateFormat(item.createdAt, {
					day: 'numeric',
					month: 'short'
				}),
				publishedSum: item.published,
				unpublishedSum: item.unpublished,
				draftSum: item.draft,
				expiredSum: item.expired
			};
		});
	}, [sortedData]);

	const handleSelection = (selected: SingleValue<SelectOption>) => {
		if (selected) {
			setSelectionType(selected);
		}
	};

	const tooltipContent = ({
		active,
		payload
	}: TooltipProps<ValueType, NameType>) => {
		if (active && payload && payload.length) {
			return (
				<Card className="py-2 px-3 d-flex flex-column gap-2">
					<span>
						{formatLocaleDateTime(
							payload[0].payload.createdAt,
							false
						)}
					</span>
					<div className="flex-grow-1">
						<div className="text-tertiary lh-base lh-base d-flex align-items-center gap-2">
							<span className="fs-small">
								{t('label.job.published')}:
							</span>
							<span className="fw-500">{payload[0].value}</span>
						</div>
						<div className="text-secondary lh-base lh-base d-flex align-items-center gap-2">
							<span className="fs-small">
								{t('label.job.draft')}:
							</span>
							<span className="fw-500">{payload[1].value}</span>
						</div>
					</div>
				</Card>
			);
		}

		return null;
	};

	return (
		<Card>
			<DashboardJobTotal />
			<div className="mt-4">
				<div className="d-flex align-items-center gap-2 mb-3">
					<h3 className="fs-base flex-grow-1 mb-0">
						{t('title.job.job-data-overview')}
					</h3>
					<DurationDropDown
						durations={selectOption}
						selectedDuration={selectionType}
						onChange={handleSelection}
					/>
				</div>
				{isLoading && <LoadingSpinner />}
				{!isLoading && (
					<ResponsiveContainer width="100%" height={200}>
						<AreaChart
							data={aggregateMonthlyData}
							margin={{
								top: 16,
								left: -40,
								right: 16
							}}>
							<defs>
								<linearGradient
									id="published"
									x1="277.5"
									y1="0"
									x2="277.5"
									y2="70%"
									gradientUnits="userSpaceOnUse">
									<stop stopColor="rgba(116, 219, 247, 0.5)" />
									<stop
										offset="1"
										stopColor={ColorValues.tertiary}
										stopOpacity="0"
									/>
								</linearGradient>
								<linearGradient
									id="draft"
									x1="277.5"
									y1="0"
									x2="277.5"
									y2="70%"
									gradientUnits="userSpaceOnUse">
									<stop stopColor="rgba(255, 140, 0, 0.5)" />
									<stop
										offset="1"
										stopColor={ColorValues.secondary}
										stopOpacity="0"
									/>
								</linearGradient>
							</defs>
							<XAxis
								dataKey="axisLabel"
								angle={-30}
								height={40}
								tickMargin={10}
								tickCount={30}
								axisLine={axisLineStyle}
								tick={{
									fill: ColorValues['gray-3']
								}}
							/>
							<YAxis
								type="category"
								axisLine={axisLineStyle}
								tickLine={tickStyle}
							/>
							<Tooltip content={tooltipContent} />
							<Area
								type="monotone"
								dataKey="publishedSum"
								stroke={ColorValues['tertiary-light']}
								dot={dotStyle(ColorValues.tertiary)}
								activeDot={activeDotStyle(ColorValues.tertiary)}
								fillOpacity={1}
								fill="url(#published)"
							/>
							<Area
								type="monotone"
								dataKey="draftSum"
								stroke={ColorValues['secondary-light']}
								dot={dotStyle(ColorValues.secondary)}
								activeDot={activeDotStyle(
									ColorValues.secondary
								)}
								fillOpacity={1}
								fill="url(#draft)"
							/>
						</AreaChart>
					</ResponsiveContainer>
				)}
			</div>
		</Card>
	);
});

DashboardJobStatistic.displayName = 'DashboardJobStatistic';
