import styled from 'styled-components';

interface Style {
	imageUrl: string;
}

export const Background = styled.div<Style>`
	${({ imageUrl }) =>
		imageUrl &&
		` 
		background-image: url(${imageUrl});
		background-size: cover;
	`};
`;
