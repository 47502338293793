import { memo, FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { clx } from 'Utils';
import { Button, FontAwesome } from 'Elements';

interface Props {
	textToCopy: string;
	label?: string;
	className?: string;
	onCopy?: () => void;
}

export const CopyButton: FC<Props> = memo(
	({ textToCopy, label = 'button.copy', className, onCopy }) => {
		const { t } = useTranslation();
		const [copied, setCopy] = useState(false);

		const handleCopy = () => {
			setCopy(true);
			onCopy?.();
		};

		useEffect(() => {
			const timeoutId = setTimeout(() => {
				setCopy(false);
			}, 1500);

			return () => clearTimeout(timeoutId);
		}, [copied]);

		return (
			<CopyToClipboard text={textToCopy} onCopy={handleCopy}>
				<Button className={clx(className)}>
					<FontAwesome
						icon={!copied ? 'copy' : 'check'}
						size="lg"
						className="flex-shrink-0 me-2"
					/>
					{!copied ? t(label) : t('button.copied')}
				</Button>
			</CopyToClipboard>
		);
	}
);

CopyButton.displayName = 'CopyButton';
