import  { FC, memo } from 'react';
import { ColorValues } from 'Types';

interface Props {
	fill?: string;
	className?: string;
	size?: number;
}

export const IconEmployee: FC<Props> = memo(
	({
		fill = ColorValues.primary,
		className,
		size,
		...props
	}): JSX.Element => {
		return (
			<svg
				width={size}
				height={size}
				viewBox="0 0 24 24"
				xmlns="http://www.w3.org/2000/svg"
				className={className}
				{...props}>
				<path
					d="M4.919 0a4.914 4.914 0 0 0-.124 9.827.835.835 0 0 1 .228 0h.072A4.915 4.915 0 0 0 4.919 0z"
					transform="translate(4.151 2.071)"
					fill={fill}
					fillOpacity={0.25}
				/>
				<path
					data-name="Vector"
					d="M12.551 1.445a10.282 10.282 0 0 0-10.511 0A4.087 4.087 0 0 0 0 4.789a4.053 4.053 0 0 0 2.03 3.324 9.568 9.568 0 0 0 5.26 1.46 9.568 9.568 0 0 0 5.26-1.46 4.086 4.086 0 0 0 2.03-3.345 4.077 4.077 0 0 0-2.029-3.323z"
					transform="translate(2.03 12.356)"
					fill={fill}
				/>
				<path
					data-name="Vector"
					d="M6.089 3.4a3.706 3.706 0 0 1-3.241 4.008H2.8a.484.484 0 0 0-.176.021A3.715 3.715 0 0 1 0 6.569a4.739 4.739 0 0 0 1.553-3.935A4.806 4.806 0 0 0 .756.376 3.718 3.718 0 0 1 6.089 3.4z"
					transform="translate(13.947 3.995)"
					fill={fill}
					fillOpacity={0.25}
				/>
				<path
					data-name="Vector"
					d="M8.284 4.236A3.154 3.154 0 0 1 6.482 6.7a7.309 7.309 0 0 1-3.635.808 3.7 3.7 0 0 0 1.263-2.4 4.08 4.08 0 0 0-1.729-3.5A8.548 8.548 0 0 0 0 .3a8.093 8.093 0 0 1 6.938 1.212 3.146 3.146 0 0 1 1.346 2.724z"
					transform="translate(13.634 12.307)"
					fill={fill}
				/>
			</svg>
		);
	}
);

IconEmployee.displayName = 'IconEmployee';
