import { lazy } from 'react';

export const ErrorPage = lazy(() => import('../components/pages/ErrorPage'));
export const Dashboard = lazy(() => import('../components/pages/Dashboard'));

// Company Pages
export const MyCompany = lazy(
	() => import('../components/pages/company/MyCompany')
);
export const MyCompanyProfilePage = lazy(
	() => import('../components/pages/company/MyCompanyProfilePage')
);

// Agent Pages
export const MyAgent = lazy(() => import('../components/pages/agent/MyAgent'));
export const MyAgentProfilePage = lazy(
	() => import('../components/pages/agent/MyAgentProfilePage')
);
export const PartnerList = lazy(
	() => import('../components/pages/agent/partners/PartnerList')
);

// Employee Pages
export const EmployeeCreate = lazy(
	() => import('../components/pages/company/employee/EmployeeCreate')
);
export const EmployeeList = lazy(
	() => import('../components/pages/company/employee/EmployeeList')
);
export const EmployeeUpdate = lazy(
	() => import('../components/pages/company/employee/EmployeeUpdate')
);

// Agent Member Pages
export const MemberCreate = lazy(
	() => import('../components/pages/agent/member/MemberCreate')
);
export const MemberList = lazy(
	() => import('../components/pages/agent/member/MemberList')
);
export const MemberUpdate = lazy(
	() => import('../components/pages/agent/member/MemberUpdate')
);

// Agent Department Pages
export const DepartmentCreate = lazy(
	() => import('../components/pages/agent/department/DepartmentCreate')
);
export const DepartmentList = lazy(
	() => import('../components/pages/agent/department/DepartmentList')
);
export const DepartmentUpdate = lazy(
	() => import('../components/pages/agent/department/DepartmentUpdate')
);

// Job Pages
export const JobList = lazy(
	() => import('../components/pages/jobs/job/JobList')
);
export const JobCreate = lazy(
	() => import('../components/pages/jobs/job/JobCreate')
);
export const JobUpdate = lazy(
	() => import('../components/pages/jobs/job/JobUpdate')
);
export const JobPreviewPage = lazy(
	() => import('../components/pages/jobs/job/JobPreviewPage')
);

// Internship Pages
export const InternshipList = lazy(
	() => import('../components/pages/jobs/internship/InternshipList')
);
export const InternshipCreate = lazy(
	() => import('../components/pages/jobs/internship/InternshipCreate')
);
export const InternshipUpdate = lazy(
	() => import('../components/pages/jobs/internship/InternshipUpdate')
);
export const InternshipPreviewPage = lazy(
	() => import('../components/pages/jobs/internship/InternshipPreviewPage')
);

// Internship Management Pages
export const InternshipManagementList = lazy(
	() =>
		import(
			'../components/pages/jobs/internship-management/InternshipManagementList'
		)
);
// export const JobPreviewPage = lazy(
// 	() => import('../components/pages/jobs/job/JobPreviewPage')
// );

// Requests Pages
export const RequestList = lazy(
	() => import('../components/pages/jobs/requests/RequestList')
);
export const RequestDetails = lazy(
	() => import('../components/pages/jobs/requests/RequestDetails')
);

// Internship Participants Pages
export const InternshipParticipantsList = lazy(
	() =>
		import(
			'../components/pages/jobs/internship-participants/InternshipParticipantsList'
		)
);
export const InternshipParticipantsDetails = lazy(
	() =>
		import(
			'../components/pages/jobs/internship-participants/InternshipParticipantsDetails'
		)
);

// User Pages
export const UserCreate = lazy(
	() => import('../components/pages/users/profile/UserCreate')
);
export const UserList = lazy(
	() => import('../components/pages/users/profile/UserList')
);
export const UserUpdate = lazy(
	() => import('../components/pages/users/profile/UserUpdate')
);
export const UserRoleCreate = lazy(
	() => import('../components/pages/users/role/UserRoleCreate')
);
export const UserRoleList = lazy(
	() => import('../components/pages/users/role/UserRoleList')
);
export const UserRoleUpdate = lazy(
	() => import('../components/pages/users/role/UserRoleUpdate')
);
export const UserGroupCreate = lazy(
	() => import('../components/pages/users/group/UserGroupCreate')
);
export const UserGroupList = lazy(
	() => import('../components/pages/users/group/UserGroupList')
);
export const UserGroupUpdate = lazy(
	() => import('../components/pages/users/group/UserGroupUpdate')
);

// Subscription Pages
export const SubscriptionCreate = lazy(
	() => import('../components/pages/company/subscription/SubscriptionCreate')
);
export const SubscriptionList = lazy(
	() => import('../components/pages/company/subscription/SubscriptionList')
);
export const SubscriptionDetails = lazy(
	() => import('../components/pages/company/subscription/SubscriptionDetails')
);
export const SubscriptionCheckout = lazy(
	() =>
		import('../components/pages/company/subscription/SubscriptionCheckout')
);
export const SubscriptionPaymentResult = lazy(
	() =>
		import(
			'../components/pages/company/subscription/SubscriptionPaymentResult'
		)
);
export const SubscriptionPaymentInvoice = lazy(
	() =>
		import(
			'../components/pages/company/subscription/SubscriptionPaymentInvoice'
		)
);

// Settlements Pages
export const TransactionList = lazy(
	() => import('../components/pages/settlements/transactions/TransactionList')
);
export const TransactionDetails = lazy(
	() =>
		import(
			'../components/pages/settlements/transactions/TransactionDetails'
		)
);
export const InvoiceList = lazy(
	() => import('../components/pages/settlements/invoice/InvoiceList')
);
export const InvoiceDetails = lazy(
	() => import('../components/pages/settlements/invoice/InvoiceDetails')
);
export const CreditList = lazy(
	() => import('../components/pages/settlements/credit/CreditList')
);
export const CreditDetails = lazy(
	() => import('../components/pages/settlements/credit/CreditDetails')
);
export const PaymentList = lazy(
	() => import('../components/pages/settlements/payment/PaymentList')
);
export const PaymentDetails = lazy(
	() => import('../components/pages/settlements/payment/PaymentDetails')
);

// Voucher Pages
export const VoucherList = lazy(
	() => import('../components/pages/agent/voucher/VoucherList')
);
export const VoucherDetails = lazy(
	() => import('../components/pages/agent/voucher/VoucherDetails')
);

// Invitation Pages
export const InvitationList = lazy(
	() => import('../components/pages/agent/invitation/InvitationList')
);
export const InvitationCreate = lazy(
	() => import('../components/pages/agent/invitation/InvitationCreate')
);
export const InvitationUpdate = lazy(
	() => import('../components/pages/agent/invitation/InvitationUpdate')
);

// Event Pages
export const EventList = lazy(
	() => import('../components/pages/event/EventList')
);
export const EventDetails = lazy(
	() => import('../components/pages/event/EventDetails')
);
export const EventCreate = lazy(
	() => import('../components/pages/agent/event/EventCreate')
);
export const EventUpdate = lazy(
	() => import('../components/pages/agent/event/EventUpdate')
);
export const MyEvents = lazy(
	() => import('../components/pages/company/event/MyEvents')
);
export const MyEventDetails = lazy(
	() => import('../components/pages/company/event/MyEventDetails')
);

// Marketing Pages
export const ToolboxWelcome = lazy(
	() => import('../components/pages/marketing/toolbox/ToolboxWelcome')
);
export const Integration = lazy(
	() => import('../components/pages/marketing/integration/Integration')
);

// Integration App Pages
export const IntegrationApps = lazy(
	() => import('../components/pages/company/integration/IntegrationUpdate')
);

// Agent Plan Price Pages
export const PlanPriceManagement = lazy(
	() => import('../components/pages/agent/plan-price/PlanPriceManagement')
);

// Contact Groups Pages
export const ListContactGroups = lazy(
	() => import('../components/pages/agent/contactGroup/ListContactGroups')
);
export const ContactGroupCreate = lazy(
	() => import('../components/pages/agent/contactGroup/ContactGroupCreate')
);
export const ContactGroupUpdate = lazy(
	() => import('../components/pages/agent/contactGroup/ContactGroupUpdate')
);

// Extern Partners Pages
export const ListExternPartners = lazy(
	() =>
		import(
			'../components/pages/agent/partners/extern-partner/ListExternPartners'
		)
);
export const ExternPartnerCreate = lazy(
	() =>
		import(
			'../components/pages/agent/partners/extern-partner/ExternPartnerCreate'
		)
);
export const ExternPartnerUpdate = lazy(
	() =>
		import(
			'../components/pages/agent/partners/extern-partner/ExternPartnerUpdate'
		)
);

// Abo Calculator
export const AboCalculator = lazy(
	() => import('../components/pages/agent/abo-calculator/AboCalculator')
);
