import { memo, useEffect, useState } from 'react';
import { addDays } from 'date-fns';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAxios, useSelector } from 'Hooks';
import { Card, PaymentMethodCard, ShimmerImage, SubmitButton } from 'Elements';
import {
	PaymentMethod,
	PaymentMethodCard as IPaymentMethodCard,
	VoucherDiscountType,
	Colors
} from 'Types';
import {
	api,
	clx,
	currencyFormatter,
	formatLocaleDateTime,
	getDiscountFormat,
	paymentMethods,
	subtractDays
} from 'Utils';
import styles from 'partials/payment/payment.module.scss';

export const CheckoutSubscriptionSummary = memo(() => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const {
		sendRequest: sendSubscriptionRequest,
		isLoading: isSubmittingSubscription
	} = useAxios<string>();
	const { sendRequest: sendPaymentRequest, isLoading: isSubmittingPayment } =
		useAxios<string>();
	const [selectedMethod, setSelectedMethod] =
		useState<IPaymentMethodCard | null>(null);

	const { selectedAgent, selectedPlan, selectedVoucher } = useSelector(
		(state) => state.subscription
	);
	const taxPercent =
		selectedVoucher?.taxPercent ?? selectedPlan?.taxPercent ?? 0;
	const taxAmount =
		selectedVoucher?.taxAmount ?? selectedPlan?.taxAmount ?? 0;
	const discount = selectedVoucher?.discount;
	const discountAmount = selectedVoucher?.discountAmount ?? 0;
	const discountType = selectedVoucher?.discountType;
	const totalAmount =
		selectedVoucher?.totalAmount ?? selectedPlan?.total ?? 0;
	const defaultImage = '/assets/img/company-default.jpg';

	const handleSelectMethod = (method: IPaymentMethodCard) => {
		setSelectedMethod(method);
	};

	useEffect(() => {
		if (!selectedAgent?.companyApprovalStatus) {
			handleSelectMethod({
				id: 2,
				title: 'button.payment.pay-create-account',
				icon: '',
				description: ''
			});
		}
	}, []);

	const handlePaymentResponse = (data?: string) => {
		if (data) {
			window.location.replace(data);
		}
	};

	const createPayment = async (subscriptionId: string) => {
		await sendPaymentRequest(
			api.createPayment,
			{
				data: {
					// token: 'token',
					// method: 'mollie'
					returnUrl:
						'https://pj-dev-b2bdashboard-ui.azurewebsites.net/payment',
					companySubscriptionId: subscriptionId,
					paymentMethod: selectedMethod?.id
				}
			},
			handlePaymentResponse
		);
	};

	const handleSubscriptionResponse = async (data?: string) => {
		if (data) {
			switch (selectedMethod?.id) {
				case PaymentMethod.online:
					await createPayment(data);
					break;
				case PaymentMethod.payPal:
					await createPayment(data);
					break;
				case PaymentMethod.sepa:
					await createPayment(data);
					break;
				case PaymentMethod.invoice:
					navigate('/payment-invoice', { replace: true });
					break;
				case PaymentMethod.pending:
					navigate('/payment-invoice', { replace: true });
					break;
				default:
					break;
			}
		}
	};

	const registerCompanySubscription = async () => {
		const api_data = {
			agentId: selectedAgent?.agentId ?? '',
			subscriptionPlanId: selectedPlan?.id ?? '',
			voucherCode: selectedVoucher?.voucherCode ?? '',
			paymentMethod: selectedMethod?.id?.toString() ?? '0'
		};
		await sendSubscriptionRequest(
			api.createSubscription(
				api_data.agentId,
				api_data.subscriptionPlanId,
				api_data.voucherCode,
				api_data.paymentMethod
			),
			{ method: 'POST' },
			handleSubscriptionResponse
		);
	};

	const handleCheckout = async () => {
		await registerCompanySubscription();
	};

	const terms = t('text.payment.terms', {
		renewalDate: selectedPlan?.remainDuration
			? formatLocaleDateTime(
					subtractDays(
						addDays(new Date(), selectedPlan.remainDuration),
						90
					),
					false
			  )
			: ''
	});

	useEffect(() => {
		if (!selectedAgent || !selectedPlan) {
			void navigate('/');
		}
	}, [selectedAgent, selectedPlan, navigate]);

	return (
		<Card className="p-0 overflow-hidden">
			<Row className="g-0">
				<Col md={6}>
					<div
						className={clx(styles.box, styles.border_box, 'h-100')}>
						<div className="d-flex align-items-center gap-3 mb-4">
							<div className={styles.logoWrapper}>
								<ShimmerImage
									width={45}
									height={45}
									src={
										selectedAgent?.agentSetting
											.thumbnailLogo || defaultImage
									}
									alt={selectedAgent?.name ?? ''}
									defaultImage={defaultImage}
									className={styles.logo}
								/>
							</div>
							<div className="flex-grow-1 d-flex flex-column justify-content-center text-truncate">
								<h4 className="fs-base lh-base mb-0">
									{selectedAgent?.name}
								</h4>
								{selectedAgent?.agentSetting?.slogan && (
									<span className="text-gray-3 fs-small lh-md text-truncate">
										{selectedAgent.agentSetting.slogan}
									</span>
								)}
							</div>
						</div>
						<div className="fs-small mb-2 text-gray-3">
							{t('title.plan-info')}
						</div>
						<div className={styles.row}>
							<div className={styles.label}>
								{t('title.plan-name')}
							</div>
							<div className={styles.value}>
								{selectedPlan?.title}
							</div>
						</div>
						<div className={styles.row}>
							<div className={styles.label}>
								{t('title.duration')}
							</div>
							<div className={styles.value}>
								{selectedPlan?.duration} {t('label.days')}
							</div>
						</div>
						<div className={styles.row}>
							<div className={styles.label}>
								{t('title.plan-price.key')}
							</div>
							<div className={styles.value}>
								{currencyFormatter(selectedPlan?.price ?? 0)}
							</div>
						</div>
						<div className="fs-small mt-4 text-gray-3">
							{t('title.invoice-info')}
						</div>
						<div className="mb-lg-3">
							<div className={styles.row}>
								<div className={styles.label}>
									{t('label.original-price')}
								</div>
								<div
									className={clx(
										styles.value,
										'd-flex flex-column text-end'
									)}>
									<span className="lh-base">
										{currencyFormatter(
											selectedPlan?.originalPrice ?? 0
										)}
									</span>
									<span className="text-gray-3 fw-400 fs-small">
										{`${t('title.per')} ${
											selectedPlan?.duration
										} ${t('title.days')}`}
									</span>
								</div>
							</div>
							<div className={styles.row}>
								<div className={styles.label}>
									{t('label.discount')}
									{discountType ===
										VoucherDiscountType.Percent && (
										<span className="text-gray-3 fs-small">
											(
											{getDiscountFormat(
												discount,
												discountType
											)}
											)
										</span>
									)}
								</div>
								<div className={styles.value}>
									{currencyFormatter(
										discountAmount && discountAmount > 0
											? -discountAmount
											: 0
									)}
								</div>
							</div>
							<div className={styles.row}>
								<div className={styles.label}>
									{t('label.subtotal')}
								</div>
								<div
									className={clx(
										styles.value,
										'd-flex flex-column text-end'
									)}>
									<span className="lh-base">
										{currencyFormatter(
											selectedVoucher?.priceWithDiscount ??
												selectedPlan?.originalPrice ??
												0
										)}
									</span>
									<span className="text-gray-3 fw-400 fs-small">
										{`${t('title.per')} ${
											selectedPlan?.duration
										} ${t('title.days')}`}
									</span>
								</div>
							</div>
							<div className={styles.row}>
								<div className={styles.label}>
									{t('label.price')}
								</div>
								<div
									className={clx(
										styles.value,
										'd-flex flex-column text-end'
									)}>
									<span className="lh-base">
										{currencyFormatter(
											selectedVoucher?.priceAfterDiscount ??
												selectedPlan?.price ??
												0
										)}
									</span>
									<span className="text-primary-dark fw-400 fs-small">
										{`${t('title.per')} ${
											selectedPlan?.remainDuration
										} ${t('title.days')}`}
									</span>
								</div>
							</div>
							<div
								className={clx(
									styles.row,
									'border-0 border-bottom border-gray-2'
								)}>
								<div className="d-flex align-items-center">
									<div className={styles.label}>
										{t('label.tax')}
										<span className="text-gray-3 fs-small">
											({taxPercent}%)
										</span>
									</div>
								</div>
								<div className={styles.value}>
									{taxAmount > 0
										? `+${currencyFormatter(taxAmount)}`
										: currencyFormatter(0)}
								</div>
							</div>
							<div className={styles.row}>
								<h5
									className={clx(
										styles.label,
										'h5 text-gray-5 mb-0'
									)}>
									{t('title.total')}:
								</h5>
								<div className={styles.value}>
									<div className="d-flex flex-column">
										<span className="fs-5 fw-700 text-gray-5 lh-base text-end">
											{currencyFormatter(totalAmount)}
										</span>
										{totalAmount !== 0 && (
											<span className="text-gray-3 lh-1 fw-400 fs-small text-end">
												{t('label.tax-included')}
											</span>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</Col>
				<Col md={6}>
					{selectedAgent?.companyApprovalStatus ? (
						<div className={clx(styles.box, 'h-100')}>
							<h6 className="fs-base mb-4 lh-base">
								{t('title.payment.choose-payment-below')}:
							</h6>
							<div className="d-flex gap-3 mb-4 flex-wrap">
								{paymentMethods.map((method) => (
									<PaymentMethodCard
										key={method.id}
										title={method.title}
										icon={method.icon}
										description={method.description}
										isSelected={
											selectedMethod?.id === method.id
										}
										isDisabled={
											isSubmittingSubscription ||
											isSubmittingPayment
										}
										onSelect={() =>
											handleSelectMethod(method)
										}
									/>
								))}
							</div>
							<div className="text-center">
								<p className="text-gray-3 fs-small lh-base mt-2">
									{terms}
								</p>
								<SubmitButton
									icon={false}
									label={
										selectedMethod?.title ??
										'button.choose-method'
									}
									savingLabel="button.subscribing"
									className={styles.submit}
									isDisable={!selectedMethod}
									isSubmitting={
										isSubmittingSubscription ||
										isSubmittingPayment
									}
									onClick={handleCheckout}
								/>
							</div>
						</div>
					) : (
						<div className="mx-auto my-8" style={{ width: '80%' }}>
							<div
								className={`${styles.boxCompanyApprovalStatus}`}>
								<div className="d-flex align-items-center gap-3 mb-4">
									<div className={styles.logoWrapper}>
										<ShimmerImage
											width={45}
											height={45}
											src={
												selectedAgent?.agentSetting
													.thumbnailLogo ||
												defaultImage
											}
											alt={selectedAgent?.name ?? ''}
											defaultImage={defaultImage}
											className={styles.logo}
										/>
									</div>
									<div className="flex-grow-1 d-flex flex-column justify-content-center text-truncate">
										<h4 className="fs-base lh-base mb-0">
											{selectedAgent?.name}
										</h4>
										{selectedAgent?.agentSetting
											?.slogan && (
											<span className="text-gray-3 fs-small lh-md text-truncate">
												{
													selectedAgent.agentSetting
														.slogan
												}
											</span>
										)}
									</div>
								</div>
								<div>
									{t(
										'title.payment.company-no-approval-label1'
									)}
								</div>
							</div>
							<img
								src="/assets/img/hourglass.svg"
								alt=""
								width="152"
								height="153"
							/>
							<div className="mt-5 mb-5 text-center">
								{t('title.payment.company-no-approval-label2')}
							</div>
							<SubmitButton
								icon={false}
								size="md"
								color={Colors['secondary']}
								label={
									selectedMethod?.title ??
									'button.payment.pay-create-account'
								}
								savingLabel=""
								className="w-100 mb-5"
								isSubmitting={
									isSubmittingSubscription ||
									isSubmittingPayment
								}
								onClick={handleCheckout}
							/>
						</div>
					)}
				</Col>
			</Row>
		</Card>
	);
});

CheckoutSubscriptionSummary.displayName = 'CheckoutSubscriptionSummary';
