import { memo, FC } from 'react';
import { clx } from 'Utils';
import { LoadingMask } from 'Elements';
import styles from 'partials/job/preview/job-organization-section.module.scss';

export const InternshipOrganizationSectionLoading: FC = memo(() => {
	return (
		<div className="d-flex flex-column gap-3 pb-3 position-relative">
			<div className="d-flex gap-3">
				<LoadingMask className={clx(styles.logoWrapper, 'd-flex')} />
				<div className="flex-grow-1 d-flex flex-column justify-content-center gap-2">
					<LoadingMask className={clx('w-40', styles.loading)} />
					<LoadingMask className={clx('w-30', styles.loading)} />
				</div>
			</div>
			<div className="d-flex flex-column gap-1">
				<LoadingMask className={clx('w-100', styles.loading)} />
				<LoadingMask className={clx('w-100', styles.loading)} />
				<LoadingMask className={clx('w-80', styles.loading)} />
				<LoadingMask className={clx('w-60', styles.loading)} />
			</div>
		</div>
	);
});

InternshipOrganizationSectionLoading.displayName = 'InternshipOrganizationSectionLoading';
