import { memo, FC } from 'react';
import styled from 'styled-components';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Badge, Button, FontAwesome } from 'Elements';
import { names } from 'Constants';
import { useResponsive } from 'Hooks';
import { Colors, PaymentMethod, Subscription, SubscriptionStatus } from 'Types';
import {
	agentPartnerApprovalStatus,
	clx,
	currencyFormatter,
	formatLocaleDateTime,
	subStatus
} from 'Utils';
import styles from 'partials/card/subscription-card.module.scss';

interface Props {
	subscription: Subscription;
	isLast?: boolean;
}

const Label = styled.label`
	@media (min-width: 1200px) {
		min-width: 60px;
	}
`;

export const SubscriptionCard: FC<Props> = memo(({ subscription, isLast }) => {
	const { subscriptions } = names;
	const { t } = useTranslation();
	const { isMobileAndBelow } = useResponsive();
	const status = subscription?.status?.approveStatus;
	const agentApprovedStatus = subscription?.agentApprovedStatus;
	const agent = subscription?.agent;
	const navigateUrl = `${subscriptions.path}/${subscription.id}`;
	const defaultImage = '/assets/img/company-default.jpg';

	const getStatusData = () => {
		return subStatus.find(
			(elem) => elem.value.toString() === status?.toString()
		);
	};

	const getApprovalStatusData = () => {
		return agentPartnerApprovalStatus.find(
			(elem) => elem.value.toString() === agentApprovedStatus?.toString()
		);
	};

	const cardClass = clx(
		styles.card,
		isLast && styles.last,
		(isLast || subscription?.subCompanySubscription?.length === 0) &&
			'mb-4',
		subscription?.subCompanySubscription && styles.switchedParent,
		subscription?.isSubCompanySubscription && styles.switched,
		status === SubscriptionStatus.active && styles.paid,
		status === SubscriptionStatus.expired && styles.expired,
		status === SubscriptionStatus.pending && styles.pending,
		status === SubscriptionStatus.pendingPayment && styles.pending,
		status === SubscriptionStatus.inactive && styles.expired
	);


	return (
		<>
			<article className={cardClass}>
				<Row className="position-relative">
					<Col xl={6}>
						<div className="d-flex align-items-sm-center flex-column flex-sm-row gap-2 gap-sm-3 flex-wrap">
							{subscription?.isSubCompanySubscription && (
								<div
									className={clx(
										styles.col,
										'text-center text-sm-start'
									)}>
									<span className="ps-sm-4">
										<FontAwesome
											icon={
												isMobileAndBelow
													? 'arrow-down'
													: 'arrow-turn-down-right'
											}
											size="2x"
											className="flex-shrink-0"
										/>
									</span>
									<span className="fs-sm fw-400 color-gray-4">
										{t('label.switched-to')}
									</span>
									<div
										className={clx(
											styles.col,
											`text-${getStatusData()?.color}`
										)}>
										<span className="fs-base d-flex align-items-center">
											<span className="fw-500">
												{currencyFormatter(
													subscription?.totalAmount ??
														0
												)}
											</span>
											<span className="text-gray-3 ms-2 fs-small">
												({t('label.tax-included')})
											</span>
										</span>
									</div>
								</div>
							)}
							{!subscription?.isSubCompanySubscription && (
								<div
									className={clx(
										styles.col,
										`text-${getStatusData()?.color}`
									)}>
									<span className="fs-base fw-500">
										{subscription.title}
									</span>
									<span className="fs-base d-flex align-items-center">
										<span className="fw-500">
											{currencyFormatter(
												subscription?.totalAmount ?? 0
											)}
										</span>
										<span className="text-gray-3 ms-2 fs-small">
											({t('label.tax-included')})
										</span>
									</span>
								</div>
							)}
							<div
								className={clx(
									styles.col,
									'text-sm-center text-xl-start flex-row flex-sm-column'
								)}>
								<span className="fs-sm fw-400 color-gray-4">
									{status === SubscriptionStatus.active &&
										(subscription?.autoRenewal
											? t('title.renew-at')
											: t('title.expire-on'))}
									{(status === SubscriptionStatus.pending ||
										status === SubscriptionStatus.expired ||
										status ===
											SubscriptionStatus.pendingPayment) &&
										t('title.expire-on')}
									{status === SubscriptionStatus.inactive &&
										t('title.switch-on')}
									:
								</span>
								<span className="fs-sm fw-500 color-gray-4 ps-2 ps-sm-0">
									{status === SubscriptionStatus.active &&
										(subscription?.renewalDate
											? `${formatLocaleDateTime(
													subscription?.renewalDate,
													false
											  )} (${
													subscription?.remainDays
											  } ${t('label.days')})`
											: `${formatLocaleDateTime(
													subscription?.endDate,
													false
											  )} (${
													subscription?.remainDays
											  } ${t('label.days')})`)}
									{(status === SubscriptionStatus.pending ||
										status === SubscriptionStatus.expired ||
										status ===
											SubscriptionStatus.pendingPayment) &&
										formatLocaleDateTime(
											subscription?.endDate,
											false
										)}
									{status === SubscriptionStatus.inactive &&
										formatLocaleDateTime(
											subscription?.purchaseDate ?? '',
											false
										)}
								</span>
							</div>
							<div className={styles.col}>
								<div className="fs-sm fw-400 color-gray-4 d-flex align-items-center justify-content-sm-end justify-content-xl-start">
									<Label>{t('label.status')}:</Label>
									<span
										className={clx(
											'fw-500 ps-2 flex-xl-grow-1',
											`text-${getStatusData()?.color}`
										)}>
										{t(`${getStatusData()?.label}`)}
									</span>
								</div>
								{/* {!subscription?.isSubCompanySubscription && (
									<div className="fs-sm fw-400 color-gray-4 d-flex align-items-center justify-content-sm-end justify-content-xl-start">
										<Label>{t('label.payment')}:</Label>
										<span className="fw-500 ps-2 flex-xl-grow-1">
											{subscription?.paymentMethod ===
												PaymentMethod.invoice &&
											status ===
												SubscriptionStatus.pending
												? t('label.pending-payment')
												: t(
														`label.${subscription.paymentStatus.toLowerCase()}`
												  ) || '---'}
										</span>
									</div>
								)} */}
							</div>
						</div>
					</Col>
					<Col xl={6}>
						<div className="mt-4 mt-xl-0 d-flex align-items-center h-lg-100 flex-wrap gap-3 gap-sm-2">
							{agent && (
								<div className="d-flex align-items-center flex-grow-1 gap-2">
									<img
										src={agent.logo || defaultImage}
										alt={agent.name}
										className={clx(
											styles.logo,
											styles.logoMd
										)}
									/>
									<span className="fs-sm fw-400">
										<div>{agent.name}</div>
										{agent?.commissionGroup?.isPremium && (
											<Badge
												title={t(
													'title.commission-group.key',
													{
														commissionGroupName:
															agent
																?.commissionGroup
																.label
													}
												)}
												color={Colors.premium}
												className="gap-2">
												<FontAwesome
													icon={'medal'}
													size="lg"
												/>
												{agent?.commissionGroup.label}
											</Badge>
										)}
									</span>

									<Badge
										color={
											getApprovalStatusData()?.color ??
											Colors.muted
										}>
										{t(`${getApprovalStatusData()?.label}`)}
									</Badge>
								</div>
							)}
							<div className="d-flex align-items-center gap-2">
								{subscription?.status?.approveStatus ===
									SubscriptionStatus.pendingPayment &&
									subscription?.agentApprovalStatus
										?.agentApprovedStatus !== 'Pending' && (
										<Button
											as="a"
											// to={subscription.payUrl}
											to={`/checkout?subscriptionId=${subscription?.id}`}
											state={{
												prevPath: subscriptions.path
											}}
											size="sm"
											className="px-4"
											label={t('button.pay') as string}
										/>
									)}
								<Button
									as="a"
									to={navigateUrl}
									state={{
										prevPath: subscriptions.path
									}}
									size="sm"
									color={Colors['white-primary']}
									label={t('button.details') as string}
								/>
							</div>
						</div>
					</Col>
				</Row>
			</article>
			{subscription?.subCompanySubscription &&
				subscription?.subCompanySubscription.map((sub, index) => (
					<SubscriptionCard
						key={sub.id}
						subscription={sub}
						isLast={
							index ===
							subscription?.subCompanySubscription.length - 1
						}
					/>
				))}
		</>
	);
});

SubscriptionCard.displayName = 'SubscriptionCard';
