import { memo, useEffect } from 'react';
import { Container } from 'reactstrap';
import { SwiperSlide } from 'swiper/react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AgentSetting } from 'Types';
import { useMembers, useTheme, useUpdateAgent } from 'Hooks';
import {
	SectionHeadline,
	LoadingSpinner,
	ProfileAddNewCard,
	ProfileStaffSection,
	ProfileStaffCard,
	SectionSwitcher
} from 'Elements';
import styles from 'partials/homepage/section/staff-section.module.scss';

export const AgentMemberSection = memo(() => {
	const { theme } = useTheme();
	const { t } = useTranslation();
	const { control, reset } = useForm();

	const {
		updateAgentSetting,
		setting,
		isLoading: isLoadingSetting
	} = useUpdateAgent();

	const { data: members, isLoading } = useMembers({
		pageNumber: 1,
		pageSize: 50,
		keyword: ''
	});

	const handleUpdate = async (field: Partial<AgentSetting>) => {
		await updateAgentSetting(field);
	};

	useEffect(() => {
		reset(setting);
	}, [reset, setting]);

	return (
		<section className="position-relative mb-5 pb-5">
			{isLoading && (
				<div className="d-flex align-items-center justify-content-center">
					<LoadingSpinner />
				</div>
			)}
			{!isLoading && !isLoadingSetting && (
				<Container>
					<SectionSwitcher
						control={control}
						name="memberSectionStatus"
						label="forms.show-members-in-home"
						isActive={!setting?.memberSectionStatus}
						onSwitchChange={(val) =>
							handleUpdate({ memberSectionStatus: val })
						}
					/>
					<SectionHeadline
						title={
							setting?.memberTitle ||
							`[${t('placeholder.our-members')}]`
						}
						titleColor="text-gray-5"
						className="mx-auto text-center"
						onUpdateTitle={(val) =>
							handleUpdate({ memberTitle: val })
						}
					/>
					{members && members?.items?.length > 0 && (
						<ProfileStaffSection list={members} profileType="agent">
							<SwiperSlide className={styles.card}>
								<ProfileAddNewCard
									navigationUrl="/members/create"
									title="title.add-new-member"
									className="h-100"
								/>
							</SwiperSlide>
							{members.items?.reverse()?.map((member) => {
								return (
									<SwiperSlide
										key={member.id}
										className={styles.card}>
										<ProfileStaffCard
											id={member.id}
											name={member.fullName}
											staffType="members"
											image={member.thumbnailUrl}
											position={member.position}
										/>
									</SwiperSlide>
								);
							})}
						</ProfileStaffSection>
					)}
					{members && members?.items?.length === 0 && (
						<ProfileAddNewCard
							navigationUrl="/members/create"
							title="title.add-new-member"
						/>
					)}
				</Container>
			)}
			{!isLoadingSetting && !setting?.memberSectionStatus && (
				<div className="overlay overlay_white overlay-box" />
			)}
		</section>
	);
});

AgentMemberSection.displayName = 'AgentMemberSection';
