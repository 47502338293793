import { Label } from 'reactstrap';
import { SwitchProps } from 'rc-switch';
import { useTranslation } from 'react-i18next';
import {
	Controller,
	ControllerFieldState,
	UseControllerProps,
	FieldValues
} from 'react-hook-form';
import { clx } from 'Utils';
import { ReactSwitch } from 'Elements';

type Props<T extends FieldValues> = {
	size?: 'sm' | 'md';
	label?: string;
	side?: 'left' | 'right';
	className?: string;
	labelClassName?: string;
	boxClassName?: string;
	onSwitchChange?: (val: boolean) => void;
} & UseControllerProps<T> &
	Partial<ControllerFieldState> &
	SwitchProps;

export const SwitchController = <T extends FieldValues>({
	name,
	rules,
	error,
	control,
	label,
	side = 'left',
	size = 'sm',
	className,
	boxClassName,
	labelClassName,
	onSwitchChange,
	...switchProps
}: Props<T>): JSX.Element => {
	const { t } = useTranslation();
	return (
		<div className={clx('d-flex align-items-center gap-2', boxClassName)}>
			{label && side === 'right' && (
				<Label
					htmlFor={name}
					className={clx(
						'd-flex align-items-center mb-0 py-0 cursor-pointer',
						labelClassName
					)}>
					{t(label)}
				</Label>
			)}
			<Controller
				control={control}
				name={name}
				rules={rules}
				render={({ field: { onChange, value } }) => (
					<ReactSwitch
						{...switchProps}
						id={name}
						size={size}
						className={clx(className)}
						checked={value}
						onChange={(val: boolean) => {
							onChange(val);
							onSwitchChange?.(val);
						}}
					/>
				)}
			/>
			{label && side === 'left' && (
				<Label
					htmlFor={name}
					className={clx(
						'd-flex align-items-center mb-0 py-0 cursor-pointer',
						labelClassName
					)}>
					{t(label)}
				</Label>
			)}
			{error && (
				<div className="invalid-feedback d-block">
					{error.message?.toString()}
				</div>
			)}
		</div>
	);
};

SwitchController.displayName = 'SwitchController';
