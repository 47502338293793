import { memo, useState } from 'react';
import { Row } from 'reactstrap';
import { useSearchParams } from 'react-router-dom';
import { useEmployees } from 'Hooks';
import {
	EmployeeCard,
	ListingPage,
	NoData,
	Pagination,
	UserCardLoading
} from 'Elements';

export const ListEmployee = memo(() => {
	const [searchParams, setSearchParams] = useSearchParams();
	const params = Object.fromEntries([...searchParams]);
	const pageParam = searchParams.get('page') || 1;
	const queryParam = searchParams.get('q') || '';
	const [state, setState] = useState({
		pageSize: 12,
		currentPage: pageParam,
		keyword: queryParam
	});

	const { data, isLoading, isFetching } = useEmployees({
		pageSize: state.pageSize,
		pageNumber: state.currentPage,
		keyword: state.keyword
	});

	const onChangePageSize = (pageSize: number) => {
		setSearchParams({ ...params, page: '1' });
		setState((prev) => ({
			...prev,
			pageSize,
			currentPage: 1
		}));
	};

	const handleSearch = (query: string) => {
		setSearchParams({
			...params,
			page: '1',
			q: query
		});
		setState((prev) => ({
			...prev,
			currentPage: 1,
			keyword: query
		}));
	};

	const handlePageChange = (page: number) => {
		setSearchParams({ ...params, page: page.toString() });
		setState((prev) => ({
			...prev,
			currentPage: page
		}));
	};

	return (
		<ListingPage
			totalItems={data?.totalItems}
			pageSizes={[4, 8, 12]}
			selectedPageSize={state.pageSize}
			onPageSizeChange={onChangePageSize}
			onSearchChange={handleSearch}>
			<Row className="position-relative">
				{!isFetching &&
					data?.items?.map((card) => {
						return <EmployeeCard key={card.id} card={card} />;
					})}
				{!isFetching && data?.totalItems === 0 && (
					<NoData message="title.no-employee" />
				)}
			</Row>
			{isFetching && <UserCardLoading />}
			{!isLoading && data && data?.totalPages > 1 && (
				<Pagination
					totalItemsCount={data?.totalItems}
					activePage={parseInt(state.currentPage.toString(), 10)}
					itemsCountPerPage={state.pageSize}
					onChange={handlePageChange}
				/>
			)}
		</ListingPage>
	);
});

ListEmployee.displayName = 'ListEmployee';
