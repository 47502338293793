import { FC, memo, SVGProps } from 'react';
import { rgba } from 'polished';
import { ColorValues } from 'Types';

interface Props {
	fill?: string;
	className?: string;
}

export const IconPlayer: FC<Props> = memo(
	({
		fill = `${rgba(ColorValues.primary, 0.5)}`,
		className
	}: SVGProps<SVGSVGElement>): JSX.Element => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="68.603"
				height="69.897"
				viewBox="0 0 68.603 69.897"
				className={className}>
				<defs>
					<filter
						id="a"
						x="16.888"
						y="16.005"
						width="41.299"
						height="45.182"
						filterUnits="userSpaceOnUse">
						<feOffset dy="3" />
						<feGaussianBlur stdDeviation="3" result="b" />
						<feFlood floodOpacity="0.161" />
						<feComposite operator="in" in2="b" />
						<feComposite in="SourceGraphic" />
					</filter>
				</defs>
				<ellipse
					cx="34.301"
					cy="34.948"
					rx="34.301"
					ry="34.948"
					fill={fill}
				/>
				<g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#a)">
					<path
						d="M11.864,2.962a2,2,0,0,1,3.455,0l10.109,17.33A2,2,0,0,1,23.7,23.3H3.482a2,2,0,0,1-1.728-3.008Z"
						transform="translate(49.19 22) rotate(90)"
						fill="#fff"
					/>
				</g>
			</svg>
		);
	}
);
IconPlayer.displayName = 'IconPlayer';
