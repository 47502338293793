import { FC, memo } from 'react';
import styled from 'styled-components';
import { Container } from 'reactstrap';
import { ProfileHeroHeader, ProfileHeroImage } from 'Elements';
import styles from 'partials/homepage/section/profile-hero-section.module.scss';

interface Props {
	type: 'agent' | 'company';
}

const Decoration = styled.div<{ profileType: 'agent' | 'company' }>`
	${({ theme, profileType }) =>
		theme && `background-color: ${theme[profileType].primaryColor}`};
`;

export const ProfileHeroSection: FC<Props> = memo(({ type }) => {
	return (
		<section className="position-relative mb-5 pb-5">
			<Decoration className={styles.decoration} profileType={type} />
			<Container>
				<div className={styles.content}>
					<ProfileHeroImage type={type} />
					<ProfileHeroHeader type={type} />
				</div>
			</Container>
		</section>
	);
});

ProfileHeroSection.displayName = 'ProfileHeroSection';
