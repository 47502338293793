import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

export const AgentPlanPricePermission: FC = memo(() => {
	const { t } = useTranslation();

	return (
		<Row className="justify-content-center">
			<Col md={8} xxl={6}>
				<div className="d-flex flex-column align-items-center justify-content-center text-center gap-4">
					<img src="/assets/img/investments-lock.svg" />
					<div className="d-flex flex-column text-center justify-content-center gap-2">
						<h4 className="lh-base mb-0 h6">
							{t('title.plan-price.only-for-premium')}
						</h4>
						<p
							className="text-gray-3 lh-base mb-0"
							dangerouslySetInnerHTML={{
								__html: t(
									'text.plan-price.only-for-premium-description'
								)
							}}
						/>
					</div>
				</div>
			</Col>
		</Row>
	);
});

AgentPlanPricePermission.displayName = 'AgentPlanPricePermission';
