import { memo } from 'react';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useVoucher } from 'Hooks';
import { MyVoucher } from 'Types';
import {
	Card,
	LoadingContent,
	VoucherDetails,
	VoucherCompanies
} from 'Elements';

const VoucherCard = styled(Card)`
	height: 100%;
	min-height: 250px;
`;

export const ViewVoucher = memo(() => {
	const { voucher_id } = useParams();
	const { data: voucher, isLoading } = useVoucher(voucher_id as string);

	return (
		<Row>
			<Col md={6}>
				<VoucherCard>
					{!isLoading && (
						<VoucherDetails voucher={voucher as MyVoucher} />
					)}
					{isLoading && <LoadingContent />}
				</VoucherCard>
			</Col>
			<Col md={6} className="mt-4 mt-md-0">
				<VoucherCard>
					{!isLoading && (
						<VoucherCompanies
							companies={voucher?.usageInfo || null}
						/>
					)}
					{isLoading && <LoadingContent />}
				</VoucherCard>
			</Col>
		</Row>
	);
});

ViewVoucher.displayName = 'ViewVoucher';
