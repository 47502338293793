import { memo, FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { clx, toastify } from 'Utils';
import { useEvent, useResponsive } from 'Hooks';
import { AgentEvent, EventFormData, Colors } from 'Types';
import { Card, FontAwesome, SubmitButton } from 'Elements';
import styles from 'partials/page/form.module.scss';

interface Props {
	formData: (data: EventFormData) => AgentEvent;
}

export const SaveAndPublishEvent: FC<Props> = memo(({ formData }) => {
	const { t } = useTranslation();
	const { event_id } = useParams();
	const { isDesktopAndBelow } = useResponsive();
	const [publishing, setPublishing] = useState(false);
	const { data: event, saveAndPublishEvent } = useEvent(event_id as string);

	const {
		trigger,
		getValues,
		formState: { isDirty, isSubmitting }
	} = useFormContext<EventFormData>();

	const handleClick = async () => {
		try {
			const isValidForm = await trigger();
			if (isValidForm) {
				setPublishing(true);
				const values = getValues();
				await saveAndPublishEvent(formData(values), () => {
					setPublishing(false);
				});
			} else {
				toastify(t('toastify.check-required-fields'));
			}
		} finally {
			setPublishing(false);
		}
	};

	const checkForm = async () => {
		const isValidForm = await trigger();
		if (!isValidForm) {
			toastify(t('toastify.check-required-fields'));
		}
	};

	const checkDisabled = () => {
		let disability = event?.publishedVersion === event?.version;
		if (isDirty) {
			disability = false;
		}

		return disability;
	};

	return (
		<Card
			className={clx(
				'd-flex align-items-center flex-wrap gap-3',
				isDesktopAndBelow && styles.sticky_action
			)}>
			{event?.hasUnpublishedChanges && (
				<div className="opacityPulse infinite d-flex align-items-center gap-2 text-secondary flex-shrink-0">
					<FontAwesome icon="exclamation-triangle" size="xl" />
					{t('text.publish-new-changes')}
				</div>
			)}
			<div className="d-flex align-items-center gap-2 ms-auto">
				<SubmitButton
					color={Colors['white-primary']}
					className="me-1"
					isDisable={!isDirty}
					isSubmitting={isSubmitting}
					onClick={checkForm}
				/>
				<SubmitButton
					type="button"
					icon="upload"
					label="button.save-publish"
					savingLabel="button.publishing"
					isDisable={checkDisabled()}
					isSubmitting={publishing}
					onClick={handleClick}
				/>
			</div>
		</Card>
	);
});

SaveAndPublishEvent.displayName = 'SaveAndPublishEvent';
