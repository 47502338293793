import { FC, InputHTMLAttributes, ReactNode, memo } from 'react';
import { clx } from 'Utils';
import { XOR } from 'Types';

interface SharedProps extends InputHTMLAttributes<HTMLInputElement> {
	label: string;
	id: string;
	name: string;
	className?: string;
	disabled?: boolean;
}

type Props = XOR<{ children: ReactNode }, { label: string }> & SharedProps;

export const RadioItem: FC<Props> = memo(
	({ label, children, id, name, className, disabled, ...props }) => {
		return (
			<div
				role="checkbox"
				tabIndex={0}
				aria-checked={props.checked}
				className={clx(className)}>
				<input
					{...props}
					type="radio"
					id={`${name}_${id}`}
					name={name}
					disabled={disabled}
					className="form-input-check"
				/>
				<label
					htmlFor={`${name}_${id}`}
					className="d-flex"
					aria-disabled={disabled}>
					<i
						className={clx(
							'form-radio-tick',
							props.checked && 'checked'
						)}
					/>
					<span className="ps-2 cursor-pointer">
						{label || children}
					</span>
				</label>
			</div>
		);
	}
);

RadioItem.displayName = 'RadioItem';
