/* eslint-disable import/no-named-as-default-member */
import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { getCurrentLocale } from 'src/helpers/Utils';
import deLang from './locale/de-DE.json';
import enLang from './locale/en-US.json';

const resources = {
	de: {
		translation: deLang
	},
	en: {
		translation: enLang
	}
};

i18n.use(detector)
	.use(initReactI18next)
	.init({
		resources,
		fallbackLng: 'de',
		lng: getCurrentLocale?.().code,
		interpolation: {
			escapeValue: false
		},
		react: {
			useSuspense: true
		}
	});

export default i18n;
