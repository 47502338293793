import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SingleValue } from 'react-select';
import { ReactSelect, ToolboxConfigGroup } from 'Elements';
import {
	AssetConfigElement,
	AssetConfigType,
	HeadlineElement,
	SelectOption
} from 'Types';

interface Props {
	element: AssetConfigElement<HeadlineElement>;
	errors?: AssetConfigType;
	defaultValue?: SelectOption | null;
	onChange: (headline: HeadlineElement) => void;
}

export const HeadlineElementList: FC<Props> = memo(
	({ element, errors, defaultValue, onChange }) => {
		const { t } = useTranslation();

		const headlineOptions: SelectOption[] = [
			...(!element.isRequired
				? [{ label: t('label.marketing.no-headline'), value: '' }]
				: []),
			...element.inputItems
		];

		return (
			<ToolboxConfigGroup
				title={element.title}
				isRequired={element.isRequired}
				errors={errors}>
				<ReactSelect
					value={defaultValue}
					options={headlineOptions}
					onChange={(value: SingleValue<SelectOption>) =>
						onChange(value as HeadlineElement)
					}
				/>
			</ToolboxConfigGroup>
		);
	}
);

HeadlineElementList.displayName = 'HeadlineElementList';
