import { useTranslation } from 'react-i18next';
import { clx } from 'Utils';
import { DataList } from 'Types';

interface Props<T> {
	data?: DataList<T>;
	className?: string;
	message?: string;
}

export const EndOfList = <T,>({
	data,
	message,
	className
}: Props<T>): JSX.Element => {
	const { t } = useTranslation();
	return (
		<>
			{data &&
				data.totalItems > 0 &&
				data.totalPages > 1 &&
				data.totalPages === data.pageNumber && (
					<p
						className={clx(
							'text-gray-3 fs-small my-4 py-2 text-center',
							className
						)}>
						{message || t('text.end-of-list')}
					</p>
				)}
		</>
	);
};

EndOfList.displayName = 'EndOfList';
