import {
	memo,
	FC,
	useEffect,
	FormEvent,
	useCallback,
	useMemo,
	useState
} from 'react';
import { useTranslation } from 'react-i18next';
import {
	CustomPermission,
	Permission,
	PermissionElement,
	PermissionGraph,
	PermissionSection,
	UserRolePermissions
} from 'Types';
import {
	LoadingContent,
	StepNavigation,
	UserPermissionSection
} from 'Elements';
import styles from 'partials/step/step-container.module.scss';

interface Props {
	fields: UserRolePermissions;
	permissionData?: Permission;
	permissionGraphData?: PermissionGraph[];
	isLoadingPermissions: boolean;
	isLoadingGraph: boolean;
	onPrev: () => void;
	onNext: (data: UserRolePermissions) => void;
}

export const RolePermissions: FC<Props> = memo(
	({
		fields,
		permissionData,
		permissionGraphData,
		isLoadingPermissions,
		isLoadingGraph,
		onNext,
		onPrev
	}) => {
		const { t } = useTranslation();
		const [error, setError] = useState(false);
		const [selectedPermission, setSelectedPermission] = useState<
			CustomPermission[]
		>([]);
		const [permissionsList, setPermissionsList] = useState<
			CustomPermission[]
		>([]);

		// Create flat list of all permissions with each connected permissions
		useMemo(() => {
			const flat_permissions: CustomPermission[] = [];

			// Loop trough every permissions in each section
			permissionData?.sections?.map((section: PermissionSection) => {
				return section.permissions?.map((section_permissions) => {
					return flat_permissions.push({
						code: section_permissions.key,
						label: section_permissions.label,
						section_title: section.title,
						section_title_key: section.key,
						isChecked: false,
						disabled: false,
						clicked: false
					});
				});
			});

			// Add connectedPermissions property to each permission level
			flat_permissions?.forEach((permission: CustomPermission) => {
				permission.connectedPermissions = permissionGraphData
					?.find((graph) => {
						return graph.permission === permission.code;
					})
					?.connectedPermission.filter(Boolean); // return not empty string code/key
			});

			setPermissionsList(flat_permissions);
		}, [permissionData, permissionGraphData]);

		const groupPermissionBySection = (list: CustomPermission[]) => {
			const res: { section_title?: PermissionSection } = {};
			list.forEach(({ section_title, section_title_key, ...rest }) => {
				if (!res[section_title]) {
					res[section_title] = {
						title: section_title,
						key: section_title_key,
						permissions: []
					} as PermissionSection;
				}
				res?.[section_title]?.permissions.push({
					...rest
				});
			});
			return Object.values(res);
		};

		const getSelectedPermissions = (list: CustomPermission[]) => {
			return list.filter(
				(permission: CustomPermission) => permission.isChecked === true
			);
		};

		const setGraphOnPermission: (
			isChecked: boolean,
			permission_item: PermissionElement
		) => void = (isChecked, permission_item) => {
			const permissions_list = permissionsList;

			// loop through every connectedPermissions in each section
			// to find relative permissions
			permission_item?.connectedPermissions?.forEach(
				(connected: string) => {
					const eachConnected = permissions_list?.find(
						(permission) => permission.code === connected
					);
					if (eachConnected) {
						eachConnected.isChecked = isChecked;
						eachConnected.disabled = isChecked;
					}
				}
			);
		};

		const handleCheckboxChange: (
			checked_item: PermissionElement,
			is_checked: boolean
		) => void = (checked_item, isChecked) => {
			const permissions_list = permissionsList;

			// Find checked item in base permission list
			// and then changed its isChecked value
			const founded_item = permissions_list.find(
				(per) => per?.code === checked_item?.code
			);

			if (founded_item) {
				founded_item.isChecked = isChecked;
			}

			setGraphOnPermission(isChecked, checked_item);
			setPermissionsList(permissions_list);
			setSelectedPermission(getSelectedPermissions(permissions_list));
		};

		const handleCheckAllChange: (
			checked_list: PermissionElement[],
			is_checked: boolean
		) => void = (checked_list, isChecked) => {
			checked_list.forEach((checked_item) => {
				handleCheckboxChange(checked_item, isChecked);
			});
		};

		const setPermissionsByFields = useCallback(() => {
			const permissions_list = permissionsList;
			fields.permissions?.forEach((field: CustomPermission) => {
				const everyPermission = permissions_list.find(
					(permission: CustomPermission) =>
						permission.code === field.code
				);
				if (everyPermission) {
					everyPermission.isChecked = field?.clicked;
					everyPermission.disabled = field.disabled;
				}
			});
			setSelectedPermission(fields.permissions);
		}, [fields.permissions, permissionsList]);

		const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
			e.preventDefault();
			if (selectedPermission.length === 0) {
				setError(true);
			} else {
				setError(false);
				const newPermission: CustomPermission[] = [];
				selectedPermission.forEach((selected: CustomPermission) => {
					newPermission.push({
						...selected,
						clicked: selected.isChecked ?? selected.clicked
					});
				});
				onNext?.({ permissions: newPermission });
			}
		};

		useEffect(() => {
			if (fields.permissions) {
				// setSelectedPermission(fields.permissions);
				setPermissionsByFields();
			}
		}, [fields.permissions, setPermissionsByFields]);

		return (
			<form className={styles.form} onSubmit={handleSubmit} noValidate>
				{groupPermissionBySection(permissionsList)?.map((section) => {
					return (
						<UserPermissionSection
							key={section.key}
							index={section.key}
							title={section.title}
							permissions={section.permissions}
							onCheckboxChange={handleCheckboxChange}
							onCheckAllChange={handleCheckAllChange}
						/>
					);
				})}
				{error && (
					<div className="invalid-feedback d-block text-center mb-3">
						{t('validation.select-permission.required')}
					</div>
				)}
				<StepNavigation onPrev={onPrev} />
				{isLoadingPermissions && isLoadingGraph && <LoadingContent />}
			</form>
		);
	}
);

RolePermissions.displayName = 'RolePermissions';
