import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Colors } from 'Types';
import { useVouchers } from 'Hooks';
import { containerVariants } from 'Constants';
import { formatLocaleDateTime, getDiscountFormat } from 'Utils';
import {
	Badge,
	Button,
	DataTable,
	DataTableBody,
	DataTableHead,
	DataTableLoading,
	DataTableRow,
	DataTableRowCell,
	EndOfList,
	ListingPage,
	NoData,
	Pagination,
	SystemErrorAlert
} from 'Elements';

export const ListVoucher = memo(() => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const params = Object.fromEntries([...searchParams]);
	const pageParam = searchParams.get('page') || 1;
	const queryParam = searchParams.get('q') || '';

	const [state, setState] = useState({
		pageSize: 10,
		currentPage: pageParam,
		keyword: queryParam
	});

	const { data, isError, isFetching } = useVouchers({
		pageSize: state.pageSize,
		pageNumber: state.currentPage,
		keyword: state.keyword
	});

	const onChangePageSize = (pageSize: number) => {
		setSearchParams({ ...params, page: '1' });
		setState((prev) => ({
			...prev,
			pageSize,
			currentPage: 1
		}));
	};

	const handleSearch = (query: string) => {
		setSearchParams({
			...params,
			page: '1',
			q: query
		});
		setState((prev) => ({
			...prev,
			currentPage: 1,
			keyword: query
		}));
	};

	const handlePageChange = (page: number) => {
		setSearchParams({ ...params, page: page.toString() });
		setState((prev) => ({
			...prev,
			currentPage: page
		}));
	};

	const redirectTo = (voucherId: string) => {
		void navigate(`/vouchers/${voucherId}`, {
			state: { prevPath: '/vouchers' }
		});
	};

	return (
		<ListingPage
			totalItems={data?.totalItems}
			pageSizes={[5, 10, 15, 20]}
			selectedPageSize={state.pageSize}
			onPageSizeChange={onChangePageSize}
			onSearchChange={handleSearch}>
			<DataTable>
				{data && data?.totalItems > 0 && (
					<DataTableHead>
						<DataTableRowCell
							cellText="table.code"
							cellClassName="w-100 w-sm-15"
						/>
						<DataTableRowCell
							cellText="table.usage"
							cellClassName="w-100 w-sm-15"
						/>
						<DataTableRowCell
							cellText="table.expire-date"
							cellClassName="w-100 w-sm-15"
						/>
						<DataTableRowCell
							cellText="table.discount"
							cellClassName="w-100 w-sm-10"
						/>
						<DataTableRowCell
							cellText="table.status"
							cellClassName="w-100 w-sm-10"
						/>
						<DataTableRowCell
							cellText="table.descriptions"
							cellClassName="w-100 w-sm-20"
						/>
						<DataTableRowCell
							cellText="table.operation"
							cellClassName="w-100 w-sm-15"
						/>
					</DataTableHead>
				)}
				<DataTableBody>
					{!isFetching &&
						<motion.div
							variants={containerVariants}
							initial="hidden"
							animate="show">
							{data?.items?.map((voucher) => {
								return (
									<DataTableRow key={voucher.id}>
										<DataTableRowCell
											cellClassName="w-100 w-sm-15"
											cellHead="table.code"
											cellText={voucher.voucherCode}
										/>
										<DataTableRowCell
											cellClassName="w-100 w-sm-15"
											cellHead="table.usage"
											cellText={`${voucher.usageLimitUsedCount
												}/${voucher.usageLimit === 0
													? t('label.unlimited')
													: voucher.usageLimit
												}`}
										/>
										<DataTableRowCell
											cellClassName="w-100 w-sm-15"
											cellHead="table.expire-date">
											{voucher.expireDate && (
												<time
													title={formatLocaleDateTime(
														voucher.expireDate
													)}
													dateTime={formatLocaleDateTime(
														voucher.expireDate
													)}>
													{formatLocaleDateTime(
														voucher.expireDate
													)}
												</time>
											)}
											{!voucher.expireDate &&
												t('label.permanent')}
										</DataTableRowCell>
										<DataTableRowCell
											cellClassName="w-100 w-sm-10"
											cellHead="table.discount"
											cellText={getDiscountFormat(
												voucher?.discount,
												voucher?.discountType
											)}
										/>
										<DataTableRowCell
											cellClassName="w-100 w-sm-10"
											cellHead="table.status">
											<Badge
												color={
													voucher.isExpired
														? Colors.red
														: Colors.success
												}>
												{voucher.isExpired
													? t('badge.expired')
													: t('badge.active')}
											</Badge>
										</DataTableRowCell>
										<DataTableRowCell
											cellClassName="w-100 w-sm-20"
											cellHead="table.description">
											{voucher.isAutomaticRenew &&
												t(
													'label.applicable-in-subscription-renewal'
												)}
										</DataTableRowCell>
										<DataTableRowCell
											cellClassName="w-100 w-sm-15"
											cellHead="table.operation">
											<Button
												size="sm"
												color={Colors['white-primary']}
												label={
													t('button.details') as string
												}
												onClick={() =>
													redirectTo(voucher.id)
												}
											/>
										</DataTableRowCell>
									</DataTableRow>
								);
							})}
						</motion.div>}
					{isFetching && (
						<DataTableLoading
							widths={[15, 15, 15, 10, 10, 20, 15]}
							count={state.pageSize}
						/>
					)}
				</DataTableBody>
				{isError && <SystemErrorAlert />}
				{!isFetching && data?.totalItems === 0 && (
					<NoData message="title.no-voucher" />
				)}
			</DataTable>
			{!isFetching && <EndOfList data={data} />}
			{!isFetching && data && data?.totalPages > 1 && (
				<Pagination
					className="mt-3"
					totalItemsCount={data?.totalItems}
					activePage={parseInt(state.currentPage.toString(), 10)}
					itemsCountPerPage={state.pageSize}
					onChange={handlePageChange}
				/>
			)}
		</ListingPage>
	);
});

ListVoucher.displayName = 'ListVoucher';
