import { FC, memo, SVGProps } from 'react';
import { ColorValues } from 'Types';

interface Props {
	className?: string;
}

export const IconNoResult: FC<Props> = memo(
	({ className, ...props }: SVGProps<SVGSVGElement>): JSX.Element => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 300 137.471"
				className={className}
				{...props}>
				<g transform="translate(-614.18 -284.548)">
					<g transform="translate(713.007 292.981)" opacity="0.7">
						<path
							d="M343.854,515.955H284.088a19.429,19.429,0,0,1-19.429-19.429V412.688a19.429,19.429,0,0,1,19.429-19.429H330.6l32.693,31.006v72.266A19.429,19.429,0,0,1,343.854,515.955Z"
							transform="translate(-261.495 -390.081)"
							fill="#fff"
						/>
						<path
							d="M346.018,521.282H286.252a22.618,22.618,0,0,1-22.593-22.593V414.852a22.619,22.619,0,0,1,22.593-22.593h46.509a3.164,3.164,0,0,1,2.177.867l32.692,31.006a3.164,3.164,0,0,1,.987,2.3V498.7a22.619,22.619,0,0,1-22.6,22.587ZM286.252,398.577a16.281,16.281,0,0,0-16.266,16.266v83.843a16.281,16.281,0,0,0,16.266,16.266h59.766a16.281,16.281,0,0,0,16.266-16.266V427.764l-30.791-29.187Z"
							transform="translate(-263.659 -392.245)"
							opacity="0.85"
							fill={ColorValues['gray-3']}
						/>
						<path
							d="M385.6,414.248H372.331A19.427,19.427,0,0,1,352.9,394.816V383.255Z"
							transform="translate(-283.812 -380.09)"
							fill="#fff"
						/>
						<path
							d="M387.761,419.577H374.5A22.619,22.619,0,0,1,351.9,396.984V385.42a3.164,3.164,0,0,1,5.341-2.3l32.693,31.006a3.164,3.164,0,0,1-2.177,5.461Zm-29.529-26.8v4.2A16.282,16.282,0,0,0,374.5,413.249h5.328Z"
							transform="translate(-285.976 -382.255)"
							opacity="0.85"
							fill={ColorValues['gray-3']}
						/>
					</g>
					<g
						transform="translate(635.046 331.988) rotate(-15.009)"
						opacity="0.4">
						<path
							d="M34.841,53.976H8.543A8.543,8.543,0,0,1,0,45.433V8.543A8.543,8.543,0,0,1,8.543,0H29.007l14.38,13.633v31.8A8.542,8.542,0,0,1,34.841,53.976Z"
							transform="translate(3.164 3.165)"
							fill={ColorValues['gray-1']}
						/>
						<path
							d="M38,60.3h-26.3A11.722,11.722,0,0,1,0,48.6V11.706A11.722,11.722,0,0,1,11.706,0H32.17a3.164,3.164,0,0,1,2.177.867L48.724,14.5a3.164,3.164,0,0,1,.987,2.3v31.8A11.722,11.722,0,0,1,38,60.3ZM11.706,6.328a5.379,5.379,0,0,0-5.379,5.379V48.594a5.379,5.379,0,0,0,5.379,5.379H38a5.379,5.379,0,0,0,5.379-5.379V18.158L30.908,6.328Z"
							transform="translate(0 0.001)"
							fill={ColorValues['gray-2']}
						/>
						<path
							d="M14.38,13.633H8.543A8.543,8.543,0,0,1,0,5.088V0Z"
							transform="translate(32.17 3.165)"
							fill={ColorValues['gray-1']}
						/>
						<path
							d="M17.544,19.962H11.706A11.722,11.722,0,0,1,0,8.252V3.165A3.164,3.164,0,0,1,5.341.868L19.721,14.5a3.164,3.164,0,0,1-2.177,5.461Z"
							transform="translate(29.007 0)"
							fill={ColorValues['gray-2']}
						/>
					</g>
					<g
						transform="translate(847.887 316.58) rotate(15.009)"
						opacity="0.4">
						<path
							d="M34.841,53.976H8.543A8.543,8.543,0,0,1,0,45.433V8.543A8.543,8.543,0,0,1,8.543,0H29.007l14.38,13.633v31.8A8.542,8.542,0,0,1,34.841,53.976Z"
							transform="translate(3.164 3.165)"
							fill={ColorValues['gray-1']}
						/>
						<path
							d="M38,60.3h-26.3A11.722,11.722,0,0,1,0,48.6V11.706A11.722,11.722,0,0,1,11.706,0H32.17a3.164,3.164,0,0,1,2.177.867L48.724,14.5a3.164,3.164,0,0,1,.987,2.3v31.8A11.722,11.722,0,0,1,38,60.3ZM11.706,6.328a5.379,5.379,0,0,0-5.379,5.379V48.594a5.379,5.379,0,0,0,5.379,5.379H38a5.379,5.379,0,0,0,5.379-5.379V18.158L30.908,6.328Z"
							transform="translate(0 0.001)"
							fill={ColorValues['gray-2']}
						/>
						<path
							d="M14.38,13.633H8.543A8.543,8.543,0,0,1,0,5.088V0Z"
							transform="translate(32.17 3.165)"
							fill={ColorValues['gray-1']}
						/>
						<path
							d="M17.544,19.962H11.706A11.722,11.722,0,0,1,0,8.252V3.165A3.164,3.164,0,0,1,5.341.868L19.721,14.5a3.164,3.164,0,0,1-2.177,5.461Z"
							transform="translate(29.007 0)"
							fill={ColorValues['gray-2']}
						/>
					</g>
					<path
						d="M3.817,61.053-.717,56.623,56.081-1.7l4.534,4.429Z"
						transform="translate(736.378 338.35)"
						fill={ColorValues.primary}
					/>
					<g transform="translate(738.293 340.76)">
						<path
							d="M26.184-1A27.184,27.184,0,1,1-1,26.184,27.184,27.184,0,0,1,26.184-1Zm0,48.044A20.856,20.856,0,1,0,5.328,26.186,20.856,20.856,0,0,0,26.184,47.044Z"
							transform="translate(1 1)"
							fill={ColorValues.primary}
						/>
					</g>
					<g transform="translate(683.217 282.169) rotate(45)">
						<path
							d="M9.89,3.164H0V0H9.89Z"
							transform="translate(0 3.363)"
							fill={ColorValues['gray-2']}
						/>
						<path
							d="M9.89,3.164H0V0H9.89Z"
							transform="translate(6.527) rotate(90)"
							fill={ColorValues['gray-2']}
						/>
					</g>
					<g transform="translate(856.076 394.867) rotate(45)">
						<path
							d="M18.85,3.164H0V0H18.85Z"
							transform="translate(0 7.843)"
							fill={ColorValues['gray-2']}
						/>
						<path
							d="M18.85,3.164H0V0H18.85Z"
							transform="translate(11.007) rotate(90)"
							fill={ColorValues['gray-2']}
						/>
					</g>
					<path
						d="M6.328,3.164A3.164,3.164,0,1,0,9.492,6.328,3.164,3.164,0,0,0,6.328,3.164M6.328,0A6.328,6.328,0,1,1,0,6.328,6.328,6.328,0,0,1,6.328,0Z"
						transform="translate(676.888 401.861)"
						fill={ColorValues['gray-2']}
					/>
					<path
						d="M3.164,0A3.164,3.164,0,1,1,0,3.164,3.164,3.164,0,0,1,3.164,0Z"
						transform="translate(907.852 357.566)"
						fill={ColorValues['gray-2']}
					/>
					<rect
						width="9.492"
						height="9.492"
						transform="translate(829.324 295.602) rotate(-45)"
						fill="#fff"
					/>
					<path
						d="M3.164,3.164V6.328H6.328V3.164H3.164M0,0H9.492V9.492H0Z"
						transform="translate(829.324 295.602) rotate(-45)"
						fill={ColorValues['gray-2']}
					/>
					<rect
						width="9.492"
						height="9.492"
						transform="translate(614.18 364.214) rotate(-45)"
						fill="#fff"
					/>
					<path
						d="M3.164,3.164V6.328H6.328V3.164H3.164M0,0H9.492V9.492H0Z"
						transform="translate(614.18 364.214) rotate(-45)"
						fill={ColorValues['gray-2']}
					/>
				</g>
			</svg>
		);
	}
);

IconNoResult.displayName = 'IconNoResult';
