import { createContext, FC, memo, PropsWithChildren, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { ColorValues, Theme } from 'Types';

export const ThemeContext = createContext((theme: Theme) => {});

export const ThemeLayout: FC<PropsWithChildren> = memo(({ children }) => {
	const initialTheme: Theme = {
		company: {
			defaultPrimaryColor: ColorValues.primary,
			primaryColor: ColorValues.primary
		},
		agent: {
			defaultPrimaryColor: ColorValues.tertiary,
			primaryColor: ColorValues.tertiary
		},
		defaultPrimaryColor: ColorValues.primary,
		colorGray1: ColorValues['gray-1'],
		colorGray2: ColorValues['gray-2'],
		colorGray3: ColorValues['gray-3'],
		colorGray4: ColorValues['gray-4'],
		colorGray5: ColorValues['gray-5']
	};
	const [theme, setTheme] = useState(initialTheme);

	return (
		<ThemeProvider theme={theme}>
			<ThemeContext.Provider value={setTheme}>
				{children}
			</ThemeContext.Provider>
		</ThemeProvider>
	);
});

ThemeLayout.displayName = 'ThemeLayout';
