import { FC, memo } from 'react';
import styled from 'styled-components';
import { t } from 'i18next';
import { darken } from 'polished';
import { Col, Row } from 'reactstrap';
import { Colors, AgentSetting, Media } from 'Types';
import { useDelayUnmount, useModal, useUpdateAgent } from 'Hooks';
import {
	Button,
	ButtonProps,
	EditButton,
	Editor,
	LoadingSpinner,
	ModalBoxChangeImage
} from 'Elements';
import styles from 'partials/homepage/section/agent-company-overview-section.module.scss';

const PartnerButton = styled((props: ButtonProps) => (
	<Button color={Colors.empty} size="lg" {...props} />
))`
	${({ theme }) =>
		theme &&
		`
		background-color: #fff !important;
		color: #000 !important;
		&:hover {
			color: #000;
			background-color: ${darken(0.07, '#fff')} !important;
		}
	`}
`;

export const AgentCompanyOverviewStandard: FC = memo(() => {
	const [openModal, toggleModal] = useModal();
	const shouldRenderModal = useDelayUnmount(openModal, 350);
	const { setting, isFetching, updateAgentSetting } = useUpdateAgent();

	const handleResetImage = async () => {
		const api_data = {
			companyOverviewImage: '',
			companyOverviewThumbnailImage: ''
		};
		await updateAgentSetting(api_data);
	};

	const handleUploadImage = async (image_data: Media) => {
		const api_data = {
			companyOverviewImage: image_data?.url || '',
			companyOverviewThumbnailImage: image_data?.thumbnailUrl || ''
		};
		await updateAgentSetting(api_data);
	};

	const handleUpdate = async (field: Partial<AgentSetting>) => {
		await updateAgentSetting(field);
	};

	return (
		<Row className="g-0">
			<Col md={7} xl={6} className="order-1 order-md-0">
				<div className="px-3 py-5 ps-lg-0 d-flex flex-column gap-3 justify-content-center h-100">
					<h3 className="text-white h3 mb-0">
						<Editor
							type="text"
							value={
								setting?.companyHeadline ||
								`[${t('placeholder.company-name')}]`
							}
							onSave={(val) =>
								handleUpdate({
									companyHeadline: val
								})
							}
						/>
					</h3>
					<p className="text-white mb-0 fs-base lh-md fw-300">
						<Editor
							type="textarea"
							value={
								setting?.companyOverviewDescription ||
								`[${t(
									'placeholder.company-overview-description'
								)}]`
							}
							onSave={(val) =>
								handleUpdate({
									companyOverviewDescription: val
								})
							}
						/>
					</p>
					<div className="d-flex align-items-center">
						<PartnerButton
							className="rounded-pill px-md-5"
							label={t('button.become-partner') as string}
						/>
					</div>
				</div>
			</Col>
			<Col md={5} xl={6}>
				<div
					role="button"
					className={styles.editable}
					onClick={toggleModal}>
					{isFetching && (
						<div className="d-flex align-items-center justify-content-center h-100">
							<LoadingSpinner />
						</div>
					)}
					{!isFetching && (
						<>
							<img
								src={
									setting?.companyOverviewImage ||
									'/assets/img/default-image.jpg'
								}
								alt={setting?.companyHeadline}
								className={styles.image}
							/>
							<div className="edit-overlay">
								<EditButton className="edit-overlay-icon" />
							</div>
						</>
					)}
				</div>
			</Col>
			{shouldRenderModal && (
				<ModalBoxChangeImage
					name="companyOverviewImage"
					isOpen={openModal}
					title="title.change-image"
					image={setting?.companyOverviewImage || ''}
					onClose={toggleModal}
					onRemove={handleResetImage}
					onImageChange={handleUploadImage}
				/>
			)}
		</Row>
	);
});
AgentCompanyOverviewStandard.displayName = 'AgentCompanyOverviewStandard';
