import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { clx } from 'Utils';
import { names } from 'Constants';
import { Button, CircleCheck } from 'Elements';
import styles from 'partials/payment/payment-result.module.scss';

export const PaymentSuccess = memo(() => {
	const { subscriptions } = names;
	const { t } = useTranslation();
	const navigate = useNavigate();
	const handleNavigate = () => {
		void navigate(`${subscriptions.path}`);
	};
	return (
		<div className={styles.box}>
			<div className={styles.filter} />
			<div className={styles.body}>
				<CircleCheck size="90" className={clx(styles.check, 'mb-3')} />
				<h2 className="text-primary fs-3 mb-3">
					{t('title.payment.success')}
				</h2>
				<p className="lh-md pb-3">{t('text.payment.success')}</p>
				<Button onClick={handleNavigate}>
					{t('button.view-subscriptions')}
				</Button>
			</div>
		</div>
	);
});

PaymentSuccess.displayName = 'PaymentSuccess';
