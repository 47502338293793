import { memo, FC } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useContactPeople, usePrompt } from 'Hooks';
import { Colors, ModalProps, ContactPeopleFormData } from 'Types';
import {
    Button,
    ModalBox,
    ModalBoxFooter,
    SubmitButton,
} from 'Elements';

interface Props extends ModalProps {
    contactGroupId: string;
    onSubmitModal: () => void;
}

export const ModalCreateContactPeople: FC<Props> = memo(
    ({ contactGroupId, isOpen, onSubmitModal, onClose }) => {
        const { t } = useTranslation();
        const descriptionLimit = 450;
        const { createContactPeople } = useContactPeople("", contactGroupId);
        const schema = yup.object().shape({
            emailAddress: yup
                .string()
                .email(t('validation.email.type'))
                .required(t('validation.email.required')),
        });
        const {
            register,
            handleSubmit,
            formState: { errors, isDirty, isSubmitting }
        } = useForm<ContactPeopleFormData>({
            resolver: yupResolver(schema)
        });


        usePrompt(t('forms.leave-screen-massage'), isDirty);


        const onSubmit: SubmitHandler<ContactPeopleFormData> = async (data) => {
            const api_data = {
                ...data,
                contactGroupId: contactGroupId,
            };
            await createContactPeople(api_data, () => onSubmitModal()).then(() => {
                onClose()
            });
        };


        return (
            <ModalBox isOpen={isOpen} onClose={onClose} title={t('forms.contact-people.addNewContactPerson')}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="fullName">
                                    {t('forms.contact-people.enterContactFullName')}
                                </Label>
                                <input
                                    {...register('fullName')}
                                    type="text"
                                    id="fullName"
                                    className="inputbox w-100"
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="emailAddress">
                                    {t('forms.contact-people.enterEmailAddress')}
                                    <small className="ms-1">
                                        ({t('validation.required')})
                                    </small>
                                </Label>
                                <input
                                    {...register('emailAddress')}
                                    type="text"
                                    id="emailAddress"
                                    className="inputbox w-100"
                                />
                                {errors.emailAddress && (
                                    <div className="invalid-feedback d-block">
                                        {errors.emailAddress.message}
                                    </div>
                                )}
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="description">
                                    {t('forms.short-desc')}
                                </Label>
                                <textarea
                                    {...register('description', {
                                        maxLength: descriptionLimit
                                    })}
                                    maxLength={descriptionLimit}
                                    id="description"
                                    className="inputbox w-100"
                                    cols={10}
                                    rows={5}
                                    aria-invalid={!!errors.description}
                                />
                            </FormGroup>
                            <ModalBoxFooter className="justify-content-end pt-3 gap-3">
                                <Button
                                    color={Colors['white-red']}
                                    label={t('button.cancel') as string}
                                    onClick={onClose}
                                />
                                <SubmitButton
                                    isDisable={!isDirty}
                                    isSubmitting={isSubmitting}
                                    label={t('button.save')}
                                    savingLabel={t('button.save')}
                                />
                            </ModalBoxFooter>
                        </Col>
                    </Row>
                </form>
            </ModalBox>
        );
    }
);

ModalCreateContactPeople.displayName = 'ModalCreateContactPeople';
