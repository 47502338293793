import { memo, FC, InputHTMLAttributes, useMemo } from 'react';
import { calculateBrightness, hexToRgb } from 'Utils';
import { FontAwesome } from 'Elements';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
	label: string;
	id: string;
	name: string;
	bgColor: string;
	className?: string;
	disabled?: boolean;
}

export const ColorPicker: FC<Props> = memo(
	({ label, name, id, bgColor, disabled, ...props }) => {
		const getBrightness = useMemo(() => {
			return calculateBrightness(bgColor);
		}, [bgColor]);

		return (
			<div role="button" tabIndex={0}>
				<label
					htmlFor={`${name}_${id}`}
					className="d-flex align-items-center cursor-pointer gap-2"
					aria-disabled={disabled}>
					<span
						style={{
							backgroundColor: bgColor,
							boxShadow: `0 5px 15px ${hexToRgb(bgColor, 0.15)}`
						}}
						className="form-input-color border-2 d-flex align-items-center justify-content-center text-center">
						{props.checked && (
							<FontAwesome icon="check" size="lg" />
						)}
						{!props.checked && (
							<FontAwesome
								icon="eye-dropper"
								size="1x"
								className={
									getBrightness >= 0 &&
									getBrightness <= 155 &&
									bgColor !== '#fff'
										? 'text-gray-1'
										: 'text-gray-4'
								}
							/>
						)}
					</span>
					<span className="flex-grow-1 text-break">{label}</span>
				</label>
				<input
					{...props}
					id={`${name}_${id}`}
					name={name}
					type="color"
					disabled={disabled}
					className="visually-hidden"
				/>
			</div>
		);
	}
);

ColorPicker.displayName = 'ColorPicker';
