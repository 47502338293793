import { SelectOptionIcon } from 'Types';

export const socialList: SelectOptionIcon[] = [
	{
		value: 'facebook',
		label: 'facebook',
		icon: 'facebook'
	},
	{
		value: 'twitter',
		label: 'twitter',
		icon: 'twitter'
	},
	{
		value: 'instagram',
		label: 'instagram',
		icon: 'instagram'
	},
	{
		value: 'youtube',
		label: 'youtube',
		icon: 'youtube'
	},
	{
		value: 'linkedin',
		label: 'linkedin',
		icon: 'linkedin-in'
	},
	{
		value: 'xing',
		label: 'xing',
		icon: 'xing'
	},
	{
		value: 'tiktok',
		label: 'tiktok',
		icon: 'tiktok'
	}
];

export const featureIconList: SelectOptionIcon[] = [
	{
		value: 'person-chalkboard',
		label: 'person-chalkboard',
		icon: 'person-chalkboard'
	},
	{
		value: 'person-arrow-down-to-line',
		label: 'person-arrow-down-to-line',
		icon: 'person-arrow-down-to-line'
	},
	{
		value: 'earth-europe',
		label: 'earth-europe',
		icon: 'earth-europe'
	}
];
