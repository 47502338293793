import { FC, memo } from 'react';
import { NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { clx } from 'Utils';
import { MenuSubItem } from 'Types';
import { FontAwesome } from 'Elements';
import styles from 'partials/sidebar/sidebar-item.module.scss';

interface Props {
	menu: MenuSubItem;
}

export const SidebarSubItem: FC<Props> = memo(({ menu }) => {
	const { t } = useTranslation();
	return (
		<NavItem className={clx(menu.separator && styles.separator)}>
			<NavLink
				end // disable active class on parent menu
				to={menu.to}
				className={({ isActive }) =>
					isActive
						? clx(styles.subitem_active, styles.subitem)
						: styles.subitem
				}>
				<FontAwesome
					icon="circle-small"
					size="2xs"
					swapOpacity
					className={styles.sub_dot}
				/>
				<span className="flex-grow-1">{t(menu.label)}</span>
			</NavLink>
		</NavItem>
	);
});

SidebarSubItem.displayName = 'SidebarSubItem';
