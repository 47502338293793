import { memo, FC, CSSProperties } from 'react';
import { Col } from 'reactstrap';
import { clx } from 'Utils';
import { Card, LoadingMask, SkeletonWrapper } from 'Elements';
import styles from 'partials/card/department-card.module.scss';

interface Props {
	count?: number | string;
}

const Skeleton = memo(({ style }: { style: CSSProperties }) => {
	return (
		<Col sm={6} xxl={4} className="mb-4" style={style}>
			<Card size="sm" className="d-flex flex-column gap-3">
				<div className={styles.shimmerBox}>
					<LoadingMask className={styles.shimmer} />
				</div>
				<div className="d-flex flex-column flex-grow-1 gap-2">
					<LoadingMask className={clx(styles.loading, 'w-40 mb-2')} />
					<LoadingMask className={clx(styles.loading, 'w-100')} />
					<LoadingMask className={clx(styles.loading, 'w-100')} />
					<LoadingMask className={clx(styles.loading, 'w-100')} />
					<div className="d-flex align-items-center justify-content-between mt-auto mb-2">
						<LoadingMask className={clx(styles.loading, 'w-20')} />
						<LoadingMask className={clx(styles.loading, 'w-20')} />
					</div>
				</div>
			</Card>
		</Col>
	);
});

export const DepartmentCardLoading: FC<Props> = ({ count = 3 }) => {
	return (
		<SkeletonWrapper
			skeleton={Skeleton}
			count={count}
			wrapperClassName="row"
		/>
	);
};

Skeleton.displayName = 'Skeleton';
DepartmentCardLoading.displayName = 'DepartmentCardLoading';
