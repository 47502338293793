import { memo, FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import { clx } from 'Utils';
import { Button } from 'Elements';
import { names } from 'Constants';
import { useDispatch } from 'Hooks';
import { resetSubscription } from 'Actions';
import styles from 'partials/payment/payment-result.module.scss';

export const PaymentInvoice: FC = memo(() => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { subscriptions } = names;

	useEffect(() => {
		dispatch(resetSubscription());
	}, [dispatch]);
	return (
		<div className={styles.wrapper}>
			<Container>
				<Row className="justify-content-center">
					<Col md={9} xl={7} xxl={5}>
						<div className={styles.box}>
							<div className={styles.filter} />
							<div className={clx(styles.body, 'gap-4')}>
								<h2 className="text-primary h3 mb-0 lh-base">
									{t('title.payment.waiting-for-payment')}
								</h2>
								<p className="mb-0 fw-400 lh-base">
									{t('text.payment.pay-by-invoice')}
								</p>
								<img
									src="/assets/img/bank.svg"
									alt=""
									className="w-100"
								/>
								<Button
									as="a"
									to={subscriptions.path}
									label={
										t('button.view-subscriptions') as string
									}
								/>
							</div>
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
});

PaymentInvoice.displayName = 'PaymentInvoice';
