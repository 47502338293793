import { memo, FC, useState, useMemo, useEffect } from 'react';
import * as yup from 'yup';
import { isAfter } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { usePrompt } from 'Hooks';
import { getCountries } from 'Data';
import {
	AspectRatio,
	EventFormGeneral,
	EventType,
	GeoAddress,
	Media,
	RadioOption
} from 'Types';
import { formatLocalAddress, formatTimeToCET, getCurrentLocale } from 'Utils';
import {
	CoverController,
	RadioController,
	RichTextController,
	StepNavigation,
	AddressAutoSuggest,
	FormInlineTip,
	EventDatePicker,
	SwitchController
} from 'Elements';
import styles from 'partials/step/step-container.module.scss';

interface Props {
	fields: EventFormGeneral;
	onNext: (data: EventFormGeneral) => void;
}

export const CreateEventGeneral: FC<Props> = memo(({ fields, onNext }) => {
	const defaultImage = {
		url: fields.featuredPhoto,
		thumbnailUrl: fields.featuredPhoto
	};
	const descriptionLimit = 450;
	const { t } = useTranslation();
	const [startDateCET, setStartCET] = useState('');
	const [endDateCET, setEndCET] = useState('');
	const [image, setImage] = useState<Partial<Media>>(defaultImage);
	const countries = useMemo(() => getCountries(), []);
	const currentLocaleLang = getCurrentLocale().lang;
	const valueConfig = {
		shouldDirty: true,
		shouldValidate: true
	};
	const required_validation = 'validation.required';

	const schema = yup.object().shape({
		title: yup.string().required(t('validation.event-title.required')),
		startDate: yup
			.string()
			.nullable()
			.required(t('validation.start-date.required')),
		endDate: yup
			.string()
			.nullable()
			.required(t('validation.end-date.required')),
		meetingLink: yup.string().when('eventType', {
			is: (eventType: number) => eventType === 0 || eventType === 2,
			then: yup.string().required(t('validation.meeting-link.required'))
		}),
		address: yup
			.object()
			.nullable()
			.when('eventType', {
				is: (eventType: number) =>
					eventType === EventType.InPerson ||
					eventType === EventType.Hybrid,
				then: yup
					.object()
					.nullable()
					.required(t('validation.address.required'))
			})
	});

	const {
		watch,
		control,
		register,
		setValue,
		getValues,
		clearErrors,
		handleSubmit,
		formState: { errors, isDirty }
	} = useForm<EventFormGeneral>({
		resolver: yupResolver(schema),
		defaultValues: fields
	});

	usePrompt(t('forms.leave-screen-massage'), isDirty);

	const eventTypeOption: () => RadioOption[] = () => {
		return [
			{
				label: t('forms.event.online'),
				value: 0
			},
			{
				label: t('forms.event.in-person'),
				value: 1
			},
			{
				label: t('forms.event.hybrid'),
				value: 2
			}
		];
	};

	const getAddressFormat = () => {
		const {
			street = '',
			houseNo = '',
			postalCode = '',
			city = '',
			state = ''
		} = fields?.address || {};

		return fields?.address
			? formatLocalAddress(street, houseNo, postalCode, city, state)
			: '';
	};

	const filterPassedTime = (time: Date, type: 'start' | 'end') => {
		const currentDate =
			type === 'end'
				? getValues('startDate')
					? new Date(getValues('startDate'))
					: new Date()
				: new Date();
		const selectedDate = new Date(time);

		return currentDate.getTime() < selectedDate.getTime();
	};

	const onStartDateChange = (val: Date) => {
		if (val) {
			const startDate = val;
			setStartCET(formatTimeToCET(startDate));
			const endDate = new Date(getValues('endDate'));
			if (endDate && isAfter(startDate, endDate)) {
				setValue('endDate', '');
			}
		} else {
			setStartCET('');
		}
	};

	const onEndDateChange = (val: Date) => {
		setEndCET(val ? formatTimeToCET(val) : '');
	};

	const onSelectAddress = (selected_address: GeoAddress) => {
		const { address, position } = selected_address;

		const selectedCountry = countries.find(
			(country) =>
				country.value.toString().toLowerCase() ===
				address?.countryCode.toLowerCase()
		);
		const selectedState = selectedCountry?.states?.find(
			(state) => state.label === address?.countrySubdivision
		);

		const addressData = {
			'address.street': address?.street ?? address?.streetName ?? '',
			'address.houseNo': address?.streetNumber ?? '',
			'address.postalCode': address?.postalCode ?? '',
			'address.city': address?.municipality ?? '',
			'address.country': selectedCountry?.value ?? '',
			'address.state': selectedState?.value ?? '',
			'address.location.latitude': position.lat ?? 0,
			'address.location.longitude': position.lon ?? 0
		};
		Object.entries(addressData).forEach(([key, value]) =>
			// @ts-ignore
			setValue(key, value, valueConfig)
		);

		clearErrors('address');
	};

	const handleImageChange = (image_data: Partial<Media>) => {
		setImage(image_data);
	};

	const onSubmit: SubmitHandler<EventFormGeneral> = (data) => {
		onNext?.({
			...data,
			featuredPhoto: image.url ?? ''
		});
	};

	useEffect(() => {
		const startDate = watch('startDate') || fields?.startDate;
		const endDate = watch('endDate') || fields?.endDate;

		if (startDate) {
			setStartCET(formatTimeToCET(startDate));
		} else {
			setStartCET('');
		}

		if (endDate) {
			setEndCET(formatTimeToCET(endDate));
		} else {
			setEndCET('');
		}
	}, [fields?.startDate, fields?.endDate, watch, currentLocaleLang]);

	return (
		<form
			onSubmit={handleSubmit(onSubmit)}
			className={styles.form}
			noValidate>
			<Row>
				<Col xxl={8}>
					<FormGroup>
						<Label htmlFor="title">
							{t('forms.event.title')}
							<small className="ms-1">
								({t(required_validation)})
							</small>
						</Label>
						<input
							{...register('title')}
							type="text"
							id="title"
							aria-invalid={!!errors.title}
							className="inputbox w-100"
						/>
						{errors.title && (
							<div className="invalid-feedback d-block">
								{errors.title.message}
							</div>
						)}
					</FormGroup>
					<FormGroup className="position-relative">
						<Label htmlFor="shortDescription">
							{t('forms.event.short-desc')}
						</Label>
						<textarea
							{...register('shortDescription', {
								maxLength: descriptionLimit
							})}
							maxLength={descriptionLimit}
							id="shortDescription"
							className="inputbox w-100"
							cols={10}
							rows={5}
						/>
						<div className="position-absolute end-0">
							<small className="ms-auto mt-1">
								{watch('shortDescription')?.length || 0}/
								{descriptionLimit}
							</small>
						</div>
					</FormGroup>
					<FormGroup>
						<Label htmlFor="description">
							{t('forms.event.description')}
						</Label>
						<RichTextController
							control={control}
							name="description"
							hasImage={false}
						/>
					</FormGroup>
					<FormGroup className="w-100 w-lg-70">
						<Label htmlFor="featuredPhoto">
							{t('forms.event.cover')}
						</Label>
						<CoverController
							image={image}
							aspectRatio={AspectRatio['16/9']}
							defaultImage={image.url}
							onRemove={handleImageChange}
							onUpload={handleImageChange}
						/>
					</FormGroup>
					<Row>
						<Col sm={6} lg={12} xl={6}>
							<FormGroup>
								<Label htmlFor="startDate">
									{t('forms.start-date')}
									<small className="ms-1">
										({t(required_validation)})
									</small>
								</Label>
								<EventDatePicker
									name="startDate"
									control={control}
									error={errors.startDate}
									defaultSelected={fields?.startDate}
									filterTime={(time: Date) =>
										filterPassedTime(time, 'start')
									}
									onDateChange={onStartDateChange}
								/>
								{startDateCET && (
									<div className="text-gray-3 fs-small mt-2">
										{startDateCET}
									</div>
								)}
							</FormGroup>
						</Col>
						<Col sm={6} lg={12} xl={6}>
							<FormGroup>
								<Label htmlFor="endDate">
									{t('forms.end-date')}
									<small className="ms-1">
										({t(required_validation)})
									</small>
								</Label>
								<EventDatePicker
									name="endDate"
									control={control}
									minDate={
										watch('startDate')
											? new Date(watch('startDate'))
											: new Date()
									}
									error={errors.endDate}
									filterTime={(time: Date) =>
										filterPassedTime(time, 'end')
									}
									defaultSelected={fields?.endDate}
									onDateChange={onEndDateChange}
								/>
								{endDateCET && (
									<div className="text-gray-3 fs-small mt-2">
										{endDateCET}
									</div>
								)}
							</FormGroup>
						</Col>
						<FormInlineTip tip="forms.event.date-info" />
					</Row>
					<FormGroup>
						<Label htmlFor="title">
							{t('forms.event.event-type')}
							<small className="ms-1">
								({t(required_validation)})
							</small>
						</Label>
						<RadioController
							control={control}
							name="eventType"
							option={eventTypeOption()}
							boxClassName="pt-1"
							radioClassName="d-flex gap-3 pb-1"
							error={errors.eventType}
						/>
					</FormGroup>
					{watch('eventType') !== EventType.Online && (
						<FormGroup>
							<Label htmlFor="address">
								{t('forms.event.address')}
							</Label>
							<AddressAutoSuggest
								defaultQuery={getAddressFormat()}
								onSelectSuggestion={onSelectAddress}
							/>
							{errors.address && (
								<div className="invalid-feedback d-block">
									{errors.address.message}
								</div>
							)}
						</FormGroup>
					)}
					{watch('eventType') !== EventType.InPerson && (
						<FormGroup>
							<Label htmlFor="meetingLink">
								{t('forms.event.url')}
							</Label>
							<input
								{...register('meetingLink')}
								type="text"
								inputMode="url"
								id="meetingLink"
								className="inputbox w-100"
							/>
							{errors.meetingLink && (
								<div className="invalid-feedback d-block">
									{errors.meetingLink.message}
								</div>
							)}
						</FormGroup>
					)}
					<FormGroup>
						<SwitchController
							control={control}
							name="isInternal"
							boxClassName="py-2 mb-2"
							label="forms.event.internal-event"
						/>
						<FormInlineTip tip="forms.event.internal-event-desc" />
					</FormGroup>
					<StepNavigation />
				</Col>
			</Row>
		</form>
	);
});

CreateEventGeneral.displayName = 'CreateEventGeneral';
