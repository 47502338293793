import { memo, FC, CSSProperties } from 'react';
import { clx } from 'Utils';
import { LoadingMask, SkeletonWrapper } from 'Elements';
import styles from 'partials/card/event-invited-card.module.scss';

interface Props {
	count?: number | string;
}

const Skeleton = memo(({ style }: { style: CSSProperties }) => {
	return (
		<div className={styles.card} style={style}>
			<div className="d-flex align-items-center">
				<LoadingMask className={styles.logoBox} />
				<div className="flex-grow-1">
					<LoadingMask
						className={clx(styles.loadingCol, styles.loadingColLg)}
					/>
					<LoadingMask
						className={clx(styles.loadingCol, styles.loadingColMd)}
					/>
				</div>
			</div>
			<div className={styles.meta}>
				<LoadingMask
					className={clx(
						styles.loadingCol,
						styles.loadingColMd,
						'me-auto'
					)}
				/>
				<LoadingMask
					className={clx(styles.loadingCol, styles.loadingColSm)}
				/>
			</div>
		</div>
	);
});

export const EventInvitedCardLoading: FC<Props> = memo(({ count = 8 }) => {
	return <SkeletonWrapper count={count} skeleton={Skeleton} />;
});

Skeleton.displayName = 'Skeleton';

EventInvitedCardLoading.displayName = 'EventInvitedCardLoading';
