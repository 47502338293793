import { cssTransition, toast, ToastOptions } from 'react-toastify';
import { ToastifyMessage } from 'Elements';

// toastify messages default configuration
const slide = cssTransition({
	enter: 'animate__slideIn',
	exit: 'animate__slideOut'
});

/**
 * Display a toast message with the given options.
 * @param message - The message to display in the toast.
 * @param userOptions - (Optional) Additional options to customize the toast.
 * @returns The toast element.
 */
export const toastify = (message: string, userOptions?: ToastOptions) => {
	const uniqueId = Date.now();
	const toastId = uniqueId % 100;
	const defaultOption: ToastOptions = {
		position: 'top-center',
		hideProgressBar: true,
		autoClose: 4500,
		type: toast.TYPE.ERROR,
		pauseOnHover: true,
		draggable: true,
		transition: slide,
		toastId
	};
	const toastifyOption: ToastOptions = { ...defaultOption, ...userOptions };

	const handleClose = () => {
		toast.dismiss(toastId);
	};

	return toast(
		<ToastifyMessage
			message={message}
			onCloseToast={handleClose}
			{...userOptions}
		/>,
		toastifyOption
	);
};
