import { startTransition } from 'react';
import axios from 'Adapter';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { queryKey } from 'Constants';
import { api, fetch, toastify } from 'Utils';
import {
	DataList,
	DataQueryParams,
	ContactGroup,
	ContactGroupFormData
} from 'Types';

const config = (options = {}) => {
	return {
		...options,
		keepPreviousData: false
	};
};

export const useContactGroups = <T = DataList<ContactGroup>>(
	{
		isActive = '',
		pageSize = 10,
		pageNumber = 1,
		keyword = ''
	}: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<ContactGroup>>
): UseQueryResult<DataList<ContactGroup>, unknown> => {
	return useQuery(
		{
			queryKey: [queryKey.contactGroup, { isActive, pageSize, pageNumber, keyword }],
			queryFn: () => fetch.listContactGroup(isActive, pageSize, pageNumber, keyword),
			...config(options)
		}
	);
};

export const useContactGroupDetails = <T = ContactGroup>(
	contactGroupId: string,
	options?: UseQueryOptions<T, any, ContactGroup>
): UseQueryResult<ContactGroup, unknown> => {
	return useQuery(
		{
			queryKey: [queryKey.contactGroup, { id: contactGroupId }],
			queryFn: () => fetch.contactGroupDetails(contactGroupId),
			...config(options)
		}
	);
};


export const useContactGroup = (contactGroupId?: string) => {
	const query = useContactGroupDetails(contactGroupId as string, {
		enabled: !!contactGroupId
	});
	const contactGroup = query.data;

	const createContactGroup: (
		fields: ContactGroupFormData,
		onFinal?: () => void
	) => Promise<void> = async (fields, onFinal) => {
		await axios.post(api.createContactGroup, fields).then((res) => {
			if (res.status === 200) {
				startTransition(() => {
					onFinal?.();
				});
			}
		});
	};


	const updateContactGroup: (
		contactGroupId: string,
		fields: ContactGroupFormData,
		onFinal?: () => void
	) => Promise<void> = async (contactGroupId, fields, onFinal) => {
		const data = {
			...contactGroup,
			...fields
		};
		await axios
			.put(api.updateContactGroupDetails(contactGroupId as string), data)
			.then((res) => {
				if (res.status === 200) {
					query.refetch();
					startTransition(() => {
						toastify('toastify.contact-group.updated', {
							type: 'success'
						});
					});
					onFinal?.();
				}
			});
	};

	const removeContactGroup: (
		contactGroupId: string,
		onFinal: () => void
	) => Promise<void> = async (contactGroupId, onFinal) => {
		await axios.delete(api.removeContactGroup(contactGroupId)).then((res) => {
			if (res.status === 200) {
				onFinal?.();
				startTransition(() => {
					toastify('toastify.contact-group.removed', {
						type: 'success'
					});
				});
			}
		});
	};

	return {
		...query,
		removeContactGroup,
		updateContactGroup,
		createContactGroup
	};
};