import { memo, FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Colors, Tab, InternshipRegistration } from 'Types';
import { format } from 'date-fns';
import { getCurrentLocale, clx } from 'Utils';
import { FontAwesome, Button, ListTab, ShimmerImage } from 'Elements';
import '../../../assets/css/internship-management.css';

import Drawer from 'react-modern-drawer';
import styles from 'partials/shared/page.module.scss';

//import styles 👇
import 'react-modern-drawer/dist/index.css';

interface Props {
	isOpen?: boolean;
	dataSelected: any;
	toggleDrawer?: () => void;
}

export const MoreTerms: FC<Props> = memo(
	({ isOpen, toggleDrawer, dataSelected }) => {
		const defaultImage = '/assets/img/profiles/img-user-default.png';
		const { t } = useTranslation();
		const navigate = useNavigate();
		const [activeTab, setActiveTab] = useState(0);
		const [data, setData] = useState<any>([]);
		const [totalTerm, setTotalTerm] = useState<any>(0);
		const [totalMember, setTotalMember] = useState<any>(0);

		const [tabs, setTabs] = useState<Tab[]>([]);
		const switchList = (tabItem: Tab) => {
			const updatedTabs = tabs.map((tab) => ({
				...tab,
				isActive: tab.id === tabItem.id
			}));
			setTabs(updatedTabs);
			setActiveTab(tabItem.id);
		};
		const getLocalFormat = () => {
			const currentLocale = getCurrentLocale().code;
			if (currentLocale === 'de') {
				return 'dd.MM.yyyy';
			}
			return 'yyyy-MM-dd';
		};

		useEffect(() => {
			if (isOpen) {
				setData(dataSelected);
			}
		}, [isOpen]);

		return (
			<Drawer
				open={isOpen as never}
				onClose={() => {
					setData([]);
					toggleDrawer && toggleDrawer();
				}}
				lockBackgroundScroll
				zIndex={100}
				direction="right"
				size="33rem">
				<div className="d-flex align-items-center justify-content-between py-3 px-3 border-bottom border-gray-1 gap-2 ">
					<div>
						<span className="fs-6 fw-600">
							{t('forms.session-info')}
						</span>
					</div>
					<div className="d-flex align-items-center gap-2">
						<Button
							size="sm"
							color={Colors['white-primary']}
							label={t('button.go-to-internship') as string}
							onClick={() => {
								navigate(
									`/internships/${dataSelected?.internshipId}`
								);
							}}
						/>
						<Button
							size="sm"
							color={Colors['white-gray']}
							label={t('button.close') as string}
							onClick={() => {
								setData([]);
								toggleDrawer && toggleDrawer();
							}}
						/>
					</div>
				</div>
				<div className="py-3 px-3">
					<h5 className="fw-500 mb-2">{data?.title}</h5>
					<p className="fs-base mb-2 border-bottom border-gray-1 pb-4">
						{data?.shortDescription}
					</p>
					<div style={{ overflow: 'auto', height: '40rem' }}>
						{data?.term?.sessions?.map((s, index) => {
							const startDate: any = new Date(
								Date.parse(s?.sessionStartDate)
							);
							const endDate: any = new Date(
								Date.parse(s?.sessionEndDate)
							);
							return (
								<div
									key={index}
									className="mb-2 pb-2 border-bottom border-gray-1 ">
									<div className="fs-small fw-400 mb-2">{`${t(
										'forms.session'
									)} ${index + 1}`}</div>
									<div className="d-flex align-items-center gap-2">
										<div className="fs-sm fw-500">
											{format(
												new Date(s?.sessionStartDate),
												`EEEE ${getLocalFormat()}`
											)}
										</div>
										<div className="fs-sm fw-500">
											{startDate.toLocaleTimeString([], {
												hour: '2-digit',
												minute: '2-digit',
												hour12: false
											})}
										</div>
										<div className="fs-sm fw-500">-</div>
										<div className="fs-sm fw-500">
											{endDate.toLocaleTimeString([], {
												hour: '2-digit',
												minute: '2-digit',
												hour12: false
											})}
										</div>
									</div>
								</div>
							);
						})}
					</div>
				</div>
			</Drawer>
		);
	}
);

MoreTerms.displayName = 'MoreTerms';
