import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxController } from 'Elements';
import { ContactGroup } from 'Types';

interface Props {
	card: ContactGroup;
	onClick: (e: string, check: boolean) => void;
	isChecked: boolean;
}

export const InviteContactGroupCard: FC<Props> = memo(
	({ card, isChecked, onClick }) => {
		const { t } = useTranslation();

		return (
			<div
				className="border-bottom py-2 cursor-pointer"
				onClick={() => {
					onClick(card.id, isChecked);
				}}>
				<div className="d-flex gap-2 align-items-center">
					<CheckboxController
						name={card.id}
						id={card.id}
						isChecked={isChecked}
						label=""
						onInputChange={(e) => {
							onClick(card.id, e);
						}}
					/>
					<div className="flex-grow-1">
						<h2 className="fs-sm lh-base text-break mb-0">
							{card.title}
						</h2>
						<span className="text-gray-3 fs-small">{`${
							card.contactPersonCount
						} ${t('label.contacts')}`}</span>
					</div>
				</div>
			</div>
		);
	}
);

InviteContactGroupCard.displayName = 'InviteContactGroupCard';
