import { memo, Suspense } from 'react';
import { Col, Row } from 'reactstrap';
import { Outlet } from 'react-router-dom';
import { CompanyDecorationBox, LoadingContent } from 'Elements';

export const UpdateCompany = memo(() => {
	return (
		<Row>
			<Col xs={12} className="mb-4">
				<CompanyDecorationBox />
			</Col>
			<Col xs={12}>
				<Suspense fallback={<LoadingContent />}>
					<Outlet />
				</Suspense>
			</Col>
		</Row>
	);
});

UpdateCompany.displayName = 'UpdateCompany';
