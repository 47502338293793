import { CSSProperties, FC, memo } from 'react';
import { clx, uid } from 'Utils';
import { DataTableRow, DataTableRowCell, LoadingMask } from 'Elements';
import styles from 'partials/data-table/data-table.module.scss';

interface Props {
	count?: number;
	widths?: number[];
}

export const DataTableLoading: FC<Props> = memo(({ count = 5, widths }) => {
	const renderLoading = () => {
		const loading: JSX.Element[] = [];
		for (let i = +count; i > 0; i--) {
			loading.push(
				<Skeleton
					key={i}
					widths={widths}
					style={{ opacity: i / +count }}
				/>
			);
		}
		return loading;
	};

	return <>{renderLoading()}</>;
});

const Skeleton = ({
	style,
	widths = [25, 25, 25, 25]
}: {
	style: CSSProperties;
	widths?: number[];
}) => {
	// Create row cell based on each width count
	const renderChildren = () => {
		const child: JSX.Element[] = [];
		for (const width of widths) {
			child.push(
				<DataTableRowCell
					key={uid()}
					cellClassName={clx(
						styles.loadingBox,
						`w-100 w-sm-${width}`
					)}
					boxClassName="w-100">
					<LoadingMask className={styles.loadingRow} />
				</DataTableRowCell>
			);
		}
		return child;
	};

	return (
		<DataTableRow
			className={clx(styles.row, 'align-items-center')}
			style={style}>
			<>{renderChildren()}</>
		</DataTableRow>
	);
};

DataTableLoading.displayName = 'DataTableLoading';
