import {
	useQuery,
	useQueries,
	UseQueryResult,
	UseQueryOptions
} from 'react-query';
import { queryKey } from 'Constants';
import { fetch, getCurrentLocale } from 'Utils';
import { Permission, PermissionGraph } from 'Types';

const config = (options = {}) => {
	return {
		...{ keepPreviousData: false },
		...options
	};
};

export const usePermissionGraph = () => {
	return useQueries([
		{
			queryKey: queryKey.permissions,
			queryFn: () => fetch.permissions(getCurrentLocale()?.code),
			staleTime: Infinity
		},
		{
			queryKey: queryKey.graph,
			queryFn: fetch.permissionsGraph,
			staleTime: Infinity
		}
	]) as [
			UseQueryResult<Permission>,
			UseQueryResult<PermissionGraph[]>
		];
};

export const usePermission = <T = Permission>(
	options?: UseQueryOptions<T, any, Permission>
): UseQueryResult<Permission, unknown> => {
	return useQuery(
		{
			queryKey: [queryKey.permissions],
			queryFn: () => fetch.permissions(getCurrentLocale()?.code),
			...config(options)
		}
	);
};

export const useGraph = <T = PermissionGraph>(
	options?: UseQueryOptions<T, any, PermissionGraph>
): UseQueryResult<PermissionGraph, unknown> => {
	return useQuery(
		{
			queryKey: [queryKey.graph],
			queryFn: () => fetch.permissionsGraph(),
			...config(options)
		}
	);
};
