import { memo, useState, startTransition, useEffect } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { AspectRatio, EmployeeForm, Media } from 'Types';
import { useModal, usePrompt, useUpdateEmployee } from 'Hooks';
import {
	Card,
	SubmitButtonGroup,
	SwitchController,
	AvatarController,
	LoadingContent,
	ModalBoxDelete
} from 'Elements';

export const UpdateEmployee = memo(() => {
	const descriptionLimit = 270;
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { employee_id } = useParams();
	const [openModal, toggleModal] = useModal();
	const [isRemoving, setIsRemoving] = useState(false);

	const {
		isFetching,
		employee: fields,
		updateEmployee,
		updateEmployeeImage,
		removeEmployee
	} = useUpdateEmployee(employee_id as string);

	const schema = yup.object({
		employeeName: yup
			.string()
			.required(t('validation.employee-name.required'))
	});

	const {
		register,
		control,
		reset,
		watch,
		handleSubmit,
		formState: { errors, isDirty, isSubmitting }
	} = useForm<EmployeeForm>({
		resolver: yupResolver(schema)
	});

	usePrompt(t('forms.leave-screen-massage'), isDirty);

	const handleRemoveImage = async () => {
		await updateEmployeeImage({
			url: '',
			thumbnailUrl: ''
		});
	};

	const handleRemoveUser = async () => {
		try {
			setIsRemoving(true);
			await removeEmployee(() => {
				startTransition(() => {
					setIsRemoving(false);
					void navigate('/employees');
				});
			});
		} finally {
			setIsRemoving(false);
		}
	};

	const handleUploadImage = async (image_data: Media) => {
		await updateEmployeeImage(image_data);
	};

	const onSubmit: SubmitHandler<EmployeeForm> = async (data) => {
		await updateEmployee(data);
	};

	useEffect(() => {
		reset({
			...fields,
			description: fields?.description.substring(0, descriptionLimit)
		});
	}, [reset, fields]);

	return (
		<Card>
			<Row>
				<Col sm={5} xl={4}>
					<FormGroup>
						<AvatarController
							name="image"
							aspectRatio={AspectRatio['3/4']}
							control={control}
							image={fields?.image}
							onRemove={handleRemoveImage}
							onUpload={handleUploadImage}
						/>
					</FormGroup>
				</Col>
				<Col sm={7} xl={8}>
					<form onSubmit={handleSubmit(onSubmit)} noValidate>
						<Row>
							<Col xl={9} xxl={7}>
								<FormGroup>
									<Label htmlFor="employeeName">
										{t('forms.employee-name')}
										<small className="ms-1">
											({t('validation.required')})
										</small>
									</Label>
									<input
										{...register('employeeName')}
										type="text"
										id="employeeName"
										aria-invalid={!!errors.employeeName}
										className="inputbox w-100"
									/>
									{errors.employeeName && (
										<div className="invalid-feedback d-block">
											{errors.employeeName.message?.toString()}
										</div>
									)}
								</FormGroup>
								<FormGroup>
									<Label htmlFor="jobTitle">
										{t('forms.job-title')}
									</Label>
									<input
										{...register('jobTitle')}
										type="text"
										id="jobTitle"
										className="inputbox w-100"
									/>
								</FormGroup>
								<FormGroup className="position-relative">
									<Label htmlFor="description">
										{t('forms.company.employee-desc')}
									</Label>
									<textarea
										{...register('description', {
											maxLength: descriptionLimit
										})}
										id="description"
										className="inputbox w-100"
										cols={10}
										rows={5}
										aria-invalid={!!errors.description}
										maxLength={descriptionLimit}
									/>
									<div className="position-absolute end-0">
										<small className="ms-auto mt-1">
											{watch('description')?.length || 0}/
											{descriptionLimit}
										</small>
									</div>
								</FormGroup>
								<FormGroup>
									<SwitchController
										name="showInHomePage"
										control={control}
										label="forms.show-in-home"
									/>
								</FormGroup>
								<SubmitButtonGroup
									isDisable={!isDirty}
									isSubmitting={isSubmitting}
									onRemove={toggleModal}
									removeTitle="button.remove-user"
									isRemoving={isRemoving}
								/>
							</Col>
						</Row>
					</form>
				</Col>
			</Row>
			<ModalBoxDelete
				isOpen={openModal}
				onClose={toggleModal}
				onRemove={handleRemoveUser}
				isRemoving={isRemoving}
				title={fields?.employeeName ?? ''}
			/>
			{isFetching && <LoadingContent />}
		</Card>
	);
});

UpdateEmployee.displayName = 'UpdateEmployee';
