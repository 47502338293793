import { memo, FC, useState } from 'react';
import { setSubscriptionPlan } from 'Actions';
import { SubscriptionPlan } from 'Types';
import { useDispatch, useSubscriptionPlans } from 'Hooks';
import {
	LoadingSpinner,
	NoData,
	Pagination,
	StepNavigation,
	SubscriptionPlanCard,
	SystemErrorAlert
} from 'Elements';
import styles from 'partials/step/step-container.module.scss';
import { useTranslation } from 'react-i18next';

interface Props {
	fields: SubscriptionPlan | null;
	onPrev: () => void;
	onNext: () => void;
}

export const CreateSubscriptionPlan: FC<Props> = memo(
	({ fields, onPrev, onNext }) => {
		const { t } = useTranslation();
		const dispatch = useDispatch();
		const [currentPage, setCurrentPage] = useState(1);
		const {
			data: planList,
			isLoading,
			isError,
			error
		} = useSubscriptionPlans({
			pageSize: 5,
			pageNumber: currentPage,
			keyword: ''
		});
		const errorData = error?.response?.data;
		const noActiveMainAbo = errorData?.detail === '2110';

		const handleSelectPlan = (plan: SubscriptionPlan) => {
			dispatch(setSubscriptionPlan(plan));
			onNext();
		};

		const handlePageChange = (page: number) => {
			setCurrentPage(page);
		};

		return (
			<div className={styles.form}>
				{!isLoading &&
					planList?.items?.map((plan) => {
						return (
							<SubscriptionPlanCard
								key={plan.id}
								card={plan}
								selected={fields?.id === plan.id}
								onSelect={handleSelectPlan}
							/>
						);
					})}
				{isLoading && (
					<div className="d-flex align-items-center justify-content-center mt-5 mb-5">
						<LoadingSpinner />
					</div>
				)}
				{!isLoading && planList?.items?.length === 0 && (
					<NoData message="title.no-subscription-plan" />
				)}
				{!isLoading && isError && noActiveMainAbo && (
					<NoData message="title.no-subscription-plan">
						<p className="mb-0 lh-md w-xl-70">{t('error.2110')}</p>
					</NoData>
				)}
				{isError && !noActiveMainAbo && <SystemErrorAlert />}
				{!isLoading && planList && planList?.totalPages > 1 && (
					<Pagination
						totalItemsCount={planList?.totalItems}
						activePage={parseInt(currentPage.toString(), 10)}
						itemsCountPerPage={5}
						onChange={handlePageChange}
					/>
				)}
				{!isLoading && (
					<StepNavigation onPrev={onPrev} showNext={false} />
				)}
			</div>
		);
	}
);

CreateSubscriptionPlan.displayName = 'CreateSubscriptionPlan';
