import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { clx } from 'Utils';
import { Card, LoadingContent } from 'Elements';
import { useTotalJobStatistics } from 'Hooks';
import styles from 'partials/page/dashboard.module.scss';

export const DashboardJobTotal: FC = memo(() => {
	const { t } = useTranslation();
	const { data, isLoading } = useTotalJobStatistics();

	return (
		<Card background="tertiary_o-1" className="shadow-none p-3">
			<h3 className="mb-0 lh-base fs-base">
				{t('title.job.job-status-overview')}
			</h3>
			<div className={clx(styles.grid, 'mt-3')}>
				<div className="d-flex align-items-center gap-2 pe-1 text-secondary">
					<label
						className="fw-300 text-truncate"
						title="{t('label.job.draft')}">
						{t('label.job.draft')}:
					</label>
					<span className="fw-500 ms-auto">
						{data?.totalDraft ?? '---'}
					</span>
				</div>
				<div className="d-flex align-items-center gap-2 pe-1 text-tertiary">
					<label
						className="fw-300 text-truncate"
						title={t('label.job.published')}>
						{t('label.job.published')}:
					</label>
					<span className="fw-500 ms-auto">
						{data?.totalPublished ?? '---'}
					</span>
				</div>
				{/* <div className="d-flex align-items-center gap-2 pe-1">
					<label
						className="fw-300 text-truncate"
						title={t('label.job.auto-published')}>
						{t('label.job.auto-published')}:
					</label>
					<span className="fw-500 ms-auto">
						{data?.totalAutoPublished ?? '---'}
					</span>
				</div> */}
				<div className="d-flex align-items-center gap-2 pe-1 text-muted">
					<label
						className="fw-300 text-truncate"
						title={t('label.job.expired')}>
						{t('label.job.expired')}:
					</label>
					<span className="fw-500 ms-auto">
						{data?.totalExpired ?? '---'}
					</span>
				</div>
				<div className="d-flex align-items-center gap-2 pe-1">
					<label
						className="fw-300 text-truncate"
						title={t('label.job.unPublished')}>
						{t('label.job.unPublished')}:
					</label>
					<span className="fw-500 ms-auto">
						{data?.totalUnpublished ?? '---'}
					</span>
				</div>
			</div>
			{isLoading && <LoadingContent />}
		</Card>
	);
});

DashboardJobTotal.displayName = 'DashboardJobTotal';
