import { FC, memo } from 'react';
import { clx } from 'Utils';
import { useModal } from 'Hooks';
import { AspectRatio, Colors, Media } from 'Types';
import { Button, CoverBox, FontAwesome, ModalBoxChangeImage } from 'Elements';
import styles from 'partials/control/cover-controller.module.scss';

interface Props {
	name?: string;
	className?: string;
	defaultImage?: string;
	uploadLabel?: string;
	image: Partial<Media>;
	aspectRatio?: AspectRatio;
	onUpload: (image_data: Media) => void;
	onRemove?: (defaultImage: Partial<Media>) => void;
}

export const CoverController: FC<Props> = memo(
	({
		image,
		name,
		className,
		uploadLabel,
		defaultImage,
		aspectRatio,
		onUpload,
		onRemove
	}) => {
		const [openModal, toggleModal] = useModal();

		const handleRemove = () => {
			const defaultImage = {
				url: '',
				thumbnailUrl: ''
			};
			onRemove?.(defaultImage);
		};

		return (
			<div className={clx(styles.control, className, 'gap-3')}>
				<CoverBox
					image={image}
					name={name}
					message={uploadLabel}
					defaultImage={defaultImage}
					onClick={toggleModal}
				/>
				{image.url && (
					<Button
						color={Colors['white-primary']}
						className={styles.edit}
						onClick={toggleModal}>
						<FontAwesome icon="pen-to-square" size="xl" />
					</Button>
				)}
				<ModalBoxChangeImage
					name={name || ''}
					title="title.change-image"
					isOpen={openModal}
					image={image?.url || ''}
					onClose={toggleModal}
					aspectRatio={aspectRatio}
					onImageChange={onUpload}
					onRemove={onRemove && handleRemove}
				/>
			</div>
		);
	}
);

CoverController.displayName = 'CoverController';
