import { UserType } from "Types";

export const getUserTypeById = (userId: UserType) => {
	const types = {
		4: {
			title: 'card.owner',
			type: 'owner'
		},
		8: {
			title: 'card.b2b-user',
			type: 'b2b-user'
		}
	};
	return types[userId as keyof typeof types];
};

export const getUserType = (userType: string) => {
	const types = {
		B2BAdmin: {
			id: 4,
			title: 'card.owner',
			type: 'owner'
		},
		B2BUser: {
			id: 8,
			title: 'card.b2b-user',
			type: 'b2b-user'
		}
	};
	return userType ? types[userType as keyof typeof types] : {};
};
