import { memo, FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { AssetConfigType } from 'Types';
import styles from 'partials/marketing/toolbox-config.module.scss';

interface Props {
	children: ReactNode;
	title?: string;
	isRequired?: boolean;
	errors?: AssetConfigType;
}

export const ToolboxConfigGroup: FC<Props> = memo(
	({ title, children, isRequired, errors }) => {
		const { t } = useTranslation();
		return (
			<div className={styles.filter_section}>
				{title && (
					<h6 className="fs-sm mb-3">
						{title}
						{isRequired && (
							<small className="ms-1">
								({t('validation.required')})
							</small>
						)}
					</h6>
				)}
				{children}
				{errors && (
					<div className="invalid-feedback d-block">{errors}</div>
				)}
			</div>
		);
	}
);

ToolboxConfigGroup.displayName = 'ToolboxConfigGroup';
