import { startTransition } from 'react';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { useAxios } from 'Hooks';
import { queryKey } from 'Constants';
import { api, fetch, toastify } from 'Utils';
import {
	AgentSetting,
	DataList,
	DataQueryParams,
	AgentPartner,
	AgentType,
	AgentProfileFormData,
	AgentPlan,
	AgentPlanForm,
	AgentParentAssociationList
} from 'Types';

const config = (options = {}) => {
	return {
		...options,
		staleTime: Infinity
	};
};

export const useAgentProfile = <T = AgentProfileFormData>(
	options?: UseQueryOptions<T, any, AgentProfileFormData>
): UseQueryResult<AgentProfileFormData, unknown> => {
	return useQuery({
		queryKey: [queryKey.agentProfile],
		queryFn: () => fetch.agentProfile(),
		...config(options)
	}
	);
};


export const useAgentPartnersArea = <T = AgentParentAssociationList[]>(
	parentAssociationId: string,
	options?: UseQueryOptions<T, any, AgentParentAssociationList[]>
): UseQueryResult<AgentParentAssociationList[]> => {
	return useQuery({
		queryKey: [queryKey.agentPartnersArea, { parentAssociationId }],
		queryFn: () => fetch.agentPartnerAreaList(parentAssociationId),
		...config(options)
	});
};

export const useAgentPartners = <T = DataList<AgentPartner>>(
	{
		pageSize = 12,
		pageNumber = 1,
		keyword = '',
		agentApprovalStatus = ''
	}: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<AgentPartner>>
): UseQueryResult<DataList<AgentPartner>, unknown> => {
	return useQuery(
		{
			queryKey: [
				queryKey.agentPartners,
				{ pageSize, pageNumber, keyword, agentApprovalStatus }
			],
			queryFn: () =>
				fetch.agentPartners(
					pageSize,
					pageNumber,
					keyword,
					agentApprovalStatus
				),
			...config(options)
		}
	);
};

export const useUpdateAgent = (
	options?: UseQueryOptions<AgentProfileFormData>
) => {
	const query = useAgentProfile(options);
	const agent = query.data;
	const setting = agent?.agentSetting;
	const address = agent?.address;
	const { sendRequest, error } = useAxios();

	const updateAgent: (
		fields: Partial<AgentProfileFormData>,
		onFinal?: () => void,
		onCatch?: () => void
	) => Promise<void> = async (fields, onFinal, onCatch) => {
		const data = {
			...agent,
			...fields
		};
		await sendRequest(
			api.updateAgentProfile,
			{ data, method: 'PUT' },
			() => {
				query.refetch();
				startTransition(() => {
					toastify('toastify.agent-updated', {
						type: 'success'
					});
				});
				onFinal?.();
			}
		);
		if (error) onCatch?.();
	};

	const updateAgentSetting: (
		field: Partial<AgentSetting>,
		onFinal?: () => void
	) => Promise<void> = async (field, onFinal) => {
		const data = {
			agentSetting: {
				...setting,
				...field
			}
		};
		await sendRequest(
			api.updateAgentSetting,
			{ data, method: 'PATCH' },
			() => {
				query.refetch();
				startTransition(() => {
					toastify('toastify.agent-updated', {
						type: 'success'
					});
				});
				onFinal?.();
			}
		);
	};

	const deleteAgentRequest: (onFinal?: () => void) => Promise<void> = async (
		onFinal
	) => {
		await sendRequest(
			api.deleteAgentAccountRequest,
			{ method: 'DELETE' },
			onFinal
		);
	};

	const deleteAgentAccount: (
		fields: string,
		onFinal?: () => void
	) => Promise<void> = async (fields, onFinal) => {
		const data = { confirmationCode: fields };
		await sendRequest(
			api.deleteAgentAccount,
			{ data, method: 'DELETE' },
			onFinal
		);
	};

	return {
		...query,
		agent,
		setting,
		address,
		updateAgent,
		// updateAgentPlanPrice,
		updateAgentSetting,
		deleteAgentRequest,
		deleteAgentAccount
	};
};

export const useAgentTypes = <T = AgentType[]>(
	options?: UseQueryOptions<T, any, AgentType[]>
): UseQueryResult<AgentType[]> => {
	return useQuery({
		queryKey: [queryKey.agentTypes],
		queryFn: () => fetch.agentTypes(),
		...config(options)
	});
};

export const useAgentParentAssociation = <T = AgentParentAssociationList[]>(
	partnerClass: number,
	options?: UseQueryOptions<T, any, AgentParentAssociationList[]>
): UseQueryResult<AgentParentAssociationList[]> => {
	return useQuery({
		queryKey: [queryKey.agentProfile, { partnerClass }],
		queryFn: () => fetch.agentParentAssociationList(partnerClass),
		...config(options)
	});
};

export const useAgentPlans = <T = DataList<AgentPlan>>(
	{ pageSize, pageNumber, keyword }: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<AgentPlan>>
): UseQueryResult<DataList<AgentPlan>> => {
	return useQuery({
		queryKey: [queryKey.agentPlans, { pageSize, pageNumber, keyword }],
		queryFn: () => fetch.agentPlanList(pageSize, pageNumber, keyword),
		...config(options)
	});
};

export const useAgentPlanDetails = <T = AgentPlan>(
	agentPlanId: string,
	options?: UseQueryOptions<T, any, AgentPlan>
): UseQueryResult<AgentPlan> => {
	return useQuery({
		queryKey: [queryKey.agentPlan, { agentPlanId }],
		queryFn: () => fetch.agentPlanDetails(agentPlanId),
		...config(options)
	}
	);
};

export const useAgentProfilePlan = <T = AgentPlanForm>(
	options?: UseQueryOptions<T, any, AgentPlanForm>
): UseQueryResult<AgentPlanForm> => {
	return useQuery({
		queryKey: [queryKey.agentProfilePlan],
		queryFn: () => fetch.agentProfilePlan(),
		...config(options)
	}
	);
};

export const useCRUDAgentPlan = (options?: UseQueryOptions<AgentPlanForm>) => {
	const { sendRequest } = useAxios();
	const query = useAgentProfilePlan(options);

	const updateAgentPlan: (
		fields: AgentPlanForm,
		onFinal?: () => void
	) => Promise<void> = async (fields, onFinal) => {
		await sendRequest(
			api.updateAgentProfilePlan,
			{ data: fields, method: 'PATCH' },
			() => {
				void query.refetch();
				startTransition(() => {
					toastify('toastify.plan-price.updated', {
						type: 'success'
					});
				});
				onFinal?.();
			}
		);
	};

	return { ...query, updateAgentPlan };
};
