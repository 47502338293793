import { rgba } from 'polished';
import Select, { CSSObjectWithLabel, GroupBase, Props } from 'react-select';
import { clx } from 'Utils';
import { ColorValues } from 'Types';

const customStyles = {
	container: (provided: CSSObjectWithLabel) => ({
		...provided,
		minHeight: 40,
		height: '100% !important'
	}),
	option: (provided: CSSObjectWithLabel, state: any) => ({
		marginRight: 16,
		marginLeft: 16,
		fontSize: state.isSelected ? 14 : 13,
		paddingTop: 10,
		paddingBottom: 10,
		minHeight: 40,
		cursor: 'pointer',
		color: state.isSelected ? ColorValues.primary : ColorValues['gray-5'],
		fontWeight: state.isSelected ? 500 : 400,
		':not(:last-child)': {
			borderBottom: `1px solid ${ColorValues['gray-2']}`
		}
	}),
	control: () => ({
		display: 'flex',
		alignItems: 'center',
		width: '100%'
	}),
	singleValue: (provided: CSSObjectWithLabel, state: any) => {
		const opacity = state.isDisabled ? 0.5 : 1;
		const transition = 'opacity 300ms';
		const marginLeft = 0;
		const marginRight = 0;

		return { ...provided, marginRight, marginLeft, opacity, transition };
	},
	multiValue: (provided: CSSObjectWithLabel) => {
		return {
			...provided,
			backgroundColor: `${rgba(ColorValues.primary, 0.2)}`,
			margin: '5px 10px 5px 0',
			borderRadius: 5,
			zIndex: 2
		};
	},
	multiValueLabel: (provided: CSSObjectWithLabel) => {
		return {
			...provided,
			padding: '4px 8px !important',
			color: ColorValues.primary
		};
	},
	multiValueRemove: (provided: CSSObjectWithLabel) => {
		return {
			...provided,
			color: ColorValues.red,
			'&:hover': {
				borderRadius: '0 5px 5px 0',
				backgroundColor: `${rgba(ColorValues.red, 0.2)}`
			}
		};
	},
	valueContainer: () => ({
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'wrap',
		padding: '0 1rem',
		minHeight: 40,
		flex: 1,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		'input[aria-readonly]': {
			display: 'none'
		}
	}),
	menuList: () => ({
		paddingBottom: 4,
		paddingTop: 4,
		maxHeight: 300,
		overflowY: 'auto'
	}),
	inputContainer: (provided: CSSObjectWithLabel) => {
		return {
			...provided,
			position: 'relative'
		};
	},
	input: () => ({
		margin: 0,
		padding: 0,
		display: 'flex'
	}),
	menu: (provided: CSSObjectWithLabel) => ({
		...provided,
		backgroundColor: ColorValues['gray-1'],
		borderRadius: 10,
		boxShadow: '0 3px 6px -2px rgb(0 0 0 / 10%)',
		marginBottom: 8,
		marginTop: 8,
		zIndex: 20,
		overflow: 'hidden'
	}),
	indicatorsContainer: (provided: CSSObjectWithLabel) => {
		return {
			...provided,
			zIndex: 2
		};
	}
};

declare module 'react-select/dist/declarations/src/Select' {
	export interface Props<
		Option,
		IsMulti extends boolean,
		Group extends GroupBase<Option>
	> {}
}

export const ReactSelect = <
	Option,
	IsMulti extends boolean = false,
	Group extends GroupBase<Option> = GroupBase<Option>
>(
	selectProps: Props<Option, IsMulti, Group>
) => {
	return (
		<Select
			{...selectProps}
			classNamePrefix="react-select"
			className={clx('react-select inputbox p-0', selectProps.className)}
			// @ts-ignore
			styles={customStyles}
		/>
	);
};

ReactSelect.displayName = 'ReactSelect';
