import { FC, memo, ReactNode } from 'react';
import PerfectScrollbar from 'perfect-scrollbar';
import ReactPerfectScrollbar, { ScrollBarProps } from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';

interface Props extends ScrollBarProps {
	children: ReactNode;
	options?: PerfectScrollbar.Options;
}

export const Scrollbar: FC<Props> = memo(
	({ children, options = {}, ...props }) => {
		const scrollbar_options = {
			...{
				suppressScrollX: true,
				wheelPropagation: true
			},
			...options
		};
		return (
			<ReactPerfectScrollbar options={scrollbar_options} {...props}>
				{children}
			</ReactPerfectScrollbar>
		);
	}
);

Scrollbar.displayName = 'Scrollbar';
