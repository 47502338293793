import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import styles from 'partials/feature/event-counter-box.module.scss';

interface Props {
	title: string;
	count: number;
	total: string;
}

export const EventCounterBox: FC<Props> = memo(({ title, count, total }) => {
	const { t } = useTranslation();

	return (
		<div className={styles.box}>
			<div
				className={styles.progress}
				style={{ width: `${(count / +total) * 100}%` }}
			/>
			<div className={styles.content}>
				<div className="d-flex align-items-center">
					<span className="fs-5 fw-500 me-1">{count}</span>/
					<span className="fw-400 ms-1">
						{total?.toString() !== '-1'
							? total
							: t('label.unlimited')}
					</span>
				</div>
				<span className="fw-300 fs-small lh-base text-gray-4">
					{t(title)}
				</span>
			</div>
		</div>
	);
});

EventCounterBox.displayName = 'EventCounterBox';
