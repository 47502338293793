import { memo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { SwiperSlide } from 'swiper/react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import { createGlobalStyle } from 'styled-components';
import { Autoplay, EffectFade, Pagination } from 'swiper/modules';
import { clx } from 'Utils';
import { AgentSetting } from 'Types';
import { useDepartments, useResponsive, useTheme, useUpdateAgent } from 'Hooks';
import {
	SectionHeadline,
	LoadingSpinner,
	ProfileAddNewCard,
	ProfileDepartmentCard,
	SectionSwitcher,
	Carousel,
	FontAwesome,
	Button
} from 'Elements';
import 'swiper/css/effect-fade';
import styles from 'partials/homepage/section/agent-department-section.module.scss';

const GlobalStyle = createGlobalStyle<{ type: string }>`
	${({ theme, type }) =>
		theme &&
		`
		.department-container {
			border-color: ${theme[type].primaryColor};
		}
		.department-bullet {
			background-color: ${theme[type].primaryColor} !important;
		}
	`}
`;

export const AgentDepartmentSection = memo(() => {
	const { theme } = useTheme();
	const { t } = useTranslation();
	const { control, reset } = useForm();
	const { isDesktopAndBelow } = useResponsive();
	const navigate = useNavigate();
	const navigationUrl = '/departments/create';

	const {
		updateAgentSetting,
		setting,
		isLoading: isLoadingSetting
	} = useUpdateAgent();

	const { data: departments, isLoading } = useDepartments({
		pageNumber: 1,
		pageSize: 50,
		keyword: ''
	});

	const handleNavigate = () => {
		navigate(navigationUrl, { state: { prevPath: '/homepage' } });
	};

	const handleUpdate = async (field: Partial<AgentSetting>) => {
		await updateAgentSetting(field);
	};

	useEffect(() => {
		reset(setting);
	}, [reset, setting]);

	return (
		<section className="position-relative mb-5 pb-5">
			{isLoading && (
				<div className="d-flex align-items-center justify-content-center">
					<LoadingSpinner />
				</div>
			)}
			{!isLoading && !isLoadingSetting && (
				<Container>
					<SectionSwitcher
						control={control}
						name="departmentSectionStatus"
						label="forms.show-departments-in-home"
						isActive={!setting?.departmentSectionStatus}
						onSwitchChange={(val) =>
							handleUpdate({
								departmentSectionStatus: val
							})
						}
					/>
					<SectionHeadline
						className="mx-auto text-center"
						title={
							setting?.departmentSectionTitle ||
							`[${t('placeholder.our-departments')}]`
						}
						titleColor="text-gray-5"
						onUpdateTitle={(val) =>
							handleUpdate({ departmentSectionTitle: val })
						}
					/>
					<Row className="justify-content-center">
						<Col lg={10}>
							<GlobalStyle type="agent" />
							{departments && departments.totalItems > 0 && (
								<Carousel
									loop
									effect="fade"
									followFinger={false}
									direction={
										isDesktopAndBelow
											? 'horizontal'
											: 'vertical'
									}
									slidesPerView={1}
									speed={800}
									autoplay={{
										delay: 3500,
										pauseOnMouseEnter: true,
										disableOnInteraction: false
									}}
									autoHeight={isDesktopAndBelow}
									pagination={{
										enabled: departments.totalItems > 1,
										clickable: true,
										horizontalClass: `${styles.bullets}`,
										verticalClass: `${styles.bullets}`,
										bulletClass: `${styles.bullet} department-bullet`,
										bulletActiveClass: `${styles.active}`
									}}
									modules={[Pagination, EffectFade, Autoplay]}
									className={clx(
										styles.carousel,
										'department-container'
									)}
									enabled={departments.totalItems > 1}>
									{departments.items
										?.reverse()
										?.map((department) => (
											<SwiperSlide
												key={department.id}
												className={styles.slide}>
												<ProfileDepartmentCard
													key={department.id}
													department={department}
												/>
											</SwiperSlide>
										))}
								</Carousel>
							)}
							{departments && departments?.totalItems > 0 && (
								<div className="d-flex align-items-center justify-content-center mt-4 mt-md-5">
									<Button
										size="lg"
										className="gap-3 rounded-pill"
										onClick={handleNavigate}>
										<FontAwesome
											icon="plus"
											size="xl"
											swapOpacity
										/>
										{t('title.add-new-department')}
									</Button>
								</div>
							)}
						</Col>
					</Row>
					{departments && departments?.items?.length === 0 && (
						<ProfileAddNewCard
							navigationUrl={navigationUrl}
							title="title.add-new-department"
						/>
					)}
				</Container>
			)}
			{!setting?.departmentSectionStatus && (
				<div className="overlay overlay_white overlay-box" />
			)}
		</section>
	);
});

AgentDepartmentSection.displayName = 'AgentDepartmentSection';
