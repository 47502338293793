import { memo, FC } from 'react';
import { clx } from 'Utils';
import { ListTabItem } from 'Elements';

interface Tab {
	id: number;
	path: string;
	title: string;
	isActive: boolean;
	number?: string;
}

interface Props {
	tabs: Tab[];
	className?: string;
	onTabChange?: (tab: Tab) => void;
}

export const ListTab: FC<Props> = memo(({ tabs, className, onTabChange }) => {
	return (
		<div className={clx(className, 'd-flex align-items-center gap-3 mb-3')}>
			{tabs.map((tab) => {
				return (
					<ListTabItem
						key={tab.id}
						title={tab.title}
						number={tab?.number}
						isActive={tab.isActive}
						onClick={() => onTabChange?.(tab)}
					/>
				);
			})}
		</div>
	);
});

ListTab.displayName = 'ListTab';
