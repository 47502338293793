import { useState, memo, useLayoutEffect, useCallback, FC } from 'react';
import Switch from 'rc-switch';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';
import { PermissionElement } from 'Types';
import { FormInlineCheckbox } from 'Elements';
import styles from 'partials/step/user-permission.module.scss';

interface Props {
	permissions: PermissionElement[];
	index: string;
	title: string;
	onCheckboxChange: (
		checked_item: PermissionElement,
		is_checked: boolean
	) => void;
	onCheckAllChange: (
		checked_item: PermissionElement[],
		is_checked: boolean
	) => void;
}

export const UserPermissionSection: FC<Props> = memo(
	({ permissions, index, title, onCheckboxChange, onCheckAllChange }) => {
		const { t } = useTranslation();
		const [isCheckedAll, setCheckedAll] = useState(false);

		// Check whether all check box is checked or not
		const isAllCheckboxSelected = useCallback(() => {
			const list = [...permissions];
			for (let i = 0; i < list.length; i++) {
				if (!list[i]?.isChecked) {
					return false;
				}
			}
			return true;
		}, [permissions]);

		const handleCheckAll = (checked: boolean) => {
			setCheckedAll(checked);
			onCheckAllChange?.(permissions, checked);
		};

		const handleCheckboxChange: (
			permissionItem: PermissionElement,
			checked: boolean
		) => void = (permissionItem, checked) => {
			// Set check all for every input change
			setCheckedAll(isAllCheckboxSelected());
			onCheckboxChange?.(permissionItem, checked);
		};

		useLayoutEffect(() => {
			setCheckedAll(isAllCheckboxSelected());
		}, [isAllCheckboxSelected]);

		return (
			<div className={styles.box}>
				<div className="d-flex align-items-center mb-4">
					<div className="d-flex align-items-center w-100 flex-wrap">
						<h3 className="fs-6 mb-0 text-break">{title}</h3>
						<div className="d-flex align-items-center ms-auto">
							<label
								htmlFor={`check_all_${index}`}
								className="d-none d-sm-inline-flex align-items-center cursor-pointer pe-2">
								{t('forms.select-all')}
							</label>
							<Switch
								id={`check_all_${index}`}
								checked={isCheckedAll}
								className="custom-switch custom-switch-primary custom-switch-sm"
								onChange={handleCheckAll}
							/>
						</div>
					</div>
				</div>
				<Row className="ps-1">
					{permissions?.map((permission) => {
						return (
							<Col
								sm={6}
								md={12}
								xl={6}
								xxl={5}
								key={permission.code}>
								<FormInlineCheckbox
									label={permission.label}
									id={`${permission.code}_checkbox`}
									isChecked={permission?.isChecked}
									disabled={permission?.disabled}
									onChange={(isChecked) =>
										handleCheckboxChange(
											permission,
											isChecked
										)
									}
								/>
							</Col>
						);
					})}
				</Row>
			</div>
		);
	}
);

UserPermissionSection.displayName = 'UserPermissionSection';
