import { FC, memo } from 'react';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Button, Card } from 'Elements';
import { clx, currencyFormatter } from 'Utils';
import { Colors, SubscriptionPlan } from 'Types';
import styles from 'partials/card/subscription-plan-card.module.scss';

interface Props {
	card: SubscriptionPlan;
	selected: boolean;
	onSelect: (plan: SubscriptionPlan) => void;
}

export const SubscriptionPlanCard: FC<Props> = memo(
	({ card, selected, onSelect }) => {
		const { t } = useTranslation();

		const handleSelectPlan = () => {
			onSelect(card);
		};

		return (
			<Card tag="article" className={clx(selected && styles.selected)}>
				<Row>
					<Col xl={6} xxl={8}>
						<h4 className="h4">{card.title}</h4>
						<div className="d-flex align-items-center my-3">
							<h6 className="h6 mb-0 pe-2 me-2 border-end border-2 text-gray-3 fw-500">
								{currencyFormatter(card.originalPrice ?? 0)}
							</h6>
							<span className="text-gray-3">
								{t('title.per-n-days', {
									duration: card.duration
								})}
							</span>
						</div>
						<div
							className="pt-2 lh-base"
							dangerouslySetInnerHTML={{
								__html: card.shortDescription
							}}
						/>
						<div
							className="lh-base"
							dangerouslySetInnerHTML={{
								__html: card.description
							}}
						/>
					</Col>
					<Col xl={6} xxl={4} className="mt-2 mt-xl-0">
						<div className="text-center d-flex flex-xl-column align-items-center gap-3 flex-wrap">
							<div className="d-flex justify-content-center">
								<div className="d-flex flex-column text-end pe-3 me-3 border-end border-2 text-primary">
									<h4 className="h4 mb-0">
										{currencyFormatter(card.price)}
									</h4>
									<span className="fw-300">
										{t('title.excluded-tax')}
									</span>
								</div>
								<div className="text-gray-3 text-start d-flex flex-column">
									<span className="fw-300 lh-base">
										{t('title.per')}
									</span>
									<span className="mt-auto fs-base">
										{`${card.remainDuration} ${t(
											'title.days'
										)}`}
									</span>
								</div>
							</div>
							<Button
								color={Colors.secondary}
								className="w-lg-100 ms-auto"
								onClick={handleSelectPlan}>
								{t('button.choose-plan')}
							</Button>
						</div>
					</Col>
				</Row>
			</Card>
		);
	}
);

SubscriptionPlanCard.displayName = 'SubscriptionPlanCard';
