import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { fetch } from 'Utils';
import { queryKey } from 'Constants';
import { Region } from 'Types';

const config = (options = {}) => {
	return {
		...{ keepPreviousData: false },
		...options
	};
};

export const useAlternativeRegion = <T = Region>(
	regionId: string,
	options?: UseQueryOptions<T, any, Region>
): UseQueryResult<Region, unknown> => {
	return useQuery(
		{
			queryKey: [queryKey.alternativeRegion, { regionId }],
			queryFn: () => fetch.alternativeRegionList(regionId),
			...config(options)
		}
	);
};
