import { memo, FC } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEventDetails, useEventAllInvitations } from 'Hooks';
import {
	EventInvitedCard,
	EventInvitedCardLoading,
	NoData,
	Scrollbar,
	FontAwesome
} from 'Elements';
import styles from 'partials/page/event.module.scss';

export const EventPartnerInvitedList: FC = memo(() => {
	const { event_id } = useParams();
	const { t } = useTranslation();

	const { data: event } = useEventDetails(event_id as string, {
		refetchOnMount: false
	});

	const { data: list, isFetching } = useEventAllInvitations(
		{ eventId: event_id as string },
		{ refetchOnMount: false }
	);

	return (
		<div className="pt-2">
			{!event?.isPublished && (
				<div className={styles.notifBar}>
					<FontAwesome icon="triangle-exclamation" size="lg" />
					{t('text.event.send-invitation-notification')}
				</div>
			)}
			{!isFetching && list && list?.length > 0 && (
				<Scrollbar className={styles.scrollbar}>
					<div>
						{list.map((invitation) => {
							return (
								<EventInvitedCard
									key={invitation.id}
									card={invitation}
								/>
							);
						})}
					</div>
				</Scrollbar>
			)}
			{isFetching && <EventInvitedCardLoading />}
			{!isFetching && list?.length === 0 && (
				<NoData message="title.no-invitation-sent" />
			)}
		</div>
	);
});

EventPartnerInvitedList.displayName = 'EventPartnerInvitedList';
