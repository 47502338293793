import { FC, InputHTMLAttributes, memo, useMemo } from 'react';
import { calculateBrightness, clx } from 'Utils';
import { Box } from 'Styles';
import { FontAwesome } from 'Elements';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
	label: string;
	id: string;
	name: string;
	bgColor: string;
	className?: string;
	disabled?: boolean;
}

export const RadioColor: FC<Props> = memo(
	({ label, id, name, className, disabled, bgColor, ...props }) => {
		const getBrightness = useMemo(() => {
			return calculateBrightness(bgColor);
		}, [bgColor]);

		return (
			<div
				role="checkbox"
				tabIndex={0}
				aria-checked={props.checked}
				className={clx(className)}>
				<input
					{...props}
					type="radio"
					id={`${name}_${id}`}
					name={name}
					disabled={disabled}
					className="form-input-check"
				/>
				<label
					htmlFor={`${name}_${id}`}
					className="d-flex align-items-center cursor-pointer"
					aria-disabled={disabled}>
					<Box
						as="span"
						className="form-input-color d-flex align-items-center justify-content-center text-center me-2"
						$bgColor={bgColor}>
						{props.checked && (
							<FontAwesome
								icon='check'
								size="lg"
								className={
									getBrightness >= 0 &&
									getBrightness <= 155 &&
									bgColor !== '#fff'
										? 'text-gray-1'
										: 'text-gray-4'
								}
							/>
						)}
					</Box>
					<span className="flex-grow-1">{label}</span>
				</label>
			</div>
		);
	}
);

RadioColor.displayName = 'RadioColor';
