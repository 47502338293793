import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import styles from 'partials/payment/payment-result.module.scss';
import { FontAwesome } from 'Elements';

export const PaymentFailed = memo(() => {
	const { t } = useTranslation();
	return (
		<div className={styles.box}>
			<div className={styles.filter} />
			<div className={styles.body}>
				<div className={styles.error}>
					<FontAwesome icon="xmark" size="3x" swapOpacity />
				</div>
				<h2 className="text-red fs-3 mb-3">
					{t('title.payment.error')}
				</h2>
				<p className="lh-md pb-3">{t('text.payment.error')}</p>
			</div>
		</div>
	);
});

PaymentFailed.displayName = 'PaymentFailed';
