import { HTMLProps, Ref } from 'react';

export const CustomDateTimeInput = (
	props: HTMLProps<HTMLInputElement>,
	ref: Ref<HTMLInputElement>
) => {
	const inputValue = props.value?.toString();
	const timeZoneIndex =
		inputValue?.lastIndexOf(' ') || inputValue?.length || 0;
	const timeZoneName = inputValue?.substr(timeZoneIndex + 1);

	return (
		<div className="inputbox w-100 position-relative">
			<input
				ref={ref}
				type="text"
				className="flex-grow-1 w-100 pe-5 z-index-1"
				defaultValue={inputValue?.substring(0, timeZoneIndex)}
				onClick={props.onClick}
			/>
			<span className="ms-auto text-gray-3 fs-small flex-shrink-0 position-absolute end-0 me-3">
				{timeZoneName}
			</span>
		</div>
	);
};

CustomDateTimeInput.displayName = 'CustomDateTimeInput';
