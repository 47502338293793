import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'Adapter';
import { Wizard, Steps, Step } from 'react-albus-react18';
import { names } from 'Constants';
import { clx, api } from 'Utils';
import {
	IStep,
	InternshipForm,
	InternshipFormInfo,
	InternshipFormDescription,
	InternshipFormFeatured,
	InternshipFormGeneral
} from 'Types';
import {
	CreateInternshipDescription,
	CreateInternshipInfo,
	CreateInternshipFeatured,
	CreateInternshipGeneral,
	CreateInternshipTerms,
	CreateInternshipPreviewSubmit,
	FormSuccess,
	StepContainer
} from 'Elements';
import styles from 'partials/step/step-container.module.scss';

export const CreateInternship = memo(() => {
	const { t } = useTranslation();
	const { jobs } = names;
	const [internshipId, setInternshipId] = useState('');

	const steps: IStep[] = [
		{
			id: 'step1',
			title: t('wizard.step-internship-1')
		},
		{
			id: 'step2',
			title: t('wizard.step-internship-2')
		},
		{
			id: 'step3',
			title: t('wizard.step-internship-3')
		},
		{
			id: 'step4',
			title: t('wizard.step-internship-4')
		},
		{
			id: 'step5',
			title: t('wizard.step-preview')
		}
	];

	const defaultFields: InternshipForm = {
		step1: {
			code: '',
			title: '',
			shortDescription: '',
			capacity: 1,
			hasLimited: false,
			hasStartDate: false,
			hasExpireDate: false,
			internshipActivityList: [],
			responsibilities: ''
		},
		step2: {
			featuredPhotoUrl: '',
			featuredVideoUrl: ''
		},
		step3: {
			sectorId: null,
			jobTypeId: null,
			activityList: null
		},
		step4: {
			description: '',
			benefits: '',
			requirements: '',
			responsibilities: '',
			showDescription: true,
			showRequirements: true,
			showResponsibilities: true,
			showBenefits: true
		},
		step5: null
	};
	const [fields, setFields] = useState(defaultFields);

	const handleNext = <T,>(
		goToNext: () => void,
		data: T,
		step_number: number
	) => {
		if (step_number !== 6) {
			setFields((prev) => ({
				...prev,
				[`step${step_number.toString()}`]: data
			}));
			goToNext();
		} else {
			goToNext();
		}
	};

	return (
		<Wizard
			render={({ step, ...props }) => (
				<div
					className={clx(
						styles.wizard,
						'd-flex flex-column flex-md-row'
					)}>
					<StepContainer
						steps={steps}
						currentIndex={props.steps.indexOf(step)}
					/>
					<Steps>
						<Step
							id="step1"
							render={({ next }) => (
								<CreateInternshipGeneral
									fields={fields.step1}
									onNext={(data) =>
										handleNext<InternshipFormGeneral>(
											next,
											data,
											1
										)
									}
								/>
							)}
						/>
						<Step
							id="step2"
							render={({ next, previous }) => (
								<CreateInternshipFeatured
									fields={fields.step2}
									onPrev={previous}
									onNext={(data) =>
										handleNext<InternshipFormFeatured>(
											next,
											data,
											2
										)
									}
								/>
							)}
						/>
						<Step
							id="step3"
							render={({ next, previous }) => (
								<CreateInternshipInfo
									fields={fields.step3}
									onPrev={previous}
									onNext={(data) =>
										handleNext<InternshipFormInfo>(
											next,
											data,
											3
										)
									}
								/>
							)}
						/>
						<Step
							id="step4"
							render={({ next, previous }) => (
								<CreateInternshipDescription
									fields={fields.step4}
									dataInternship={fields}
									setInternshipId={setInternshipId}
									onPrev={previous}
									onNext={(data) =>
										handleNext<InternshipFormDescription>(
											next,
											data,
											4
										)
									}
								/>
							)}
						/>
						<Step
							id="step5"
							render={({ next, previous }) => (
								<CreateInternshipTerms
									internshipId={internshipId}
									dataInternship={fields}
									onPrev={previous}
									onNext={(data) => handleNext(next, data, 5)}
								/>
							)}
						/>
						<Step
							id="step6"
							render={({ next, previous, push }) => (
								<CreateInternshipPreviewSubmit
									fields={fields}
									onPrev={previous}
									onNext={next}
									goToStep={push}
									setInternshipId={setInternshipId}
								/>
							)}
						/>
						<Step
							id="step7"
							render={({ push }) => (
								<div className={clx('d-flex', styles.form)}>
									<FormSuccess
										addButtonTitle="button.add-internship"
										listTitle="button.check-details"
										successTitle="forms.internship-created"
										className="flex-grow-1 align-self-center"
										listUrl={`${jobs?.internships?.path}/${internshipId}`}
										onClickAdd={() => {
											push('step1');
											setFields(defaultFields);
										}}
									/>
								</div>
							)}
						/>
					</Steps>
				</div>
			)}
		/>
	);
});

CreateInternship.displayName = 'CreateInternship';
