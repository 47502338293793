import { FC, memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { create, API, Options } from 'nouislider';
import 'nouislider/dist/nouislider.css';

interface Props extends Options {
	className?: string;
	onChange?: (value: (string | number)[]) => void;
	onUpdate?: (value: (string | number)[]) => void;
}

interface TargetElement extends HTMLDivElement {
	noUiSlider?: API;
}

export const RangeSlider: FC<Props> = memo(
	({ className, onChange, onUpdate, ...options }) => {
		const rangeEl = useRef<TargetElement>(null);
		const sliderParams = useMemo(
			() => ({
				keyboardSupport: true,
				...options
			}),
			[options]
		);

		const initRangeSlider = useCallback(() => {
			const rangeRef = rangeEl.current;
			if (rangeRef) {
				create(rangeRef, sliderParams);
				rangeRef?.noUiSlider?.on('change', (val) => onChange?.(val));
				rangeRef?.noUiSlider?.on('update', (val) => onUpdate?.(val));
			}
		}, [onChange, onUpdate, sliderParams]);

		useEffect(() => {
			const rangeRef = rangeEl.current;
			if (rangeRef) initRangeSlider();

			return () => {
				if (rangeRef) rangeRef?.noUiSlider?.destroy();
			};
		}, [initRangeSlider]);

		return <div ref={rangeEl} className={className} />;
	}
);

RangeSlider.displayName = 'RangeSlider';
