import { memo, FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Card, LoadingContent, FontAwesome } from 'Elements';
import styles from 'partials/card/subscription-card.module.scss';

import {
	ColorValues,
	CustomerDto,
} from 'Types';

interface Props {
	description?: string;
	shortDescription?: string;
	agent?: CustomerDto;
	isLoading: boolean;
}

const CardBox = styled(Card)`
	min-height: 286px;
`;

export const SubscriptionDescription: FC<Props> = memo(
	({ agent, description, shortDescription, isLoading }) => {
		const { t } = useTranslation();
		return (
			<CardBox className="h-100">
				<h6 className="text-gray-3 mb-3 fs-6 fw-400">
					{t('title.desc')}
				</h6>
				<div className={styles.text}>
					{shortDescription && (
						<div
							className="lh-base"
							dangerouslySetInnerHTML={{
								__html: shortDescription
							}}
						/>
					)}
					{description && (
						<div
							className="lh-base mt-2"
							dangerouslySetInnerHTML={{
								__html: description
							}}
						/>
					)}
				</div>
				{agent?.commissionGroup?.isPremium &&
					<>
						{agent?.agentPlanInfo?.description && (
							<div
								className="lh-base mt-2"
								dangerouslySetInnerHTML={{
									__html: agent?.agentPlanInfo?.description
								}}
							/>
						)}
						<div className='d-flex flex-wrap gap-2 mt-4 mb-2'>
							{agent?.agentPlanInfo?.features?.map((feature, index) => {
								return (
									<div key={index} className={'d-flex border border-1 border-gray-2 w-48 gap-3 p-2 round-10 h-100'}>
										<FontAwesome
											icon={feature.icon}
											size="2x"
											fixedWidth
											className="mt-1"
											color={ColorValues['gray-3']}
										/>
										<div className="d-flex flex-column gap-1 flex-grow-1">
											<h5 className="lh-base fs-base mb-0">{feature.title}</h5>
											{feature?.description && (
												<p className="fw-300 lh-sm pre-line mb-0">
													{feature.description}
												</p>
											)}
										</div>
									</div>
								);
							})}
						</div>
					</>
				}
				{isLoading && <LoadingContent />}
			</CardBox>
		);
	}
);

SubscriptionDescription.displayName = 'SubscriptionDescription';
