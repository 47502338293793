import { memo, FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { useUpdateJob } from 'Hooks';
import { SubmitButton } from 'Elements';
import { JobFormData, JobFormInitialData } from 'Types';

interface Props {
	formData: (data: JobFormInitialData) => JobFormData;
}

export const SaveAndPublishJob: FC<Props> = memo(({ formData }) => {
	const { job_id } = useParams();
	const [publishing, setPublishing] = useState(false);
	const {
		saveAndPublishJob,
		data: job,
		refetch
	} = useUpdateJob(job_id as string);
	const {
		watch,
		trigger,
		getValues,
		formState: { isDirty }
	} = useFormContext<JobFormInitialData>();
	const watchAutoPublish = watch('hasStartDate');

	const handleClick = async () => {
		try {
			const isValidForm = await trigger();
			if (isValidForm) {
				setPublishing(true);
				if (!watchAutoPublish) {
					const values = getValues();
					await saveAndPublishJob(
						job_id as string,
						formData(values),
						() => {
							refetch();
							setPublishing(false);
						}
					);
				}
			}
		} finally {
			setPublishing(false);
		}
	};

	const checkDisabled = () => {
		let check = false;
		if (job?.publishedVersion !== job?.currentVersion) {
			check = false;
		}
		if (watchAutoPublish || job?.publishedVersion === job?.currentVersion) {
			check = true;
		}
		if (isDirty && !watchAutoPublish) {
			check = false;
		}

		return check;
	};

	return (
		<SubmitButton
			type="button"
			icon="upload"
			label="button.save-publish"
			savingLabel="button.publishing"
			isDisable={checkDisabled()}
			isSubmitting={publishing}
			onClick={handleClick}
		/>
	);
});

SaveAndPublishJob.displayName = 'SaveAndPublishJob';
