import { memo, FC } from 'react';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Tab } from 'Types';
import { names } from 'Constants';
import { useAgentProfile } from 'Hooks';
import { Card, DecorationTab } from 'Elements';
import styles from 'partials/decoration/page-decoration-box.module.scss';

export const AgentPlanPriceDecorationBox: FC = memo(() => {
	const { pathname } = useLocation();
	const { planPrice } = names;
	const { t } = useTranslation();
	const { data: agentProfile, isLoading } = useAgentProfile();

	const defaultTab =
		pathname === planPrice.path || pathname === planPrice.calculation.path;

	const tabs: Tab[] = [
		{
			id: 0,
			title: 'tab.plan-price',
			path: 'calculation',
			isActive: defaultTab
		},
		{
			id: 1,
			title: 'tab.plan-info',
			path: 'info',
			isActive: pathname === planPrice.info.path
		},
		{
			id: 2,
			title: 'tab.plan-feature',
			path: 'feature',
			isActive: pathname === planPrice.feature.path
		},
		{
			id: 3,
			title: 'tab.contact-person',
			path: 'contact',
			isActive: pathname === planPrice.contactPerson.path
		}
	];

	return (
		<Card tab>
			<Row>
				<Col md={6} xl={7}>
					<h5 className="fs-6 fw-500 mb-3 lh-base">
						{t('title.plan-price.plan-price-program')}
					</h5>
					<p className="lh-md pre-line">
						{t('text.plan-price.what-is-plan-pricing')}
					</p>
					<div className="d-flex flex-column gap-2 mb-3">
						<div className="d-flex align-items-center gap-2 lh-base">
							<span>
								{t('label.plan-price.your-commission-group')}:
							</span>
							<span className="fw-500 text-primary">
								{isLoading
									? '...'
									: agentProfile?.commissionGroup?.label}
							</span>
						</div>
						<div className="d-flex align-items-center gap-2 lh-base">
							<span>
								{t('label.plan-price.commission-percent')}:
							</span>
							<span className="fw-500 text-primary">
								{isLoading
									? '...'
									: `${agentProfile?.agentCommissionLevel?.prov}%`}
							</span>
						</div>
					</div>
				</Col>
				<Col
					md={6}
					xl={5}
					className="my-3 my-md-0 align-self-xl-center">
					<img
						src="/assets/img/investments.svg"
						className={styles.image}
					/>
				</Col>
			</Row>
			<DecorationTab tabs={tabs} />
		</Card>
	);
});

AgentPlanPriceDecorationBox.displayName = 'AgentPlanPriceDecorationBox';
