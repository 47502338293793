import { memo, FC, ReactNode } from 'react';
import { clx } from 'Utils';

interface Props {
	children: ReactNode;
	className?: string;
}

export const ModalBoxFooter: FC<Props> = memo(({ children, className }) => {
	return (
		<footer className={clx('d-flex align-items-center', className)}>
			{children}
		</footer>
	);
});

ModalBoxFooter.displayName = 'ModalBoxFooter';
