import { memo, FC, Dispatch, SetStateAction } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import type { SubmitHandler } from 'react-hook-form/dist/types';
import { usePrompt } from 'Hooks';
import axios from 'Adapter';
import { api } from 'Utils';
import { InternshipFormDescription, InternshipForm, Colors, SelectOption } from 'Types';
import {
	RichTextController,
	StepNavigation,
	SwitchController,
	Button,
	FontAwesome
} from 'Elements';
import styles from 'partials/step/step-container.module.scss';

interface Props {
	fields: InternshipFormDescription;
	dataInternship: InternshipForm;
	onPrev: () => void;
	onNext: (data: InternshipFormDescription) => void;
	setInternshipId: Dispatch<SetStateAction<string>>;
}

export const CreateInternshipDescription: FC<Props> = memo(
	({ fields, dataInternship, onPrev, onNext, setInternshipId }) => {
		const { t } = useTranslation();
		const {
			watch,
			control,
			handleSubmit,
			formState: { isSubmitting, isDirty }
		} = useForm<InternshipFormDescription>({
			defaultValues: fields
		});

		usePrompt(t('forms.leave-screen-massage'), isDirty);

		const onSubmit: SubmitHandler<InternshipFormDescription> = async (
			data
		) => {
			if (
				dataInternship.step3.sectorId &&
				dataInternship.step3.jobTypeId &&
				dataInternship.step3.activityList
			) {
				await axios
					.post(api.createInternship, {
						...dataInternship.step1,
						...dataInternship.step2,
						...data,
						contactPerson: null,
						sectorId: dataInternship?.step3?.sectorId.value.toString(),
						jobTypeId: dataInternship?.step3?.jobTypeId.value.toString(),
						activityList: dataInternship?.step3?.activityList.map(
							(activity: SelectOption) => {
								return { activityId: activity.value.toString() };
							}
						),
					})
					.then((res) => {
						if (res.status === 200) {
							setInternshipId(res.data);
							onNext?.(data);
						}
					});
			}
		};

		return (
			<form
				onSubmit={handleSubmit(onSubmit)}
				className={styles.form}
				noValidate>
				<Row>
					<Col xxl={8}>
						<FormGroup className="mb-4">
							<div className="d-flex align-items-center mb-2">
								<Label htmlFor="description" className="mb-0">
									{t('forms.internship.desc')}
								</Label>
								<SwitchController
									control={control}
									name="showDescription"
									boxClassName="ms-auto"
									side="right"
									label={t('forms.show-in-internship')}
								/>
							</div>
							<RichTextController
								control={control}
								name="description"
								readOnly={!watch('showDescription')}
							/>
						</FormGroup>
						<FormGroup className="mb-4">
							<div className="d-flex align-items-center mb-2">
								<Label htmlFor="requirements" className="mb-0">
									{t('forms.internship.requirements')}
								</Label>
								<SwitchController
									control={control}
									name="showRequirements"
									boxClassName="ms-auto"
									side="right"
									label={t('forms.show-in-internship')}
								/>
							</div>
							<RichTextController
								control={control}
								name="requirements"
								readOnly={!watch('showRequirements')}
							/>
						</FormGroup>
						<FormGroup className="mb-4">
							<div className="d-flex align-items-center mb-2">
								<Label
									htmlFor="responsibilities"
									className="mb-0">
									{t('forms.internship.responsibilities')}
								</Label>
								<SwitchController
									control={control}
									name="showResponsibilities"
									boxClassName="ms-auto"
									side="right"
									label={t('forms.show-in-internship')}
								/>
							</div>
							<RichTextController
								control={control}
								name="responsibilities"
								readOnly={!watch('showResponsibilities')}
							/>
						</FormGroup>
						<FormGroup className="mb-4">
							<div className="d-flex align-items-center mb-2">
								<Label htmlFor="benefits" className="mb-0">
									{t('forms.internship.benefits')}
								</Label>
								<SwitchController
									control={control}
									name="showBenefits"
									boxClassName="ms-auto"
									side="right"
									label={t('forms.show-in-internship')}
								/>
							</div>
							<RichTextController
								control={control}
								name="benefits"
								readOnly={!watch('showBenefits')}
							/>
						</FormGroup>
						<div className="d-flex align-items-center lh-base justify-content-between">
							<Button
								color={Colors['white-primary']}
								className="gap-2 me-auto"
								onClick={onPrev}>
								<FontAwesome icon="chevron-left" size="1x" />
								{t('wizard.prev')}{' '}
							</Button>
							<Button
								type="submit"
								className="gap-2"
								disabled={isSubmitting}>
								{t('wizard.next')}{' '}
								<FontAwesome
									icon="chevron-right"
									size="1x"

								// onClick={()=>submitInternship}
								/>
							</Button>
						</div>
					</Col>
				</Row>
			</form>
		);
	}
);

CreateInternshipDescription.displayName = 'CreateInternshipDescription';
