export const containerVariants = {
	hidden: { opacity: 0 },
	show: {
		opacity: 1,
		transition: { staggerChildren: 0.05, ease: 'ease' }
	}
};

export const itemVariants = {
	hidden: { y: 10, opacity: 0 },
	show: { y: 0, opacity: 1 }
};

export const fadeInTopVariants = {
	hidden: { y: -10, opacity: 0 },
	show: { y: 0, opacity: 1 }
};
