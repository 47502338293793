import { useLocation, useNavigate } from 'react-router-dom';

export const useGoBack = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const goBack = () => {
		navigate(-1);
	};

	return { goBack, location };
};
