import { FC, memo } from 'react';
import { ColorValues } from 'Types';

interface Props {
	fill?: string;
	className?: string;
	size?: number;
}

export const IconBranch: FC<Props> = memo(
	({
		fill = ColorValues.primary,
		className,
		size,
		...props
	}): JSX.Element => {
		return (
			<svg
				width={size}
				height={size}
				viewBox="0 0 24 24"
				xmlns="http://www.w3.org/2000/svg"
				className={className}
				{...props}>
				<path
					d="M10.62 5.4v14.566H2.128A2.1 2.1 0 0 1 0 17.9V3.085C0 .47 1.977-.718 4.387.45l4.468 2.186A3.392 3.392 0 0 1 10.62 5.4z"
					transform="translate(1.987 2.017)"
					fill={fill}
					fillOpacity={0.25}
				/>
				<path
					d="M4.256 1.513h-3.5A.762.762 0 0 1 0 .756.762.762 0 0 1 .756 0h3.5a.756.756 0 1 1 0 1.513z"
					transform="translate(4.739 8.245)"
					fill={fill}
				/>
				<path
					d="M4.256 1.513h-3.5A.762.762 0 0 1 0 .756.762.762 0 0 1 .756 0h3.5a.756.756 0 1 1 0 1.513z"
					transform="translate(4.739 12.243)"
					fill={fill}
				/>
				<path
					d="M9.581 4.669v4.488a2.522 2.522 0 0 1-2.521 2.522H0V0l.474.1 4.074.908.484.111 2.057.464a3.924 3.924 0 0 1 1.341.524.01.01 0 0 0 .01.01 2.191 2.191 0 0 1 .292.242 3.191 3.191 0 0 1 .837 2.128c.002.061.012.122.012.182z"
					transform="translate(12.402 10.304)"
					fill={fill}
					fillOpacity={0.6}
				/>
				<path
					d="M0 0v6.051a2.528 2.528 0 0 0 4.548-1.513V1.019L.474.111z"
					transform="translate(12.475 10.383)"
					fill={fill}
				/>
				<path
					d="M5.022 3.479a3.191 3.191 0 0 0-.837-2.128 2.191 2.191 0 0 0-.292-.242.01.01 0 0 1-.01-.01A3.924 3.924 0 0 0 2.541.575L.484.111 0 0v3.53a2.518 2.518 0 0 0 5.032.141v-.01c0-.061-.01-.121-.01-.182z"
					transform="translate(16.95 11.388)"
					fill={fill}
				/>
			</svg>
		);
	}
);

IconBranch.displayName = 'IconBranch';
