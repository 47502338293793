import { memo, FC, CSSProperties } from 'react';
import { Col } from 'reactstrap';
import { clx } from 'Utils';
import { Card, LoadingMask, SkeletonWrapper } from 'Elements';
import styles from 'partials/card/user-card.module.scss';

interface Props {
	count?: number | string;
}

const Skeleton = memo(({ style }: { style: CSSProperties }) => {
	return (
		<Col sm={6} xl={4} xxl={3} className="mb-4" style={style}>
			<Card className={styles.card}>
				<div className={clx(styles.avatar, 'shadow-none')}>
					<div className={styles.shimmerBox}>
						<LoadingMask className={styles.shimmer} />
					</div>
				</div>
				<div className={styles.body}>
					<LoadingMask
						className={clx(
							styles.loading,
							styles.loadingMd,
							'mb-2'
						)}
					/>
					<LoadingMask
						className={clx(
							styles.loading,
							styles.loadingSm,
							'mb-2'
						)}
					/>
					<div className="d-flex align-items-center justify-content-between mt-auto mb-2 pt-4">
						<LoadingMask
							className={clx(styles.loading, styles.loadingSm)}
						/>
						<LoadingMask
							className={clx(styles.loading, styles.loadingSm)}
						/>
					</div>
				</div>
			</Card>
		</Col>
	);
});

export const UserCardLoading: FC<Props> = ({ count = 3 }) => {
	return (
		<SkeletonWrapper
			skeleton={Skeleton}
			count={count}
			wrapperClassName="row"
		/>
	);
};

Skeleton.displayName = 'Skeleton';
UserCardLoading.displayName = 'UserCardLoading';
