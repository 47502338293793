import { FC, memo } from 'react';
import ReactPagination from 'react-js-pagination';
import { clx } from 'Utils';
import { FontAwesome } from 'Elements';
import { useResponsive } from 'Hooks';
import styles from 'partials/shared/pagination.module.scss';

interface Props {
	className?: string;
	activePage: number;
	itemsCountPerPage: number;
	totalItemsCount: number;
	onChange: (page: number) => void;
	[x: string]: unknown;
}

export const Pagination: FC<Props> = memo((props): JSX.Element => {
	const { isMobileAndBelow } = useResponsive();
	return (
		<div className={clx(styles.row, props.className)}>
			<ReactPagination
				hideFirstLastPages={false}
				pageRangeDisplayed={isMobileAndBelow ? 3 : 5}
				totalItemsCount={props.totalItemsCount}
				itemsCountPerPage={props.itemsCountPerPage || 10}
				activePage={props.activePage}
				firstPageText={
					<FontAwesome
						icon='angles-left'
						size="1x"
						className="flex-shrink-0"
						swapOpacity
					/>
				}
				prevPageText={
					<FontAwesome
						icon='chevron-left'
						size="lg"
						className="flex-shrink-0"
						swapOpacity
					/>
				}
				nextPageText={
					<FontAwesome
						icon='chevron-right'
						size="lg"
						className="flex-shrink-0"
						swapOpacity
					/>
				}
				lastPageText={
					<FontAwesome
						icon='angles-right'
						size="1x"
						className="flex-shrink-0"
						swapOpacity
					/>
				}
				innerClass={styles.pagination}
				linkClass={styles.num}
				activeLinkClass={styles.current}
				disabledClass={styles.disabled}
				itemClassPrev={styles.arrow}
				itemClassNext={styles.arrow}
				onChange={props.onChange}
			/>
		</div>
	);
});

Pagination.displayName = 'Pagination';
