import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { clx } from 'Utils';
import { useResponsive } from 'Hooks';
import { FontAwesome } from 'Elements';
import styles from 'partials/step/step-item.module.scss';

interface Props {
	title: string;
	number: number;
	currentIndex: number;
	className?: string;
}

export const StepItem: FC<Props> = memo(
	({ title, number, currentIndex, className }) => {
		const { t } = useTranslation();
		const { isTabletAndBelow } = useResponsive();
		const isDone = number < currentIndex + 1;
		const isActive = number === currentIndex + 1;

		return (
			<li
				className={clx(
					className,
					styles.item,
					isActive && styles.active,
					isDone && styles.checked
				)}>
				<div className="d-flex align-items-center">
					<span className={styles.number}>
						{!isDone && <>{number}</>}
						{isDone && (
							<FontAwesome
								icon="check"
								size={isTabletAndBelow ? 'sm' : 'xl'}
							/>
						)}
					</span>
					<div className={styles.content}>
						<h5 className="fs-6 lh-md text-white mb-0">
							{t(title)}
						</h5>
					</div>
				</div>
			</li>
		);
	}
);

StepItem.displayName = 'StepItem';
