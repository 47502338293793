import { memo, FC } from 'react';
import { clx } from 'Utils';
import styles from 'partials/shared/loading/loading-mask.module.scss';

interface Props {
	className?: string;
}

export const LoadingMask: FC<Props> = memo(({ className }) => {
	const maskClass = clx(styles.mask, className);

	return <div className={maskClass}></div>;
});

LoadingMask.displayName = 'LoadingMask';
