import { memo, FC } from 'react';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { clx, config } from 'Utils';
import { Button, Card, ShimmerImage, Badge, FontAwesome } from 'Elements';
import { Colors, SubscriptionAgentSetting } from 'Types';
import styles from 'partials/card/subscription-agent-card.module.scss';

interface Props {
	agent: SubscriptionAgentSetting;
	selected: boolean;
	onSelect: (agent: SubscriptionAgentSetting) => void;
}

export const SubscriptionAgentCard: FC<Props> = memo(
	({ agent, selected, onSelect }) => {
		const { t } = useTranslation();
		const setting = agent?.agentSetting;
		const navigationUrl = `${config.publicUrl}/agent/${agent.agentId}/${agent.slugUrl}`;

		return (
			<Card tag="article" className={clx(selected && styles.selected)}>
				<Row>
					<Col xl={8} className="order-1 order-xl-0">
						<div className="d-flex align-items-center gap-3 mb-3">
							<a
								target="_blank"
								rel="noopener noreferrer"
								href={navigationUrl}
								className={styles.logoWrapper}>
								<ShimmerImage
									width={45}
									height={45}
									src={setting?.thumbnailLogo}
									alt={agent.name}
									defaultImage="/assets/img/company-default.jpg"
									className={styles.logo}
								/>
							</a>
							<div className="flex-grow-1 d-flex flex-column justify-content-center">
								<h4 className="fs-base lh-base mb-0">
									<a
										target="_blank"
										rel="noopener noreferrer"
										className="d-inline-flex"
										href={navigationUrl}>
										{agent.name}
									</a>
								</h4>
								{setting?.slogan && (
									<span className="text-gray-3 fs-small lh-md">
										{setting?.slogan}
									</span>
								)}
							</div>
						</div>
						<div className="mb-3">
							{setting?.description && (
								<div
									className="lh-md"
									dangerouslySetInnerHTML={{
										__html: setting?.description
									}}
								/>
							)}
						</div>
					</Col>
					<Col xl={4} className="d-none d-md-block mb-md-3 mb-xl-0">
						<img
							src={
								setting?.headerImage ||
								'/assets/img/default-image.jpg'
							}
							alt={agent.name}
							className={styles.image}
						/>
					</Col>
				</Row>
				<div>
					{!agent?.companyApprovalStatus && (
						<Badge color={Colors['warning']}>
							<FontAwesome icon={'info-circle'} size="1x" />
							<span className="ms-1">
								{t('badge.no-approval')}
							</span>
						</Badge>
					)}
				</div>
				<Row className="mt-3">
					<Col
						sm={6}
						lg={12}
						xl={8}
						className="mb-3 mb-sm-0 mb-lg-3 mb-xl-0">
						<a
							target="_blank"
							rel="noreferrer noopener"
							href={navigationUrl}
							className={clx(
								styles.more,
								'w-100 h-100 w-lg-auto'
							)}>
							{t('button.more-about-agent')}
						</a>
					</Col>
					<Col sm={6} lg={12} xl={4}>
						<Button
							color={Colors.secondary}
							className="w-100 h-100"
							onClick={() => onSelect(agent)}>
							{t('button.choose-agent')}
						</Button>
					</Col>
				</Row>
			</Card>
		);
	}
);

SubscriptionAgentCard.displayName = 'SubscriptionAgentCard';
