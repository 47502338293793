import { memo, useEffect, useState, useRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import deLocale from '@fullcalendar/core/locales/de';
import { useTranslation } from 'react-i18next';
import { names } from 'Constants';
import { useNavigate, useSearchParams } from 'react-router-dom';
import FullCalendar from '@fullcalendar/react';
import { Internship, Colors } from 'Types';
import { getCurrentLocale } from 'Utils';
import { format } from 'date-fns';
import { useInternships } from 'Hooks';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import listPlugin from '@fullcalendar/list';
import { FontAwesome, LoadingContent, TermsDrawer, Button } from 'Elements';
import '../../../assets/css/internship-management.css';

export const ListInternshipManagement = memo(() => {
	const calendarRef = useRef<FullCalendar>(null!);
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [events, setEvents] = useState([]);
	const [dataFilter, setDataFilter] = useState<any>([]);
	const [loading, setLoading] = useState(true);
	const [loadingFilter, setLoadingFilter] = useState(true);
	const [isOpen, setIsOpen] = useState(false);
	const [dataSelected, setDataSelected] = useState({});
	const [dataFilterSelected, setDataFilterSelected] = useState([]);
	const [searchParams] = useSearchParams();
	const [startDate, setStartDate] = useState(
		searchParams.get('dateSelected')
			? new Date(searchParams.get('dateSelected') as never)
			: new Date()
	);
	const {
		jobs: { internships }
	} = names;

	const [state] = useState<{
		pageSize: number;
		currentPage: string | number;
		keyword: string;
		filterBy: string;
		isRemoving: boolean;
		selectedInternship: Internship | null;
		publishing: boolean;
	}>({
		pageSize: 1000,
		currentPage: 1,
		keyword: '',
		filterBy: '',
		isRemoving: false,
		selectedInternship: null,
		publishing: false
	});

	const { data } = useInternships({
		status: 1,
		pageSize: state.pageSize,
		pageNumber: state.currentPage,
		keyword: state.keyword
	});

	const width = window.innerWidth;

	function renderEventContent(eventInfo) {
		const startDate: any = new Date(
			Date.parse(eventInfo?.event?.extendedProps?.startTime)
		);
		const endDate: any = new Date(
			Date.parse(eventInfo?.event?.extendedProps?.endTime)
		);

		let textDecoration = 'none';
		if (eventInfo.event.start < new Date()) {
			textDecoration = 'line-through';
		}
		if (
			new Date(eventInfo.event.start).getMonth() ===
			new Date().getMonth() &&
			new Date(eventInfo.event.start).getDate() ===
			new Date().getDate() &&
			startDate.toLocaleTimeString([], {
				hour: '2-digit',
				minute: '2-digit',
				hour12: false
			}) > format(new Date(), 'HH:mm')
		) {
			textDecoration = 'none';
		}

		return (
			<div
				key={eventInfo.event.id}
				data-id={eventInfo.event.id}
				onClick={(e: any) => {
					setDataSelected('');
					data?.items?.forEach((term) => {
						term?.terms.forEach((s) => {
							if (s.id === e.target.getAttribute('data-id')) {
								setDataSelected({
									data: term,
									id: s.id,
									internshipId: term.id
								});
							}
						});
					});
					setIsOpen((prevState) => !prevState);
				}}>
				<div
					data-id={eventInfo.event.id}
					className="fs-sm fw-700 textEllipsis">
					{eventInfo.event.title}
				</div>
				<div
					data-id={eventInfo.event.id}
					className="fs-sm fw-500 textEllipsis">
					{eventInfo.event.extendedProps.titleTerm}
				</div>
				<div
					data-id={eventInfo.event.id}
					style={{ textDecoration: textDecoration }}
					className="d-flex flex-wrap gap-2">
					<span data-id={eventInfo.event.id} className="textEllipsis">
						{startDate.toLocaleTimeString([], {
							hour: '2-digit',
							minute: '2-digit',
							hour12: false
						})}
					</span>
					<span data-id={eventInfo.event.id}>-</span>
					<span data-id={eventInfo.event.id} className="textEllipsis">
						{endDate.toLocaleTimeString([], {
							hour: '2-digit',
							minute: '2-digit',
							hour12: false
						})}
					</span>
				</div>
				<div data-id={eventInfo.event.id}>
					<FontAwesome
						data-id={eventInfo.event.id}
						icon="user"
						size="lg"
					/>
					<span data-id={eventInfo.event.id} className="ms-2">
						{eventInfo.event.extendedProps.members}
					</span>
				</div>
			</div>
		);
	}

	useEffect(() => {
		setEvents([]);
		const list: any = [];
		data?.items?.forEach((internship) => {
			internship?.terms?.forEach((term: any) => {
				term.sessions.forEach((session: any) => {
					if (dataFilterSelected.includes(term.id as never)) {
						list.push({
							title: internship.title,
							titleTerm: term.title,
							id: term.id,
							extendedProps: {
								startTime: session?.sessionStartDate,
								endTime: session?.sessionEndDate,
								members: term?.registrantsInfos?.length ? term?.registrantsInfos?.length : 0
							},
							date: format(
								new Date(session?.sessionStartDate),
								`yyyy-MM-dd`
							),
							// backgroundColor: 'rgb(233 233 233)',
							// borderColor: 'rgb(233 233 233)',
							textColor: '#9392A1'
						});
					}
				});
			});
		});
		setEvents(list);
		const calendarApi = calendarRef?.current?.getApi();
		setStartDate(
			searchParams.get('dateSelected')
				? new Date(searchParams.get('dateSelected') as never)
				: new Date()
		);
		calendarApi?.gotoDate(searchParams.get('dateSelected') as string);
		setLoading(false);
		setLoadingFilter(false);
	}, [data, dataFilterSelected]);

	useEffect(() => {
		navigate(
			`?dateSelected=${searchParams.get('dateSelected') ||
			format(new Date(), 'yyyy-MM-dd')
			}`
		);
		const filter: any = [];
		const newFilter: any = [];
		data?.items?.forEach((internship) => {
			internship.terms.forEach((term) => {
				newFilter.push(term.id);
				filter.push({
					title: internship.title,
					titleTerm: term.title,
					id: term.id,
					startTime: term.sessions[0].sessionStartDate,
					endTime: term.sessions[0].sessionEndDate,
					checked: true,
					members: term?.registrantsInfos?.length ? term?.registrantsInfos?.length : 0,
					date: format(
						new Date(term.sessions[0].sessionStartDate),
						`yyyy-MM-dd`
					),
					// backgroundColor: 'rgb(233 233 233)',
					// borderColor: 'rgb(233 233 233)',
					textColor: '#9392A1'
				});
			});
		});
		setDataFilterSelected(newFilter);
		setDataFilter(filter);
	}, [data]);

	const onChangeHandler = (itemId, checked) => {
		const filter: any = [...dataFilter];
		filter.forEach((element) => {
			if (element.id === itemId) {
				element.checked = checked;
			}
		});

		const newFilter: any = [...dataFilterSelected];
		if (checked) {
			newFilter.push(itemId);
		} else {
			const indexDel = newFilter?.indexOf(itemId as never);
			if (indexDel > -1) {
				newFilter.splice(indexDel, 1);
			}
		}
		navigate(`?dateSelected=${format(new Date(), 'yyyy-MM-dd')}`);
		setDataFilterSelected(newFilter);
		setDataFilter(filter);
		setLoadingFilter(true);
	};

	return loading ? (
		<LoadingContent />
	) : (
		<>
			<div className="d-flex">
				<FontAwesome icon={'circle-info'} size="1x" className="me-1" />
				<p className="fw-400 fs-sm">
					{t(
						'title.by-selecting-checkboxes-you-can-filter-your-calendar-by-internships-you-want-to-follow'
					)}
				</p>
			</div>
			<div className="d-flex align-items-center scroll">
				<div className="d-flex align-items-center gap-2">
					{dataFilter?.map((item, index) => {
						const startDate: any = new Date(
							Date.parse(item.startTime)
						);
						const endDate: any = new Date(Date.parse(item.endTime));
						return (
							<div key={index} className="d-flex gap-2">
								<div
									data-id={item?.id}
									className="fc-event"
									style={{ width: '13rem' }}>
									<div
										data-id={item?.id}
										className="fs-sm fw-700 textEllipsis">
										{item?.title}
									</div>
									<div
										data-id={item?.id}
										className="fs-sm fw-500 textEllipsis">
										{item?.titleTerm}
									</div>
									<div data-id={item?.id}>
										{`${startDate.toLocaleTimeString([], {
											hour: '2-digit',
											minute: '2-digit',
											hour12: false
										})} - ${endDate.toLocaleTimeString([], {
											hour: '2-digit',
											minute: '2-digit',
											hour12: false
										})}`}
									</div>
									<div data-id={item.id}>
										<FontAwesome
											data-id={item.id}
											icon="user"
											size="lg"
										/>
										<span
											data-id={item.id}
											className="ms-2">
											{item?.members}
										</span>
									</div>
								</div>
								<div
									role="checkbox"
									tabIndex={item.id}
									aria-checked={item.checked}
									onClick={(e) => {
										onChangeHandler(
											item.id,
											item.checked ? false : true
										);
									}}>
									<input
										type="checkbox"
										key={item.id + index}
										name={(item.id + index) as string}
										id={(item.id + index) as string}
										checked={item.checked}
										readOnly
										className="form-input-check"
									/>
									<span className="form-check-tick">
										{item.checked && (
											<FontAwesome
												icon="check"
												size="xs"
											/>
										)}
									</span>
								</div>
							</div>
						);
					})}
				</div>
			</div>
			<>
				<div className="d-flex align-items-center justify-content-between">
					<div className="d-flex flex-wrap align-items-center gap-3">
						<div className="fs-5 fw-500">
							<ReactDatePicker
								locale={getCurrentLocale().code}
								selected={startDate}
								onChange={(date: any) => {
									const calendarApi =
										calendarRef?.current?.getApi();
									setStartDate(date as never);
									calendarApi?.gotoDate(
										format(date, 'yyyy-MM-dd') as string
									);
									navigate(
										`?dateSelected=${format(
											date,
											'yyyy-MM-dd'
										)}`
									);
								}}
								dateFormat="MMMM yyyy"
								className={'inputDatePicker'}
								showMonthYearPicker
								showFullMonthYearPicker
								showFourColumnMonthYearPicker
							/>
						</div>
						<div>
							<FontAwesome
								icon={'chevron-left'}
								size="1x"
								className="me-4 fc-bPrev-button"
								onClick={() => {
									const calendarApi =
										calendarRef?.current?.getApi();
									calendarApi?.prev();
									setStartDate(calendarApi?.getDate());
									navigate(
										`?dateSelected=${format(
											calendarApi?.getDate(),
											'yyyy-MM-dd'
										)}`
									);
								}}
							/>
							<FontAwesome
								icon={'chevron-right'}
								size="1x"
								className="fc-bNext-button"
								onClick={() => {
									const calendarApi =
										calendarRef?.current?.getApi();
									calendarApi?.next();
									setStartDate(calendarApi?.getDate());
									navigate(
										`?dateSelected=${format(
											calendarApi?.getDate(),
											'yyyy-MM-dd'
										)}`
									);
								}}
							/>
						</div>
					</div>
					<div className="d-flex flex-wrap align-items-center gap-3">
						<div className="fs-5 fw-500 text-gray-3 gap-2">
							<span className="me-1"> {t('text.today')}</span>
							{format(new Date(), 'dd MMMM yyyy')}
						</div>
						<Button
							color={Colors['white-green']}
							label={t('button.go-today') as string}
							onClick={() => {
								const calendarApi =
									calendarRef?.current?.getApi();
								calendarApi?.today();
								setStartDate(new Date() as never);
								navigate(
									`?dateSelected=${format(
										calendarApi?.getDate(),
										'yyyy-MM-dd'
									)}`
								);
							}}
						/>
					</div>
				</div>
				{loadingFilter ? (
					<LoadingContent />
				) : (
					<FullCalendar
						locale={getCurrentLocale().code}
						locales={[deLocale]}
						ref={calendarRef}
						plugins={[dayGridPlugin, listPlugin]}
						initialView={
							width < 1070 ? 'listMonth' : 'dayGridMonth'
						}
						firstDay={1}
						weekends={true}
						events={events}
						dayMaxEvents={3}
						height="auto"
						eventContent={renderEventContent}
						headerToolbar={{
							left: '',
							center: '',
							right: ''
						}}
					/>
				)}
			</>
			<TermsDrawer
				isOpen={isOpen}
				dataSelected={dataSelected}
				toggleDrawer={() => setIsOpen((prevState) => !prevState)}
			/>
		</>
	);
});

ListInternshipManagement.displayName = 'ListInternshipManagement';
