import { memo } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useSearchParams } from 'react-router-dom';
import { usePaymentStatus, useSubscription, useAxios } from 'Hooks';
import {
	LoadingSpinner,
	PaymentFailed,
	PaymentSuccess,
	PaymentSepa
} from 'Elements';
import styles from 'partials/payment/payment-result.module.scss';
import { api } from 'Utils';

export const PaymentResult = memo(() => {
	const [searchParams] = useSearchParams();
	const paymentId = searchParams.get('id') as string;
	const status = searchParams.get('status') as string;
	const subscriptionId = searchParams.get('subscriptionId') as string;
	const paymentMethod = searchParams.get('paymentMethod') as string;
	const { sendRequest } = useAxios();

	const handlePaymentResponse = (data?: string) => {
		if (data) {
			window.location.replace(`${data}&paymentMethod=${paymentMethod}`);
		}
	};

	const dataCanceled: any = { status: 'canceled' };

	const {
		data: paymentStatus,
		isFetching,
		refetch
	} = usePaymentStatus(paymentId?.toString(), {
		enabled: !!paymentId,
		retry: 3,
		onSuccess: (data) => {
			if (data.status === 'open') {
				refetch();
			}
		}
	});

	const subscriptionData = useSubscription(subscriptionId, {
		enabled: !!subscriptionId,
		onSuccess: async (data) => {
			if (data?.paymentStatus === 'pending') {
				await sendRequest(
					api.createPayment,
					{
						data: {
							token: 'token',
							companySubscriptionId: subscriptionId,
							paymentMethod: paymentMethod
						}
					},
					(data) => {
						if (data) {
							handlePaymentResponse(data as string);
						}
					}
				);
			} else {
				window.location.replace('/');
			}
		}
	});

	const paymentData = paymentId
		? paymentStatus
		: status === '1'
		? subscriptionData
		: dataCanceled;

	return (
		<div className={styles.wrapper}>
			<Container>
				<Row className="justify-content-center">
					<Col md={9} xl={7} xxl={5}>
						{paymentId ? (
							<div
								className={
									paymentMethod === '5'
										? ''
										: styles.container
								}>
								{isFetching && (
									<LoadingSpinner className="mt-5" />
								)}
								{!isFetching && paymentData && (
									<>
										{['paid', 'authorized']?.includes(
											paymentData?.status as string
										) && paymentMethod !== '5' ? (
											<PaymentSuccess />
											) : (
											<PaymentSepa />
										)}
										{[
											'failed',
											'expired',
											'canceled'
										]?.includes(
											paymentData?.status as string
										) && <PaymentFailed />}
										{['open', 'pending']?.includes(
											paymentData?.status as string
										) && (
											<LoadingSpinner className="mt-5" />
										)}
									</>
								)}
							</div>
						) : status === '0' ? (
							<div className={styles.container}>
								<PaymentFailed />
							</div>
						) : (
							<LoadingSpinner />
						)}
					</Col>
				</Row>
			</Container>
		</div>
	);
});

PaymentResult.displayName = 'PaymentResult';
