import { FC, memo, SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
	color?: string;
	strokeWidth?: number;
	className?: string;
}

export const ShapeLineDivider: FC<Props> = memo(
	({ color = '#fff', strokeWidth = 1, className, ...props }): JSX.Element => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 1921 127.872"
				className={className}
				{...props}>
				<path
					d="M-4114.277,4356.611l407.154,98.894a327.349,327.349,0,0,0,102.071,8.308l1410.775-107.2"
					transform="translate(4114.513 -4355.614)"
					fill="none"
					stroke={color}
					strokeWidth={strokeWidth}
				/>
			</svg>
		);
	}
);

ShapeLineDivider.displayName = 'ShapeLineDivider';
