import { startTransition } from 'react';
import axios from 'Adapter';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { queryKey } from 'Constants';
import { fetch, api, toastify } from 'Utils';
import {
	DataQueryParams,
	DataList,
	Internship,
	InternshipFormData,
	Media
} from 'Types';

const config = (options = {}) => {
	return {
		...options,
		keepPreviousData: false
	};
};

export const useInternships = <T = DataList<Internship>>(
	{
		status = '',
		pageSize = 12,
		pageNumber = 1,
		keyword = ''
	}: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<Internship>>
): UseQueryResult<DataList<Internship>> => {
	return useQuery({
		queryKey: [
			queryKey.internships,
			{ status, pageSize, pageNumber, keyword }
		],
		queryFn: () =>
			fetch.internshipList(status, pageSize, pageNumber, keyword),
		...config(options)
	});
};

/**
 * Fetches a list of Internships based on the provided query parameters.
 * @param internshipId The ID of the internship to fetch.
 * @param options Additional options for the query.
 * @returns The result of the query.
 */
export const useInternship = <T = Internship>(
	internshipId: string,
	options?: UseQueryOptions<T, any, Internship>
): UseQueryResult<Internship> => {
	return useQuery({
		queryKey: [queryKey.internship, { id: internshipId }],
		queryFn: () => fetch.internshipDetails(internshipId),
		...config(options)
	});
};

/**
 * Provides a function to submit a Internship creation request.
 * @returns An object with the submitRequest function.
 */
export const useCreateInternship = () => {
	/**
	 * Submits a Internship creation request with the provided fields.
	 * @param fields The Internship form data.
	 * @param onFinal Optional callback to be called after the request is completed.
	 * @returns A Promise that resolves to the ID of the created Internship.
	 */
	const submitRequest: (
		fields: InternshipFormData,
		onFinal?: () => void
	) => Promise<string> = async (fields, onFinal) => {
		const data = fields;
		return await axios.post(api.createInternship, data).then((res) => {
			if (res.status === 200) {
				startTransition(() => {
					onFinal?.();
				});
			}
			return res.data;
		});
	};

	return {
		submitRequest
	};
};

export const useUpdateInternship = (internship_id?: string) => {
	const query = useInternship(internship_id as string, {
		enabled: !!internship_id,
		staleTime: Infinity,
		refetchOnMount: false
	});
	const internship = query.data;

	// Update internship with given internship id
	const updateInternship: (
		internshipId: string,
		fields: Partial<InternshipFormData>,
		onFinal?: () => void
	) => Promise<void> = async (internshipId, fields, onFinal) => {
		const data: Partial<InternshipFormData> = {
			...internship,
			...fields
		};
		await axios
			.put(api.updateInternship(internshipId), data)
			.then((res) => {
				if (res.status === 200) {
					query?.refetch();
					startTransition(() => {
						toastify('toastify.internship-updated', {
							type: 'success'
						});
						onFinal?.();
					});
				}
			});
	};

	// Publish internship method
	const publishInternship: (
		internshipId: string,
		onFinal?: () => void
	) => Promise<void> = async (internshipId, onFinal) => {
		await axios
			.post(api.publishInternship(internshipId), {})
			.then((res) => {
				if (res.status === 200) {
					startTransition(() => {
						toastify('toastify.internship-published', {
							type: 'success'
						});
						onFinal?.();
					});
				}
			});
	};

	// Unpublish internship method
	const unPublishInternship: (
		internshipId: string,
		onFinal?: () => void
	) => Promise<void> = async (internshipId, onFinal) => {
		await axios
			.patch(api.unPublishInternship(internshipId), {})
			.then((res) => {
				if (res.status === 200) {
					startTransition(() => {
						toastify('toastify.internship-unPublished', {
							type: 'success'
						});
						onFinal?.();
					});
				}
			});
	};

	// Update and publish internship simultaneously
	const saveAndPublishInternship: (
		internshipId: string,
		fields: InternshipFormData,
		onFinal?: () => void
	) => Promise<void> = async (internshipId, fields, onFinal) => {
		const data: Partial<InternshipFormData> = {
			...internship,
			...fields
		};
		await axios
			.put(api.updateInternship(internshipId), data)
			.then(async (res) => {
				if (res.status === 200) {
					await publishInternship(internshipId, onFinal);
				}
			});
	};

	const updateInternshipFeature: (
		internshipId: string,
		url: {
			featuredPhotoUrl?: string;
			featuredVideoUrl?: string;
		},
		onFinal?: () => void
	) => Promise<void> = async (internshipId, url, onFinal) => {
		const data = {
			...(url.featuredPhotoUrl && {
				featuredPhotoUrl: url.featuredPhotoUrl
			}),
			...(url.featuredVideoUrl && {
				featuredVideoUrl: url.featuredVideoUrl
			})
		};
		await axios
			.patch(api.internshipFeature(internshipId), data)
			.then(async (res) => {
				if (res.status === 200) {
					query?.refetch();
					startTransition(() => {
						onFinal?.();
					});
				}
			});
	};

	const updateContactImage: (
		internshipId: string,
		fields: Partial<Media>,
		onFinal?: () => void
	) => Promise<void> = async (internshipId, fields, onFinal) => {
		const data = {
			image: fields.url,
			thumbnailUrl: fields.thumbnailUrl
		};
		await axios
			.patch(api.updateContactImage(internshipId), data)
			.then((res) => {
				if (res.status === 200) {
					onFinal?.();
				}
			});
	};

	const removeInternship: (
		internshipId: string,
		onFinal: () => void
	) => Promise<void> = async (internshipId, onFinal) => {
		await axios.delete(api.removeInternship(internshipId)).then((res) => {
			if (res.status === 200) {
				onFinal?.();
				startTransition(() => {
					toastify('toastify.internship-removed', {
						type: 'success'
					});
				});
			}
		});
	};

	return {
		...query,
		updateInternship,
		publishInternship,
		unPublishInternship,
		updateInternshipFeature,
		saveAndPublishInternship,
		updateContactImage,
		removeInternship,
		internship
	};
};


export const useInternshipQrCode = <T = string>(
	internshipId: string,
	options?: UseQueryOptions<T, any, string>
): UseQueryResult<string> => {
	return useQuery({
		queryKey: [queryKey.internshipQrCode, { id: internshipId }],
		queryFn: () => fetch.internshipQrCode(internshipId),
		...config(options)
	});
};

export const useDownloadInternshipQrCode = <T = string>(
	internshipId: string,
	options?: UseQueryOptions<T, any, string>
): UseQueryResult<string> => {
	return useQuery(
		{
			queryKey: [queryKey.downloadInternshipQrCode, { id: internshipId }],
			queryFn: () => fetch.downloadInternshipQrCode(internshipId),
			...config(options)
		}
	);
};