import { updateObj } from 'Utils';

enum ActionType {
	SET_FIELDS = 'SET_FIELDS'
}

// An interface for our actions
interface Action {
	type: ActionType;
	payload: any;
}

interface State {
	contactDescription: string;
	fullName: string;
	tel: string;
	email: string;
	position: string;
	contactImage: string;
	contactThumbnailImage: string;
}

// Reducer
// @ts-ignore
export const ProfileContactReducer: (state: State, action: Action) => State = (
	state,
	action
) => {
	const { payload, type } = action;
	if (type === ActionType.SET_FIELDS) {
		return updateObj(state, payload);
	}
	return state;
};

// Actions
export const setContactFields = (payload: State) => {
	return {
		type: ActionType.SET_FIELDS,
		payload
	};
};
