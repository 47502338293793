import { FC, memo } from 'react';
import { Badge } from 'Elements';
import { companyStatus, getFilterByValue } from 'Utils';
import { Colors, CompanyApproveStatus, CompanyProfileInitialData } from 'Types';
import { useTranslation } from 'react-i18next';

interface Props {
	profile: CompanyProfileInitialData;
}

export const ProfileDecorationCompanyBadge: FC<Props> = memo(({ profile }) => {
	const { t } = useTranslation();
	return (
		<>
			{profile?.approveStatus !== CompanyApproveStatus.Approved && (
				<Badge
					color={
						profile
							? getFilterByValue(
									companyStatus,
									profile.approveStatus.toString()
							  )?.color ?? Colors.primary
							: Colors.primary
					}>
					{profile
						? t(
								getFilterByValue(
									companyStatus,
									profile.approveStatus.toString()
								)?.label ?? ''
						  )
						: '...'}
				</Badge>
			)}
			{profile?.approveStatus === CompanyApproveStatus.Approved && (
				<Badge color={profile?.isActive ? Colors.green : Colors.yellow}>
					{profile?.isActive
						? t('badge.published')
						: t('badge.not-published')}
				</Badge>
			)}
		</>
	);
});

ProfileDecorationCompanyBadge.displayName = 'ProfileDecorationCompanyBadge';
