import { memo, FC, CSSProperties } from 'react';
import { clx } from 'Utils';
import { LoadingMask, SkeletonWrapper } from 'Elements';
import styles from 'partials/card/invite-company-card.module.scss';

interface Props {
	count?: number | string;
}

const Skeleton = memo(({ style }: { style: CSSProperties }) => {
	return (
		<div className={styles.card} style={style}>
			<LoadingMask className={styles.logoBox} />
			<div className="flex-grow-1">
				<LoadingMask
					className={clx(styles.loadingCol, styles.loadingColLg)}
				/>
				<LoadingMask
					className={clx(styles.loadingCol, styles.loadingColSm)}
				/>
			</div>
			<LoadingMask
				className={clx(styles.loadingCol, styles.loadingColSm)}
			/>
		</div>
	);
});

export const ChooseAgentToSwitchLoading: FC<Props> = memo(({ count = 8 }) => {
	return <SkeletonWrapper count={count} skeleton={Skeleton} />;
});

Skeleton.displayName = 'Skeleton';
ChooseAgentToSwitchLoading.displayName = 'ChooseAgentToSwitchLoading';
