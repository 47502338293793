import { memo, useReducer, useEffect, useMemo } from 'react';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { clx, formatLocalAddress } from 'Utils';
import { useModal, useUpdateCompany } from 'Hooks';
import { CompanySetting, Media } from 'Types';
import { ProfileHeroHeaderReducer, setHeroFields } from 'Reducers';
import {
	LoadingSpinner,
	Editor,
	ModalBoxChangeImage,
	EditButton,
	FontAwesome
} from 'Elements';
import styles from 'partials/homepage/section/profile-hero-header.module.scss';

export const CompanyHeroHeader = memo(() => {
	const sloganLimit = 90;
	const descriptionLimit = 480;
	const { t } = useTranslation();
	const initialState = useMemo(() => {
		return {
			companyText: `[${t('placeholder.company-name')}]`,
			companyOverviewDescription: `[${t(
				'placeholder.company-overview-description'
			)}]`,
			slogan: `[${t('placeholder.company-slogan')}]`
		};
	}, [t]);
	const defaultLogo = '/assets/img/company-default.jpg';
	const [openModal, toggleModal] = useModal();
	const { updateCompanySetting, company, setting, isLoading, isFetching } =
		useUpdateCompany();

	const [state, dispatch] = useReducer(
		ProfileHeroHeaderReducer,
		initialState
	);
	const address = company?.address;
	const headerTitle = setting?.companyText || company?.companyName;

	const handleUpdate: (
		field: Partial<CompanySetting>
	) => Promise<void> = async (field) => {
		dispatch(
			setHeroFields({
				...state,
				...field
			})
		);
		await updateCompanySetting(field);
	};

	const handleUploadImage = async (image_data: Media) => {
		if (image_data) {
			await updateCompanySetting({
				logo: image_data.url,
				thumbnailLogo: image_data.thumbnailUrl
			});
		}
	};

	const handleResetLogo = async () => {
		await updateCompanySetting({
			logo: '',
			thumbnailLogo: ''
		});
	};

	useEffect(() => {
		dispatch(
			setHeroFields(
				{
					companyText:
						headerTitle?.trim() || initialState.companyText,
					companyOverviewDescription:
						setting?.companyOverviewDescription?.trim() ||
						initialState.companyOverviewDescription,
					slogan: setting?.slogan?.trim() || initialState.slogan
				},
				'company'
			)
		);
	}, [
		headerTitle,
		setting?.companyOverviewDescription,
		setting?.slogan,
		dispatch,
		initialState
	]);

	return (
		<>
			{isLoading && (
				<div className="d-flex align-items-center justify-content-center">
					<LoadingSpinner />
				</div>
			)}
			{!isLoading && company && (
				<div className="ps-lg-4">
					<Row className="g-0 mb-4 ps-xxl-4">
						<Col lg={3} xxl={2}>
							<div
								className={styles.headerLogo}
								role="button"
								onClick={toggleModal}>
								<img
									src={setting?.thumbnailLogo || defaultLogo}
									alt={headerTitle}
									className={styles.logo}
								/>
								<div className="edit-overlay">
									<EditButton className="edit-overlay-icon" />
								</div>
								{isFetching && (
									<span className={styles.overlay}>
										<LoadingSpinner
											size="xl"
											color="#fff"
										/>
									</span>
								)}
							</div>
						</Col>
						<Col lg={9} xxl={10} className="ps-xxl-4">
							<div className="d-flex flex-column gap-2 justify-content-end h-100 pt-lg-5">
								{headerTitle && (
									<h3
										className={clx(
											styles.title,
											'fw-700 mb-0 text-break mt-5 pt-xl-3'
										)}>
										<Editor
											type="text"
											value={state.companyText ?? ''}
											validation={(val: string) =>
												val.length > 0
											}
											validationMessage={t(
												'validation.company-title.required'
											)}
											onSave={(val: string) =>
												handleUpdate({
													companyText: val
												})
											}
										/>
									</h3>
								)}
								{setting?.slogan && (
									<div className="d-flex text-gray-4 lh-md">
										<Editor
											type="text"
											wrapperClassName="w-100 w-sm-auto"
											value={state.slogan ?? ''}
											validation={(val: string) =>
												val.length < sloganLimit
											}
											validationMessage={t(
												'validation.max',
												{
													count: sloganLimit
												}
											)}
											onSave={(val: string) =>
												handleUpdate({
													slogan: val
												})
											}
										/>
									</div>
								)}
								{address && (
									<div className="d-flex align-items-center gap-2 text-gray-3 fs-sm lh-base">
										<FontAwesome
											icon='location-dot'
											size="lg"
											className="flex-shrink-0"
										/>
										<Editor
											type="text"
											value={formatLocalAddress(
												address.street,
												address.houseNo,
												address.postalCode,
												address.city,
												address.state
											)}
											navigationUrl="/my-company/address"
											onSave={() => false}
										/>
									</div>
								)}
							</div>
						</Col>
					</Row>
					<Row className="justify-content-end g-0 ps-xxl-4">
						<Col lg={9} xxl={10} className="ps-xxl-4">
							<Col xl={10}>
								<div className="fs-base text-gray-3 lh-md text-break">
									<Editor
										type="textarea"
										value={
											state.companyOverviewDescription ??
											''
										}
										validation={(val: string) =>
											val.length < descriptionLimit
										}
										validationMessage={t('validation.max', {
											count: descriptionLimit
										})}
										onSave={(val: string) =>
											handleUpdate({
												companyOverviewDescription: val
											})
										}
									/>
								</div>
							</Col>
						</Col>
					</Row>
				</div>
			)}
			<ModalBoxChangeImage
				name="logo"
				isOpen={openModal}
				title="title.change-logo"
				image={setting?.thumbnailLogo || ''}
				defaultImage="/assets/img/company-default.jpg"
				onClose={toggleModal}
				onRemove={handleResetLogo}
				onImageChange={handleUploadImage}
			/>
		</>
	);
});

CompanyHeroHeader.displayName = 'CompanyHeroHeader';
