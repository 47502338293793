import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { clx } from 'Utils';
import { Card, ToolboxConfigurator } from 'Elements';

interface Props {
	className?: string;
}
export const ToolboxConfigSection: FC<Props> = memo(({ className }) => {
	const { t } = useTranslation();

	return (
		<Card className={clx(className)}>
			<h6 className="fs-6 lh-base mb-3">
				{t('title.marketing.customize')}
			</h6>
			<ToolboxConfigurator />
		</Card>
	);
});

ToolboxConfigSection.displayName = 'ToolboxConfigSection';
