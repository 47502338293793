import reducers from '../sidebar/SidebarReducer';
import { createSlice } from '@reduxjs/toolkit';

interface SidebarState {
	isOpen: boolean;
}

export const initialState: SidebarState = {
	isOpen: false
};

const SidebarSlice = createSlice({
	name: 'sidebar',
	initialState,
	reducers
});

export const { toggleSidebar } = SidebarSlice.actions;

export default SidebarSlice.reducer;
