import { t } from 'i18next';
import { AxiosError } from 'axios';
import { Error } from 'Types';
import { toastify } from 'Utils';

const errorCode = [
	'11100',
	'11101',
	'1102',
	'1103',
	'1104',
	'1105',
	'1106',
	'1500',
	'1501',
	'1502',
	'1503',
	'1504',
	'1505',
	'1506',
	'1507',
	'1508',
	'1512',
	'1514',
	'1515',
	'1521',
	'12100',
	'2101',
	'2102',
	'2103',
	'2104',
	'2110',
	'2112',
	'2113',
	'2116',
	'2117',
	'3100',
	'3103',
	'3104',
	'3105',
	'3112',
	'3113',
	'3114',
	'3115',
	'3116',
	'4102',
	'13101',
	'13102',
	'13103',
	'13104',
	'13105',
	'13106',
	'13109',
	'14100',
	'14101',
	'15100',
	'15101',
	'5100',
	'5101',
	'5102',
	'5103',
	'5104',
	'5105',
	'16001',
	'16002',
	'16003',
	'6100',
	'6101',
	'6102',
	'6103',
	'6104',
	'6105',
	'6106',
	'6107',
	'6108',
	'6109',
	'6110',
	'6111',
	'6112',
	'6113',
	'6114',
	'6115',
	'6116',
	'6117',
	'6118',
	'6119',
	'6120',
	'6121',
	'6122',
	'6123',
	'6124',
	'6125',
	'6126',
	'6127',
	'6128',
	'6129',
	'17001',
	'17002',
	'17003',
	'17004',
	'8001',
	'8002'
];

/**
 * Displays an error notification based on the given Axios error.
 * @param error - The Axios error object.
 */
export const ErrorNotification = (error: AxiosError<Error>) => {
	let message = 'global.an-error-occurred';
	const errorData = error?.response?.data;
	const errorDetail = errorData?.detail as string;

	if (errorCode.includes(errorDetail)) {
		message = `error.${errorDetail}`;
	} else {
		switch (errorData?.status || error.status) {
			case 403:
				message = 'error.403.title';
				break;
			case 404:
				message = errorData?.detail
					? `error.${errorData?.detail}`
					: errorData?.title
					? `${errorData?.title}`
					: 'global.an-error-occurred';
				break;
				break;
			case 400:
			case 500:
				message =
					errorData?.title ||
					errorData?.detail ||
					'global.an-error-occurred';
				break;
			default:
				break;
		}
	}
	toastify(t(message));
};
