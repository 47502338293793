import { memo, FC, FormEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { useModal } from 'Hooks';
import {
	FeaturePhoto,
	FeatureVideo,
	ModalBoxChangeImage,
	StepNavigation
} from 'Elements';
import { AspectRatio, JobFormFeatured, Media } from 'Types';
import styles from 'partials/step/step-container.module.scss';

interface Props {
	fields: JobFormFeatured;
	onPrev: () => void;
	onNext: (data: JobFormFeatured) => void;
}

export const CreateJobFeatured: FC<Props> = memo(
	({ fields, onNext, onPrev }) => {
		const { t } = useTranslation();
		const [openModal, toggleModal] = useModal();
		const [featuredPhoto, setPhoto] = useState<Partial<Media>>({
			url: fields.featuredPhotoUrl,
			thumbnailUrl: fields.featuredPhotoUrl
		});
		const [featuredVideo, setVideo] = useState(fields.featuredVideoUrl);

		const onUploadPhoto = (image_data: Partial<Media>) => {
			if (image_data) setPhoto(image_data);
		};

		const handleRemovePhoto = () => {
			const defaultImage = {
				url: '',
				thumbnailUrl: ''
			};
			setPhoto(defaultImage);
		};

		const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
			e.preventDefault();
			onNext?.({
				featuredPhotoUrl: featuredPhoto.url ?? '',
				featuredVideoUrl: featuredVideo ?? ''
			});
		};

		return (
			<form onSubmit={handleSubmit} className={styles.form}>
				<Row>
					<Col xxl={8}>
						<FormGroup>
							<Label htmlFor="featuredPhoto">
								{t('forms.job.photo')}
							</Label>
							<FeaturePhoto
								image={featuredPhoto.url}
								onClick={toggleModal}
							/>
						</FormGroup>
						<FormGroup>
							<Label htmlFor="featuredPhoto">
								{t('forms.job.video')}
							</Label>
							<FeatureVideo
								videoUrl={featuredVideo}
								onUpload={(videoUrl) => setVideo(videoUrl)}
								onRemove={() => setVideo('')}
							/>
						</FormGroup>
						<StepNavigation onPrev={onPrev} />
					</Col>
				</Row>
				<ModalBoxChangeImage
					name="featuredPhotoUrl"
					title="title.change-image"
					isOpen={openModal}
					aspectRatio={AspectRatio['16/9']}
					image={featuredPhoto?.url || ''}
					onClose={toggleModal}
					onRemove={handleRemovePhoto}
					onImageChange={onUploadPhoto}
				/>
			</form>
		);
	}
);

CreateJobFeatured.displayName = 'CreateJobFeatured';
