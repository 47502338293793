import { memo, FC } from 'react';
import { Container } from 'reactstrap';
import styled from 'styled-components';
import { AgentPartnerJobSection, CompanyJobSection } from 'Elements';
import styles from 'partials/homepage/section/profile-job-section.module.scss';
import { AgentProfileFormData } from 'Types';

interface Props {
	type: 'agent' | 'company';
	jobSectionStatus: boolean;
	agent?: AgentProfileFormData;
}

const Decoration = styled.div<{ profileType: 'agent' | 'company' }>`
	${({ theme, profileType }) =>
		theme.company.primaryColor &&
		`&:after {
            background-color: ${theme[profileType].primaryColor}
        }`}
`;

export const ProfileJobSection: FC<Props> = memo(
	({ type, jobSectionStatus, agent }) => {
		return (
			<section className={styles.wrapper}>
				<Decoration profileType={type} className={styles.decoration}>
					<img
						src="/assets/img/job/job-bg.webp"
						alt=""
						className="w-100"
					/>
				</Decoration>
				<Container>
					{type === 'agent' && <AgentPartnerJobSection agent={agent} />}
					{type === 'company' && <CompanyJobSection />}
				</Container>
				{!jobSectionStatus && (
					<div className="overlay overlay_white overlay-box" />
				)}
			</section>
		);
	}
);

ProfileJobSection.displayName = 'ProfileJobSection';
