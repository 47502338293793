import { FC, memo, useEffect, useState } from 'react';
import { Col, Row, Tooltip } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDelayUnmount, useModal, useUpdateSubscription } from 'Hooks';
import { clx, currencyFormatter, formatLocaleDateTime, subStatus } from 'Utils';
import {
	Badge,
	Button,
	LoadingContent,
	ModalBoxPartnerApprovalFeedback,
	ModalBoxSwitchAgentList,
	SwitchController,
	FontAwesome
} from 'Elements';
import {
	AgentPartnerApprovalStatus,
	ColorValues,
	Colors,
	CustomerDto,
	SubscriptionDetails,
	SubscriptionStatus
} from 'Types';
import styles from 'partials/card/subscription-card.module.scss';

interface Props {
	subscription: SubscriptionDetails;
	subscriptionId: string;
	isLoading: boolean;
	canAutoRenew?: boolean;
	agent?: CustomerDto;
	showPrice?: boolean;
}

export const DetailsSubscription: FC<Props> = memo(
	({
		subscription,
		subscriptionId,
		isLoading,
		agent,
		canAutoRenew = true,
		showPrice = true
	}) => {
		const { t } = useTranslation();
		const navigate = useNavigate();
		const [tooltipOpen, setTooltipOpen] = useState(false);
		const [openModalSwitch, toggleSwitchModal] = useModal();
		const [openModalFeedback, toggleFeedbackModal] = useModal();
		const shouldRenderModalSwitch = useDelayUnmount(openModalSwitch, 350);
		const shouldRenderModalReason = useDelayUnmount(openModalFeedback, 350);
		const status = subscription?.status?.approveStatus;
		const agentApprovedStatus = subscription?.agentApprovedStatus;
		const agentApprovalDescription =
			subscription?.agentApprovalStatus?.description ?? '';
		const canAutoRenewAndSwitch =
			canAutoRenew && status === SubscriptionStatus.active;
		const canSwitchAgent =
			canAutoRenewAndSwitch &&
			// !subscription?.isSubCompanySubscription &&
			agentApprovedStatus !== AgentPartnerApprovalStatus.pending;
		const isRejected =
			agentApprovedStatus === AgentPartnerApprovalStatus.rejected;
		const isApproved =
			agentApprovedStatus === AgentPartnerApprovalStatus.approved;
		const row_class = 'justify-content-between justify-content-md-start';
		const defaultImage = '/assets/img/company-default.jpg';

		const { updateSubscription, refetch } = useUpdateSubscription(
			subscriptionId as string,
			{ enabled: !!subscriptionId && canAutoRenew }
		);

		const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

		const getStatusData = () => {
			return subStatus.find(
				(elem) => elem.value.toString() === status?.toString()
			);
		};

		const { reset, control } = useForm();

		const handleUpdate = async (autoRenewal: boolean) => {
			await updateSubscription(autoRenewal, undefined, () =>
				reset({
					autoRenewal: subscription?.autoRenewal ?? true
				})
			);
		};

		const handleSwitchAgent = () => {
			toggleSwitchModal();
			void refetch();
		};

		const handleViewSwitchedSub = () => {
			navigate(
				`/subscriptions/${subscription.subCompanySubscription.id}`,
				{
					state: { prevPath: `/subscriptions/${subscription.id}` }
				}
			);
		};

		useEffect(() => {
			reset({
				autoRenewal: subscription?.autoRenewal
			});
		}, [reset, subscription]);

		return (
			<Row>
				<Col md={12} xl={canAutoRenewAndSwitch ? 9 : 12}>
					<Row>
						<Col md={5}>
							<div className={clx(styles.row, row_class)}>
								<span className={styles.rowLabel}>
									{t('label.name')}:
								</span>
								<span className="text-primary fs-6 fw-700">
									{subscription?.title ?? '---'}
								</span>
							</div>
							<div className={clx(styles.row, row_class)}>
								<span className={styles.rowLabel}>
									{t('label.product-code')}:
								</span>
								<span>{subscription?.code ?? '---'}</span>
							</div>
							{showPrice && (
								<div className={clx(styles.row, row_class)}>
									<span className={styles.rowLabel}>
										{t('label.price')}:
									</span>
									<span className="fw-500">
										{currencyFormatter(
											subscription?.price ?? 0
										)}
									</span>
								</div>
							)}
							<div className={clx(styles.row, row_class)}>
								<span className={styles.rowLabel}>
									{t('label.status')}:
								</span>
								<Badge
									color={
										getStatusData()?.color ?? Colors.muted
									}>
									{t(`${getStatusData()?.label}`)}
								</Badge>
							</div>
						</Col>
						<Col md={7}>
							<div className={clx(styles.row, row_class)}>
								<span
									className={clx(
										styles.rowLabel,
										'fs-sm fw-400 color-gray-4'
									)}>
									{t('label.purchase-date')}:
								</span>
								<span>
									{subscription?.purchaseDate
										? formatLocaleDateTime(
												subscription?.purchaseDate
										  )
										: '---'}
								</span>
							</div>
							<div className={clx(styles.row, row_class)}>
								<span
									className={clx(
										styles.rowLabel,
										'fs-sm fw-500 color-gray-4'
									)}>
									{status === SubscriptionStatus.active &&
										(subscription?.autoRenewal
											? t('title.renew-at')
											: t('title.expire-on'))}
									{(status === SubscriptionStatus.pending ||
										status ===
											SubscriptionStatus.pendingPayment ||
										status ===
											SubscriptionStatus.expired) &&
										t('title.expire-on')}
									{status === SubscriptionStatus.inactive &&
										t('title.switch-on')}
								</span>
								<span className="fw-500">
									{status === SubscriptionStatus.active &&
										(subscription?.renewalDate
											? `${formatLocaleDateTime(
													subscription?.renewalDate,
													false
											  )} (${
													subscription?.remainDays
											  } ${t('label.days')})`
											: `${formatLocaleDateTime(
													subscription?.endDate,
													false
											  )} (${
													subscription?.remainDays
											  } ${t('label.days')})`)}
									{(status === SubscriptionStatus.pending ||
										status ===
											SubscriptionStatus.pendingPayment ||
										status ===
											SubscriptionStatus.expired) &&
										formatLocaleDateTime(
											subscription?.endDate,
											false
										)}
									{status === SubscriptionStatus.inactive &&
										formatLocaleDateTime(
											subscription?.status.updatedAt,
											false
										)}
								</span>
							</div>
							<div
								className={clx(
									styles.row,
									row_class,
									canSwitchAgent && 'gap-2'
								)}>
								<span className={styles.rowLabel}>
									{t('title.agent')}:
								</span>
								{agent && (
									<div className="d-flex align-items-center flex-grow-1 justify-content-end justify-content-md-start gap-2">
										<img
											src={agent.logo || defaultImage}
											alt={agent.name ?? '---'}
											className={clx(
												styles.logo,
												styles.logoSm
											)}
										/>
										<span className="fs-sm fw-400">
											{agent.name ?? '---'}
										</span>
										{agent?.commissionGroup?.isPremium && (
											<Badge
												title={t(
													'title.commission-group.key',
													{
														commissionGroupName:
															agent
																?.commissionGroup
																.label
													}
												)}
												color={Colors.premium}
												className="gap-2">
												<FontAwesome
													icon={'medal'}
													size="lg"
												/>
												{agent?.commissionGroup.label}
											</Badge>
										)}
										{agentApprovedStatus ===
											AgentPartnerApprovalStatus.pending && (
											<Badge color={Colors.warning}>
												{t('badge.pending-approval')}
											</Badge>
										)}
										{isRejected && (
											<Badge color={Colors.red}>
												{t('badge.rejected')}
											</Badge>
										)}
										{agentApprovalDescription &&
											(isApproved || isRejected) && (
												<Button
													color={Colors.empty}
													className="p-0"
													id="bp-approval"
													onClick={
														toggleFeedbackModal
													}>
													<FontAwesome
														icon="info-circle"
														color={
															isApproved
																? ColorValues.green
																: ColorValues.red
														}
														size="lg"
													/>
													<Tooltip
														toggle={toggleTooltip}
														isOpen={tooltipOpen}
														placement="top"
														target="bp-approval">
														{t(
															'title.company-approval-explanation'
														)}
													</Tooltip>
												</Button>
											)}
									</div>
								)}
								{agent?.commissionGroup?.isPremium &&
								status === SubscriptionStatus.active
									? ''
									: (canSwitchAgent || isRejected) &&
									  status !== 4 && (
											<Button
												size="sm"
												color={Colors['white-primary']}
												label={
													t(
														'button.switch-agent'
													) as string
												}
												onClick={toggleSwitchModal}
											/>
									  )}
							</div>
							{status === SubscriptionStatus.inactive &&
								subscription?.subCompanySubscription && (
									<div
										className={clx(
											styles.row,
											row_class,
											'gap-2 gap-xxl-4'
										)}>
										<div className="d-flex align-items-center">
											<span className={styles.rowLabel}>
												{t('title.switched-agent')}:
											</span>
											<div className="d-flex align-items-center justify-content-end justify-content-md-start flex-grow-1 flex-wrap gap-2">
												<img
													src={
														subscription
															.subCompanySubscription
															.agent.logo ||
														defaultImage
													}
													alt={
														subscription
															.subCompanySubscription
															.agent.name ?? '---'
													}
													className={clx(
														styles.logo,
														styles.logoSm
													)}
												/>
												<span className="fs-sm fw-400">
													{subscription
														.subCompanySubscription
														.agent.name ?? '---'}
												</span>
											</div>
										</div>
										<Button
											size="sm"
											color={Colors['white-primary']}
											onClick={handleViewSwitchedSub}>
											<FontAwesome
												icon="eye"
												size="lg"
												className="me-2 flex-shrink-0"
											/>
											{t('button.view-sub')}
										</Button>
									</div>
								)}
						</Col>
					</Row>
				</Col>
				{canAutoRenewAndSwitch && (
					<Col md={12} xl={3} className="mt-4 mt-md-4 mt-xl-0">
						<div className={clx(styles.row, 'border-0 gap-2')}>
							<span className={styles.rowLabel}>
								{t('forms.auto-renewal')}:
							</span>
							<SwitchController
								control={control}
								name="autoRenewal"
								label={
									subscription?.autoRenewal
										? 'label.active'
										: 'label.inactive'
								}
								onSwitchChange={handleUpdate}
							/>
						</div>
						<p className="mt-2 mb-0 text-gray-3 lh-md fs-small">
							{t('text.auto-renewal-terms')}
						</p>
					</Col>
				)}
				{isLoading && <LoadingContent />}
				{shouldRenderModalSwitch && (
					<ModalBoxSwitchAgentList
						isOpen={openModalSwitch}
						onClose={toggleSwitchModal}
						subscriptionId={subscriptionId}
						onSelectAgent={handleSwitchAgent}
					/>
				)}
				{shouldRenderModalReason && (
					<ModalBoxPartnerApprovalFeedback
						isOpen={openModalFeedback}
						onClose={toggleFeedbackModal}
						description={
							subscription.agentApprovalStatus.description
						}
					/>
				)}
			</Row>
		);
	}
);

DetailsSubscription.displayName = 'DetailsSubscription';
