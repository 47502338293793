import { memo, FC } from 'react';
import { clx } from 'Utils';
import { IStep } from 'Types';
import { StepItem } from 'Elements';
import styles from 'partials/step/step-container.module.scss';

interface Props {
	steps: IStep[];
	currentIndex: number;
	className?: string;
}

export const StepContainer: FC<Props> = memo(
	({ steps, currentIndex, className }) => {
		return (
			<div className={clx(styles.box, className)}>
				<ul className={styles.step}>
					{steps.map((step, idx) => {
						return (
							<StepItem
								key={step.id}
								title={step.title}
								number={idx + 1}
								currentIndex={currentIndex}
							/>
						);
					})}
				</ul>
			</div>
		);
	}
);

StepContainer.displayName = 'StepContainer';
