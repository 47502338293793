import { UseControllerProps, FieldValues } from 'react-hook-form';
import { SwitchProps } from 'rc-switch';
import { clx } from 'Utils';
import { SwitchController } from 'Elements';
import styles from 'partials/homepage/section-switcher.module.scss';

type Props<T extends FieldValues> = {
	isActive: boolean;
	label: string;
	wrapperClassName?: string;
	onSwitchChange: (val: boolean) => void;
} & UseControllerProps<T> &
	SwitchProps;

export const SectionSwitcher = <T extends FieldValues>({
	isActive,
	control,
	name,
	label,
	wrapperClassName,
	onSwitchChange,
	...switchProps
}: Props<T>) => {
	return (
		<div
			className={clx(
				styles.switch,
				isActive && styles.active,
				wrapperClassName
			)}>
			<SwitchController
				{...switchProps}
				control={control}
				name={name}
				label={label}
				side="left"
				size="md"
				labelClassName="fw-500"
				onSwitchChange={onSwitchChange}
			/>
		</div>
	);
};

SectionSwitcher.displayName = 'SectionSwitcher';
