import { memo, FC, useState, ChangeEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { clx, sortArrayByProperty } from 'Utils';
import { ColorPicker, RadioColor, ToolboxConfigGroup,FontAwesome } from 'Elements';
import { AssetConfigElement, AssetConfigType, ColorElement } from 'Types';
import styles from 'partials/marketing/toolbox-config.module.scss';

interface Props {
	element: AssetConfigElement<ColorElement>;
	errors?: AssetConfigType;
	name: 'textBgColor' | 'textColor';
	sectionTitle: string;
	defaultColor?: string;
	isSelected?: boolean;
	onSelect: (color: ColorElement) => void;
	onSelectCustom?: (color: string) => void;
	onSelectNoColor?: () => void;
}

export const ColorElementList: FC<Props> = memo(
	({
		element,
		name,
		sectionTitle,
		errors,
		defaultColor,
		onSelect,
		onSelectCustom,
		onSelectNoColor
	}) => {
		const { t } = useTranslation();
		const [customColor, setCustomColor] = useState('#fff');

		const handleCustomColorChange = useCallback(
			(e: ChangeEvent<HTMLInputElement>) => {
				const color = e.target.value;
				setCustomColor(color);
				onSelectCustom?.(color);
			},
			[onSelectCustom]
		);

		const handleSelectColor = (color: ColorElement) => {
			setCustomColor('#fff');
			onSelect(color);
		};

		const handleNoColorChange = () => {
			setCustomColor('#fff');
			onSelectNoColor?.();
		};

		return (
			<ToolboxConfigGroup
				title={t(sectionTitle)}
				isRequired={element.isRequired}
				errors={errors}>
				<div className={styles.imageGrid}>
					{sortArrayByProperty(element.inputItems, 'hex')
						.reverse()
						.map((color) => (
							<RadioColor
								id={color.hex}
								key={color.hex}
								bgColor={color.hex}
								name={name}
								label={color.name}
								checked={color.hex === defaultColor}
								onChange={() => handleSelectColor(color)}
							/>
						))}
					<ColorPicker
						id={customColor}
						name={`custom_${name}`}
						label={t('label.marketing.custom-color')}
						bgColor={customColor}
						onChange={handleCustomColorChange}
					/>
					{!element.isRequired && (
						<label
							className="d-flex align-items-center cursor-pointer gap-2"
							onClick={handleNoColorChange}>
							<span
								role="button"
								className={clx(
									'form-input-color border-2 bg-white d-flex align-items-center justify-content-center text-center',
									defaultColor === '' && 'border-primary'
								)}>
								<FontAwesome
									icon='droplet-slash'
									size="1x"
								/>
							</span>
							{t('label.marketing.no-color')}
						</label>
					)}
				</div>
			</ToolboxConfigGroup>
		);
	}
);

ColorElementList.displayName = 'ColorElementList';
