import { config, fetch } from 'Utils';
import { useTranslation } from 'react-i18next';
import { UseQueryOptions, UseQueryResult, useQuery } from 'react-query';
import { queryKey } from 'Constants';
import { LinkedinConfig, ValidateLinkedinTokenResponse } from 'Types';

const queryConfig = (options = {}) => {
	return {
		...{ keepPreviousData: false },
		...options
	};
};

export const useLinkedin = () => {
	useTranslation();
	// const { sendRequest: getTokenRequest } = useAxios<string>();

	const getLinkedinToken: (
		field: LinkedinConfig,
		returnUrl?: string,
		onFinal?: () => void
	) => string | void = (
		field,
		returnUrl = `http://localhost:3018/integration`
	) => {
			const redirectUrl = `https://www.linkedin.com/oauth/v2/authorization?client_id=${field.clientIdentify}&client_secret=${field.clientSecret}&scope=openid,profile,w_member_social,email&redirect_uri=${returnUrl}&response_type=code`;

			const popup = window.open(redirectUrl, '_blank', 'popup');
			let code: string | undefined;
			const checkPopup = setInterval(() => {
				const popupCurrentUrl = popup?.window?.location?.href ?? '';
				const searchParams = new URL(popupCurrentUrl as string)
					.searchParams;
				code = searchParams.get('code')?.toString();
				if (
					code &&
					popupCurrentUrl?.includes(config.redirectUrl as string)
				) {
					localStorage.setItem('linkedin-access-token', code);
					// onFinal?.();
					popup?.close();
					return code;
				}
				if (!popup || !popup.closed) return;
				clearInterval(checkPopup);
			}, 500);
			if (!popup || !popup.closed) return;
			// return code;
		};

	return {
		getLinkedinToken
	};
};

export const useValidateLinkedinToken = <T = ValidateLinkedinTokenResponse>(
	accessToken: string,
	options?: UseQueryOptions<T, any, ValidateLinkedinTokenResponse>
): UseQueryResult<ValidateLinkedinTokenResponse, unknown> => {
	return useQuery(
		{
			queryKey: [queryKey.linkedinToken, { accessToken }],
			queryFn: () => fetch.linkedinAuthToken(accessToken),
			...queryConfig(options)
		}
	);
};
