import { FC, memo } from 'react';
import { ColorValues } from 'Types';

interface Props {
	fill?: string;
	className?: string;
	size?: number;
}

export const IconSocialMedia: FC<Props> = memo(
	({
		fill = ColorValues.primary,
		className,
		size = 100,
		...props
	}): JSX.Element => {
		return (
			<svg
				width={size}
				height={size}
				viewBox="0 0 100 100"
				xmlns="http://www.w3.org/2000/svg"
				className={className}
				{...props}>
				<g transform="translate(24.96 7.588)">
					<rect
						width="100"
						height="100"
						transform="translate(-24.96 -7.588)"
						fill="#fff"
						opacity="0"
					/>
					<rect
						width="50.342"
						height="82.199"
						rx="8"
						transform="translate(0.04)"
						fill={fill}
					/>
					<rect
						width="35.659"
						height="58.376"
						rx="4"
						transform="translate(7.04 7.449)"
						fill="#fff"
					/>
					<circle
						cx="4.5"
						cy="4.5"
						r="4.5"
						transform="translate(21.04 69.024)"
						fill={fill}
					/>
					<g transform="translate(-0.499)">
						<path
							d="M113.832,169.459H133.4"
							transform="translate(-98.292 -143.424)"
							fill={ColorValues['tertiary-light']}
							stroke={ColorValues.tertiary}
							strokeLinecap="round"
							strokeWidth="2"
						/>
						<path
							d="M113.832,166.181h10.992"
							transform="translate(-98.292 -147.291)"
							fill={ColorValues['tertiary-light']}
							stroke={ColorValues.tertiary}
							strokeLinecap="round"
							strokeWidth="2"
						/>
					</g>
				</g>
			</svg>
		);
	}
);

IconSocialMedia.displayName = 'IconSocialMedia';
