import { RefObject, useEffect } from 'react';

/**
 * Hook that handles outside click event of the passed refs
 *
 * @param ref ref
 * @param handler a handler function to be called when clicked outside
 */
export const useOutsideClick = (
	ref: RefObject<HTMLElement>,
	handler: () => void
) => {
	useEffect(() => {
		let startedInside = false;
		let startedWhenMounted = false;
		// const handleClickOutside = (event: any) => {
		// 	if (!handler) return;

		// 	// Clicked browser's scrollbar
		// 	if (
		// 		event.target === document.getElementsByTagName('html')[0] &&
		// 		event.clientX >= document.documentElement.offsetWidth
		// 		)
		// 		return;

		// 		let containedToAnyRefs = false;
		// 		if (ref && ref.current && ref.current.contains(event.target)) {
		// 			containedToAnyRefs = true;
		// 	}
		// 	// for (const rf of refs) {
		// 		// }

		// 		// Not contained to any given refs
		// 	if (!containedToAnyRefs) {
		// 		handler();
		// 	}
		// };

		const listener = (event: any) => {
			if (!handler) return;
			// Do nothing if `mousedown` or `touchstart` started inside ref element
			if (startedInside || !startedWhenMounted) return;
			// Do nothing if clicking ref's element or descendent elements
			if (!ref.current || ref.current.contains(event.target)) return;

			handler();
		};

		const validateEventStart = (event: any) => {
			startedWhenMounted = !!ref.current;
			startedInside = !!(
				ref.current && ref.current.contains(event.target)
			);
		};

		// Bind the event listener
		document.addEventListener('mousedown', validateEventStart);
		document.addEventListener('touchstart', validateEventStart);
		document.addEventListener('click', listener);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', validateEventStart);
			document.removeEventListener('touchstart', validateEventStart);
			document.removeEventListener('click', listener);
		};
	}, [ref, handler]);
};
