import { FC, ReactNode, memo } from 'react';
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { clx } from 'Utils';
import {  FontAwesome } from 'Elements';
import styles from 'partials/shared/dropdown/decoration-dropdown.module.scss';

interface Props {
	children: ReactNode;
	className?: string;
}

export const DecorationDropDown: FC<Props> = memo(({ children, className }) => {
	return (
		<UncontrolledDropdown
			className={clx(className, 'd-inline-flex flex-shrink-0')}>
			<DropdownToggle tag="span" role="button">
				<FontAwesome
					icon='ellipsis-vertical'
					size="xl"
					width={20}
					fixedWidth
					className="text-gray-4"
				/>
			</DropdownToggle>
			<DropdownMenu className={styles.menu} end>
				{children}
			</DropdownMenu>
		</UncontrolledDropdown>
	);
});

DecorationDropDown.displayName = 'DecorationDropDown';
