import { Controller, UseControllerProps } from 'react-hook-form';
import type { FieldValues } from 'react-hook-form/dist/types';
import { DatePicker } from 'Elements';
import { clx, getCurrentLocale } from 'Utils';

type Props<T extends FieldValues> = {
	className?: string;
	dateFormat?: string;
	onDateChange?: (val: Date) => void;
	[x: string]: any;
} & UseControllerProps<T>;

export const DatePickerController = <T extends FieldValues>({
	control,
	name,
	error,
	rules,
	className,
	dateFormat,
	onDateChange,
	...datePickerProps
}: Props<T>): JSX.Element => {
	const getLocalFormat = () => {
		const currentLocale = getCurrentLocale().code;
		if (currentLocale === 'de') {
			return 'dd.MM.yyyy';
		}
		return 'yyyy-MM-dd';
	};

	return (
		<div className={clx(className)}>
			<Controller
				control={control}
				name={name}
				rules={rules}
				render={({ field: { onChange, value } }) => (
					<DatePicker
						dateFormat={dateFormat ?? getLocalFormat()}
						locale={getCurrentLocale().lang}
						className={clx(
							'inputbox w-100',
							!!error && 'has-error'
						)}
						value={Date.parse(value) || ''}
						onChange={(val: Date) => {
							onChange(val);
							onDateChange?.(val);
						}}
						{...datePickerProps}
					/>
				)}
			/>
			{error && (
				<div className="invalid-feedback d-block">{error.message}</div>
			)}
		</div>
	);
};

DatePickerController.displayName = 'DatePickerController';
