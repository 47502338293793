import { FC, Fragment, memo } from 'react';
import { clx } from 'Utils';
import { Job } from 'Types';
import { IconPlayer } from 'Elements';
import styles from 'partials/job/preview/job-image-section.module.scss';

interface Props {
	job: Job;
	jobImage?: string;
}

export const JobImageSection: FC<Props> = memo(({ job, jobImage }) => {
	const defaultImage = '/assets/img/default-image.jpg';

	const image = job?.featuredPhotoUrl || jobImage || defaultImage;
	const video = job?.featuredVideoUrl;

	return (
		<Fragment>
			<div
				className={clx(
					styles.imageWrapper,
					video && styles.video,
					'position-relative p-3 p-lg-0'
				)}>
				<img src={image} alt={job?.title} className={styles.image} />
				{video && (
					<div className={styles.play_icon}>
						<IconPlayer />
					</div>
				)}
			</div>
		</Fragment>
	);
});
JobImageSection.displayName = 'JobImageSection';
