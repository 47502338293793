import styled from 'styled-components';

interface Style {
	textColor: string;
}

export const Title = styled.h3<Style>`
	color: ${({ textColor }) => textColor};
`;

export const Text = styled.p<Style>`
	color: ${({ textColor }) => textColor};
`;
