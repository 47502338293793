import { memo, startTransition, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { rgba } from 'polished';
import { Container, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { Colors, CompanySetting } from 'Types';
import {
	useDelayUnmount,
	useModal,
	useUpdateBenefits,
	useUpdateCompany
} from 'Hooks';
import {
	ProfileBenefitCard,
	Button,
	LoadingSpinner,
	ModalBoxAddBenefit,
	SectionSwitcher,
	SectionHeadline,
	FontAwesome
} from 'Elements';
import styles from 'partials/homepage/section/benefit-section.module.scss';

const GridBox = styled('div')`
	background-color: ${({ theme }) =>
		theme && rgba(theme.company.primaryColor, 0.3)};
	border-color: ${({ theme }) => theme && theme.company.primaryColor};
`;

export const CompanyBenefitSection = memo(() => {
	const perRow = 8;
	const { t } = useTranslation();
	const { control, reset } = useForm();
	const [next, setNext] = useState(perRow);
	const [openModal, toggleModal] = useModal();
	const [isRemoving, setIsRemoving] = useState(false);
	const { updateBenefits } = useUpdateBenefits();
	const {
		setting,
		company,
		refetch,
		updateCompanySetting,
		isLoading: isLoadingSetting
	} = useUpdateCompany();
	const shouldRenderModal = useDelayUnmount(openModal, 350);

	const handleMoreBenefit = () => {
		setNext(next + perRow);
	};

	const handleRemoveBenefit = async (benefitId: string) => {
		const filteredBenefits = company?.companyBenefitDto?.filter(
			(benefit) => benefit.id !== benefitId
		);
		const api_data = {
			benefitList:
				filteredBenefits?.map((benefit) => {
					return { benefitId: benefit.id };
				}) ?? []
		};
		try {
			setIsRemoving(true);
			await updateBenefits(api_data, () => {
				refetch();
			});
		} finally {
			startTransition(() => {
				setIsRemoving(false);
			});
		}
	};

	const handleUpdate = async (field: Partial<CompanySetting>) => {
		await updateCompanySetting(field);
	};

	useEffect(() => {
		reset(setting);
	}, [reset, setting]);

	return (
		<section className="position-relative mb-5 pb-5">
			{isLoadingSetting && (
				<div className="d-flex align-items-center justify-content-center">
					<LoadingSpinner />
				</div>
			)}
			{!isLoadingSetting && setting && company && (
				<Container>
					<SectionSwitcher
						control={control}
						name="benefitSectionStatus"
						label="forms.show-benefits-in-home"
						isActive={!setting?.benefitSectionStatus}
						onSwitchChange={(val) =>
							handleUpdate({ benefitSectionStatus: val })
						}
					/>
					<GridBox className={styles.grid}>
						<SectionHeadline
							title={
								setting?.benefitTitle ||
								`[${t('placeholder.our-benefits')}]`
							}
							description={
								setting?.benefitDescription ||
								`[${t('placeholder.benefit-description')}]`
							}
							className="mx-auto text-center"
							onUpdateTitle={(val) =>
								handleUpdate({ benefitTitle: val })
							}
							onUpdateDescription={(val) =>
								handleUpdate({ benefitDescription: val })
							}
						/>
						<Row className="g-0">
							{company?.companyBenefitDto &&
								company?.companyBenefitDto
									?.slice(0, next)
									?.map((benefit) => {
										return benefit.benefitTranslates.map(
											(item) => {
												if (
													item.language.toLowerCase() ===
													'de'
												) {
													return (
														<ProfileBenefitCard
															key={benefit.id}
															benefit={item}
															isRemoving={
																isRemoving
															}
															onClick={() =>
																handleRemoveBenefit(
																	benefit.id
																)
															}
														/>
													);
												}
											}
										);
									})}
						</Row>
						<div className="d-flex align-items-center justify-content-center mt-4 gap-3">
							{company?.companyBenefitDto?.length > next && (
								<Button
									size="lg"
									color={Colors['gray-5']}
									className="fs-base rounded-pill"
									label={t('button.more-benefits') as string}
									onClick={handleMoreBenefit}
								/>
							)}
							<Button
								size="lg"
								className="gap-3 rounded-pill"
								onClick={toggleModal}>
								<FontAwesome
									icon="plus"
									size="xl"
									swapOpacity
								/>
								{t('title.add-new-benefit')}
							</Button>
						</div>
					</GridBox>
				</Container>
			)}
			{!isLoadingSetting && !setting?.benefitSectionStatus && (
				<div className="overlay overlay_white overlay-box" />
			)}
			{shouldRenderModal && company && (
				<ModalBoxAddBenefit
					isOpen={openModal}
					onClose={toggleModal}
					title={t('title.choose-benefit')}
					selectedBenefits={company?.companyBenefitDto}
				/>
			)}
		</section>
	);
});

CompanyBenefitSection.displayName = 'CompanyBenefitSection';
