import { memo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { clx } from 'Utils';
import { names } from 'Constants';
import { CompanySetting, Colors } from 'Types';
import { useInternships, useUpdateCompany } from 'Hooks';
import {
	ProfileInternshipCard,
	Button,
	LoadingSpinner,
	ProfileAddNewCard,
	SectionSwitcher,
	Editor,
	FontAwesome
} from 'Elements';
import styles from 'partials/homepage/section/profile-job-section.module.scss';

export const CompanyInternshipSection = memo(() => {
	const perRow = 3;
	const { t } = useTranslation();
	const { control, reset } = useForm();
	const [next, setNext] = useState(perRow);
	const navigate = useNavigate();
	const { jobs } = names;
	const addInternshipUrl = jobs.internships.add?.path as string;

	const {
		setting,
		updateCompanySetting,
		isLoading: isLoadingSetting
	} = useUpdateCompany();

	const { data: internships, isLoading } = useInternships({
		pageNumber: 1,
		pageSize: 200
	});

	const handleMore = () => {
		setNext(next + perRow);
	};

	const handleNavigate = () => {
		navigate(addInternshipUrl, { state: { prevPath: '/homepage' } });
	};

	const handleUpdate = async (field: Partial<CompanySetting>) => {
		await updateCompanySetting(field);
	};

	useEffect(() => {
		reset(setting);
	}, [reset, setting]);

	return (
		<>
			{isLoading && (
				<div className="d-flex align-items-center justify-content-center">
					<LoadingSpinner />
				</div>
			)}
			{!isLoading && !isLoadingSetting && setting && internships && (
				<div className="position-relative">
					<SectionSwitcher
						control={control}
						isActive={!setting?.internshipListStatus}
						name="internshipListStatus"
						label="forms.show-agent-internships-in-home"
						wrapperClassName={clx(styles.switch, 'mt-3')}
						onSwitchChange={(val) =>
							handleUpdate({ internshipListStatus: val })
						}
					/>
					<h3 className="h3 text-gray-5 mb-5 pb-4 lh-base d-inline-flex">
						<Editor
							type="text"
							value={
								setting?.internshipOverviewHeadLine ||
								`[${t('placeholder.internship-list-headline')}]`
							}
							onSave={(val) =>
								handleUpdate({
									internshipOverviewHeadLine: val
								})
							}
						/>
					</h3>
					{internships?.items?.length > 0 && (
						<Row className="justify-content-center">
							{internships.items?.slice(0, next)?.map((internship) => {
								return (
									<Col
										md={6}
										lg={4}
										key={internship.id}
										className="mb-4">
										<ProfileInternshipCard
											internship={internship}
											type="company"
											profileImage={
												setting.companyHeaderLogo
											}
										/>
									</Col>
								);
							})}
						</Row>
					)}
					<div className="d-flex align-items-center justify-content-center mt-3 gap-3">
						{internships.items?.length > next && (
							<Button
								size="lg"
								color={Colors['border-gray-4']}
								label={t('button.more-internships') as string}
								className="rounded-pill"
								onClick={handleMore}
							/>
						)}
						{internships.totalItems > 0 && (
							<Button
								size="lg"
								className="gap-3 rounded-pill"
								onClick={handleNavigate}>
								<FontAwesome
									icon="plus"
									size="xl"
									swapOpacity
								/>
								{t('title.add-new-internship')}
							</Button>
						)}
					</div>
					{internships && internships?.items?.length === 0 && (
						<ProfileAddNewCard
							navigationUrl={addInternshipUrl}
							title="title.add-new-internship"
						/>
					)}
				</div>
			)}
			{!setting?.internshipListStatus && (
				<div className="overlay overlay_white overlay-box" />
			)}
		</>
	);
});

CompanyInternshipSection.displayName = 'CompanyInternshipSection';
