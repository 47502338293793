import { memo, FC, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Link, useOutletContext } from 'react-router-dom';
import { names } from 'Constants';
import { AgentEvent, EventStatus } from 'Types';
import {
	Card,
	EventPartnerCompanyList,
	EventPartnerInvitedList,
	EventPartnerParticipantList,
	FontAwesome,
	ListTab
} from 'Elements';
import styles from 'partials/page/event.module.scss';

interface Tab {
	id: number;
	title: string;
	path: string;
	isActive: boolean;
}

export const EventPartners: FC = memo(() => {
	const { t } = useTranslation();
	const fields = useOutletContext<AgentEvent>();
	const [activeTab, setActiveTab] = useState(0);
	const { events } = names;
	const [tabs, setTabs] = useState<Tab[]>([
		{
			id: 0,
			isActive: true,
			path: 'invited',
			title: 'tab.event.invited-list'
		},
		{
			id: 1,
			isActive: false,
			path: 'participant',
			title: 'tab.event.participant-list'
		}
	]);

	const isEventFinishOrCancel =
		fields?.eventStatus === EventStatus.Canceled || fields?.isFinished;

	const switchList = (tabItem: Tab) => {
		const updatedTabs = tabs.map((tab) => ({
			...tab,
			isActive: tab.id === tabItem.id
		}));
		setTabs(updatedTabs);
		setActiveTab(tabItem.id);
	};

	return (
		<Row className="mb-4">
			{fields?.hasPartner && (
				<>
					{!isEventFinishOrCancel && (
						<Col
							md={6}
							lg={12}
							xl={6}
							className="mb-4 mb-md-0 mb-lg-4 mb-xl-0">
							<EventPartnerCompanyList />
						</Col>
					)}
					<Col md={6} lg={12} xl={6}>
						<Card className={styles.card}>
							<ListTab tabs={tabs} onTabChange={switchList} />
							{activeTab === 0 && <EventPartnerInvitedList />}
							{activeTab === 1 && <EventPartnerParticipantList />}
						</Card>
					</Col>
				</>
			)}
			{!fields?.hasPartner && (
				<Col xs={12}>
					<Card className="p-5 d-flex align-items-center justify-content-center text-center flex-column">
						<FontAwesome
							icon="info-circle"
							size="4x"
							fillOpacity={0.65}
							className="mb-3"
						/>
						<h6 className="fs-6 lh-base">
							{t('title.event.event-partner-not-active')}
						</h6>
						{!isEventFinishOrCancel && (
							<p className="text-gray-3 fs-small">
								{t('text.event.activate-registration')}
								<Link
									to={`${events.path}/${fields?.id}/registration`}
									className="text-decoration-underline mx-1">
									{t('tab.registration')}
								</Link>
								tab
							</p>
						)}
					</Card>
				</Col>
			)}
		</Row>
	);
});

EventPartners.displayName = 'EventPartners';
