import { startTransition } from 'react';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import axios from 'Adapter';
import { queryKey } from 'Constants';
import { usePermissionGraph } from 'Hooks';
import { api, fetch, toastify } from 'Utils';
import { DataList, DataQueryParams, UserRole, UserRoleFormData } from 'Types';

const config = (options = {}) => {
	return {
		...{ keepPreviousData: false },
		...options
	};
};

export const useRoles = <T = DataList<UserRole>>(
	{ pageSize = 10, pageNumber = 1, keyword = '' }: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<UserRole>>
): UseQueryResult<DataList<UserRole>, unknown> => {
	return useQuery(
		{
			queryKey: [queryKey.roles, { pageSize, pageNumber, keyword }],
			queryFn: () => fetch.userRoleList(pageSize, pageNumber, keyword),
			...config(options)
		}
	);
};

export const useRole = <T = UserRole>(
	roleId: string,
	options?: UseQueryOptions<T, any, UserRole>
): UseQueryResult<UserRole, unknown> => {
	return useQuery(
		{
			queryKey: [queryKey.role, { id: roleId }],
			queryFn: () => fetch.userRoleDetails(roleId),
			...config(options)
		}
	);
};

export const useCreateRole = () => {
	const [permissionsData, permissionsGraphData] = usePermissionGraph();
	const { data: permissionData, isLoading: isLoadingPermissions } =
		permissionsData;
	const { data: permissionGraphData, isLoading: isLoadingGraph } =
		permissionsGraphData;

	const submitRequest: (
		fields: UserRoleFormData,
		onFinal?: () => void
	) => Promise<void> = async (fields, onFinal) => {
		const data = fields;
		await axios.post(api.createUserRoles, data).then((res) => {
			if (res.status === 200) {
				startTransition(() => {
					onFinal?.();
				});
			}
		});
	};

	return {
		submitRequest,
		permissionData,
		permissionGraphData,
		isLoadingPermissions,
		isLoadingGraph
	};
};

export const useUpdateRole = (roleId: string) => {
	const [permissionsData, permissionsGraphData] = usePermissionGraph();
	const { data: permissionData, isLoading: isLoadingPermissions } =
		permissionsData;
	const { data: permissionGraphData, isLoading: isLoadingGraph } =
		permissionsGraphData;

	const query = useRole(roleId);
	const role = query.data;

	const updateRole: (
		fields: UserRoleFormData,
		onFinal?: () => void
	) => Promise<void> = async (fields, onFinal) => {
		await axios.put(api.updateUserRole(roleId), fields).then((res) => {
			if (res.status === 200) {
				query.refetch();
				startTransition(() => {
					toastify('toastify.role-updated', {
						type: 'success'
					});
				});
				onFinal?.();
			}
		});
	};

	return {
		...query,
		role,
		updateRole,
		permissionData,
		permissionGraphData,
		isLoadingPermissions,
		isLoadingGraph
	};
};
