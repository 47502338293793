import { useContext, useEffect, useState } from 'react';
import { ScrollContext } from 'Context';
import { getScrollPosition, body } from 'Utils';

export const useScroll = (): { isActiveScroll: boolean } => {
	const [activeScroll, setActiveScroll] = useState<boolean>(false);

	const handleScroll = () => {
		if (getScrollPosition().y > 10) {
			setActiveScroll(true);
			body.classList.add('scroll');
		} else {
			setActiveScroll(false);
			body.classList.remove('scroll');
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, [activeScroll]);

	return { isActiveScroll: activeScroll };
};

export const useScrollContext = () => {
	const scroll = useContext(ScrollContext);

	if (!scroll) {
		throw new Error('You have to add the Scroll Provider to make it work');
	}

	return { ...scroll.state, setColorType: scroll.setState };
};
