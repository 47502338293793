import { FC, memo } from 'react';
import { Link } from 'react-router-dom';
import { IconLogo } from 'Elements';
import { ColorValues } from 'Types';
import { useScrollContext } from 'Hooks';

interface Props {
	className: string;
}

export const Logo: FC<Props> = memo(({ className }): JSX.Element => {
	const { colorType } = useScrollContext();
	return (
		<Link to="/" className="d-flex align-items-center flex-shrink-0">
			<IconLogo
				fill={colorType === 'colorize' ? ColorValues['gray-5'] : '#fff'}
				className={className}
			/>
		</Link>
	);
});

Logo.displayName = 'Logo';
