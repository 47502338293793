import { memo, FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectOption } from 'Types';
import { getCurrentLocale } from 'Utils';
import {
	PageHeadingFilter,
	PageHeadingPageSize,
	PageHeadingSearch
} from 'Elements';

interface SharedProps {
	totalItems?: number;
	children?: ReactNode;
}

type TSearch = {
	onSearchChange?: (keyword: string) => void;
};

type TPageSize =
	| {
			pageSizes?: number[];
			selectedPageSize?: number;
			onPageSizeChange?: (pageSize: number) => void;
	  }
	| {
			pageSizes: number[];
			selectedPageSize: number;
			onPageSizeChange: (pageSize: number) => void;
	  };

type TFilter =
	| {
			filterOptions?: SelectOption[];
			selectedFilter?: string;
			onFilterChange?: (filter: SelectOption) => void;
	  }
	| {
			onFilterChange: (filter: SelectOption) => void;
			filterOptions: SelectOption[];
			selectedFilter: string;
	  };

type Props = SharedProps & TFilter & TPageSize & TSearch;

export const ListingPage: FC<Props> = memo(
	({
		children,
		pageSizes,
		filterOptions,
		selectedFilter,
		selectedPageSize,
		totalItems = 0,
		onSearchChange,
		onFilterChange,
		onPageSizeChange
	}) => {
		const { t } = useTranslation();
		return (
			<div className="d-flex flex-column">
				<div className="d-flex align-items-center flex-wrap flex-sm-nowrap gap-3 mb-3">
					{onSearchChange && (
						<PageHeadingSearch onSubmit={onSearchChange} />
					)}
					<div className="ms-auto ps-3 flex-grow-1 d-flex justify-content-end">
						{totalItems !== 0 && (
							<div className="me-2 d-flex align-items-center gap-1 text-gray-4 fw-300">
								<span className="fw-500 text-gray-5">
									{new Intl.NumberFormat(
										getCurrentLocale().lang
									).format(totalItems)}
								</span>
								{t('title.items')}
							</div>
						)}
						{filterOptions && filterOptions?.length > 0 && (
							<PageHeadingFilter
								filters={filterOptions}
								selectedFilter={selectedFilter}
								onChange={onFilterChange}
							/>
						)}
						{pageSizes && pageSizes?.length > 0 && (
							<PageHeadingPageSize
								pageSizes={pageSizes}
								selectedPageSize={selectedPageSize}
								onChange={onPageSizeChange}
							/>
						)}
					</div>
				</div>
				<div className="flex-grow-1">{children}</div>
			</div>
		);
	}
);

ListingPage.displayName = 'ListingPage';
