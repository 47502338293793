import { FC, memo, PropsWithChildren } from 'react';
import { UserCategory } from 'Types';
import { HomepageHeader, PrivateRoute } from 'Elements';
import styles from 'partials/homepage/layout.module.scss';

interface Props extends PropsWithChildren {
	previewUrl: string;
	type: UserCategory;
}

export const ProfilePageLayout: FC<Props> = memo(
	({ children, previewUrl, type }) => {
		return (
			<PrivateRoute role={type}>
				<main className={styles.wrapper}>
					<div className="position-relative d-flex flex-grow-1">
						<HomepageHeader previewUrl={previewUrl} type={type} />
						<div className="w-100 flex-1">{children}</div>
					</div>
				</main>
			</PrivateRoute>
		);
	}
);
ProfilePageLayout.displayName = 'ProfilePageLayout';
