import OtpInput from 'react-otp-input';
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import { clx } from 'Utils';

type Props<T extends FieldValues> = {
	className?: string;
	numInputs: number;
	onInputChange?: (val: string) => void;
	[x: string]: any;
} & UseControllerProps<T>;

export const OTPController = <T extends FieldValues>({
	control,
	name,
	error,
	className,
	numInputs,
	onInputChange,
	...inputProps
}: Props<T>): JSX.Element => {
	return (
		<div
			className={clx(
				'd-flex justify-content-center flex-column',
				className
			)}>
			<Controller
				control={control}
				name={name}
				render={({ field: { onChange, value } }) => (
					<OtpInput
						{...inputProps}
						value={value}
						numInputs={numInputs}
						renderInput={(props) => <input {...props} />}
						onChange={(val: string) => {
							onChange(val);
							onInputChange?.(val);
						}}
					/>
				)}
			/>
			{error && (
				<div className="invalid-feedback d-block">
					{error.message as string}
				</div>
			)}
		</div>
	);
};

OTPController.displayName = 'OTPController';
