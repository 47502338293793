import { memo, FC } from 'react';
import { clx } from 'Utils';
import { LoadingMask } from 'Elements';
import styles from 'partials/feature/file-card.module.scss';

export const FileUploaderCardLoading: FC = memo(() => {
	return (
		<div className={styles.card}>
			<LoadingMask className={styles.image} />
			<LoadingMask className={clx('w-80 flex-grow-1', styles.loading)} />
			<LoadingMask className={clx('w-20 ms-auto', styles.loading)} />
		</div>
	);
});

FileUploaderCardLoading.displayName = 'FileUploaderCardLoading';
