import { EventEmitter } from 'events';

export class ModalEventHandler extends EventEmitter {
	show = () => {
		this.emit('show');
	};

	hide = () => {
		this.emit('hide');
	};

	addChangeListener = (callback: any) => {
		this.addListener('show', callback);
	};

	removeChangeListener = (callback: any) => {
		this.removeListener('show', callback);
	};
}

export const ModalEvent = new ModalEventHandler();
