import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, FontAwesome } from 'Elements';
import { EventDetails, EventParticipationStatus, EventType } from 'Types';
import { formatLocalAddress, formatTimeToCET } from 'Utils';
import styles from 'partials/event/event-location-box.module.scss';

interface Props {
	event: EventDetails;
}

export const EventLocationBox: FC<Props> = memo(({ event }) => {
	const { t } = useTranslation();
	const address = event?.address;
	const hasParticipated =
		!!event?.participationInfo &&
		event.participationInfo.status === EventParticipationStatus.Active;

	return (
		<Card className={styles.box}>
			<div className="mb-2">
				{(event.eventType === EventType.Online ||
					event.eventType === EventType.Hybrid) && (
					<div className="d-flex align-items-center lh-md mb-2">
						<FontAwesome
							size="lg"
							icon="camera-web"
							className="text-tertiary-dark me-3"
						/>
						<div className="fw-300 lh-md d-flex align-items-center">
							{hasParticipated ? (
								<a
									href={event?.meetingLink}
									target="_blank"
									rel="noreferrer noopener"
									className="d-flex align-items-center text-secondary">
									{t('label.event.event-link')}
									<FontAwesome
										size="sm"
										icon="arrow-up-right-from-square"
										className="ms-1"
									/>
								</a>
							) : (
								<span>{t('label.event.online-url')}</span>
							)}
						</div>
					</div>
				)}
				{(event.eventType === EventType.InPerson ||
					event.eventType === EventType.Hybrid) && (
					<div className="d-flex align-items-center lh-md">
						<FontAwesome
							size="lg"
							icon="location-dot"
							className="text-tertiary-dark me-3"
						/>
						<div className="fw-300">
							{formatLocalAddress(
								address?.street,
								address?.houseNo,
								address?.postalCode,
								address?.city,
								address?.state
							)}
						</div>
					</div>
				)}
			</div>
			<div className="d-flex">
				<FontAwesome
					size="lg"
					icon="calendar-days"
					className="text-tertiary-dark flex-shrink-0 me-3 mt-1"
				/>
				<div className="flex-grow-1 fw-300 lh-md d-flex flex-column gap-1">
					<time dateTime={event.startDate}>
						{formatTimeToCET(event.startDate)}
					</time>
					<time dateTime={event.endDate}>
						{formatTimeToCET(event.endDate)}
					</time>
				</div>
			</div>
		</Card>
	);
});

EventLocationBox.displayName = 'EventLocationBox';
