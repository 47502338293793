import { FC, memo } from 'react';
import { Address } from 'Types';
import { FontAwesome } from 'Elements';
import { formatLocalAddress } from 'Utils';

interface Props {
	address?: Address;
}

export const JobCompanyAddress: FC<Props> = memo(({ address }) => {
	return (
		<div className="d-flex align-items-center lh-base gap-3 justify-content-center">
			<FontAwesome icon="location-dot" size="lg" />
			<span className="text-gray-3">
				{address
					? formatLocalAddress(
							address?.street,
							address?.houseNo,
							address?.postalCode,
							address?.city,
							address?.state
					  )
					: '---'}
			</span>
		</div>
	);
});
JobCompanyAddress.displayName = 'JobCompanyAddress';
