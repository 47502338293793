import { FC, memo } from 'react';
import { Col } from 'reactstrap';
import styled from 'styled-components';
import { clx } from 'Utils';
import { Button, FontAwesome } from 'Elements';
import { ColorValues, Colors, Translate } from 'Types';
import styles from 'partials/homepage/card/benefit-card.module.scss';

interface Props {
	benefit: Translate;
	isRemoving: boolean;
	onClick: () => void;
}

const Icon = styled.div`
	${({ theme }) =>
		theme.company.primaryColor &&
		` border-color: ${theme.company.primaryColor}
	`};
`;

export const ProfileBenefitCard: FC<Props> = memo(
	({ benefit, isRemoving, onClick }) => {
		return (
			<Col sm={6} lg={4} xxl={3} className={styles.borderBox}>
				<div
					role="button"
					className={clx(
						styles.box,
						'd-flex flex-column flex-md-row gap-2 align-items-center justify-content-center'
					)}
					onClick={onClick}>
					<Icon className={styles.icon}>
						<FontAwesome icon="check" size="2x" />
					</Icon>
					<h6 className="fs-sm fw-400 mb-0 text-center text-gray-5 lh-base flex-grow-1 text-break">
						{benefit.name}
					</h6>
					<div className="edit-overlay">
						{!isRemoving && (
							<Button
								color={Colors.empty}
								disabled={isRemoving}
								className="edit-overlay-icon">
								<FontAwesome
									icon="trash-xmark"
									size="xl"
									color={ColorValues.red}
								/>
							</Button>
						)}
					</div>
				</div>
			</Col>
		);
	}
);
ProfileBenefitCard.displayName = 'ProfileBenefitCard';
