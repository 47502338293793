import { FC, ReactNode, memo, useState } from 'react';
import { Swiper, SwiperClass, SwiperProps } from 'swiper/react';
import { SwiperButtonGroup } from 'Elements';
import 'swiper/css';

interface Shared {
	children: ReactNode;
	navigationClass?: string;
	darkBackground?: boolean;
	navigationGroup?: boolean;
}

type Props = SwiperProps & Shared;

export const Carousel: FC<Props> = memo(
	({
		children,
		navigationClass,
		darkBackground,
		navigationGroup,
		...options
	}) => {
		const [disablePrev, setDisablePrev] = useState(false);
		const [disableNext, setDisableNext] = useState(false);
		const [hideBtn, setHideBtn] = useState(false);

		const handleSlide = (swiper: SwiperClass) => {
			if (!options.loop) {
				setDisablePrev(swiper.isBeginning);
				setDisableNext(swiper.isEnd);
			}
		};

		const handleInit = (swiper: SwiperClass) => {
			if (swiper.isBeginning && swiper.isEnd) setHideBtn(true);
			handleSlide(swiper);
		};

		return (
			<Swiper
				observer
				grabCursor
				roundLengths
				centerInsufficientSlides
				spaceBetween={30}
				uniqueNavElements={false}
				centeredSlidesBounds={false}
				onInit={handleInit}
				onSlideChange={handleSlide}
				onReachEnd={handleSlide}
				onReachBeginning={handleSlide}
				{...options}>
				{children}
				{navigationGroup && !hideBtn && (
					<SwiperButtonGroup
						disablePrev={disablePrev}
						disableNext={disableNext}
						className={navigationClass}
						darkBackground={darkBackground}
					/>
				)}
			</Swiper>
		);
	}
);

Carousel.displayName = 'Carousel';
