import { memo, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColorValues, Colors, ModalProps } from 'Types';
import { useDelayUnmount, useModal, useUpdateAgent } from 'Hooks';
import {
	Button,
	FormInlineCheckbox,
	ModalBox,
	ModalBoxDeleteAccountVerification,
	ModalBoxFooter,
	SubmitButton,
	FontAwesome
} from 'Elements';

export const ModalBoxDeleteAccount: FC<ModalProps> = memo(
	({ isOpen, onClose }) => {
		const { t } = useTranslation();
		const [openModal, toggleModal] = useModal();
		const [submitDelete, setSubmitDelete] = useState(false);
		const [disableDelete, setDisableDelete] = useState(true);
		const shouldRenderModal = useDelayUnmount(openModal, 350);
		const { deleteAgentRequest } = useUpdateAgent();

		const onDeleteAccount = async () => {
			try {
				setSubmitDelete(true);
				await deleteAgentRequest(toggleModal);
			} finally {
				setSubmitDelete(false);
			}
		};

		const onCheckDeleteAccount = (isChecked: boolean) => {
			setDisableDelete(!isChecked);
		};

		return (
			<ModalBox isOpen={isOpen} onClose={onClose}>
				<header className="d-flex flex-column align-items-center text-center">
					<FontAwesome
						icon='triangle-exclamation'
						size="8x"
						color={ColorValues.red}
						className="mb-3"
					/>
					<h6 className="h6 lh-base">{t('title.delete-my-agent')}</h6>
					<p className="text-gray-3 lh-base">
						{t('text.delete-account.warning')}
					</p>
					<FormInlineCheckbox
						id="delete"
						className="d-flex text-center mb-3"
						label={t('label.i-read-warning') as string}
						onChange={onCheckDeleteAccount}
					/>
				</header>
				<ModalBoxFooter className="justify-content-between pt-3">
					<Button color={Colors['white-gray']} onClick={onClose}>
						{t('button.cancel')}
					</Button>
					<SubmitButton
						icon={false}
						type="button"
						color={Colors.red}
						className="flex-shrink-0"
						isSubmitting={submitDelete}
						isDisable={disableDelete || submitDelete}
						label={t('button.delete-account') as string}
						savingLabel={t('button.deleting') as string}
						onClick={onDeleteAccount}
					/>
				</ModalBoxFooter>
				{shouldRenderModal && (
					<ModalBoxDeleteAccountVerification
						isOpen={openModal}
						onClose={toggleModal}
					/>
				)}
			</ModalBox>
		);
	}
);

ModalBoxDeleteAccount.displayName = 'ModalBoxDeleteAccount';
