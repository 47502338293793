import { FC, memo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'Hooks';
import { FontAwesome } from 'Elements';
import { Job, UserCategory } from 'Types';
import { clx, getTranslate } from 'Utils';

interface Props {
	job: Job;
	userType: UserCategory;
}

const Box = styled.div<{ $userType: UserCategory }>`
	border-radius: 10px;
	${({ theme, $userType }) =>
		theme[$userType].primaryColor &&
		`border-bottom: 2px solid ${theme[$userType].primaryColor}`};
`;

export const JobInfoSection: FC<Props> = memo(({ job, userType }) => {
	const { theme } = useTheme();
	const { t } = useTranslation();

	return (
		<div className="d-flex flex-column gap-3 pt-lg-3 pb-3">
			<h2 className="fs-3 fw-bold lh-sm text-gray-6">{job.title}</h2>
			<Box
				$userType={userType}
				className={clx(
					'p-3 gap-3 bg-gray-1 d-flex align-items-center flex-wrap gap-3',
					job?.code && 'justify-content-around'
				)}>
				{getTranslate(job.jobTypes?.jobTypeTranslates) && (
					<div
						className="d-flex align-items-center justify-content-center gap-2 fs-sm lh-base px-2"
						title={t('title.job.job-type')}>
						<FontAwesome
							icon="briefcase"
							color={theme[userType].primaryColor}
							size="lg"
							fixedWidth
						/>
						<div className="lh-1">
							<span className="fw-300 me-2">
								{t('title.job.job-type')}:
							</span>
							{getTranslate(job.jobTypes?.jobTypeTranslates)}
						</div>
					</div>
				)}
				{getTranslate(job.sectors?.sectorTranslates) && (
					<div
						className="d-flex align-items-center justify-content-center gap-2 fs-sm lh-base px-2"
						title={t('title.job.sector')}>
						<FontAwesome
							icon="industry"
							color={theme[userType].primaryColor}
							size="lg"
							fixedWidth
						/>
						<div className="lh-1">
							<span className="fw-300 me-2">
								{t('title.job.sector')}:
							</span>
							{getTranslate(job.sectors?.sectorTranslates)}
						</div>
					</div>
				)}
				{job?.code && (
					<div className="d-flex align-items-center justify-content-center gap-2 fs-sm lh-base px-2">
						<FontAwesome
							icon="barcode-read"
							color={theme[userType].primaryColor}
							size="lg"
							fixedWidth
						/>
						<div className="lh-1">
							<span className="fw-300 me-2">
								{t('title.job.code')}:
							</span>
							{job.code}
						</div>
					</div>
				)}
			</Box>
			{job.shortDescription && (
				<div className="text-gray-3 text">{job.shortDescription}</div>
			)}
		</div>
	);
});
JobInfoSection.displayName = 'JobInfoSection';
