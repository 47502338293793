import { FC, memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Button, Col, Container, Row } from 'reactstrap';
import { useTheme, useUpdateAgent, useAgentPartnersArea } from 'Hooks';
import { AgentSetting, Colors } from 'Types';
import {
	AgentPartnerAreaCard,
	LoadingSpinner,
	NoData,
	SectionHeadline,
	SectionSwitcher
} from 'Elements';
import { AgentProfileFormData } from 'Types';

interface Props {
	agent?: AgentProfileFormData;
}

export const AgentPartnerAreaSection: FC<Props> = memo(({ agent }) => {
	const perRow = 8;
	const { theme } = useTheme();
	const { t } = useTranslation();
	const [next, setNext] = useState(perRow);
	const { control, reset } = useForm();
	const {
		setting,
		isLoading: isLoadingSetting,
		updateAgentSetting
	} = useUpdateAgent();

	const { data: partners, isLoading } = useAgentPartnersArea(
		agent?.id as string,
		{
			enabled: !!agent?.id
		}
	);

	const handleMore = () => {
		setNext(next + perRow);
	};

	const handleUpdate = async (field: Partial<AgentSetting>) => {
		await updateAgentSetting(field);
	};

	useEffect(() => {
		reset(setting);
	}, [reset, setting]);

	return (
		<section className="position-relative mb-5 pb-5">
			{isLoading && (
				<div className="d-flex align-items-center justify-content-center">
					<LoadingSpinner />
				</div>
			)}
			{!isLoading && !isLoadingSetting && (
				<Container>
					<SectionSwitcher
						control={control}
						name="areaCommonHeadlineStatus"
						label="forms.show-partners-area-in-home"
						isActive={!setting?.areaCommonHeadlineStatus}
						onSwitchChange={(val) =>
							handleUpdate({
								areaCommonHeadlineStatus: val,
								areaListStatus: val
							})
						}
					/>
					<SectionHeadline
						titleColor="text-gray-5"
						title={
							setting?.areaCommonHeadline ||
							`[${t('placeholder.partners-area-title')}]`
						}
						description={
							setting?.areaText ||
							`[${t('placeholder.partners-area-description')}]`
						}
						onUpdateTitle={(val) =>
							handleUpdate({ areaCommonHeadline: val })
						}
						onUpdateDescription={(val) =>
							handleUpdate({ areaText: val })
						}
					/>
					{partners && partners?.length > 0 && (
						<Row className="justify-content-center">
							{partners?.slice(0, next)?.map((partner) => {
								return (
									<Col
										md={6}
										lg={3}
										key={partner.id}
										className="mb-4">
										<AgentPartnerAreaCard
											key={partner.id}
											partner={partner}
										/>
									</Col>
								);
							})}
						</Row>
					)}
					<div className="d-flex align-items-center justify-content-center mt-3 gap-3">
						{partners && partners?.length > next && (
							<Button
								size="lg"
								color={Colors['border-gray-4']}
								label={t('button.more-partners') as string}
								className="rounded-pill"
								onClick={handleMore}
							/>
						)}
					</div>
					{partners && partners?.length === 0 && (
						<NoData message={t('title.no-partner-yet')} />
					)}
				</Container>
			)}
			{!setting?.areaListStatus && (
				<div className="overlay overlay_white overlay-box" />
			)}
		</section>
	);
});
AgentPartnerAreaSection.displayName = 'AgentPartnerAreaSection';
