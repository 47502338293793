import { CSSProperties, FC, memo, ReactNode } from 'react';
import { motion } from 'framer-motion';
import { clx } from 'Utils';
import { itemVariants } from 'Constants';
import styles from 'partials/data-table/data-table.module.scss';

interface Props {
	children: ReactNode;
	index?: string;
	className?: string;
	style?: CSSProperties;
}

export const DataTableRow: FC<Props> = memo(
	({ children, className, style, index }) => {
		const rowClass = clx(
			'd-flex flex-column flex-md-row align-items-end align-items-md-center justify-content-md-center',
			styles.row,
			className
		);
		return (
			<motion.div
				variants={itemVariants}
				className="table-row"
				key={index}>
				<div className={rowClass} style={style}>
					{children}
				</div>
			</motion.div>
		);
	}
);

DataTableRow.displayName = 'DataTableRow';
