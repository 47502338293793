import { memo } from 'react';
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { locales } from 'Constants';
import { changeLocale } from 'Actions';
import { ShimmerImage, FontAwesome } from 'Elements';
import { ColorValues, Locale } from 'Types';
import { useDispatch, useSelector } from 'Hooks';
import styles from 'partials/shared/dropdown/language-dropdown.module.scss';

export const LocaleDropDown = memo(() => {
	const { i18n } = useTranslation();
	const dispatch = useDispatch();
	const { locale } = useSelector((state) => state.setting);

	const handleChangeLocale = (locale: Locale) => {
		dispatch(changeLocale(locale));
		i18n.changeLanguage(locale.code);
	};

	return (
		<UncontrolledDropdown>
			<DropdownToggle tag="div" role="button" className={styles.toggle}>
				{locale?.code.toUpperCase()}
				<FontAwesome
					icon="chevron-down"
					swapOpacity
					size="sm"
					color={ColorValues['gray-3']}
					className={styles.arrow}
				/>
			</DropdownToggle>
			<DropdownMenu className={styles.menu} end>
				{locales.map((local) => {
					return (
						<DropdownItem
							key={local.code}
							className={styles.item}
							onClick={() => handleChangeLocale(local)}>
							<div className="d-flex align-items-center gap-2">
								<div className={styles.logoWrapper}>
									<ShimmerImage
										width={25}
										height={25}
										src={local.icon}
										alt={local.name}
										className={styles.logo}
										defaultImage=""
									/>
								</div>
								{local.name}
							</div>
						</DropdownItem>
					);
				})}
			</DropdownMenu>
		</UncontrolledDropdown>
	);
});

LocaleDropDown.displayName = 'LocaleDropDown';
