import { memo, FC } from 'react';
import { Col, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, StepNavigation, FontAwesome } from 'Elements';
import {
	ColorValues,
	Colors,
	CustomPermission,
	UserRoleForm,
	UserRoleFormData
} from 'Types';
import styles from 'partials/step/step-container.module.scss';

interface Props {
	fields: UserRoleForm;
	label?: string;
	savingLabel?: string;
	onPrev: () => void;
	onNext: () => void;
	goToStep: (stepId: string) => void;
	onSubmit: (data: UserRoleFormData, onFinall: () => void) => Promise<void>;
}

export const RolePreview: FC<Props> = memo(
	({
		fields,
		label = 'button.save',
		savingLabel = 'button.saving',
		onNext,
		onPrev,
		goToStep,
		onSubmit
	}) => {
		const { t } = useTranslation();
		const {
			handleSubmit,
			formState: { isSubmitting }
		} = useForm();

		const submitHandler = async () => {
			const api_data: UserRoleFormData = {
				...fields.step1,
				...fields.step2
			};
			try {
				await onSubmit(api_data, onNext);
			} finally {
				//
			}
		};

		return (
			<form
				onSubmit={handleSubmit(submitHandler)}
				className={styles.form}>
				<Row>
					<Col sm={6} md={12} xl={6} className="mb-3">
						<h4 className="fw-500 fs-6">{t('forms.role-name')}:</h4>
						<p className="mb-0 text-gray-3">{fields.step1.name}</p>
					</Col>
					<Col sm={6} md={12} xl={6} className="mb-3">
						<h4 className="fw-500 fs-6">{t('forms.desc')}:</h4>
						<p className="mb-0 text-gray-3">
							{fields.step1.description || '--------'}
						</p>
					</Col>
				</Row>
				<div className="my-4 border-bottom position-relative">
					<Button
						color={Colors['white-gray']}
						className={styles.edit}
						onClick={() => goToStep('step1')}>
						<FontAwesome icon="pen" />
					</Button>
				</div>
				<Row className="pt-2">
					<Col className="mb-3">
						<h4 className="fw-500 fs-6">
							{t('forms.permissions')}:
						</h4>
						<Row className="mt-3">
							{fields.step2.permissions?.map(
								(elem: CustomPermission) => {
									return (
										<Col
											sm={6}
											md={12}
											xl={6}
											xxl={4}
											className="mb-3"
											key={elem.code}>
											<div className="d-flex align-items-center text-gray-3">
												<FontAwesome
													icon="shield-check"
													size="lg"
													color={ColorValues.green}
													className="me-2 mt-1 align-self-start"
												/>
												<span className="text-break lh-md">
													{elem.label}
												</span>
											</div>
										</Col>
									);
								}
							)}
						</Row>
					</Col>
				</Row>
				<div className="my-4 border-bottom position-relative">
					<Button
						color={Colors['white-gray']}
						className={styles.edit}
						onClick={() => goToStep('step2')}>
						<FontAwesome icon="pen" />
					</Button>
				</div>
				<StepNavigation
					type="submit"
					label={label}
					savingLabel={savingLabel}
					isSubmitting={isSubmitting}
					onPrev={onPrev}
				/>
			</form>
		);
	}
);

RolePreview.displayName = 'RolePreview';
