import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAxios } from 'Hooks';
import { ModalProps } from 'Types';
import { api, toastify } from 'Utils';
import { Html5QrcodeReader, LoadingContent, ModalBox } from 'Elements';

interface CheckInData {
	participantId: string;
	eventId: string;
}

export const ModalBoxEventCheckIn: FC<ModalProps> = memo(
	({ isOpen, onClose }) => {
		const { t } = useTranslation();
		const { sendRequest, isLoading: isSubmitting } = useAxios();

		const submitCheckIn = async (data: CheckInData) => {
			await sendRequest(api.checkInParticipants, { data }, () => {
				toastify(t('toastify.check-in'), { type: 'success' });
				onClose();
			});
		};
		
		const handleSuccess = async (decodedText: string) => {
			const jsonData: CheckInData = JSON.parse(decodedText);
			if (decodedText) {
				await submitCheckIn(jsonData);
			}
		};

		const qrboxFunction = (
			viewfinderWidth: number,
			viewfinderHeight: number
		) => {
			const minEdgePercentage = 0.7; // 70%
			const minEdgeSize = Math.min(viewfinderWidth, viewfinderHeight);
			const qrboxSize = Math.floor(minEdgeSize * minEdgePercentage);
			return {
				width: qrboxSize,
				height: qrboxSize
			};
		};

		return (
			<ModalBox
				isOpen={isOpen}
				onClose={onClose}
				title="title.event.qr-code-scanner">
				<p className="text-gray-3">{t('text.check-in-desc')}</p>
				<Html5QrcodeReader
					onSuccess={handleSuccess}
					config={{
						qrbox: qrboxFunction,
						fps: 10
					}}
				/>
				{isSubmitting && <LoadingContent title="title.submitting" />}
			</ModalBox>
		);
	}
);

ModalBoxEventCheckIn.displayName = 'ModalBoxEventCheckIn';
