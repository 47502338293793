import { memo, FC } from 'react';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { ApplicantDemand } from 'Types';
import { Card, LoadingContent } from 'Elements';
import { formatLocaleDateTime } from 'Utils';

interface Props {
	request?: ApplicantDemand;
	isLoading: boolean;
}

export const RequestInformation: FC<Props> = memo(({ request, isLoading }) => {
	const { t } = useTranslation();
	const applicant = request?.applicant;
	const applicantName = applicant
		? `${applicant?.firstName} ${applicant?.lastName}`
		: '---';

	const getGender = () => {
		const gender = {
			0: 'forms.gender.unknown',
			1: 'forms.gender.male',
			2: 'forms.gender.female'
		};
		return applicant?.gender ? t(gender[applicant?.gender]) : '---';
	};
	return (
		<Card className="h-100">
			<h6 className="text-gray-3 mb-3 fs-6 fw-400">
				{t('title.applicant-information')}
			</h6>
			<Row>
				<Col xl={6}>
					<div className="d-flex align-items-center lh-base mb-3">
						<span className="fw-500 align-self-start">
							{t('forms.name')}:
						</span>
						<span className="ms-2">{applicantName}</span>
					</div>
					<div className="d-flex align-items-center lh-base mb-3">
						<span className="fw-500 align-self-start">
							{t('forms.date-of-birth')}:
						</span>
						<span className="ms-2">
							{applicant?.dateOfBirth
								? formatLocaleDateTime(
										applicant?.dateOfBirth,
										false
								  )
								: '----'}
						</span>
					</div>
					<div className="d-flex align-items-center lh-base mb-3">
						<span className="fw-500 align-self-start">
							{t('forms.gender.key')}:
						</span>
						<span className="ms-2">{getGender()}</span>
					</div>
					<div className="d-flex align-items-center lh-base mb-3">
						<span className="fw-500 align-self-start">
							{t('forms.street')}:
						</span>
						<span className="ms-2">
							{applicant?.address?.street || '---'}
						</span>
					</div>
					<div className="d-flex align-items-center lh-base mb-3">
						<span className="fw-500 align-self-start">
							{t('forms.house-number')}:
						</span>
						<span className="ms-2">
							{applicant?.address?.houseNo || '---'}
						</span>
					</div>
					<div className="d-flex align-items-center lh-base mb-3">
						<span className="fw-500 align-self-start">
							{t('forms.postal-code')}:
						</span>
						<span className="ms-2">
							{applicant?.address?.postalCode || '---'}
						</span>
					</div>
					<div className="d-flex align-items-center lh-base mb-3">
						<span className="fw-500 align-self-start">
							{t('forms.city')}:
						</span>
						<span className="ms-2">
							{applicant?.address?.city || '---'}
						</span>
					</div>
					<div className="d-flex align-items-center lh-base mb-3">
						<span className="fw-500 align-self-start">
							{t('forms.state')}:
						</span>
						<span className="ms-2">
							{applicant?.address?.state || '---'}
						</span>
					</div>
				</Col>
				<Col xl={6}>
					<div className="d-flex align-items-center lh-base mb-3">
						<span className="fw-500 align-self-start">
							{t('forms.email')}:
						</span>
						<span className="ms-2">
							{applicant?.email || '---'}
						</span>
					</div>
					<div className="d-flex align-items-center lh-base mb-3">
						<span className="fw-500 align-self-start">
							{t('forms.tel')}:
						</span>
						<span className="ms-2">{applicant?.tel || '---'}</span>
					</div>
					<div className=" lh-base mb-3">
						<span className="fw-500 align-self-start">
							{t('forms.applicant-comment')}:
						</span>
						<div>
							{request?.applicantComment || '---'}
						</div>
					</div>
				</Col>
			</Row>
			{isLoading && <LoadingContent />}
		</Card>
	);
});

RequestInformation.displayName = 'RequestInformation';
