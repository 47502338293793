import { memo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useUpdateCompany } from 'Hooks';
import { Address, CompanyProfileInitialData, Media, Tab } from 'Types';
import { ProfileDecorationBox } from 'Elements';

export const CompanyDecorationBox = memo(() => {
	const { reset } = useForm();
	const { pathname } = useLocation();
	const {
		data: company,
		setting,
		address,
		isLoading,
		updateCompanySetting
	} = useUpdateCompany();

	const tabs: Tab[] = [
		{
			id: 0,
			title: 'tab.general',
			path: 'general',
			isActive:
				pathname === '/my-company' || pathname === '/my-company/general'
		},
		{
			id: 1,
			title: 'tab.address',
			path: 'address',
			isActive: pathname === '/my-company/address'
		}
	];

	const handleUploadImage = async (image_data: Partial<Media>) => {
		if (image_data) {
			const data = {
				logo: image_data.url,
				thumbnailLogo: image_data.thumbnailUrl
			};
			await updateCompanySetting(data);
		}
	};

	const handleResetLogo = async () => {
		const data = {
			logo: '',
			thumbnailLogo: ''
		};
		await updateCompanySetting(data);
	};

	useEffect(() => {
		reset(company);
	}, [reset, company]);

	return (
		<ProfileDecorationBox
			tabs={tabs}
			type="company"
			profile={company as CompanyProfileInitialData}
			isLoading={isLoading}
			isPublished={company?.isActive ?? false}
			title={company?.companyName ?? '---'}
			logo={setting?.logo ?? ''}
			thumbnailLogo={setting?.thumbnailLogo ?? ''}
			address={address as Address}
			onResetLogo={handleResetLogo}
			onChangeLogo={handleUploadImage}
			disablePublish={company?.approveStatus !== 1}
		/>
	);
});

CompanyDecorationBox.displayName = 'CompanyDecorationBox';
