import { ChangeEvent, FC, ReactNode, memo, useEffect, useState } from 'react';
import { clx } from 'Utils';
import { XOR } from 'Types';
import { FontAwesome } from 'Elements';

interface SharedProps {
	isChecked?: boolean;
	disabled?: boolean;
	className?: string;
	labelClassName?: string;
	onChange: (value: boolean) => void;
	[x: string]: any;
}

type Props = SharedProps & XOR<{ label: string }, { children: ReactNode }>;

export const FormInlineCheckbox: FC<Props> = memo((props) => {
	const [check, setCheck] = useState(props.isChecked || false);

	const runCheck = (isChecked: boolean) => {
		setCheck(isChecked);
		props.onChange?.(isChecked);
	};

	const handleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
		runCheck(e.target.checked);
	};

	const handleTickCheck = () => {
		runCheck(!check);
	};

	useEffect(() => {
		setCheck(props.isChecked ?? false);
	}, [props.isChecked]);

	return (
		<div
			role="checkbox"
			tabIndex={0}
			aria-checked={check}
			className={clx(props.disabled && 'disabled', props.className)}>
			<input
				type="checkbox"
				id={props.id}
				value={props.id}
				checked={check}
				name={props.name}
				disabled={props.disabled}
				className="form-input-check"
				onChange={handleCheckbox}
			/>
			<span className="form-check-tick" onClick={handleTickCheck}>
				<FontAwesome icon="check" size="xs" />
			</span>
			<label
				htmlFor={props.id}
				className={clx(
					'ps-2 lh-md d-inline cursor-pointer flex-grow-1',
					props.labelClassName
				)}>
				{props.label || props.children}
			</label>
		</div>
	);
});

FormInlineCheckbox.displayName = 'FormInlineCheckbox';
