import { PayloadAction } from '@reduxjs/toolkit';
import { Locale, SettingState } from 'Types';
import { setCurrentLocale } from 'Utils';

const reducers = {
	changeLocale: (state: SettingState, action: PayloadAction<Locale>) => {
		setCurrentLocale(action.payload);
		state.locale = action.payload;
	}
};

export default reducers;
