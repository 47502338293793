import styled from 'styled-components';
import { rgba } from 'polished';

interface Style {
	$bgColor: string;
	$hasShadow?: boolean;
}

export const Box = styled.div<Style>`
	${({ $bgColor, $hasShadow = true }) => `
		${$bgColor ? `background-color: ${$bgColor};` : ''}
		${$hasShadow ? `box-shadow: 0px 10px 15px ${rgba($bgColor, 0.15)};` : ''} 
	`};
`;
