import { memo, useEffect } from 'react';
import { Container } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { SwiperSlide } from 'swiper/react';
import { useTranslation } from 'react-i18next';
import { CompanySetting } from 'Types';
import { useEmployees, useTheme, useUpdateCompany } from 'Hooks';
import {
	SectionHeadline,
	LoadingSpinner,
	ProfileAddNewCard,
	ProfileStaffCard,
	ProfileStaffSection,
	SectionSwitcher
} from 'Elements';
import styles from 'partials/homepage/section/staff-section.module.scss';

export const CompanyEmployeeSection = memo(() => {
	const { theme } = useTheme();
	const { t } = useTranslation();
	const { control, reset } = useForm();
	const {
		setting,
		updateCompanySetting,
		isLoading: isLoadingSetting
	} = useUpdateCompany();

	const { data: employees, isLoading } = useEmployees({
		pageNumber: 1,
		pageSize: 50,
		keyword: ''
	});

	const handleUpdate = async (field: Partial<CompanySetting>) => {
		await updateCompanySetting(field);
	};

	useEffect(() => {
		reset(setting);
	}, [reset, setting]);

	return (
		<section className="position-relative mb-5 pb-5">
			{isLoading && (
				<div className="d-flex align-items-center justify-content-center">
					<LoadingSpinner />
				</div>
			)}
			{!isLoading && !isLoadingSetting && (
				<Container>
					<SectionSwitcher
						control={control}
						onSwitchChange={(val) =>
							handleUpdate({ employeeHeadlineStatus: val })
						}
						name="employeeHeadlineStatus"
						label="forms.show-employees-in-home"
						isActive={!setting?.employeeHeadlineStatus}
					/>
					<SectionHeadline
						title={
							setting?.employeeHeadline ||
							`[${t('placeholder.our-employees')}]`
						}
						titleColor="text-gray-5"
						className="mx-auto text-center"
						onUpdateTitle={(val) =>
							handleUpdate({ employeeHeadline: val })
						}
					/>
					{employees && employees?.items?.length > 0 && (
						<ProfileStaffSection
							list={employees}
							profileType="company">
							<SwiperSlide className={styles.card}>
								<ProfileAddNewCard
									navigationUrl="/employees/create"
									title="title.add-new-employee"
									className="h-100"
								/>
							</SwiperSlide>
							{employees.items.reverse()?.map((employee) => {
								return (
									<SwiperSlide
										key={employee.id}
										className={styles.card}>
										<ProfileStaffCard
											id={employee.id}
											name={employee.employeeName}
											staffType="employees"
											image={employee.thumbnailUrl}
											position={employee.jobTitle}
										/>
									</SwiperSlide>
								);
							})}
						</ProfileStaffSection>
					)}
					{employees && employees?.items?.length === 0 && (
						<ProfileAddNewCard
							navigationUrl="/employees/create"
							title="title.add-new-employee"
						/>
					)}
				</Container>
			)}
			{!isLoadingSetting && !setting?.employeeHeadlineStatus && (
				<div className="overlay overlay_white overlay-box" />
			)}
		</section>
	);
});

CompanyEmployeeSection.displayName = 'CompanyEmployeeSection';
