import { FC, memo, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { clx } from 'Utils';
import { ColorValues } from 'Types';
import { FontAwesome } from 'Elements';

interface Props {
	children?: ReactNode;
	message?: string;
	boxClassName?: string;
	iconClassName?: string;
}

export const SystemErrorAlert: FC<Props> = memo(
	({ children, message, boxClassName, iconClassName }) => {
		const { t } = useTranslation();
		return (
			<div
				className={clx(
					'text-center d-flex justify-content-center align-items-center flex-column gap-2',
					boxClassName
				)}>
				<FontAwesome
					icon="triangle-exclamation"
					size="3x"
					color={ColorValues.red}
					className={iconClassName}
				/>
				<h5 className="fs-base mb-0 text-gray-3 lh-base">
					{t(message as string) || t('global.error')}
				</h5>
				{children}
			</div>
		);
	}
);

SystemErrorAlert.displayName = 'SystemErrorAlert';
