import { memo, startTransition, useState } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { useForm, SubmitHandler } from 'react-hook-form';
import { names } from 'Constants';
import { ContactGroupFormData } from 'Types';
import { useContactGroup, usePrompt } from 'Hooks';
import {
	Card,
	FormSuccess,
	SubmitButtonGroup,
	SwitchController
} from 'Elements';

export const CreateContactGroup = memo(() => {
	const { t } = useTranslation();
	const descriptionLimit = 450;
	const { contactGroups } = names;
	const { createContactGroup } = useContactGroup();
	const [formSuccess, setFormSuccess] = useState(false);
	const schema = yup.object().shape({
		title: yup.string().required(t('validation.title.required'))
	});

	const defaultValues: Partial<ContactGroupFormData> = {
		title: '',
		description: '',
		isActive: false
	};

	const {
		reset,
		control,
		register,
		handleSubmit,
		formState: { errors, isDirty, isSubmitting }
	} = useForm<ContactGroupFormData>({
		resolver: yupResolver(schema)
	});

	usePrompt(t('forms.leave-screen-massage'), isDirty);

	const resetForm = () => {
		reset(defaultValues);
	};

	const onSubmit: SubmitHandler<ContactGroupFormData> = async (data) => {
		const api_data = {
			...data
		};
		await createContactGroup(api_data, () => {
			startTransition(() => {
				setFormSuccess(true);
				resetForm();
			});
		});
	};

	return (
		<Card>
			{!formSuccess && (
				<form onSubmit={handleSubmit(onSubmit)}>
					<Row>
						<Col md={8} xxl={6}>
							<FormGroup>
								<Label htmlFor="title">
									{t('forms.group-title')}
									<small className="ms-1">
										({t('validation.required')})
									</small>
								</Label>
								<input
									{...register('title')}
									type="text"
									id="title"
									className="inputbox w-100"
								/>
								{errors.title && (
									<div className="invalid-feedback d-block">
										{errors.title.message}
									</div>
								)}
							</FormGroup>
							<FormGroup>
								<Label htmlFor="description">
									{t('forms.short-desc')}
								</Label>
								<textarea
									{...register('description', {
										maxLength: descriptionLimit
									})}
									maxLength={descriptionLimit}
									id="description"
									className="inputbox w-100"
									cols={10}
									rows={5}
									aria-invalid={!!errors.description}
								/>
							</FormGroup>
							<FormGroup>
								<SwitchController
									name="isActive"
									control={control}
									label="forms.is-active"
									boxClassName="py-2"
								/>
							</FormGroup>
							<SubmitButtonGroup
								isDisable={!isDirty}
								isSubmitting={isSubmitting}
							/>
						</Col>
					</Row>
				</form>
			)}
			{formSuccess && (
				<FormSuccess
					addButtonTitle="button.contact-group.create-new"
					listTitle="button.contact-group.list"
					successTitle="title.contact-group"
					className="flex-grow-1 align-self-center"
					listUrl={contactGroups.path}
					onClickAdd={() => {
						setFormSuccess(false);
						resetForm();
					}}
				/>
			)}
		</Card>
	);
});

CreateContactGroup.displayName = 'CreateContactGroup';
