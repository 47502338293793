import { memo, FC, useState, useEffect, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EdiText, { EdiTextProps } from 'react-editext';
import { Colors } from 'Types';
import { Button, FontAwesome } from 'Elements';
import { body, clx } from 'Utils';
import styles from 'partials/homepage/editor.module.scss';

interface Props extends EdiTextProps {
	className?: string;
	wrapperClassName?: string;
	navigationUrl?: string;
}

export const Editor: FC<Props> = memo(
	({
		type = 'text',
		onSave,
		className,
		wrapperClassName,
		navigationUrl,
		...editorProps
	}) => {
		const { t } = useTranslation();
		const navigate = useNavigate();
		const [editing, setEditing] = useState(false);
		const [value, setValue] = useState(editorProps.value);

		const openNavigationUrl = () => {
			navigate(navigationUrl as string, {
				state: {
					prevPath: 'homepage'
				}
			});
		};

		const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
			const target = e.target;
			setValue(target.value);
		};

		const toggleEditing = () => {
			setEditing(!editing);
		};

		const handleSave = () => {
			onSave(value);
			toggleEditing();
		};

		useEffect(() => {
			setValue(editorProps.value);
		}, [editorProps.value]);

		useEffect(() => {
			const body_class = 'active-editor';
			if (editing) {
				body.classList.add(body_class);
			} else {
				body.classList.remove(body_class);
			}
			return () => {
				body.classList.remove(body_class);
			};
		}, [editing]);

		return (
			<div
				className={clx(
					styles.container,
					editing && styles.editing,
					wrapperClassName
				)}>
				<div className={styles.toolbar}>
					{!editing && (
						<Button
							size="sm"
							className="ms-auto flex-shrink-0"
							onClick={() => {
								!navigationUrl
									? setEditing(true)
									: openNavigationUrl();
							}}>
							<FontAwesome
								icon="pen"
								className="me-2"
								size="lg"
							/>
							{t('button.edit')}
						</Button>
					)}
					{editing && (
						<div className="d-flex w-100">
							<div className="ms-auto d-flex align-items-center">
								<Button
									size="sm"
									color={Colors['white-primary']}
									className="me-2 flex-shrink-0"
									onClick={toggleEditing}>
									{t('button.discard')}
								</Button>
								<Button
									size="sm"
									className="flex-shrink-0"
									onClick={handleSave}>
									<FontAwesome
										icon="floppy-disk"
										size="lg"
										className="me-2"
									/>
									{t('button.save')}
								</Button>
							</div>
						</div>
					)}
				</div>
				<EdiText
					containerProps={{
						className: 'w-100'
					}}
					viewProps={{
						className
					}}
					inputProps={{
						className: styles.input,
						onChange: handleInput
					}}
					hideIcons
					cancelOnEscape
					type={type}
					editing={editing}
					submitOnEnter={!navigationUrl}
					editOnViewClick={!navigationUrl}
					editButtonClassName={styles.editor_button}
					cancelButtonClassName={styles.editor_button}
					saveButtonClassName={styles.editor_button}
					editContainerClassName={styles.editor_container}
					viewContainerClassName={styles.editor_viewcontainer}
					onSave={handleSave}
					onCancel={toggleEditing}
					onEditingStart={toggleEditing}
					{...editorProps}
				/>
			</div>
		);
	}
);

Editor.displayName = 'Editor';
