import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, FontAwesome } from 'Elements';
import { clx, formatTimeToCET, formatTimeToNow } from 'Utils';
import {
	Colors,
	EventDetails,
	EventInvitationStatus,
	EventStatus,
	RegistrationStatus
} from 'Types';
import styles from 'partials/page/my-event.module.scss';

interface Props {
	className?: string;
	event: EventDetails;
	onAccept?: () => void;
	onCancel?: () => void;
}

export const EventParticipationStatusBox: FC<Props> = memo(
	({ className, event, onAccept, onCancel }) => {
		const { t } = useTranslation();
		const hasInvitation = event.companyInvitation;
		const hasParticipate = event.participationInfo && !hasInvitation;
		const status = hasInvitation && event.companyInvitation.status;
		const partnerRegistrationStatus =
			hasInvitation && event.partnerRegistrationStatus;

		return (
			<Card
				className={clx(
					className,
					styles.card,
					status === EventInvitationStatus.Pending && styles.pending,
					(status === EventInvitationStatus.Accepted ||
						hasParticipate) &&
						styles.approved,
					status === EventInvitationStatus.Rejected &&
						styles.rejected,
					partnerRegistrationStatus === RegistrationStatus.Finished &&
						styles.finished
				)}>
				{partnerRegistrationStatus &&
					partnerRegistrationStatus !==
						RegistrationStatus.Finished && (
						<div>
							{status !== EventInvitationStatus.Accepted ? (
								<>
									{partnerRegistrationStatus !==
										RegistrationStatus.Open && (
										<time className={styles.timeRow}>
											<span className="d-flex align-items-center">
												<FontAwesome
													icon="timer"
													size="sm"
													className="me-2 flex-shrink-0"
												/>
												{t(
													'label.event.register-start-in'
												)}
												:
											</span>
											<span className="fw-300">
												{formatTimeToNow(
													event.partnerRegistrationStartDate ||
														event.startDate
												)}
											</span>
										</time>
									)}
									<time className={styles.timeRow}>
										<span className="d-flex align-items-center">
											<FontAwesome
												icon="timer"
												size="sm"
												className="me-2 flex-shrink-0"
											/>
											{t('label.event.register-end-in')}:
										</span>
										<span className="fw-300">
											{formatTimeToNow(
												event.partnerRegistrationEndDate ||
													event.endDate
											)}
										</span>
									</time>
								</>
							) : (
								<time className={styles.timeRow}>
									{t('label.event.accept-in')}:
									<span className="fw-300">
										{formatTimeToCET(
											event.companyInvitation.responseDate
										)}
									</span>
								</time>
							)}
						</div>
					)}
				{event.eventStatus !== EventStatus.Canceled &&
					!event.isFinished &&
					partnerRegistrationStatus === RegistrationStatus.Open && (
						<div className="d-flex align-items-center gap-3 flex-wrap mt-auto pt-3">
							{status === EventInvitationStatus.Pending && (
								<>
									<Button
										color={Colors['white-primary']}
										label={
											t('button.event.reject') as string
										}
										onClick={onCancel}
									/>
									<Button
										label={
											t('button.event.accept') as string
										}
										onClick={onAccept}
									/>
								</>
							)}
							{status === EventInvitationStatus.Accepted && (
								<Button
									color={Colors['white-red']}
									label={t('button.event.cancel') as string}
									onClick={onCancel}
								/>
							)}
							{status === EventInvitationStatus.Rejected && (
								<Button
									label={
										t('button.event.re-accept') as string
									}
									onClick={onAccept}
								/>
							)}
						</div>
					)}
				{partnerRegistrationStatus &&
					partnerRegistrationStatus ===
						RegistrationStatus.Finished && (
						<p className="mt-3 fw-500">
							{t('text.event.you-were-invited')}
						</p>
					)}
				{hasParticipate && (
					<div>
						<time className={styles.timeRow}>
							{t('label.event.participate-in')}:
							<span className="fw-300">
								{formatTimeToCET(
									event.participationInfo.createdAt
								)}
							</span>
						</time>
						<div className="d-flex align-items-center gap-3 flex-wrap mt-3">
							<div className="flex-grow-1 d-flex align-items-center">
								{t('label.event.number-of-seat')}:{' '}
								<strong className="fw-500 ms-2">
									{event.participationInfo.numberOfSeats}
								</strong>
							</div>
							<Button
								color={Colors['white-red']}
								label={t('button.event.cancel') as string}
								onClick={onCancel}
							/>
						</div>
					</div>
				)}
			</Card>
		);
	}
);

EventParticipationStatusBox.displayName = 'EventParticipationStatusBox';
