import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { clx } from 'Utils';
import { FontAwesome } from 'Elements';
import { ApplicantFile } from 'Types';
import styles from 'partials/feature/file-card.module.scss';

interface Props {
	card: ApplicantFile;
	onRemove: (id: string) => void;
}

export const FileUploaderCard: FC<Props> = memo(({ card, onRemove }) => {
	const { t } = useTranslation();

	const isImage = () => {
		const acceptedImageTypes = ['gif', 'jpeg', 'png', 'jpg'];
		return acceptedImageTypes.some((type) => card.title.includes(type));
	};

	return (
		<div className={styles.card}>
			<div className="d-flex align-items-center gap-2 text-truncate flex-grow-1">
				<div className="position-relative text-center d-flex align-items-center flex-shrink-0">
					<img
						className={styles.image}
						src={
							isImage() ? card.url : '/images/applicant/file.svg'
						}
					/>
					{!isImage() && (
						<span
							className={clx(
								styles.file,
								'position-absolute start-50 top-50 translate-middle fs-6'
							)}>
							{card.title.substring(
								card.title.lastIndexOf('.') + 1
							)}
						</span>
					)}
				</div>
				<h6
					className="mb-0 fs-sm fw-400 text-truncate flex-grow-1"
					title={card.title}>
					{card.title}
				</h6>
			</div>
			<div className="d-flex align-items-center ms-auto gap-2">
				<a
					download
					href={card.url}
					target="_blank"
					rel="noreferrer"
					title={t('forms.download')}
					className={styles.link}>
					<FontAwesome icon="download" size="lg" fixedWidth />
				</a>
				<span
					role="button"
					title={t('forms.remove')}
					className="text-red d-flex align-items-center"
					onClick={() => {
						onRemove(card.id);
					}}>
					<FontAwesome icon="trash-can" size="lg" fixedWidth />
				</span>
			</div>
		</div>
	);
});

FileUploaderCard.displayName = 'FileUploaderCard';
