import {
	Activity,
	ActivityId,
	ApplicantDemand,
	CompanySetting,
	JobType,
	Sector,
	SelectOption
} from 'Types';

export enum UpdateJobActionTypes {
	UPDATE_JOB = 'UPDATE_JOB'
}

export enum JobStatisticReportRange {
	Monthly = 0,
	SixMonth = 1,
	Yearly = 2
}

export interface Job {
	id: string;
	code: string;
	shortDescription: string;
	description: string;
	benefits: string;
	requirements: string;
	jobStatus: JobStatus;
	responsibilities: string;
	isActive: boolean;
	permanent: boolean;
	isExpired: boolean;
	isAutoPublish: boolean;
	approved: boolean;
	title: string;
	featuredPhotoUrl: string;
	featuredVideoUrl: string;
	expireDate: string;
	startDate: string;
	modifiedAt: string;
	publishedAt: string;
	version: string;
	sectorId: string;
	sectors: Sector;
	jobTypeId: string;
	jobTypes: JobType;
	currentVersion: number;
	publishedVersion: number | null;
	activityList: ActivityId[];
	activityLists: Activity[];
	publishedInfo: PublishedInfo;
	contactPerson: JobContactPerson;
	showBenefits: boolean;
	showDescription: boolean;
	showRequirements: boolean;
	showResponsibilities: boolean;
}

export interface PublishedInfo {
	downloadUrl: string;
	slugUrl: string;
	jobVersion: number;
	publishedVersion: number;
	publishJobStatus: number | null;
}

export interface JobStatus {
	statusTypes: number;
	createdDate: string;
	createdUser: string;
	userName: string;
}

export interface ApplicantJob {
	id: string;
	jobId: string;
	code: string;
	shortDescription: string;
	title: string;
	description: string;
	benefits: string;
	requirements: string;
	responsibilities: string;
	companyId: string;
	sectorId: string;
	sector: Sector;
	jobTypeId: string;
	jobType: JobType;
	activityList: ActivityId[];
	activityLists: Activity[];
	applicantDemand: ApplicantDemand;
	company: Partial<CompanySetting>;
}

export interface JobFormGeneral {
	code: string;
	title: string;
	shortDescription: string;
	startDate: string | null;
	expireDate: string | null;
	isActive: boolean;
	hasStartDate: boolean;
	hasExpireDate: boolean;
}

export interface JobFormFeatured {
	featuredPhotoUrl: string;
	featuredVideoUrl: string;
}

export interface JobContactPerson {
	fullName: string;
	emailAddress: string;
	phoneNumber: string;
	image: string;
	thumbnailImage: string;
	visibleOnDetail: boolean;
}

export interface JobFormInfo {
	sectorId: SelectOption | null;
	jobTypeId: SelectOption | null;
	activityList: SelectOption[] | null;
}

export interface JobFormDescription {
	description: string;
	benefits: string;
	requirements: string;
	responsibilities: string;
	showDescription: boolean;
	showRequirements: boolean;
	showResponsibilities: boolean;
	showBenefits: boolean;
}

export type JobFormInitialData = JobFormGeneral &
	JobFormInfo &
	JobFormFeatured &
	JobFormDescription & { contactPerson: JobContactPerson };

export interface JobForm {
	step1: JobFormGeneral;
	step2: JobFormFeatured;
	step3: JobFormInfo;
	step4: JobFormDescription;
}

export interface JobFormData {
	code: string;
	title: string;
	shortDescription: string;
	isActive: boolean;
	sectorId: string;
	jobTypeId: string;
	featuredPhotoUrl: string;
	featuredVideoUrl: string;
	startDate: string | Date;
	expireDate: string | Date;
	activityList: ActivityId[];
	description: string;
	benefits: string;
	requirements: string;
	responsibilities: string;
	contactPerson: JobContactPerson | null;
}

export interface JobStatistic {
	id: string;
	draft: number;
	autoPublished: number;
	published: number;
	expired: number;
	unpublished: number;
	companyId: string;
	createdAt: string;
}

export interface TotalJobStatistic {
	id: string;
	totalDraft: number;
	totalAutoPublished: number;
	totalPublished: number;
	totalExpired: number;
	totalUnpublished: number;
	companyId: string;
	createdAt: string;
}
