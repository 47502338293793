import { memo, FC, ReactNode } from 'react';
import { AnimatePresence } from 'framer-motion';
import { clx } from 'Utils';
import styles from 'partials/data-table/data-table.module.scss';

interface Props {
	children: ReactNode;
}

export const DataTableBody: FC<Props> = memo(({ children }) => {
	const bodyClass = clx('flex-grow-1', styles.body);
	return (
		<div className={bodyClass}>
			<AnimatePresence>{children}</AnimatePresence>
		</div>
	);
});

DataTableBody.displayName = 'DataTableBody';
