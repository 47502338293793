import { memo, FC, ReactNode } from 'react';
import { clx } from 'Utils';
import { Text, Title } from 'Styles';
import { Editor } from 'Elements';
import { ColorValues } from 'Types';
import styles from 'partials/homepage/headline.module.scss';

interface Props {
	children?: ReactNode;
	title: string;
	description?: string;
	className?: string;
	titleColor?: string;
	textColor?: string;
	onUpdateTitle: (val: string) => void;
	onUpdateDescription?: (val: string) => void;
}

export const SectionHeadline: FC<Props> = memo(
	({
		children,
		title,
		description,
		className,
		titleColor = ColorValues['gray-4'],
		textColor = ColorValues['gray-4'],
		onUpdateTitle,
		onUpdateDescription
	}) => {
		return (
			<header
				className={clx('mb-4', className)}
				style={{ minWidth: '50%' }}>
				<div
					className={clx(
						styles.title,
						'd-flex flex-column justify-content-center mx-auto'
					)}>
					<Title
						textColor={titleColor}
						className="h3 fw-700 mb-0 text-center">
						<Editor
							type="text"
							value={title || '------'}
							onSave={onUpdateTitle}
						/>
					</Title>
					{onUpdateDescription && (
						<Text
							as="div"
							textColor={textColor}
							className="mt-3 lh-md text-center">
							<Editor
								type="textarea"
								value={description || '------'}
								onSave={onUpdateDescription}
							/>
						</Text>
					)}
				</div>
				{children}
			</header>
		);
	}
);

SectionHeadline.displayName = 'SectionHeadline';
