import { startTransition } from 'react';
import axios from 'Adapter';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { queryKey } from 'Constants';
import { api, fetch, toastify } from 'Utils';
import {
	DataList,
	DataQueryParams,
	Invitation,
	InvitationFormData
} from 'Types';

const config = (options = {}) => {
	return {
		...{ keepPreviousData: false },
		...options
	};
};

export const useInvitations = <T = DataList<Invitation>>(
	{ pageSize = 12, pageNumber = 1, keyword = '' }: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<Invitation>>
): UseQueryResult<DataList<Invitation>, unknown> => {
	return useQuery(
		{
			queryKey: [queryKey.invitations, { pageSize, pageNumber, keyword }],
			queryFn: () => fetch.invitationList(pageSize, pageNumber, keyword),
			...config(options)
		}
	);
};

export const useInvitationDetails = <T = Invitation>(
	invitationId: string,
	options?: UseQueryOptions<T, any, Invitation>
): UseQueryResult<Invitation, unknown> => {
	return useQuery(
		{
			queryKey: [queryKey.invitation, { id: invitationId }],
			queryFn: () => fetch.invitationDetails(invitationId),
			...config(options)
		}
	);
};

export const useInvitation = (invitationId?: string) => {
	const query = useInvitationDetails(invitationId as string, {
		enabled: !!invitationId
	});
	const invitation = query.data;

	const createInvitation: (
		fields: InvitationFormData,
		onFinal?: () => void
	) => Promise<void> = async (fields, onFinal) => {
		await axios.post(api.createInvitation, fields).then((res) => {
			if (res.status === 200) {
				startTransition(() => {
					onFinal?.();
				});
			}
		});
	};

	const updateInvitation: (
		fields: InvitationFormData,
		onFinal?: () => void
	) => Promise<void> = async (fields, onFinal) => {
		const data = {
			...invitation,
			...fields
		};
		await axios
			.put(api.updateInvitation(invitationId as string), data)
			.then((res) => {
				if (res.status === 200) {
					query.refetch();
					startTransition(() => {
						toastify('toastify.invitation-updated', {
							type: 'success'
						});
					});
					onFinal?.();
				}
			});
	};

	return {
		...query,
		createInvitation,
		updateInvitation
	};
};
