import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from 'Elements';
import { clx, getKeyFromValue } from 'Utils';
import { Colors, TransactionStatus } from 'Types';

interface Props {
	list: TransactionStatus[];
	title: TransactionStatus;
	color?: Colors;
	className?: string;
}

export const PaymentBadgeStatus: FC<Props> = memo(
	({ list, title, color, className }) => {
		const { t } = useTranslation();

		const renderBadge = () => {
			const shouldRenderBadge = list.includes(title);
			if (!shouldRenderBadge) {
				return null;
			}

			const transactionStatus = getKeyFromValue(TransactionStatus, title);
			const badgeText = transactionStatus
				? t(`badge.${transactionStatus.toLowerCase()}`)
				: '';

			return (
				<Badge
					color={color ?? Colors.primary}
					className={clx(className)}>
					{badgeText}
				</Badge>
			);
		};

		return renderBadge();
	}
);

PaymentBadgeStatus.displayName = 'PaymentBadgeStatus';
