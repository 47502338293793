import { memo, FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { Button, FontAwesome } from 'Elements';
import { Colors } from 'Types';
import styles from 'partials/job/job.module.scss';

interface Props {
	children: ReactNode;
}

export const ShareJobDropDown: FC<Props> = memo(({ children }) => {
	const { t } = useTranslation();
	return (
		<UncontrolledDropdown>
			<DropdownToggle tag="div">
				<Button
					color={Colors['white-primary']}
					title={t('title.share-job')}
					className={styles.action}>
					<FontAwesome
						icon='share-nodes'
						size="xl"
						fixedWidth
					/>
				</Button>
			</DropdownToggle>
			<DropdownMenu end>{children}</DropdownMenu>
		</UncontrolledDropdown>
	);
});

ShareJobDropDown.displayName = 'ShareJobDropDown';
