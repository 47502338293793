import { memo, FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
	Colors,
	SubscriptionDetails,
	PaymentMethod,
	CompanyApproveStatus
} from 'Types';
import { Badge, Card, LoadingContent } from 'Elements';
import {
	clx,
	currencyFormatter,
	getDiscountFormat,
	invoiceStatus
} from 'Utils';
import styles from 'partials/card/subscription-card.module.scss';

interface Props {
	subscription: SubscriptionDetails;
	isLoading: boolean;
}

const CardBox = styled(Card)`
	min-height: 286px;
`;

export const SubscriptionInvoice: FC<Props> = memo(
	({ subscription, isLoading }) => {
		const { t } = useTranslation();
		const row_class = 'justify-content-between';
		const applyVoucher = subscription?.applyVoucher;
		const discount = applyVoucher?.discount ?? subscription?.discount ?? 0;
		const discountType =
			applyVoucher?.discountType ?? subscription?.discountType ?? 0;

			
		const getStatusData = () => {
			if (
				subscription?.paymentStatus === 'pending' &&
				subscription?.payment?.status === 'processing' &&
				subscription?.status?.approveStatus ===
					CompanyApproveStatus.Approved &&
				subscription?.paymentMethod === PaymentMethod.sepa
			) {
				return invoiceStatus.find(
					(elem) =>
						elem?.title?.toLowerCase() ===
						subscription?.payment?.status?.toString()
				);
			} else {
				return invoiceStatus.find(
					(elem) =>
						elem?.title?.toLowerCase() ===
						subscription?.paymentStatus?.toString()
				);
			}
		};

		return (
			<CardBox className="h-100">
				<h6 className="text-gray-3 mb-3 fs-6 fw-400 d-flex align-items-center">
					{t('title.invoice')}
					<Badge
						color={getStatusData()?.color ?? Colors.muted}
						className="ms-auto">
						{t(`${getStatusData()?.label}`)}
					</Badge>
				</h6>
				<div className={clx(styles.row, row_class)}>
					<span className={styles.rowLabel}>
						{t('label.net-price')}:
					</span>
					<span>{currencyFormatter(subscription?.price ?? 0)}</span>
				</div>
				<div className={clx(styles.row, row_class)}>
					<div className={clx(styles.rowLabel, 'd-flex flex-column')}>
						<div className="d-flex align-items-center">
							{t('label.discount')}
							<span className="ms-1 text-gray-3 fs-small">
								({getDiscountFormat(discount, discountType)})
							</span>
							:
						</div>
						{subscription?.applyVoucher && (
							<div className="d-flex align-items-center mt-1 lh-base text-gray-3 fs-small">
								{t('label.voucher-code')}:
								<span className="ms-1">
									{subscription?.applyVoucher?.voucherCode}
								</span>
							</div>
						)}
					</div>
					<span>
						{subscription?.discountAmount > 0
							? `- ${currencyFormatter(
									subscription?.discountAmount ?? 0
							  )}`
							: currencyFormatter(0)}
					</span>
				</div>
				<div className={clx(styles.row, row_class)}>
					<span className={styles.rowLabel}>
						{t('label.subtotal')}:
					</span>
					<span>
						{currencyFormatter(subscription?.subTotal ?? 0)}
					</span>
				</div>
				<div className={clx(styles.row, row_class)}>
					<span className={styles.rowLabel}>
						{t('label.tax')}
						<span className="ms-1 text-gray-3 fs-small">
							({subscription?.taxPercent ?? 0}
							%)
						</span>
						:
					</span>
					<span>
						{subscription?.taxAmount > 0
							? `+ ${currencyFormatter(
									subscription?.taxAmount ?? 0
							  )}`
							: currencyFormatter(0)}
					</span>
				</div>
				<div className={clx(styles.row, row_class)}>
					<span className={clx(styles.rowLabel, 'fw-500')}>
						{t('label.total')}
					</span>
					<div className="d-flex flex-column text-end">
						<span className="fs-6 fw-500">
							{currencyFormatter(subscription?.totalAmount ?? 0)}
						</span>
						<span className="text-gray-3 lh-base fs-small">
							{t('label.tax-included')}
						</span>
					</div>
				</div>
				{isLoading && <LoadingContent />}
			</CardBox>
		);
	}
);

SubscriptionInvoice.displayName = 'SubscriptionInvoice';
