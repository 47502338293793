import { memo, FC, InputHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';
import { FormGroup, Label } from 'reactstrap';
import { AssetConfigElement, AssetConfigType, InputTextElement } from 'Types';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
	element: AssetConfigElement<InputTextElement>;
	errors?: AssetConfigType;
	name: string;
	label: string;
	defaultValue: string;
}

export const TextElement: FC<Props> = memo(
	({ element, defaultValue, name, label, errors, ...inputProps }) => {
		const { t } = useTranslation();
		const required_validation = 'validation.required';

		return (
			<FormGroup>
				<Label htmlFor={name}>
					{t(label)}
					{element.isRequired && (
						<small className="ms-1">
							({t(required_validation)})
						</small>
					)}
				</Label>
				<input
					type="text"
					name={name}
					className="inputbox w-100"
					defaultValue={defaultValue}
					onInput={inputProps.onChange}
					{...inputProps}
				/>
				{errors && (
					<div className="invalid-feedback d-block">{errors}</div>
				)}
			</FormGroup>
		);
	}
);

TextElement.displayName = 'TextElement';
