import { FC, memo } from 'react';
import { Image, Breathing } from 'react-shimmer';

interface Props {
	src: string;
	alt: string;
	defaultImage: string;
	className: string;
	width: number;
	height: number;
	fallback?: JSX.Element;
}

export const ShimmerImage: FC<Props> = memo(
	({ src, alt, defaultImage, className, width, height, fallback }) => {
		const errorFallback = () => {
			return <img className={className} src={defaultImage} alt={alt} />;
		};

		const Fallback = fallback;
		return (
			<Image
				fadeIn
				src={src || defaultImage}
				fallback={
					Fallback || <Breathing width={width} height={height} />
				}
				errorFallback={errorFallback}
				NativeImgProps={{
					className,
					alt
				}}
			/>
		);
	}
);

ShimmerImage.displayName = 'ShimmerImage';
