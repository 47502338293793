import { memo, FC } from "react"
import { Col, Row } from "reactstrap"

interface Props {}

export const AgentDashboard: FC<Props> = memo(() => {
    return (
		<Row>
			<Col xs={12} className="mb-4">
				{/* <AgentDecorationBox /> */}
			</Col>
			<Col md={6}>
                
            </Col>
			<Col md={6}></Col>
		</Row>
	);
})

AgentDashboard.displayName = "AgentDashboard"