import { memo, FC, useState, ChangeEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { clx } from 'Utils';
import { Colors } from 'Types';
import { Button, FontAwesome } from 'Elements';
import { useDebounce } from 'Hooks';
import styles from 'partials/shared/heading/page-heading-search.module.scss';

interface Props {
	className?: string;
	onSubmit?: (keyword: string) => void;
}

export const PageHeadingSearch: FC<Props> = memo(({ className, onSubmit }) => {
	const { t } = useTranslation();
	const [searchParams] = useSearchParams();
	const queryParam = searchParams.get('q') || '';
	const [keyword, setKeyword] = useState(queryParam ?? '');
	const debouncedSearchTerm = useDebounce(keyword, 1000);

	const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
		const target = e.target;
		setKeyword(target.value);
		onSubmit?.(debouncedSearchTerm);
	};

	const handleSubmit = () => {
		onSubmit?.(debouncedSearchTerm);
	};

	useEffect(() => {
		onSubmit?.(debouncedSearchTerm);
		// eslint-disable-next-line
	}, [debouncedSearchTerm]);

	return (
		<div className={clx(styles.form, className, 'p-0 inputbox')}>
			<input
				type="search"
				defaultValue={queryParam ?? ''}
				className={clx(styles.input, 'w-100')}
				placeholder={t('menu.search')}
				onChange={handleInput}
			/>
			<Button
				type="button"
				color={Colors.empty}
				className={clx('p-0', styles.submit)}
				onClick={handleSubmit}>
				<FontAwesome size="sm" icon="magnifying-glass" />
			</Button>
		</div>
	);
});

PageHeadingSearch.displayName = 'PageHeadingSearch';
