import { memo, FC, PropsWithChildren } from 'react';
import { Outlet } from 'react-router-dom';
import { ScrollProvider } from 'Context';
import { ScrollTop, ThemeLayout } from 'Elements';

export const PublicThemeProvider: FC<PropsWithChildren> = memo(() => {
	return (
		<ThemeLayout>
			<ScrollTop />
			<ScrollProvider>
				<Outlet />
			</ScrollProvider>
		</ThemeLayout>
	);
});

PublicThemeProvider.displayName = 'PublicThemeProvider';
