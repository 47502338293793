import { memo, useEffect, useReducer } from 'react';
import Swiper from 'swiper';
import { Container } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { SwiperSlide } from 'swiper/react';
import { Pagination, EffectFade } from 'swiper/modules';
import { useTranslation } from 'react-i18next';
import { createGlobalStyle } from 'styled-components';
import { api, clx } from 'Utils';
import {
	AspectRatio,
	CompanySetting,
	Gallery,
	GalleryFormData,
	Media
} from 'Types';
import {
	GalleryReducer,
	setImageLoading,
	setImageRemoving,
	setImageUpdating,
	setSwiper
} from 'Reducers';
import {
	useAxios,
	useDelayUnmount,
	useModal,
	useTheme,
	useUpdateCompany
} from 'Hooks';
import {
	Button,
	Carousel,
	GallerySlide,
	LoadingContent,
	ModalBoxChangeImage,
	SectionSwitcher,
	FontAwesome
} from 'Elements';
import 'swiper/css/effect-fade';
import styles from 'partials/homepage/section/gallery-section.module.scss';

const GlobalStyle = createGlobalStyle<{ bgColor: string }>`
	${({ bgColor }) =>
		bgColor &&
		`.swiperNav {
			background-color: ${bgColor} !important;
		}
		.swiper-pagination-current {
			color: ${bgColor} !important;
			font-weight: 700;
		}
	`}
`;

export const CompanyGallerySection = memo(() => {
	const { t } = useTranslation();
	const { theme } = useTheme();
	const { control, reset } = useForm();
	const [openModal, toggleModal] = useModal();
	const { sendRequest } = useAxios();
	const { setting, company, isLoading, updateCompanySetting, refetch } =
		useUpdateCompany();
	const gallery = company?.gallery;
	const shouldRenderModal = useDelayUnmount(openModal, 350);

	const initialState = {
		swiper: null,
		nextImage: '',
		prevImage: '',
		imageLoading: false,
		imageRemoving: false,
		imageUpdating: false
	};
	const [state, dispatch] = useReducer(GalleryReducer, initialState);

	const handleUpdateSetting = async (field: Partial<CompanySetting>) => {
		await updateCompanySetting(field);
	};

	const initSwiper = (swiper: Swiper) => {
		dispatch(setSwiper(swiper));
	};

	const updateSwiper = () => {
		if (state.swiper) {
			state.swiper.update();
		}
	};

	const updateGalleryItem = async (
		data: { gallery: Gallery },
		gallery_id: string
	) => {
		await sendRequest(
			api.updateCompanyGallery(gallery_id),
			{ data, method: 'PATCH' },
			() => {
				void refetch();
				updateSwiper();
			}
		);
	};

	const removeGalleryItem = async (item: { galleryId: string }) => {
		const data = item;
		await sendRequest(
			api.removeCompanyGallery,
			{ data, method: 'DELETE' },
			() => {
				void refetch();
				updateSwiper();
			}
		);
	};

	const submitGallery = async (image_data: {
		gallery: GalleryFormData[];
	}) => {
		await sendRequest(
			api.createCompanyGallery,
			{ data: image_data },
			() => {
				void refetch();
				updateSwiper();
			}
		);
	};

	const handleUploadImage = async (image_data: Media) => {
		try {
			dispatch(setImageLoading(true));
			const api_data: GalleryFormData[] = [
				{
					image: image_data.url,
					imageThumbnail: image_data.thumbnailUrl,
					showInHomePage: true,
					sortOrder: 1,
					title: image_data.metaData?.OriginalName,
					originalName: image_data.metaData?.OriginalName,
					size: image_data.metaData?.ActualSize
				}
			];
			const imageData = { gallery: api_data };
			await submitGallery(imageData);
		} finally {
			dispatch(setImageLoading(false));
		}
	};

	const handleDeleteItem = async (gallery_id: string) => {
		const data = {
			galleryId: gallery_id
		};
		try {
			dispatch(setImageRemoving(true));
			await removeGalleryItem(data);
		} finally {
			dispatch(setImageRemoving(false));
		}
	};

	const handleEditItem = async (gallery_item: Gallery, image_data: Media) => {
		const data = {
			gallery: {
				...gallery_item,
				...{
					image: image_data.url,
					imageThumbnail: image_data.thumbnailUrl,
					title: image_data.metaData.OriginalName,
					OriginalName: image_data.metaData.OriginalName
				}
			}
		};
		try {
			dispatch(setImageUpdating(true));
			await updateGalleryItem(data, gallery_item.id);
		} finally {
			dispatch(setImageUpdating(false));
		}
	};

	useEffect(() => {
		reset(setting);
	}, [reset, setting]);

	return (
		<section className="position-relative mb-5 pb-5">
			{!isLoading && gallery && (
				<Container>
					<GlobalStyle bgColor={theme.company.primaryColor} />
					<SectionSwitcher
						control={control}
						name="gallerySectionStatus"
						label="forms.show-gallery-in-home"
						isActive={!setting?.gallerySectionStatus}
						onSwitchChange={(val) =>
							handleUpdateSetting({
								gallerySectionStatus: val
							})
						}
					/>
					{gallery?.length > 0 && (
						<Carousel
							effect="fade"
							observeParents
							fadeEffect={{
								crossFade: true
							}}
							navigationGroup={gallery.length > 1}
							className={styles.carousel}
							enabled={gallery.length > 1}
							modules={[Pagination, EffectFade]}
							navigation
							navigationClass={clx(styles.swiperNav, 'swiperNav')}
							pagination={{
								enabled: gallery.length > 1,
								type: 'fraction',
								horizontalClass: `${styles.pagination}`,
								bulletClass: `${styles.page} gallery-page`,
								bulletActiveClass: `gallery-page-active`
							}}
							onInit={initSwiper}
							onSwiper={initSwiper}
							onSlideChange={updateSwiper}
							onUpdate={initSwiper}
							onActiveIndexChange={updateSwiper}>
							{gallery
								?.slice(0)
								?.reverse()
								?.map((gallery) => {
									return (
										<SwiperSlide
											key={gallery.id}
											className={styles.slide}>
											<GallerySlide
												title={gallery.title}
												image={gallery.image}
												imageClassName={
													styles.slideImage
												}
												isUpdating={state.imageUpdating}
												isRemoving={state.imageRemoving}
												onEdit={(image_data: Media) =>
													handleEditItem(
														gallery,
														image_data
													)
												}
												onRemove={() =>
													handleDeleteItem(gallery.id)
												}
											/>
										</SwiperSlide>
									);
								})}
						</Carousel>
					)}
					{gallery?.length === 0 && (
						<div
							role="button"
							className={styles.add_slide}
							onClick={toggleModal}>
							<FontAwesome
								icon="upload"
								size="3x"
								className="mb-3"
							/>
							{t('button.add-new-image')}
						</div>
					)}
					{state.imageLoading && (
						<LoadingContent title="title.uploading" />
					)}
					{gallery?.length > 0 && (
						<div className="d-flex align-items-center justify-content-center mt-3 mt-md-4">
							<Button
								size="lg"
								className="gap-3 rounded-pill"
								disabled={state.imageLoading}
								onClick={toggleModal}>
								<FontAwesome
									icon="plus"
									size="xl"
									swapOpacity
								/>
								{t('button.add-new-image')}
							</Button>
						</div>
					)}
				</Container>
			)}
			{!isLoading && !setting?.gallerySectionStatus && (
				<div className="overlay overlay_white overlay-box" />
			)}
			{shouldRenderModal && (
				<ModalBoxChangeImage
					name="companyHeaderLogo"
					isOpen={openModal}
					title="title.add-new-image"
					image={''}
					aspectRatio={AspectRatio['16/9']}
					onClose={toggleModal}
					onImageChange={handleUploadImage}
				/>
			)}
		</section>
	);
});
CompanyGallerySection.displayName = 'CompanyGallerySection';
