import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field';
import { CurrencyInputOnChangeValues } from 'react-currency-input-field/dist/components/CurrencyInputProps';
import {
	Controller,
	UseControllerProps,
	FieldValues,
	ControllerFieldState
} from 'react-hook-form';
import { defaultLocale } from 'Constants';
import { clx } from 'src/helpers/Utils';

type Props<T extends FieldValues> = {
	className?: string;
	inputClassName?: string;
	onCurrencyChange?: (
		value: string | undefined,
		name?: string | undefined,
		values?: CurrencyInputOnChangeValues | undefined
	) => void;
} & Partial<ControllerFieldState> &
	UseControllerProps<T> &
	CurrencyInputProps;

export const CurrencyController = <T extends FieldValues>({
	control,
	name,
	className,
	inputClassName,
	error,
	onCurrencyChange,
	...inputProps
}: Props<T>): JSX.Element => {
	return (
		<div className={className}>
			<Controller
				control={control}
				name={name}
				render={({ field: { onChange, value } }) => (
					<CurrencyInput
						{...inputProps}
						value={value}
						aria-invalid={!!error}
						className={clx('inputbox w-100', inputClassName)}
						allowNegativeValue={false}
						decimalSeparator="."
						groupSeparator=","
						intlConfig={{
							locale: defaultLocale.lang,
							currency: 'EUR'
						}}
						onValueChange={(val) => {
							onChange(val);
							onCurrencyChange?.(val);
						}}
					/>
				)}
			/>
			{error && (
				<div className="invalid-feedback d-block">{error.message}</div>
			)}
		</div>
	);
};
