import { memo, FC} from 'react';
import { AgentHeroHeader, CompanyHeroHeader } from 'Elements';
import styles from 'partials/homepage/section/profile-hero-header.module.scss';

interface Props {
	type: 'agent' | 'company';
}

export const ProfileHeroHeader: FC<Props> = memo(({ type }) => {
	return (
		<header className={styles.header}>
			{type === 'agent' && <AgentHeroHeader />}
			{type === 'company' && <CompanyHeroHeader />}
		</header>
	);
});

ProfileHeroHeader.displayName = 'ProfileHeroHeader';
