import { memo, useState } from 'react';
import { motion } from 'framer-motion';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useContactGroups, useContactGroup, useDelayUnmount, useModal } from 'Hooks';
import { containerVariants, names } from 'Constants';
import { clx, formatLocaleDateTime } from 'Utils';
import { useTranslation } from 'react-i18next';
import { ContactGroup, Colors } from 'Types';
import {
	DataTable,
	DataTableBody,
	DataTableHead,
	DataTableLoading,
	DataTableRow,
	DataTableRowCell,
	DataTableOperation,
	EndOfList,
	ListingPage,
	NoData,
	Pagination,
	Badge,
	ModalBoxDelete,
	SystemErrorAlert
} from 'Elements';

export const ListContactGroup = memo(() => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { contactGroups } = names;
	const [searchParams, setSearchParams] = useSearchParams();
	const params = Object.fromEntries([...searchParams]);
	const pageParam = searchParams.get('page') || 1;
	const queryParam = searchParams.get('q') || '';
	const statusParam = searchParams.get('isActive') || '';
	const [openModalDelete, toggleModalDelete] = useModal();
	const { removeContactGroup } = useContactGroup();
	const shouldRenderModalDelete = useDelayUnmount(openModalDelete, 350);

	const [state, setState] = useState<{
		isActive: '';
		pageSize: number;
		currentPage: string | number;
		keyword: string;
		filterBy: string;
		isRemoving: boolean;
		selectedContactGroup: ContactGroup | null;
	}>({
		isActive: '',
		pageSize: 10,
		currentPage: pageParam,
		keyword: queryParam,
		filterBy: statusParam,
		isRemoving: false,
		selectedContactGroup: null
	});

	const { data, isFetching, isError, refetch } = useContactGroups({
		isActive: state.isActive,
		pageSize: state.pageSize,
		pageNumber: state.currentPage,
		keyword: state.keyword
	});

	const onChangePageSize = (pageSize: number) => {
		setSearchParams({ ...params, page: '1' });
		setState((prev) => ({
			...prev,
			pageSize,
			currentPage: 1
		}));
	};

	const handleSearch = (query: string) => {
		setSearchParams({
			...params,
			page: '1',
			q: query
		});
		setState((prev) => ({
			...prev,
			currentPage: 1,
			keyword: query
		}));
	};

	const handlePageChange = (page: number) => {
		setSearchParams({ ...params, page: page.toString() });
		setState((prev) => ({
			...prev,
			currentPage: page
		}));
	};

	const handleSelectRow = (row_id: string) => {
		return navigate(`${contactGroups.path}/${row_id}`, {
			state: { prevPath: '${contactGroups.path}' }
		});
	};

	const cellClassName = (col: string) => {
		const cols: { [x: string]: string } = {
			title: 'w-md-25',
			description: 'w-md-35',
			'created-at': 'w-md-15',
			status: 'w-md-15',
			operation: 'w-md-10'
		};
		return clx('w-100', cols[col.toLowerCase()]);
	};

	const onRemoveContactGroup = async (ContactGroup: ContactGroup) => {
		setState((prev) => ({
			...prev,
			selectedContactGroup: ContactGroup
		}));
		toggleModalDelete();
	};

	const handleRemoveContactGroup = async () => {
		setState((prev) => ({
			...prev,
			isRemoving: true
		}));
		await removeContactGroup(state?.selectedContactGroup?.id as string, refetch).then(() => {
			toggleModalDelete();
			setState((prev) => ({
				...prev,
				isRemoving: false
			}));
		});
	};

	return (
		<ListingPage
			totalItems={data?.totalItems}
			pageSizes={[5, 10, 20]}
			selectedPageSize={state.pageSize}
			onPageSizeChange={onChangePageSize}
			onSearchChange={handleSearch}>
			<DataTable>
				{data && data?.totalItems > 0 && (
					<DataTableHead>
						<DataTableRowCell
							cellText="table.title"
							cellClassName={cellClassName('title')}
						/>
						<DataTableRowCell
							cellText="table.desc"
							cellClassName={cellClassName('description')}
						/>
						<DataTableRowCell
							cellText="table.created-at"
							cellClassName={cellClassName('created-at')}
						/>
						<DataTableRowCell
							cellText="table.status"
							cellClassName={cellClassName('status')}
						/>
						<DataTableRowCell
							cellText="table.operation"
							cellClassName={cellClassName('operation')}
						/>
					</DataTableHead>
				)}
				<DataTableBody>
					{!isFetching &&
						<motion.div
							variants={containerVariants}
							initial="hidden"
							animate="show">
							{data?.items?.map((group) => {
								return (
									<DataTableRow key={group.id}>
										<DataTableRowCell
											cellClassName={cellClassName('title')}
											cellHead="table.contact-group-title">
											<Link
												to={`/contact-groups/${group.id}`}
												state={{
													prevPath: '/contact-groups'
												}}
												title={group.title}
												className="d-flex">
												<div className="fw-500 py-1 ps-2 ps-sm-0 text-end text-sm-start">
													{group.title}
													{group?.contactPersonCount !== 0 && <p className="text-gray-3 fs-small fw-light mb-0">
														<span>{group?.contactPersonCount}</span>
														<span className='ms-1'>{t('table.email')}</span>
													</p>}
												</div>
											</Link>
										</DataTableRowCell>
										<DataTableRowCell
											cellHead="table.desc"
											cellClassName={cellClassName(
												'description'
											)}
											cellText={
												group.description ||
												'---'
											}
										/>
										<DataTableRowCell
											cellHead="table.created-at"
											cellClassName={cellClassName(
												'created-at'
											)}
											cellText={formatLocaleDateTime(
												group.createdAt,
												false
											)}
										/>
										<DataTableRowCell
											boxClassName="d-flex align-items-center flex-wrap gap-2"
											cellClassName={cellClassName(
												'status'
											)}
											cellHead="table.status">
											<Badge
												color={
													group.isActive
														? Colors.green
														: Colors.muted
												}>
												{group.isActive
													? t('badge.active')
													: t('badge.inactive')}
											</Badge>
										</DataTableRowCell>
										<DataTableRowCell
											cellHead="table.operation"
											cellClassName={cellClassName(
												'operation'
											)}>
											<DataTableOperation
												onEditRow={() =>
													handleSelectRow(group.id)
												}
												onDeleteRow={() =>
													onRemoveContactGroup(group)
												}
											/>
										</DataTableRowCell>
									</DataTableRow>
								);
							})}
						</motion.div>
					}
					{isFetching && (
						<DataTableLoading
							widths={[25, 35, 15, 15, 10]}
							count={state.pageSize}
						/>
					)}
				</DataTableBody>
				{isError && <SystemErrorAlert />}
				{!isFetching && data?.totalItems === 0 && (
					<NoData message="title.no-contact-group" />
				)}
			</DataTable>
			{!isFetching && <EndOfList data={data} />}
			{!isFetching && data && data?.totalPages > 1 && (
				<Pagination
					className="mt-3"
					totalItemsCount={data.totalItems}
					activePage={parseInt(state.currentPage.toString(), 10)}
					itemsCountPerPage={state.pageSize}
					onChange={handlePageChange}
				/>
			)}
			{shouldRenderModalDelete && (
				<ModalBoxDelete
					isOpen={openModalDelete}
					onClose={toggleModalDelete}
					onRemove={handleRemoveContactGroup}
					isRemoving={state?.isRemoving}
					title={state?.selectedContactGroup?.title ?? ''}
				/>
			)}
		</ListingPage>
	);
});

ListContactGroup.displayName = 'ListContactGroup';
