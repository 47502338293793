import { Address, Sector, SelectableAddress, Status } from 'Types';

export enum ApplicantApproveStatus {
	Pending = 0,
	Approve = 1,
	Reject = 2,
	CanceledByApplicant = 3
}

export interface Applicant {
	id: string;
	dateOfBirth: string;
	gender: number;
	tel: string;
	description: null;
	image: string;
	imageThumbnail: string;
	firstName: string;
	lastName: string;
	email: string;
	address: Address;
	applicantFiles: ApplicantFile[];
}

export interface ApplicantDemand {
	id: string;
	requestDate: string;
	approveStatus: ApplicantApproveStatus;
	requestStatus: Status;
	jobId: string;
	code: string;
	shortDescription: string;
	applicantComment: string;
	title: string;
	slugUrl: string;
	applicant: Applicant;
	sectorId: string;
	sector: Sector;
}

export interface ApplicantFile {
	id: string;
	url: string;
	title: string;
	size: string;
}

export interface ApplicantState {
	firstName: string;
	lastName: string;
	email: string;
	postalCode: string;
	userRegisterId: string;
	address: SelectableAddress;
	selectedSectors: string[];
	selectedJobTypes: string[];
	selectedActivities: string[];
}
