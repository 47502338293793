import { FC, memo } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import { getCurrentLocale } from 'Utils';
import 'react-datepicker/dist/react-datepicker.css';
import de from 'date-fns/locale/de';
registerLocale('de', de);

interface Props {
	dateFormat: string;
	[x: string]: any;
}

export const DatePicker: FC<Props> = memo(({ dateFormat, ...props }) => {
	const currentLanguage = getCurrentLocale()?.code;

	return (
		<ReactDatePicker
			{...props}
			name={props.name}
			className={props.className}
			locale={currentLanguage}
			dateFormat={dateFormat}
			selected={props.value}
			onChange={props.onChange}
		/>
	);
});

DatePicker.displayName = 'DatePicker';
