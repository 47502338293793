import { memo, FC } from 'react';
import * as yup from 'yup';
import axios from 'Adapter';
import { FormGroup, Label } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { api, toastify } from 'Utils';
import { useEventAllInvitations } from 'Hooks';
import { ModalBox, SubmitButton } from 'Elements';
import { EventInvitationForm, ModalProps } from 'Types';

interface Props extends ModalProps {
	isPartner?: boolean;
}

export const ModalBoxInviteGuest: FC<Props> = memo(
	({ isOpen, onClose, isPartner = true }) => {
		const { t } = useTranslation();
		const { event_id } = useParams();

		const schema = yup.object().shape({
			fullName: yup
				.string()
				.required(t('validation.company-person-name.required')),
			email: yup
				.string()
				.required(t('validation.email.required'))
				.email(t('validation.email.type'))
		});

		const { refetch } = useEventAllInvitations({
			eventId: event_id as string,
			isPartner
		});

		const {
			register,
			handleSubmit,
			formState: { isSubmitting, errors, isDirty }
		} = useForm<EventInvitationForm>({
			resolver: yupResolver(schema),
			defaultValues: {
				fullName: '',
				email: ''
			}
		});

		const inviteGuest = async (data: EventInvitationForm) => {
			await axios
				.post(api.inviteGuest(event_id as string), data)
				.then((res) => {
					if (res.status === 200) {
						onClose?.();
						refetch();
						toastify('toastify.invitation-sent', {
							type: 'success'
						});
					}
				});
		};

		const onSubmit: SubmitHandler<EventInvitationForm> = async (data) => {
			const api_data = {
				...data,
				isPartner
			};
			await inviteGuest(api_data);
		};

		return (
			<ModalBox
				isOpen={isOpen}
				onClose={onClose}
				title={t('title.invite-guest-by-email')}>
				<form
					onSubmit={(e) => {
						e.stopPropagation();
						return handleSubmit(onSubmit)(e);
					}}>
					<FormGroup>
						<Label htmlFor="fullName">
							{t('forms.company-person-name')}
						</Label>
						<input
							{...register('fullName')}
							type="text"
							id="fullName"
							aria-invalid={!!errors.fullName}
							className="inputbox w-100"
						/>
						{errors.fullName && (
							<div className="invalid-feedback d-block">
								{errors.fullName.message?.toString()}
							</div>
						)}
					</FormGroup>
					<FormGroup>
						<Label htmlFor="email">{t('forms.email')}</Label>
						<input
							{...register('email')}
							type="email"
							id="email"
							aria-invalid={!!errors.email}
							className="inputbox w-100"
						/>
						{errors.email && (
							<div className="invalid-feedback d-block">
								{errors.email.message?.toString()}
							</div>
						)}
					</FormGroup>
					<div className="d-flex align-items-center mb-2">
						<SubmitButton
							icon={false}
							className="ms-auto"
							isDisable={!isDirty}
							isSubmitting={isSubmitting}
							label="button.send-invitation"
							savingLabel="button.sending"
						/>
					</div>
				</form>
			</ModalBox>
		);
	}
);

ModalBoxInviteGuest.displayName = 'ModalBoxInviteGuest';
