import {
	Address,
	SocialNetwork,
	Status,
	Contact,
	MainRegion,
	CustomerDto,
	SelectOption,
	SelectOptionIcon
} from 'Types';

export const enum AgentApproveStatus {
	pending = 0,
	approved = 1,
	rejected = 2,
	canceled = 3,
	inactive = 4
}

export const enum AgentPartnerApprovalStatus {
	autoApproved = 1,
	approved = 2,
	pending = 3,
	rejected = 4,
	autoRejected = 5
}

export interface AgentProfile {
	id: string;
	tenantId: string;
	agentName: string;
	webSiteAddress: string;
	taxNumber: string;
	description: string;
	isActive: boolean;
	isGlobal: boolean;
	firstName: string;
	lastName: string;
	email: string;
	agentEmail: string;
	slugUrl: string;
	commissionGroupId: string;
	commissionGroup: AgentCommissionGroup;
	address: Address;
	status: Status;
	smallCompany: boolean;
	approveStatus: AgentApproveStatus;
	agentBankAccount: AgentBankAccount;
	agentSetting: AgentSetting;
	contact: Contact;
	socialNetwork: SocialNetwork[];
	mainRegion: MainRegion;
	alternativeRegionList: MainRegion[];
	isApprove: boolean;
	agentCommissionLevel: AgentCommissionLevel;
	companyApprovalStatus: boolean;
	communityPartnerClass: number;
	companySubscription: {
		agentAdditionalCharge: number;
		id: string;
		subTotal: number;
		taxAmount: number;
		taxPercent: number;
		total: number;
	};
}

export interface AgentCommissionLevel {
	id: string;
	prov: number;
	maxActiveMainAbo: number;
	minActiveMainAbo: number;
	title: string;
}

export interface AgentCommissionGroup {
	id: string;
	title: string;
	isDefault: boolean;
	label: string;
	isPremium: boolean;
	commissionLevels: AgentCommissionLevel[];
}

export interface AgentProfileData extends AgentProfile {
	agentTypeId?: SelectOption | undefined | null;
	parentAssociationId?: SelectOption | undefined | null;
}

export interface AgentProfileFormData extends AgentProfile {
	agentTypeId?: string;
	parentAssociationId?: string;
}

export interface AgentBankAccount {
	payout: boolean;
	ownerName: string;
	iban: string;
	bic: string;
	climateChange: boolean;
}

export interface AgentSetting {
	logo: string;
	thumbnailLogo: string;
	headerTitle: string;
	headlineTitle: string;
	slogan: string;
	description: string;
	jobHeadline: string;
	internshipHeadline: string;
	jobDescription: string;
	internshipDescription: string;
	jobHeadlineStatus: boolean;
	internshipHeadlineStatus: boolean;
	companyHeadline: string;
	companyOverviewDescription: string;
	companyOverviewSectionStatus: boolean;
	eventSectionTitle: string;
	eventSectionStatus: boolean;
	partnerHeadline: string;
	partnerHeadlineStatus: boolean;
	sloganBgColor: string;
	headerBgColor: string;
	headerImage: string;
	headerThumbnailImage: string;
	memberTitle: string;
	partnerDescription: string;
	companyOverviewImage: string;
	companyOverviewThumbnailImage: string;
	memberSectionStatus: boolean;
	departmentSectionStatus: boolean;
	departmentSectionTitle: string;
	departments: string;
	memberNumber: string;
	establishedDate: string;
	statisticsSectionStatus: boolean;
	jobListTitle: string;
	internshipListTitle: string;
	internshipOverviewHeadLine: string;
	jobListStatus: boolean;
	internshipListStatus: boolean;
	communityPartnerClass: number;
	jobFilterSetting: number;
	areaCommonHeadlineStatus: boolean;
	areaCommonHeadline: string;
	areaListStatus: boolean;
	areaOverviewHeadLine: string;
	areaText: string;
}

export interface AgentType {
	id: string;
	title: string;
	description: string;
}

export interface AgentParentAssociationList {
	id: string;
	agentName: string;
	slugUrl: string;
	webSiteAddress: string;
	taxId: string;
	description: string;
	firstName: string;
	lastName: string;
	email: string;
	agentEmail: string;
	isGlobal: boolean;
	isActive: boolean;
	address: Address;
	agentSetting: AgentSetting;
	contact: Contact;
	socialNetwork: SocialNetwork[];
	communityPartnerClass: number;
	mainRegion: MainRegion;
	companyApprovalStatus: boolean;
	agentPlanInfo: string;
	commissionGroup: string;
	alternativeRegionList: MainRegion[];
}

export interface AgentPartner {
	id: string;
	companyId: string;
	purchaseDate: string;
	agentApprovalStatus: AgentApprovalStatus;
	agentApprovedStatus: AgentPartnerApprovalStatus;
	company: CompanyDto;
	status: Status;
}

export interface AgentApprovalStatus {
	agentApprovedStatus: string;
	updatedAt: string;
	description: string;
	createdBy: null;
	createdByIdentify: string;
}

export interface CompanyDto extends CustomerDto {
	branch: string;
	slugUrl: string;
	isActive: boolean;
}

export interface Member {
	id: string;
	phoneNumber: string;
	description: string;
	isActive: boolean;
	fullName: string;
	email: string;
	agentId?: string;
	image: string;
	thumbnailUrl: string;
	position: string;
}

export interface Department {
	id: string;
	title: string;
	description: string;
	isActive: boolean;
	image: string;
	thumbnailImage: string;
	sortOrder: number;
}

export interface AgentPlan {
	coverPhotoUrl: string;
	videoUrl: string;
	createdAt: string;
	updatedAt: string;
	price: number;
	description: string;
	contactPerson: AgentPlanContactPerson;
	termsAndConditionLink: string;
	features: AgentPlanFeature[];
}

export interface AgentPlanForm extends Omit<AgentPlan, 'features'> {
	features: {
		title: string;
		description: string;
		icon: any;
		sortOrder: number;
	}[];
}

export interface AgentPlanContactPerson {
	fullName: string;
	description: string;
	avatarUrl: string;
	published: boolean;
}

export interface AgentPlanFeature {
	title: string;
	description: string;
	icon?: SelectOptionIcon;
	sortOrder: number;
}

export interface AgentPlanInfo {
	additionalCharge: number;
	description: string;
	updatedAt: string;
	updatedBy: string;
}
