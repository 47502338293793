import { memo, useReducer, useEffect, useMemo } from 'react';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { clx, formatLocalAddress } from 'Utils';
import { useModal, useUpdateAgent } from 'Hooks';
import { AgentSetting, Media } from 'Types';
import { ProfileHeroHeaderReducer, setHeroFields } from 'Reducers';
import {
	LoadingSpinner,
	Editor,
	ModalBoxChangeImage,
	EditButton,
	FontAwesome
} from 'Elements';
import styles from 'partials/homepage/section/profile-hero-header.module.scss';

export const AgentHeroHeader = memo(() => {
	const { t } = useTranslation();
	const [openModal, toggleModal] = useModal();
	const { updateAgentSetting, agent, setting, isLoading, isFetching } =
		useUpdateAgent();
	const sloganLimit = 90;
	const descriptionLimit = 480;
	const defaultLogo = '/assets/img/company-default.jpg';

	const initialState = useMemo(() => {
		return {
			headerTitle: `[${t('placeholder.company-name')}]`,
			description: `[${t('placeholder.company-overview-description')}]`,
			slogan: `[${t('placeholder.company-slogan')}]`
		};
	}, [t]);

	const [state, dispatch] = useReducer(
		ProfileHeroHeaderReducer,
		initialState
	);
	const address = agent?.address;
	const headerTitle = setting?.headerTitle || agent?.agentName;

	const handleUpdate: (
		field: Partial<AgentSetting>
	) => Promise<void> = async (field) => {
		dispatch(
			setHeroFields({
				...state,
				...field
			})
		);
		await updateAgentSetting(field);
	};

	const handleUploadImage = async (image_data: Media) => {
		if (image_data) {
			await updateAgentSetting({
				logo: image_data.url,
				thumbnailLogo: image_data.thumbnailUrl
			});
		}
	};

	const handleResetLogo = async () => {
		await updateAgentSetting({
			logo: '',
			thumbnailLogo: ''
		});
	};

	useEffect(() => {
		dispatch(
			setHeroFields(
				{
					headerTitle: setting?.headerTitle?.trim(),
					description: setting?.description?.trim(),
					slogan: setting?.slogan?.trim()
				},
				'agent'
			)
		);
	}, [
		setting?.headerTitle,
		setting?.description,
		setting?.slogan,
		dispatch,
		initialState
	]);

	return (
		<>
			{isLoading && (
				<div className="d-flex align-items-center justify-content-center">
					<LoadingSpinner />
				</div>
			)}
			{!isLoading && agent && (
				<div className="ps-lg-4">
					<Row className="g-0 mb-4 ps-xxl-4">
						<Col lg={3} xxl={2}>
							<div
								className={styles.headerLogo}
								role="button"
								onClick={toggleModal}>
								<img
									className={styles.logo}
									src={setting?.thumbnailLogo || defaultLogo}
									alt={headerTitle}
								/>
								<div className="edit-overlay">
									<EditButton className="edit-overlay-icon" />
								</div>
								{isFetching && (
									<span className={styles.overlay}>
										<LoadingSpinner
											size="xl"
											color="#fff"
										/>
									</span>
								)}
							</div>
						</Col>
						<Col lg={9} xxl={10} className="ps-xxl-4">
							<div className="d-flex flex-column gap-2 justify-content-end h-100 pt-lg-5">
								<h3
									className={clx(
										styles.title,
										'fw-700 mb-0 text-break mt-5 pt-xl-3'
									)}>
									<Editor
										type="text"
										value={
											state.headerTitle ||
											initialState.headerTitle
										}
										validation={(val) => val.length > 0}
										validationMessage={t(
											'validation.company-title.required'
										)}
										onSave={(val) =>
											handleUpdate({
												headerTitle: val
											})
										}
									/>
								</h3>
								<div className="d-flex text-gray-4 lh-md">
									<Editor
										type="text"
										value={
											state.slogan || initialState.slogan
										}
										validation={(val) =>
											val.length < sloganLimit
										}
										validationMessage={t('validation.max', {
											count: sloganLimit
										})}
										onSave={(val) =>
											handleUpdate({
												slogan: val
											})
										}
									/>
								</div>
								{address && (
									<div className="d-flex align-items-center gap-2 text-gray-3 fs-sm lh-base">
										<FontAwesome
											icon="location-dot"
											size="lg"
											className="flex-shrink-0"
										/>
										<Editor
											type="text"
											value={formatLocalAddress(
												address.street,
												address.houseNo,
												address.postalCode,
												address.city,
												address.state
											)}
											navigationUrl="/my-agent/address"
											onSave={() => false}
										/>
									</div>
								)}
							</div>
						</Col>
					</Row>
					<Row className="justify-content-end g-0 ps-xxl-4">
						<Col lg={9} xxl={10} className="ps-xxl-4">
							<Col xl={10}>
								<div className="fs-base text-gray-3 lh-md text-break">
									<Editor
										type="textarea"
										value={
											state.description ||
											initialState.description
										}
										validation={(val) =>
											val.length < descriptionLimit
										}
										validationMessage={t('validation.max', {
											count: descriptionLimit
										})}
										onSave={(val) =>
											handleUpdate({
												description: val
											})
										}
									/>
								</div>
							</Col>
						</Col>
					</Row>
				</div>
			)}
			<ModalBoxChangeImage
				name="logo"
				isOpen={openModal}
				title="title.change-logo"
				image={setting?.thumbnailLogo || ''}
				defaultImage="/assets/img/agent-default.jpg"
				onRemove={handleResetLogo}
				onClose={toggleModal}
				onImageChange={handleUploadImage}
			/>
		</>
	);
});

AgentHeroHeader.displayName = 'AgentHeroHeader';
