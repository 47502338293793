import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { ThemeContext as ThemeUpdateContext } from 'Elements';

export const useTheme = () => {
	const theme = useContext(ThemeContext);
	const setTheme = useContext(ThemeUpdateContext);
	if (!theme) {
		throw new Error('You have to add the Provider to make it work');
	}
	return { theme, setTheme };
};
