import { memo, FC } from 'react';
import { EventVisitorInvitedList, EventVisitorParticipantList } from 'Elements';
import { Col, Row } from 'reactstrap';

export const EventVisitors: FC = memo(() => {
	return (
		<Row className="mb-4">
			<Col md={6} lg={12} xl={6} className="mb-4 mb-md-0 mb-lg-4 mb-xl-0">
				<EventVisitorInvitedList />
			</Col>
			<Col md={6} lg={12} xl={6}>
				<EventVisitorParticipantList />
			</Col>
		</Row>
	);
});

EventVisitors.displayName = 'EventVisitors';
