import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { clx } from 'Utils';
import { FontAwesome } from 'Elements';

interface Props {
	title?: string;
	className?: string;
	size?: any;
	[x: string]: unknown;
}

export const LoadingSpinner: FC<Props> = memo(
	({ title, className, size = '2x', ...spinnerProps }): JSX.Element => {
		const { t } = useTranslation();
		return (
			<span
				className={clx(
					'd-flex align-items-center justify-content-center my-5',
					title && 'flex-column',
					className
				)}>
				<FontAwesome
					icon="spinner"
					{...spinnerProps}
					spin
					spinPulse
					size={size}
				/>
				{title && <span className="mt-3">{t(title)}</span>}
			</span>
		);
	}
);

LoadingSpinner.displayName = 'LoadingSpinner';
