import { memo, FC, CSSProperties } from 'react';
import { Col } from 'reactstrap';
import { clx } from 'Utils';
import { Card, LoadingMask } from 'Elements';
import styles from 'partials/card/user-profile-card.module.scss';

interface Props {
	count?: number | string;
}

export const UserProfileCardLoading: FC<Props> = memo(({ count = 4 }) => {
	const loading: JSX.Element[] = [];
	for (let i = +count; i > 0; i--) {
		loading.push(<Skeleton key={i} style={{ opacity: i / +count }} />);
	}

	return <>{loading}</>;
});

const Skeleton = memo(({ style }: { style: CSSProperties }) => {
	return (
		<Col sm={6} xl={4} xxl={3} className="mb-4" style={style}>
			<Card className={styles.card}>
				<div className={clx(styles.avatar, 'shadow-none')}>
					<div className={styles.shimmerBox}>
						<LoadingMask className={styles.shimmer} />
					</div>
				</div>
				<div className={clx(styles.body, 'align-items-center')}>
					<LoadingMask
						className={clx(
							styles.loading,
							styles.loadingMd,
							'mb-2'
						)}
					/>
					<LoadingMask
						className={clx(
							styles.loading,
							styles.loadingSm,
							'mb-2'
						)}
					/>
					<LoadingMask
						className={clx(
							styles.loading,
							styles.loadingSm,
							'mt-2'
						)}
					/>
					<div className="d-flex align-items-center justify-content-center mt-auto pt-4">
						<LoadingMask
							className={clx(styles.loading, styles.loadingSm)}
						/>
					</div>
				</div>
			</Card>
		</Col>
	);
});

Skeleton.displayName = 'Skeleton';
UserProfileCardLoading.displayName = 'UserProfileCardLoading';
