import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { clx } from 'Utils';
import { Button, FontAwesome } from 'Elements';
import { Colors, DataTableActionTypes } from 'Types';
import styles from 'partials/data-table/data-table.module.scss';

interface Props {
	type: DataTableActionTypes;
	onClick?: () => void;
}

export const DataTableAction: FC<Props> = memo(({ type, onClick }) => {
	const { t } = useTranslation();
	interface Icons {
		[x: string]: {
			icon: any;
			color: Colors;
		};
	}
	const icons: Icons = {
		edit: { icon: 'pen', color: Colors.primary },
		delete: { icon: 'trash-can', color: Colors.red },
		view: { icon: 'eye', color: Colors.primary }
	};

	const getTitle: () => string = () => {
		const titles = {
			edit: t('table.edit'),
			delete: t('table.delete'),
			view: t('table.view')
		};
		return titles[type];
	};

	return (
		<Button
			size="sm"
			color={Colors[`white-${icons[type].color}`]}
			title={getTitle()}
			className={clx('flex-shrink-0', styles.actions)}
			onClick={onClick}>
			<FontAwesome icon={icons[type].icon} size="lg" />
		</Button>
	);
});

DataTableAction.displayName = 'DataTableAction';
