import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { clx } from 'Utils';
import styles from 'partials/shared/tab.module.scss';

interface Props {
	to: string;
	title: string;
	isActive: boolean;
	mode?: 'row' | 'col';
	className?: string;
	onClick?: () => void;
}

export const TabNavigation: FC<Props> = memo(
	({ to, title, mode = 'row', isActive, className, onClick }) => {
		const tabClass = mode === 'row' ? styles.tab_link : styles.tab_col;
		const { t } = useTranslation();
		const navigate = useNavigate();

		const handleClick = () => {
			navigate(to);
			onClick?.();
		};

		return (
			<span
				role="button"
				className={clx(tabClass, className, isActive && styles.active)}
				onClick={handleClick}>
				{t(title) as string}
			</span>
		);
	}
);

TabNavigation.displayName = 'TabNavigation';
