import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { fetch } from 'Utils';
import { queryKey } from 'Constants';
import {
	AgentPaymentTransaction,
	AgentTransactionDetails,
	DataList,
	DataQueryParams,
	Transaction,
	TransactionDetails
} from 'Types';

const config = (options = {}) => {
	return {
		...options,
		keepPreviousData: false
	};
};

export const useAgentTransactions = <T = DataList<Transaction>>(
	{
		status = '',
		type = '',
		pageSize = 10,
		pageNumber = 1,
		keyword = ''
	}: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<Transaction>>
): UseQueryResult<DataList<Transaction>, unknown> => {
	return useQuery(
		{
			queryKey: [
				queryKey.transactions,
				{ status, type, pageSize, pageNumber, keyword }
			],
			queryFn: () =>
				fetch.agentTransactionList(
					status,
					type,
					pageSize,
					pageNumber,
					keyword
				),
			...config(options)
		}
	);
};

export const useCompanyTransactions = <T = DataList<Transaction>>(
	{
		status = '',
		type = '',
		pageSize = 10,
		pageNumber = 1,
		keyword = ''
	}: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<Transaction>>
): UseQueryResult<DataList<Transaction>, unknown> => {
	return useQuery(
		{
			queryKey: [
				queryKey.transactions,
				{ status, type, pageSize, pageNumber, keyword }
			],
			queryFn: () =>
				fetch.companyTransactionList(
					status,
					type,
					pageSize,
					pageNumber,
					keyword
				),
			...config(options)
		}
	);
};

export const useTransaction = <T = TransactionDetails>(
	transactionId: string,
	options?: UseQueryOptions<T, any, TransactionDetails>
): UseQueryResult<TransactionDetails, unknown> => {
	return useQuery(
		{
			queryKey: [queryKey.transaction, { transactionId }],
			queryFn: () => fetch.transactionDetails(transactionId),
			...config(options)
		}
	);
};

export const useAgentTransaction = <T = AgentTransactionDetails>(
	transactionId: string,
	options?: UseQueryOptions<T, any, AgentTransactionDetails>
): UseQueryResult<AgentTransactionDetails, unknown> => {
	return useQuery(
		{
			queryKey: [queryKey.transaction, { transactionId }],
			queryFn: () => fetch.agentTransactionDetails(transactionId),
			...config(options)
		}
	);
};

export const useAgentPaymentTransaction = <T = AgentPaymentTransaction[]>(
	transactionId: string,
	options?: UseQueryOptions<T, any, AgentPaymentTransaction[]>
): UseQueryResult<AgentPaymentTransaction[], unknown> => {
	return useQuery(
		{
			queryKey: [queryKey.paymentTransaction, { transactionId }],
			queryFn: () => fetch.agentPaymentTransactionDetails(transactionId),
			...config(options)
		}
	);
};
