import { memo, FC } from 'react';
import { Col, Row } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAxios } from 'Hooks';
import { api, config, currencyFormatter } from 'Utils';
import { Colors, PaymentMethod, SubscriptionSate } from 'Types';
import {
	Button,
	CreateSubscriptionVoucher,
	ShimmerImage,
	SubmitButton,
	FontAwesome,
	Badge
} from 'Elements';
import styles from 'partials/step/step-container.module.scss';
import agentStyles from 'partials/card/subscription-agent-card.module.scss';

interface Props {
	fields: SubscriptionSate;
	onPrev: () => void;
	goToStep: (stepId: string) => void;
}

export const CreateSubscriptionReview: FC<Props> = memo(
	({ fields, onPrev, goToStep }) => {
		const { t } = useTranslation();
		const navigate = useNavigate();
		const {
			sendRequest: sendSubscriptionRequest,
			isLoading: isSubmittingSubscription
		} = useAxios<string>();
		const {
			sendRequest: sendPaymentRequest,
			isLoading: isSubmittingPayment
		} = useAxios<string>();

		const selectedPlan = fields.selectedPlan;
		const selectedVoucher = fields.selectedVoucher;
		const selectedAgent = fields.selectedAgent;
		const setting = selectedAgent?.agentSetting;
		const navigationUrl = `${config.publicUrl}/agent/${selectedAgent?.agentId}/${selectedAgent?.slugUrl}`;

		const handlePaymentResponse = (data?: string) => {
			if (data) {
				window.location.replace(data);
			}
		};

		const createPayment = async (subscriptionId: string) => {
			await sendPaymentRequest(
				api.createPayment,
				{
					data: {
						token: 'token',
						companySubscriptionId: subscriptionId,
						paymentMethod: 10
						
					}
				},
				handlePaymentResponse
			);
		};

		const registerCompanySubscription = async (
			onFinal?: (data: string) => void
		) => {
			const api_data = {
				agentId: selectedAgent?.agentId ?? '',
				subscriptionPlanId: selectedPlan?.id ?? '',
				voucherCode: selectedVoucher?.voucherCode ?? '',
				paymentMethod: PaymentMethod.online.toString()
			};
			await sendSubscriptionRequest(
				api.createSubscription(
					api_data.agentId,
					api_data.subscriptionPlanId,
					api_data.voucherCode,
					api_data.paymentMethod
				),
				{ method: 'POST' },
				(data) => {
					if (data) {
						onFinal?.(data);
					}
				}
			);
		};

		const handleFreeCheckout = async () => {
			await registerCompanySubscription(async (subscriptionId) => {
				await createPayment(subscriptionId);
			});
		};

		const onRegister = async () => {
			if (selectedVoucher?.totalAmount === 0) {
				await handleFreeCheckout();
			} else {
				void navigate('/checkout', {
					state: {
						prevPath: '/subscription'
					}
				});
			}
		};

		return (
			<div className={styles.form}>
				<div className="mb-4">
					<h6 className="fs-sm fw-400 text-gray-3 mb-3">
						{t('title.selected-agent')}
					</h6>
					<div className="d-flex align-items-center  justify-content-between mb-3">
						<div className="d-flex align-items-center gap-3">
							<a
								target="_blank"
								rel="noopener noreferrer"
								href={navigationUrl}
								className={agentStyles.logoWrapper}>
								<ShimmerImage
									width={45}
									height={45}
									src={
										setting?.thumbnailLogo ||
										'/assets/img/company-default.jpg'
									}
									alt={selectedAgent?.name ?? ''}
									defaultImage="/assets/img/company-default.jpg"
									className={agentStyles.logo}
								/>
							</a>
							<div className="flex-grow-1 d-flex flex-column justify-content-center">
								<h4 className="fs-base lh-base mb-0">
									<a
										target="_blank"
										rel="noopener noreferrer"
										className="d-inline-flex"
										href={navigationUrl}>
										{selectedAgent?.name}
									</a>
								</h4>
								{setting?.slogan && (
									<span className="text-gray-3 fs-small lh-md">
										{setting?.slogan}
									</span>
								)}
							</div>
						</div>
						<div>
							{!selectedAgent?.companyApprovalStatus && (
								<Badge color={Colors['warning']}>
									<FontAwesome
										icon={'info-circle'}
										size="1x"
									/>
									<span className="ms-1">
										{t('badge.no-approval')}
									</span>
								</Badge>
							)}
						</div>
					</div>
					<div className="mb-3">
						{setting?.description && (
							<div
								className="lh-md"
								dangerouslySetInnerHTML={{
									__html: setting?.description
								}}
							/>
						)}
					</div>
				</div>
				<div className="my-4 border-bottom position-relative">
					<Button
						color={Colors['white-gray']}
						className={styles.edit}
						onClick={() => goToStep('step1')}>
						<FontAwesome icon="pen" />
					</Button>
				</div>
				<div className="mb-3">
					<h6 className="fs-sm fw-400 text-gray-3 mb-3">
						{t('title.selected-plan')}
					</h6>
					<Row>
						<Col xl={6} xxl={8}>
							<h4 className="h4">{selectedPlan?.title}</h4>
							<div className="d-flex align-items-center my-3">
								<h6 className="h6 mb-0 pe-2 me-2 border-end border-2 text-gray-3 fw-500">
									{currencyFormatter(
										selectedPlan?.originalPrice ?? 0
									)}
								</h6>
								<span className="text-gray-3">
									{t('title.per-n-days', {
										duration: selectedPlan?.duration
									})}
								</span>
							</div>
							<div
								className="pt-2 lh-base"
								dangerouslySetInnerHTML={{
									__html: selectedPlan?.shortDescription ?? ''
								}}
							/>
							<div
								className="lh-base"
								dangerouslySetInnerHTML={{
									__html: selectedPlan?.description ?? ''
								}}
							/>
						</Col>
						<Col xl={6} xxl={4} className="mt-2 mt-xl-0">
							<div className="text-center d-flex flex-xl-column align-items-center gap-3 flex-wrap">
								<div className="d-flex justify-content-center">
									<div className="d-flex flex-column text-end pe-3 me-3 border-end border-2 text-primary">
										<h4 className="h4 mb-0">
											{currencyFormatter(
												selectedPlan?.price ?? 0
											)}
										</h4>
										<span className="fw-300">
											{t('title.excluded-tax')}
										</span>
									</div>
									<div className="text-gray-3 text-start d-flex flex-column">
										<span className="fw-300 lh-base">
											{t('title.per')}
										</span>
										<span className="mt-auto fs-base">
											{`${
												selectedPlan?.remainDuration
											} ${t('title.days')}`}
										</span>
									</div>
								</div>
							</div>
						</Col>
					</Row>
				</div>
				<div className="my-4 position-relative">
					<Button
						color={Colors['white-gray']}
						className={styles.edit}
						onClick={() => goToStep('step2')}>
						<FontAwesome icon="pen" />
					</Button>
				</div>
				<CreateSubscriptionVoucher />
				<div className="d-flex align-items-center justify-content-end pt-4 gap-3">
					{onPrev && (
						<Button
							color={Colors['white-primary']}
							className="gap-2 me-auto"
							onClick={onPrev}>
							<FontAwesome
								icon="chevron-left"
								size="1x"
								swapOpacity
							/>
							{t('wizard.prev')}
						</Button>
					)}
					<SubmitButton
						icon={false}
						color={Colors.secondary}
						label={
							selectedVoucher?.totalAmount !== 0
								? t('wizard.proceed-to-payment')
								: t('button.free-checkout')
						}
						savingLabel=""
						isSubmitting={
							isSubmittingSubscription || isSubmittingPayment
						}
						onClick={onRegister}
					/>
				</div>
			</div>
		);
	}
);

CreateSubscriptionReview.displayName = 'CreateSubscriptionReview';
