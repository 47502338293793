import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { AgentApproveStatus, AgentProfileFormData, ColorValues, Colors } from 'Types';
import { Badge, FontAwesome } from 'Elements';
import { agentStatus, getFilterByValue } from 'Utils';

interface Props {
	profile: AgentProfileFormData;
}

export const ProfileDecorationAgentBadge: FC<Props> = memo(({ profile }) => {
	const { t } = useTranslation();
	const isPremium = profile?.commissionGroup.isPremium;
	return (
		<>
			{profile?.approveStatus !== AgentApproveStatus.approved && (
				<Badge
					color={
						profile
							? getFilterByValue(
									agentStatus,
									profile.approveStatus
							  )?.color ?? Colors.primary
							: Colors.primary
					}>
					{profile
						? t(
								getFilterByValue(
									agentStatus,
									profile.approveStatus
								)?.label ?? ''
						  )
						: '...'}
				</Badge>
			)}
			{profile?.approveStatus === AgentApproveStatus.approved && (
				<Badge color={profile.isActive ? Colors.green : Colors.yellow}>
					{profile.isActive
						? t('badge.published')
						: t('badge.not-published')}
				</Badge>
			)}
			{profile?.commissionGroup && (
				<Badge
					title={t('title.commission-group.key', {
						commissionGroupName: profile?.commissionGroup.label
					})}
					color={isPremium ? Colors.premium : Colors['tertiary-dark']}
					className="gap-2">
					<FontAwesome
						icon={isPremium ? 'medal' : 'trophy-star'}
						size="lg"
					/>
					{profile?.commissionGroup.label}
				</Badge>
			)}
			{profile?.communityPartnerClass === 3 && (
				<Badge
					title={t('title.association-type')}
					color={Colors.association}
					className="gap-2">
					{t('title.association-type')}
				</Badge>
			)}
		</>
	);
});

ProfileDecorationAgentBadge.displayName = 'ProfileDecorationAgentBadge';
