import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'Hooks';
import { setSelectedAsset, setSelectedTemplate } from 'Actions';
import { clx, getKeyFromValue } from 'Utils';
import { Card, ToolboxAssetCard } from 'Elements';
import { AssetToolType, MarketingAsset } from 'Types';
import styles from 'partials/marketing/toolbox.module.scss';

interface Props {
	className?: string;
}

export const ToolboxAssetSection: FC<Props> = memo(({ className }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { selectedTemplate } = useSelector((state) => state.marketing);
	const assetList: MarketingAsset[] = [
		{
			id: AssetToolType.poster,
			title: t('title.marketing.poster'),
			icon: getKeyFromValue(
				AssetToolType,
				AssetToolType.poster
			) as string,
			toolType: AssetToolType.poster
		},
		{
			id: AssetToolType.coaster,
			title: t('title.marketing.coaster'),
			icon: getKeyFromValue(
				AssetToolType,
				AssetToolType.coaster
			) as string,
			toolType: AssetToolType.coaster
		},
		{
			id: AssetToolType.socialMedia,
			title: t('title.marketing.social-media'),
			icon: getKeyFromValue(
				AssetToolType,
				AssetToolType.socialMedia
			) as string,
			toolType: AssetToolType.socialMedia
		},
		{
			id: AssetToolType.flyer,
			title: t('title.marketing.flyer'),
			icon: getKeyFromValue(AssetToolType, AssetToolType.flyer) as string,
			toolType: AssetToolType.flyer
		},
		// {
		// 	id: AssetToolType.sticker,
		// 	title: t('title.marketing.sticker'),
		// 	icon: getKeyFromValue(
		// 		AssetToolType,
		// 		AssetToolType.sticker
		// 	) as string,
		// 	toolType: AssetToolType.sticker
		// }
	];

	const handleSelectAsset = (asset: MarketingAsset) => {
		dispatch(setSelectedAsset(asset));
		if (selectedTemplate) dispatch(setSelectedTemplate(null));
	};

	return (
		<Card className={clx(className)}>
			<h6 className="fs-6 lh-base mb-3">{t('title.marketing.assets')}</h6>
			<div className={clx(styles.asset_list, 'd-flex gap-4')}>
				{assetList.map((asset) => (
					<ToolboxAssetCard
						key={asset.id}
						asset={asset}
						index={asset.id}
						icon={asset.icon}
						onSelect={() => handleSelectAsset(asset)}
					/>
				))}
			</div>
		</Card>
	);
});

ToolboxAssetSection.displayName = 'ToolboxAssetSection';
