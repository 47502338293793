import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { fetch } from 'Utils';
import { queryKey } from 'Constants';
import { ApplicantDemand, DataList, DataQueryParams } from 'Types';

const config = (options = {}) => {
	return {
		...{ keepPreviousData: false },
		...options
	};
};

export const useDemand = <T = ApplicantDemand>(
	demandId: string,
	options?: UseQueryOptions<T, any, ApplicantDemand>
): UseQueryResult<ApplicantDemand, unknown> => {
	return useQuery(
		{
			queryKey: [queryKey.demand, { demandId }],
			queryFn: () => fetch.demandDetails(demandId),
			...config(options)
		}
	);
};

export const useDemands = <T = DataList<ApplicantDemand>>(
	{ status = '', pageSize, pageNumber, keyword = '' }: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<ApplicantDemand>>
): UseQueryResult<DataList<ApplicantDemand>, unknown> => {
	return useQuery(
		{
			queryKey: [queryKey.demands, { status, pageSize, pageNumber, keyword }],
			queryFn: () => fetch.demandList(status, pageSize, pageNumber, keyword),
			...config(options)
		}
	);
};
