import { PayloadAction } from '@reduxjs/toolkit';
import { body } from 'Utils';

interface SidebarState {
	isOpen: boolean;
}

const reducers = {
	toggleSidebar(state: SidebarState, action: PayloadAction<boolean>) {
		const { payload } = action;
		state.isOpen = payload;
		body.classList.toggle('sidebar-open');
	}
};

export default reducers;
