import { memo, FC, ElementType } from 'react';
import { clx } from 'Utils';

interface Props {
	count?: number | string;
	skeleton: ElementType;
	wrapperTag?: keyof JSX.IntrinsicElements;
	wrapperClassName?: string;
}

export const SkeletonWrapper: FC<Props> = memo(
	({
		count = 4,
		skeleton: Skeleton,
		wrapperTag: Wrapper = 'div',
		wrapperClassName
	}) => {
		const skeleton = Array.from({ length: +count }, (_, index) => (
			<Skeleton key={index} style={{ opacity: (index + 1) / +count }} />
		)).reverse();

		return <Wrapper className={clx(wrapperClassName)}>{skeleton}</Wrapper>;
	}
);

SkeletonWrapper.displayName = 'SkeletonWrapper';
