import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Wizard, Steps, Step } from 'react-albus-react18';
import { clx } from 'Utils';
import { IStep } from 'Types';
import { useCompanyAgents, useSelector } from 'Hooks';
import {
	CreateSubscriptionAgent,
	CreateSubscriptionPlan,
	CreateSubscriptionReview,
	StepContainer
} from 'Elements';
import styles from 'partials/step/step-container.module.scss';

export const CreateSubscription = memo(() => {
	const { t } = useTranslation();
	const subscription = useSelector((state) => state.subscription);

	const { data } = useCompanyAgents();

	const steps: IStep[] = [
		{
			id: 'step1',
			title: t('wizard.step-subs-1')
		},
		{
			id: 'step2',
			title: t('wizard.step-subs-2')
		},
		{
			id: 'step3',
			title: t('wizard.step-preview')
		}
	];

	return (
		<Wizard
			render={({ step, ...props }) => (
				<div
					className={clx(
						styles.wizard,
						'd-flex flex-column flex-md-row'
					)}>
					<StepContainer
						steps={steps}
						currentIndex={props.steps.indexOf(step)}
					/>
					<Steps>
						<Step
							id="step1"
							render={({ next }) => (
								<CreateSubscriptionAgent
									agentList={
										data
											? [
													...data.agentAlternativeList,
													...data.agentMainList
											  ]
											: []
									}
									selectedAgent={subscription.selectedAgent}
									onNext={next}
								/>
							)}
						/>
						<Step
							id="step2"
							render={({ next, previous }) => (
								<CreateSubscriptionPlan
									fields={subscription.selectedPlan}
									onPrev={previous}
									onNext={next}
								/>
							)}
						/>
						<Step
							id="step3"
							render={({ previous, push }) => (
								<CreateSubscriptionReview
									fields={subscription}
									onPrev={previous}
									goToStep={push}
								/>
							)}
						/>
					</Steps>
				</div>
			)}
		/>
	);
});

CreateSubscription.displayName = 'CreateSubscription';
