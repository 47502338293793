import { memo, useCallback, useMemo, useState, useEffect } from 'react';
import * as yup from 'yup';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import type { SubmitHandler } from 'react-hook-form/dist/types';
import { getDiscountFormat } from 'Utils';
import { InvitationForm, SelectOption, MyVoucher } from 'Types';
import { useInvitation, usePrompt, useAvailableVouchers } from 'Hooks';
import {
	Card,
	LoadingContent,
	Registrant,
	SelectController,
	SubmitButtonGroup,
	SwitchController
} from 'Elements';

const CardBox = styled(Card)`
	min-height: 250px;
`;

export const UpdateInvitation = memo(() => {
	const { t } = useTranslation();
	const { invitation_id } = useParams();
	const [voucherCode, setVoucherCode] = useState(false);
	const {
		data: fields,
		isLoading,
		isFetching,
		updateInvitation
	} = useInvitation(invitation_id as string);
	const { data: voucherList, isLoading: isLoadingVouchers } =
		useAvailableVouchers({
			enabled: !isLoading && !!fields
		});

	const isEditable = fields?.usageCount === 0;

	const schema = yup.object().shape({
		email: yup
			.string()
			.email(t('validation.email.type'))
			.required(t('validation.email.required')),
		companyName: yup
			.string()
			.required(t('validation.company-name.required'))
	});

	const {
		reset,
		control,
		register,
		handleSubmit,
		formState: { errors, isDirty, isSubmitting }
	} = useForm<InvitationForm>({
		resolver: yupResolver(schema)
	});

	usePrompt(t('forms.leave-screen-massage'), isDirty);

	const optionsListObject = useCallback((key_obj: MyVoucher) => {
		return {
			value: key_obj?.['id'],
			label: `${key_obj.voucherCode} (${getDiscountFormat(
				key_obj.discount,
				key_obj.discountType
			)})`
		};
	}, []);

	const getDefaultVoucher = useCallback(
		(voucherId: string) => {
			const selected_voucher = voucherList?.items?.find(
				(voucher: MyVoucher) => {
					return voucher.id === voucherId;
				}
			);
			if (selected_voucher) {
				return optionsListObject(selected_voucher);
			}
		},
		[voucherList?.items, optionsListObject]
	);

	const getVoucherListOptions = useMemo(() => {
		const options: SelectOption[] = [];
		voucherList?.items?.forEach((voucher: MyVoucher) => {
			options.push(optionsListObject(voucher));
		});
		return options;
	}, [voucherList?.items, optionsListObject]);

	const onSetVoucher = (val: boolean) => {
		setVoucherCode(val);
	};

	const onSubmit: SubmitHandler<InvitationForm> = async (data) => {
		const api_data = {
			...data,
			voucherId: data.voucherId?.value?.toString() ?? ''
		};
		await updateInvitation(api_data);
	};

	useEffect(() => {
		reset({
			...fields,
			voucherId: getDefaultVoucher(fields?.voucherIdentifier ?? '')
		});
		setVoucherCode(!!fields?.voucherCode);
	}, [reset, fields, getDefaultVoucher]);

	return (
		<CardBox>
			{!isLoading && isEditable && (
				<form onSubmit={handleSubmit(onSubmit)}>
					<Row>
						<Col md={8} xxl={6}>
							<FormGroup>
								<Label htmlFor="receiverName">
									{t('forms.receiver-name')}
								</Label>
								<input
									{...register('receiverName')}
									type="text"
									id="receiverName"
									className="inputbox w-100"
								/>
							</FormGroup>
							<FormGroup>
								<Label htmlFor="companyName">
									{t('forms.company-name')}
									<small className="ms-1">
										({t('validation.required')})
									</small>
								</Label>
								<input
									{...register('companyName')}
									type="text"
									id="companyName"
									aria-invalid={!!errors.companyName}
									className="inputbox w-100"
								/>
								{errors.companyName && (
									<div className="invalid-feedback d-block">
										{errors.companyName.message}
									</div>
								)}
							</FormGroup>
							<FormGroup>
								<Label htmlFor="email">
									{t('forms.email')}
									<small className="ms-1">
										({t('validation.required')})
									</small>
								</Label>
								<input
									{...register('email')}
									type="email"
									id="email"
									aria-invalid={!!errors.email}
									className="inputbox w-100"
								/>
								{errors.email && (
									<div className="invalid-feedback d-block">
										{errors.email.message}
									</div>
								)}
							</FormGroup>
							<FormGroup>
								<SwitchController
									name="voucherId"
									control={control}
									defaultChecked={voucherCode}
									checked={voucherCode}
									label="forms.voucher-code"
									boxClassName="py-2"
									onSwitchChange={onSetVoucher}
								/>
							</FormGroup>
							{voucherCode && (
								<FormGroup>
									<Label htmlFor="voucherId">
										{t('forms.voucher-code')}
									</Label>
									<SelectController
										name="voucherId"
										isDisabled={isLoadingVouchers}
										control={control}
										placeholder={
											isLoadingVouchers
												? 'placeholder.loading'
												: 'placeholder.select-voucher'
										}
										options={getVoucherListOptions}
									/>
								</FormGroup>
							)}
							<SubmitButtonGroup
								isSubmitting={isSubmitting}
								icon="paper-plane"
								label={t('button.resend-invitation')}
								savingLabel={t('button.sending')}
							/>
						</Col>
					</Row>
				</form>
			)}
			{!isLoading && !isEditable && fields?.registrants && (
				<Registrant registrants={fields?.registrants} />
			)}
			{isFetching && <LoadingContent />}
		</CardBox>
	);
});

UpdateInvitation.displayName = 'UpdateInvitation';
