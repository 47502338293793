import { memo, FC } from 'react';
import { clx } from 'Utils';
import { useSelector } from 'Hooks';
import { ImageElement } from 'Types';
import styles from 'partials/marketing/toolbox-config.module.scss';

interface Props {
	card: ImageElement;
	onClick?: (card: ImageElement) => void;
}

export const ToolboxImageCard: FC<Props> = memo(({ card, onClick }) => {
	const { assetConfig } = useSelector((state) => state.marketing);
	const handleClick = () => {
		onClick?.(card);
	};

	return (
		<div
			onClick={handleClick}
			className={clx(
				styles.imageWrapper,
				card.thumbnail === assetConfig.imageElement?.thumbnail &&
					styles.selectedImage
			)}>
			<img src={card.thumbnail} alt="" className={styles.image} />
		</div>
	);
});

ToolboxImageCard.displayName = 'ToolboxImageCard';
