import { FC, memo } from 'react';
import { useSwiper } from 'swiper/react';

interface Props {
	type: string;
	className?: string;
	[x: string]: any;
}

export const SwiperButton: FC<Props> = memo(
	({ children, className, type, ...buttonProps }) => {
		const swiper = useSwiper();

		const handleClick = () => {
			if (type === 'next') {
				swiper.slideNext();
			} else {
				swiper.slidePrev();
			}
		};

		return (
			<button
				type="button"
				className={className}
				onClick={handleClick}
				{...buttonProps}>
				{children}
			</button>
		);
	}
);

SwiperButton.displayName = 'SwiperButton';
