import { memo, FC } from 'react';
import { clx } from 'Utils';
import { EventDetails } from 'Types';
import styles from 'partials/event/event-info-section.module.scss';

interface Props {
	event: EventDetails;
}

export const EventInfoSection: FC<Props> = memo(({ event }) => {
	const defaultImage = '/assets/img/default-event.webp';
	const image = event?.featuredPhoto || defaultImage;

	return (
		<div className="order-1 order-md-0 d-flex flex-column">
			<div className={clx(styles.imageWrapper)}>
				<img
					src={image || defaultImage}
					className={styles.image}
					alt={event.title}
				/>
			</div>
			<h1 className="fs-4 lh-sm mb-0">{event.title}</h1>
			<p className={styles.content}>
				{event.shortDescription || '-------'}
			</p>
		</div>
	);
});

EventInfoSection.displayName = 'EventInfoSection';
