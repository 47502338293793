import { useMemo } from 'react';
import ReactQuill, { ReactQuillProps } from 'react-quill';
import {
	Controller,
	ControllerFieldState,
	FieldValues,
	UseControllerProps
} from 'react-hook-form';
import 'react-quill/dist/quill.snow.css';

type Props<T extends FieldValues> = {
	className?: string;
	hasImage?: boolean;
	onEditorChange?: (content: string) => void;
} & UseControllerProps<T> &
	Partial<ControllerFieldState> &
	ReactQuillProps;

export const RichTextController = <T extends FieldValues>({
	control,
	name,
	error,
	rules,
	defaultValue,
	hasImage = true,
	onEditorChange,
	...editorProps
}: Props<T>): JSX.Element => {
	const quillFormats = [
		'bold',
		'italic',
		'underline',
		'list',
		'bullet',
		'indent',
		'link',
		'image'
	];

	const quillModules = useMemo(() => {
		const toolbar = [
			['bold', 'italic', 'underline'],
			[
				{ list: 'ordered' },
				{ list: 'bullet' },
				{ indent: '-1' },
				{ indent: '+1' }
			],
			['link', ...(hasImage ? ['image'] : [])],
			['clean']
		];

		return { toolbar };
	}, [hasImage]);

	return (
		<div className="position-relative">
			<Controller
				control={control}
				name={name}
				rules={rules}
				render={({ field: { onChange, value = defaultValue } }) => (
					<ReactQuill
						{...editorProps}
						theme="snow"
						value={value ?? ''}
						defaultValue={defaultValue ?? ''}
						modules={quillModules}
						formats={quillFormats}
						onChange={(content) => {
							onChange(content);
							onEditorChange?.(content);
						}}
					/>
				)}
			/>
			{error && (
				<div className="invalid-feedback d-block">
					{error.message?.toString()}
				</div>
			)}
			{editorProps.readOnly && <div className="overlay-content" />}
		</div>
	);
};
