import { memo, FC, useReducer, useEffect, useMemo } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Box } from 'Styles';
import { useModal, useTheme } from 'Hooks';
import { formatLocalAddress } from 'Utils';
import { ProfileContactReducer, setContactFields } from 'Reducers';
import {
	Contact,
	Media,
	SocialFields,
	SocialNetwork,
	Address,
	ContactForm
} from 'Types';
import {
	EditButton,
	Editor,
	FontAwesome,
	IconGrid,
	LoadingSpinner,
	ModalBoxChangeImage,
	ShimmerImage,
	SocialList
} from 'Elements';
import styles from 'partials/homepage/section/profile-contact-section.module.scss';

interface Props {
	isLoading: boolean;
	contact?: Contact;
	social: SocialNetwork[];
	address?: Address;
	type: 'agent' | 'company';
	onUpdate: (data: ContactForm, onFinal?: () => void) => Promise<void>;
}

export const ProfileContactSection: FC<Props> = memo(
	({ contact, social, address, isLoading, type, onUpdate }) => {
		const { t } = useTranslation();
		const { theme } = useTheme();
		const [openModal, toggleModal] = useModal();
		const defaultImage = '/assets/img/profiles/img-user-default.png';
		const initialState = useMemo(() => {
			return {
				fullName: `[${t('placeholder.contact-full-name')}]`,
				tel: `[${t('placeholder.contact-tel')}]`,
				email: `[${t('placeholder.contact-email')}]`,
				position: `[${t('placeholder.contact-position')}]`,
				contactDescription: `[${t('placeholder.contact-description')}]`,
				contactImage: '',
				contactThumbnailImage: ''
			};
		}, [t]);

		const [state, dispatch] = useReducer(
			ProfileContactReducer,
			initialState
		);

		const handleUpdate: (
			contact_fields?: Partial<Contact>,
			social_fields?: SocialFields[]
		) => Promise<void> = async (contact_fields, social_fields = []) => {
			const socialFields =
				social_fields?.map((elem) => {
					return {
						name: elem?.name?.label,
						url: elem.url,
						type: elem?.name?.value
					};
				}) || [];
			const api_data = {
				contact: {
					...state,
					...contact_fields,
					lastName: '',
					firstName: ''
				},
				socialNetwork: [...(social || []), ...socialFields]
			};
			dispatch(
				setContactFields({
					...state,
					...contact_fields
				})
			);
			await onUpdate(api_data);
		};

		const handleRemoveSocial = async (selected_social: SocialNetwork) => {
			let filtered_list: SocialNetwork[] = social;
			if (filtered_list) {
				filtered_list = filtered_list?.filter(
					(elem) => elem.url !== selected_social.url
				);
				const api_data = {
					contact: {
						...state,
						lastName: '',
						firstName: ''
					},
					socialNetwork: filtered_list
				};
				await onUpdate(api_data);
			}
		};

		const handleRemoveImage = async () => {
			await handleUpdate({
				contactImage: '',
				contactThumbnailImage: ''
			});
		};

		const handleUploadImage = async (image_data: Media) => {
			if (image_data) {
				await handleUpdate({
					contactImage: image_data.url,
					contactThumbnailImage: image_data.thumbnailUrl
				});
			}
		};

		useEffect(() => {
			dispatch(
				setContactFields({
					fullName:
						contact?.fullName?.trim() || '',
					contactDescription:
						contact?.contactDescription?.trim() ||
						'',
					tel: contact?.tel?.trim() || '',
					email: contact?.email?.trim() || '',
					position:
						contact?.position?.trim() || '',
					contactImage: contact?.contactImage || defaultImage,
					contactThumbnailImage:
						contact?.contactThumbnailImage || defaultImage
				})
			);
		}, [dispatch, contact]);

		return (
			<section className="position-relative mb-5 pb-5">
				{isLoading && (
					<div className="d-flex align-items-center justify-content-center">
						<LoadingSpinner />
					</div>
				)}
				{!isLoading && contact && (
					<Container>
						<Row className="justify-content-center">
							<Col lg={8} xl={6}>
								<div className="d-flex flex-column gap-4">
									<div className="d-flex flex-column flex-md-row align-items-center justify-content-center gap-4">
										<Box
											$hasShadow={false}
											$bgColor={theme[type].primaryColor}
											className={styles.imageWrapper}
											onClick={toggleModal}>
											<ShimmerImage
												src={
													contact.contactImage ||
													defaultImage
												}
												defaultImage={defaultImage}
												alt={contact.fullName}
												width={160}
												height={160}
												className={styles.image}
											/>
											<div className="edit-overlay">
												<EditButton className="edit-overlay-icon" />
											</div>
										</Box>
										<div className="d-flex flex-column gap-2">
											<div className="d-flex flex-column gap-1">
												<h4 className="h4 lh-base mb-0">
													<Editor
														type="text"
														value={state.fullName || initialState.fullName}
														validation={(val) =>
															val.length > 0
														}
														validationMessage={t(
															'validation.required'
														)}
														onSave={(val) =>
															handleUpdate({
																fullName: val
															})
														}
													/>
												</h4>
												<div className="fs-base lh-base text-break">
													<Editor
														type="text"
														value={state.position || initialState.position}
														onSave={(val) =>
															handleUpdate({
																position: val
															})
														}
													/>
												</div>
											</div>
											<hr className="bg-gray-3 my-0" />
											<div className="d-flex align-items-center gap-3 lh-lg">
												<FontAwesome
													icon="envelope"
													size="lg"
													fixedWidth
												/>
												<Editor
													type="text"
													className="text-break text-gray-3"
													wrapperClassName="flex-grow-1"
													value={state.email}
													onSave={(val) =>
														handleUpdate({
															email: val
														})
													}
												/>
											</div>
											<div className="d-flex align-items-center gap-3 lh-lg">
												<FontAwesome
													icon="phone"
													size="lg"
													fixedWidth
												/>
												<Editor
													type="text"
													className="text-gray-3"
													wrapperClassName="flex-grow-1"
													value={state.tel || initialState.tel}
													onSave={(val) =>
														handleUpdate({
															tel: val
														})
													}
												/>
											</div>
											{address && (
												<div className="d-flex align-items-center gap-3 lh-lg">
													<FontAwesome
														icon="location-dot"
														size="lg"
														swapOpacity
														fixedWidth
													/>
													<Editor
														type="text"
														className="text-gray-3"
														wrapperClassName="flex-grow-1"
														value={formatLocalAddress(
															address.street,
															address.houseNo,
															address.postalCode,
															address.city,
															address.state
														)}
														navigationUrl={`/my-${type}/address`}
														onSave={() => false}
													/>
												</div>
											)}
										</div>
									</div>
									<div className="d-flex align-items-center gap-3">
										<h4 className="h3 mb-0 flex-shrink-0">
											{t('title.contact')}
										</h4>
										<IconGrid
											className="flex-grow-1"
											fill={theme[type].primaryColor}
										/>
										<div className="d-inline-flex align-items-center ms-auto flex-shrink-0">
											<SocialList
												className={styles.social}
												list={social}
												onAddSocial={async (
													selected_social
												) => {
													await handleUpdate(
														state,
														selected_social
													);
												}}
												onRemoveSocial={
													handleRemoveSocial
												}
											/>
										</div>
									</div>
									<Editor
										type="textarea"
										className="text-gray-3 lh-md"
										value={state.contactDescription}
										onSave={(val) =>
											handleUpdate({
												contactDescription: val
											})
										}
									/>
								</div>
							</Col>
						</Row>
					</Container>
				)}
				<ModalBoxChangeImage
					name="contactImage"
					isOpen={openModal}
					title="title.change-logo"
					image={contact?.contactImage || defaultImage}
					onClose={toggleModal}
					onRemove={handleRemoveImage}
					onImageChange={handleUploadImage}
				/>
			</section>
		);
	}
);

ProfileContactSection.displayName = 'ProfileContactSection';
