import { memo, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Wizard, Steps, Step } from 'react-albus-react18';
import { clx } from 'Utils';
import { names } from 'Constants';
import {
	EventForm,
	EventFormGeneral,
	EventFormRegistrationType,
	IStep
} from 'Types';
import {
	StepContainer,
	CreateEventGeneral,
	CreateEventPreviewSubmit,
	FormSuccess,
	CreateEventRegistration
} from 'Elements';
import styles from 'partials/step/step-container.module.scss';

export const CreateEvent: FC = memo(() => {
	const { t } = useTranslation();
	const { events } = names;
	const [eventId, setEventId] = useState('');

	const steps: IStep[] = [
		{
			id: 'step1',
			title: t('wizard.event-step-1')
		},
		{
			id: 'step2',
			title: t('wizard.event-step-2')
		},
		{
			id: 'step3',
			title: t('wizard.step-preview')
		}
	];

	const defaultFields: EventForm = {
		step1: {
			title: '',
			shortDescription: '',
			description: '',
			startDate: '',
			endDate: '',
			eventType: 0,
			meetingLink: '',
			featuredPhoto: '',
			address: null,
			isInternal: false
		},
		step2: {
			hasPartner: false,
			partnerNeedInvitation: false,
			participationType: 0,
			registrationStartDate: '',
			registrationEndDate: '',
			hasParticipantLimit: 0,
			participantLimit: '-1',
			hasPartnerLimit: 0,
			partnerLimit: '-1',
			seatPerPartnerLimit: '-1',
			showPartnersDetail: true,
			partnerRegistrationStartDate: '',
			partnerRegistrationEndDate: ''
		}
	};
	const [fields, setFields] = useState(defaultFields);

	const handleNext = <T,>(
		goToNext: () => void,
		data: T,
		step_number: number
	) => {
		setFields((prev) => ({
			...prev,
			[`step${step_number.toString()}`]: data
		}));
		goToNext();
	};

	return (
		<Wizard
			render={({ step, ...props }) => (
				<div
					className={clx(
						styles.wizard,
						'd-flex flex-column flex-md-row'
					)}>
					<StepContainer
						steps={steps}
						currentIndex={props.steps.indexOf(step)}
					/>
					<Steps>
						<Step
							id="step1"
							render={({ next }) => (
								<CreateEventGeneral
									fields={fields.step1}
									onNext={(data) =>
										handleNext<EventFormGeneral>(
											next,
											data,
											1
										)
									}
								/>
							)}
						/>
						<Step
							id="step2"
							render={({ next, previous }) => (
								<CreateEventRegistration
									fields={fields.step2}
									endDate={fields.step1.endDate}
									onPrev={previous}
									onNext={(data) =>
										handleNext<EventFormRegistrationType>(
											next,
											data,
											2
										)
									}
								/>
							)}
						/>
						<Step
							id="step3"
							render={({ next, previous, push }) => (
								<CreateEventPreviewSubmit
									fields={fields}
									onPrev={previous}
									onNext={next}
									goToStep={push}
									setEventId={setEventId}
								/>
							)}
						/>
						<Step
							id="step4"
							render={() => (
								<div className={clx('d-flex', styles.form)}>
									<FormSuccess
										listTitle="button.check-details"
										successTitle="forms.event.created"
										className="flex-grow-1 align-self-center"
										listUrl={`${events.path}/${eventId}`}
									/>
								</div>
							)}
						/>
					</Steps>
				</div>
			)}
		/>
	);
});

CreateEvent.displayName = 'CreateEvent';
