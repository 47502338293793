import { FC, memo } from 'react';
import { clx } from 'Utils';
import { Colors } from 'Types';
import styles from 'partials/auto-suggest/search-autosuggest.module.scss';
import { FontAwesome } from 'Elements';

interface Props {
	title: string;
}

export const AddressSuggestion: FC<Props> = memo(({ title }) => {
	return (
		<div
			className={clx(styles.suggestionItem, 'd-flex align-items-center')}>
			<FontAwesome
				icon="location-dot"
				className="me-2"
				color={Colors.primary}
				size="1x"
			/>
			<span className="text-truncate" title={title}>
				{title}
			</span>
		</div>
	);
});

AddressSuggestion.displayName = 'AddressSuggestion';
