import { memo, useMemo, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import type { SubmitHandler } from 'react-hook-form/dist/types';
import { names } from 'Constants';
import { SelectOption, UserGroupForm } from 'Types';
import { useCreateGroup, useGroups, usePrompt, useRoles } from 'Hooks';
import {
	Card,
	FormSuccess,
	SelectController,
	SubmitButtonGroup
} from 'Elements';

export const CreateUserGroup = memo(() => {
	const { groups } = names;
	const { submitRequest } = useCreateGroup();
	const { refetch } = useGroups({});
	const { t } = useTranslation();
	const { data: rolesList, isLoading: isLoadingRoles } = useRoles({
		pageNumber: 1,
		pageSize: 500
	});
	const [formSuccess, setFormSuccess] = useState(false);

	const defaultValues: {
		name: string;
		roles: SelectOption[];
		description: string;
	} = {
		name: '',
		roles: [],
		description: ''
	};

	const schema = yup.object().shape({
		name: yup.string().required(t('validation.group-name.required')),
		roles: yup
			.array()
			.min(1, t('validation.select-role.required'))
			.nullable()
			.of(
				yup.object({
					label: yup.string(),
					value: yup.string()
				})
			)
			.required(t('validation.select-role.required'))
	});

	const {
		reset,
		control,
		register,
		handleSubmit,
		formState: { errors, isDirty, isSubmitting }
	} = useForm<UserGroupForm>({
		resolver: yupResolver(schema)
	});

	usePrompt(t('forms.leave-screen-massage'), isDirty);

	const resetForm = () => {
		reset(defaultValues);
	};

	const getRoleListOptions = useMemo(() => {
		const options: SelectOption[] = [];
		rolesList?.items?.forEach((role) => {
			options.push({ value: role.id, label: role.name });
		});
		return options;
	}, [rolesList?.items]);

	const onSubmit: SubmitHandler<UserGroupForm> = async (data) => {
		const api_data = {
			...data,
			roles: data.roles.map((role: SelectOption) => {
				return role.value as string;
			})
		};

		await submitRequest(api_data, () => {
			setFormSuccess(true);
			resetForm();
		});
	};

	return (
		<Card>
			{!formSuccess && (
				<form onSubmit={handleSubmit(onSubmit)}>
					<Row>
						<Col md={8} xxl={6}>
							<FormGroup>
								<Label htmlFor="name">
									{t('forms.name')}
									<small className="ms-1">
										({t('validation.required')})
									</small>
								</Label>
								<input
									{...register('name')}
									type="text"
									id="name"
									aria-invalid={!!errors.name}
									className="inputbox w-100"
								/>
								{errors.name && (
									<div className="invalid-feedback d-block">
										{errors.name.message}
									</div>
								)}
							</FormGroup>
							<FormGroup>
								<Label htmlFor="roles">
									{t('forms.roles')}
									<small className="ms-1">
										({t('validation.required')})
									</small>
								</Label>
								<SelectController
									isMulti
									name="roles"
									control={control}
									isDisabled={isLoadingRoles}
									options={getRoleListOptions}
									error={errors?.roles?.message?.toString()}
								/>
							</FormGroup>
							<FormGroup>
								<Label htmlFor="description">
									{t('forms.user.group.desc')}
								</Label>
								<textarea
									{...register('description')}
									id="description"
									className="inputbox w-100"
									cols={10}
									rows={5}
								/>
							</FormGroup>
							<SubmitButtonGroup
								isDisable={!isDirty}
								isSubmitting={isSubmitting}
							/>
						</Col>
					</Row>
				</form>
			)}
			{formSuccess && (
				<FormSuccess
					addButtonTitle="button.add-user-group"
					listTitle="button.user-group-list"
					successTitle="forms.user-group-created"
					className="flex-grow-1 align-self-center"
					listUrl={groups.path}
					fetcher={refetch}
					onClickAdd={() => {
						setFormSuccess(false);
						resetForm();
					}}
				/>
			)}
		</Card>
	);
});

CreateUserGroup.displayName = 'CreateUserGroup';
