import { memo, useState } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useSearchParams, useParams } from 'react-router-dom';
import { useContactPeopleTable, useContactPeople, useModal, useDelayUnmount } from 'Hooks';
import { clx, formatLocaleDateTime } from 'Utils';
import { ContactPeople, Colors } from 'Types';
import { containerVariants } from 'Constants';
import {
	DataTable,
	DataTableBody,
	DataTableHead,
	DataTableLoading,
	DataTableRow,
	DataTableRowCell,
	DataTableOperation,
	EndOfList,
	Button,
	ListingPage,
	NoData,
	Pagination,
	ModalBoxDelete,
	SystemErrorAlert,
	FontAwesome,
	ModalUpdateContactPeople,
	ModalCreateContactPeople,
	ModalUploaderFileContactPeople
} from 'Elements';

export const ListContactPeople = memo(() => {
	const { t } = useTranslation();
	const { contactGroup_id } = useParams();
	const [searchParams, setSearchParams] = useSearchParams();
	const params = Object.fromEntries([...searchParams]);
	const pageParam = searchParams.get('page') || 1;
	const queryParam = searchParams.get('q') || '';
	const { removeContactPeople } = useContactPeople();
	const [openModal, toggleModal] = useModal();
	const shouldRenderModal = useDelayUnmount(openModal, 350);
	const [openModalUploader, toggleModalUploader] = useModal();
	const shouldRenderModalUploader = useDelayUnmount(openModalUploader, 350);
	const [openModalUpdate, toggleModalUpdate] = useModal();
	const shouldRenderModalUpdate = useDelayUnmount(openModalUpdate, 350);
	const [openModalDelete, toggleModalDelete] = useModal();
	const shouldRenderModalDelete = useDelayUnmount(openModalDelete, 350);

	const [state, setState] = useState<{
		pageSize: number;
		currentPage: string | number;
		keyword: string;
		filterBy: string;
		isRemoving: boolean;
		selectedContactPeople: ContactPeople | null;
	}>({
		pageSize: 10,
		currentPage: pageParam,
		keyword: queryParam,
		filterBy: '',
		isRemoving: false,
		selectedContactPeople: null
	});

	const { data, isFetching, isError, refetch } = useContactPeopleTable({
		contactGroupId: contactGroup_id,
		pageSize: state.pageSize,
		pageNumber: state.currentPage,
		keyword: state.keyword
	});

	const [contactPeopleId, setContactPeopleId] = useState({});

	const onChangePageSize = (pageSize: number) => {
		setSearchParams({ ...params, page: '1' });
		setState((prev) => ({
			...prev,
			pageSize,
			currentPage: 1
		}));
	};

	const handleSearch = (query: string) => {
		setSearchParams({
			...params,
			page: '1',
			q: query
		});
		setState((prev) => ({
			...prev,
			currentPage: 1,
			keyword: query
		}));
	};

	const handlePageChange = (page: number) => {
		setSearchParams({ ...params, page: page.toString() });
		setState((prev) => ({
			...prev,
			currentPage: page
		}));
	};

	const handleSelectRow = (row_id: string) => {
		setContactPeopleId(row_id);
		toggleModalUpdate();
	};

	const onRemoveContactPeople = async (ContactPeople: ContactPeople) => {
		setState((prev) => ({
			...prev,
			selectedContactPeople: ContactPeople
		}));
		toggleModalDelete();
	};

	const handleRemoveContactPeople = async () => {
		setState((prev) => ({
			...prev,
			isRemoving: true
		}));
		await removeContactPeople(state?.selectedContactPeople?.id as string, refetch).then(() => {
			toggleModalDelete();
			setState((prev) => ({
				...prev,
				isRemoving: false
			}));
		});
	};


	const onSubmitModal = () => {
		refetch();
	}

	const cellClassName = (col: string) => {
		const cols: { [x: string]: string } = {
			"email-address": 'w-md-25',
			"full-name": 'w-md-15',
			description: 'w-md-35',
			"date-added": 'w-md-15',
			operation: 'w-md-10'
		};
		return clx('w-100', cols[col.toLowerCase()]);
	};

	return (
		<>
			<div className="position-relative d-flex align-items-sm-center flex-column flex-sm-row mb-3 mb-lg-4 pb-1 gap-3 gap-sm-2">
				<div className="flex-grow-1">
					<h2 className="fs-5 fw-500 mb-0 d-flex align-items-center">
						{t('pages.emailList')}
					</h2>
				</div>
				<div className="ms-sm-auto flex-shrink-0 d-flex gap-2">
					<Button
						className="gap-2"
						color={Colors['white-primary']}
						onClick={toggleModalUploader}>
						{t('dropdown.import-contacts')}
						<FontAwesome icon="upload" size="sm" swapOpacity />
					</Button>
					<Button onClick={toggleModal} className="flex-fill gap-2">
						<FontAwesome icon="plus" size="lg" swapOpacity />
						{t('button.add-user')}
					</Button>
				</div>
			</div>
			<ListingPage
				totalItems={data?.totalItems}
				pageSizes={[5, 10, 20]}
				selectedPageSize={state.pageSize}
				onPageSizeChange={onChangePageSize}
				onSearchChange={handleSearch}>
				<DataTable>
					{data && data?.totalItems > 0 && (
						<DataTableHead>
							<DataTableRowCell
								cellText="table.contact-people.emailAddress"
								cellClassName={cellClassName('email-address')}
							/>
							<DataTableRowCell
								cellText="table.contact-people.fullName"
								cellClassName={cellClassName('full-name')}
							/>
							<DataTableRowCell
								cellText="table.desc"
								cellClassName={cellClassName('description')}
							/>
							<DataTableRowCell
								cellText="table.contact-people.dateAdded"
								cellClassName={cellClassName('date-added')}
							/>
							<DataTableRowCell
								cellText="table.operation"
								cellClassName={cellClassName('operation')}
							/>
						</DataTableHead>
					)}
					<DataTableBody>
						{!isFetching &&
							<motion.div
								variants={containerVariants}
								initial="hidden"
								animate="show">
								{data?.items?.map((people) => {
									return (
										<DataTableRow key={people.id}>
											<DataTableRowCell
												cellClassName={cellClassName('email-address')}
												cellHead="table.contact-people.emailAddress"
												cellText={people.emailAddress} />
											<DataTableRowCell
												cellClassName={cellClassName('full-name')}
												cellHead="table.contact-people.fullName"
												cellText={people.fullName} />
											<DataTableRowCell
												cellHead="table.desc"
												cellClassName={cellClassName(
													'description'
												)}
												cellText={
													people.description ||
													'---'
												}
											/>
											<DataTableRowCell
												cellClassName={cellClassName('date-added')}
												cellHead="table.contact-people.dateAdded"
												cellText={formatLocaleDateTime(people.createdAt, false)}
											/>
											<DataTableRowCell
												cellHead="table.operation"
												cellClassName={cellClassName('operation')}>
												<DataTableOperation
													onEditRow={() =>
														handleSelectRow(people.id)
													}
													onDeleteRow={() =>
														onRemoveContactPeople(people)
													}
												/>
											</DataTableRowCell>
										</DataTableRow>
									);
								})}
							</motion.div>}
						{isFetching && (
							<DataTableLoading
								widths={[25, 15, 35, 15, 10]}
								count={state.pageSize}
							/>
						)}
					</DataTableBody>
					{isError && <SystemErrorAlert />}
					{!isFetching && data?.totalItems === 0 && (
						<NoData message="title.no-contact-people" />
					)}
				</DataTable>
				{!isFetching && <EndOfList data={data} />}
				{!isFetching && data && data?.totalPages > 1 && (
					<Pagination
						className="mt-3"
						totalItemsCount={data.totalItems}
						activePage={parseInt(state.currentPage.toString(), 10)}
						itemsCountPerPage={state.pageSize}
						onChange={handlePageChange}
					/>
				)}
			</ListingPage>
			{shouldRenderModal && (
				<ModalCreateContactPeople
					contactGroupId={contactGroup_id as string}
					isOpen={openModal}
					onSubmitModal={onSubmitModal}
					onClose={toggleModal}
				/>
			)}
			{shouldRenderModalUpdate && (
				<ModalUpdateContactPeople
					contactGroupId={contactGroup_id as string}
					contactPeopleId={contactPeopleId as string}
					onSubmitModal={onSubmitModal}
					isOpen={openModalUpdate}
					onClose={toggleModalUpdate}
				/>
			)}
			{shouldRenderModalUploader && (
				<ModalUploaderFileContactPeople
					contactGroupId={contactGroup_id as string}
					isOpen={openModalUploader}
					onSubmitModal={onSubmitModal}
					onClose={toggleModalUploader}
				/>
			)}
			{shouldRenderModalDelete && (
				<ModalBoxDelete
					isOpen={openModalDelete}
					onClose={toggleModalDelete}
					onRemove={handleRemoveContactPeople}
					isRemoving={state?.isRemoving}
					title={state?.selectedContactPeople?.emailAddress ?? ''}
				/>
			)}
		</>
	);
});

ListContactPeople.displayName = 'ListContactPeople';
