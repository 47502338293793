import { FC, memo, ReactNode } from 'react';
import { clx } from 'Utils';
import styles from 'partials/data-table/data-table.module.scss';

interface Props {
	children: ReactNode;
	className?: string;
}

export const DataTableHead: FC<Props> = memo(({ children, className }) => {
	const headerClass = clx(
		'd-none d-md-flex align-items-center flex-shrink-0 fw-500 fs-sm text-gray-3',
		styles.header,
		className
	);
	return <div className={headerClass}>{children}</div>;
});

DataTableHead.displayName = 'DataTableHead';
