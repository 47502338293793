import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { clx } from 'Utils';
import { Media } from 'Types';
import { FontAwesome, ShimmerImage } from 'Elements';
import styles from 'partials/control/cover-controller.module.scss';

interface Props {
	image: Partial<Media>;
	name?: string;
	message?: string;
	defaultImage?: string;
	onClick?: () => void;
}

export const CoverBox: FC<Props> = memo(
	({
		image,
		name,
		message,
		defaultImage = '/assets/img/default-image.jpg',
		onClick
	}) => {
		const { t } = useTranslation();
		const hasImage = !!image.thumbnailUrl || !!image.url;

		return (
			<div
				role="img"
				className={clx(
					'overflow-hidden',
					hasImage && styles.coverBox,
					!hasImage && styles.coverDefault
				)}
				onClick={onClick}>
				{hasImage && (
					<ShimmerImage
						src={image.url || defaultImage}
						defaultImage={defaultImage}
						width={700}
						height={500}
						alt={name ?? ''}
						className={styles.cover}
					/>
				)}
				{!hasImage && (
					<div className="d-flex flex-column gap-3 p-3">
						<FontAwesome icon="cloud-arrow-up" size="3x" />
						<div className="fs-small text-gray-3 text-center">
							{t(message || 'forms.image-accept-type')}
						</div>
					</div>
				)}
			</div>
		);
	}
);

CoverBox.displayName = 'CoverBox';
