import { memo, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Badge, Button } from 'Elements';
import {
	clx,
	currencyFormatter,
	formatLocaleDateTime,
	getKeyFromValue,
	companyPaymentStatus
} from 'Utils';
import {
	Colors,
	TransactionDetails,
	TransactionStatus
} from 'Types';
import styles from 'partials/card/subscription-card.module.scss';

interface Props {
	transaction: TransactionDetails;
	baseUrl?: string;
}

export const CompanyTransactionPayment: FC<Props> = memo(
	({ transaction, baseUrl = 'transactions' }) => {
		const { t } = useTranslation();
		const navigate = useNavigate();
		const status = transaction.status;
		const transactionStatus = getKeyFromValue(TransactionStatus, status);
		const row_class = 'justify-content-between';
		const badgeText = transactionStatus
			? t(`badge.${transactionStatus.toLowerCase()}`)
			: '';

		const getStatusData = () => {
			return companyPaymentStatus.find((elem) => elem.value === status);
		};

		return (
			<div>
				<h6 className="text-gray-3 mb-3 fs-6 fw-400 d-flex align-items-center justify-content-between">
					{t('title.payment.key')}
					<Badge color={getStatusData()?.color ?? Colors.muted}>
						{badgeText}
					</Badge>
				</h6>
				<div className={clx(styles.row, row_class)}>
					<span className={styles.rowLabel}>
						{t('label.transaction-number')}:
					</span>
					<span className="fs-6">
						{transaction.transactionCode ?? '---'}
					</span>
				</div>
				<div className={clx(styles.row, row_class)}>
					<span className={styles.rowLabel}>
						{t('label.payment-date')}:
					</span>
					<span>
						{transaction.createdAt
							? formatLocaleDateTime(transaction.createdAt)
							: '---'}
					</span>
				</div>
				<div className={clx(styles.row, row_class)}>
					<span className={clx(styles.rowLabel, 'fw-500')}>
						{t('label.amount')}
					</span>
					<div className="d-flex flex-column text-end">
						<span className="fs-6 fw-500">
							{currencyFormatter(transaction.totalAmount ?? 0)}
						</span>
						<span className="text-gray-3 lh-md fs-small">
							{`${t('label.included')} ${
								transaction.taxPercent ?? 0
							}% ${t('label.tax')}`}
						</span>
					</div>
				</div>
				<div className={clx(styles.row, row_class)}>
					<Button
						color={Colors['white-primary']}
						className="mt-2"
						label={t('button.invoice-details') as string}
						onClick={() =>
							navigate(
								`/${baseUrl}/${transaction.relatedTransaction}`
							)
						}
					/>
				</div>
			</div>
		);
	}
);

CompanyTransactionPayment.displayName = 'CompanyTransactionPayment';
