import { memo, FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Colors } from 'Types';
import { useGoBack } from 'Hooks';
import { Breadcrumb, Button, FontAwesome } from 'Elements';

interface SharedProps {
	heading: string;
	totalItems?: number;
	showPath?: boolean;
	children?: ReactNode;
}

type TBreadCrumb =
	| {
			path?: string;
			pathInfo?: string;
	  }
	| {
			pathInfo: string;
			path: string;
	  }
	| {
			path: string;
			pathInfo?: string;
	  };

type Props = SharedProps & TBreadCrumb;

export const PageHeading: FC<Props> = memo(
	({ path, children, heading, pathInfo, showPath = true }) => {
		const { t } = useTranslation();
		const { goBack, location } = useGoBack();

		return (
			<>
				{heading && (
					<div className="position-relative d-flex align-items-sm-center flex-column flex-sm-row mb-3 mb-lg-4 pb-1 gap-3 gap-sm-2">
						<div className="flex-grow-1">
							<h1 className="fs-5 fw-500 mb-0 d-flex align-items-center back-button">
								{location.state?.prevPath && (
									<Button
										size="sm"
										color={Colors.empty}
										className="me-2 px-1 py-0"
										onClick={goBack}>
										<FontAwesome
											icon="arrow-left"
											size="lg"
											className="back-icon"
										/>
									</Button>
								)}
								{t(heading)}
							</h1>
							{showPath && (
								<Breadcrumb path={path} pathInfo={pathInfo} />
							)}
						</div>
						<div className="ms-sm-auto flex-shrink-0 d-flex">
							{children}
						</div>
					</div>
				)}
			</>
		);
	}
);

PageHeading.displayName = 'PageHeading';
