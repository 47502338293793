import { memo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { ColorValues, Media, Tab } from 'Types';
import { useModal, useUpdateUser } from 'Hooks';
import {
	Card,
	LoadingContent,
	ModalBoxChangeImage,
	ShimmerImage,
	TabNavigation,
	FontAwesome
} from 'Elements';
import pageStyles from 'partials/shared/page.module.scss';
import styles from 'partials/decoration/user-decoration-box.module.scss';

export const UserDecoration = memo(() => {
	const [openModal, toggleModal] = useModal();
	const { pathname } = useLocation();
	const { user_id } = useParams<'user_id'>();
	const {
		data: user,
		isFetching,
		updateUserAvatar
	} = useUpdateUser(user_id as string);
	const name =
		user?.firstName || user?.lastName
			? `${user?.firstName} ${user?.lastName}`
			: '...';
	const defaultImage = '/assets/img/profiles/img-user-default.png';

	const defaultTab =
		pathname === `/users/${user_id}` ||
		pathname === `/users/${user_id}/general`;

	const tabs: Tab[] = [
		{
			id: 0,
			title: 'tab.general',
			path: 'general',
			isActive: defaultTab
		},
		{
			id: 1,
			title: 'tab.address',
			path: 'address',
			isActive: pathname === `/users/${user_id}/address`
		}
	];

	const handleResetImage = async () => {
		await updateUserAvatar({
			url: '',
			thumbnailUrl: '',
			type: ''
		});
	};

	const handleUploadImage = async (image_data: Media) => {
		await updateUserAvatar(image_data);
	};

	return (
		<Card tab>
			{isFetching && <LoadingContent />}
			<div className="d-flex align-items-center mb-3 position-relative">
				<div
					role="button"
					tabIndex={-1}
					className={styles.avatarBox}
					onClick={toggleModal}>
					<div className={styles.avatar}>
						<ShimmerImage
							width={120}
							height={120}
							src={user?.imageUrl ?? defaultImage}
							defaultImage={defaultImage}
							className={styles.shimmer}
							alt={name}
						/>
					</div>
					<span className={styles.edit}>
						<FontAwesome
							icon="pen"
							size="xl"
							color={ColorValues['gray-1']}
						/>
					</span>
				</div>
				<div className="flex-grow-1">
					<h6 className="fs-6 fw-400 lh-base mb-1">{name}</h6>
					<span className="text-gray-3 lh-base mb-2 d-flex">
						{user?.emailAddress ?? '...'}
					</span>
				</div>
			</div>
			<div className={pageStyles.tab}>
				{tabs?.map((tab) => {
					return (
						<TabNavigation
							key={tab.id}
							to={tab.path}
							title={tab.title}
							isActive={tab.isActive}
						/>
					);
				})}
			</div>
			<ModalBoxChangeImage
				name="imageUrl"
				isOpen={openModal}
				title="title.change-image"
				image={user?.imageUrl || ''}
				defaultImage={defaultImage}
				onRemove={handleResetImage}
				onClose={toggleModal}
				onImageChange={handleUploadImage}
			/>
		</Card>
	);
});

UserDecoration.displayName = 'UserDecoration';
