import { FC, memo } from 'react';
import { clx } from 'Utils';
import { LoadingSpinner } from 'Elements';
import styles from 'partials/shared/loading/loading-content.module.scss';

interface Props {
	title?: string;
	full?: boolean;
	className?: string;
	[x: string]: unknown;
}

export const LoadingContent: FC<Props> = memo(
	({
		title = 'forms.loading-content',
		full = false,
		className,
		...spinnerProps
	}) => {
		return (
			<div
				className={clx(
					full && styles.overlay,
					!full && styles.content,
					className
				)}>
				<LoadingSpinner {...spinnerProps} size="2x" title={title} />
			</div>
		);
	}
);
LoadingContent.displayName = 'LoadingContent';
