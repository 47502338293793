import { memo, FC, useState } from 'react';
import { Col, Row } from 'reactstrap';
import {
	EventNewInvitationList,
	EventParticipatedList,
	EventRejectedInvitationList,
	EventCancelParticipatedList,
	ListTab
} from 'Elements';
import styles from 'partials/page/event.module.scss';

interface Tab {
	id: number;
	title: string;
	path: string;
	isActive: boolean;
}

export const MyCompanyEvent: FC = memo(() => {
	const [activeTab, setActiveTab] = useState(0);
	const [tabs, setTabs] = useState<Tab[]>([
		{
			id: 0,
			isActive: true,
			path: 'new-invitation',
			title: 'tab.event.new-invitation'
		},
		{
			id: 1,
			isActive: false,
			path: 'rejected-invitation',
			title: 'tab.event.rejected-invitation'
		},
		{
			id: 2,
			isActive: false,
			path: 'participated',
			title: 'tab.event.participated'
		},
		{
			id: 3,
			isActive: false,
			path: 'not-participated',
			title: 'tab.event.not-participated'
		}
	]);

	const switchList = (tabItem: Tab) => {
		const updatedTabs = tabs.map((tab) => ({
			...tab,
			isActive: tab.id === tabItem.id
		}));
		setTabs(updatedTabs);
		setActiveTab(tabItem.id);
	};

	return (
		<Row className="mb-4">
			<>
				<Col>
					<div className={styles.div}>
						<ListTab tabs={tabs} onTabChange={switchList} />
						{activeTab === 0 && <EventNewInvitationList />}
						{activeTab === 1 && <EventRejectedInvitationList />}
						{activeTab === 2 && <EventParticipatedList />}
						{activeTab === 3 && <EventCancelParticipatedList />}
					</div>
				</Col>
			</>
		</Row>
	);
});

MyCompanyEvent.displayName = 'MyCompanyEvent';
