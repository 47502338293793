import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { clx, toastify } from 'Utils';
import { useDelayUnmount, useModal } from 'Hooks';
import { Button, ModalBoxAddSocial, SocialItem, FontAwesome } from 'Elements';
import { ColorValues, Colors, SocialFields, SocialNetwork } from 'Types';
import styles from 'partials/homepage/social-item.module.scss';

interface Props {
	list: SocialNetwork[];
	className?: string;
	onRemoveSocial: (social: SocialNetwork) => void;
	onAddSocial: (data: SocialFields[]) => Promise<void>;
}

export const SocialList: FC<Props> = memo(
	({ list, className, onRemoveSocial, onAddSocial }) => {
		const { t } = useTranslation();
		const [openModal, toggleModal] = useModal();
		const shouldRenderModal = useDelayUnmount(openModal, 350);

		const checkSelectedSocial = (socialFields: SocialFields[]) => {
			return socialFields.find((elem) => {
				return list?.some((item) => item.type === elem?.name?.value);
			});
		};

		const handleAddSocial = async (socialFields: SocialFields[]) => {
			const selectedSocial = checkSelectedSocial(socialFields);
			if (selectedSocial) {
				toastify(
					t('toastify.social-selected-before', {
						socialName: selectedSocial?.name?.value
					})
				);
			} else {
				await onAddSocial(socialFields);
				toggleModal();
			}
		};

		return (
			<ul
				className={clx(
					'd-inline-flex align-items-center',
					styles.list,
					className
				)}>
				{list?.map((social, idx) => {
					return (
						<li key={`${social.type}_${idx}_${social.url}`}>
							<SocialItem
								social={social}
								onRemove={() => onRemoveSocial(social)}
							/>
						</li>
					);
				})}
				<li>
					<Button
						color={Colors.empty}
						className={styles.add}
						onClick={toggleModal}>
						<FontAwesome
							swapOpacity
							icon="plus"
							size="lg"
							color={ColorValues.primary}
						/>
					</Button>
				</li>
				{shouldRenderModal && (
					<ModalBoxAddSocial
						isOpen={openModal}
						onClose={toggleModal}
						onSubmit={handleAddSocial}
					/>
				)}
			</ul>
		);
	}
);

SocialList.displayName = 'SocialList';
