import { Controller, UseControllerProps } from 'react-hook-form';
import type { FieldValues } from 'react-hook-form/dist/types';
import { RadioGroup } from 'Elements';
import { RadioOption } from 'Types';

type Props<T extends FieldValues> = {
	radioClassName?: string;
	boxClassName?: string;
	option: RadioOption[];
	defaultSelected?: string | number;
	onRadioChange?: (id: string | number, label: string) => void;
	[x: string]: any;
} & UseControllerProps<T>;

export const RadioController = <T extends FieldValues>({
	control,
	name,
	rules,
	error,
	option,
	defaultSelected,
	radioClassName,
	boxClassName,
	onRadioChange
}: Props<T>): JSX.Element => {
	return (
		<div className={boxClassName}>
			<Controller
				control={control}
				name={name}
				rules={rules}
				render={({ field: { onChange, value = defaultSelected } }) => (
					<RadioGroup
						name={name}
						options={option}
						boxClassName={radioClassName}
						defaultSelected={value}
						onChange={(id: string | number, label: string) => {
							onChange(id, label);
							onRadioChange?.(id, label);
						}}
					/>
				)}
			/>
			{error && (
				<div className="invalid-feedback d-block">{error.message}</div>
			)}
		</div>
	);
};

RadioController.displayName = 'RadioController';
