import {
	SocialNetwork,
	Address,
	AgentSetting,
	Status,
	Contact,
	Translate,
	SelectOption
} from 'Types';

export enum CompanyApproveStatus {
	Pending = 0,
	Approved = 1,
	Rejected = 2,
	Expired = 3,
	PendingAgentApproval = 4
}

export interface CompanyProfile {
	id: string;
	companyName: string;
	webSiteAddress: string;
	taxNumber: string;
	description: string;
	approveStatus: CompanyApproveStatus;
	isActive: boolean;
	firstName: string;
	lastName: string;
	email: string;
	companyEmail: string;
	agentId: string;
	slugUrl: string;
	address: Address;
	status: Status;
	companySetting: CompanySetting;
	agentSetting: AgentSetting;
	contact: Contact;
	benefitList: Benefit[];
	companyBenefitDto: Benefit[];
	agentName: string;
	socialNetwork: SocialNetwork[];
	gallery: Gallery[];
	regionId: string;
	region: string;
	sector: SelectOption;
	linkedinConfig: LinkedinConfig;
	sectorName: string;
}

export interface CompanyProfileInitialData extends CompanyProfile {
	sectorId: string;
}

export interface CompanyProfileData extends CompanyProfile {
	sectorId: SelectOption | undefined | null;
}

export interface CompanyProfileFormData extends CompanyProfile {
	sectorId: string;
}

export interface CompanyProfile {
	companyId: string;
	name: string;
	companyEmail: string;
	regionId: string;
	regionName: string;
	approveStatus: CompanyApproveStatus;
	isActive: boolean;
	companySetting: CompanySetting;
}

export interface CompanySetting {
	logo: string;
	thumbnailLogo: string;
	companyHeaderLogo: string;
	companyHeaderThumbnailLogo: string;
	slogan: string;
	employeeHeadline: string;
	employeeHeadlineStatus: boolean;
	employeeSectionStatus: boolean;
	description: string;
	jobCommonHeadline: string;
	internshipCommonHeadline: string;
	jobText: string;
	internshipText: string;
	jobOverviewHeadLine: string;
	internshipOverviewHeadLine: string;
	jobCommonHeadlineStatus: boolean;
	internshipCommonHeadlineStatus: boolean;
	benefitSectionStatus: boolean;
	statisticsSectionStatus: boolean;
	gallerySectionStatus: boolean;
	jobListStatus: boolean;
	internshipListStatus: boolean;
	jobStatus: boolean;
	companyText: string;
	companyOverviewDescription: string;
	branch: string;
	employeeNumber: string;
	establishedDate: string;
	location: string;
	benefitTitle: string;
	benefitDescription: string;
	secondaryColor: string;
}

export interface CompanyIntroduction {
	logo: string;
	thumbnailLogo: string;
	companyHeaderLogo: string;
	companyHeaderThumbnailLogo: string;
	slogan: string;
	description: string;
	companyText: string;
	companyOverviewDescription: string;
}

export interface Statistic {
	branch: string;
	establishedDate: string;
	location: string;
	employeeNumber: string;
}

export interface CompanyJobCommon {
	jobCommonHeadline: string;
	jobText: string;
	jobCommonHeadlineStatus: boolean;
}

export interface CompanyJobList {
	jobOverviewHeadLine: string;
	jobListStatus: boolean;
}

export interface CompanyBenefit {
	benefitTitle: string;
	benefitDescription: string;
}

export interface CompanyBenefitDto {
	id: string;
	code: string;
	isActive: boolean;
	benefitTranslates: Translate[];
}

export interface CompanyEmployee {
	employeeHeadline: string;
	employeeHeadlineStatus: boolean;
}

export interface Benefit {
	id: string;
	code: string;
	benefitTranslates: Translate[];
	isActive: boolean;
}

export interface Gallery {
	id: string;
	title: string;
	sortOrder: number;
	image: string;
	imageThumbnail: string;
	showInHomePage: boolean;
	isRemoved: boolean;
	size: string;
	originalName: string;
}

export interface GalleryFormData {
	title: string;
	sortOrder: number;
	image: string;
	imageThumbnail: string;
	showInHomePage: boolean;
	size: string;
	originalName: string;
}

export interface Employee {
	id: string;
	employeeName: string;
	description: string;
	isActive: boolean;
	jobTitle: string;
	jobText?: string;
	showInHomePage: boolean;
	companyId?: string;
	image: string;
	thumbnailUrl: string;
}

export interface EmployeeForm {
	image: string;
	thumbnailUrl: string;
	employeeName: string;
	description: string;
	jobTitle: string;
	isActive: boolean;
	showInHomePage: boolean;
}

export interface Activity {
	id: string;
	code: string;
	isActive: boolean;
	activityTranslates: Translate[];
}

export interface ActivityId {
	activityId: string;
}

export interface JobType {
	id: string;
	code: string;
	isActive: boolean;
	jobKind: number;
	jobTypeTranslates: Translate[];
}

export interface InternshipType {
	id: string;
	code: string;
	isActive: boolean;
	jobTypeTranslates: Translate[];
}

export interface Sector {
	id: string;
	code: string;
	isActive: boolean;
	sectorTranslates: Translate[];
}

export interface LinkedinConfig {
	clientIdentify?: string;
	clientSecret?: string;
	token?: string;
}
