import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { featureIconList } from 'Data';
import { AgentPlan, ColorValues, Colors } from 'Types';
import { Button, FontAwesome, NoData, SelectController } from 'Elements';

export const AgentPlanPriceFeatures: FC = memo(() => {
	const descriptionLimit = 270;
	const { t } = useTranslation();

	const {
		watch,
		control,
		register,
		formState: { errors }
	} = useFormContext<AgentPlan>();

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'features'
	});

	const appendFeature = () => {
		append({
			icon: undefined,
			title: '',
			description: '',
			sortOrder: 1
		});
	};

	const formatOptionLabel = ({
		label,
		icon
	}: {
		label: string;
		icon: any;
	}) => (
		<div className="d-flex align-items-center gap-2">
			<FontAwesome
				icon={icon}
				size="lg"
				fixedWidth
				color={ColorValues['gray-4']}
			/>
			<span className="text-truncate text-capitalize">{label}</span>
		</div>
	);

	return (
		<Row>
			<Col md={8} xxl={6}>
				{fields?.map((field, index) => {
					return (
						<div className="border-bottom pb-4 mb-4" key={field.id}>
							<FormGroup>
								<Label>
									{t('forms.plan-price.feature-title')}
									<small className="ms-1">
										({t('validation.required')})
									</small>
								</Label>
								<input
									{...register(`features.${index}.title`)}
									type="text"
									placeholder={t('placeholder.enter-title')}
									className="inputbox w-100"
								/>
								{errors?.features?.[index]?.title && (
									<div className="invalid-feedback d-block">
										{
											errors?.features?.[index]?.title
												?.message
										}
									</div>
								)}
							</FormGroup>
							<FormGroup className="position-relative">
								<Label htmlFor="featureDescription">
									{t('forms.plan-price.feature-description')}
								</Label>
								<textarea
									{...register(
										`features.${index}.description`,
										{
											maxLength: descriptionLimit
										}
									)}
									maxLength={descriptionLimit}
									id="featureDescription"
									className="inputbox w-100"
									cols={10}
									rows={5}
								/>
								<div className="position-absolute end-0">
									<small className="ms-auto mt-1">
										{watch(`features.${index}.description`)
											?.length || 0}
										/{descriptionLimit}
									</small>
								</div>
								{errors?.features?.[index]?.description && (
									<div className="invalid-feedback d-block">
										{
											errors?.features?.[index]
												?.description?.message
										}
									</div>
								)}
							</FormGroup>
							<div className="d-flex align-items-end gap-3 flex-wrap">
								<div className="flex-grow-1">
									<Label>
										{t('forms.plan-price.feature-icon')}
									</Label>
									<SelectController
										control={control}
										name={`features.${index}.icon`}
										options={featureIconList}
										className="w-100 w-xs-50 w-md-80 w-lg-60"
										formatOptionLabel={formatOptionLabel}
									/>
								</div>
								<Button
									size="sm"
									color={Colors['white-red']}
									className="ms-auto gap-2 flex-shrink-0"
									onClick={() => remove(index)}>
									<FontAwesome icon="trash-can" size="lg" />
									{t('button.remove')}
								</Button>
							</div>
						</div>
					);
				})}
				{fields && fields?.length !== 0 && (
					<div className="ms-auto d-flex align-items-center gap-3">
						<span
							role="button"
							className="gap-2 text-primary d-flex align-items-center"
							onClick={appendFeature}>
							<FontAwesome icon="plus" size="1x" swapOpacity />
							{t('button.plan-price.add-new-feature')}
						</span>
					</div>
				)}
			</Col>
			{fields?.length === 0 && (
				<Col xs={12}>
					<NoData message="title.plan-price.no-agent-feature">
						<Button className="gap-2 mt-2" onClick={appendFeature}>
							<FontAwesome icon="plus" size="lg" swapOpacity />
							{t('button.plan-price.add-new-feature')}
						</Button>
					</NoData>
				</Col>
			)}
		</Row>
	);
});

AgentPlanPriceFeatures.displayName = 'AgentPlanPriceFeatures';
// export default AgentPlanPriceFeatures;
