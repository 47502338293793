import { memo, FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Colors, Tab, InternshipRegistration } from 'Types';
import { format } from 'date-fns';
import { de, enUS } from 'date-fns/locale';
import { getCurrentLocale, clx } from 'Utils';
import { FontAwesome, Button, ListTab, ShimmerImage } from 'Elements';
import '../../../assets/css/internship-management.css';

import Drawer from 'react-modern-drawer';
import styles from 'partials/shared/page.module.scss';

//import styles 👇
import 'react-modern-drawer/dist/index.css';

interface Props {
	isOpen?: boolean;
	dataSelected: any;
	toggleDrawer?: () => void;
}

export const TermsDrawer: FC<Props> = memo(
	({ isOpen, toggleDrawer, dataSelected }) => {
		const defaultImage = '/assets/img/profiles/img-user-default.png';
		const { t } = useTranslation();
		const navigate = useNavigate();
		const [activeTab, setActiveTab] = useState(0);
		const [data, setData] = useState<any>([]);
		const [totalTerm, setTotalTerm] = useState<any>(0);
		const [totalMember, setTotalMember] = useState<any>(0);

		const [tabs, setTabs] = useState<Tab[]>([]);
		const switchList = (tabItem: Tab) => {
			const updatedTabs = tabs.map((tab) => ({
				...tab,
				isActive: tab.id === tabItem.id
			}));
			setTabs(updatedTabs);
			setActiveTab(tabItem.id);
		};
		const getLocalFormat = () => {
			const currentLocale = getCurrentLocale().code;
			if (currentLocale === 'de') {
				return 'dd.MM.yyyy';
			}
			return 'yyyy-MM-dd';
		};

		const getApplicantName = (applicant: InternshipRegistration) => {
			const { firstName, lastName } = applicant;
			return `${firstName} ${lastName}`;
		};

		useEffect(() => {
			if (isOpen) {
				setData(dataSelected);
				let t = 0;
				dataSelected?.data?.terms.forEach((item) => {
					if (item.id === dataSelected.id) {
						t = t + parseInt(item?.sessions.length);
						setTotalMember(
							item?.registrantsInfos?.length
								? item?.registrantsInfos?.length
								: 0
						);
					}
				});
				setTotalTerm(t);
				setTabs([
					{
						id: 0,
						isActive: true,
						path: 'invited',
						title: `title.session-dates`,
						number: totalTerm
					},
					{
						id: 1,
						isActive: false,
						path: 'participant',
						title: `label.members`,
						number: totalMember
					}
				]);
			}
		}, [isOpen, data]);

		return (
			<Drawer
				open={isOpen as never}
				onClose={() => {
					setData([]);
					toggleDrawer && toggleDrawer();
				}}
				lockBackgroundScroll
				zIndex={100}
				direction="right"
				size="34rem">
				<div className="d-flex align-items-center justify-content-between py-3 px-3 border-bottom border-gray-1 gap-2 ">
					<div>
						<span className="fs-6 fw-600">
							{t('forms.internship-term-info')}
						</span>
					</div>
					<div className="d-flex align-items-center gap-2">
						<Button
							size="sm"
							color={Colors['white-primary']}
							label={t('button.go-to-internship') as string}
							onClick={() => {
								navigate(
									`/internships/${dataSelected?.internshipId}`
								);
							}}
						/>
						<Button
							size="sm"
							color={Colors['white-gray']}
							label={t('button.close') as string}
							onClick={() => {
								setData([]);
								toggleDrawer && toggleDrawer();
							}}
						/>
					</div>
				</div>
				<div className="py-3 px-3">
					<h5 className="fw-500 mb-2">{data?.data?.title}</h5>
					<p className="fs-base mb-2">
						{data?.data?.shortDescription}
					</p>
					<div className="d-flex align-items-center lh-base justify-content-between mb-2 border-top border-gray-1 pt-2">
						<span className="fs-sm fw-400">
							<FontAwesome icon="calendar" size="sm" />
							<span className="ms-1">
								{t('forms.start-date')}
							</span>
						</span>
						{data?.data?.terms.map((item) => {
							return (
								item.id === data.id && (
									<span
										key={item.id}
										className="fs-sm fw-400">
										{format(
											new Date(
												item?.sessions[0]?.sessionStartDate
											),
											`EEEE ${getLocalFormat()}`,
											{
												locale:
													getCurrentLocale().code ===
													'de'
														? de
														: enUS
											}
										)}
									</span>
								)
							);
						})}
					</div>
					<div className="d-flex align-items-center lh-base justify-content-between mb-2">
						<span className="fs-sm fw-400">
							<FontAwesome icon="calendar" size="sm" />
							<span className="ms-1"> {t('forms.time')}</span>
						</span>
						<span className="fs-sm fw-400">
							{data?.data?.terms.map((item) => {
								const startDate: any = new Date(
									Date.parse(
										item?.sessions[0]?.sessionStartDate
									)
								);
								const endDate: any = new Date(
									Date.parse(
										item?.sessions[0]?.sessionEndDate
									)
								);
								return (
									item.id === data.id &&
									`${startDate.toLocaleTimeString([], {
										hour: '2-digit',
										minute: '2-digit',
										hour12: false
									})} - ${endDate.toLocaleTimeString([], {
										hour: '2-digit',
										minute: '2-digit',
										hour12: false
									})}`
								);
							})}
						</span>
					</div>
					<div className="d-flex align-items-center lh-base justify-content-between mb-4">
						<span className="fs-sm fw-400">
							<FontAwesome icon="user" size="sm" />
							<span className="ms-1">
								{t('forms.number-of-seats')}
							</span>
						</span>
						<span className="fs-sm fw-400">
							{data?.data?.capacity === 0
								? `${totalMember}/${t('label.unlimited')}`
								: `${totalMember}/${data?.data?.capacity}`}
						</span>
					</div>
					<ListTab
						tabs={tabs}
						onTabChange={switchList}
						className="tabInternship"
					/>
					{activeTab === 0 && (
						<div style={{ overflow: 'auto', height: '40rem' }}>
							{data?.data?.terms.map((item) => {
								if (item.id === data.id) {
									return item?.sessions?.map((s, index) => {
										return (
											<div
												key={s.id}
												className="mb-2 pb-2 border-bottom border-gray-1 ">
												<div className="fs-small fw-400 mb-2">{`${t(
													'forms.session'
												)} ${index + 1}`}</div>
												<div className="fs-sm fw-500">
													{format(
														new Date(
															s?.sessionStartDate
														),
														`EEEE ${getLocalFormat()}`,
														{
															locale:
																getCurrentLocale()
																	.code ===
																'de'
																	? de
																	: enUS
														}
													)}
												</div>
											</div>
										);
									});
								}
							})}
						</div>
					)}
					{activeTab === 1 && (
						<div style={{ overflow: 'auto', height: '40rem' }}>
							{data?.data?.terms.map((item) => {
								if (item.id === data.id) {
									return item?.registrantsInfos?.map(
										(member, index) => {
											return (
												<div
													key={
														member.applicant.id +
														index
													}
													className="d-flex align-items-center justify-content-between mb-2 border-bottom border-gray-1 pb-2">
													<div className="d-flex align-items-center gap-3">
														<div
															className={
																styles.avatarBox
															}>
															<ShimmerImage
																width={48}
																height={48}
																src={
																	member
																		.applicant
																		.imageThumbnail
																}
																alt={getApplicantName(
																	member.applicant
																)}
																defaultImage={
																	defaultImage
																}
																className={clx(
																	styles.avatar,
																	'object-cover'
																)}
															/>
														</div>
														<div className="d-flex flex-column">
															<h6 className="fs-sm fw-400 mb-0 lh-base">
																{getApplicantName(
																	member?.applicant
																)}
															</h6>
															<span className="text-gray-3 fs-small">
																{
																	member
																		.applicant
																		.email
																}
															</span>
														</div>
													</div>
													<Button
														color={
															Colors[
																'white-primary'
															]
														}
														label={
															t(
																'button.details'
															) as string
														}
														onClick={() => {
															navigate(
																`/internship-participants/${member?.id}`
															);
														}}
													/>
												</div>
											);
										}
									);
								}
							})}
						</div>
					)}
				</div>
			</Drawer>
		);
	}
);

TermsDrawer.displayName = 'TermsDrawer';
