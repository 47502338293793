import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Wizard, Steps, Step } from 'react-albus-react18';
import { names } from 'Constants';
import { clx } from 'Utils';
import {
	IStep,
	JobForm,
	JobFormDescription,
	JobFormFeatured,
	JobFormGeneral,
	JobFormInfo
} from 'Types';
import {
	CreateJobDescription,
	CreateJobFeatured,
	CreateJobGeneral,
	CreateJobInfo,
	CreateJobPreviewSubmit,
	FormSuccess,
	StepContainer
} from 'Elements';
import styles from 'partials/step/step-container.module.scss';

export const CreateJob = memo(() => {
	const { t } = useTranslation();
	const { jobs } = names;
	const [jobId, setJobId] = useState('');

	const steps: IStep[] = [
		{
			id: 'step1',
			title: t('wizard.step-job-1')
		},
		{
			id: 'step2',
			title: t('wizard.step-job-2')
		},
		{
			id: 'step3',
			title: t('wizard.step-job-3')
		},
		{
			id: 'step4',
			title: t('wizard.step-job-4')
		},
		{
			id: 'step5',
			title: t('wizard.step-preview')
		}
	];

	const defaultFields: JobForm = {
		step1: {
			code: '',
			title: '',
			shortDescription: '',
			expireDate: '',
			startDate: '',
			isActive: true,
			hasStartDate: false,
			hasExpireDate: false
		},
		step2: {
			featuredPhotoUrl: '',
			featuredVideoUrl: ''
		},
		step3: {
			sectorId: null,
			jobTypeId: null,
			activityList: null
		},
		step4: {
			description: '',
			benefits: '',
			requirements: '',
			responsibilities: '',
			showDescription: true,
			showRequirements: true,
			showResponsibilities: true,
			showBenefits: true
		}
	};
	const [fields, setFields] = useState(defaultFields);

	const handleNext = <T,>(
		goToNext: () => void,
		data: T,
		step_number: number
	) => {
		setFields((prev) => ({
			...prev,
			[`step${step_number.toString()}`]: data
		}));
		goToNext();
	};

	return (
		<Wizard
			render={({ step, ...props }) => (
				<div
					className={clx(
						styles.wizard,
						'd-flex flex-column flex-md-row'
					)}>
					<StepContainer
						steps={steps}
						currentIndex={props.steps.indexOf(step)}
					/>
					<Steps>
						<Step
							id="step1"
							render={({ next }) => (
								<CreateJobGeneral
									fields={fields.step1}
									onNext={(data) =>
										handleNext<JobFormGeneral>(
											next,
											data,
											1
										)
									}
								/>
							)}
						/>
						<Step
							id="step2"
							render={({ next, previous }) => (
								<CreateJobFeatured
									fields={fields.step2}
									onPrev={previous}
									onNext={(data) =>
										handleNext<JobFormFeatured>(
											next,
											data,
											2
										)
									}
								/>
							)}
						/>
						<Step
							id="step3"
							render={({ next, previous }) => (
								<CreateJobInfo
									fields={fields.step3}
									onPrev={previous}
									onNext={(data) =>
										handleNext<JobFormInfo>(next, data, 3)
									}
								/>
							)}
						/>
						<Step
							id="step4"
							render={({ next, previous }) => (
								<CreateJobDescription
									fields={fields.step4}
									onPrev={previous}
									onNext={(data) =>
										handleNext<JobFormDescription>(
											next,
											data,
											4
										)
									}
								/>
							)}
						/>
						<Step
							id="step5"
							render={({ next, previous, push }) => (
								<CreateJobPreviewSubmit
									fields={fields}
									onPrev={previous}
									onNext={next}
									goToStep={push}
									setJobId={setJobId}
								/>
							)}
						/>
						<Step
							id="step6"
							render={({ push }) => (
								<div className={clx('d-flex', styles.form)}>
									<FormSuccess
										addButtonTitle="button.add-job"
										listTitle="button.check-details"
										successTitle="forms.job-created"
										className="flex-grow-1 align-self-center"
										listUrl={`${jobs.path}/${jobId}`}
										onClickAdd={() => {
											push('step1');
											setFields(defaultFields);
										}}
									/>
								</div>
							)}
						/>
					</Steps>
				</div>
			)}
		/>
	);
});

CreateJob.displayName = 'CreateJob';
