import { memo, FC, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { api, clx, dynamicFileDownload } from 'Utils';
import { setFieldValidationError } from 'Actions';
import {
	AssetConfigType,
	AssetExportType,
	AssetToolType,
	MarketingAssetFormData
} from 'Types';
import {
	useAxios,
	useDelayUnmount,
	useDispatch,
	useModal,
	useResponsive,
	useSelector
} from 'Hooks';
import {
	ModalBoxDownloadFile,
	ToolboxAssetSection,
	ToolboxConfigSection,
	ToolboxExportSection,
	ToolboxTemplateSection
} from 'Elements';
import styles from 'partials/page/form.module.scss';

// eslint-disable-next-line sonarjs/cognitive-complexity
export const ManageToolbox: FC = memo(() => {
	const { t } = useTranslation();
	const { sendRequest } = useAxios();
	const { isTabletAndBelow } = useResponsive();
	const [openDownload, toggleOpenDownload] = useModal();
	const dispatch = useDispatch();
	const shouldRenderModal = useDelayUnmount(openDownload, 350);
	const { assetConfig, selectedTemplate, selectedAsset, showLogo } =
		useSelector((state) => state.marketing);
	const [state, setState] = useState<{
		exporting: {
			pdf: boolean;
			jpg: boolean;
		};
		downloadLink: string;
		currentStep: number;
		selectedAsset: AssetToolType | null;
	}>({
		exporting: {
			pdf: false,
			jpg: false
		},
		downloadLink: '',
		currentStep: 1,
		selectedAsset: null
	});

	const getRequiredFields = () => {
		const requiredFields: AssetConfigType[] = [];
		const data = selectedTemplate;
		for (const key in data) {
			if (data[key]?.isRequired) {
				requiredFields.push(key as AssetConfigType);
			}
		}
		return requiredFields;
	};

	const validateForm = () => {
		let isValid = true;

		getRequiredFields().forEach((field) => {
			if (!assetConfig[field]) {
				dispatch(
					setFieldValidationError({
						field,
						error: t(`validation.marketing.${field}.required`)
					})
				);
				isValid = false;
			}
		});
		return isValid;
	};

	const requestExport = async (
		type: AssetExportType,
		data: MarketingAssetFormData
	) => {
		const api_url =
			type === AssetExportType.jpg
				? api.exportMarketingAssetAsJpg
				: api.exportMarketingAssetAsPdf;

		await sendRequest(api_url, { data }, (export_data) => {
			setState((prev) => ({
				...prev,
				downloadLink: export_data as string
			}));
			toggleOpenDownload();
			dynamicFileDownload(
				export_data as string,
				`Jobbiplace - ${selectedTemplate?.title}`
			);
		});
	};

	const handleExport = async (type: AssetExportType) => {
		const api_data = {
			id: selectedTemplate?.id ?? '',
			toolType: selectedTemplate?.toolType ?? AssetToolType.poster,
			validExportableType: type,
			sizeElementInputItem: selectedTemplate?.sizeElement
				? {
						...selectedTemplate?.sizeElement?.inputItems[0],
						value: assetConfig.sizeElement?.value ?? ''
				  }
				: null,
			simpleLogoElementInputItem: selectedTemplate?.simpleLogoElement
				? {
						...selectedTemplate?.simpleLogoElement?.inputItems[0],
						value: showLogo
							? assetConfig.simpleLogoElement ?? ''
							: ''
				  }
				: null,
			headlineElementInputItem: selectedTemplate?.headlineElement
				? assetConfig.headlineElement
				: null,
			textColorElementInputItem: selectedTemplate?.textColorElement
				? assetConfig.textColorElement
				: null,
			textBgColorElementInputItem: selectedTemplate?.textBgColorElement
				? assetConfig.textBgColorElement
				: null,
			imageElementInputItem: selectedTemplate?.imageElement
				? assetConfig.imageElement
				: null,
			qrCodeElementInputItem: selectedTemplate?.qrCodeElement
				? {
						...selectedTemplate?.qrCodeElement?.inputItems[0],
						value:
							assetConfig.qrCodeElement ||
							selectedTemplate?.qrCodeElement?.inputItems[0]
								?.value ||
							''
				  }
				: null,
			nameElementInputItem: selectedTemplate?.nameElement
				? {
						...selectedTemplate?.nameElement?.inputItems[0],
						value: assetConfig.nameElement ?? ''
				  }
				: null,
			emailElementInputItem: selectedTemplate?.emailElement
				? {
						...selectedTemplate?.emailElement?.inputItems[0],
						value: assetConfig.emailElement ?? ''
				  }
				: null,
			phoneElementInputItem: selectedTemplate?.phoneElement
				? {
						...selectedTemplate?.phoneElement?.inputItems[0],
						value: assetConfig.phoneElement ?? ''
				  }
				: null
		};

		if (validateForm()) {
			try {
				setState((prev) => ({
					...prev,
					exporting: {
						...prev.exporting,
						[AssetExportType[type]]: true
					}
				}));
				await requestExport(type, api_data);
			} finally {
				setState((prev) => ({
					...prev,
					exporting: {
						...prev.exporting,
						[AssetExportType[type]]: false
					}
				}));
			}
		}
	};

	return (
		<div className="mb-5">
			<ToolboxAssetSection className="mb-4" />
			{!selectedAsset && (
				<Row className="mt-3 mt-md-5 pt-xxl-3">
					<Col md={5} className="mt-3 mt-md-0 order-1 order-md-0">
						<img
							src="/assets/img/select-asset.svg"
							className="w-90"
						/>
					</Col>
					<Col md={7} xl={5} className="">
						<img
							src="/assets/img/arrow-up.svg"
							className="ms-0 mb-2 px-4 w-30"
						/>
						<h6 className="fs-6 mb-2 lh-base">
							{t('title.marketing.select-asset')}
						</h6>
						<p className="lh-base text-gray-3">
							{t('text.marketing.select-asset-desc')}
						</p>
					</Col>
				</Row>
			)}
			{selectedAsset && (
				<Row>
					<Col md={5} className="mb-4 mb-md-0">
						<ToolboxTemplateSection />
					</Col>
					{!selectedTemplate && (
						<Col md={7}>
							<div className="d-flex flex-wrap mt-3 mt-md-5 gap-3">
								<img
									src={`/assets/img/${
										isTabletAndBelow
											? 'arrow-up'
											: 'arrow-left'
									}.svg`}
									className="ms-0 px-4 flex-shrink-0 w-20"
								/>
								<div className="flex-grow-1">
									<h6 className="fs-6 mb-2 lh-base">
										{t('title.marketing.select-template')}
									</h6>
									<p className="lh-base text-gray-3">
										{t(
											'text.marketing.select-template-desc'
										)}
									</p>
								</div>
							</div>
						</Col>
					)}
					{selectedTemplate && (
						<Col md={7} className={clx(isTabletAndBelow && 'mb-5')}>
							<ToolboxConfigSection
								className={clx(isTabletAndBelow && 'mb-5')}
							/>
							<ToolboxExportSection
								className={clx(
									isTabletAndBelow && styles.sticky_action
								)}
								exportType={
									selectedTemplate.validExportableTypes
								}
								exporting={{
									pdf: state.exporting.pdf,
									jpg: state.exporting.jpg
								}}
								onExport={handleExport}
							/>
						</Col>
					)}
				</Row>
			)}
			{shouldRenderModal && (
				<ModalBoxDownloadFile
					isOpen={openDownload}
					onClose={toggleOpenDownload}
					downloadLink={state.downloadLink}
				/>
			)}
		</div>
	);
});

ManageToolbox.displayName = 'ManageToolbox';
