import { memo, FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'Elements';
import { clx, formatLocaleDateTime } from 'Utils';
import { Colors, TransactionDetails, TransactionStatus } from 'Types';
import styles from 'partials/card/subscription-card.module.scss';

interface Props {
	transaction: TransactionDetails;
	baseUrl?: string;
}

export const CompanyTransactionInvoicePayment: FC<Props> = memo(
	({ transaction, baseUrl = 'transactions' }) => {
		const { t } = useTranslation();
		const navigate = useNavigate();
		const payStatus = transaction.status;

		return (
			<div>
				<h6 className="text-gray-3 mb-3 fs-6 fw-400">
					{t('title.payment.key')}
				</h6>
				<div className={clx(styles.row, 'justify-content-between')}>
					<span className={styles.rowLabel}>
						{t('label.payment-date')}:
					</span>
					<span>
						{transaction.createdAt
							? formatLocaleDateTime(transaction.createdAt)
							: '---'}
					</span>
				</div>
				<div
					className={clx(
						styles.row,
						'justify-content-between border-0'
					)}>
					<span className={styles.rowLabel}>
						{t('label.payment-reference')}:
					</span>
					<span>{transaction.payment?.paymentCode || '---'}</span>
				</div>
				<div className={clx(styles.row, 'justify-content-between')}>
					{payStatus === TransactionStatus.Paid && (
						<Button
							color={Colors['white-primary']}
							className="mt-2"
							label={t('button.payment-details') as string}
							onClick={() =>
								navigate(
									`/${baseUrl}/${transaction.relatedTransaction}`
								)
							}
						/>
					)}
					{payStatus === TransactionStatus.Pending && (
						<a
							href={transaction.payment?.payUrl}
							className={styles.link}>
							{t('button.pay-invoice')}
						</a>
					)}
				</div>
			</div>
		);
	}
);

CompanyTransactionInvoicePayment.displayName =
	'CompanyTransactionInvoicePayment';
