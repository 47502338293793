import { memo, FC } from 'react';
import { Col, Row } from 'reactstrap';
import { Outlet } from 'react-router-dom';
import { IntegrationDecorationBox } from 'Elements';

export const IntegrateEmbed: FC = memo(() => {
	return (
		<Row>
			<Col xs={12} className="mb-4">
				<IntegrationDecorationBox />
			</Col>
			<Col xs={12}>
				<Outlet />
			</Col>
		</Row>
	);
});

IntegrateEmbed.displayName = 'IntegrateEmbed';
