import { memo, FC } from 'react';
import { Col, Row } from 'reactstrap';
import { DashboardJobStatistic } from 'Elements';

interface Props {}

export const CompanyDashboard: FC<Props> = memo(() => {
	return (
		<Row>
			<Col xs={12} className="mb-4">
				{/* <AgentDecorationBox /> */}
			</Col>
			<Col md={6} lg={12} xl={6}>
				<DashboardJobStatistic />
			</Col>
			<Col md={6} lg={12} xl={6}></Col>
		</Row>
	);
});

CompanyDashboard.displayName = 'CompanyDashboard';
