export const enum VoucherDiscountType {
	Percent = 'Percent',
	Amount = 'Amount'
}

export interface Voucher {
	taxAmount: number;
	taxPercent: number;
	totalAmount: number;
	voucherCode: string;
	discount: number;
	discountType: VoucherDiscountType;
	discountAmount: number;
	priceWithDiscount: number;
	priceAfterDiscount: number;
}

export interface MyVoucher {
	id: string;
	voucherCode: string;
	title: string;
	discount: number;
	usageLimitUsedCount: number;
	usageLimit: number;
	createdAt: string;
	expireDate: string;
	isExpired: boolean;
	usageInfo: VoucherUsageInfo[];
	discountType: VoucherDiscountType;
	isAutomaticRenew: boolean;
}

export interface VoucherUsageInfo {
	companyId: string;
	usageDateTime: string;
	transactionCode: string;
	name: string;
	email: string;
	logo: string;
}
