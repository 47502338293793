import { FC, memo } from 'react';
import { DropdownItem } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { clx, formatLocalAddress } from 'Utils';
import { useDelayUnmount, useModal, useResponsive } from 'Hooks';
import {
	Address,
	AgentProfileFormData,
	Colors,
	CompanyProfileInitialData,
	Media,
	Tab,
	XOR,
	ColorValues
} from 'Types';
import {
	Card,
	TabNavigation,
	ShimmerImage,
	ModalBoxChangeImage,
	LoadingContent,
	Button,
	ModalBoxPublish,
	DecorationDropDown,
	ModalBoxDeleteAccount,
	ProfileDecorationAgentBadge,
	ProfileDecorationCompanyBadge,
	FontAwesome
} from 'Elements';
import pageStyles from 'partials/shared/page.module.scss';
import styles from 'partials/decoration/profile-decoration-box.module.scss';

interface SharedProps {
	logo: string;
	thumbnailLogo: string;
	address: Address;
	isLoading: boolean;
	isPublished: boolean;
	title: string;
	tabs: Tab[];
	onResetLogo: () => void;
	onChangeLogo: (image_data: Media) => void;
	disablePublish: boolean;
}
type Agent = {
	type: 'agent';
	profile: AgentProfileFormData;
};
type Company = {
	type: 'company';
	profile: CompanyProfileInitialData;
};
type Props = SharedProps & XOR<Agent, Company>;

export const ProfileDecorationBox: FC<Props> = memo(
	({
		isLoading,
		isPublished,
		logo,
		thumbnailLogo,
		address,
		title,
		tabs,
		type,
		profile,
		onResetLogo,
		onChangeLogo,
		disablePublish
	}): JSX.Element => {
		const { t } = useTranslation();
		const [openModalLogo, toggleModalLogo] = useModal();
		const [openModalPublish, toggleModalPublish] = useModal();
		const [openModalDelete, toggleModalDelete] = useModal();
		const { isTabletAndAbove, isDesktopAndAbove } = useResponsive();
		const shouldRenderModalDelete = useDelayUnmount(openModalDelete, 350);
		const defaultImage = '/assets/img/company-default.jpg';

		const getImageSize = () => {
			return isDesktopAndAbove ? 160 : isTabletAndAbove ? 136 : 112;
		};

		return (
			<Card tab>
				{isLoading && <LoadingContent />}
				<div
					className={clx(
						styles.body,
						'd-flex flex-column flex-md-row gap-3'
					)}>
					<div
						role="button"
						tabIndex={-1}
						className={styles.logo}
						onClick={toggleModalLogo}>
						<div className={styles.logoBox}>
							<ShimmerImage
								width={getImageSize()}
								height={getImageSize()}
								src={thumbnailLogo ?? defaultImage}
								defaultImage={defaultImage}
								className={styles.shimmer}
								alt={title ?? ''}
							/>
						</div>
						<span className={styles.edit}>
							<FontAwesome icon="pen" size="xl" />
						</span>
					</div>
					<div className="flex-grow-1">
						<h3 className="h5 lh-sm fw-400 text-break">{title}</h3>
						<div className="d-flex align-items-center flex-wrap gap-3 mb-3">
							{type === 'agent' && (
								<ProfileDecorationAgentBadge
									profile={profile}
								/>
							)}
							{type === 'company' && (
								<ProfileDecorationCompanyBadge
									profile={profile}
								/>
							)}
							{profile?.webSiteAddress && (
								<a
									href={profile.webSiteAddress}
									target="_blank"
									rel="noreferrer"
									className="fw-300 text-gray-3">
									{profile.webSiteAddress}
								</a>
							)}
						</div>
						{address && (
							<div className="text-gray-3 fw-300 fs-sm d-flex lh-base mb-3">
								<FontAwesome
									icon="location-dot"
									size="1x"
									className="me-2 mt-1"
								/>
								{formatLocalAddress(
									address?.street,
									address?.houseNo,
									address?.postalCode,
									address?.city,
									address?.state
								)}
							</div>
						)}
					</div>
					<div className={clx(
						styles.publish,
						'd-flex flex-column align-items-end gap-2 h-sm-100'
					)} >
						<div className='d-flex align-items-center justify-content-end gap-2'>
							<Button
								size="sm"
								color={
									isPublished
										? Colors['white-red']
										: Colors['white-primary']
								}
								disabled={disablePublish}
								onClick={toggleModalPublish}>
								<FontAwesome
									icon={
										isPublished
											? 'triangle-exclamation'
											: 'upload'
									}
									size="1x"
									className="me-2"
								/>
								{t(
									isPublished
										? 'button.unPublish'
										: 'button.publish'
								)}
							</Button>
							{type === 'agent' && (
								<DecorationDropDown>
									<DropdownItem
										className="d-flex align-items-center gap-2 text-red"
										onClick={toggleModalDelete}>
										<FontAwesome
											icon="triangle-exclamation"
											fixedWidth
											size="lg"
										/>
										{t('dropdown.delete-account')}
									</DropdownItem>
								</DecorationDropDown>
							)}
						</div>
						{disablePublish && (
							<div className={clx(styles.disablePublish,'d-flex gap-2 align-items-start text-yellow w-50')}>
								<FontAwesome icon="info-circle" size="lg" color={ColorValues.yellow}/>
								{type === 'agent' ? <span>{t('error.1507')}</span> : <span>{t('error.1508')}</span>}
							</div>
						)}
					</div>
				</div>
				<div className={pageStyles.tab}>
					{tabs?.map((tab) => {
						return (
							<TabNavigation
								key={tab.id}
								to={tab.path}
								title={tab.title}
								isActive={tab.isActive}
							/>
						);
					})}
				</div>
				<ModalBoxPublish
					userType={type}
					type={isPublished ? 'unPublish' : 'publish'}
					isPublished={isPublished}
					isOpen={openModalPublish}
					onClose={toggleModalPublish}
				/>
				<ModalBoxChangeImage
					name="logo"
					isOpen={openModalLogo}
					title="title.change-logo"
					image={logo}
					defaultImage={defaultImage}
					onRemove={onResetLogo}
					onClose={toggleModalLogo}
					onImageChange={onChangeLogo}
				/>
				{shouldRenderModalDelete && (
					<ModalBoxDeleteAccount
						isOpen={openModalDelete}
						onClose={toggleModalDelete}
					/>
				)}
			</Card>
		);
	}
);

ProfileDecorationBox.displayName = 'ProfileDecorationBox';
