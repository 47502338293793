import { memo } from 'react';
import { Col, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useSubscription } from 'Hooks';
import { SubscriptionDetails } from 'Types';
import {
	Card,
	DetailsSubscription,
	SubscriptionDescription,
	SubscriptionInvoice
} from 'Elements';

export const ViewSubscription = memo(() => {
	const { subscription_id } = useParams();
	const { data: subscription, isFetching } = useSubscription(
		subscription_id as string
	);

	return (
		<>
			<Card>
				<DetailsSubscription
					subscription={subscription as SubscriptionDetails}
					subscriptionId={subscription_id as string}
					agent={subscription?.agent}
					isLoading={isFetching}
				/>
			</Card>
			<Row className="mt-4">
				<Col md={6}>
					<SubscriptionInvoice
						isLoading={isFetching}
						subscription={subscription as SubscriptionDetails}
					/>
				</Col>
				<Col md={6} className="mt-4 mt-md-0">
					<SubscriptionDescription
						isLoading={isFetching}
						agent={subscription?.agent}
						description={subscription?.description || '---'}
						shortDescription={
							subscription?.shortDescription || '---'
						}
					/>
				</Col>
			</Row>
		</>
	);
});

ViewSubscription.displayName = 'ViewSubscription';
