import { memo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useUpdateAgent } from 'Hooks';
import { Address, AgentProfileFormData, Media, Tab } from 'Types';
import { ProfileDecorationBox } from 'Elements';

export const AgentDecorationBox = memo(() => {
	const { pathname } = useLocation();
	const { reset } = useForm();
	const {
		data: agent,
		setting,
		address,
		isFetching,
		updateAgentSetting
	} = useUpdateAgent();

	let tabs: Tab[] = [
		{
			id: 0,
			title: 'tab.general',
			path: 'general',
			isActive:
				pathname === '/my-agent' || pathname === '/my-agent/general'
		},
		{
			id: 1,
			title: 'tab.address',
			path: 'address',
			isActive: pathname === '/my-agent/address'
		},
		{
			id: 2,
			title: 'tab.regions',
			path: 'regions',
			isActive: pathname === '/my-agent/regions'
		},
		{
			id: 3,
			title: 'tab.bank',
			path: 'bank',
			isActive: pathname === '/my-agent/bank'
		}
	];

	if (
		agent?.communityPartnerClass !== 3 &&
		agent?.agentTypeId === '75b61de6-0784-4001-8b98-247db014e676'
	) {
		tabs = [
			{
				id: 0,
				title: 'tab.general',
				path: 'general',
				isActive:
					pathname === '/my-agent' || pathname === '/my-agent/general'
			},
			{
				id: 1,
				title: 'tab.address',
				path: 'address',
				isActive: pathname === '/my-agent/address'
			},
			{
				id: 2,
				title: 'tab.regions',
				path: 'regions',
				isActive: pathname === '/my-agent/regions'
			},
			{
				id: 3,
				title: 'tab.bank',
				path: 'bank',
				isActive: pathname === '/my-agent/bank'
			},
			{
				id: 4,
				title: 'tab.association',
				path: 'association',
				isActive: pathname === '/my-agent/association'
			}
		];
	}

	const handleUploadImage = async (image_data: Media) => {
		if (image_data) {
			const data = {
				logo: image_data.url,
				thumbnailLogo: image_data.thumbnailUrl
			};
			await updateAgentSetting(data);
		}
	};

	const handleResetLogo = async () => {
		const data = {
			logo: '',
			thumbnailLogo: ''
		};
		await updateAgentSetting(data);
	};

	useEffect(() => {
		reset(agent);
	}, [reset, agent]);

	return (
		<ProfileDecorationBox
			tabs={tabs}
			type="agent"
			profile={agent as AgentProfileFormData}
			isLoading={isFetching}
			isPublished={agent?.isActive ?? false}
			title={agent?.agentName || '----'}
			logo={setting?.logo ?? ''}
			thumbnailLogo={setting?.thumbnailLogo ?? ''}
			address={address as Address}
			onResetLogo={handleResetLogo}
			onChangeLogo={handleUploadImage}
			disablePublish={agent?.approveStatus !== 1}
		/>
	);
});

AgentDecorationBox.displayName = 'AgentDecorationBox';
