import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from 'Elements';
import { MyVoucher, Colors } from 'Types';
import { clx, formatLocaleDateTime, getDiscountFormat } from 'Utils';
import styles from 'partials/card/subscription-card.module.scss';

interface Props {
	voucher: MyVoucher;
}

export const VoucherDetails: FC<Props> = memo(({ voucher }) => {
	const { t } = useTranslation();
	const row_class = 'justify-content-between';
	return (
		<div>
			<h6 className="text-gray-3 mb-3 fs-6 fw-400">
				{t('title.voucher-details')}{' '}
			</h6>
			<div className={clx(styles.row, row_class)}>
				<span className={clx(styles.rowLabel, 'fw-500')}>
					{t('label.voucher-code')}:
				</span>
				<span className="fw-500">{voucher.voucherCode}</span>
			</div>
			<div className={clx(styles.row, row_class)}>
				<span className={styles.rowLabel}>{t('label.discount')}:</span>
				<span>
					{getDiscountFormat(
						voucher?.discount,
						voucher?.discountType
					)}
				</span>
			</div>
			<div className={clx(styles.row, row_class)}>
				<span className={styles.rowLabel}>{t('label.usage')}:</span>
				<span>{`${voucher.usageLimitUsedCount}/${
					voucher.usageLimit === 0
						? t('label.unlimited')
						: voucher.usageLimit
				}`}</span>
			</div>
			<div className={clx(styles.row, row_class)}>
				<span className={styles.rowLabel}>
					{t('label.expire-date')}:
				</span>
				{voucher.expireDate && (
					<time
						title={formatLocaleDateTime(voucher.expireDate)}
						dateTime={formatLocaleDateTime(voucher.expireDate)}>
						{formatLocaleDateTime(voucher.expireDate)}
					</time>
				)}
				{!voucher.expireDate && t('label.permanent')}
			</div>
			<div className={clx(styles.row, row_class)}>
				<span className={styles.rowLabel}>{t('label.status')}:</span>
				<span>
					<Badge
						color={voucher.isExpired ? Colors.red : Colors.success}>
						{voucher.isExpired
							? t('badge.expired')
							: t('badge.active')}
					</Badge>
				</span>
			</div>
			<div className={clx(styles.row, row_class)}>
				<span className={styles.rowLabel}>
					{t('label.descriptions')}:
				</span>
				<span>
					{voucher.isAutomaticRenew &&
						t('label.applicable-in-subscription-renewal')}
				</span>
			</div>
		</div>
	);
});

VoucherDetails.displayName = 'VoucherDetails';
