import { memo, FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import { clx } from 'Utils';
import { Button } from 'Elements';
import { names } from 'Constants';
import { useDispatch } from 'Hooks';
import { resetSubscription } from 'Actions';
import styles from 'partials/payment/payment-result.module.scss';

export const PaymentSepa: FC = memo(() => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { subscriptions } = names;

	useEffect(() => {
		dispatch(resetSubscription());
	}, [dispatch]);
	return (
		<div className={styles.wrapper}>
			<Row className="justify-content-center">
				<div className={clx(styles.body, 'gap-4')}>
					<h2 className="text-primary h4 mb-0 lh-base">
						{t('title.payment.waiting-for-sepa')}
					</h2>
					<div>
						<p className="mb-0 fw-400 lh-base">
							{t('text.payment.pay-by-sepa-1')}
						</p>
						<p className="mb-0 fw-400 lh-base">
							{t('text.payment.pay-by-sepa-2')}
						</p>
						<p className="mb-0 fw-400 lh-base">
							{t('text.payment.pay-by-sepa-3')}
						</p>
						<p className="mb-0 fw-400 lh-base">
							{t('text.payment.pay-by-sepa-4')}
						</p>
					</div>
					<img src="/assets/img/bank.svg" alt="" className="w-100" />
					<Button
						as="a"
						to={subscriptions.path}
						label={t('button.view-subscriptions') as string}
					/>
				</div>
			</Row>
		</div>
	);
});

PaymentSepa.displayName = 'PaymentSepa';
