import { FC, memo } from 'react';
import { DropdownItem } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { names } from 'Constants';
import { useModal, useEvent, useAxios } from 'Hooks';
import {
	api,
	clx,
	dynamicFileDownload,
	formatLocaleDateTime,
	slugify
} from 'Utils';
import { Tab, Colors, Media, AgentEvent, EventStatus } from 'Types';
import {
	Badge,
	Card,
	DecorationDropDown,
	DecorationTab,
	EventCounterBox,
	FeaturePhoto,
	LoadingContent,
	ModalBoxChangeImage,
	FontAwesome
} from 'Elements';
import styles from 'partials/decoration/page-decoration-box.module.scss';

export const EventDecorationBox: FC = memo(() => {
	const { events } = names;
	const { t } = useTranslation();
	const { event_id } = useParams();
	const { pathname } = useLocation();
	const [openModal, toggleModal] = useModal();
	const { sendRequest, isLoading: isSubmitting } = useAxios<string>();
	const {
		data: fields,
		isFetching,
		updateEvent
	} = useEvent(event_id as string);

	const isEventFinishOrCancel =
		fields?.eventStatus === EventStatus.Canceled || fields?.isFinished;

	const defaultImage = '/assets/img/default-image.jpg';

	const defaultTab =
		pathname === `${events.path}/${event_id}` ||
		pathname === `${events.path}/${event_id}/general`;

	const tabs: Tab[] = [
		{
			id: 0,
			title: 'tab.general',
			path: 'general',
			isActive: defaultTab
		},
		{
			id: 1,
			title: 'tab.registration',
			path: 'registration',
			isActive: pathname === `${events.path}/${event_id}/registration`
		},
		{
			id: 2,
			title: 'tab.event-partners',
			path: 'event-partners',
			isActive: pathname === `${events.path}/${event_id}/event-partners`,
			isDisabled: isEventFinishOrCancel
		},
		{
			id: 3,
			title: 'tab.visitors',
			path: 'visitors',
			isActive: pathname === `${events.path}/${event_id}/visitors`
		}
	];

	const getBadgeData = (event?: AgentEvent) => {
		let badgeColor = Colors.yellow;
		let badgeLabel = 'draft';
		if (event) {
			if (event.eventStatus === EventStatus.Draft) {
				badgeLabel = 'badge.draft';
			} else if (event.eventStatus === EventStatus.Published) {
				badgeLabel = 'badge.published';
				badgeColor = Colors.green;
			} else if (event.eventStatus === EventStatus.Canceled) {
				badgeLabel = 'badge.canceled';
				badgeColor = Colors.red;
			}
			if (event.isFinished) {
				badgeLabel = 'badge.finished';
				badgeColor = Colors.muted;
			}
		}
		return {
			color: badgeColor,
			label: t(badgeLabel)
		};
	};

	const handelEventImage = async (image_data?: Media) => {
		if (fields) {
			const data = {
				...fields,
				featuredPhoto: image_data?.url ?? ''
			};
			await updateEvent(data);
		}
	};

	const handleExportList = async () => {
		await sendRequest(
			api.exportParticipants(event_id as string),
			{
				method: 'GET',
				responseType: 'blob',
				headers: {
					Accept: 'text/csv'
				}
			},
			(data) => {
				if (data && fields?.title) {
					const href = window.URL.createObjectURL(new Blob([data]));
					dynamicFileDownload(
						href,
						`Participants_${slugify(fields.title)}.csv`
					);
					URL.revokeObjectURL(href);
				}
			}
		);
	};

	return (
		<Card tab className="mb-4">
			{isFetching && <LoadingContent />}
			<div className={clx(styles.body, 'flex-column flex-md-row gap-3')}>
				<FeaturePhoto
					image={fields?.featuredPhoto}
					onClick={!isEventFinishOrCancel ? toggleModal : undefined}
				/>
				<div className="d-flex flex-column flex-grow-1 flex-wrap">
					<h3 className="h5 mb-0 lh-sm fw-400 d-flex">
						{fields?.title ?? '...'}
						{fields?.eventStatus !== EventStatus.Draft && (
							<DecorationDropDown
								className={clx(
									styles.dropdown,
									'ms-auto fs-sm'
								)}>
								<DropdownItem
									className="d-flex align-items-center gap-2 text-primary"
									onClick={handleExportList}
									disabled={isSubmitting}>
									<FontAwesome
										icon="download"
										size="lg"
										fixedWidth
										className="flex-shrink-0"
									/>
									{t('dropdown.export-participants')}
								</DropdownItem>
							</DecorationDropDown>
						)}
					</h3>
					<div className="text-gray-3 fw-300 d-flex align-items-center flex-wrap">
						<div className={styles.metaItem}>
							<Badge color={getBadgeData(fields).color}>
								{getBadgeData(fields).label}
							</Badge>
							{fields?.isInternal && (
								<Badge color={Colors['gray-5']}>
									{t('badge.event.internal')}
								</Badge>
							)}
						</div>
						<div className={styles.metaItem}>
							<FontAwesome
								icon="code-branch"
								size="lg"
								fixedWidth
								className="flex-shrink-0"
							/>
							<span className="text-gray-3">
								{t('card.current-version')}:
							</span>
							<span className="text-gray-5">
								{fields?.version ?? '...'}
							</span>
						</div>
						{fields?.modifiedAt && (
							<div className={styles.metaItem}>
								<FontAwesome
									icon="calendar-days"
									size="lg"
									fixedWidth
									className="flex-shrink-0"
								/>
								<span className="text-gray-3">
									{t('card.last-update')}:
								</span>
								<span className="text-gray-5">
									{fields?.modifiedAt
										? formatLocaleDateTime(
												fields?.modifiedAt
										  )
										: '...'}
								</span>
							</div>
						)}
					</div>
					{fields && (
						<div className="mt-auto pt-3 d-flex align-items-center gap-3 flex-wrap">
							<EventCounterBox
								title="label.event-partners"
								count={fields.partnerCount}
								total={fields.partnerLimit}
							/>
							<EventCounterBox
								title="label.visitors"
								count={fields.visitorCount}
								total={fields.participantLimit}
							/>
						</div>
					)}
				</div>
			</div>
			<DecorationTab tabs={tabs} />
			<ModalBoxChangeImage
				name="featurePhoto"
				isOpen={openModal}
				title="title.change-image"
				image={fields?.featuredPhoto ?? ''}
				defaultImage={defaultImage}
				onClose={toggleModal}
				onRemove={handelEventImage}
				onImageChange={handelEventImage}
			/>
		</Card>
	);
});

EventDecorationBox.displayName = 'EventDecorationBox';
