import { CustomPermission, SelectableAddress, SelectOption } from 'Types';

export type UserCategory = 'agent' | 'company';

export enum UserType {
	b2bUser = 8,
	b2bAdmin = 4
}

export interface UserProfile {
	userId: string;
	userGroupId: string;
	userGroupName: string;
	companyId: string;
	companyName: string;
	userType: UserType;
	firstName: string;
	lastName: string;
	username: string;
	emailAddress: string;
	dateOfBirth: string;
	gender: string;
	userCategory: UserCategory;
	street: string;
	houseNumber: string;
	postalCode: string;
	city: string;
	state: string;
	country: string;
	mobileNumber: string;
	phoneNumber: string;
	imageUrl: string;
	permissions: string[];
}

export interface B2BUser {
	userId: string;
	userGroupId: string;
	userGroupName: string;
	companyId: string;
	companyName: string;
	userType: UserType;
	firstName: string;
	lastName: string;
	username: string;
	emailAddress: string;
	dateOfBirth: string;
	gender: string;
	street: string;
	houseNumber: string;
	postalCode: string;
	city: string;
	state: string;
	country: string;
	mobileNumber: string;
	phoneNumber: string;
	imageUrl: string;
}

export interface User {
	userId: string;
	userGroupId: string;
	userGroupName: string;
	companyId: string;
	companyName: string;
	firstName: string;
	lastName: string;
	emailAddress: string;
	userType: UserType;
	dateOfBirth: string;
	gender: string;
	imageThumbnailUrl: string;
}

export type UserAddress = SelectableAddress;

export interface UserGeneral {
	userId: string;
	isAdmin: boolean;
	userGroupId: SelectOption | null;
	userGroupName: string;
	firstName: string;
	lastName: string;
	emailAddress: string;
	userType: UserType;
	dateOfBirth: string;
	gender: string;
	imageUrl: string;
	mobileNumber: string;
	phoneNumber: string;
}

export type UserInitialData = UserGeneral & UserAddress;

export interface UserFormData {
	userId: string;
	isAdmin: boolean;
	userGroupId: string;
	userGroupName: string;
	firstName: string;
	lastName: string;
	emailAddress: string;
	userType: UserType;
	dateOfBirth: string;
	gender: string;
	imageUrl: string;
	street: string;
	houseNumber: string;
	postalCode: string;
	city: string;
	state: string;
	country: string;
	mobileNumber: string;
	phoneNumber: string;
}

// User Group Interfaces
export interface UserGroup {
	id: string;
	name: string;
	description: string;
	roles: {
		id: string;
		name: string;
	}[];
}

export interface UserGroupForm {
	name: string;
	description?: string;
	roles: SelectOption[];
}

export interface UserGroupFormData {
	name: string;
	description?: string;
	roles: string[];
}

// User Role Interfaces
export interface UserRole {
	id: string;
	name: string;
	description: string;
	permissions: CustomPermission[];
}

export interface UserRoleInformation {
	name: string;
	description: string;
}

export interface UserRolePermissions {
	permissions: CustomPermission[];
}

export interface UserRoleForm {
	step1: UserRoleInformation;
	step2: UserRolePermissions;
}

export interface UserRoleFormData {
	name: string;
	description: string;
	permissions: CustomPermission[];
}
