import { createBrowserRouter, Outlet } from 'react-router-dom';
import { names } from 'Constants';
import {
	PublicThemeProvider,
	PageLayout,
	RemoveTrailingSlash,
	UpdateAgentRegions,
	UpdateAgentAssociation,
	UpdateAgentAddress,
	UpdateAgentBankAccount,
	UpdateAgentGeneral,
	UpdateCompanyGeneral,
	UpdateCompanyAddress,
	UpdateJobDescription,
	UpdateJobContactPerson,
	UpdateJobGeneral,
	UpdateJobInfo,
	UpdateUserGeneral,
	UpdateUserAddress,
	UpdateEventGeneral,
	UpdateInternshipGeneral,
	UpdateInternshipInfo,
	UpdateInternshipTerms,
	UpdateInternshipDescription,
	UpdateInternshipContactPerson,
	EventPartners,
	EventVisitors,
	UpdateEventRegistration,
	PartnerScript,
	BecomePartnerScript,
	JobsScript,
	JobbiplacePartnerScript,
	AgentPlanPriceCalculation,
	AgentPlanPriceInfo,
	AgentPlanPriceContactPerson,
	AgentPlanPriceFeatures
} from 'Elements';

import {
	Dashboard,
	MyCompany,
	MyAgent,
	MyCompanyProfilePage,
	MyAgentProfilePage,
	EmployeeUpdate,
	EmployeeCreate,
	EmployeeList,
	JobList,
	InternshipList,
	InternshipCreate,
	InternshipUpdate,
	InternshipManagementList,
	JobCreate,
	JobUpdate,
	VoucherList,
	MemberCreate,
	MemberUpdate,
	ErrorPage,
	SubscriptionList,
	SubscriptionCreate,
	SubscriptionCheckout,
	SubscriptionPaymentResult,
	TransactionList,
	TransactionDetails,
	SubscriptionDetails,
	PartnerList,
	EventCreate,
	EventList,
	ListContactGroups,
	ContactGroupCreate,
	ContactGroupUpdate,
	RequestList,
	RequestDetails,
	InternshipParticipantsList,
	InternshipParticipantsDetails,
	UserList,
	UserCreate,
	UserUpdate,
	UserGroupList,
	UserGroupCreate,
	UserGroupUpdate,
	UserRoleList,
	UserRoleCreate,
	UserRoleUpdate,
	MemberList,
	VoucherDetails,
	InvitationList,
	InvitationCreate,
	InvitationUpdate,
	ListExternPartners,
	ExternPartnerCreate,
	ExternPartnerUpdate,
	MyEvents,
	EventDetails,
	MyEventDetails,
	ToolboxWelcome,
	Integration,
	CreditList,
	CreditDetails,
	InvoiceList,
	InvoiceDetails,
	PaymentList,
	PaymentDetails,
	DepartmentList,
	DepartmentCreate,
	DepartmentUpdate,
	SubscriptionPaymentInvoice,
	PlanPriceManagement,
	AboCalculator,
	JobPreviewPage,
	InternshipPreviewPage
} from 'Pages';

const { planPrice, jobs } = names;

export const routeList = [
	{
		element: (
			<>
				<RemoveTrailingSlash />
				<PageLayout />
			</>
		),
		children: [
			{
				index: true,
				element: <Dashboard />
			},
			{
				path: '/',
				element: <Dashboard />
			},
			// My company profile pages
			{
				path: 'my-company',
				element: <MyCompany />,
				children: [
					{
						index: true,
						element: <UpdateCompanyGeneral />
					},
					{
						path: 'general',
						element: <UpdateCompanyGeneral />
					},
					{
						path: 'address',
						element: <UpdateCompanyAddress />
					}
				]
			},
			// My agent profile pages
			{
				path: 'my-agent',
				element: <MyAgent />,
				children: [
					{
						index: true,
						element: <UpdateAgentGeneral />
					},
					{
						path: 'general',
						element: <UpdateAgentGeneral />
					},
					{
						path: 'address',
						element: <UpdateAgentAddress />
					},
					{
						path: 'bank',
						element: <UpdateAgentBankAccount />
					},
					{
						path: 'regions',
						element: <UpdateAgentRegions />
					},
					{
						path: 'association',
						element: <UpdateAgentAssociation />
					}
				]
			},
			// Partner pages
			{
				path: 'partners',
				element: <Outlet />,
				children: [
					{
						index: true,
						element: <PartnerList />
					}
				]
			},
			// Events pages
			{
				path: 'events',
				element: <Outlet />,
				children: [
					{
						index: true,
						element: <EventList />
					},
					{
						path: 'create',
						element: <EventCreate />
					},
					{
						path: 'my-events',
						element: <MyEvents />
					},
					{
						path: ':event_id',
						element: <EventDetails />,
						children: [
							{
								index: true,
								element: <UpdateEventGeneral />
							},
							{
								path: 'general',
								element: <UpdateEventGeneral />
							},
							{
								path: 'registration',
								element: <UpdateEventRegistration />
							},
							{
								path: 'event-partners',
								element: <EventPartners />
							},
							{
								path: 'visitors',
								element: <EventVisitors />
							},
							{
								path: ':event_slug',
								element: <MyEventDetails />
							}
						]
					}
				]
			},
			// Contact Groups pages
			{
				path: 'contact-groups',
				element: <Outlet />,
				children: [
					{
						index: true,
						element: <ListContactGroups />
					},
					{
						path: 'create',
						element: <ContactGroupCreate />
					},
					{
						path: ':contactGroup_id',
						element: <ContactGroupUpdate />
					}
				]
			},
			// Invitation pages
			{
				path: 'invitations',
				element: <Outlet />,
				children: [
					{
						index: true,
						element: <InvitationList />
					},
					{
						path: 'create',
						element: <InvitationCreate />
					},
					{
						path: ':invitation_id',
						element: <InvitationUpdate />
					}
				]
			},
			// Abo Calculator
			{
				path: 'abo-calculator',
				element: <Outlet />,
				children: [
					{
						index: true,
						element: <AboCalculator />
					}
				]
			},
			// Extern Partner pages
			{
				path: 'extern-partners',
				element: <Outlet />,
				children: [
					{
						index: true,
						element: <ListExternPartners />
					},
					{
						path: 'create',
						element: <ExternPartnerCreate />
					},
					{
						path: ':externPartner_id',
						element: <ExternPartnerUpdate />
					}
				]
			},
			// Employee Pages
			{
				path: 'employees',
				element: <Outlet />,
				children: [
					{
						index: true,
						element: <EmployeeList />
					},
					{
						path: 'create',
						element: <EmployeeCreate />
					},
					{
						path: ':employee_id',
						element: <EmployeeUpdate />
					}
				]
			},
			// Member Pages
			{
				path: 'members',
				element: <Outlet />,
				children: [
					{
						index: true,
						element: <MemberList />
					},
					{
						path: 'create',
						element: <MemberCreate />
					},
					{
						path: ':member_id',
						element: <MemberUpdate />
					}
				]
			},
			// Department Pages
			{
				path: 'departments',
				element: <Outlet />,
				children: [
					{
						index: true,
						element: <DepartmentList />
					},
					{
						path: 'create',
						element: <DepartmentCreate />
					},
					{
						path: ':department_id',
						element: <DepartmentUpdate />
					}
				]
			},
			// Voucher Pages
			{
				path: 'vouchers',
				element: <Outlet />,
				children: [
					{
						index: true,
						element: <VoucherList />
					},
					{
						path: ':voucher_id',
						element: <VoucherDetails />
					}
				]
			},
			// Jobs Pages
			{
				path: jobs.path,
				element: <Outlet />,
				children: [
					{
						index: true,
						element: <JobList />
					},
					{
						path: jobs.add?.path,
						element: <JobCreate />
					},
					{
						path: ':job_id',
						element: <Outlet />,
						children: [
							{
								element: <JobUpdate />,
								children: [
									{
										index: true,
										element: <UpdateJobGeneral />
									},
									{
										path: 'general',
										element: <UpdateJobGeneral />
									},
									{
										path: 'info',
										element: <UpdateJobInfo />
									},
									{
										path: 'description',
										element: <UpdateJobDescription />
									},
									{
										path: 'contact-person',
										element: <UpdateJobContactPerson />
									}
									// {
									// 	path: `preview`,
									// 	element: (
									// 		<PublicThemeProvider>
									// 			<JobPreviewPage />
									// 		</PublicThemeProvider>
									// 	)
									// }
								]
							}
						]
					}
				]
			},
			// Requests Pages
			{
				path: 'requests',
				element: <Outlet />,
				children: [
					{
						index: true,
						element: <RequestList />
					},
					{
						path: ':request_id',
						element: <RequestDetails />
					}
				]
			},
			// Internship Participants Pages
			{
				path: jobs?.internshipParticipants?.path,
				element: <Outlet />,
				children: [
					{
						index: true,
						element: <InternshipParticipantsList />
					},
					{
						path: ':internshipParticipant_id',
						element: <InternshipParticipantsDetails />
					}
				]
			},
			// Internships Pages
			{
				path: jobs?.internships?.path,
				element: <Outlet />,
				children: [
					{
						index: true,
						element: <InternshipList />
					},
					{
						path: jobs.internships?.add?.path,
						element: <InternshipCreate />
					},
					{
						path: ':internship_id',
						element: <Outlet />,
						children: [
							{
								element: <InternshipUpdate />,
								children: [
									{
										index: true,
										element: <UpdateInternshipGeneral />
									},
									{
										path: 'general',
										element: <UpdateInternshipGeneral />
									},
									{
										path: 'info',
										element: <UpdateInternshipInfo />
									},
									{
										path: 'terms',
										element: <UpdateInternshipTerms />
									},
									{
										path: 'description',
										element: <UpdateInternshipDescription />
									},
									{
										path: 'contact-person',
										element: (
											<UpdateInternshipContactPerson />
										)
									}
									// {
									// 	path: `preview`,
									// 	element: (
									// 		<PublicThemeProvider>
									// 			<JobPreviewPage />
									// 		</PublicThemeProvider>
									// 	)
									// }
								]
							}
						]
					}
				]
			},
			// Internship Management Pages
			{
				path: jobs?.internshipManagement?.path,
				element: <Outlet />,
				children: [
					{
						index: true,
						element: <InternshipManagementList />
					}
				]
			},

			// Subscription Pages
			{
				path: 'subscriptions',
				element: <Outlet />,
				children: [
					{
						index: true,
						element: <SubscriptionList />
					},
					{
						path: 'add',
						element: <SubscriptionCreate />
					},
					{
						path: ':subscription_id',
						element: <SubscriptionDetails />
					}
				]
			},
			// Toolbox Pages
			{
				path: 'marketing',
				element: <Outlet />,
				children: [
					{
						path: 'toolbox',
						element: <Outlet />,
						children: [
							{
								index: true,
								element: <ToolboxWelcome />
							}
						]
					},
					{
						path: 'integration',
						element: <Integration />,
						children: [
							{
								index: true,
								element: <PartnerScript />
							},
							{
								path: 'partners',
								element: <PartnerScript />
							},
							{
								path: 'become-partner',
								element: <BecomePartnerScript />
							},
							{
								path: 'jobs',
								element: <JobsScript />
							},
							{
								path: 'jobbiplace-partner',
								element: <JobbiplacePartnerScript />
							}
						]
					}
				]
			},
			// Agent Plan Price Pages
			{
				path: planPrice.path,
				element: <PlanPriceManagement />,
				children: [
					{
						index: true,
						element: <AgentPlanPriceCalculation />
					},
					{
						path: planPrice.calculation.path,
						element: <AgentPlanPriceCalculation />
					},
					{
						path: planPrice.info.path,
						element: <AgentPlanPriceInfo />
					},
					{
						path: planPrice.feature.path,
						element: <AgentPlanPriceFeatures />
					},
					{
						path: planPrice.contactPerson.path,
						element: <AgentPlanPriceContactPerson />
					}
				]
			},
			// Transaction Pages
			{
				path: 'transactions',
				element: <Outlet />,
				children: [
					{
						index: true,
						element: <TransactionList />
					},
					{
						path: ':transaction_id',
						element: <TransactionDetails />
					}
				]
			},
			// Credit Pages
			{
				path: 'credits',
				element: <Outlet />,
				children: [
					{
						index: true,
						element: <CreditList />
					},
					{
						path: ':credit_id',
						element: <CreditDetails />
					}
				]
			},
			// Invoice Pages
			{
				path: 'invoices',
				element: <Outlet />,
				children: [
					{
						index: true,
						element: <InvoiceList />
					},
					{
						path: ':invoice_id',
						element: <InvoiceDetails />
					}
				]
			},
			// Payment Pages
			{
				path: 'payments',
				element: <Outlet />,
				children: [
					{
						index: true,
						element: <PaymentList />
					},
					{
						path: ':payment_id',
						element: <PaymentDetails />
					}
				]
			},
			// Checkout pages
			{
				path: 'checkout',
				element: <SubscriptionCheckout />
			},
			// Payment Result Pages
			{
				path: 'payment',
				element: <SubscriptionPaymentResult />
			},
			{
				path: 'payment-invoice',
				element: <SubscriptionPaymentInvoice />
			},
			// User Pages
			{
				path: 'users',
				element: <Outlet />,
				children: [
					{
						index: true,
						element: <UserList />
					},
					{
						path: 'create',
						element: <UserCreate />
					},
					{
						path: ':user_id',
						element: <UserUpdate />,
						children: [
							{
								index: true,
								element: <UpdateUserGeneral />
							},
							{
								path: 'general',
								element: <UpdateUserGeneral />
							},
							{
								path: 'address',
								element: <UpdateUserAddress />
							}
						]
					}
				]
			},
			// User Group Pages
			{
				path: 'groups',
				element: <Outlet />,
				children: [
					{
						index: true,
						element: <UserGroupList />
					},
					{
						path: 'create',
						element: <UserGroupCreate />
					},
					{
						path: ':group_id',
						element: <UserGroupUpdate />
					}
				]
			},
			// User Role Pages
			{
				path: 'roles',
				element: <Outlet />,
				children: [
					{
						index: true,
						element: <UserRoleList />
					},
					{
						path: 'create',
						element: <UserRoleCreate />
					},
					{
						path: ':role_id',
						element: <UserRoleUpdate />
					}
				]
			}
		],
		errorElement: <ErrorPage />
	},
	{
		element: <PublicThemeProvider />,
		children: [
			{
				path: '/my-company/homepage',
				element: <MyCompanyProfilePage />
			},
			{
				path: '/my-agent/homepage',
				element: <MyAgentProfilePage />
			},
			{
				path: `${jobs.internships.path}/:internship_id/preview`,
				element: <InternshipPreviewPage />
			},
			{
				path: `${jobs.path}/:job_id/preview`,
				element: <JobPreviewPage />
			}
		]
	}
];

export const routes = createBrowserRouter(routeList);
