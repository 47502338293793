import { getCurrentLocale } from 'Utils';
import { Colors, Translate, BadgeStatus } from 'Types';

/**
 * Retrieves the list item that matches the language code of the current locale.
 * @param list - The list of data to search through.
 * @returns The list item that matches the language code of the current locale, or undefined if not found.
 */
export const getListItemByLocale = (list: Translate[]) => {
	for (const item of list) {
		if (item.language === getCurrentLocale()?.code) {
			return item;
		}
	}
	return undefined;
};

/**
 * Converts an array of items to an array of badge status objects.
 * @param items The array of items to convert. Should have two mandatory key: id, title
 * @returns An array of badge status objects.
 */
export const convertToBadgeStatus = <
	T extends { id: string | number; title: string }
>(
	items: T[]
): BadgeStatus[] => {
	if (!items) {
		return [];
	}

	const badgeStatusOptions: BadgeStatus[] = items.map((item) => ({
		value: item.id,
		label: item.title,
		title: item.title,
		color: Colors.primary
	}));

	const allOption: BadgeStatus = {
		value: '',
		label: 'dropdown.all',
		title: 'all',
		color: Colors.primary
	};

	return [allOption, ...badgeStatusOptions];
};

/**
 * Retrieves an item from the list based on the current locale.
 * @param list - The list of items.
 * @returns The item that matches the current locale, or undefined if no match is found.
 */
export const getListByLocale = (
	list: { language: string }[]
): { language: string } | undefined => {
	return list.find((item) => item.language === getCurrentLocale()?.code);
};

/**
 * Inserts items at a specific index in an array.
 *
 * @template T The type of the array elements.
 * @param currentArray The original array.
 * @param index The index at which to insert the items.
 * @param newArray The items to insert.
 * @returns A new array with the items inserted at the specified index.
 */
export function insertItemInList<T>(
	currentArray: T[],
	index: number,
	newArray: T[]
): T[] {
	const result = new Array<T>(currentArray.length + newArray.length);
	let i = 0;
	// copy elements before the specified index
	while (i < index) {
		result[i] = currentArray[i];
		i++;
	}
	// copy inserted items
	let j = 0;
	while (j < newArray.length) {
		result[i] = newArray[j];
		i++;
		j++;
	}
	// copy remaining elements
	while (i < result.length) {
		result[i] = currentArray[i - newArray.length];
		i++;
	}
	return result;
}

/**
 * Sorts an array of items based on a string property.
 *
 * @template T The type of the array elements.
 * @template K The type of the property to sort by.
 * @param array The array of items to sort.
 * @param property The property to sort by.
 * @returns The sorted array.
 */
export const sortArrayByProperty = <T, K extends keyof T>(
	array: T[],
	property: K
): T[] => {
	return array.slice().sort((a, b) => {
		const valueA = String(a[property]);
		const valueB = String(b[property]);
		return valueA.localeCompare(valueB);
	});
};
