import { useTranslation } from 'react-i18next';
import { FieldValues, UseControllerProps } from 'react-hook-form';
import { clx } from 'Utils';
import { useModal } from 'Hooks';
import { AspectRatio, Colors, Media } from 'Types';
import { Button, FontAwesome, ModalBoxChangeImage } from 'Elements';
import styles from 'partials/control/avatar-controller.module.scss';

type Props<T extends FieldValues> = {
	className?: string;
	image?: string;
	aspectRatio?: AspectRatio;
	onRemove?: (defaultImage: { url: string; thumbnailUrl: string }) => void;
	onUpload: (image_data: Media) => void;
	defaultImage?: string;
} & UseControllerProps<T>;

export const AvatarController = <T extends FieldValues>({
	image,
	aspectRatio,
	name,
	control,
	className,
	onUpload,
	onRemove,
	defaultImage
}: Props<T>): JSX.Element => {
	const { t } = useTranslation();
	const [openModal, toggleModal] = useModal();
	const defaultImg =
		defaultImage || '/assets/img/profiles/img-user-default.png';
	const handleRemove = () => {
		const defaultImage = {
			url: '',
			thumbnailUrl: ''
		};
		onRemove?.(defaultImage);
	};
	return (
		<div className={clx(styles.control, className)}>
			<div role="img" className={styles.avatarBox} onClick={toggleModal}>
				{image && (
					<img
						src={image}
						alt={name ?? ''}
						className={styles.avatar}
					/>
				)}
				{!image && (
					<div className={styles.box}>
						<span className={styles.add}>
							<FontAwesome icon="plus" size="lg" swapOpacity />
						</span>
						<span className="mt-3 fw-500 text-primary">
							{t('button.add-new-image')}
						</span>
					</div>
				)}
			</div>
			{image && (
				<Button
					color={Colors['white-primary']}
					className={styles.edit}
					onClick={toggleModal}>
					<FontAwesome icon="pen-to-square" size="xl" />
				</Button>
			)}
			<ModalBoxChangeImage
				title="title.change-image"
				isOpen={openModal}
				image={image || ''}
				name={name}
				aspectRatio={aspectRatio}
				control={control}
				defaultImage={defaultImg}
				onClose={toggleModal}
				onImageChange={onUpload}
				onRemove={handleRemove}
			/>
		</div>
	);
};
// );

AvatarController.displayName = 'AvatarController';
