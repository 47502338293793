import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import { ToastOptions, TypeOptions } from 'react-toastify';
import { FontAwesome } from 'Elements';

interface Props extends ToastOptions {
	message: string;
	onCloseToast?: () => void;
}

type IconType = {
	[x in TypeOptions]: any;
};

export const ToastifyMessage: FC<Props> = memo(
	({ message, onCloseToast, ...props }) => {
		const { t } = useTranslation();
		const getIconByType = () => {
			const types: IconType = {
				error: 'circle-exclamation',
				success: 'circle-check',
				warning: 'circle-exclamation',
				info: 'circle-info',
				default: 'circle-exclamation'
			};
			return (
				<FontAwesome
					icon={types[props.type ?? 'error']}
					size="xl"
					className="me-2"
				/>
			);
		};

		return (
			<Toast>
				<ToastHeader
					icon={getIconByType()}
					close={
						props.closeOnClick && (
							<FontAwesome
								icon='xmark'
								size="lg"
								swapOpacity
								className="ms-auto"
								onClick={onCloseToast}
							/>
						)
					}>
					{t(`toastify.${props.type ?? 'error'}`)}
				</ToastHeader>
				<ToastBody>{t(message)}</ToastBody>
			</Toast>
		);
	}
);

ToastifyMessage.displayName = 'ToastifyMessage';
