import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Colors } from 'Types';
import { Button, FontAwesome } from 'Elements';
import styles from 'partials/modal/modalbox.module.scss';

interface Props {
	title?: string;
	onClose?: () => void;
}

export const ModalBoxHeader: FC<Props> = memo(({ title, onClose }) => {
	const { t } = useTranslation();
	return (
		<header className={styles.header}>
			{title && (
				<h4 className="fs-6 fw-400 mb-0 me-2 flex-grow-1">
					{t(title)}
				</h4>
			)}
			{onClose && (
				<Button
					color={Colors.empty}
					className={styles.close}
					onClick={onClose}>
					<FontAwesome
						icon="xmark"
						swapOpacity
						strokeWidth={1}
						size="xl"
					/>
				</Button>
			)}
		</header>
	);
});

ModalBoxHeader.displayName = 'ModalBoxHeader';
