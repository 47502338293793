import { FC, memo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { clx } from 'Utils';
import { useTheme } from 'Hooks';
import { UserCategory } from 'Types';
import { FontAwesome, ShimmerImage } from 'Elements';
import styles from 'partials/job/preview/job-contact-person-section.module.scss';

interface Props {
	name: string;
	image?: string;
	tel: string;
	email: string;
	userType: UserCategory;
}

const Box = styled.div<{ $userType: UserCategory }>`
	border-color: ${({ theme, $userType }) => theme[$userType].primaryColor};
`;

export const InternshipContactPersonSection: FC<Props> = memo(
	({ name, image, tel, email, userType }) => {
		const { t } = useTranslation();
		const { theme } = useTheme();
		const defaultImage = '/assets/img/profiles/img-user-default.png';

		return (
			<div className="my-4">
				<h4 className="fs-5 fw-700 mb-3 text-center lh-base">
					{t('title.internship.internship-contact-person')}
				</h4>
				<Box
					$userType={userType}
					className={clx(
						styles.box,
						'd-flex align-items-center justify-content-center gap-3 px-3 py-4 flex-wrap'
					)}>
					<div className={styles.imageWrapper}>
						<ShimmerImage
							src={image || defaultImage}
							alt={name}
							defaultImage={defaultImage}
							className={styles.image}
							width={96}
							height={96}
						/>
					</div>
					<div className="d-flex flex-column justify-content-center gap-2">
						<h6 className="fs-base lh-base fw-500 mb-0">{name}</h6>
						{email && (
							<div className="d-flex align-items-center lh-base gap-3">
								<FontAwesome
									icon="envelope"
									size="lg"
									color={theme[userType].primaryColor}
								/>
								<a
									href={`mailto:${email}`}
									className="text-break fs-sm fw-300">
									{email}
								</a>
							</div>
						)}
						{tel && (
							<div className="d-flex align-items-center lh-base gap-3">
								<FontAwesome
									icon="phone"
									size="lg"
									color={theme[userType].primaryColor}
								/>
								<a href={`tel:${tel}`} className="fs-sm fw-300">
									{tel}
								</a>
							</div>
						)}
					</div>
				</Box>
			</div>
		);
	}
);
InternshipContactPersonSection.displayName = 'InternshipContactPersonSection';
