import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { clx } from 'Utils';
import { Editor, FontAwesome } from 'Elements';

interface Props {
	icon: any;
	title: string;
	desc: string;
	fill: string;
	className?: string;
	onUpdate: (val: string) => void;
}

export const ProfileStatisticCard: FC<Props> = memo(
	({ icon, title, desc, fill, className, onUpdate }) => {
		const { t } = useTranslation();
		return (
			<div
				className={clx(
					'd-flex flex-column align-items-center justify-content-center text-center gap-2',
					className
				)}>
				<FontAwesome icon={icon} size="3x" color={fill} />
				<h5 className="h6 lh-base mb-0 mt-2 text-gray-5">{t(title)}</h5>
				<div className="text-break lh-base w-100 fs-base">
					<Editor
						type="text"
						value={desc || `[${t(title)}]`}
						onSave={onUpdate}
					/>
				</div>
			</div>
		);
	}
);
ProfileStatisticCard.displayName = 'ProfileStatisticCard';
