import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useCompanyTransactions } from 'Hooks';
import { motion } from 'framer-motion';
import { containerVariants } from 'Constants';
import { BadgeStatus, Colors, TransactionStatus, TransactionType } from 'Types';
import {
	currencyFormatter,
	formatLocaleDateTime,
	companySettlementStatus,
	transactionType,
	unslug
} from 'Utils';
import {
	DataTable,
	DataTableBody,
	DataTableHead,
	DataTableLoading,
	DataTableRow,
	DataTableRowCell,
	NoData,
	Pagination,
	ListingPage,
	Button,
	PaymentBadgeStatus,
	EndOfList,
	SystemErrorAlert,
	FontAwesome
} from 'Elements';
import styles from 'partials/page/transaction.module.scss';

export const CompanyListTransaction = memo(() => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const params = Object.fromEntries([...searchParams]);
	const pageParam = searchParams.get('page') || 1;
	const queryParam = searchParams.get('q') || '';
	const statusParam = unslug(searchParams.get('status') ?? '') ?? '';

	const [state, setState] = useState({
		pageSize: 20,
		totalItems: 0,
		currentPage: pageParam,
		keyword: queryParam,
		filterBy: statusParam
	});

	const getFilterByName = (filter_name: string) => {
		return companySettlementStatus.find(
			(filter) => filter.title?.toLowerCase() === filter_name?.toLowerCase()
		);
	};

	const getTypeByValue = (filter_name: TransactionType) => {
		return transactionType.find((filter) => filter.value === filter_name);
	};

	const { data, isError, isFetching } = useCompanyTransactions(
		{
			status: getFilterByName(statusParam)?.value,
			pageSize: state.pageSize,
			pageNumber: state.currentPage,
			keyword: state.keyword
		},
		{
			onSuccess: (data) => {
				setState((prev) => ({
					...prev,
					totalItems: data.totalItems
				}));
			}
		}
	);

	const onChangePageSize = (pageSize: number) => {
		setSearchParams({ ...params, page: '1' });
		setState((prev) => ({
			...prev,
			pageSize,
			currentPage: 1
		}));
	};

	const handleSearch = (query: string) => {
		setSearchParams({
			...params,
			page: '1',
			q: query
		});
		setState((prev) => ({
			...prev,
			currentPage: 1,
			keyword: query
		}));
	};

	const handleFilterBy = (selected_filter: Partial<BadgeStatus>) => {
		setSearchParams({
			...params,
			page: '1',
			status: selected_filter.title?.toString() ?? ''
		});
		setState((prev) => ({
			...prev,
			filterBy: selected_filter.label?.toString() ?? '',
			currentPage: 1
		}));
	};

	const handlePageChange = (page: number) => {
		setSearchParams({ ...params, page: page.toString() });
		setState((prev) => ({
			...prev,
			currentPage: page
		}));
	};

	const redirectTo = (id: string) => {
		return navigate(`/transactions/${id}`, {
			state: { prevPath: '/transactions' }
		});
	};

	return (
		<ListingPage
			totalItems={data?.totalItems}
			pageSizes={[10, 15, 20, 50]}
			selectedPageSize={state.pageSize}
			onPageSizeChange={onChangePageSize}
			filterOptions={companySettlementStatus}
			selectedFilter={t(
				getFilterByName(statusParam)?.label ?? 'dropdown.all'
			)}
			onFilterChange={handleFilterBy}
			onSearchChange={handleSearch}>
			<DataTable>
				{state?.totalItems > 0 && (
					<DataTableHead>
						<DataTableRowCell
							cellText="table.transaction-number"
							cellClassName="w-100 w-sm-15"
						/>
						<DataTableRowCell
							cellText="table.product"
							cellClassName="w-100 w-sm-15"
						/>
						<DataTableRowCell
							cellText="table.created-at"
							cellClassName="w-100 w-sm-15"
						/>
						<DataTableRowCell
							cellText="table.type"
							cellClassName="w-100 w-sm-10"
						/>
						<DataTableRowCell
							cellText="table.amount"
							cellClassName="w-100 w-sm-15"
						/>
						<DataTableRowCell
							cellText="table.status"
							cellClassName="w-100 w-sm-10"
						/>
						<DataTableRowCell
							cellText="table.operation"
							cellClassName="w-100 w-sm-20"
						/>
					</DataTableHead>
				)}
				<DataTableBody>
					{!isFetching &&
						<motion.div
							variants={containerVariants}
							initial="hidden"
							animate="show">
							{data?.items?.map((transaction) => {
								return (
									<DataTableRow key={transaction.id}>
										<DataTableRowCell
											cellClassName="w-100 w-sm-15"
											cellHead="table.transaction-number"
											cellText={transaction.transactionCode}
										/>
										<DataTableRowCell
											cellClassName="w-100 w-sm-15 fw-500"
											cellHead="table.product"
											cellText={
												transaction.productInfo.name ??
												'---'
											}
										/>
										<DataTableRowCell
											cellClassName="w-100 w-sm-15"
											cellHead="table.created-at">
											<time
												title={formatLocaleDateTime(
													transaction.createdAt
												)}
												dateTime={formatLocaleDateTime(
													transaction.createdAt
												)}>
												{formatLocaleDateTime(
													transaction.createdAt
												)}
											</time>
										</DataTableRowCell>
										<DataTableRowCell
											cellClassName="w-100 w-sm-10"
											cellHead="table.type"
											cellText={
												getTypeByValue(transaction.type)
													?.label ?? '---'
											}
										/>
										<DataTableRowCell
											cellClassName="w-100 w-sm-15"
											cellHead="table.amount">
											<div className="d-flex flex-column text-end text-sm-start">
												<strong
													className="fs-base"
													title={currencyFormatter(
														transaction.totalAmount
													)}>
													{currencyFormatter(
														transaction.totalAmount
													)}
												</strong>
												<span className="text-gray-3 lh-base fs-small">
													{t('label.tax-included')}
												</span>
											</div>
										</DataTableRowCell>
										<DataTableRowCell
											cellClassName="w-100 w-sm-10"
											cellHead="table.status">
											<PaymentBadgeStatus
												list={[TransactionStatus.Done]}
												title={transaction.status}
												color={Colors.primary}
											/>
											<PaymentBadgeStatus
												list={[TransactionStatus.UnPaid]}
												title={transaction.status}
												color={Colors.muted}
											/>
											<PaymentBadgeStatus
												list={[TransactionStatus.Donated]}
												title={transaction.status}
												color={Colors.tertiary}
											/>
											<PaymentBadgeStatus
												list={[TransactionStatus.Pending]}
												title={transaction.status}
												color={Colors.warning}
											/>
											<PaymentBadgeStatus
												list={[TransactionStatus.Paid]}
												title={transaction.status}
												color={Colors.success}
											/>
											<PaymentBadgeStatus
												list={[TransactionStatus.Failed]}
												title={transaction.status}
												color={Colors.error}
											/>
										</DataTableRowCell>
										<DataTableRowCell
											cellHead="table.operation"
											cellClassName="w-100 w-sm-20"
											boxClassName="d-flex flex-wrap gap-2">
											<Button
												size="sm"
												color={Colors['white-primary']}
												label={
													t('button.details') as string
												}
												onClick={() =>
													redirectTo(transaction.id)
												}
											/>
											{transaction?.downloadUrl && (
												<a
													href={transaction?.downloadUrl}
													rel="noreferrer"
													target="_blank"
													className={styles.button}
													download={`jobbiplace-invoice_${transaction?.transactionCode}.pdf`}>
													<FontAwesome
														icon='download'
														size="lg"
													/>
												</a>
											)}
										</DataTableRowCell>
									</DataTableRow>
								);
							})}
						</motion.div>}
					{isFetching && (
						<DataTableLoading
							widths={[15, 15, 15, 10, 15, 15, 15]}
							count={state.pageSize}
						/>
					)}
				</DataTableBody>
				{isError && <SystemErrorAlert />}
				{!isFetching && data?.totalItems === 0 && (
					<NoData message="title.no-transaction" />
				)}
			</DataTable>
			{!isFetching && <EndOfList data={data} />}
			{!isFetching && data && data?.totalPages > 1 && (
				<Pagination
					className="mt-3"
					totalItemsCount={data?.totalItems}
					activePage={parseInt(state.currentPage.toString(), 10)}
					itemsCountPerPage={state.pageSize}
					onChange={handlePageChange}
				/>
			)}
		</ListingPage>
	);
});

CompanyListTransaction.displayName = 'CompanyListTransaction';
