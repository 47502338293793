import { memo, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useUpdateInternship } from 'Hooks';
import { ColorValues, Colors, ModalProps } from 'Types';
import {
	Button,
	ModalBox,
	ModalBoxFooter,
	SubmitButton,
	FontAwesome
} from 'Elements';

export const ModalBoxUnPublishInternship: FC<ModalProps> = memo(
	({ isOpen, onClose }) => {
		const { t } = useTranslation();
		const { internship_id } = useParams() as { internship_id: string };
		const { refetch, unPublishInternship } = useUpdateInternship(internship_id);
		const [unPublishing, setUnPublish] = useState(false);

		const handleUnPublishInternship = async () => {
			try {
				setUnPublish(true);
				await unPublishInternship(internship_id, refetch);
				onClose();
			} finally {
				setUnPublish(false);
			}
		};

		return (
			<ModalBox isOpen={isOpen} onClose={onClose}>
				<header className="d-flex flex-column align-items-center text-center">
					<FontAwesome
						icon="triangle-exclamation"
						size="8x"
						color={ColorValues.red}
						className="mb-4"
					/>
					<h4 className="h6 lh-base">
						{t('text.are-you-sure-to-unPublish-your-internship')}
					</h4>
					<p className="text-gray-3 lh-md">
						{t('text.internship-unPublish-sub-text')}
					</p>
				</header>
				<ModalBoxFooter className="justify-content-between pt-3">
					<Button color={Colors['white-gray']} onClick={onClose}>
						{t('button.cancel')}
					</Button>
					<SubmitButton
						icon={false}
						color="white-red"
						isSubmitting={unPublishing}
						label="button.unPublish"
						savingLabel="button.unPublishing"
						onClick={handleUnPublishInternship}
					/>
				</ModalBoxFooter>
			</ModalBox>
		);
	}
);

ModalBoxUnPublishInternship.displayName = 'ModalBoxUnPublishInternship';
