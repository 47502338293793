import { memo, Suspense } from 'react';
import { Col, Row } from 'reactstrap';
import { Outlet } from 'react-router-dom';
import { IntegrationAppDecorationBox, LoadingContent } from 'Elements';

export const UpdateIntegration = memo(() => {
	return (
		<Row>
			<Col xs={12} className="mb-4">
				<IntegrationAppDecorationBox />
			</Col>
			<Col xs={12}>
				<Suspense fallback={<LoadingContent />}>
					<Outlet />
				</Suspense>
			</Col>
		</Row>
	);
});

UpdateIntegration.displayName = 'UpdateIntegration';
