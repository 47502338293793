import { memo, FC } from 'react';
import { Container } from 'reactstrap';
import styled from 'styled-components';
import { CompanyInternshipSection } from 'Elements';
import styles from 'partials/homepage/section/profile-job-section.module.scss';

interface Props {
	type: 'agent' | 'company';
	internshipSectionStatus: boolean;
}

const Decoration = styled.div<{ profileType: 'agent' | 'company' }>`
	${({ theme, profileType }) =>
		theme.company.primaryColor &&
		`&:after {
            background-color: ${theme[profileType].primaryColor}
        }`}
`;

export const ProfileInternshipSection: FC<Props> = memo(
	({ type, internshipSectionStatus }) => {
		return (
			<section className={styles.wrapper}>
				<Decoration profileType={type} className={styles.decoration}>
					<img
						src="/assets/img/job/job-bg.webp"
						alt=""
						className="w-100"
					/>
				</Decoration>
				<Container>
					{type === 'company' && <CompanyInternshipSection />}
				</Container>
				{!internshipSectionStatus && (
					<div className="overlay overlay_white overlay-box" />
				)}
			</section>
		);
	}
);

ProfileInternshipSection.displayName = 'ProfileInternshipSection';
