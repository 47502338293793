import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { AgentAboCalculator, Card } from 'Elements';
import { Col, Row } from 'reactstrap';
import { useAgentProfile } from 'Hooks';

export const AgentEarningCalculator: FC = memo(() => {
	const { t } = useTranslation();
	const { data: agentProfile, isLoading } = useAgentProfile();
	return (
		<Card>
			<Row>
				<Col md={6}>
					<div className="border-sm-right border-md-right-none border-lg-right border-gray-1 pe-md-4 pe-lg-0 pe-xl-4 pb-4 pb-md-0 pb-lg-4 pb-xl-0">
						<h5 className="fs-6 fw-500 mb-3 lh-base">
							{t('title.abo-calculator-title')}
						</h5>
						<p className="lh-md pre-line">
							{t('text.abo-calculator-description')}
						</p>
						<div className="d-flex flex-column gap-2 mb-3">
							<div className="d-flex align-items-center gap-2 lh-base">
								<span>
									{t(
										'label.plan-price.your-commission-group'
									)}
									:
								</span>
								<span className="fw-500 text-primary">
									{isLoading
										? '...'
										: agentProfile?.commissionGroup?.label}
								</span>
							</div>
							<div className="d-flex align-items-center gap-2 lh-base">
								<span>
									{t('label.plan-price.commission-percent')}:
								</span>
								<span className="fw-500 text-gray-3">
									{isLoading
										? '...'
										: `${agentProfile?.agentCommissionLevel?.prov}%`}
								</span>
							</div>
						</div>
					</div>
				</Col>
				<Col md={6}>
					<AgentAboCalculator />
				</Col>
			</Row>
		</Card>
	);
});

AgentEarningCalculator.displayName = 'AgentEarningCalculator';
