import { FC, memo } from 'react';
import { ColorValues } from 'Types';

interface Props {
	fill?: string;
	className?: string;
	size?: number;
}

export const IconFlyer: FC<Props> = memo(
	({
		fill = ColorValues.primary,
		className,
		size = 100,
		...props
	}): JSX.Element => {
		const tertiary_light_class = 'tertiary-light';
		return (
			<svg
				width={size}
				height={size}
				viewBox="0 0 100 100"
				xmlns="http://www.w3.org/2000/svg"
				className={className}
				{...props}>
				<g transform="translate(-50.081 -80.141)">
					<rect
						width="100"
						height="100"
						transform="translate(50.081 80.141)"
						fill="#fff"
						opacity="0"
					/>
					<path
						d="M61.081,91.142l25.8,13.138v65.314l-25.8-13.138Z"
						transform="translate(0 0)"
						fill={ColorValues['primary-light']}
					/>
					<path
						d="M63.619,97.372l12.451,6.34"
						transform="translate(4.136 9.277)"
						fill="none"
						stroke={ColorValues[tertiary_light_class]}
						strokeLinecap="round"
						strokeWidth="2"
					/>
					<path
						d="M63.619,100.97l12.451,6.34"
						transform="translate(4.136 13.68)"
						fill="none"
						stroke={ColorValues[tertiary_light_class]}
						strokeLinecap="round"
						strokeWidth="2"
					/>
					<path
						d="M63.619,104.567l12.451,6.34"
						transform="translate(4.136 18.083)"
						fill="none"
						stroke={ColorValues[tertiary_light_class]}
						strokeLinecap="round"
						strokeWidth="2"
					/>
					<path
						d="M63.619,108.165l12.451,6.34"
						transform="translate(4.136 22.485)"
						fill="none"
						stroke={ColorValues[tertiary_light_class]}
						strokeLinecap="round"
						strokeWidth="2"
					/>
					<path
						d="M63.619,111.633l6.505,3.312"
						transform="translate(4.136 26.017)"
						fill="none"
						stroke={ColorValues[tertiary_light_class]}
						strokeLinecap="round"
						strokeWidth="2"
					/>
					<path
						d="M99.459,91.141l-25.8,13.138v65.314l25.8-13.138Z"
						transform="translate(13.224 0)"
						fill={fill}
					/>
					<path
						d="M87.768,97.372,76.2,103.264"
						transform="translate(17.799 7.522)"
						fill="none"
						stroke={ColorValues[tertiary_light_class]}
						strokeLinecap="round"
						strokeWidth="2"
					/>
					<path
						d="M89.942,100.718l-13.556,6.9v31.97l13.556-6.9Z"
						transform="translate(16.812 12.135)"
						fill="#fff"
					/>
					<path
						d="M86.236,91.142l25.8,13.138v65.314l-25.8-13.138Z"
						transform="translate(26.447 0.001)"
						fill={ColorValues['primary-light']}
					/>
					<path
						d="M88.774,105.525l13.537,6.893"
						transform="translate(30.04 17.125)"
						fill="none"
						stroke={ColorValues[tertiary_light_class]}
						strokeLinecap="round"
						strokeWidth="2"
					/>
					<path
						d="M88.774,109.122l13.537,6.893"
						transform="translate(30.04 19.528)"
						fill="none"
						stroke={ColorValues[tertiary_light_class]}
						strokeLinecap="round"
						strokeWidth="2"
					/>
					<path
						d="M88.774,112.72l13.537,6.893"
						transform="translate(30.04 22.93)"
						fill="none"
						stroke={ColorValues[tertiary_light_class]}
						strokeLinecap="round"
						strokeWidth="2"
					/>
					<path
						d="M88.774,116.317l13.537,6.893"
						transform="translate(30.04 25.333)"
						fill="none"
						stroke={ColorValues[tertiary_light_class]}
						strokeLinecap="round"
						strokeWidth="2"
					/>
					<path
						d="M88.774,119.785l7.073,3.6"
						transform="translate(30.04 28.865)"
						fill="none"
						stroke={ColorValues[tertiary_light_class]}
						strokeLinecap="round"
						strokeWidth="2"
					/>
					<path
						d="M88.869,97.421l15,7.636V115.08l-15-7.636Z"
						transform="translate(29.216 6.602)"
						fill="#fff"
					/>
				</g>
			</svg>
		);
	}
);

IconFlyer.displayName = 'IconFlyer';
