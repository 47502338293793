import { FC, memo } from 'react';
import { ColorValues } from 'Types';

interface Props {
	fill?: string;
	className?: string;
	size?: number;
}

export const IconPoster: FC<Props> = memo(
	({
		fill = ColorValues.primary,
		className,
		size = 100,
		...props
	}): JSX.Element => {
		return (
			<svg
				width={size}
				height={size}
				viewBox="0 0 100 100"
				xmlns="http://www.w3.org/2000/svg"
				className={className}
				{...props}>
				<g transform="translate(-648 -546.5)">
					<rect
						width="100"
						height="100"
						transform="translate(648 546.5)"
						fill="#fff"
						opacity="0"
					/>
					<rect
						width="66.71"
						height="72.414"
						transform="translate(665 564.874)"
						fill={ColorValues['primary-light']}
					/>
					<g transform="translate(673.49 548.5)">
						<g transform="translate(38.813)">
							<g
								transform="translate(0 13.949)"
								fill={fill}
								stroke={fill}
								strokeLinecap="round"
								strokeWidth="2">
								<rect
									width="10.916"
									height="6.065"
									stroke="none"
								/>
								<rect
									x="1"
									y="1"
									width="8.916"
									height="4.065"
									fill="none"
								/>
							</g>
							<line
								y1="14.555"
								transform="translate(5.458 0)"
								fill="none"
								stroke={fill}
								strokeLinecap="round"
								strokeWidth="2"
							/>
						</g>
						<g
							transform="translate(0 13.949)"
							fill={fill}
							stroke={fill}
							strokeLinecap="round"
							strokeWidth="2">
							<rect width="10.916" height="6.065" stroke="none" />
							<rect
								x="1"
								y="1"
								width="8.916"
								height="4.065"
								fill="none"
							/>
						</g>
						<line
							y1="14.555"
							transform="translate(5.458 0)"
							fill="none"
							stroke={fill}
							strokeLinecap="round"
							strokeWidth="2"
						/>
					</g>
					<path
						d="M0,0H40.026V29.11H0Z"
						transform="translate(678.342 578.5)"
						fill="#fff"
					/>
					<line
						x2="20.62"
						transform="translate(688.652 615)"
						fill="none"
						stroke={ColorValues['tertiary-light']}
						strokeLinecap="round"
						strokeWidth="2"
					/>
					<line
						x2="30.323"
						transform="translate(683.8 622)"
						fill="none"
						stroke={ColorValues['tertiary-light']}
						strokeLinecap="round"
						strokeWidth="2"
					/>
				</g>
			</svg>
		);
	}
);

IconPoster.displayName = 'IconPoster';
