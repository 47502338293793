import { FC, memo, useEffect, useMemo, useRef, useState } from 'react';
import { Container } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Colors } from 'Types';
import { api, calculateBrightness, clx, toastify } from 'Utils';
import {
	Button,
	ColorPickerPopover,
	FontAwesome,
	ModalBoxPublish
} from 'Elements';
import {
	useOutsideClick,
	useResponsive,
	useScrollContext,
	useAgentProfile,
	useCompanyProfile,
	useTheme,
	useAxios,
	useModal
} from 'Hooks';
import styles from 'partials/homepage/home-header.module.scss';

interface Props {
	previewUrl: string;
	type: 'company' | 'agent';
}

export const HomepageHeader: FC<Props> = memo(({ previewUrl, type }) => {
	const { theme } = useTheme();
	const { t } = useTranslation();
	const { colorType } = useScrollContext();
	const { isMobileAndBelow } = useResponsive();
	const popoverRef = useRef<HTMLDivElement>(null);
	const [openModalPublish, toggleModalPublish] = useModal();
	const { sendRequest, isLoading: submittingColor } = useAxios();
	const [openColorPicker, toggleColorPicker] = useState(false);
	const [color, setColor] = useState(theme[type].primaryColor);

	const getBrightness = useMemo(() => {
		return calculateBrightness(color);
	}, [color]);

	const { data: company, refetch: refetchCompany } = useCompanyProfile({
		enabled: type === 'company'
	});
	const { data: agent, refetch: refetchAgent } = useAgentProfile({
		enabled: type === 'agent'
	});

	const isPublished =
		(type === 'company' ? company?.isActive : agent?.isActive) ?? false;

	const togglePicker = () => {
		toggleColorPicker(!openColorPicker);
	};

	const submitColor = async (color: string) => {
		const apiUrl =
			type === 'company'
				? api.updateCompanySetting
				: api.updateAgentSetting;
		const data = {
			...(type === 'company' && {
				companySetting: {
					...company?.companySetting,
					secondaryColor: color
				}
			}),
			...(type === 'agent' && {
				agentSetting: {
					...agent?.agentSetting,
					headerBgColor: color
				}
			})
		};
		await sendRequest(apiUrl, { method: 'PATCH', data }, () => {
			if (type === 'company') {
				refetchCompany();
				toastify('toastify.company-updated', {
					type: 'success'
				});
			} else {
				refetchAgent();
				toastify('toastify.agent-updated', {
					type: 'success'
				});
			}
			togglePicker();
		});
	};

	const onSubmitColor = async (color: string) => {
		// setSubmittingColor(true);
		await submitColor(color);
	};

	useOutsideClick(popoverRef, togglePicker);

	const onPreviewPage = () => {
		window.open(previewUrl, '_blank', 'noopener,noreferrer');
	};

	useEffect(() => {
		// Set selected color on get new data
		if (company?.companySetting) {
			setColor(company?.companySetting.secondaryColor);
		}
		if (agent?.agentSetting) {
			setColor(agent?.agentSetting.headerBgColor);
		}
	}, [company?.companySetting, agent?.agentSetting]);

	return (
		<header className={styles.header}>
			<Container fluid className={clx(styles.container, 'container-lg')}>
				<nav className={styles.navbar}>
					<h6 className="fs-6 fw-400 mb-0">
						<Link
							to="/"
							className="d-flex align-items-center back-button">
							<FontAwesome
								icon="arrow-left"
								size={isMobileAndBelow ? 'lg' : '1x'}
								className="me-3 back-icon"
							/>
							{!isMobileAndBelow && t('title.back-to-profile')}
						</Link>
					</h6>
					<div className="d-flex align-items-center justify-content-end flex-grow-1 gap-2">
						<div className="position-relative">
							<Button
								size={isMobileAndBelow ? 'sm' : 'md'}
								color={
									colorType === 'colorize'
										? Colors['border-primary']
										: Colors['border-gray-3']
								}
								className={clx(
									styles.actionButton,
									styles.colorPicker,
									'gap-2'
								)}
								onClick={togglePicker}>
								<span
									style={{
										backgroundColor:
											color || theme[type].primaryColor
									}}
									className={styles.theme_color}>
									<FontAwesome
										icon="eye-dropper"
										size="1x"
										className={
											getBrightness <= 155
												? 'text-gray-1'
												: 'text-gray-4'
										}
									/>
								</span>
								{t('button.theme-color')}
							</Button>
							{openColorPicker && (
								<ColorPickerPopover
									ref={popoverRef}
									color={color}
									onChange={setColor}
									onSubmit={onSubmitColor}
									isSubmitting={submittingColor}
									onClose={togglePicker}
								/>
							)}
						</div>
						<Button
							disabled={!previewUrl}
							color={
								isPublished
									? Colors['white-red']
									: Colors.primary
							}
							size={isMobileAndBelow ? 'sm' : 'md'}
							className={clx(styles.actionButton, 'gap-2')}
							onClick={toggleModalPublish}>
							<FontAwesome
								icon={
									isPublished
										? 'triangle-exclamation'
										: 'upload'
								}
								size="lg"
								className="flex-shrink-0"
							/>
							{t(
								isPublished
									? 'button.unPublish'
									: 'button.publish'
							)}
						</Button>
						<Button
							disabled={!previewUrl}
							color={Colors.secondary}
							size={isMobileAndBelow ? 'sm' : 'md'}
							className={clx(styles.actionButton, 'gap-2')}
							onClick={onPreviewPage}>
							<FontAwesome icon="eye" size="lg" />
							{t('button.preview')}
						</Button>
					</div>
				</nav>
			</Container>
			<ModalBoxPublish
				userType={type}
				type={isPublished ? 'unPublish' : 'publish'}
				isPublished={isPublished}
				isOpen={openModalPublish}
				onClose={toggleModalPublish}
			/>
		</header>
	);
});

HomepageHeader.displayName = 'HomepageHeader';
