import { memo, FC, useState } from 'react';
import axios from 'Adapter';
import { useOutletContext, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { api } from 'Utils';
import { useEventAllInvitations, useParticipant } from 'Hooks';
import { AgentEvent, EventParticipant, EventStatus } from 'Types';
import {
	Card,
	NoData,
	PageHeadingSearch,
	Pagination,
	Scrollbar,
	EventParticipantCard,
	EventInvitedCardLoading
} from 'Elements';
import styles from 'partials/page/event.module.scss';

export const EventVisitorParticipantList: FC = memo(() => {
	const { event_id } = useParams();
	const { t } = useTranslation();
	const fields = useOutletContext<AgentEvent>();

	const isEventFinishOrCancel =
		fields?.isFinished || fields?.eventStatus === EventStatus.Canceled;

	const [state, setState] = useState({
		pageSize: 10,
		currentPage: 1,
		keyword: ''
	});

	const { refetch: refetchInvitation } = useEventAllInvitations({
		eventId: event_id,
		isPartner: false
	});

	const {
		data: list,
		isFetching,
		refetch
	} = useParticipant({
		eventId: event_id,
		isPartner: false,
		status: '',
		pageSize: state.pageSize,
		pageNumber: state.currentPage,
		keyword: state.keyword
	});

	const handleSearch = (query: string) => {
		setState((prev) => ({
			...prev,
			currentPage: 1,
			keyword: query
		}));
	};

	const handlePageChange = (page: number) => {
		setState((prev) => ({
			...prev,
			currentPage: page
		}));
	};

	const handleCancelInvite = (participant: EventParticipant) => {
		axios
			.post(api.cancelInvitation(event_id as string, participant.id))
			.then((res) => {
				if (res.status === 200) {
					refetch();
					refetchInvitation();
				}
			});
	};

	return (
		<Card className={styles.card}>
			<h3 className="text-gray-3 fs-6 fw-400 mb-3">
				{t('title.participant-list')}
			</h3>
			<PageHeadingSearch onSubmit={handleSearch} className="mb-3" />
			{!isFetching && list && (
				<Scrollbar className={styles.scrollbar}>
					<div>
						{list.items.map((participant) => {
							return (
								<EventParticipantCard
									key={participant.id}
									card={participant}
									cancelable={
										!isEventFinishOrCancel &&
										participant.status !== 1
									}
									onCancel={() =>
										handleCancelInvite(participant)
									}
								/>
							);
						})}
						{isFetching && <EventInvitedCardLoading />}
						{!isFetching && list?.items?.length === 0 && (
							<NoData message="title.no-participant" />
						)}
					</div>
				</Scrollbar>
			)}
			{!isFetching && list && list?.totalPages > 1 && (
				<Pagination
					className="mt-3"
					totalItemsCount={list.totalItems}
					activePage={parseInt(state.currentPage.toString(), 10)}
					itemsCountPerPage={state.pageSize}
					onChange={handlePageChange}
				/>
			)}
		</Card>
	);
});

EventVisitorParticipantList.displayName = 'EventVisitorParticipantList';
