import { memo, FC, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AgentEvent, EventStatus } from 'Types';
import { useEventAllInvitations } from 'Hooks';
import {
	NoData,
	PageHeadingSearch,
	Card,
	Scrollbar,
	EventInvitedCard,
	EventInvitedCardLoading,
	InviteGuestBar
} from 'Elements';
import styles from 'partials/page/event.module.scss';

export const EventVisitorInvitedList: FC = memo(() => {
	const { t } = useTranslation();
	const { event_id } = useParams();
	const fields = useOutletContext<AgentEvent>();
	const isEventFinishOrCancel =
		fields?.isFinished || fields?.eventStatus === EventStatus.Canceled;

	const [state, setState] = useState({
		keyword: ''
	});

	const { data: list, isFetching } = useEventAllInvitations({
		eventId: event_id,
		isPartner: false,
		keyword: state.keyword
	});

	const handleSearch = (query: string) => {
		setState((prev) => ({
			...prev,
			keyword: query
		}));
	};

	return (
		<Card className={styles.card}>
			<h3 className="text-gray-3 fs-6 fw-400 mb-3">
				{t('title.invited-list')}
			</h3>
			{!isEventFinishOrCancel && <InviteGuestBar isPartner={false} />}
			<PageHeadingSearch className="mb-3" onSubmit={handleSearch} />
			{!isFetching && list && list?.length > 0 && (
				<Scrollbar className={styles.scrollbar}>
					<div>
						{list.map((invitation) => {
							return (
								<EventInvitedCard
									key={invitation.id}
									card={invitation}
								/>
							);
						})}
					</div>
				</Scrollbar>
			)}
			{isFetching && <EventInvitedCardLoading />}
			{!isFetching && list?.length === 0 && (
				<NoData message="title.no-invitation-sent" />
			)}
		</Card>
	);
});

EventVisitorInvitedList.displayName = 'EventVisitorInvitedList';
