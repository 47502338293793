import { FC, memo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'Adapter';
import { Box } from 'Styles';
import { api, clx } from 'Utils';
import { queryKey, names } from 'Constants';
import { useTheme, useUpdateAgent } from 'Hooks';
import { AgentEvent, AgentSetting, ColorValues, Colors, DataList } from 'Types';
import {
	ProfileEventCard,
	Button,
	ProfileAddNewCard,
	LoadingSpinner,
	SectionHeadline,
	SectionSwitcher,
	SubmitButton,
	FontAwesome
} from 'Elements';

export const AgentEventSection: FC = memo(() => {
	const perRow = 3;
	const { theme } = useTheme();
	const { events } = names;
	const { t } = useTranslation();
	const { control, reset } = useForm();
	const [searchParams, setSearchParams] = useSearchParams();
	const params = Object.fromEntries([...searchParams]);
	const statusParam = searchParams.get('event-status') || '1';
	const navigate = useNavigate();
	const navigationUrl = `${events.path}/create`;

	const {
		updateAgentSetting,
		setting,
		isLoading: isLoadingSetting
	} = useUpdateAgent();

	const {
		data: eventList,
		isFetching,
		isLoading,
		fetchNextPage,
		hasNextPage
	} = useInfiniteQuery<any, unknown, DataList<AgentEvent>>(
		[queryKey.events, { status: statusParam }],
		async ({ pageParam }) => {
			const res = await axios.get(
				`${
					api.getEvents
				}?status=${statusParam}&pageSize=${perRow}&pageNumber=${
					pageParam || 1
				}&keyword`
			);
			return res.data ?? [];
		},
		{
			getNextPageParam: (lastPage, pages) => {
				if (lastPage.pageNumber < lastPage.totalPages) {
					return pages.length + 1;
				}
			}
		}
	);

	const handleTabChange = (status: number) => {
		setSearchParams({ ...params, 'event-status': status.toString() });
	};

	const handleNavigate = () => {
		navigate(navigationUrl, { state: { prevPath: '/homepage' } });
	};

	const handleUpdate = async (field: Partial<AgentSetting>) => {
		await updateAgentSetting(field);
	};

	useEffect(() => {
		reset(setting);
	}, [reset, setting]);

	return (
		<section className="position-relative mb-5 py-5 bg-gray-1">
			{isLoading && (
				<div className="d-flex align-items-center justify-content-center">
					<LoadingSpinner />
				</div>
			)}
			{!isLoading && !isLoadingSetting && (
				<Container>
					<SectionSwitcher
						control={control}
						name="eventSectionStatus"
						label="forms.show-events-in-home"
						isActive={!setting?.eventSectionStatus}
						onSwitchChange={(val) =>
							handleUpdate({ eventSectionStatus: val })
						}
					/>
					<SectionHeadline
						className="text-center"
						title={
							setting?.eventSectionTitle ||
							`[${t('placeholder.our-events')}]`
						}
						titleColor={ColorValues['gray-5']}
						onUpdateTitle={(val) =>
							handleUpdate({ eventSectionTitle: val })
						}>
						{eventList && eventList?.pages[0]?.totalItems > 0 && (
							<Box
								$hasShadow={false}
								$bgColor={theme.agent.primaryColor}
								className="d-inline-flex justify-content-center align-items-center rounded-pill p-2 mt-4">
								<Button
									color={Colors.empty}
									className={clx(
										'fs-base fw-500 text-white',
										statusParam === '1' &&
											'fw-500 bg-white rounded-pill'
									)}
									label={t('button.event.upcoming') as string}
									onClick={() => handleTabChange(1)}
								/>
								<Button
									color={Colors.empty}
									className={clx(
										'fs-base fw-500 text-white',
										statusParam === '99' &&
											'fw-500 bg-white rounded-pill'
									)}
									label={t('button.event.past') as string}
									onClick={() => handleTabChange(99)}
								/>
							</Box>
						)}
					</SectionHeadline>
					{eventList && eventList?.pages[0]?.totalItems > 0 && (
						<Row className="justify-content-center">
							{eventList?.pages.map((page) =>
								page.items?.map((event) => (
									<ProfileEventCard
										card={event}
										key={event.id}
									/>
								))
							)}
						</Row>
					)}
					<div className="mt-4 d-flex align-items-center justify-content-center gap-3">
						{hasNextPage && (
							<SubmitButton
								icon={false}
								type="button"
								savingLabel=""
								isSubmitting={isFetching}
								color={Colors['border-gray']}
								className="rounded-pill px-4"
								label={t('button.show-more') as string}
								onClick={() => fetchNextPage()}
							/>
						)}
						{eventList && eventList?.pages[0]?.totalItems > 0 && (
							<Button
								className="gap-3 rounded-pill"
								onClick={handleNavigate}>
								<FontAwesome
									icon="plus"
									size="lg"
									swapOpacity
								/>
								{t('title.add-new-event')}
							</Button>
						)}
					</div>
					{eventList && eventList?.pages[0]?.totalItems === 0 && (
						<ProfileAddNewCard
							navigationUrl={navigationUrl}
							title="title.add-new-event"
						/>
					)}
				</Container>
			)}
			{!setting?.eventSectionStatus && (
				<div className="overlay overlay_white overlay-box" />
			)}
		</section>
	);
});

AgentEventSection.displayName = 'AgentEventSection';
