import { memo, FC, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { EventParticipationStatus } from 'Types';
import { useCompanyParticipatedEvent } from 'Hooks';
import {
	CompanyParticipatedEventsCard,
	EndOfList,
	LoadingSpinner,
	NoData,
	Pagination
} from 'Elements';

export const EventCancelParticipatedList: FC = memo(() => {
	const [searchParams, setSearchParams] = useSearchParams();
	const params = Object.fromEntries([...searchParams]);
	const pageParam = searchParams.get('page') || 1;
	const statusParam = searchParams.get('status') || '';

	const [state, setState] = useState({
		pageSize: 10,
		currentPage: pageParam,
		filterBy: statusParam
	});

	const { data, isLoading, refetch } = useCompanyParticipatedEvent({
		pageSize: state.pageSize,
		pageNumber: state.currentPage,
		status: EventParticipationStatus.Canceled
	});

	const handlePageChange = (page: number) => {
		setSearchParams({ ...params, page: page.toString() });
		setState((prev) => ({
			...prev,
			currentPage: page
		}));
	};

	return (
		<div className="position-relative">
			{!isLoading &&
				data?.items.map((event) => {
					return (
						<CompanyParticipatedEventsCard
							key={event.id}
							card={event}
							refetch={refetch}
						/>
					);
				})}
			{isLoading && <LoadingSpinner />}
			{!isLoading && data && data?.totalPages > 1 && (
				<Pagination
					className="mt-3"
					totalItemsCount={data.totalItems}
					activePage={parseInt(state.currentPage.toString(), 10)}
					itemsCountPerPage={state.pageSize}
					onChange={handlePageChange}
				/>
			)}
			{!isLoading && <EndOfList data={data} />}
			{!isLoading && data && data?.totalItems === 0 && (
				<NoData message="title.no-event" />
			)}
		</div>
	);
});

EventCancelParticipatedList.displayName = 'EventCancelParticipatedList';
