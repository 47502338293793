import { CSSProperties, FC, memo } from 'react';
import { clx } from 'Utils';
import { LoadingMask, SkeletonWrapper } from 'Elements';
import styles from 'partials/page/agent-plan-price.module.scss';

interface Props {
	count?: number | string;
}

const Skeleton = memo(({ style }: { style: CSSProperties }) => {
	const boxClass = clx(
		styles.level,
		'd-flex flex-column gap-2 p-2 bg-gray-1 round-10'
	);
	return (
		<div className={boxClass} style={style}>
			<LoadingMask className={clx(styles.loading, 'w-60 bg-gray-2')} />
			<div className="d-flex algin-items-center justify-content-between mt-auto">
				<LoadingMask
					className={clx(styles.loading, 'w-20 bg-gray-2')}
				/>
				<LoadingMask
					className={clx(styles.loading, 'w-20 bg-gray-2')}
				/>
			</div>
		</div>
	);
});

export const AgentCommissionLevelBoxLoading: FC<Props> = memo(
	({ count = 8 }) => {
		return (
			<SkeletonWrapper
				count={count}
				skeleton={Skeleton}
				wrapperClassName="d-flex align-items-center flex-grow-1 gap-2"
			/>
		);
	}
);

Skeleton.displayName = 'Skeleton';
AgentCommissionLevelBoxLoading.displayName = 'AgentCommissionLevelBoxLoading';
