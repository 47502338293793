import { memo } from 'react';
import { Media } from 'Types';
import { useUpdateCompany, useModal } from 'Hooks';
import { EditButton, LoadingContent, ModalBoxChangeImage } from 'Elements';
import styles from 'partials/homepage/section/profile-hero-image.module.scss';

export const CompanyHeroImage = memo(() => {
	const [openModal, toggleModal] = useModal();

	const {
		data: company,
		isFetching,
		updateCompanySetting
	} = useUpdateCompany();
	const setting = company?.companySetting;

	const handleResetLogo = () => {
		onUpdate({ url: '', thumbnailUrl: '' });
	};

	const handleUploadImage = (image_data: Partial<Media>) => {
		if (image_data) {
			onUpdate(image_data);
		}
	};

	const onUpdate = async (image_data: Partial<Media>) => {
		const api_data = {
			companyHeaderLogo: image_data?.url,
			companyHeaderThumbnailLogo: image_data?.thumbnailUrl
		};
		await updateCompanySetting(api_data);
	};

	return (
		<>
			<div
				className={styles.editable}
				role="button"
				onClick={toggleModal}>
				{isFetching && <LoadingContent />}
				{!isFetching && company && (
					<>
						<img
							src={
								setting?.companyHeaderLogo ||
								'/assets/img/default-image.jpg'
							}
							alt={setting?.companyText || company?.companyName}
							className={styles.image}
						/>
						<div className="edit-overlay">
							<EditButton className="edit-overlay-icon" />
						</div>
					</>
				)}
			</div>
			<ModalBoxChangeImage
				name="companyHeaderLogo"
				isOpen={openModal}
				title="title.change-image"
				image={setting?.companyHeaderLogo || ''}
				onClose={toggleModal}
				onRemove={handleResetLogo}
				onImageChange={handleUploadImage}
			/>
		</>
	);
});

CompanyHeroImage.displayName = 'CompanyHeroImage';
