import { FC, memo, useState } from 'react';
import { Container } from 'reactstrap';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { names } from 'Constants';
import { clx, config } from 'Utils';
import { Colors, Job } from 'Types';
import { useDelayUnmount, useModal, useResponsive, useUpdateJob } from 'Hooks';
import {
	Button,
	FontAwesome,
	JobInfoDropDown,
	ModalBoxUnPublishJob,
	SubmitButton
} from 'Elements';
import styles from 'partials/homepage/home-header.module.scss';

interface Props {
	job?: Job;
}

export const JobPreviewHeader: FC<Props> = memo(({ job }) => {
	const { t } = useTranslation();
	const { jobs } = names;
	const { job_id } = useParams() as { job_id: string };
	const [publishing, setPublishing] = useState(false);
	const { isMobileAndBelow } = useResponsive();
	const { publishJob, refetch } = useUpdateJob(job_id as string);
	const [openUnPublishModal, toggleUnPublishModal] = useModal();
	const shouldRenderUnPublishModal = useDelayUnmount(openUnPublishModal, 350);

	const isPublished = !!job?.publishedVersion;
	const previewUrl = `${config.publicUrl}/job/${job?.id}/${job?.publishedInfo.slugUrl}`;

	const redirectToPreview = () => {
		window.open(previewUrl, '_blank', 'noopener,noreferrer');
	};

	const handlePublish = async () => {
		try {
			setPublishing(true);
			await publishJob(job_id as string, () => {
				void refetch();
			});
		} finally {
			setPublishing(false);
		}
	};

	return (
		<header className={styles.header}>
			<Container fluid className={clx(styles.container, 'container-lg')}>
				<nav className={styles.navbar}>
					<h6 className="fs-6 fw-400 mb-0">
						<Link
							to={`${jobs.path}/${job_id}`}
							className="d-flex gap-3 align-items-center back-button">
							<FontAwesome
								icon="arrow-left"
								size={isMobileAndBelow ? 'lg' : '1x'}
								className="back-icon"
							/>
							{!isMobileAndBelow && t('title.back-to-job-page')}
						</Link>
					</h6>
					{job && (
						<div className="d-flex align-items-center justify-content-end flex-grow-1 gap-2">
							<JobInfoDropDown job={job} />
							{isPublished && (
								<Button
									color={Colors['white-red']}
									title={t('button.unPublish')}
									size={isMobileAndBelow ? 'sm' : 'md'}
									className="gap-2"
									onClick={toggleUnPublishModal}>
									<FontAwesome
										icon="triangle-exclamation"
										size="lg"
									/>
									{!isMobileAndBelow && t('button.unPublish')}
								</Button>
							)}
							{isPublished && (
								<Button
									color={Colors.secondary}
									title={t('button.job.view-job-page')}
									size={isMobileAndBelow ? 'sm' : 'md'}
									className="gap-2"
									onClick={redirectToPreview}>
									<FontAwesome icon="eye" size="lg" />
									{!isMobileAndBelow &&
										t('button.job.view-job-page')}
								</Button>
							)}
							{!isPublished && !job?.startDate && (
								<SubmitButton
									type="button"
									icon="upload"
									label="button.publish"
									savingLabel="button.publishing"
									isSubmitting={publishing}
									onClick={handlePublish}
								/>
							)}
						</div>
					)}
				</nav>
			</Container>
			{shouldRenderUnPublishModal && (
				<ModalBoxUnPublishJob
					isOpen={openUnPublishModal}
					onClose={toggleUnPublishModal}
				/>
			)}
		</header>
	);
});

JobPreviewHeader.displayName = 'JobPreviewHeader';
