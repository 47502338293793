import { memo, useEffect } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { ContactGroupFormData } from 'Types';
import {
	useContactGroup,
	useContactGroupDetails,
	usePrompt
} from 'Hooks';
import {
	Card,
	LoadingContent,
	SubmitButtonGroup,
	SwitchController,
	ListContactPeople
} from 'Elements';

export const UpdateContactGroup = memo(() => {
	const { t } = useTranslation();
	const descriptionLimit = 450;
	const { contactGroup_id } = useParams();
	const { updateContactGroup } = useContactGroup(contactGroup_id as string);
	const schema = yup.object().shape({
		title: yup.string().required(t('validation.title.required'))
	});
	const { data: fields, isFetching } = useContactGroupDetails(
		contactGroup_id as string
	);

	const {
		reset,
		control,
		register,
		handleSubmit,
		formState: { errors, isDirty, isSubmitting }
	} = useForm<ContactGroupFormData>({
		resolver: yupResolver(schema)
	});

	usePrompt(t('forms.leave-screen-massage'), isDirty);

	useEffect(() => {
		reset(fields);
	}, [reset, fields]);


	const onSubmit: SubmitHandler<ContactGroupFormData> = async (data) => {
		const api_data = {
			...data
		};
		await updateContactGroup(contactGroup_id as string, api_data);
	};

	return (
		<>
			<Card>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Row>
						<Col md={8} xxl={6}>
							<FormGroup>
								<Label htmlFor="title">
									{t('forms.group-title')}
									<small className="ms-1">
										({t('validation.required')})
									</small>
								</Label>
								<input
									{...register('title')}
									type="text"
									id="title"
									className="inputbox w-100"
								/>
								{errors.title && (
									<div className="invalid-feedback d-block">
										{errors.title.message}
									</div>
								)}
							</FormGroup>
							<FormGroup>
								<Label htmlFor="description">
									{t('forms.short-desc')}
								</Label>
								<textarea
									{...register('description', {
										maxLength: descriptionLimit
									})}
									maxLength={descriptionLimit}
									id="description"
									className="inputbox w-100"
									cols={10}
									rows={5}
									aria-invalid={!!errors.description}
								/>
							</FormGroup>
							<FormGroup>
								<SwitchController
									name="isActive"
									control={control}
									label="forms.is-active"
									boxClassName="py-2"
								/>
							</FormGroup>
							<SubmitButtonGroup
								isDisable={!isDirty}
								isSubmitting={isSubmitting}
							/>
						</Col>
					</Row>
				</form>
				{isFetching && <LoadingContent />}
			</Card>
			<ListContactPeople />
		</>
	);
});

UpdateContactGroup.displayName = 'UpdateContactGroup';
