import { memo } from 'react';
import { Container } from 'reactstrap';
import { Outlet, useNavigate } from 'react-router-dom';
import { CustomNavigationClient, authInstance, clx } from 'Utils';
import { toggleSidebar } from 'Actions';
import { Sidebar, Header, ScrollTop } from 'Elements';
import { useDelayUnmount, useDispatch, useSelector } from 'Hooks';
import styles from 'partials/shared/page-frame.module.scss';

export const PageLayout = memo(() => {
	const dispatch = useDispatch();
	const sidebar = useSelector((state) => state.sidebar);
	const shouldRenderSidebar = useDelayUnmount(sidebar.isOpen, 400);
	const navigate = useNavigate();
	const navigationClient = new CustomNavigationClient(navigate);
	authInstance.setNavigationClient(navigationClient);

	const closeSidebar = () => {
		dispatch(toggleSidebar(false));
	};
	return (
		<>
			<ScrollTop />
			<div className={styles.wrapper}>
				<Header />
				<Sidebar isOpen={sidebar.isOpen} />
				<main className={styles.content_wrapper}>
					<Container fluid className={styles.container}>
						<div className={styles.content}>
							<Outlet />
						</div>
					</Container>
				</main>
			</div>
			{shouldRenderSidebar && (
				<div
					className={clx(
						'overlay',
						sidebar.isOpen && 'show',
						!sidebar.isOpen && 'hiding'
					)}
					onClick={closeSidebar}
				/>
			)}
		</>
	);
});

PageLayout.displayName = 'PageLayout';
