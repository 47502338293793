import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Wizard, Steps, Step } from 'react-albus-react18';
import { clx } from 'Utils';
import { names } from 'Constants';
import { useCreateRole, useRoles } from 'Hooks';
import {
	IStep,
	UserRoleInformation,
	UserRolePermissions,
	UserRoleForm
} from 'Types';
import {
	FormSuccess,
	RoleInformation,
	RolePermissions,
	RolePreview,
	StepContainer
} from 'Elements';
import styles from 'partials/step/step-container.module.scss';

export const CreateRole = memo(() => {
	const { t } = useTranslation();
	const { roles } = names;
	const steps: IStep[] = [
		{
			id: 'step1',
			title: t('wizard.step-role-1')
		},
		{
			id: 'step2',
			title: t('wizard.step-role-2')
		},
		{
			id: 'step3',
			title: t('wizard.step-preview')
		}
	];

	const defaultFields: UserRoleForm = {
		step1: {
			name: '',
			description: ''
		},
		step2: {
			permissions: []
		}
	};
	const [fields, setFields] = useState(defaultFields);
	const { refetch } = useRoles({});
	const {
		submitRequest,
		permissionData,
		permissionGraphData,
		isLoadingGraph,
		isLoadingPermissions
	} = useCreateRole();

	const handleNext = <T,>(
		goToNext: () => void,
		data: T,
		step_number: number
	) => {
		setFields((prev) => ({
			...prev,
			[`step${step_number.toString()}`]: data
		}));
		goToNext();
	};

	return (
		<Wizard
			render={({ step, ...props }) => (
				<div
					className={clx(
						styles.wizard,
						'd-flex flex-column flex-md-row'
					)}>
					<StepContainer
						steps={steps}
						currentIndex={props.steps.indexOf(step)}
					/>
					<Steps>
						<Step
							id="step1"
							render={({ next }) => (
								<RoleInformation
									fields={fields.step1}
									onNext={(data) =>
										handleNext<UserRoleInformation>(
											next,
											data,
											1
										)
									}
								/>
							)}
						/>
						<Step
							id="step2"
							render={({ next, previous }) => (
								<RolePermissions
									fields={fields.step2}
									permissionData={permissionData}
									permissionGraphData={permissionGraphData}
									isLoadingGraph={isLoadingGraph}
									isLoadingPermissions={isLoadingPermissions}
									onPrev={previous}
									onNext={(data) =>
										handleNext<UserRolePermissions>(
											next,
											data,
											2
										)
									}
								/>
							)}
						/>
						<Step
							id="step3"
							render={({ next, previous, push }) => (
								<RolePreview
									fields={fields}
									onPrev={previous}
									onNext={next}
									goToStep={push}
									onSubmit={submitRequest}
								/>
							)}
						/>
						<Step
							id="step4"
							render={({ push }) => (
								<div className={clx('d-flex', styles.form)}>
									<FormSuccess
										addButtonTitle="button.add-user-role"
										listTitle="button.user-role-list"
										successTitle="forms.user-role-created"
										className="flex-grow-1 align-self-center"
										fetcher={refetch}
										listUrl={roles.path}
										onClickAdd={() => {
											push('step1');
											setFields(defaultFields);
										}}
									/>
								</div>
							)}
						/>
					</Steps>
				</div>
			)}
		/>
	);
});

CreateRole.displayName = 'CreateRole';
