import { memo, FC, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { array, object, string } from 'yup';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider } from 'react-hook-form';
import type { SubmitHandler } from 'react-hook-form/dist/types';
import { clx } from 'Utils';
import { AgentPlan, AgentPlanForm } from 'Types';
import {
	useCRUDAgentPlan,
	useDelayUnmount,
	useModal,
	useResponsive
} from 'Hooks';
import {
	AgentPlanPriceDecorationBox,
	Button,
	Card,
	FontAwesome,
	LoadingContent,
	ModalBoxCheckPlanPriceRequirement,
	ModalBoxConfirmPlanPrice
} from 'Elements';
import styles from 'partials/page/form.module.scss';

export const AgentPlanPrice: FC = memo(() => {
	const descriptionLimit = 480;
	const { t } = useTranslation();
	const { isDesktopAndBelow } = useResponsive();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [submitData, setData] = useState<AgentPlan | null>(null);
	const [openModalConfirm, toggleModalConfirm] = useModal();
	const [openModalRequirement, toggleModalRequirement] = useModal();
	const shouldRenderModalConfirm = useDelayUnmount(openModalConfirm, 350);
	const shouldRenderModalRequirement = useDelayUnmount(
		openModalRequirement,
		350
	);
	const { data: agentPlan, isFetching, updateAgentPlan } = useCRUDAgentPlan();

	const featureSchema = {
		title: string().required(
			t('validation.plan-price.feature-title.required')
		),
		description: string().required(
			t('validation.plan-price.feature-desc.required')
		)
	};

	const schema = object().shape({
		contactPerson: object().shape({
			fullName: string().required(t('validation.fullName.required'))
		}),
		termsAndConditionLink: string().required(
			t('validation.plan-price.agb-terms.required')
		),
		features: array()
			.of(object().shape(featureSchema))
			.required(t('validation.plan-price.feature.required'))
			.min(1, t('validation.plan-price.feature.required'))
	});

	const formMethods = useForm<AgentPlan>({
		shouldUnregister: false,
		mode: 'onBlur',
		resolver: yupResolver(schema)
	});

	const {
		reset,
		handleSubmit,
		formState: { isDirty, errors }
	} = formMethods;

	const submitAgentPlan = async () => {
		setIsSubmitting(true);
		if (submitData) {
			await updateAgentPlan(
				{
					...submitData,
					coverPhotoUrl: submitData.coverPhotoUrl ?? '',
					videoUrl: submitData.videoUrl ?? '',
					description: submitData.description ?? '',
					termsAndConditionLink:
						submitData.termsAndConditionLink ?? '',
					contactPerson: {
						fullName: submitData.contactPerson.fullName ?? '',
						description: submitData.contactPerson.description ?? '',
						avatarUrl: submitData.contactPerson.avatarUrl ?? '',
						published: submitData.contactPerson.published ?? false
					},
					features:
						submitData.features?.map((feature) => {
							return {
								...feature,
								icon: (feature?.icon?.value as any) ?? ''
							};
						}) ?? []
				} as AgentPlanForm,
				() => {
					setIsSubmitting(false);
					toggleModalConfirm();
				}
			);
		}
	};

	const onError = () => {
		toggleModalRequirement();
	};

	const onSubmit: SubmitHandler<AgentPlan> = async (data) => {
		setData(data);
		toggleModalConfirm();
	};

	useEffect(() => {
		reset({
			...agentPlan,
			price: agentPlan?.price ?? 0,
			features:
				agentPlan?.features?.map((feature) => {
					return {
						...feature,
						icon: {
							label: feature.icon,
							value: feature.icon,
							icon: feature.icon
						}
					};
				}) ?? [],
			contactPerson: {
				...agentPlan?.contactPerson,
				description: agentPlan?.contactPerson?.description.substring(
					0,
					descriptionLimit
				)
			}
		});
	}, [reset, agentPlan]);

	return (
		<FormProvider {...formMethods}>
			<form
				noValidate
				onSubmit={handleSubmit(onSubmit, onError)}
				className={clx(
					isDesktopAndBelow && styles.form,
					'position-relative'
				)}>
				<AgentPlanPriceDecorationBox />
				<Card className={clx(isDesktopAndBelow && 'mb-4')}>
					<Outlet context={agentPlan} />
					{isFetching && <LoadingContent />}
				</Card>
				<Card
					className={clx(isDesktopAndBelow && styles.sticky_action)}>
					<div className="d-flex align-items-center justify-content-end gap-2">
						<Button
							type="submit"
							disabled={!isDirty}
							className="gap-2">
							<FontAwesome icon="check" size="lg" />
							{t('button.plan-price.confirm')}
						</Button>
					</div>
				</Card>
			</form>
			{shouldRenderModalConfirm && (
				<ModalBoxConfirmPlanPrice
					isOpen={openModalConfirm}
					onClose={toggleModalConfirm}
					onConfirm={submitAgentPlan}
					isSubmitting={isSubmitting}
				/>
			)}
			{shouldRenderModalRequirement && (
				<ModalBoxCheckPlanPriceRequirement
					isOpen={openModalRequirement}
					onClose={toggleModalRequirement}
					requirement={errors}
				/>
			)}
		</FormProvider>
	);
});

AgentPlanPrice.displayName = 'AgentPlanPrice';
