import { memo, useState, FC } from 'react';
import { AxiosProgressEvent } from 'axios';
import { clx } from 'Utils';
import Adapter from 'Adapter';
import {
	FileUploader,
	FileUploaderCardLoading
} from 'Elements';

interface Props {
	nameKey: string;
	onChange: (data: any) => void;
	className?: string;
	isLoading?: boolean;
	url: string
}

export const FileUploaderBox: FC<Props> = memo(
	({ nameKey, url, className, isLoading = false, onChange }) => {
		const [progress, setProgress] = useState(0);

		const uploadFileRequest = async (formData: FormData) => {
			await Adapter({
				method: 'post',
				url,
				data: formData,
				onUploadProgress: (progressEvent: AxiosProgressEvent) => {
					let percentCompleted = 0;
					if (progressEvent.total) {
						percentCompleted = Math.round(
							(progressEvent.loaded * 100) / progressEvent.total
						);
						setProgress(percentCompleted);
					}
				}
			}).then((data)=>{
				onChange(data);
			})
		};


		return (
			<div className={clx('mt-4 d-flex flex-column gap-3', className)}>
				<FileUploader
					onUpload={uploadFileRequest}
					progress={progress}
					nameKey={nameKey}
				/>
				<div className="d-flex align-items-center flex-column gap-2">
					{isLoading && <FileUploaderCardLoading />}
				</div>
			</div>
		);
	}
);

FileUploaderBox.displayName = 'FileUploaderBox';
