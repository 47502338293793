import { FC, memo, PropsWithChildren } from 'react';
import styles from 'partials/homepage/layout.module.scss';

export const JobPageLayout: FC<PropsWithChildren> = memo(({ children }) => {
	return (
		<main className={styles.wrapper}>
			<div className="position-relative d-flex flex-grow-1">
				<div className="flex-1">{children}</div>
			</div>
		</main>
	);
});
JobPageLayout.displayName = 'JobPageLayout';
