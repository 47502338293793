import { FC, memo } from 'react';
import { Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Colors, Employee } from 'Types';
import { Badge, Button, Card, ShimmerImage, FontAwesome } from 'Elements';
import styles from 'partials/card/user-card.module.scss';

interface Props {
	card: Employee;
}

export const EmployeeCard: FC<Props> = memo(({ card }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const navigateUrl = `/employees/${card.id}`;
	const defaultImage = '/assets/img/profiles/img-user-default.png';

	const handleNavigate = () => {
		void navigate(navigateUrl, { state: { prevPath: '/employees' } });
	};

	return (
		<Col sm={6} xl={4} xxl={3} className="mb-4">
			<Card className={styles.card}>
				<div className={styles.avatar}>
					<Link
						to={navigateUrl}
						state={{ prevPath: '/employees' }}
						className={styles.shimmerBox}>
						<ShimmerImage
							src={card.thumbnailUrl}
							width={500}
							height={500}
							defaultImage={defaultImage}
							alt={card.employeeName}
							className={styles.shimmer}
						/>
					</Link>
				</div>
				<div className={styles.body}>
					<h4 className="fs-6 fw-400 mb-2 text-truncate">
						<Link
							state={{ prevPath: '/employees' }}
							to={navigateUrl}>
							{card.employeeName}
						</Link>
					</h4>
					<span className="text-gray-3 fw-300">{card.jobTitle}</span>
					<div className="d-flex align-items-center justify-content-between mt-auto pt-4">
						<Badge
							color={
								card.showInHomePage
									? Colors.green
									: Colors['gray-3']
							}>
							{t(
								card.showInHomePage
									? 'badge.published'
									: 'badge.unPublished'
							)}
						</Badge>
						<Button
							size="sm"
							color={Colors['white-primary']}
							onClick={handleNavigate}>
							<FontAwesome
								size="sm"
								icon="pen"
								className="me-2"
							/>
							{t('button.edit')}
						</Button>
					</div>
				</div>
			</Card>
		</Col>
	);
});

EmployeeCard.displayName = 'EmployeeCard';
