import { cloneElement, FC, memo, PropsWithChildren, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { clx } from 'Utils';
import { IconNoResult } from 'Elements';
import styles from 'partials/shared/no-data.module.scss';

interface Props extends PropsWithChildren {
	message?: string;
	boxClassName?: string;
	icon?: ReactNode;
	iconClassName?: string;
}

export const NoData: FC<Props> = memo(
	({ children, icon: Icon, message, boxClassName, iconClassName }) => {
		const { t } = useTranslation();
		return (
			<div
				className={clx(
					'text-center d-flex justify-content-center align-items-center flex-column py-5 gap-3',
					boxClassName
				)}>
				{Icon || (
					<IconNoResult className={clx(styles.icon, iconClassName)} />
				)}
				<h5 className="fs-base fw-bold mb-0 mt-2 lh-base text-gray-3">
					{t(message as string) || t('global.no-data')}
				</h5>
				{children}
			</div>
		);
	}
);

NoData.displayName = 'NoData';
