import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import axios from 'Adapter';
import { api, fetch } from 'Utils';
import { queryKey } from 'Constants';
import { Benefit, DataList, DataQueryParams } from 'Types';

interface BenefitList {
	benefitList: { benefitId: string }[];
}

const config = (options = {}) => {
	return {
		...{ keepPreviousData: false },
		...options
	};
};

export const useBenefits = <T = DataList<Benefit>>(
	{ pageSize, pageNumber = 1, keyword = '' }: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<Benefit>>
): UseQueryResult<DataList<Benefit>, unknown> => {
	return useQuery(
		{
			queryKey: [queryKey.benefits, { pageSize, pageNumber, keyword }],
			queryFn: () => fetch.benefitList(pageSize, pageNumber, keyword),
			...config(options)
		}
	);
};

export const useUpdateBenefits = (keyword?: string) => {
	const query = useBenefits({
		pageSize: 500,
		keyword
	});
	const benefits = query.data;

	const updateBenefits: (
		fields: BenefitList,
		onFinal?: () => void
	) => Promise<void> = async (fields, onFinal) => {
		await axios.patch(api.updateCompanyBenefit, fields).then((res) => {
			if (res.status === 200) {
				onFinal?.();
			}
		});
	};
	return { ...query, benefits, updateBenefits };
};
