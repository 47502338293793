import Swiper from 'swiper';
import { updateObj } from 'Utils';

enum ActionType {
	SET_SWIPER = 'SET_SWIPER',
	SET_IMAGE = 'SET_IMAGE',
	UPDATE_IMAGE = 'UPDATE_IMAGE',
	SUBMIT_GALLERY = 'SUBMIT_GALLERY',
	DELETE_IMAGE = 'DELETE_IMAGE'
}

// An interface for our actions
interface Action {
	type: ActionType;
	payload: any;
}

interface State {
	swiper: Swiper | null;
	nextImage: string;
	prevImage: string;
	imageLoading?: boolean;
	imageRemoving?: boolean;
	imageUpdating?: boolean;
}

// Reducer
// @ts-ignore
export const GalleryReducer: (state: State, action: Action) => State = (
	state,
	action
) => {
	const { payload, type } = action;
	switch (type) {
		case ActionType.SET_SWIPER:
			return updateObj(state, {
				swiper: payload
			});
		case ActionType.SET_IMAGE:
			return updateObj(state, {
				nextImage: payload.next,
				prevImage: payload.prev
			});
		case ActionType.SUBMIT_GALLERY:
			return updateObj(state, {
				imageLoading: payload
			});
		case ActionType.UPDATE_IMAGE:
			return updateObj(state, {
				imageUpdating: payload
			});
		case ActionType.DELETE_IMAGE:
			return updateObj(state, {
				imageRemoving: payload
			});
		default:
			state;
	}
};

// Actions
export const setSwiper = (payload: Swiper | null) => {
	return {
		type: ActionType.SET_SWIPER,
		payload
	};
};
export const setImage = (payload: { next: string; prev: string }) => {
	return {
		type: ActionType.SET_IMAGE,
		payload
	};
};
export const setImageLoading = (payload: boolean) => {
	return {
		type: ActionType.SUBMIT_GALLERY,
		payload
	};
};

export const setImageRemoving = (payload: boolean) => {
	return {
		type: ActionType.DELETE_IMAGE,
		payload
	};
};

export const setImageUpdating = (payload: boolean) => {
	return {
		type: ActionType.UPDATE_IMAGE,
		payload
	};
};
