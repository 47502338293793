import { FC, memo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Container, Row } from 'reactstrap';
import { CompanySetting } from 'Types';
import { useTheme, useUpdateCompany } from 'Hooks';
import {
	ProfileStatisticCard,
	LoadingSpinner,
	SectionSwitcher
} from 'Elements';

export const CompanyStatisticSection: FC = memo(() => {
	const { theme } = useTheme();
	const { control, reset } = useForm();
	const { setting, updateCompanySetting, isLoading } = useUpdateCompany();

	const handleUpdate = async (field: Partial<CompanySetting>) => {
		await updateCompanySetting(field);
	};

	useEffect(() => {
		reset(setting);
	}, [reset, setting]);

	return (
		<section className="position-relative mb-5">
			{isLoading && (
				<div className="d-flex align-items-center justify-content-center my-4 py-4">
					<LoadingSpinner />
				</div>
			)}
			{!isLoading && setting && (
				<Container>
					<SectionSwitcher
						control={control}
						name="statisticsSectionStatus"
						label="forms.show-statistic-in-home"
						isActive={!setting?.statisticsSectionStatus}
						onSwitchChange={(val) =>
							handleUpdate({
								statisticsSectionStatus: val
							})
						}
					/>
					<Row className="d-flex justify-content-center mx-lg-5 pt-3">
						<Col sm={6} md={3} className="mb-4 mb-md-0">
							<ProfileStatisticCard
								icon="calendar"
								fill={theme.company.primaryColor}
								title="label.established-date"
								desc={setting?.establishedDate}
								onUpdate={(val) =>
									handleUpdate({ establishedDate: val })
								}
							/>
						</Col>
						<Col sm={6} md={3} className="mb-4 mb-md-0">
							<ProfileStatisticCard
								icon="users"
								fill={theme.company.primaryColor}
								title="label.employees"
								desc={setting?.employeeNumber}
								onUpdate={(val) =>
									handleUpdate({ employeeNumber: val })
								}
							/>
						</Col>
						<Col sm={6} md={3} className="mb-4 mb-md-0">
							<ProfileStatisticCard
								icon="earth-america"
								fill={theme.company.primaryColor}
								title="label.locations"
								desc={setting?.location}
								onUpdate={(val) =>
									handleUpdate({ location: val })
								}
							/>
						</Col>
						<Col sm={6} md={3} className="mb-4 mb-md-0">
							<ProfileStatisticCard
								icon="buildings"
								fill={theme.company.primaryColor}
								title="label.branch"
								desc={setting?.branch}
								onUpdate={(val) =>
									handleUpdate({ branch: val })
								}
							/>
						</Col>
					</Row>
				</Container>
			)}
			{!isLoading && !setting?.statisticsSectionStatus && (
				<div className="overlay overlay_white overlay-box" />
			)}
		</section>
	);
});

CompanyStatisticSection.displayName = 'CompanyStatisticSection';
