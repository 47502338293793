import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { clx } from 'Utils';
import { TransactionDetails } from 'Types';
import styles from 'partials/card/subscription-card.module.scss';

interface Props {
	transaction: TransactionDetails;
}

export const CompanyTransactionPaymentProvider: FC<Props> = memo(
	({ transaction }) => {
		const { t } = useTranslation();
		return (
			<div>
				<h6 className="text-gray-3 mb-3 fs-6 fw-400">
					{t('title.payment.provider')}
				</h6>
				<div className={clx(styles.row, 'justify-content-between')}>
					<span className={styles.rowLabel}>
						{t('label.payment-reference')}:
					</span>
					<span>{transaction.payment?.paymentCode || '---'}</span>
				</div>
				<div
					className={clx(
						styles.row,
						'justify-content-between border-0'
					)}>
					<span className={styles.rowLabel}>
						{t('label.payment-provider')}:
					</span>
					<span>{transaction.payment?.paymentProvider || '---'}</span>
				</div>
				<div className={clx(styles.row, 'justify-content-between')}>
					<span className={styles.rowLabel}>
						{t('label.payment-method')}:
					</span>
					<span>{transaction.payment?.paymentMethod ?? '---'}</span>
				</div>
			</div>
		);
	}
);

CompanyTransactionPaymentProvider.displayName =
	'CompanyTransactionPaymentProvider';
