import { memo, FC, useEffect } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { SubmitHandler, useForm } from 'react-hook-form';
import { usePrompt } from 'Hooks';
import { LoadingContent, StepNavigation } from 'Elements';
import { UserRoleInformation } from 'Types';
import styles from 'partials/step/step-container.module.scss';

interface Props {
	fields: UserRoleInformation;
	isLoading?: boolean;
	onNext: (data: UserRoleInformation) => void;
}

export const RoleInformation: FC<Props> = memo(
	({ fields, isLoading, onNext }) => {
		const { t } = useTranslation();
		const schema = yup.object({
			name: yup.string().required(t('validation.role-name.required'))
		});

		const {
			register,
			handleSubmit,
			reset,
			formState: { errors, isDirty }
		} = useForm<UserRoleInformation>({
			resolver: yupResolver(schema)
		});

		usePrompt(t('forms.leave-screen-massage'), isDirty);

		const onSubmit: SubmitHandler<UserRoleInformation> = (data) => {
			onNext?.(data);
		};

		useEffect(() => {
			reset(fields);
		}, [reset, fields]);

		return (
			<form
				onSubmit={handleSubmit(onSubmit)}
				className={styles.form}
				noValidate>
				<Row>
					<Col xxl={8}>
						<FormGroup>
							<Label htmlFor="name">
								{t('forms.role-name')}{' '}
								<small className="ms-1">
									({t('validation.required')})
								</small>
							</Label>
							<input
								{...register('name')}
								type="text"
								id="name"
								aria-invalid={!!errors.name}
								className="inputbox w-100"
							/>
							{errors.name && (
								<div className="invalid-feedback d-block">
									{errors.name.message?.toString()}
								</div>
							)}
						</FormGroup>
						<FormGroup>
							<Label htmlFor="description">
								{t('forms.user.role.desc')}
							</Label>
							<textarea
								{...register('description')}
								id="description"
								className="inputbox w-100"
								cols={10}
								rows={5}
							/>
						</FormGroup>
						<StepNavigation />
					</Col>
				</Row>
				{isLoading && <LoadingContent />}
			</form>
		);
	}
);

RoleInformation.displayName = 'RoleInformation';
