import { FC, memo } from 'react';
import { SocialItem } from 'Elements';
import { SocialNetwork } from 'Types';

interface Props {
	socialList: SocialNetwork[];
}

export const InternshipSocialMedia: FC<Props> = memo(({ socialList }) => {
	return (
		<div className="border-top border-bottom d-flex justify-content-center py-2">
			<ul className="d-inline-flex align-items-center gap-2">
				{socialList?.map((social) => {
					return (
						<li key={social.url}>
							<SocialItem social={social} />
						</li>
					);
				})}
			</ul>
		</div>
	);
});
InternshipSocialMedia.displayName = 'InternshipSocialMedia';
