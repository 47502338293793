import { memo, FC, useState } from 'react';
import axios from 'Adapter';
import { Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Button, SubmitButton } from 'Elements';
import { setSubscriptionVoucher } from 'Actions';
import { SubmitHandler, useForm } from 'react-hook-form';
import { api, clx, currencyFormatter, getDiscountFormat } from 'Utils';
import { Colors, Voucher, VoucherDiscountType } from 'Types';
import {
	useAxios,
	useDispatch,
	useGetCompanyProfileQuery,
	useSelector
} from 'Hooks';
import styles from 'partials/payment/voucher-subscription.module.scss';

export const CreateSubscriptionVoucher: FC = memo(() => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { selectedPlan, selectedAgent } = useSelector(
		(state) => state.subscription
	);
	const { sendRequest } = useAxios<Voucher>();
	const { data: user } = useGetCompanyProfileQuery();
	const [invoice, setInvoice] = useState<Voucher | null>(null);
	const [removing, setRemoving] = useState(false);
	const [canApplyVoucher, activateApplyVoucher] = useState(true);

	const {
		reset,
		register,
		handleSubmit,
		formState: { isDirty, isSubmitting }
	} = useForm<{ code: string }>({
		defaultValues: { code: '' }
	});

	const applyVoucher = async (voucherCode: string) => {
		await sendRequest(
			api.applyVoucher(selectedPlan?.id ?? '', voucherCode),
			{ method: 'GET' },
			(data) => {
				if (data) {
					setInvoice(data);
					dispatch(setSubscriptionVoucher(data));
				}
			}
		);
	};

	const validateVoucher = async (voucherCode: string) => {
		const data = {
			companyregionCode: user?.address?.postalCode?.substring(0, 2),
			planCode: selectedPlan?.code,
			voucherCode,
			agentId: selectedAgent?.agentId
		};

		await axios
			.post(api.validateVoucher, data)
			.then(async (res) => {
				if (res.status === 200) {
					try {
						await applyVoucher(voucherCode);
					} finally {
						activateApplyVoucher(false);
						// onApplyVoucher(voucherCode);
					}
				}
			})
			.catch(() => {
				activateApplyVoucher(true);
			});
	};

	const onResetVoucher = async () => {
		try {
			setRemoving(true);
			await applyVoucher('');
		} finally {
			reset({ code: '' });
			setRemoving(false);
			activateApplyVoucher(true);
			// onApplyVoucher('');
		}
	};

	const submitHandler: SubmitHandler<{ code: string }> = async (data) => {
		await validateVoucher(data.code);
	};

	return (
		<div className={styles.box}>
			<form onSubmit={handleSubmit(submitHandler)} noValidate>
				<div className="d-flex align-items-center flex-wrap gap-2">
					<Label
						htmlFor="code"
						className="fs-6 fw-500 p-0 mb-0 flex-shrink-0 flex-grow-1">
						{t('forms.voucher-code')}
					</Label>
					<div className="d-flex align-items-center gap-3 w-100 w-xs-auto">
						<div
							className={clx(
								styles.inputboxWrapper,
								'inputbox d-flex align-items-center flex-fill gap-3'
							)}>
							<input
								{...register('code')}
								id="code"
								type="text"
								placeholder={t('placeholder.enter-code-here')}
								className={styles.inputbox}
							/>
							{canApplyVoucher && (
								<SubmitButton
									icon={false}
									size="sm"
									color={Colors.secondary}
									className="flex-shrink-0"
									label="button.apply"
									savingLabel=""
									isDisable={!isDirty}
									isSubmitting={isSubmitting}
								/>
							)}
							{!canApplyVoucher && (
								<Button
									type="button"
									color={Colors.empty}
									className="p-0 flex-shrink-0 text-red me-2"
									label={
										!removing
											? (t('button.remove') as string)
											: (t('button.removing') as string)
									}
									onClick={onResetVoucher}
								/>
							)}
						</div>
					</div>
				</div>
			</form>
			<div className={clx(styles.summary, 'mt-3')}>
				<div className={styles.row}>
					<div className={styles.label}>
						{t('label.original-price')}
					</div>
					<div
						className={clx(
							styles.value,
							'd-flex flex-column text-end'
						)}>
						<span className="lh-base">
							{currencyFormatter(
								selectedPlan?.originalPrice ?? 0
							)}
						</span>
						<span className="text-gray-3 fs-small fw-400">
							{`${t('title.per')} ${selectedPlan?.duration} ${t(
								'title.days'
							)}`}
						</span>
					</div>
				</div>
				<div className={styles.row}>
					<div className={styles.label}>
						{t('label.discount')}
						{invoice?.discountType ===
							VoucherDiscountType.Percent && (
							<span className="text-gray-3 fs-small">
								(
								{getDiscountFormat(
									invoice?.discount,
									invoice?.discountType
								)}
								)
							</span>
						)}
					</div>
					<div className={styles.value}>
						{currencyFormatter(
							invoice && invoice?.discountAmount > 0
								? -invoice.discountAmount
								: 0
						)}
					</div>
				</div>
				<div className={styles.row}>
					<div className={styles.label}>{t('label.subtotal')}</div>
					<div
						className={clx(
							styles.value,
							'd-flex flex-column text-end'
						)}>
						<span className="lh-base">
							{currencyFormatter(
								invoice?.priceWithDiscount ??
									selectedPlan?.originalPrice ??
									0
							)}
						</span>
						<span className="text-gray-3 fw-400 fs-small">
							{`${t('title.per')} ${selectedPlan?.duration} ${t(
								'title.days'
							)}`}
						</span>
					</div>
				</div>
				<div className={styles.row}>
					<div className={styles.label}>{t('label.price')}</div>
					<div
						className={clx(
							styles.value,
							'd-flex flex-column text-end'
						)}>
						<span className="lh-base">
							{currencyFormatter(
								invoice?.priceAfterDiscount ??
									selectedPlan?.price ??
									0
							)}
						</span>
						<span className="text-primary fw-400 fs-small">
							{`${t('title.per')} ${
								selectedPlan?.remainDuration
							} ${t('title.days')}`}
						</span>
					</div>
				</div>
				<div className={styles.row}>
					<div className={styles.label}>
						{t('label.tax')}
						<span className="text-gray-3 fs-small">
							(
							{invoice?.taxPercent ??
								selectedPlan?.taxPercent ??
								0}
							%)
						</span>
					</div>
					<div className={styles.value}>
						{`+${currencyFormatter(
							invoice?.taxAmount ?? selectedPlan?.taxAmount ?? 0
						)}`}
					</div>
				</div>
				<div className={styles.row}>
					<h5 className={clx(styles.label, 'h5 text-gray-5 mb-0')}>
						{t('title.total')}
					</h5>
					<div className={clx(styles.value, 'd-flex flex-column')}>
						<span className="fs-5 fw-700 text-gray-5 lh-base text-end">
							{currencyFormatter(
								invoice?.totalAmount ?? selectedPlan?.total ?? 0
							)}
						</span>
						{(invoice?.totalAmount !== 0 ??
							selectedPlan?.total !== 0) && (
							<span className="text-gray-3 lh-1 fs-small fw-400 text-end">
								{t('label.tax-included')}
							</span>
						)}
					</div>
				</div>
			</div>
		</div>
	);
});

CreateSubscriptionVoucher.displayName = 'CreateSubscriptionVoucher';
