import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { names } from 'Constants';
import { Tab, Colors, Media, AspectRatio } from 'Types';
import { clx, intlDateFormat } from 'Utils';
import { useModal, useUpdateInternship } from 'Hooks';
import {
	Badge,
	Card,
	DecorationTab,
	FeaturePhoto,
	FontAwesome,
	LoadingContent,
	ModalBoxChangeImage
} from 'Elements';
import styles from 'partials/decoration/page-decoration-box.module.scss';

export const InternshipDecorationBox: FC = memo(() => {
	const { t } = useTranslation();
	const { jobs } = names;
	const { internship_id } = useParams();
	const { pathname } = useLocation();
	const [openModal, toggleModal] = useModal();
	const {
		data: fields,
		isFetching,
		updateInternshipFeature
	} = useUpdateInternship(internship_id as string);
	const defaultImage = '/assets/img/default-image.jpg';

	const defaultTab =
		pathname === `${jobs.internships.path}/${internship_id}` ||
		pathname === `${jobs.internships.path}/${internship_id}/general`;

	const tabs: Tab[] = [
		{
			id: 0,
			title: 'tab.general',
			path: 'general',
			isActive: defaultTab
		},
		{
			id: 1,
			title: 'tab.info',
			path: 'info',
			isActive: pathname === `${jobs.internships.path}/${internship_id}/info`
		},
		{
			id: 2,
			title: 'tab.terms',
			path: 'terms',
			isActive: pathname === `${jobs.internships.path}/${internship_id}/terms`
		},
		{
			id: 3,
			title: 'forms.event.description',
			path: 'description',
			isActive: pathname === `${jobs.internships.path}/${internship_id}/description`
		},
		{
			id: 4,
			title: 'tab.contact-person',
			path: 'contact-person',
			isActive: pathname === `${jobs.internships.path}/${internship_id}/contact-person`
		}
	];

	const getBadgeData = () => {
		let badgeColor = Colors.yellow;
		let badgeLabel = 'draft';
		if (fields) {
			if (fields.jobStatus?.statusTypes === 0) {
				badgeLabel = 'badge.draft';
			} else if (fields.jobStatus?.statusTypes === 1) {
				badgeLabel = 'badge.published';
				badgeColor = Colors.green;
			} else if (fields.jobStatus?.statusTypes === 2) {
				badgeLabel = 'badge.unPublished';
				badgeColor = Colors.muted;
			}
			if (fields.isExpired) {
				badgeLabel = 'badge.expired';
				badgeColor = Colors.muted;
			}
		}
		return {
			color: badgeColor,
			label: t(badgeLabel)
		};
	};

	const handleUploadImage = async (image_data: Media) => {
		if (image_data) {
			const data = {
				featuredVideoUrl: fields?.featuredVideoUrl,
				featuredPhotoUrl: image_data.url
			};
			await updateInternshipFeature(internship_id as string, data);
		}
	};

	const handleRemoveImage = async () => {
		const data = {
			featuredVideoUrl: fields?.featuredVideoUrl,
			featuredPhotoUrl: ''
		};
		await updateInternshipFeature(internship_id as string, data);
	};

	return (
		<Card tab className="mb-4">
			{isFetching && <LoadingContent />}
			<div className={clx(styles.body, 'flex-column flex-md-row gap-3')}>
				<FeaturePhoto
					image={fields?.featuredPhotoUrl}
					onClick={toggleModal}
				/>
				<div className="d-flex flex-column flex-grow-1 flex-wrap">
					<h3 className="h5 mb-0 lh-sm fw-400">
						{fields?.title ?? '...'}
					</h3>
					<div className="text-gray-3 fw-300 d-flex align-items-center flex-wrap">
						{!fields?.publishedVersion && (
							<div className={styles.metaItem}>
								<Badge color={getBadgeData().color}>
									{getBadgeData().label}
								</Badge>
							</div>
						)}
						<div className={styles.metaItem}>
							<FontAwesome
								icon="code-branch"
								size="lg"
							/>
							<span className="text-gray-3">
								{t('card.current-version')}:
							</span>
							<span className="text-gray-5">
								{fields?.currentVersion ?? '...'}
							</span>
						</div>
						{fields?.modifiedAt && (
							<div className={styles.metaItem}>
								<FontAwesome
									icon="calendar-days"
									size="lg"
								/>
								<span className="text-gray-3">
									{fields?.jobStatus?.statusTypes === 0
										? t('card.created-date')
										: t('card.last-update')}
									:
								</span>
								<span className="text-gray-5">
									{intlDateFormat(fields?.modifiedAt, {
										day: 'numeric',
										month: 'short',
										year: 'numeric',
										hour: 'numeric',
										minute: 'numeric'
									}) || '...'}
								</span>
							</div>
						)}
						<div className={styles.metaItem}>
							<FontAwesome icon="user" size="lg" />
							<span className="text-gray-3">{t('card.by')}:</span>
							<span className="text-gray-5">
								{fields?.jobStatus?.userName ?? '...'}
							</span>
						</div>
					</div>
				</div>
			</div>
			<DecorationTab tabs={tabs} />
			<ModalBoxChangeImage
				name="featuredPhotoUrl"
				isOpen={openModal}
				title="title.change-image"
				image={fields?.featuredPhotoUrl ?? ''}
				aspectRatio={AspectRatio['16/9']}
				defaultImage={defaultImage}
				onClose={toggleModal}
				onRemove={handleRemoveImage}
				onImageChange={handleUploadImage}
			/>
		</Card>
	);
});

InternshipDecorationBox.displayName = 'InternshipDecorationBox';
