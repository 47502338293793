import { useMediaQuery } from 'react-responsive';

export const useResponsive = () => {
	const isMobileAndBelow = useMediaQuery({ maxWidth: 576 });
	const isTabletAndBelow = useMediaQuery({ maxWidth: 768 });
	const isTabletAndAbove = useMediaQuery({ minWidth: 768 });
	const isDesktopAndBelow = useMediaQuery({ maxWidth: 992 });
	const isDesktopAndAbove = useMediaQuery({ minWidth: 992 });
	const isLargeScreenAndBelow = useMediaQuery({ maxWidth: 1200 });
	const isLargeScreen = useMediaQuery({ minWidth: 1200 });
	return {
		isMobileAndBelow,
		isTabletAndBelow,
		isTabletAndAbove,
		isDesktopAndBelow,
		isDesktopAndAbove,
		isLargeScreen,
		isLargeScreenAndBelow
	};
};
