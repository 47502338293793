import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FontAwesome } from 'Elements';

interface Props {
	type?: 'submit' | 'button';
	label?: string;
	savingLabel?: string;
	icon?: any | false;
	isDisable?: boolean;
	isSubmitting: boolean;
	[x: string]: any;
}

export const SubmitButton: FC<Props> = memo(
	({
		type = 'submit',
		label = 'button.save',
		savingLabel = 'button.saving',
		icon = 'floppy-disk',
		isDisable,
		isSubmitting,
		...props
	}) => {
		const { t } = useTranslation();
		return (
			<Button type={type} disabled={isDisable || isSubmitting} {...props}>
				{isSubmitting && (
					<span className="d-flex align-items-center gap-2">
						<FontAwesome spin spinPulse icon="spinner" size="lg" />
						{savingLabel ? t(savingLabel) : t(label)}
					</span>
				)}
				{!isSubmitting && (
					<span className="d-flex align-items-center gap-2">
						{icon && <FontAwesome icon={icon} size="lg" />}
						{t(label)}
					</span>
				)}
			</Button>
		);
	}
);

SubmitButton.displayName = 'SubmitButton';
