import { memo, FC } from 'react';
import { clx } from 'Utils';
import { useSelector } from 'Hooks';
import { AssetToolType, MarketingAsset } from 'Types';
import {
	IconCoaster,
	IconFlyer,
	IconPoster,
	IconSocialMedia,
	// IconSticker
} from 'Elements';
import styles from 'partials/card/asset-card.module.scss';

interface Props {
	asset: MarketingAsset;
	index: AssetToolType | -1;
	icon: string;
	onSelect: (toolType: AssetToolType) => void;
}

export const ToolboxAssetCard: FC<Props> = memo(
	({ asset, index, icon, onSelect }) => {
		const { selectedAsset } = useSelector((state) => state.marketing);
		const isActive = selectedAsset?.id === index;

		const onSelectToolbox = () => {
			onSelect?.(asset.toolType);
		};

		const components = {
			poster: IconPoster,
			// sticker: IconSticker,
			coaster: IconCoaster,
			socialMedia: IconSocialMedia,
			flyer: IconFlyer
		};

		const Icon = components[icon];

		return (
			<div className={styles.card} onClick={onSelectToolbox}>
				<div className={clx(styles.content, isActive && styles.active)}>
					<Icon />
				</div>
				<h6
					className={clx(
						styles.title,
						'mb-0 lh-base px-1',
						isActive && 'text-primary'
					)}>
					{asset.title}
				</h6>
			</div>
		);
	}
);

ToolboxAssetCard.displayName = 'ToolboxAssetCard';
