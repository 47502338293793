import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { fetch } from 'Utils';
import { queryKey } from 'Constants';
import { DataList, DataQueryParams, Payment } from 'Types';

const config = (options = {}) => {
	return {
		...{ keepPreviousData: false },
		...options
	};
};

export const usePayments = <T = DataList<Payment>>(
	{ status, pageSize, pageNumber, keyword = '' }: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<Payment>>
): UseQueryResult<DataList<Payment>, unknown> => {
	return useQuery(
		{
			queryKey: [queryKey.payments, { status, pageSize, pageNumber, keyword }],
			queryFn: () => fetch.paymentList(status, pageSize, pageNumber, keyword),
			...config(options)
		}
	);
};

export const usePayment = <T = Payment>(
	paymentId: string,
	options?: UseQueryOptions<T, any, Payment>
): UseQueryResult<Payment, unknown> => {
	return useQuery(
		{
			queryKey: [queryKey.payment, { id: paymentId }],
			queryFn: () => fetch.paymentDetails(paymentId),
			...config(options)
		}
	);
};

export const usePaymentStatus = <T = Payment>(
	paymentId: string,
	options?: UseQueryOptions<T, any, Payment>
): UseQueryResult<Payment, unknown> => {
	return useQuery(
		{
			queryKey: [queryKey.paymentStatus, { paymentId }],
			queryFn: () => fetch.paymentStatus(paymentId),
			...config(options)
		}
	);
};
