import { startTransition } from 'react';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import axios from 'Adapter';
import { queryKey } from 'Constants';
import { api, fetch, toastify } from 'Utils';
import {
	DataList,
	DataQueryParams,
	AgentEvent,
	EventFormData,
	CompaniesToInvite,
	EventParticipant,
	EventInvitation,
	CompanyEventInvitation,
	EventDetails,
	EventRegisterForm,
	EventLimit,
	CompanyParticipatedEvent
} from 'Types';

const config = (options = {}) => {
	return {
		...options,
		keepPreviousData: false
	};
};

export const useEvents = <T = DataList<AgentEvent>>(
	{
		status = '', // All = empty,Draft = 0, Published = 1, Canceled = 2, Finished = 99
		pageSize = 10,
		pageNumber = 1,
		keyword = ''
	}: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<AgentEvent>>
): UseQueryResult<DataList<AgentEvent>, unknown> => {
	return useQuery(
		{
			queryKey: [queryKey.events, { status, pageSize, pageNumber, keyword }],
			queryFn: () => fetch.eventList(status, pageSize, pageNumber, keyword),
			...config(options)
		}
	);
};

export const usePublishEvents = <T = DataList<AgentEvent>>(
	{ isFinished = false, pageSize = 10, pageNumber = 1 }: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<AgentEvent>>
): UseQueryResult<DataList<AgentEvent>, unknown> => {
	return useQuery(
		{
			queryFn: () => fetch.publishEventList(isFinished, pageSize, pageNumber),
			...config(options)
		}
	);
};

export const useEventDetails = <T = AgentEvent>(
	eventId: string,
	options?: UseQueryOptions<T, any, AgentEvent>
): UseQueryResult<AgentEvent, unknown> => {
	return useQuery(
		{
			queryKey: [queryKey.event, { eventId }],
			queryFn: () => fetch.eventDetails(eventId),
			...config(options)
		}
	);
};

export const useEventQrCode = <T = string>(
	eventId: string,
	options?: UseQueryOptions<T, any, string>
): UseQueryResult<string, unknown> => {
	return useQuery(
		{
			queryKey: [queryKey.eventQrCode, { id: eventId }],
			queryFn: () => fetch.eventQrCode(eventId),
			...config(options)
		}
	);
};

export const useDownloadEventQrCode = <T = string>(
	eventId: string,
	options?: UseQueryOptions<T, any, string>
): UseQueryResult<string, unknown> => {
	return useQuery(
		{
			queryKey: [queryKey.downloadEventQrCode, { id: eventId }],
			queryFn: () => fetch.downloadEventQrCode(eventId),
			...config(options)
		}
	);
};

export const useCompanyEventDetails = <T = EventDetails>(
	eventId: string,
	options?: UseQueryOptions<T, any, EventDetails>
): UseQueryResult<EventDetails, unknown> => {
	return useQuery(
		{
			queryKey: [queryKey.eventDetails, { eventId }],
			queryFn: () => fetch.companyEventDetails(eventId),
			...config(options)
		}
	);
};

export const useCompanyEventLimit = <T = EventLimit>(
	eventId: string,
	options?: UseQueryOptions<T, any, EventLimit>
): UseQueryResult<EventLimit, unknown> => {
	return useQuery(
		{
			queryKey: [queryKey.eventLimit, { eventId }],
			queryFn: () => fetch.companyEventLimit(eventId),
			...config(options)
		}
	);
};

export const useEvent = (eventId?: string) => {
	const query = useEventDetails(eventId as string, {
		enabled: !!eventId
	});
	const event = query.data;

	const submitEvent: (
		fields: EventFormData,
		onFinal?: () => void
	) => Promise<string> = async (fields, onFinal) => {
		return await axios.post(api.createEvent, fields).then((res) => {
			if (res.status === 200) {
				startTransition(() => {
					onFinal?.();
				});
			}
			return res.data;
		});
	};

	const updateEvent: (
		fields: AgentEvent,
		onFinal?: () => void
	) => Promise<void> = async (fields, onFinal) => {
		await axios
			.put(api.updateEvent(eventId as string), fields)
			.then((res) => {
				if (res.status === 200) {
					query.refetch();
					startTransition(() => {
						toastify('toastify.event.updated', {
							type: 'success'
						});
						onFinal?.();
					});
				}
			});
	};

	const publishEvent: (
		onFinal?: () => void,
		event_id?: string,
		refetch?: boolean
	) => Promise<void> = async (
		onFinal,
		event_id = eventId as string,
		refetch = true
	) => {
			await axios.post(api.publishEvent(event_id), {}).then((res) => {
				if (res.status === 200) {
					refetch && query.refetch();
					startTransition(() => {
						toastify('toastify.event.published', {
							type: 'success'
						});
						onFinal?.();
					});
				}
			});
		};

	const unPublishEvent: (onFinal?: () => void) => Promise<void> = async (
		onFinal
	) => {
		await axios
			.post(api.unPublishEvent(eventId as string), {})
			.then((res) => {
				if (res.status === 200) {
					query.refetch();
					startTransition(() => {
						toastify('toastify.event.unPublished', {
							type: 'success'
						});
						onFinal?.();
					});
				}
			});
	};

	const cancelEvent: (onFinal?: () => void) => Promise<void> = async (
		onFinal
	) => {
		await axios.post(api.cancelEvent(eventId as string), {}).then((res) => {
			if (res.status === 200) {
				query.refetch();
				startTransition(() => {
					toastify('toastify.event.canceled', {
						type: 'success'
					});
					onFinal?.();
				});
			}
		});
	};

	const saveAndPublishEvent: (
		fields: AgentEvent,
		onFinal?: () => void
	) => Promise<void> = async (fields, onFinal) => {
		const data: Partial<AgentEvent> = {
			...event,
			...fields
		};
		await axios
			.put(api.updateEvent(eventId as string), data)
			.then(async (res) => {
				if (res.status === 200) {
					await publishEvent(onFinal);
				}
			});
	};

	const submitAndPublishEvent: (
		fields: EventFormData,
		onFinal?: () => void
	) => Promise<string> = async (fields, onFinal) => {
		return await axios.post(api.createEvent, fields).then(async (res) => {
			let eventId = '';
			if (res.status === 200) {
				eventId = res.data;
				await publishEvent(onFinal, eventId, false);
			}
			return eventId;
		});
	};

	return {
		...query,
		submitEvent,
		updateEvent,
		publishEvent,
		unPublishEvent,
		cancelEvent,
		saveAndPublishEvent,
		submitAndPublishEvent
	};
};

export const useCompaniesToInvite = <T = DataList<CompaniesToInvite>>(
	{ pageSize = 10, pageNumber = 1, keyword = '' }: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<CompaniesToInvite>>
): UseQueryResult<DataList<CompaniesToInvite>, unknown> => {
	return useQuery(
		{
			queryKey: [queryKey.companiesToInvite, { pageSize, pageNumber, keyword }],
			queryFn: () => fetch.companiesToInvite(pageSize, pageNumber, keyword),
			...config({ keepPreviousData: false, ...options })
		}
	);
};

export const useParticipant = <T = DataList<EventParticipant>>(
	{
		eventId,
		isPartner = '',
		status = '',
		pageSize = 10,
		pageNumber = 1,
		keyword = ''
	}: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<EventParticipant>>
): UseQueryResult<DataList<EventParticipant>, unknown> => {
	return useQuery(
		{
			queryKey: [
				queryKey.participants,
				{ eventId, isPartner, status, pageSize, pageNumber, keyword }
			],
			queryFn: () =>
				fetch.participantList(
					eventId,
					isPartner,
					status,
					pageSize,
					pageNumber,
					keyword
				),
			...config(options)
		}
	);
};

export const useEventInvitations = <T = DataList<EventInvitation>>(
	{ eventId, pageSize = 10, pageNumber = 1 }: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<EventInvitation>>
): UseQueryResult<DataList<EventInvitation>, unknown> => {
	return useQuery(
		{
			queryKey: [queryKey.eventInvitations, { eventId, pageSize, pageNumber }],
			queryFn: () => fetch.eventInvitations(eventId, pageSize, pageNumber),
			...config(options)
		}
	);
};

export const useEventAllInvitations = <T = EventInvitation[]>(
	{ eventId, isPartner = 'true', status = '', keyword = '' }: DataQueryParams,
	options?: UseQueryOptions<T, any, EventInvitation[]>
): UseQueryResult<EventInvitation[], unknown> => {
	return useQuery(
		{
			queryKey: [queryKey.eventAllInvitations, { eventId, isPartner, status, keyword }],
			queryFn: () =>
				fetch.eventAllInvitations(
					eventId,
					isPartner,
					status,
					keyword as string
				),
			...config(options)
		}
	);
};

export const useCompanyEventInvitations = <
	T = DataList<CompanyEventInvitation>
>(
	{
		pageSize = 10,
		pageNumber = 1,
		finishedOnly = 'false',
		status = ''
	}: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<CompanyEventInvitation>>
): UseQueryResult<DataList<CompanyEventInvitation>, unknown> => {
	return useQuery(
		{
			queryKey: [
				queryKey.companyEventInvitations,
				{ pageSize, pageNumber, finishedOnly, status }
			],
			queryFn: () =>
				fetch.companyEventInvitations(
					pageSize,
					pageNumber,
					finishedOnly,
					status
				),
			...config(options)
		}
	);
};

export const useCompanyParticipatedEvent = <
	T = DataList<CompanyParticipatedEvent>
>(
	{ pageSize = 10, pageNumber = 1, status = '' }: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<CompanyParticipatedEvent>>
): UseQueryResult<DataList<CompanyParticipatedEvent>, unknown> => {
	return useQuery(
		{
			queryKey: [queryKey.companyParticipatedEvent, { pageSize, pageNumber, status }],
			queryFn: () => fetch.companyParticipatedEvent(pageSize, pageNumber, status),
			...config(options)
		}
	);
};

export const useMyEventInvitation = (eventId: string) => {
	// const acceptInvitation: (
	// 	fields: MyEventInvitationForm,
	// 	onFinal?: () => void,
	// 	onCatch?: () => void
	// ) => Promise<void> = async (fields, onFinal, onCatch) => {
	// 	await axios
	// 		.post(api.acceptEventInvitations(eventId), fields)
	// 		.then((res) => {
	// 			if (res.status === 200) {
	// 				startTransition(() => {
	// 					onFinal?.();
	// 				});
	// 			}
	// 		})
	// 		.catch(() => onCatch?.());
	// };

	// const rejectInvitation: (
	// 	onFinal?: () => void,
	// 	onCatch?: () => void
	// ) => Promise<void> = async (onFinal, onCatch) => {
	// 	console.log('eventId', eventId);
	// 	await axios
	// 		.post(api.rejectEventInvitations(eventId), {})
	// 		.then((res) => {
	// 			if (res.status === 200) {
	// 				startTransition(() => {
	// 					onFinal?.();
	// 				});
	// 			}
	// 		})
	// 		.catch(() => onCatch?.());
	// };

	const participateEvent: (
		fields: EventRegisterForm,
		onFinal?: () => void,
		onCatch?: () => void
	) => Promise<void> = async (fields, onFinal, onCatch) => {
		await axios
			.post(api.participateEvent(eventId), fields)
			.then((res) => {
				if (res.status === 200) {
					startTransition(() => {
						onFinal?.();
					});
				}
			})
			.catch(() => onCatch?.());
	};

	const cancelParticipateEvent: (
		onFinal?: () => void,
		onCatch?: () => void
	) => Promise<void> = async (onFinal, onCatch) => {
		await axios
			.post(api.cancelCompanyParticipation(eventId), {})
			.then((res) => {
				if (res.status === 200) {
					startTransition(() => {
						onFinal?.();
					});
				}
			})
			.catch(() => onCatch?.());
	};

	return {
		// acceptInvitation,
		// rejectInvitation,
		participateEvent,
		cancelParticipateEvent
	};
};
