import { memo, useState } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { containerVariants, names } from 'Constants';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useEvents } from 'Hooks';
import { clx, eventStatus, formatTimeToCET } from 'Utils';
import { Colors, BadgeStatus, AgentEvent, EventStatus } from 'Types';
import {
	Badge,
	Button,
	DataTable,
	DataTableBody,
	DataTableHead,
	DataTableLoading,
	DataTableRow,
	DataTableRowCell,
	EndOfList,
	ListingPage,
	NoData,
	Pagination,
	SystemErrorAlert,
	FontAwesome
} from 'Elements';

export const AgentListEvent = memo(() => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { events } = names;
	const [searchParams, setSearchParams] = useSearchParams();
	const params = Object.fromEntries([...searchParams]);
	const pageParam = searchParams.get('page') || 1;
	const queryParam = searchParams.get('q') || '';
	const statusParam = searchParams.get('status') || '';

	const [state, setState] = useState({
		pageSize: 10,
		currentPage: pageParam,
		keyword: queryParam,
		filterBy: statusParam,
		isRemoving: false
	});

	const getFilterByName = (filter_name: string) => {
		return eventStatus.find((filter) => filter.title === filter_name);
	};

	const { data, isFetching, isError } = useEvents({
		status: getFilterByName(statusParam)?.value,
		pageSize: state.pageSize,
		pageNumber: state.currentPage,
		keyword: state.keyword
	});

	const onChangePageSize = (pageSize: number) => {
		setSearchParams({ ...params, page: '1' });
		setState((prev) => ({
			...prev,
			pageSize,
			currentPage: 1
		}));
	};

	const handleSearch = (query: string) => {
		setSearchParams({
			...params,
			page: '1',
			q: query
		});
		setState((prev) => ({
			...prev,
			currentPage: 1,
			keyword: query
		}));
	};

	const handleFilterBy = (selected_filter: Partial<BadgeStatus>) => {
		setSearchParams({
			...params,
			page: '1',
			status: selected_filter.title?.toString() ?? ''
		});
		setState((prev) => ({
			...prev,
			filterBy: selected_filter.label?.toString() ?? '',
			currentPage: 1
		}));
	};

	const handlePageChange = (page: number) => {
		setSearchParams({ ...params, page: page.toString() });
		setState((prev) => ({
			...prev,
			currentPage: page
		}));
	};

	const getBadgeData = (event: AgentEvent) => {
		let badgeColor = Colors.yellow;
		let badgeLabel = 'draft';
		if (event) {
			if (event.eventStatus === EventStatus.Draft) {
				badgeLabel = 'badge.draft';
			} else if (event.eventStatus === EventStatus.Published) {
				badgeLabel = 'badge.published';
				badgeColor = Colors.green;
			} else if (event.eventStatus === EventStatus.Canceled) {
				badgeLabel = 'badge.canceled';
				badgeColor = Colors.red;
			}
			if (event.isFinished) {
				badgeLabel = 'badge.finished';
				badgeColor = Colors.muted;
			}
		}
		return {
			color: badgeColor,
			label: t(badgeLabel)
		};
	};

	const handleSelectRow = (row_id: string) => {
		return navigate(`${events.path}/${row_id}`, {
			state: { prevPath: `${events.path}` }
		});
	};

	return (
		<ListingPage
			totalItems={data?.totalItems}
			pageSizes={[5, 10, 20]}
			selectedPageSize={state.pageSize}
			filterOptions={eventStatus}
			selectedFilter={t(
				getFilterByName(statusParam)?.label ?? 'dropdown.all'
			)}
			onPageSizeChange={onChangePageSize}
			onFilterChange={handleFilterBy}
			onSearchChange={handleSearch}>
			<DataTable>
				{data && data?.totalItems > 0 && (
					<DataTableHead>
						<DataTableRowCell
							cellText="table.event.title"
							cellClassName="w-100 w-sm-30"
						/>
						<DataTableRowCell
							cellText="table.event.event-date"
							cellClassName="w-100 w-sm-25"
						/>
						<DataTableRowCell
							cellText="table.status"
							cellClassName="w-100 w-sm-15"
						/>
						<DataTableRowCell
							cellText="table.info"
							cellClassName="w-100 w-sm-15"
						/>
						<DataTableRowCell
							cellText="table.operation"
							cellClassName="w-100 w-sm-15"
						/>
					</DataTableHead>
				)}
				<DataTableBody>
					{!isFetching && (
						<motion.div
							variants={containerVariants}
							initial="hidden"
							animate="show">
							{data?.items?.map((event) => {
								return (
									<DataTableRow key={event.id}>
										<DataTableRowCell
											cellClassName="w-100 w-sm-30"
											cellHead="table.event.title">
											<div className="fw-500 py-1 ps-2 ps-sm-0 text-end text-sm-start">
												<Link
													to={`${events.path}/${event.id}`}
													state={{
														prevPath: `${events.path}`
													}}
													title={event.title}
													className="d-flex">
													{event.title}
												</Link>
											</div>
										</DataTableRowCell>
										<DataTableRowCell
											cellClassName="w-100 w-sm-25"
											cellHead="table.event.event-date">
											<div className="d-flex flex-column fw-300">
												<time
													className="mb-1"
													dateTime={event.startDate.toString()}>
													{formatTimeToCET(
														new Date(
															event.startDate
														)
													)}
												</time>
												<time
													dateTime={event.endDate.toString()}>
													{formatTimeToCET(
														new Date(event.endDate)
													)}
												</time>
											</div>
										</DataTableRowCell>
										<DataTableRowCell
											cellClassName="w-100 w-sm-15"
											cellHead="table.status"
											boxClassName="d-flex align-items-center flex-wrap gap-2">
											<Badge
												color={
													getBadgeData(event).color
												}>
												{getBadgeData(event).label}
											</Badge>
										</DataTableRowCell>
										<DataTableRowCell
											cellClassName="w-100 w-sm-15"
											boxClassName="d-flex align-items-center"
											cellHead="table.info">
											{(event.registrationStatus === 2 ||
												event.registrationStatus ===
													3) && (
												<span
													className={clx(
														'lh-sm fs-small d-inline-flex align-items-center',
														event.registrationStatus ===
															2 && 'text-green',
														event.registrationStatus ===
															3 && 'text-yellow'
													)}>
													{event.registrationStatus ===
														2 && (
														<FontAwesome
															icon="circle-small"
															size="sm"
															className="me-2 opacityPulse infinite flex-shrink-0"
														/>
													)}
													{event.registrationStatus ===
														2 &&
														t('text.on-boarding')}
													{event.registrationStatus ===
														3 &&
														t(
															'text.closed-on-boarding'
														)}
												</span>
											)}
										</DataTableRowCell>
										<DataTableRowCell
											cellClassName="w-100 w-sm-15"
											cellHead="table.operation">
											<Button
												size="sm"
												color={Colors['white-primary']}
												onClick={() =>
													handleSelectRow(event.id)
												}>
												{t('button.details')}
											</Button>
										</DataTableRowCell>
									</DataTableRow>
								);
							})}
						</motion.div>
					)}
					{isFetching && (
						<DataTableLoading
							widths={[30, 25, 15, 15, 15]}
							count={state.pageSize}
						/>
					)}
				</DataTableBody>
				{!isFetching && data?.totalItems === 0 && (
					<NoData message="title.no-event" />
				)}
			</DataTable>
			{!isFetching && <EndOfList data={data} />}
			{isError && <SystemErrorAlert />}
			{!isFetching && data && data?.totalPages > 1 && (
				<Pagination
					className="mt-3"
					totalItemsCount={data.totalItems}
					activePage={parseInt(state.currentPage.toString(), 10)}
					itemsCountPerPage={state.pageSize}
					onChange={handlePageChange}
				/>
			)}
		</ListingPage>
	);
});

AgentListEvent.displayName = 'AgentListEvent';
