import { memo, FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import axios from 'Adapter';
import { Colors, InternshipForm } from 'Types';
import { getCurrentLocale, api } from 'Utils';
import type { SubmitHandler } from 'react-hook-form/dist/types';
import { DatePicker, Button, FontAwesome } from 'Elements';
import styles from 'partials/step/step-container.module.scss';

interface Props {
	internshipId: string;
	dataInternship: InternshipForm;
	onPrev: () => void;
	onNext: (data: null) => void;
}

export const CreateInternshipTerms: FC<Props> = memo(
	({ onNext, internshipId }) => {
		const { t } = useTranslation();

		const [valid, setValid] = useState<any>(true);

		const [listInputTerms, setListInputTerms] = useState<any>([
			{
				sessions: [
					{
						sessionStartDate: '',
						sessionEndDate: '',
						startTime: '',
						endTime: ''
					}
				],
				title: ''
			}
		]);
		const onChangeHandler = (
			key: any,
			value: any,
			index: any,
			indexSession: any
		) => {
			const listNew: any = [...listInputTerms];
			if (key !== 'title') {
				listNew[index].sessions[indexSession][key] = value;
				if (key === 'sessionStartDate') {
					listNew[index].sessions[indexSession]['sessionEndDate'] =
						value;
					listNew[index].sessions[indexSession]['startTime'] = '';
					listNew[index].sessions[indexSession]['endTime'] = '';
				}
				if (key === 'startTime') {
					listNew[index].sessions[indexSession]['endTime'] = '';
					listNew[index].sessions[indexSession]['sessionStartDate'] =
						new Date(
							new Date(
								listNew[index].sessions[indexSession][
									'sessionStartDate'
								]
							).setHours(new Date(value).getHours())
						);
					listNew[index].sessions[indexSession]['sessionStartDate'] =
						new Date(
							new Date(
								listNew[index].sessions[indexSession][
									'sessionStartDate'
								]
							).setMinutes(new Date(value).getMinutes())
						);
				}
				if (key === 'endTime') {
					listNew[index].sessions[indexSession]['sessionEndDate'] =
						new Date(
							new Date(
								listNew[index].sessions[indexSession][
									'sessionEndDate'
								]
							).setHours(new Date(value).getHours())
						);
					listNew[index].sessions[indexSession]['sessionEndDate'] =
						new Date(
							new Date(
								listNew[index].sessions[indexSession][
									'sessionEndDate'
								]
							).setMinutes(new Date(value).getMinutes())
						);
				}
			} else {
				listNew[index][key] = value;
			}
			setListInputTerms(listNew);
		};

		const addNewTerm = () => {
			const newList: any = [...listInputTerms];
			newList.push({
				sessions: [
					{
						sessionStartDate: '',
						sessionEndDate: '',
						startTime: '',
						endTime: ''
					}
				],
				title: ''
			});

			setListInputTerms(newList);
			setValid(true);
		};

		const addNewSessions = (index: any) => {
			const newList: any = [...listInputTerms];
			newList[index].sessions.push({
				sessionStartDate: '',
				sessionEndDate: ''
			});

			setListInputTerms(newList);
			setValid(true);
		};

		const handleRemove = (index) => {
			const deleteRow = [...listInputTerms];
			deleteRow.splice(index, 1);
			setListInputTerms(deleteRow);
		};

		const handleRemoveSession = (index, indexSession) => {
			const deleteRow = [...listInputTerms];
			deleteRow[index].sessions.splice(indexSession, 1);
			setListInputTerms(deleteRow);
		};

		const onSubmit: SubmitHandler<any> = (e) => {
			e.preventDefault();
			setValid(true);
			const newListInputTerms = JSON.parse(
				JSON.stringify(listInputTerms)
			);
			newListInputTerms.forEach((element: any) => {
				element.sessions?.forEach((item: any) => {
					delete item.startTime;
					delete item.endTime;
					item.sessionEndDate = new Date(
						item.sessionEndDate as string
					);
					item.sessionStartDate = new Date(
						item.sessionStartDate as string
					);
				});
			});
			newListInputTerms?.forEach(async (item, index) => {
				if (item?.sessions?.length !== 0) {
					await axios
						.post(api.addTermInternship(internshipId), {
							sessions: item.sessions,
							title: item.title
						})
						.then((res) => {
							if (res.status === 200) {
								newListInputTerms.length - 1 === index &&
									onNext?.(newListInputTerms as any);
								setValid(false);
							}
						});
				}
			});
		};

		useEffect(() => {
			const validation = listInputTerms?.map((term: any) => {
				return term?.sessions?.every(
					(item) =>
						term.title &&
						item.startTime &&
						item.sessionStartDate &&
						item.endTime
				);
			});
			setValid(!validation.every((item) => item));
		}, [listInputTerms]);

		const getLocalFormat = () => {
			const currentLocale = getCurrentLocale().code;
			if (currentLocale === 'de') {
				return 'dd.MM.yyyy';
			}
			return 'yyyy-MM-dd';
		};

		return (
			<form onSubmit={onSubmit} className={styles.form}>
				<div>
					{listInputTerms?.map((term: any, index: any) => {
						return (
							<div
								key={index}
								className="mb-3"
								style={{
									border: '1px solid #F2F2F4',
									padding: '16px 24px',
									borderRadius: '8px'
								}}>
								<div className="d-flex justify-content-between align-items-center">
									<Col md={5}>
										{' '}
										<FormGroup>
											<Label htmlFor="title">
												{t('forms.title')}
											</Label>
											<input
												type="text"
												className="inputbox w-97"
												value={term.title}
												onChange={(e: any) => {
													onChangeHandler(
														'title',
														e.target.value,
														index,
														index
													);
												}}
											/>
										</FormGroup>
									</Col>
									{parseInt(listInputTerms?.length) > 1 && (
										<div>
											<Button
												color={Colors['white-red']}
												onClick={() =>
													handleRemove(index)
												}>
												<FontAwesome
													icon="trash-can"
													size="lg"
												/>
											</Button>
										</div>
									)}
								</div>
								{term?.sessions?.map(
									(session: any, indexSession: any) => {
										return (
											<Row
												key={indexSession}
												className="d-flex align-items-center">
												<Col sm={7} md={3} lg={3}>
													{' '}
													<FormGroup>
														<Label htmlFor="sessionStartDate">
															{t(
																'forms.session-date'
															)}
														</Label>
														<DatePicker
															disabled={
																indexSession ===
																0
																	? false
																	: term
																			?.sessions[
																			parseInt(
																				indexSession
																			) -
																				1
																	  ]
																			.sessionStartDate
																	? false
																	: true
															}
															dateFormat={getLocalFormat()}
															locale={
																getCurrentLocale()
																	.lang
															}
															value={
																session.sessionStartDate
															}
															className={
																'inputbox w-100'
															}
															minDate={
																indexSession ===
																0
																	? new Date()
																	: term
																			?.sessions[
																			parseInt(
																				indexSession
																			) -
																				1
																	  ]
																			.sessionStartDate
															}
															onChange={(
																val: Date
															) => {
																onChangeHandler(
																	'sessionStartDate',
																	val,
																	index,
																	indexSession
																);
															}}
														/>
													</FormGroup>
												</Col>
												<Col sm={7} md={3} lg={2}>
													<FormGroup className="w-100">
														<Label htmlFor="sessionStartTime">
															{t(
																'forms.start-time'
															)}
														</Label>
														<DatePicker
															disabled={
																session?.sessionStartDate
																	? false
																	: true
															}
															value={
																session.startTime
															}
															onChange={(
																time: any
															) => {
																onChangeHandler(
																	'startTime',
																	time,
																	index,
																	indexSession
																);
															}}
															className={
																'inputbox w-100'
															}
															showTimeSelect
															showTimeSelectOnly
															timeFormat="HH:mm"
															dateFormat="HH:mm"
															minTime={
																new Date(
																	session?.sessionStartDate
																).setHours(
																	0,
																	0,
																	0,
																	0
																) ===
																new Date().setHours(
																	0,
																	0,
																	0,
																	0
																)
																	? new Date().setHours(
																			new Date().getHours(),
																			new Date().getMinutes()
																	  )
																	: new Date().setHours(
																			0,
																			0,
																			0
																	  )
															}
															maxTime={new Date().setHours(
																23,
																59,
																59
															)}
														/>
													</FormGroup>
												</Col>
												<Col sm={7} md={3} lg={2}>
													<FormGroup className="w-100">
														<Label htmlFor="endTime">
															{t(
																'forms.end-time'
															)}
														</Label>
														<DatePicker
															disabled={
																session?.startTime
																	? false
																	: true
															}
															minTime={
																session?.startTime
																	? new Date(
																			session?.startTime
																	  ).getTime()
																	: new Date().setHours(
																			0,
																			0,
																			0
																	  )
															}
															maxTime={new Date().setHours(
																23,
																59,
																59
															)}
															value={
																session.endTime
															}
															onChange={(
																time: any
															) => {
																onChangeHandler(
																	'endTime',
																	time,
																	index,
																	indexSession
																);
															}}
															className={
																'inputbox w-100'
															}
															showTimeSelect
															showTimeSelectOnly
															timeFormat="HH:mm"
															dateFormat="HH:mm"
														/>
													</FormGroup>
												</Col>
												{parseInt(
													term?.sessions.length
												) > 1 && (
													<Col md={2}>
														<FormGroup className="w-100 mt-4">
															<Button
																color={
																	Colors[
																		'white-red'
																	]
																}
																onClick={() =>
																	handleRemoveSession(
																		index,
																		indexSession
																	)
																}>
																<FontAwesome
																	icon="trash-can"
																	size="lg"
																/>
															</Button>
														</FormGroup>
													</Col>
												)}
											</Row>
										);
									}
								)}
								<Button
									size="sm"
									color={Colors['white-primary']}
									disabled={valid}
									label={t('button.addNewSessions') as string}
									onClick={() => {
										addNewSessions(index);
									}}
								/>
							</div>
						);
					})}
					<Button
						size="sm"
						disabled={valid}
						color={Colors['white-primary']}
						label={t('button.addNewTerm') as string}
						onClick={addNewTerm}
					/>
				</div>
				<div className="d-flex align-items-center lh-base justify-content-end">
					<Button type="submit" className="gap-2" disabled={valid}>
						{t('wizard.next')}{' '}
						<FontAwesome icon="chevron-right" size="1x" />
					</Button>
				</div>
			</form>
		);
	}
);

CreateInternshipTerms.displayName = 'CreateInternshipTerms';
