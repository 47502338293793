import { FC, memo } from 'react';
import { clx } from 'Utils';
import { FontAwesome } from 'Elements';
import styles from 'partials/shared/button/check-button.module.scss';

interface Props {
	title: string;
	isActive?: boolean;
	onClick?: () => void;
}

export const CheckButtonToggler: FC<Props> = memo(
	({ title, isActive = false, onClick }): JSX.Element => {
		return (
			<span
				role="button"
				aria-label={title}
				className={clx(styles.button, isActive && styles.active)}
				onClick={onClick}>
				<FontAwesome
					icon={isActive ? 'check' : 'plus'}
					size="lg"
					className="me-2 flex-shrink-0"
				/>
				{title}
			</span>
		);
	}
);

CheckButtonToggler.displayName = 'CheckButtonToggler';
