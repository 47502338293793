import { FC, useState, useCallback, useEffect, memo } from 'react';
import { useDispatch } from 'Hooks';
import { setSubscriptionAgent } from 'Actions';
import { SubscriptionAgentSetting } from 'Types';
import {
	CreateSubscriptionSearchAgent,
	LoadingSpinner,
	NoData,
	SubscriptionAgentCard
} from 'Elements';
import styles from 'partials/step/step-container.module.scss';

interface Props {
	agentList?: SubscriptionAgentSetting[];
	selectedAgent: SubscriptionAgentSetting | null;
	onNext: () => void;
}
export const CreateSubscriptionAgent: FC<Props> = memo(
	({ selectedAgent, agentList, onNext }) => {
		const dispatch = useDispatch();
		const [isLoading, setIsLoading] = useState(true);
		const [filteredList, setFilteredList] = useState<
			SubscriptionAgentSetting[] | undefined
		>([]);

		const handleSearch = useCallback(
			(keyword: string) => {
				const newList = keyword
					? agentList?.filter((agent) =>
							agent.name
								.toLowerCase()
								.includes(keyword.toLowerCase())
					  )
					: agentList;
				setFilteredList(newList);
			},
			[agentList]
		);

		const handleSelectAgent = (agent: SubscriptionAgentSetting) => {
			onNext?.();
			dispatch(setSubscriptionAgent(agent));
		};

		useEffect(() => {
			if (agentList && agentList?.length > 0) {
				setFilteredList(agentList);
				setIsLoading(false);
			}
		}, [agentList]);

		return (
			<div className={styles.form}>
				{isLoading && <LoadingSpinner />}
				{!isLoading && (
					<div className="d-flex flex-column gap-4">
						<CreateSubscriptionSearchAgent
							onSubmit={handleSearch}
							totalItem={filteredList?.length}
						/>
						{filteredList && filteredList?.length > 0 && (
							<div>
								{filteredList.map((agent) => {
									return (
										<SubscriptionAgentCard
											key={agent.agentId}
											agent={agent}
											selected={
												selectedAgent?.agentId ===
												agent.agentId
											}
											onSelect={handleSelectAgent}
										/>
									);
								})}
							</div>
						)}
						{agentList &&
							agentList?.length > 0 &&
							filteredList?.length === 0 && (
								<NoData iconClassName={styles.no_data} />
							)}
					</div>
				)}
			</div>
		);
	}
);

CreateSubscriptionAgent.displayName = 'CreateSubscriptionAgent';
