import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Colors, ModalProps } from 'Types';
import { Button, ModalBox, ModalBoxFooter } from 'Elements';

interface Props extends ModalProps {
	description: string;
}

export const ModalBoxPartnerApprovalFeedback: FC<Props> = memo(
	({ isOpen, onClose, description }) => {
		const { t } = useTranslation();

		return (
			<ModalBox
				isOpen={isOpen}
				onClose={onClose}
				title={t('title.agent-feedback')}>
				<div
					className="lh-md p-3 bg-gray-3_o-1 round-10 pre-line"
					dangerouslySetInnerHTML={{
						__html: description
					}}
				/>
				<ModalBoxFooter className="justify-content-center mt-4">
					<Button
						color={Colors['gray-4']}
						label={t('button.close') as string}
						onClick={onClose}
					/>
				</ModalBoxFooter>
			</ModalBox>
		);
	}
);

ModalBoxPartnerApprovalFeedback.displayName = 'ModalBoxPartnerApprovalFeedback';
