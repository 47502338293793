import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { fetch } from 'Utils';
import { queryKey } from 'Constants';
import { Activity, DataList, DataQueryParams } from 'Types';

const config = (options = {}) => {
	return {
		...{ keepPreviousData: false },
		...options
	};
};

export const useActivities = <T = DataList<Activity>>(
	{ pageSize, pageNumber, keyword = '' }: DataQueryParams,
	options?: UseQueryOptions<T, any, DataList<Activity>>
): UseQueryResult<DataList<Activity>, unknown> => {
	return useQuery({
		queryKey: [queryKey.activities, { pageSize, pageNumber, keyword }],
		queryFn: () => fetch.activityList(pageSize, pageNumber, keyword),
		...config(options)
	}
	);
};
