import { FC, memo, useState, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { MenuItem, UserCategory } from 'Types';
import { toggleSidebar } from 'Actions';
import { clx } from 'Utils';
import { companyMenu, agentMenu } from 'Data';
import {
	useDispatch,
	useGetB2bUserProfileQuery,
	useModal,
	useDelayUnmount,
	useAgentProfile
} from 'Hooks';
import {
	LoadingSpinner,
	Scrollbar,
	SidebarMainItem,
	ModalBoxSupport,
	FontAwesome
} from 'Elements';
import styles from 'partials/sidebar/sidebar.module.scss';

interface Props {
	isOpen?: boolean;
}

export const Sidebar: FC<Props> = memo(({ isOpen }) => {
	const { t } = useTranslation();
	const location = useLocation();
	const dispatch = useDispatch();
	const [openModal, toggleModal] = useModal();
	const shouldRenderModal = useDelayUnmount(openModal, 350);
	const defaultMenu: MenuItem = {
		id: 'dashboard',
		label: 'menu.dashboard',
		icon: 'gauge-low',
		to: `/`
	} as const;
	const [activeMenu, setActiveMenu] = useState<MenuItem>(defaultMenu);
	const { data: user, isLoading } = useGetB2bUserProfileQuery();
	const userType = user?.userCategory?.toLowerCase() as UserCategory;
	const userMenu = userType === 'company' ? companyMenu : agentMenu;
	const { data: agent } = useAgentProfile({
		enabled: userType === 'agent'
	});
	const isPremium = agent?.commissionGroup?.isPremium ?? false;

	const handleActiveMenu = (menu: MenuItem) => {
		setActiveMenu(activeMenu === menu ? defaultMenu : menu);
	};

	useLayoutEffect(() => {
		dispatch(toggleSidebar(false));
	}, [location.pathname, dispatch]);

	return (
		<aside
			className={clx(
				styles.sidebar,
				isOpen && styles.open,
				!isOpen && styles.close
			)}>
			<Scrollbar className={styles.scrollbar}>
				{isLoading && <LoadingSpinner />}
				{!isLoading && userMenu && (
					<div className="d-flex flex-column h-100 justify-content-between">
						<ul className={styles.list}>
							{userMenu.map((menu) => {
								return (
									<SidebarMainItem
										key={menu.id}
										menu={menu}
										index={menu.id}
										isPremium={isPremium}
										activeMenu={activeMenu as MenuItem}
										onClick={handleActiveMenu}
									/>
								);
							})}
						</ul>
					</div>
				)}
			</Scrollbar>
			<div
				role="button"
				className={clx(
					styles.support,
					'bg-primary px-3 d-flex align-items-center gap-3'
				)}
				onClick={toggleModal}>
				<FontAwesome icon="user-headset" size="xl" fixedWidth />
				{t('menu.contact-support')}
			</div>
			{shouldRenderModal && (
				<ModalBoxSupport isOpen={openModal} onClose={toggleModal} />
			)}
		</aside>
	);
});

Sidebar.displayName = 'Sidebar';
