import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { getCurrentLocale } from 'Utils';
import { PageHeadingSearch } from 'Elements';

interface Props {
	totalItem?: number;
	onSubmit?: (keyword: string) => void;
}

export const CreateSubscriptionSearchAgent: FC<Props> = memo(
	({ onSubmit, totalItem = 0 }) => {
		const { t } = useTranslation();
		return (
			<div className="d-flex align-items-center gap-3 flex-wrap">
				<PageHeadingSearch onSubmit={onSubmit} />
				{totalItem > 0 && (
					<div className="ms-auto d-flex align-items-center gap-1 text-gray-4 fw-300">
						<span className="fw-500 text-gray-5">
							{new Intl.NumberFormat(
								getCurrentLocale().lang
							).format(totalItem)}
						</span>
						{t('title.items')}
					</div>
				)}
			</div>
		);
	}
);

CreateSubscriptionSearchAgent.displayName = 'CreateSubscriptionSearchAgent';
