import { memo } from 'react';
import { clx } from 'Utils';
import { LoadingMask } from 'Elements';
import styles from 'partials/shared/dropdown/user-dropdown.module.scss';

export const UserDropDownLoading = memo(() => {
	return (
		<div>
			<LoadingMask className={clx(styles.loadingMd, 'mb-2')} />
			<LoadingMask className={styles.loadingSm} />
		</div>
	);
});

UserDropDownLoading.displayName = 'UserDropDownLoading';
