import { updateObj } from 'Utils';

enum ActionType {
	SET_FIELDS = 'SET_FIELDS',
	SET_TITLE = 'SET_TITLE',
	SET_DESCRIPTION = 'SET_DESCRIPTION',
	SET_SLOGAN = 'SET_SLOGAN'
}

// An interface for our actions
interface Action {
	type: ActionType;
	payload: Partial<State>;
	prorfile_type?: 'company' | 'agent';
}

interface State {
	companyText?: string;
	companyOverviewDescription?: string;
	slogan?: string;
	headerTitle?: string;
	description?: string;
}

// Reducer
// @ts-ignore
export const ProfileHeroHeaderReducer: (
	state: State,
	action: Action
) => State = (state, action) => {
	const { payload, type, prorfile_type } = action;
	switch (type) {
		case ActionType.SET_FIELDS:
			if (prorfile_type === 'company') {
				return updateObj(state, {
					companyText: payload.companyText,
					companyOverviewDescription:
						payload.companyOverviewDescription,
					slogan: payload.slogan
				});
			} else {
				return updateObj(state, {
					headerTitle: payload.headerTitle,
					description: payload.description,
					slogan: payload.slogan
				});
			}
		case ActionType.SET_TITLE:
			return { ...state, companyText: payload };
		case ActionType.SET_DESCRIPTION:
			return { ...state, companyOverviewDescription: payload };
		case ActionType.SET_SLOGAN:
			return { ...state, slogan: payload };
		default:
			state;
	}
};

// Actions
export const setHeroFields: (
	payload: Partial<State>,
	type?: 'company' | 'agent'
) => Action = (payload, type = 'company') => {
	return {
		type: ActionType.SET_FIELDS,
		payload,
		prorfile_type: type
	};
};

export const setTitle = (companyText: string) => {
	return {
		type: ActionType.SET_TITLE,
		payload: companyText
	};
};

export const setDescription = (companyOverviewDescription: string) => {
	return {
		type: ActionType.SET_DESCRIPTION,
		payload: companyOverviewDescription
	};
};

export const setSlogan = (slogan: string) => {
	return {
		type: ActionType.SET_SLOGAN,
		payload: slogan
	};
};
