import { memo, useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSubscriptions } from 'Hooks';
import {
	EndOfList,
	ListingPage,
	LoadingSpinner,
	NoData,
	Pagination,
	SubscriptionCard
} from 'Elements';

export const ListSubscription = memo(() => {
	const [searchParams, setSearchParams] = useSearchParams();
	const params = Object.fromEntries([...searchParams]);
	const pageParam = searchParams.get('page') || 1;
	const queryParam = searchParams.get('q') || '';
	const statusParam = searchParams.get('status') || '';

	const [state, setState] = useState({
		pageSize: 12,
		currentPage: pageParam,
		keyword: queryParam,
		filterBy: statusParam
	});

	const { data: subscriptions, isFetching } = useSubscriptions({
		pageSize: state.pageSize,
		pageNumber: state.currentPage,
		keyword: state.keyword
	});

	const onChangePageSize = (pageSize: number) => {
		setSearchParams({ ...params, page: '1' });
		setState((prev) => ({
			...prev,
			pageSize,
			currentPage: 1
		}));
	};

	const handleSearch = useCallback(
		(query: string) => {
			setSearchParams({
				...params,
				page: '1',
				q: query
			});
			setState((prev) => ({
				...prev,
				currentPage: 1,
				keyword: query
			}));
		},
		[params, setSearchParams]
	);

	const handleFilterBy = (selected_filter: any) => {
		setSearchParams({
			...params,
			page: '1',
			status: selected_filter.title?.toString() ?? ''
		});
		setState((prev) => ({
			...prev,
			filterBy: selected_filter.label?.toString() ?? '',
			currentPage: 1
		}));
	};

	const handlePageChange = (page: number) => {
		setSearchParams({ ...params, page: page.toString() });
		setState((prev) => ({
			...prev,
			currentPage: page
		}));
	};

	return (
		<ListingPage
			totalItems={subscriptions?.totalItems}
			pageSizes={[4, 8, 12]}
			selectedPageSize={state.pageSize}
			onPageSizeChange={onChangePageSize}
			onFilterChange={handleFilterBy}
			onSearchChange={handleSearch}>
			{!isFetching &&
				subscriptions?.items?.map((subscription) => (
					<SubscriptionCard
						key={subscription.id}
						subscription={subscription}
					/>
				))}
			{isFetching && <LoadingSpinner />}
			{!isFetching && <EndOfList data={subscriptions} />}
			{!isFetching && subscriptions?.totalItems === 0 && (
				<NoData message="title.no-subscription" />
			)}
			{!isFetching && subscriptions && subscriptions?.totalPages > 1 && (
				<Pagination
					className="mt-3"
					totalItemsCount={subscriptions?.totalItems}
					activePage={parseInt(state.currentPage.toString(), 10)}
					itemsCountPerPage={state.pageSize}
					onChange={handlePageChange}
				/>
			)}
		</ListingPage>
	);
});

ListSubscription.displayName = 'ListSubscription';
