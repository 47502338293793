import { FC, memo, SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
	fill?: string;
	className?: string;
}

export const ShapeDividerTop: FC<Props> = memo(
	({ fill = '#fff', className, ...props }): JSX.Element => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 1921 127.872"
				className={className}
				{...props}>
				<path
					d="M-2161.6,4595.221h-1920v18l407,99c33.377,8.107,67.751,11.6,102,9l1411-108Z"
					transform="translate(4082.096 -4594.721)"
					fill={fill}
					strokeMiterlimit="10"
					strokeWidth="1"
				/>
			</svg>
		);
	}
);

ShapeDividerTop.displayName = 'ShapeDividerTop';
