import { memo, FC, useEffect } from 'react';
import {
	Html5QrcodeCameraScanConfig,
	QrcodeErrorCallback,
	QrcodeSuccessCallback
} from 'html5-qrcode';
import {
	Html5QrcodeScanner,
	Html5QrcodeScannerConfig
} from 'html5-qrcode/esm/html5-qrcode-scanner';
import styles from 'partials/feature/html5-qrcode.module.scss';

type HTML5QrCodeConfig = Html5QrcodeScannerConfig & Html5QrcodeCameraScanConfig;

type Props = {
	onSuccess: QrcodeSuccessCallback;
	onError?: QrcodeErrorCallback;
	config?: HTML5QrCodeConfig;
	isFinished?: boolean;
};

export const Html5QrcodeReader: FC<Props> = memo(
	({ onSuccess, onError, config }) => {
		const qrcodeRegionId = 'html5qr-code-full-region';

		useEffect(() => {
			// Success callback is required.
			if (!onSuccess) {
				throw 'onSuccess is required callback.';
			}
			const oldRegion = document.getElementById('qr-shaded-region');
			const verbose = false;

			if (oldRegion) {
				oldRegion.remove();
			}
			// const html5QrCode = new Html5Qrcode(qrcodeRegionId);

			// const handleStart = async () => {
			// 	await html5QrCode.start(
			// 		{ facingMode: 'environment' },
			// 		config,
			// 		onSuccess,
			// 		onError
			// 	);
			// };

			// handleStart();

			// const handleStop = () => {
			// 	// html5QrCode.stop();
			// 	if (!html5QrCode.isScanning) {
			// 		html5QrCode.clear();
			// 	}
			// };

			// return () => {
			// 	handleStop();
			// };
			const html5QrcodeScanner = new Html5QrcodeScanner(
				qrcodeRegionId,
				config,
				verbose
			);
			html5QrcodeScanner.render(onSuccess, onError);

			// cleanup function when component will unmount
			return () => {
				html5QrcodeScanner.clear().catch((error) => {
					console.error(
						'Failed to clear html5QrcodeScanner. ',
						error
					);
				});
			};
		}, []);

		return <div id={qrcodeRegionId} className={styles.qrbox} />;
	}
);

Html5QrcodeReader.displayName = 'Html5QrcodeReader';
