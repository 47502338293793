import { memo, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { clx } from 'Utils';
import { FontAwesome } from 'Elements';
import styles from 'partials/event/event-status-bar.module.scss';

interface Props {
	className?: string;
	isCanceled?: boolean;
	isFinished?: boolean;
}

export const EventStatusBar: FC<Props> = memo(
	({ isCanceled, isFinished, className }) => {
		const { t } = useTranslation();
		return (
			<div
				className={clx(
					className,
					styles.box,
					isCanceled && styles.canceled,
					isFinished && styles.finished,
					'd-flex align-items-center'
				)}>
				<span
					className={clx(
						'd-flex align-items-center me-3 fw-500',
						isCanceled && 'text-red',
						isFinished && 'text-gray-5'
					)}>
					<FontAwesome
						icon={isCanceled ? 'warning' : 'circle-info'}
						size="lg"
						className="me-2"
					/>
					{isCanceled && t('label.event.canceled')}
					{isFinished && t('label.event.finished')}
				</span>
				{isCanceled && t('text.event.canceled')}
				{isFinished && t('text.event.finished')}
			</div>
		);
	}
);

EventStatusBar.displayName = 'EventStatusBar';
