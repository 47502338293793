import { memo, FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import type { SubmitHandler } from 'react-hook-form/dist/types';
import { usePrompt } from 'Hooks';
import { JobFormDescription } from 'Types';
import { RichTextController, StepNavigation, SwitchController } from 'Elements';
import styles from 'partials/step/step-container.module.scss';

interface Props {
	fields: JobFormDescription;
	onPrev: () => void;
	onNext: (data: JobFormDescription) => void;
}

export const CreateJobDescription: FC<Props> = memo(
	({ fields, onPrev, onNext }) => {
		const { t } = useTranslation();
		const {
			watch,
			control,
			handleSubmit,
			formState: { isDirty }
		} = useForm<JobFormDescription>({
			defaultValues: fields
		});

		usePrompt(t('forms.leave-screen-massage'), isDirty);

		const onSubmit: SubmitHandler<JobFormDescription> = (data) => {
			onNext?.(data);
		};

		return (
			<form
				onSubmit={handleSubmit(onSubmit)}
				className={styles.form}
				noValidate>
				<Row>
					<Col xxl={8}>
						<FormGroup className="mb-4">
							<div className="d-flex align-items-center mb-2">
								<Label htmlFor="description" className="mb-0">
									{t('forms.job.desc')}
								</Label>
								<SwitchController
									control={control}
									name="showDescription"
									boxClassName="ms-auto"
									side="right"
									label={t('label.job.show-in-job-page')}
								/>
							</div>
							<RichTextController
								control={control}
								name="description"
								readOnly={!watch('showDescription')}
							/>
						</FormGroup>
						<FormGroup className="mb-4">
							<div className="d-flex align-items-center mb-2">
								<Label htmlFor="requirements" className="mb-0">
									{t('forms.job.requirements')}
								</Label>
								<SwitchController
									control={control}
									name="showRequirements"
									boxClassName="ms-auto"
									side="right"
									label={t('label.job.show-in-job-page')}
								/>
							</div>
							<RichTextController
								control={control}
								name="requirements"
								readOnly={!watch('showRequirements')}
							/>
						</FormGroup>
						<FormGroup className="mb-4">
							<div className="d-flex align-items-center mb-2">
								<Label
									htmlFor="responsibilities"
									className="mb-0">
									{t('forms.job.responsibilities')}
								</Label>
								<SwitchController
									control={control}
									name="showResponsibilities"
									boxClassName="ms-auto"
									side="right"
									label={t('label.job.show-in-job-page')}
								/>
							</div>
							<RichTextController
								control={control}
								name="responsibilities"
								readOnly={!watch('showResponsibilities')}
							/>
						</FormGroup>
						<FormGroup className="mb-4">
							<div className="d-flex align-items-center mb-2">
								<Label htmlFor="benefits" className="mb-0">
									{t('forms.job.benefits')}
								</Label>
								<SwitchController
									control={control}
									name="showBenefits"
									boxClassName="ms-auto"
									side="right"
									label={t('label.job.show-in-job-page')}
								/>
							</div>
							<RichTextController
								control={control}
								name="benefits"
								readOnly={!watch('showBenefits')}
							/>
						</FormGroup>
						<StepNavigation onPrev={onPrev} />
					</Col>
				</Row>
			</form>
		);
	}
);

CreateJobDescription.displayName = 'CreateJobDescription';
