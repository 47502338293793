import { CSSProperties, FC } from 'react';
import { Col, Row } from 'reactstrap';
import { clx } from 'Utils';
import { LoadingMask, SkeletonWrapper } from 'Elements';
import styles from 'partials/homepage/card/agent-job-card.module.scss';

type Props = {
	count?: string | number;
};

const Skeleton = ({ style }: { style: CSSProperties }) => {
	return (
		<article className={styles.card} style={style}>
			<Row>
				<Col md={4} xl={3} className={styles.imageBoxWrapper}>
					<div className={clx(styles.imageBox, 'w-100 h-100')}>
						<LoadingMask className={styles.image} />
					</div>
				</Col>
				<Col md={8} xl={9} className="d-flex flex-column">
					<div
						className={clx(
							styles.content,
							'd-flex flex-column mt-3 pt-2 mt-lg-0 mb-3 flex-grow-1'
						)}>
						<LoadingMask
							className={clx(styles.loadingCol, 'mb-2 w-50')}
						/>
						<LoadingMask
							className={clx(styles.loadingCol, 'mb-3 w-25')}
						/>
						<LoadingMask
							className={clx(styles.loadingCol, 'w-100 mb-3')}
						/>
					</div>
					<div className="d-flex align-items-center">
						<LoadingMask
							className={clx(styles.loadingCol, 'w-25 me-3')}
						/>
						<LoadingMask
							className={clx(styles.loadingCol, 'w-25 ms-auto')}
						/>
					</div>
				</Col>
			</Row>
		</article>
	);
};

export const AgentJobCardLoading: FC<Props> = ({ count = 3 }) => {
	return <SkeletonWrapper skeleton={Skeleton} count={count} />;
};
