import { memo, startTransition } from 'react';
import { api, toastify } from 'Utils';
import { Contact, SocialNetwork } from 'Types';
import { useAxios, useUpdateAgent } from 'Hooks';
import { ProfileContactSection } from 'Elements';

export const AgentContactSection = memo(() => {
	const { sendRequest } = useAxios();
	const { isLoading, agent, refetch } = useUpdateAgent();
	const contact = agent?.contact;
	const address = agent?.address;
	const social = agent?.socialNetwork as SocialNetwork[];

	const submitRequest: (fields: {
		contact: Partial<Contact>;
		socialNetwork?: Partial<SocialNetwork[]>;
	}) => Promise<void> = async (data) => {
		await sendRequest(
			api.updateAgentContact,
			{ data, method: 'PATCH' },
			() => {
				refetch();
				startTransition(() => {
					toastify('toastify.homepage-updated', {
						type: 'success'
					});
				});
			}
		);
	};

	return (
		<ProfileContactSection
			type="agent"
			contact={contact}
			address={address}
			social={social}
			isLoading={isLoading}
			onUpdate={submitRequest}
		/>
	);
});
AgentContactSection.displayName = 'AgentContactSection';
