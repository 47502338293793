import { memo, FC } from 'react';
import { clx } from 'Utils';
import { RadioOption } from 'Types';
import { RadioItem } from 'Elements';

interface Props {
	name: string;
	boxClassName?: string;
	radioClassName?: string;
	defaultSelected?: string | number;
	tag?: keyof JSX.IntrinsicElements;
	options: RadioOption[];
	onChange: (id: string | number, label: string) => void;
}

export const RadioGroup: FC<Props> = memo(
	({
		options,
		name,
		boxClassName,
		radioClassName,
		defaultSelected,
		onChange,
		tag: Wrapper = 'div'
	}) => {
		return (
			<Wrapper className={clx(boxClassName)}>
				{options?.map(({ label, value, disabled }: RadioOption) => {
					const optionId = `radio-option-${value}`;
					return (
						<RadioItem
							key={optionId}
							id={optionId}
							name={name}
							value={value}
							label={label}
							className={radioClassName}
							checked={
								value.toString() === defaultSelected?.toString()
							}
							disabled={disabled}
							onChange={() => onChange(value, label)}
						/>
					);
				})}
			</Wrapper>
		);
	}
);

RadioGroup.displayName = 'RadioGroup';
