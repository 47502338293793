import { Fragment, memo, Suspense } from 'react';
import { ToastContainer } from 'react-toastify';
import { RouterProvider } from 'react-router-dom';
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import { InteractionStatus, InteractionType } from '@azure/msal-browser';
import { withTranslation } from 'react-i18next';
import { UserCategory } from 'Types';
import { routes, loginRequest } from 'Utils';
import { useGetB2bUserProfileQuery } from 'Hooks';
import { LoadingAuthentication, LoadingContent } from 'Elements';

const App = memo(() => {
	const { inProgress } = useMsal();
	const { data } = useGetB2bUserProfileQuery();
	const userRole = data?.userCategory?.toLowerCase() as UserCategory;
	userRole && localStorage.setItem('userRole', userRole);

	return (
		<Fragment>
			{inProgress !== InteractionStatus.Startup &&
				inProgress !== InteractionStatus.HandleRedirect && (
					<MsalAuthenticationTemplate
						interactionType={InteractionType.Redirect}
						authenticationRequest={loginRequest}
						loadingComponent={LoadingAuthentication}>
						<Suspense fallback={<LoadingContent full title="" />}>
							<RouterProvider router={routes} />
						</Suspense>
					</MsalAuthenticationTemplate>
				)}
			<ToastContainer newestOnTop limit={1} icon={false} />
		</Fragment>
	);
});
App.displayName = 'App';
export default withTranslation()(App);
